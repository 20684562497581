import {
  Button,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import AuthorizationService from '../../../core/auth/Authorization.service';
import {
  initializeIndexedDB_v1,
  InitializerStatus,
  persistOfflineGlobalData,
} from '../../../sdk/services/indexeddb/Initializer';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import useOfflinePersistState from '../../../core/hooks/useOfflinePersistState';

import ErrorDisplay from '../../components/ErrorDisplay';
import { CustomModal } from '../../components/CustomModal';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import getRandomInteger from '../../../core/functions/getRandomInteger';

export default function Home() {
  usePageTitle('SIZ - Início');

  const { setDone, setSyncing } = useOfflinePersistState();
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [showModalPersist, setShowModalPersist] = useState(false);
  const [modalInfo, setModalInfo] = useState<{
    external?: boolean;
    title: string;
    info: string;
    to: string;
  }>();

  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();
  const { online } = useNavigatorStatus();

  const [screen, setScreen] = useState<'HOME' | 'FORM_INVESTIGACAO'>('HOME');

  const [randomNumber, setRandomNumber] = useState<number>(
    getRandomInteger(1000000000, 9999999999)
  );

  if ('virtualKeyboard' in navigator) {
    //@ts-ignore
    navigator.virtualKeyboard.overlaysContent = true;
  }

  const initDatabase = async () => {
    setFetching(true);

    setSyncing(true);
    await initializeIndexedDB_v1()
      .then(async () => {
        await persistOfflineGlobalData()
          .then((result) => {
            const statusSincronizacao: InitializerStatus = result[0];
            if (statusSincronizacao === InitializerStatus.ERROR)
              notification.error({
                message:
                  'Houve um erro durante a sincronização de dados globais',
              });

            if (statusSincronizacao === InitializerStatus.UPDATED)
              notification.success({
                message: 'Sincronização de dados globais realizada com sucesso',
              });

            setDone(true);
            setSyncing(false);
            setFetching(false);
          })
          .finally(() => {
            setFetching(false);
            setDone(true);
            setSyncing(false);
          })
          .catch((e) => {
            notification.error({
              message: 'Encontramos um erro ao atualizar os dados',
              description: 'Contate o administrador do sistema',
            });
            setFetching(false);
            setDone(true);
            setSyncing(false);
          });
      })
      .finally(() => {
        setFetching(false);
        setDone(true);
        setSyncing(false);
      })
      .catch((e) => {
        setFetching(false);
        setDone(true);
        setSyncing(false);
      });
  };

  /** Tries to persist storage without ever prompting user.
  returns {Promise<string>}
    "never" In case persisting is not ever possible. Caller don't bother
      asking user for permission.
    "prompt" In case persisting would be possible if prompting user first.
    "persisted" In case this call successfully silently persisted the storage,
      or if it was already persisted.
*/
  const tryPersistWithoutPromtingUser = async () => {
    if (!navigator.storage || !navigator.storage.persisted) {
      return 'never';
    }
    let persisted = await navigator.storage.persisted();
    if (persisted) {
      return 'persisted';
    }
    if (!navigator.permissions || !navigator.permissions.query) {
      return 'prompt'; // It MAY be successful to prompt. Don't know.
    }
    const permission = await navigator.permissions.query({
      name: 'persistent-storage',
    });
    if (permission.state === 'granted') {
      persisted = await navigator.storage.persist();
      if (persisted) {
        return 'persisted';
      } else {
        throw new Error('Failed to persist');
      }
    }
    if (permission.state === 'prompt') {
      return 'prompt';
    }
    return 'never';
  };

  const initStoragePersistence = async () => {
    const persist = await tryPersistWithoutPromtingUser();
    switch (persist) {
      case 'never':
        console.log('Not possible to persist storage');
        break;
      case 'persisted':
        console.log('Successfully persisted storage silently');
        break;
      case 'prompt':
        console.log('Not persisted, but we may prompt user when we want to.');
        navigator.storage.persist();
        //setShowModalPersist(true);
        break;
    }
  };

  useEffect(() => {
    initStoragePersistence();

    if (!fetching) initDatabase();
  }, []);

  useEffect(() => {}, [fetching]);

  useEffect(() => {}, [screen]);
  interface BotaoProps {
    alternateColor?: string;
    to: string;
    title: string;
    info: string;
    icon?: React.ReactNode;
    emDesemvolvimento?: boolean;
    external?: boolean;
    onClick?: () => any;
  }

  const Botao: React.FC<BotaoProps> = (props) => {
    return (
      <div
        style={{
          width: '100%',
          height: '100px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            padding: '5px',
          }}
        >
          <QuestionCircleOutlined
            //@ts-ignore
            style={{
              fontSize: '24px',
              color: '#ffffff',
              padding: '5px',
            }}
            onClick={() => {
              setShowModalInfo(true);
              setModalInfo({
                ...props,
              });
            }}
          />
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <Button
            type='primary'
            style={{
              width: '100%',
              height: '100px',
              backgroundColor: props.alternateColor,
            }}
            icon={props.icon}
            onClick={(e) => {
              if (props.onClick) {
                props.onClick();
                e.preventDefault();
                if (props.external) {
                  //@ts-ignore
                  window.open(props.to, '_blank').focus();
                }
                return;
              }

              if (props.emDesemvolvimento) {
                Modal.info({
                  content: (
                    <>
                      <Typography.Title
                        level={3}
                        style={{ textAlign: 'center' }}
                      >
                        Em desenvolvimento
                      </Typography.Title>
                      <Divider style={{ marginBottom: '-15px' }} />
                    </>
                  ),
                  icon: null,
                });
              } else {
                if (props.external) {
                  //@ts-ignore
                  window.open(props.to, '_blank').focus();
                } else {
                  navigate(props.to);
                }
              }
              e.preventDefault();
            }}
          >
            {props.title}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Spin
        size='large'
        spinning={fetching}
        tip={'Estamos atualizando os dados do app'}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        {screen === 'HOME' && (
          <Row gutter={[8, 8]}>
            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: `/visitas/cadastro${!online ? '/local' : ''}`,
                title: 'Novo FVER',
                info: 'Neste botão você preencherá o Formulário de Visita a Estabelecimento Rural e, na sequência, o Formulário de Vigilância Veterinária (caso seja veterinário).',
              })}
            </Col>
            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '/visitas',
                title: 'Novo FVV',
                info: 'Neste botão você preencherá o Formulário de Vigilância Veterinária a partir de uma visita realizada anteriormente. Não é possível inserir o Formulário de Vigilância Veterinária sem haver, antes, um Formulário de Visita a Estabelecimento Rural.',
              })}
            </Col>
            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: `/tfr/cadastro${!online ? '/local' : ''}`,
                title: 'Novo TFR',
                info: 'Neste botão você preencherá o Formulário de Termo de Fisacalização de Revendas.',
              })}
            </Col>
            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '/visitas',
                title: 'Novo Laudo de Inspeção',
                info: 'Neste botão você preencherá o Formulário de Vigilância Veterinária a partir de uma visita realizada anteriormente. Não é possível inserir o Formulário de Vigilância Veterinária sem haver, antes, um Formulário de Visita a Estabelecimento Rural.',
              })}
            </Col>
            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: 'https://drive.google.com/drive/folders/1XsPSgINCAlMkQGv9YARYlTsWnzqJoMva',
                title: 'Informes e Análises',
                info: 'Neste botão você terá acesso ao repositório de Informes e Análises.',
                external: true,
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '#',
                title: 'Formulários de investigação',
                info: 'Neste botão você terá acesso aos formulários oficiais para atendimento à suspeita de doença de notificação obrigatória.',
                emDesemvolvimento: true,
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: `http://cdsa:${encodeURIComponent(
                  '$4Iei1b7#SXc'
                )}@pbi.mti.mt.gov.br/Reports/browse/INDEA/ANIMAL?xyz=${randomNumber}`,
                title: 'Gestão de metas',
                info: 'Neste botão você terá acesso ao andamento das metas de sua unidade podendo acompanhar mais de perto o seu progresso.',
                external: true,
                onClick() {
                  setRandomNumber(getRandomInteger(1000000000, 9999999999));
                },
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: 'https://drive.google.com/drive/folders/1jPEOh9qcs6QePYPtgGHbnesgyFYzzUxT?usp=sharing',
                title: 'Manuais técnicos',
                info: 'Neste botão você terá acesso, para consulta, aos manuais de procedimentos publicados pela Coordenadoria de Defesa Sanitária Animal, MAP e OIE.',
                external: true,
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: 'https://linktr.ee/educaindea',
                title: 'Educação Sanitária',
                info: 'Neste botão você terá acesso ao repositório de Educação Sanitária.',
                external: true,
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '/offline',
                title: 'Sincronizar dados',
                info: 'Neste botão você poderá armazenar localmente (offline) os dados de produtores rurais, estabelecimentos rurais e explorações pecuárias. O funcionamento offline depende do envio e sincronização desses dados.',
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '/acoes-de-campo',
                title: 'Ações de campo',
                info: 'Neste botão você fará o envio, para o SIZ WEB, dos dados inseridos de forma offline. Somente após este envio a ação de campo será registrada.',
              })}
            </Col>

            <Col md={8} sm={12} xs={24}>
              {Botao({
                to: '/usuario',
                title:
                  '' +
                  AuthorizationService.getUsuarioXVia()?.name.split(' ')[0],
                info: 'Neste botão você poderá visualizar o seu perfil',
                icon: (
                  <UserOutlined style={{ color: 'white', fontSize: '20px' }} />
                ),
              })}
            </Col>
          </Row>
        )}

        {screen === 'FORM_INVESTIGACAO' && (
          <>
            <Row gutter={[8, 8]}>
              <Col md={8} xs={24}>
                {Botao({
                  to: '/formvins/cadastro',
                  title: 'Novo FormVIN',
                  info: 'Novo FormVIN',
                })}
              </Col>
            </Row>

            <br />
            <Row>
              <Col xs={24}>
                <Button
                  type='primary'
                  style={{ width: '100%' }}
                  onClick={() => setScreen('HOME')}
                >
                  Voltar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Spin>

      <br />

      <Col span={24}>
        {Botao({
          alternateColor: 'green',
          to: 'https://docs.google.com/forms/d/e/1FAIpQLScEWU0j09D64js4FViZdseAADI1BRD-pn1AFY4G3FgO4RBSFg/viewform',
          title: 'Pesquisa de satisfação',
          info: 'Neste botão você preencherá um Formulário de Feedback em relação ao SIZ',
          external: true,
        })}
      </Col>

      <CustomModal
        open={false}
        closable={false}
        footer={
          <Button
            type='primary'
            style={{ width: '100%', height: '60px' }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Tentar novamente
          </Button>
        }
      >
        <div
          style={{
            padding: 24,
            border: '1px solid ',
            textAlign: 'center',
          }}
        >
          <ErrorDisplay
            title='Não conseguimos conectar com o servidor. Tente novamente mais tarde'
            message=' '
          />
        </div>
      </CustomModal>

      <CustomModal
        open={showModalInfo}
        closable={false}
        bodyStyle={{ padding: 0 }}
        title={modalInfo?.title}
        footer={
          <Row gutter={12}>
            <Col span={12}>
              <Button
                type='default'
                style={{ width: '100%' }}
                onClick={() => {
                  setShowModalInfo(false);
                }}
              >
                Fechar
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                style={{ width: '100%' }}
                onClick={() => {
                  setShowModalInfo(false);
                  if (modalInfo?.to) {
                    if (modalInfo.external) {
                      //@ts-ignore
                      window.open(modalInfo.to, '_blank').focus();
                    } else {
                      navigate(modalInfo.to);
                    }
                  }
                }}
              >
                {modalInfo?.to && modalInfo?.to !== '#' && 'Ir'}
                {(!modalInfo?.to || modalInfo?.to === '#') &&
                  'Em desenvolvimento'}
              </Button>
            </Col>
          </Row>
        }
      >
        <Typography.Paragraph style={{ textAlign: 'justify' }}>
          {modalInfo?.info}
        </Typography.Paragraph>
      </CustomModal>

      <CustomModal
        open={showModalPersist}
        closable={false}
        bodyStyle={{ padding: 0 }}
        title={'Permissão requerida'}
        footer={
          <Button
            type='primary'
            style={{ width: '100%' }}
            onClick={() => {
              navigator.storage.persist();
              setShowModalPersist(false);
            }}
          >
            Ok
          </Button>
        }
      >
        <Typography.Paragraph style={{ textAlign: 'justify' }}>
          Nesta última versão do SIZ melhoramos a forma como armazenamos os
          dados no seu dispositivo. Mas para que essas ações tenham efeito,
          precisamos que você dê permissão para que o SIZ possa utilizar mais
          efetivamente o armazenamento interno do dispositivo.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Clique em Ok para confirmar.
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
