import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Affix, Button, Col, Row, Spin, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import PrintTFR from '../reports/PrintTFR';
import TermoFisacalizacaoService from '../../../sdk/services/SIZ-API/TermoFiscalizacao.service';
import { TermoFiscalizacao } from '../../../sdk/@types';

export default function TFRExternalView() {
  usePageTitle('TFR');

  const { loading, setLoading } = useLoadingPage();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();
  const [tfr, setTFR] = useState<TermoFiscalizacao.Response>();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });

  useEffect(() => {}, [params]);

  const fetchTFRById = useCallback(
    async (codigoVerificador: string) => {
      // @ts-ignore
      await TermoFisacalizacaoService.getById_PublicAccess(params.id)
        .then(async (tfr) => {
          setTFR(tfr);
        })
        .catch((e) => {
          setError(e);
          notification.error({
            message: e.message,
          });
        });
    },
    [params.id]
  );

  useEffect(() => {}, [params]);

  useEffect(() => {}, [tfr]);

  useEffect(() => {
    // @ts-ignore
    fetchTFRById(params.id).catch((e) => {
      setError(new Error(e.message));
    });
  }, [fetchTFRById, params]);

  if (error) throw error;

  return (
    <Spin
      size='large'
      spinning={loading}
      style={{
        position: 'fixed',
        top: '20%',
        zIndex: '1',
      }}
    >
      <div
        style={{
          maxWidth: '90%',
          margin: 'auto',
        }}
      >
        <Affix offsetTop={80} className='printButton'>
          <Row gutter={12}>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  navigate(-1);
                }}
              >
                Voltar
              </Button>
            </Col>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  //window.print();
                  reactPrint();
                }}
                icon={<PrinterOutlined />}
              >
                Imprimir
              </Button>
            </Col>
          </Row>
        </Affix>
        <div ref={printRef}>
          <table
            className='tablePrintTFR'
            style={{
              tableLayout: 'fixed',
              width: '100%',
            }}
          >
            <PrintTFR tfr={tfr} />
          </table>
        </div>
      </div>
    </Spin>
  );
}
