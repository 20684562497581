import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SW_INIT, SW_UPDATE } from './types';

interface ServiceWorkerControllerState {
  serviceWorkerInitialized: boolean;
  serviceWorkerUpdated: boolean;
  serviceWorkerRegistration: any;
}

const initialState: ServiceWorkerControllerState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const ServiceWorkerControllerSlice = createSlice({
  initialState,
  name: 'serviceWorkerController',
  reducers: {
    rootReducer(state, action: PayloadAction<{ payload: any; type: string }>) {
      switch (action.payload.type) {
        case SW_INIT:
          return {
            ...state,
            serviceWorkerInitialized: !state.serviceWorkerInitialized,
          };
        case SW_UPDATE:
          return {
            ...state,
            serviceWorkerUpdated: !state.serviceWorkerUpdated,
            serviceWorkerRegistration: action.payload.payload,
          };
        default:
          return state;
      }
    },
  },
});

export const { rootReducer } = ServiceWorkerControllerSlice.actions;
const ServiceWorkerControllerReducer = ServiceWorkerControllerSlice.reducer;
export default ServiceWorkerControllerReducer;
