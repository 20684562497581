import { EmpresaIntegradora } from '../../@types/EmpresaIntegradora';

import Service from './Service';

class EmpresaIntegradoraService extends Service {
  static getAllEmpresasIntegradoras() {
    return this.Http.get<EmpresaIntegradora.Summary[]>(
      '/empresas-integradoras',
      { timeout: 600000 }
    ).then(this.getData);
  }
}

export default EmpresaIntegradoraService;
