import { Col, Form, FormInstance, Input, Row, Tooltip } from 'antd';
import { RecebimentosRevendas } from '../../sdk/@types';

interface RevendaProps {
  form: FormInstance;
  formDisabled: boolean;
  revendaSelecionada?: boolean;
  revendaDestinoDados: RecebimentosRevendas.Response;
  isReadOnly: boolean;
  allowSearch: boolean;
  onClearForm: () => void;
}

export default function RevendaPanelNotaFiscal(props: RevendaProps) {
  return (
    <>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item label={'CNPJ'} name={['revendaDestino', 'cpfCnpj']}>
            <Input
              placeholder={'CNPJ ou nome fantasia'}
              value={props.revendaDestinoDados?.revendaDestino?.cpfCnpj || ''}
              readOnly
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Tooltip title='Busca por nome disponível apenas no modo offline'>
            <Form.Item
              label={'Nome Fantasia'}
              name={['revendaDestino', 'nome']}
            >
              <Input
                placeholder={'CNPJ ou nome fantasia'}
                value={props.revendaDestinoDados?.revendaDestino?.nome || ''}
                readOnly
                disabled
              />
            </Form.Item>
          </Tooltip>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Email'}
            name={['revendaDestino', 'email']}
            rules={[
              {
                validator: async (_, email) => {
                  if (!email) return;
                  if (
                    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                      email
                    )
                  )
                    return Promise.reject(new Error('Email inválido'));
                },
              },
            ]}
          >
            <Input
              inputMode='email'
              disabled
              autoComplete='off'
              value={props.revendaDestinoDados?.revendaDestino?.email || ''}
              readOnly
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={16}>
          <Form.Item
            label={'Endereço'}
            name={['revendaDestino', 'endereco', 'bairro']}
          >
            <Input
              disabled
              value={
                props.revendaDestinoDados?.revendaDestino?.endereco?.bairro ||
                ''
              }
              readOnly
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label={'ULE'} name={['revendaDestino', 'ule', 'nome']}>
            <Input
              disabled
              value={props.revendaDestinoDados?.revendaDestino?.ule?.nome || ''}
              readOnly
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
