import { Propriedade } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
import ProdutorIDBService from './ProdutorIDB.service';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';

class PropriedadeIDBService extends ServiceIDB {
  static DB_STORE_PROPRIEDADE = 'propriedade';
  static DB_STORE_PROPRIEDADE_INDEX_ID_NAME = 'propriedade_index_id';
  static DB_STORE_PROPRIEDADE_INDEX_ID_KEY = 'payload.id';
  static DB_STORE_PROPRIEDADE_INDEX_MUNICIPIO_NAME =
    'propriedade_index_municipio';
  static DB_STORE_PROPRIEDADE_INDEX_MUNICIPIO_KEY =
    'payload.municipio.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(PropriedadeIDBService.DB_STORE_PROPRIEDADE)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(PropriedadeIDBService.DB_STORE_PROPRIEDADE);
    return list;
  }

  static async getAllPayload(): Promise<Propriedade.Summary[]> {
    let list: Propriedade.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async add(propriedade: Propriedade.Summary) {
    return openDB(DBConfig.DB_NAME).then(async (db) => {
      /* if (propriedade.id === 21179)
        throw new Error('Erro adicionando propriedade 21179'); */

      const date = new Date();
      const obj = {
        date: date,
        payload: propriedade,
      };

      await db.add(this.DB_STORE_PROPRIEDADE, obj);

      const isProprietarioEExplorador = (cpf: string) => {
        let result = false;
        const produtores = propriedade.exploracaos?.filter((explorador) =>
          explorador.produtores.filter(
            (produtor) =>
              //@ts-ignore
              produtor.produtor.cpfCnpj === cpf || produtor.produtor.cpf === cpf
          )
        );

        if (produtores && produtores.length > 0) {
          result = true;
        }
        return result;
      };

      propriedade.exploracaos?.forEach(async (exp) => {
        exp.produtores.forEach(async (produtor) => {
          await db.add(ProdutorIDBService.DB_STORE_PRODUTOR, {
            produtor: {
              cpf: produtor.produtor.cpfCnpj
                ? produtor.produtor.cpfCnpj
                : //@ts-ignore
                  produtor.produtor.cpf,
              nome: produtor.produtor.nome,
            },
            propriedade: {
              id: propriedade.id,
              municipio: propriedade.municipio,
            },
          });
        });
      });

      let shouldIncludeProprietario;
      propriedade.proprietarios?.forEach(async (proprietario) => {
        shouldIncludeProprietario = !isProprietarioEExplorador(
          proprietario.proprietario.cpfCnpj
            ? proprietario.proprietario.cpfCnpj
            : //@ts-ignore
              proprietario.proprietario.cpf
        );

        if (shouldIncludeProprietario) {
          await db.add(ProdutorIDBService.DB_STORE_PRODUTOR, {
            produtor: {
              cpf: proprietario.proprietario.cpfCnpj
                ? proprietario.proprietario.cpfCnpj
                : //@ts-ignore
                  proprietario.proprietario.cpf,
              nome: proprietario.proprietario.nome,
            },
            propriedade: {
              id: propriedade.id,
              municipio: propriedade.municipio,
            },
          });
        }
      });
    });
  }

  static async addAllPropriedade(lista: Propriedade.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((propriedade) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: propriedade,
      };

      db.add(this.DB_STORE_PROPRIEDADE, obj);

      propriedade.exploracaos?.forEach((exp) => {
        exp.produtores.forEach((produtor) => {
          db.add(ProdutorIDBService.DB_STORE_PRODUTOR, {
            produtor: {
              cpf: produtor.produtor.cpfCnpj,
              nome: produtor.produtor.nome,
            },
            propriedade: {
              id: propriedade.id,
              municipio: propriedade.municipio,
            },
          });
        });
      });
    });
  }

  static async getByProdutorCpf(cpf: string): Promise<Propriedade.Summary[]> {
    let listaPropriedade: Propriedade.Summary[] = [];
    return await super
      ._getAllFromIndex(
        ProdutorIDBService.DB_STORE_PRODUTOR,
        ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_CPF_NAME,
        removeNonNumericCharactersFromString(cpf)
      )
      .then((codigos: any[]) => {
        return Promise.all(
          codigos.map(async (element) => {
            return await PropriedadeIDBService.getById(
              element.propriedade.id
            ).then((e) => {
              listaPropriedade = listaPropriedade.concat(e.payload);
              return e.payload;
            });
          })
        );
      });
  }

  static async getById(id: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_PROPRIEDADE,
      this.DB_STORE_PROPRIEDADE_INDEX_ID_NAME,
      id
    );
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_PROPRIEDADE);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_PROPRIEDADE,
      this.DB_STORE_PROPRIEDADE_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default PropriedadeIDBService;
