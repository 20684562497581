import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHasFieldWithNoValueDistanciasExternas } from '../../../core/store/LIA.slice';

type CustomCardProps = {
  title: string;
  name: string;
  whatDistance: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChangeDistanciasExternas: (
    name: string,
    value: string,
    whatDistance: string,
    updatedValue: string | null
  ) => void;
};
const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  whatDistance,
  formValue,
  formDisable,
  updatedValue,
  handleChangeDistanciasExternas,
}) => {
  return (
    <Card title={title}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChangeDistanciasExternas(
                    name,
                    e.target.value,
                    whatDistance,
                    updatedValue
                  )
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
                <Radio value='NA'>Não se Aplica (N.A)</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12}>
            {formValue === 'NAO' && (
              <Form.Item
                label='Qual a distancia ?'
                name={whatDistance}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input disabled={formDisable} />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

type InformacoesDistanciasExternasProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function DistanciasExternas(
  props: InformacoesDistanciasExternasProps
) {
  const dispatch = useDispatch();

  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const [hasNAOValueDistanciasExternas, setHasNAOValueDistanciasExternas] =
    useState<boolean>(false);

  const handleChangeDistanciasExternas = (
    name: string,
    value: string,
    whatDistance: string
  ) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [whatDistance]: undefined,
      });
    }
    if (value === 'NAO') {
      setHasNAOValueDistanciasExternas(true);
    } else {
      setHasNAOValueDistanciasExternas(false);
    }
  };

  useEffect(() => {
    const hasAnyNAOValue = fieldsToCheck.some(
      (fieldName) => props.form.getFieldValue(fieldName) === 'NAO'
    );

    setHasNAOValueDistanciasExternas(hasAnyNAOValue);
  }, [props.form, hasNAOValueDistanciasExternas, dispatch]);

  useEffect(() => {
    dispatch(
      setHasFieldWithNoValueDistanciasExternas(hasNAOValueDistanciasExternas)
    );
  }, [hasNAOValueDistanciasExternas, dispatch]);

  const fieldsToCheck = useMemo(
    () => [
      'distanciaMinima3kmMatrizeiros_1_1',
      'distanciaMinima3kmAvesOrnamentais_1_2',
      'distanciaMinima3kmEstabelecimentoEnsino_1_3',
      'distanciaMinima3kmIncubatorios_1_4',
      'distanciaMinima10kmCriacaoDeAves_1_5',
      'distanciaMinima10kmCriacoesAteMilAves_1_6',
      'distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7',
      'distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8',
      'distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9',
      'distanciaMinima1kmGranjaAvesPoedeiras_1_10',
      'distanciaMinima3kmAviarioFabricaRacao_1_11',
      'distanciaMinima3kmIndustriaProcessamentoProdutos_1_12',
      'distanciaMinima3kmAterroSanitarioLixao_1_13',
      'distanciaMinima3kmLagoaResiduo_1_14',
      'distanciaMinima1kmBiodigestores_1_15',
      'distanciaMinima4kmCriacaoDeAves_1_16',
    ],
    []
  );

  return (
    <>
      <Typography.Title level={5}>
        Distâncias mínimas entre estabelecimentos avícolas | Distâncias Externas
      </Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmMatrizeiros_1_1'
            title='1.1 - Está distante no mínimo 3,0Km de matrizeiros?'
            name='distanciaMinima3kmMatrizeiros_1_1'
            whatDistance='distanciaMatrizeiros_1_1'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmMatrizeiros_1_1'
            )}
            updatedValue={updatedValues['distanciaMinima3kmMatrizeiros_1_1']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmAvesOrnamentais_1_2'
            title='1.2 - Está distante no mínimo 3,0Km de estabelecimento de aves ornamentais?'
            name='distanciaMinima3kmAvesOrnamentais_1_2'
            whatDistance='distanciaAvesOrnamentais_1_2'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmAvesOrnamentais_1_2'
            )}
            updatedValue={
              updatedValues['distanciaMinima3kmAvesOrnamentais_1_2']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmEstabelecimentoEnsino_1_3'
            title='1.3 - Está distante no mínimo 3,0Km estabelecimento de ensino ou pesquisa?'
            name='distanciaMinima3kmEstabelecimentoEnsino_1_3'
            whatDistance='distanciaEstabelecimentoEnsino_1_3'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmEstabelecimentoEnsino_1_3'
            )}
            updatedValue={
              updatedValues['distanciaMinima3kmEstabelecimentoEnsino_1_3']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmIncubatorios_1_4'
            title='1.4 - Está distante no mínimo 3,0Km de incubatórios?'
            name='distanciaMinima3kmIncubatorios_1_4'
            whatDistance='distanciaIncubatorios_1_4'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmIncubatorios_1_4'
            )}
            updatedValue={updatedValues['distanciaMinima3kmIncubatorios_1_4']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima10kmCriacaoDeAves_1_5'
            title='1.5 - Está distante no mínimo 10 Km de Sistemas de criação de aves comerciais ao ar livre?'
            name='distanciaMinima10kmCriacaoDeAves_1_5'
            whatDistance='distanciaCriacaoDeAves_1_5'
            formValue={props.form.getFieldValue(
              'distanciaMinima10kmCriacaoDeAves_1_5'
            )}
            updatedValue={updatedValues['distanciaMinima10kmCriacaoDeAves_1_5']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima10kmCriacoesAteMilAves_1_6'
            title='1.6 - Está distante no mínimo 10 Km de criações comerciais de até 1.000(mil) aves?'
            name='distanciaMinima10kmCriacoesAteMilAves_1_6'
            whatDistance='distanciaCriacoesAteMilAves_1_6'
            formValue={props.form.getFieldValue(
              'distanciaMinima10kmCriacoesAteMilAves_1_6'
            )}
            updatedValue={
              updatedValues['distanciaMinima10kmCriacoesAteMilAves_1_6']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaEntreAvesCorteAvesPoedeiras_1_7'
            title='1.7 - A distância entre aves de corte e aves poedeiras de ovos comerciais é de no mínimo 3,0 km?'
            name='distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7'
            whatDistance='distanciaEntreAvesCorteAvesPoedeiras_1_7'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7'
            )}
            updatedValue={
              updatedValues['distanciaEntreAvesCorteAvesPoedeiras_1_7']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8'
            title='1.8 - A distância entre aves de corte de diferentes integradoras e independentes é de no mínimo 1,0km?'
            name='distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8'
            whatDistance='distanciaEntreAvesCortesDiferentesIntegradoras_1_8'
            formValue={props.form.getFieldValue(
              'distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8'
            )}
            updatedValue={
              updatedValues[
                'distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9'
            title='1.9 - A distância entre granjas de aves de corte de mesma integradora é de no mínimo 200m?'
            name='distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9'
            whatDistance='distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9'
            formValue={props.form.getFieldValue(
              'distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9'
            )}
            updatedValue={
              updatedValues[
                'distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima1kmGranjaAvesPoedeiras_1_10'
            title='1.10 - A distância entre granjas de aves poedeiras de ovos comerciais é de no mínimo 1,0 km?'
            name='distanciaMinima1kmGranjaAvesPoedeiras_1_10'
            whatDistance='distanciaGranjaAvesPoedeiras_1_10'
            formValue={props.form.getFieldValue(
              'distanciaMinima1kmGranjaAvesPoedeiras_1_10'
            )}
            updatedValue={
              updatedValues['distanciaMinima1kmGranjaAvesPoedeiras_1_10']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmAviarioFabricaRacao_1_11'
            title='1.11 - A distância entre aviário(s) e fábrica de ração de terceiros é de no mínimo 3,0 km?'
            name='distanciaMinima3kmAviarioFabricaRacao_1_11'
            whatDistance='distanciaAviarioFabricaRacao_1_11'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmAviarioFabricaRacao_1_11'
            )}
            updatedValue={
              updatedValues['distanciaMinima3kmAviarioFabricaRacao_1_11']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmIndustriaProcessamentoProdutos_1_12'
            title='1.12 - Está distante no mínimo 3,0 Km de indústria de processamento de produtos e subprodutos de origem animal?'
            name='distanciaMinima3kmIndustriaProcessamentoProdutos_1_12'
            whatDistance='distanciaIndustriaProcessamentoProdutos_1_12'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmIndustriaProcessamentoProdutos_1_12'
            )}
            updatedValue={
              updatedValues[
                'distanciaMinima3kmIndustriaProcessamentoProdutos_1_12'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmAterroSanitarioLixao_1_13'
            title='1.13 - Está distante no mínimo 3,0 Km do aterro sanitário e/ou lixão?'
            name='distanciaMinima3kmAterroSanitarioLixao_1_13'
            whatDistance='distanciaAterroSanitarioLixao_1_13'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmAterroSanitarioLixao_1_13'
            )}
            updatedValue={
              updatedValues['distanciaMinima3kmAterroSanitarioLixao_1_13']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima3kmLagoaResiduo_1_14'
            title='1.14 - Está distante no mínimo 3,0 Km de lagoa de resíduo de outra Natureza?'
            name='distanciaMinima3kmLagoaResiduo_1_14'
            whatDistance='distanciaLagoaResiduo_1_14'
            formValue={props.form.getFieldValue(
              'distanciaMinima3kmLagoaResiduo_1_14'
            )}
            updatedValue={updatedValues['distanciaMinima3kmLagoaResiduo_1_14']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima1kmBiodigestores_1_15'
            title='1.15 - Está distante 1,0Km de biodigestores (suínos/aves/ outros)?'
            name='distanciaMinima1kmBiodigestores_1_15'
            whatDistance='distanciaBiodigestores_1_15'
            formValue={props.form.getFieldValue(
              'distanciaMinima1kmBiodigestores_1_15'
            )}
            updatedValue={updatedValues['distanciaMinima1kmBiodigestores_1_15']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasExternas={handleChangeDistanciasExternas}
            key='distanciaMinima4kmCriacaoDeAves_1_16'
            title='1.16 - Está distante no mínimo 4,0km de criação de aves de diferentes espécies, exóticas, silvestres ou domésticas, com objetivo 
de produção de matrizes ou de aves vivas para atendimento ao mercado de aves de estimação?'
            name='distanciaMinima4kmCriacaoDeAves_1_16'
            whatDistance='distanciaCriacaoDeAves_1_16'
            formValue={props.form.getFieldValue(
              'distanciaMinima4kmCriacaoDeAves_1_16'
            )}
            updatedValue={updatedValues['distanciaMinima4kmCriacaoDeAves_1_16']}
          />
        </Col>
      </Row>
      <Divider />
      <Col xs={24} lg={24}>
        <Form.Item
          label={'Observações'}
          name={'observacoesDistanciasExternas_1_17'}
        >
          <TextArea
            showCount
            rows={5}
            maxLength={2000}
            disabled={props.formDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
