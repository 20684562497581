import { FVERGeralReport } from '../../@types/FVERGeralReport';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FVERGeralReportService extends Service {
  static get(
    dataIni: string,
    dataFim: string,
    codigoIBGE?: string
  ): Promise<FVERGeralReport.Summary[]> {
    const queryString = generateQueryString({
      dataIni,
      dataFim,
      codigoIBGE,
    });
    return this.Http.get<FVERGeralReport.Summary[]>(
      '/relatorios-visita/geral-v3'.concat(queryString)
    ).then(this.getData);
  }
}

export default FVERGeralReportService;
