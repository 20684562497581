import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PublicLIAPrintView from '../features/LIA/PublicLIAPrintView';

export default function LIAPublicPrintWrapper() {
  usePageTitle('LIA');

  return (
    <>
      <ErrorBoundary component='o Laudo de Inspeção'>
        <PublicLIAPrintView />
      </ErrorBoundary>
    </>
  );
}
