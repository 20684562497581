import { useEffect } from 'react';
import { Card, Descriptions, Row, Tag, Typography } from 'antd';
import { FormGraf } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';

type PrintTFRProps = {
  formGraf?: FormGraf.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number | React.ReactNode;
  span?: number;
};

export default function PrintFormGraf(props: PrintTFRProps) {
  useEffect(() => {}, [props.formGraf]);

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO TERMO DE FISCALIZAÇÃO DE REVENDA
              </Typography.Title>
            </Row>
            <br />

            {panelDescription('Dados da Visita', [
              {
                label: 'Data de Criação',
                value: moment(props.formGraf?.dataCadastro).format(
                  'DD/MM/YYYY HH:mm'
                ),
              },
              {
                label: 'Data de Distribuição',
                value: moment(props.formGraf?.dataDistribuicao).format(
                  'DD/MM/YYYY HH:mm'
                ),
              },
            ])}
            <br />
            {panelDescription('Modalidade', [
              {
                value: props.formGraf?.modalidadeDistribuicao,
              },
            ])}
            <br />
            {panelDescription('Tipos de Apresentação do Material Educativo', [
              {
                value: props.formGraf?.tiposApresentacaoMaterialEducativo?.map(
                  (tipo: string, index: number) => (
                    <Tag
                      style={{
                        fontSize: '0.7em',
                        padding: 4,
                        margin: 2,
                      }}
                      key={index}
                    >
                      {tipo}
                    </Tag>
                  )
                ),
              },
            ])}
            <br />
            {panelDescription('Município', [
              {
                label: 'Nome',
                value: props.formGraf?.municipio?.nome,
              },
              {
                label: 'UF',
                value: props.formGraf?.municipio?.uf?.nome,
              },
            ])}
            <br />
            {props.formGraf?.servidores &&
              panelComponent(
                'Servidores',
                props.formGraf?.servidores?.map((servidor) =>
                  panelDescription('', [
                    {
                      label: 'Nome',
                      value: servidor?.pessoa?.nome,
                    },
                    {
                      label: 'CPF',
                      value: servidor.pessoa?.cpfCnpj?.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4'
                      ),
                      span: 2,
                    },

                    {
                      label: 'Matrícula',
                      value: servidor.matricula,
                    },
                    {
                      label: 'Profissão',
                      value: servidor.profissao?.nome,
                    },
                  ])
                )
              )}
            <br />
            {panelComponent(
              'Programas e Atividades',
              props.formGraf?.tiposChavesPrincipais
                ? props.formGraf.tiposChavesPrincipais.map(
                    (chavePrimaria, index) => (
                      <div className='newPage' key={`chave-primaria-${index}`}>
                        {chavePrimaria?.nome}
                      </div>
                    )
                  )
                : null
            )}
            <br />
            {panelDescription(
              'Quantidade de Pessoas Beneficiadas Diretamente',
              [
                {
                  value: props.formGraf?.quantidadePessoasAtingidas,
                },
              ]
            )}
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
