import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as LatestVersionAvaiableStateActions from '../store/LatestVersionAvaiableState.slice';
import EnvConfig from '../../app/EnvConfig';
import NotificacaoSistemaService from '../../sdk/services/SIZ-API/NotificacaoSistema.service';

type Version = {
  major: number;
  minor: number;
  patch: number;
};

export default function useLatestVersionAvaiableState() {
  const dispatch = useDispatch();

  const latestVersion = useSelector(
    (state: RootState) => state.latestVersionAvaiableState.latestVersion
  );

  const stringToVersion = (version: string): Version | undefined => {
    let versionSplit = version.split('.');

    if (versionSplit && versionSplit.length === 3)
      return {
        major: Number(versionSplit[0]),
        minor: Number(versionSplit[1]),
        patch: Number(versionSplit[2]),
      };
    else return undefined;
  };

  const newVersionAvailable = useCallback(() => {
    const atual = stringToVersion(EnvConfig.version())!;
    const latest = latestVersion ? stringToVersion(latestVersion) : undefined;

    if (!latest) return false;

    //check major
    if (latest.major > atual.major) {
      return true;
    } else if (latest.major === atual.major && latest.minor > atual.minor) {
      return true;
    } else if (latest.minor === atual.minor && latest.patch > atual.patch) {
      return true;
    } else {
      return false;
    }
  }, [latestVersion]);

  const setLatestVersion = useCallback(async () => {
    await NotificacaoSistemaService.getAll()
      .then((notificacoesSistema) => {
        if (notificacoesSistema && notificacoesSistema.length > 0) {
          notificacoesSistema.sort((n1, n2) => {
            if (n1.id > n2.id) return -1;
            if (n1.id < n2.id) return 1;

            return 0;
          });

          dispatch(
            LatestVersionAvaiableStateActions.setLatestVersion(
              notificacoesSistema[0].versao!
            )
          );
        }
      })
      .catch(() => {});
  }, [dispatch]);

  return {
    latestVersion,
    newVersionAvailable,
    setLatestVersion,
  };
}
