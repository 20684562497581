import { useCallback, useState } from 'react';
import { Municipio } from '../../sdk/@types/Municipio';
import MunicipioService from '../../sdk/services/SIZ-API/Municipio.service';

export default function useMunicipio() {
  const [listaMunicipio, setlistaMunicipio] = useState<Municipio.Detailed[]>();
  const [fetchingMunicipios, setFetchingMunicipios] = useState<boolean>(false);

  const fetchMunicipiosByUf = useCallback((uf: string) => {
    try {
      setFetchingMunicipios(true);
      return MunicipioService.getByUf(uf).then(setlistaMunicipio);
    } finally {
      setFetchingMunicipios(false);
    }
  }, []);

  return {
    listaMunicipio,
    fetchingMunicipios,
    fetchMunicipiosByUf,
  };
}
