import { Divider, Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormSOVIV from './FormRecebimentoRevenda';

export default function CreateSOVIV() {
  const { loading } = useLoadingPage();

  return (
    <>
      <Divider orientation='left'>
        <Typography.Title level={3}>
          Novo Formulário Recebimento de Insumos Pecuários - SVO IV
        </Typography.Title>
      </Divider>

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormSOVIV />
      </Spin>
    </>
  );
}
