import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormEDUCA from './FormEDUCA';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FormEducaActions from '../../../core/store/FormEDUCA.slice';

function EditFormEduca() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const formEduca = useSelector((state: RootState) => state.formEduca.data);
  const loading = useSelector((state: RootState) => state.formEduca.loading);

  const prepareData = useCallback(
    (formEduca) => ({
      ...formEduca,
      dataAcao: moment(formEduca?.dataAcao),
    }),
    []
  );
  useEffect(() => {
    if (!isNaN(Number(id))) {
      dispatch(FormEducaActions.fetchFormEducaById(Number(id)));
    }
  }, [id, local, dispatch]);
  return (
    <>
      {!formEduca && <Skeleton active />}
      {formEduca && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando FormEduca</Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loading === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormEDUCA educa={prepareData(formEduca)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFormEduca;
