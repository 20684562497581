import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permissao } from '../../sdk/@types/Permissao';
import PermissaoService from '../../sdk/services/SIZ-API/Permissao.service';

interface PermissoesType {
  permissoes: Permissao.Response[];
}

const initialState: PermissoesType = {
  permissoes: [],
};

export const fetchAll = createAsyncThunk(
  'permissoes/fetchAll',
  async (_, { dispatch, rejectWithValue }) => {
    return await PermissaoService.getAll()
      .then((permissoes) => {
        dispatch(setPermissoes(permissoes));
      })
      .catch((error) => {
        console.error('Erro ao buscar as Permissões:', error);
        return rejectWithValue(
          'Ocorreu um erro ao buscar as Permissões: ' + error
        );
      });
  }
);

const PermissoesSlice = createSlice({
  initialState,
  name: 'permissoes',
  reducers: {
    setPermissoes(state, action: PayloadAction<Permissao.Response[]>) {
      state.permissoes = action.payload;
    },
  },
});

export const { setPermissoes } = PermissoesSlice.actions;

const PermissoesReducer = PermissoesSlice.reducer;
export default PermissoesReducer;
