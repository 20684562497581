import { RecebimentosRevendas } from '../../@types/RecebimentosRevenda';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class RecebimentoRevendaService extends Service {
  static BASE_URL = '/recebimentos-revendas';

  static getAll(
    query: RecebimentosRevendas.Query
  ): Promise<RecebimentosRevendas.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<RecebimentosRevendas.Paginated>(
      this.BASE_URL.concat(queryString)
    ).then(this.getData);
  }

  static add(recebimentoRevendaRequest: RecebimentosRevendas.Request) {
    const recebimentoRevendaDTO = {
      ...recebimentoRevendaRequest,
    };

    return this.Http.post<RecebimentosRevendas.Request>(
      this.BASE_URL,
      recebimentoRevendaDTO
    ).then(this.getData);
  }

  static getByCnpjAndNumero(
    cnpj: string,
    numero: number
  ): Promise<RecebimentosRevendas.Response[]> {
    const queryString = generateQueryString({ cnpj, numero });
    const url = `${this.BASE_URL}/notas-fiscais`.concat(queryString);

    return this.Http.get<RecebimentosRevendas.Response[]>(url)
      .then(this.getData)
      .catch((error) => {
        console.error('Erro na chamada para API:', error);
        throw error;
      });
  }

  static async update(
    recebimentoRevendaId: number,
    data: RecebimentosRevendas.Request
  ) {
    const recebimentosRevendasDTO = {
      ...data,
    };

    return this.Http.put<RecebimentosRevendas.Request>(
      `${this.BASE_URL}/${recebimentoRevendaId}`,
      recebimentosRevendasDTO
    ).then(this.getData);
  }

  static getById(recebimentoRevendaId: number) {
    return this.Http.get<RecebimentosRevendas.Response>(
      `${this.BASE_URL}/${recebimentoRevendaId}`
    ).then(this.getData);
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`${this.BASE_URL}/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }
}

export default RecebimentoRevendaService;
