import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { insertParticipant } from '../../core/store/FormEDUCA.slice';
import { AppDispatch } from '../../core/store';
import { CustomModal } from './CustomModal';

interface AddParticipantModalProps {
  visible: boolean;
  onCancel: () => void;
  formEducaId: number;
}

const AddParticipantModal: React.FC<AddParticipantModalProps> = ({
  visible,
  onCancel,
  formEducaId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log('Form Values:', values);
      await dispatch(
        insertParticipant({ formEducaId: formEducaId, participante: values })
      ).unwrap();
      notification.success({
        message: 'Participante adicionado com sucesso!',
      });
      onCancel(); // Fechar o modal
    } catch (error) {
      console.error('Failed to add participant:', error);
      if (error instanceof Error) {
        notification.error({
          message: 'Erro ao adicionar participante',
          description: error.message,
        });
      } else {
        notification.error({
          message: 'Erro ao adicionar participante',
          description: 'Erro desconhecido',
        });
      }
    }
  };

  return (
    <CustomModal
      visible={visible}
      title='Adicionar Participante'
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk}>
          Adicionar
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical'>
        <Form.Item name='nome' label='Nome'>
          <Input />
        </Form.Item>
        <Form.Item name='cpf' label='CPF'>
          <Input />
        </Form.Item>
        <Form.Item name='email' label='Email'>
          <Input type='email' />
        </Form.Item>
        <Form.Item name='telefone' label='Telefone'>
          <Input />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default AddParticipantModal;
