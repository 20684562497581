import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import EditFormCustom from '../features/FormCustom/EditFormCustom';

export default function FormGrafEditView() {
  usePageTitle('Editando formulário');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formCustom'>
        <EditFormCustom />
      </ErrorBoundary>
    </>
  );
}
