import { useCallback, useState } from 'react';
import { Recinto } from '../../sdk/@types/Recinto';
import RecintoIDBService from '../../sdk/services/indexeddb/RecintoIDB.service';
import RecintoService from '../../sdk/services/SIZ-API/Recinto.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useRecinto() {
  const { online } = useNavigatorStatus();
  const [recinto, setRecinto] = useState<Recinto.Summary | null>();
  const [listaRecintos, setListaRecintos] = useState<Recinto.Summary[]>();
  const [fetchingRecinto, setFetchingRecinto] = useState<boolean>(false);

  const fetchRecintoByCodigo = useCallback(
    async (recintoId: number, fromApi?: boolean) => {
      try {
        setFetchingRecinto(true);

        if (!fromApi) {
          return await RecintoIDBService.getByCodigo(recintoId).then(
            async (payload) => {
              if (payload) {
                setRecinto(payload?.payload);
              } else if (online) {
                return await RecintoService.getByCodigo(recintoId).then(
                  setRecinto
                );
              } else {
                setRecinto(null);
              }
            }
          );
        } else {
          await RecintoService.getByCodigo(recintoId).then(setRecinto);
        }
      } catch (err) {
      } finally {
        setFetchingRecinto(false);
      }
    },
    [online]
  );

  const fetchRecintosByMunicipio = useCallback((codgIbge: string) => {
    try {
      setFetchingRecinto(true);
      return RecintoService.getByMunicipio(codgIbge).then(setListaRecintos);
    } finally {
      setFetchingRecinto(false);
    }
  }, []);

  return {
    recinto,
    fetchingRecinto,
    listaRecintos,
    fetchRecintoByCodigo,
    fetchRecintosByMunicipio,
    setFetchingRecinto,
  };
}
