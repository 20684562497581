import { useEffect } from 'react';
import { Card, Descriptions, Row, Typography } from 'antd';
import { FormEduca } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';
import { TipoAcaoEducativa } from '../../../core/enums/TipoAcaoEducativa';
import { TipoLocalAcaoEducativa } from '../../../core/enums/TipoLocalAcaoEducativaSelect';
import { ModalidadeAcaoEducativa } from '../../../core/enums/ModalidadeAcaoEducativa';

type PrintFormEducaProps = {
  educa?: FormEduca.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintFormEduca(props: PrintFormEducaProps) {
  useEffect(() => {}, [props.educa]);

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item) => (
                <Descriptions.Item
                  key={item.value}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO PARA REGISTRO DE ATIVIDADES DE EDUCAÇÃO SANITÁRIA E
                COMUNICAÇÃO SOCIAL
              </Typography.Title>
            </Row>
            <br />

            {panelDescription('Data da Ação', [
              {
                value: moment(props.educa?.dataAcao).format('DD/MM/YYYY HH:mm'),
              },
            ])}
            <br />
            {panelDescription('Nome', [
              {
                value: props.educa?.nome,
              },
            ])}
            <br />
            {props.educa?.localAcaoEducativa &&
              panelDescription('Local da Ação Educativa', [
                {
                  label: 'Nome',
                  value: props.educa?.localAcaoEducativa.nome,
                },
                {
                  label: 'Status',
                  value: props.educa?.localAcaoEducativa.status,
                },
                {
                  label: 'Descrição',
                  value: props.educa?.localAcaoEducativa.descricao,
                },

                {
                  label: 'Tipo local acão educativa',
                  value: TipoLocalAcaoEducativa.valueOf(
                    props.educa?.localAcaoEducativa.tipoLocalAcaoEducativa
                  ) as string,
                },
              ])}
            <br />
            {panelDescription('Modalidade da Ação Educativa', [
              {
                value: ModalidadeAcaoEducativa.valueOf(props.educa?.modalidadeAcaoEducativa || '') as string,
              },
            ])}
            <br />
            {panelDescription('Tipo da Ação Educativa', [
              {
                value: TipoAcaoEducativa?.valueOf(
                  props.educa?.tipoAcaoEducativa || ''
                ) as string,
              },
            ])}
            <br />
            {props.educa?.municipio &&
              panelDescription('Município', [
                {
                  label: 'Nome',
                  value: props.educa.municipio.nome,
                  span: 2,
                },
                {
                  label: 'UF',
                  value: props.educa.municipio.uf.nome,
                },
                {
                  label: 'Sigla',
                  value: props.educa.municipio.uf.sigla,
                },
              ])}
            <br />
            {props.educa?.servidores &&
              panelComponent(
                'Servidores',
                props.educa?.servidores?.map((chavePrimaria) =>
                  panelDescription('', [
                    {
                      label: 'Nome',
                      value: chavePrimaria.pessoa.nome,
                    },
                    {
                      label: 'CPF',
                      value: chavePrimaria.pessoa?.cpfCnpj?.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4'
                      ),
                      span: 2,
                    },

                    {
                      label: 'Matrícula',
                      value: chavePrimaria?.matricula,
                    },
                    {
                      label: 'Profissão',
                      value: chavePrimaria.profissao?.nome,
                    },
                  ])
                )
              )}
            <br />
            {panelComponent(
              'Programas e Atividades',
              props.educa?.tiposChavesPrincipais
                ? props.educa.tiposChavesPrincipais.map(
                    (chavePrimaria, index) => (
                      <div className='newPage' key={`chave-primaria-${index}`}>
                        {chavePrimaria?.nome}
                      </div>
                    )
                  )
                : null
            )}
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
