import { Col, Divider, Form, FormInstance, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import VeterinarioPanel from '../../components/VeterinarioPanel';
import { useCallback, useState } from 'react';
import { SimNao } from '../../../core/enums/SimNao';

type VigilanciaOutrasInformacoesProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaOutrasInformacoes(
  props: VigilanciaOutrasInformacoesProps
) {
  const [statusRadioEmissaoDeFormIN, setStatusRadioEmissaoDeFormIN] = useState<
    boolean | undefined
  >();

  const handleChangeStatusRadioEmissaoDeFormIN = useCallback(
    (status: boolean | undefined) => {
      let simNao;

      if (status === undefined) {
        simNao = undefined;
      } else if (status) {
        simNao = SimNao.keyOf(SimNao.SIM);
      } else {
        simNao = SimNao.keyOf(SimNao.NAO);
      }

      setStatusRadioEmissaoDeFormIN(status);

      props.form.setFieldValue('emissaoDeFormIN', simNao);
    },
    [props.form]
  );

  return (
    <>
      <Divider orientation='left'>Outras informações</Divider>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Form.Item label={'Observação'} name={'observacao'}>
            <TextArea showCount rows={5} maxLength={4000} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Houve emissão de Form IN?'}
            name={'emissaoDeFormIN'}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusRadioEmissaoDeFormIN}
              onChange={(e) => {
                handleChangeStatusRadioEmissaoDeFormIN(e.target.value);
              }}
              defaultValue={
                !props.form.getFieldValue('emissaoDeFormIN')
                  ? undefined
                  : props.form.getFieldValue('emissaoDeFormIN') === 'SIM'
                  ? true
                  : false
              }
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <VeterinarioPanel
        form={props.form}
        formDisabled={props.formDisabled ? true : false}
      />
    </>
  );
}
