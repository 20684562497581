import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateEDUCA from '../features/FormEduca/CreateEDUCA';

export default function EDUCACreateView() {
  usePageTitle('Novo Formulário para Registro de Atividades de Educação Sanitária e Comunicação Social');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateEDUCA />
      </ErrorBoundary>
    </>
  );
}
