import { NotificacaoSistema } from '../../@types/NotificacaoSistema';
import Service from './Service';

class NotificacaoSistemaService extends Service {
  static getAll() {
    return this.Http.get<NotificacaoSistema.Response[]>(
      `notificacoes-sistema/`
    ).then(this.getData);
  }

  static async getByUsuario(
    username: string
  ): Promise<NotificacaoSistema.Response[]> {
    return await this.Http.get<NotificacaoSistema.Response[]>(
      `/usuarios/${username}/notificacoes-sistema`
    ).then(this.getData);
  }

  static add(notificacao: NotificacaoSistema.Request) {
    return this.Http.post<NotificacaoSistema.Request>(
      '/notificacoes-sistema',
      notificacao
    ).then(this.getData);
  }

  static markAsRead(username: string, notificacaoSistemaId: number) {
    return this.Http.post<NotificacaoSistema.Request>(
      `/usuarios/${username}/notificacoes-sistema/${notificacaoSistemaId}`
    ).then(this.getData);
  }
}

export default NotificacaoSistemaService;
