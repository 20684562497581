import fallback from '../../assets/fallback-signature-canvas.png';

import {
  ArrowLeftOutlined,
  BackwardOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import useAssinaturaBase64Image from '../../core/hooks/useAssinaturaBase64Image';
//@ts-ignore
import trimCanvas from 'trim-canvas';

export default function SignatureWrapper(props: {
  onSaveSignature: () => any;
  onClearSignature: () => any;
  disabled: boolean;
  default?: string;
}) {
  const [showModal, setShowModal] = useState(false);
  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();

  const [assinaturaBase64ForComponent, setAssinaturaBase64ForComponent] =
    useState<string | undefined>(undefined);

  let refSigCanvas = useRef<SignatureCanvas>(null);

  window.screen.orientation.onchange = (e) => {
    if (showModal)
      notification.info({
        message: 'A assinatura é reiniciada quando o dispositivo é rotacionado',
      });
  };

  useEffect(() => {
    if (props.default) {
      setAssinaturaBase64ForComponent(props.default);
      refSigCanvas.current?.fromDataURL(props.default);
    }
  }, [props.default]);

  useEffect(() => {}, [assinaturaBase64Image, refSigCanvas]);

  const clearSignatureCanvas = () => {
    refSigCanvas.current?.clear();
    setAssinaturaBase64Image(undefined);
    setAssinaturaBase64ForComponent(undefined);
    props.onClearSignature();
  };

  const saveAssinatura = useCallback(() => {
    if (refSigCanvas.current?.isEmpty()) return;

    setAssinaturaBase64Image(
      trimCanvas(refSigCanvas.current?.getTrimmedCanvas()).toDataURL(
        'image/png'
      )
    );
    setAssinaturaBase64ForComponent(
      refSigCanvas.current?.getCanvas().toDataURL('image/png')
    );
  }, [refSigCanvas, setAssinaturaBase64Image]);

  const canvas = () => {
    return (
      <Row style={{ height: '100%' }}>
        <Col xs={24} lg={24} style={{ height: '100%' }}>
          <div
            id='signature-canvas-border'
            style={{
              display: 'block',
              backgroundColor: 'white',
              border: '1px solid',
              height: '100%',
            }}
          >
            <div
              id='signature-canvas-wrapper'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                padding: '12px',
              }}
            >
              <Row gutter={8} align='middle' justify='end'>
                <Col>
                  <DeleteTwoTone
                    onClick={() => clearSignatureCanvas()}
                    style={{
                      fontSize: '24px',
                    }}
                    twoToneColor={'#ff4d4f'}
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    type='primary'
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </div>

            <div
              id='signature-canvas-wrapper'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                padding: '12px',
              }}
            >
              <Row gutter={8} align='middle' justify='start'>
                <ArrowLeftOutlined
                  onClick={() => setShowModal(false)}
                  style={{
                    fontSize: '24px',
                    padding: '5px',
                    zIndex: 2,
                  }}
                />
              </Row>
            </div>

            <SignatureCanvas
              canvasProps={{
                className: 'sigCanvas',
              }}
              clearOnResize
              onEnd={() => {
                saveAssinatura();
              }}
              ref={refSigCanvas}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const image = () => {
    return (
      <Row style={{ height: '100%' }}>
        <Col xs={24} lg={24} style={{ height: '100%' }}>
          <div
            id='signature-canvas-border'
            style={{
              display: 'block',
              backgroundColor: 'white',
              border: '1px solid',
              height: '500px',
            }}
          >
            <div
              id='signature-canvas-wrapper'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                padding: '8px',
              }}
            >
              <div>
                <DeleteTwoTone
                  onClick={() => clearSignatureCanvas()}
                  style={{
                    fontSize: '24px',
                    padding: '5px',
                    zIndex: 2,
                  }}
                  twoToneColor={'#ff4d4f'}
                />
              </div>
            </div>

            <img
              src={
                assinaturaBase64Image ? assinaturaBase64ForComponent : fallback
              }
              alt=''
              style={{
                display: 'block',
                margin: '0 auto',
                width: '100%',
                height: '100%',
              }}
              onClick={() => setShowModal(true)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Space
        direction={'vertical'}
        style={{ maxWidth: '100%', width: '100%' }}
        size={8}
      >
        {image()}
      </Space>

      <Modal
        bodyStyle={{ height: '100vh' }}
        centered
        closable={false}
        destroyOnClose
        footer={null}
        /* keyboard={false} */
        maskClosable={false}
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        title={null}
        width={'100%'}
      >
        {canvas()}
      </Modal>
    </>
  );
}
