import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../core/store';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import moment from 'moment';
import * as TFRActions from '../../core/store/TFR.slice';
import { TermoFiscalizacao } from '../../sdk/@types';

interface TFRPanelProps {
  form: FormInstance;
  formDisabled: boolean;
  selectTFR: TermoFiscalizacao.Summary;
  setSelectTFR: (selectTFR: TermoFiscalizacao.Summary) => void;
}

export default function TFRPanel(props: TFRPanelProps) {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const { mode, setLoading } = useLoadingPage();
  const [fetching, setFetching] = useState<boolean>(false);
  const query = useSelector((state: RootState) => state.formTfr.query);
  const [searchResult, setSearchResult] =
    useState<TermoFiscalizacao.Summary | null>(null);

  const fetchTFRByNumero = useCallback(
    async (numero: string) => {
      if (mode === 'VIEW' || !numero) return;
      setLoading(true);
      try {
        dispatch(TFRActions.setQuery({ ...query, numero: numero }));
        const result = await dispatch(
          TFRActions.filterTFRs({ ...query, numero })
        ).unwrap();
        if (result?.content && result.content.length > 0) {
          const tfr = result.content[0];
          setSearchResult(tfr);
          props.form.setFieldsValue({
            termoFiscalizacao: {
              id: tfr.id,
              numero: tfr.numero,
              codigoVerificador: tfr.codigoVerificador,
              dataVisita: tfr.dataVisita,
              nomeFiscalizado: tfr.nomeFiscalizado,
              municipioFiscalizado: {
                id: tfr.municipioFiscalizado?.id,
                codgIBGE: tfr.municipioFiscalizado?.codgIBGE,
                nome: tfr.municipioFiscalizado?.nome,
                uf: {
                  id: tfr.municipioFiscalizado?.id,
                  nome: tfr.municipioFiscalizado?.nome,
                  sigla: tfr.municipioFiscalizado?.uf?.sigla,
                },
              },
            },
          });

          notification.success({
            message: `Termo de Fiscalização ${tfr.nomeFiscalizado} encontrado com sucesso`,
          });
        } else {
          setSearchResult(null);
          notification.info({
            message: 'Erro ao buscar Termo de Fiscalização',
            description:
              'Não foi possível encontrar o termo de fiscalização pelo número fornecido.',
          });
        }
      } catch (error) {
        console.error('Erro ao buscar Termo de Fiscalização:', error);
        notification.info({
          message: 'Erro ao buscar Termo de Fiscalização',
          description:
            'Não foi possível encontrar o termo de fiscalização pelo número fornecido.',
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setLoading, mode, query, props.form]
  );

  const removeTFR = useCallback(() => {
    setSearchResult(null);
    props.form.resetFields(['termoFiscalizacao']);
    props.setSelectTFR({} as TermoFiscalizacao.Summary);
  }, [props]);

  const tfrData = props.selectTFR || searchResult;

  useEffect(() => {
    if (props.selectTFR) {
      props.form.setFieldsValue({ termoFiscalizacao: props.selectTFR });
    }
  }, [props.selectTFR, props.form]);

  return (
    <>
      <Divider orientation='left'>Termo de Fiscalização</Divider>

      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Form.Item
            label={'Número'}
            name={['termoFiscalizacao', 'numero']}
            validateTrigger={'onSearch'}
          >
            <Input.Search
              placeholder={'Informe o número do termo'}
              disabled={props.formDisabled}
              autoComplete='off'
              allowClear={false}
              loading={fetching}
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeTFR}
                />
              }
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onSearch={(value) => {
                if (!value) return;

                fetchTFRByNumero(value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {tfrData ? (
        <Card
          title={
            <Row justify='space-between'>
              <Typography.Title level={4}>{'TFR'}</Typography.Title>
            </Row>
          }
        >
          <Descriptions column={3} labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item label='Número' span={1}>
              {tfrData.numero}
            </Descriptions.Item>

            <Descriptions.Item label='Data' span={2}>
              {tfrData.dataVisita
                ? moment(tfrData.dataVisita).format('DD/MM/YYYY HH:mm')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Nome Fiscalizado' span={3}>
              {tfrData.nomeFiscalizado}
            </Descriptions.Item>
            <Descriptions.Item label='Município Fiscalizado' span={3}>
              {tfrData.municipioFiscalizado?.nome} -{' '}
              {tfrData.municipioFiscalizado?.uf.sigla}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ) : (
        <Card
          title={
            <Row justify='space-between'>
              <Typography.Title level={4}>{'TFR'}</Typography.Title>
            </Row>
          }
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
      )}
    </>
  );
}
