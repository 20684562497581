import {
  Button,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';

import { FormOutlined } from '@ant-design/icons';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {FormularioRespostas } from '../../../sdk/@types';
import { Link } from 'react-router-dom';
import { CustomModal } from '../../components/CustomModal';
import * as FormCustomActions from '../../../core/store/FormResposta.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';

export default function ListFormCustom() {
  const [deleting] = useState(false);
  const { xs } = useBreakpoint();

  const [modalSummaryVisible, setModalSummaryVisible] = useState(false);

  const [FormCustomsSummary, setFormCustomsSummary] =
    useState<FormularioRespostas.Summary>();

  const [error] = useState<Error>();
  const dispatch = useDispatch();

  const formularios = useSelector(
    (state: RootState) => state.formRespostas.allForms
  );
  const query = useSelector((state: RootState) => state.formRespostas.query);
  const fetching = useSelector(
    (state: RootState) => state.formRespostas.fetching
  );

  if (!window.navigator.onLine)
    throw new Error(
      'Não é possível visualizar a lista de formulários no modo offline'
    );
  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, []);

  useEffect(() => {
    dispatch(FormCustomActions.filterFormCustomsRespostas(query));
  }, [dispatch, query]);

  return (
    <>
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FormularioRespostas.Summary>
                rowKey={'id'}
                dataSource={formularios as FormularioRespostas.Summary[]}
                loading={deleting || fetching}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Lista de Formulários
                      </Typography.Title>
                    </Row>
                  );
                }}
                size={'small'}
                showHeader={false}
                columns={[
                  {
                    dataIndex: 'nome',
                    title: 'Nome',
                    width: 100,
                    responsive: ['xs', 'sm'],
                    render(nome: FormularioRespostas.Summary['formulario']) {
                      return nome;
                    },
                  },
                  {
                    dataIndex: 'id',
                    title: 'Ações',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Link to={`/formRespostas/cadastro/${id}`}>
                            <Button
                              icon={<FormOutlined />}
                              size={'small'}
                              type={'ghost'}
                            >
                              Responder Formulário
                            </Button>
                          </Link>
                        </Space>
                      );
                    },
                  },
                ]}
                onRow={(record, index) => {
                  return {
                    onClick: (e) => {
                      if (!xs) return;

                      setModalSummaryVisible(true);
                      setFormCustomsSummary(record);
                    },
                  };
                }}
              />
              <CustomModal
                centered
                open={modalSummaryVisible}
                title={`Formulário nº ${FormCustomsSummary?.id}/${
                  FormCustomsSummary?.id ? FormCustomsSummary?.id : 's/n'
                }`}
                closable={false}
                onCancel={() => {
                  setModalSummaryVisible(false);
                  setFormCustomsSummary(undefined);
                }}
                maskClosable={true}
                keyboard={true}
                footer={
                  <Button
                    type='primary'
                    onDoubleClick={() => {
                      setModalSummaryVisible(false);
                      setFormCustomsSummary(undefined);
                    }}
                  >
                    Fechar
                  </Button>
                }
              >
                {FormCustomsSummary && (
                  <Descriptions size={'small'}>
                    <Descriptions.Item label={'Formulários'}>
                      {FormCustomsSummary.id}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Nome'}>
                      <Tooltip title={FormCustomsSummary.formulario?.nome}>
                        {FormCustomsSummary.formulario?.nome}
                      </Tooltip>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </CustomModal>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
