import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';
import { FVER, Produtor } from '../../@types';
import { FVERFormulariosAnexos } from '../../@types/FVERFormulariosAnexos';
import generateQueryString from '../../utils/generateQueryString';
import PropriedadeIDBService from '../indexeddb/PropriedadeIDB.service';
import Service from './Service';

class FVERService extends Service {
  static getAll(query: FVER.Query): Promise<FVER.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<FVER.Paginated>('/visitas'.concat(queryString)).then(
      this.getData
    );
  }

  static async add(fver: FVER.Input) {
    let fverDTO: FVER.Input = {
      ...fver,
    };

    if (fverDTO.propriedade) {
      let proprietario: Produtor.Input | undefined = undefined;

      if (!fverDTO.proprietario!.id) {
        const listaPropriedade = await PropriedadeIDBService.getByProdutorCpf(
          fverDTO.proprietario!.cpfCnpj
        );

        console.log('1');

        listaPropriedade.forEach((propriedade) => {
          console.log('2');
          propriedade.exploracaos?.forEach((exploracao) => {
            console.log('3');
            exploracao.produtores?.forEach((produtor, i) => {
              console.log('4');
              if (
                removeNonNumericCharactersFromString(
                  produtor.produtor.cpfCnpj
                ) ===
                removeNonNumericCharactersFromString(
                  fverDTO.proprietario!.cpfCnpj
                )
              ) {
                proprietario = {
                  cpfCnpj: produtor.produtor.cpfCnpj,
                  //@ts-ignore
                  email: produtor.produtor.email,
                  //@ts-ignore
                  endereco: produtor.produtor.endereco,
                  nome: produtor.produtor.nome,
                  id: produtor.produtor.id,
                };

                console.log('5', proprietario);
              }
            });
          });
        });

        if (!proprietario) {
          console.log('5.5');

          //proprietario = await PropriedadeService.f
        }
      } else {
        console.log('6');
        proprietario = fverDTO.proprietario;
      }

      console.log('7', proprietario);

      fverDTO = {
        ...fverDTO,
        propriedade: {
          ...fverDTO.propriedade,
          municipio: {
            ...fverDTO.propriedade.municipio,
            codgIBGE: String(fverDTO.propriedade.municipio.id),
          },
        },
        proprietario: proprietario,
      };
    }

    if (fverDTO.abatedouro && fverDTO.abatedouro.pessoa.endereco) {
      fverDTO = {
        ...fverDTO,
        abatedouro: {
          ...fverDTO.abatedouro,
          pessoa: {
            ...fverDTO.abatedouro.pessoa,
            endereco: {
              ...fverDTO.abatedouro.pessoa.endereco,
              municipio: {
                ...fverDTO.abatedouro.pessoa.endereco.municipio,
                codgIBGE: String(
                  fverDTO.abatedouro.pessoa.endereco.municipio.id
                ),
              },
            },
          },
        },
      };
    }

    if (fverDTO.recinto) {
      fverDTO = {
        ...fverDTO,
        recinto: {
          ...fverDTO.recinto,
          municipio: {
            ...fverDTO.recinto.municipio,
            codgIBGE: String(fverDTO.recinto.municipio.id),
          },
        },
      };
    }

    return this.Http.post<FVER.Input>('/visitas', fverDTO).then(this.getData);
  }

  static cancel(fverId: number, motivo: string) {
    return this.Http.post<FVER.Input>(`/visitas/${fverId}/cancelar`, {
      motivo: motivo,
    }).then(this.getData);
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`/visitas/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get<FVER.Input>(`/visitas/${id}`).then(this.getData);
  }

  static getByNumero(numero: string) {
    return this.Http.get<FVER.Input>(`/visitas/numero/${numero}`).then(
      this.getData
    );
  }

  static getByMunicipio(codgIBGE: string, dataIni: string, dataFim: string) {
    const queryString = generateQueryString({
      dataIni,
      dataFim,
    });
    return this.Http.get<FVER.Summary[]>(
      `/visitas/municipio/${codgIBGE}`.concat(queryString)
    ).then(this.getData);
  }

  static getFormulariosAssociados(id: number) {
    return this.Http.get<FVERFormulariosAnexos.Response>(
      `/visitas/${id}/formularios-associados`
    ).then(this.getData);
  }

  static getByCodigoVerificador(codigoVerificador: string) {
    const queryString = generateQueryString({
      codigoVerificador,
    });
    return this.Http.get<FVER.Paginated>(`/visitas`.concat(queryString)).then(
      this.getData
    );
  }

  static getByCodigoVerificador_PublicAccess(codigoVerificador: string) {
    return this.Http.get<FVER.Detailed>(
      `/visitas/codigo/${codigoVerificador}`
    ).then(this.getData);
  }

  static getByDataCadastro(dataCadastro: string) {
    const queryString = generateQueryString({
      dataCadastro,
    });
    return this.Http.get<FVER.Detailed[]>(
      `/visitas/data-cadastro`.concat(queryString)
    ).then(this.getData);
  }

  static getAll_PublicAccess(query: FVER.QueryPublic): Promise<FVER.Paginated> {
    return fetch(
      `${Service.API_URL}/visitas/publico`.concat(generateQueryString(query)),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(async (response) => {
      if (response.ok) return await response.json();
    });
  }
}

export default FVERService;
