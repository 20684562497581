import { Form, Space } from 'antd';
import { useState } from 'react';
import ReactGeolocatedLocationTest from './ReactGeolocatedLocationTest';
import NavigatorGeolocationTest from './NavigatorGeolocationTest';
import { GoBackButton } from '../../components/GoBackButton';
import CoordenadasPanel from '../../components/CoordenadasPanel';

export default function LocationTest() {
  const [
    reactGeolocatedLocationVersionControl,
    setReactGeolocatedLocationVersionControl,
  ] = useState(1);
  const [
    navigatorGeolocationTestVersionControl,
    setNavigatorGeolocationTestVersionControl,
  ] = useState(1);

  const [form] = Form.useForm<any>();

  return (
    <>
      <Space direction='vertical' size={24} style={{ width: '100%' }}>
        <GoBackButton label='Home' to='/' />

        <ReactGeolocatedLocationTest
          key={'RG' + reactGeolocatedLocationVersionControl}
          reset={() => {
            setReactGeolocatedLocationVersionControl(Math.random());
          }}
        />

        <NavigatorGeolocationTest
          key={'NG' + navigatorGeolocationTestVersionControl}
          reset={() => {
            setNavigatorGeolocationTestVersionControl(Math.random());
          }}
        />

        <CoordenadasPanel
          form={form}
          formDisabled={false}
          onAcceptCoords={() => {}}
          pathToCoordenada={[]}
        />
      </Space>
    </>
  );
}
