import jwtDecode from 'jwt-decode';
import localforage from 'localforage';
import { Usuario } from '../../sdk/@types/Usuario';
import { UsuarioOAuth } from '../../sdk/@types/UsuarioOAuth';
import EnvConfig from '../../app/EnvConfig';

export enum AuthorizationEventType {
  AUTHORIZATION_CHANGED = 'Autorização atualizada',
}

export default class AuthorizationService {
  public static ACCESS_TOKEN_LOCAL_STORAGE_NAME = 'accessToken';

  public static REFRESH_TOKEN_LOCAL_STORAGE_NAME = 'refreshToken';

  public static CODE_VERIFIER_LOCAL_STORAGE_NAME = 'codeVerifierToken';

  public static USUARIO_VINCULADO_LOCAL_STORAGE_NAME = 'usuarioVinculado';

  public static USUARIO_SIZ_VINCULADO_LOCAL_STORAGE_NAME = 'usuarioSIZ';

  public static USUARIO_X_VIA_VINCULADO_LOCAL_STORAGE_NAME = 'usuarioXVia';

  static APP_BASE_URL = EnvConfig.baseUrl();

  static AUTH_BASE_URL =
    'https://login.mt.gov.br/auth/realms/mt-realm/protocol/openid-connect';

  public static URL_LOGIN = `${this.AUTH_BASE_URL}/auth?client_id=indea-syz&redirect_uri=${this.APP_BASE_URL}/auth&response_type=code`;

  public static URL_TOKEN = `${this.AUTH_BASE_URL}/token`;

  public static URL_USER_INFO = `${this.AUTH_BASE_URL}/userinfo`;
  public static URL_LOGOUT = `${this.AUTH_BASE_URL}/logout?client_id=indea-syz&redirect_uri=${this.APP_BASE_URL}/logout&response_type=code`;

  public static config = {
    grant_type: 'authorization_code',
    client_id: 'indea-syz',
    redirect_uri: `${this.APP_BASE_URL}/auth`,
  };

  public static async renewToken(config: {
    refreshToken: string;
    codeVerifier: string;
  }) {
    return await fetch(this.URL_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        refresh_token: config.refreshToken,
        grant_type: 'refresh_token',
        client_id: this.config.client_id,
        code: config.codeVerifier,
      }),
      //@ts-ignore
    }).then((res) => res.json());
  }

  public static async getFirstAccessToken(code: string) {
    return await fetch(this.URL_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: this.config.grant_type,
        client_id: this.config.client_id,
        code: code,
        redirect_uri: this.config.redirect_uri,
      }),
    });
  }

  public static async fetchUsuarioXVia() {
    if (AuthorizationService.getAccessToken()) {
      return await fetch(this.URL_USER_INFO, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: new URLSearchParams({
          access_token: AuthorizationService.getAccessToken() || '',
        }),
      })
        .then(async (data) => data.json())
        .then(async (data) => {
          AuthorizationService.setUsuarioXVia(JSON.stringify(data));
        });
    } else {
      return undefined;
    }
  }

  public static logout(options?: { expired?: boolean; error?: boolean }) {
    localStorage.clear();
    localforage.clear();
    if (options?.expired) window.location.href = '/?expired=true';
    else if (options?.error) window.location.href = '/?error=true';
    else {
      window.location.replace(AuthorizationService.URL_LOGOUT);
    }
  }

  public static getAccessToken() {
    return window.localStorage.getItem(this.ACCESS_TOKEN_LOCAL_STORAGE_NAME);
  }

  public static setAccessToken(token: string) {
    if (token)
      window.localStorage.setItem(this.ACCESS_TOKEN_LOCAL_STORAGE_NAME, token);
    else window.localStorage.removeItem(this.ACCESS_TOKEN_LOCAL_STORAGE_NAME);
  }

  public static getCodeVerifier() {
    return window.localStorage.getItem(this.CODE_VERIFIER_LOCAL_STORAGE_NAME);
  }

  public static setCodeVerifier(codeVerifier: string) {
    if (codeVerifier)
      return window.localStorage.setItem(
        this.CODE_VERIFIER_LOCAL_STORAGE_NAME,
        codeVerifier
      );
    else
      return window.localStorage.removeItem(
        this.CODE_VERIFIER_LOCAL_STORAGE_NAME
      );
  }

  public static getRefreshToken() {
    return window.localStorage.getItem(this.REFRESH_TOKEN_LOCAL_STORAGE_NAME);
  }

  public static setRefreshToken(refreshToken: string) {
    if (refreshToken)
      return window.localStorage.setItem(
        this.REFRESH_TOKEN_LOCAL_STORAGE_NAME,
        refreshToken
      );
    else
      return window.localStorage.removeItem(
        this.REFRESH_TOKEN_LOCAL_STORAGE_NAME
      );
  }

  public static getUsuarioXVia(): UsuarioOAuth | null {
    const usuario = window.localStorage.getItem(
      this.USUARIO_X_VIA_VINCULADO_LOCAL_STORAGE_NAME
    );
    let usuarioOAuth: UsuarioOAuth | null = null;
    if (usuario) usuarioOAuth = JSON.parse(usuario);
    return usuarioOAuth;
  }

  public static setUsuarioXVia(usuario: string) {
    if (usuario)
      return window.localStorage.setItem(
        this.USUARIO_X_VIA_VINCULADO_LOCAL_STORAGE_NAME,
        usuario
      );
    else
      return window.localStorage.removeItem(
        this.USUARIO_X_VIA_VINCULADO_LOCAL_STORAGE_NAME
      );
  }

  public static getUsuarioSIZ(): Usuario.Detailed | null {
    const usuario = window.localStorage.getItem(
      this.USUARIO_SIZ_VINCULADO_LOCAL_STORAGE_NAME
    );
    let usuarioSIZ: Usuario.Detailed | null = null;
    if (usuario) usuarioSIZ = JSON.parse(usuario);
    return usuarioSIZ;
  }

  public static setUsuarioSIZ(usuario: Usuario.Detailed) {
    if (usuario)
      window.localStorage.setItem(
        this.USUARIO_SIZ_VINCULADO_LOCAL_STORAGE_NAME,
        JSON.stringify(usuario)
      );
    else
      window.localStorage.removeItem(
        this.USUARIO_SIZ_VINCULADO_LOCAL_STORAGE_NAME
      );
    window.dispatchEvent(
      new Event(AuthorizationEventType.AUTHORIZATION_CHANGED)
    );
  }

  public static setUsuarioVinculado(usuarioVinculado: boolean) {
    return window.localStorage.setItem(
      this.USUARIO_VINCULADO_LOCAL_STORAGE_NAME,
      JSON.stringify(usuarioVinculado)
    );
  }

  public static isUsuarioVinculado(): boolean {
    const usuarioVinculadoLocalStorage = window.localStorage.getItem(
      this.USUARIO_VINCULADO_LOCAL_STORAGE_NAME
    );

    const usuarioSIZ = AuthorizationService.getUsuarioSIZ();

    if (usuarioSIZ) {
      return usuarioSIZ.vinculadoMTCidadao;
    } else {
      if (usuarioVinculadoLocalStorage) {
        return JSON.parse(usuarioVinculadoLocalStorage);
      } else {
        return true;
      }
    }
  }

  public static isAccessTokenExpired(): boolean {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const decodedAccesTokenJwt = jwtDecode(accessToken);
      if (!decodedAccesTokenJwt) return true;

      //@ts-ignore
      if (new Date(decodedAccesTokenJwt.exp * 1000) < Date.now()) {
        return false;
      } else return true;
    } else {
      return true;
    }
  }

  public static isRefreshTokenExpired(): boolean {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const decodedAccesTokenJwt = jwtDecode(refreshToken);
      if (!decodedAccesTokenJwt) return true;

      //@ts-ignore
      if (new Date(decodedAccesTokenJwt.exp * 1000) < Date.now()) {
        return false;
      } else return true;
    } else {
      return true;
    }
  }

  public static isAuthenticated(): boolean {
    // :todo
    if (this.getAccessToken() && this.getUsuarioSIZ() !== null) return true;
    else return false;
  }
}
