export const formatCpf_Cnpj = (cpfCnpj) => {
  if (!cpfCnpj) return '';

  cpfCnpj = cpfCnpj.replace(/\D/g, '');

  if (cpfCnpj.length <= 11) {
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    cpfCnpj = cpfCnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cpfCnpj = cpfCnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cpfCnpj = cpfCnpj.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return cpfCnpj;
};
