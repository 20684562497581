import React, { useState } from 'react';
import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Typography,
} from 'antd';

import TextArea from 'antd/lib/input/TextArea';

type CustomCardProps = {
  title: string;
  name: string;
  specify: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChange: (
    name: string,
    value: string,
    specify: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  specify,
  formValue,
  formDisable,
  updatedValue,
  handleChange,
}) => {
  return (
    <Card title={title}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChange(name, e.target.value, specify, updatedValue)
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
                <Radio value='NA'>Não se Aplica (N.A)</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            {formValue === 'NAO' && (
              <Form.Item
                label={'Especificar'}
                name={specify}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea
                  showCount
                  rows={5}
                  maxLength={2000}
                  disabled={formDisable}
                />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};
export default function InstalacoesEquipamentos(
  props: InformacoesCadastroProps
) {
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const handleChange = (name: string, value: string, specify: string) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [specify]: undefined,
      });
    }
  };

  return (
    <>
      <Typography.Title level={5}>Instalações e equipamentos</Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1'
            title='5.1 - As instalações foram construidas com materiais que permitem limpeza e desinfecção adequadas?'
            name='instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1'
            specify='instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1'
            formValue={props.form.getFieldValue(
              'instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1'
            )}
            updatedValue={
              updatedValues[
                'instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='pisoFeitoTerraCompactadaOuCimentado_5_2'
            title='5.2 - O piso do(s) aviário(s) é feito de terra compactada ou cimentado ?'
            name='pisoFeitoTerraCompactadaOuCimentado_5_2'
            specify='pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2'
            formValue={props.form.getFieldValue(
              'pisoFeitoTerraCompactadaOuCimentado_5_2'
            )}
            updatedValue={
              updatedValues['pisoFeitoTerraCompactadaOuCimentado_5_2']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='muretaDeAlvenariaAdequadoParaLimpeza_5_3'
            title='5.3 - A mureta é de alvenaria com acabamento adequado a limpeza e desinfecção ?'
            name='muretaDeAlvenariaAdequadoParaLimpeza_5_3'
            specify='muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3'
            formValue={props.form.getFieldValue(
              'muretaDeAlvenariaAdequadoParaLimpeza_5_3'
            )}
            updatedValue={
              updatedValues['muretaDeAlvenariaAdequadoParaLimpeza_5_3']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='malhaDaTelaDosAviariosAteUmaPolegada_5_4'
            title='5.4 - A malha da tela dos aviários é igual ou menor que 1 (uma) polegada ou 2, 54cm (dois centímetros e cinquenta e quatro milímetros)?'
            name='malhaDaTelaDosAviariosAteUmaPolegada_5_4'
            specify='malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4'
            formValue={props.form.getFieldValue(
              'malhaDaTelaDosAviariosAteUmaPolegada_5_4'
            )}
            updatedValue={
              updatedValues['malhaDaTelaDosAviariosAteUmaPolegada_5_4']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5'
            title='5.5 - A cobertura do telhado é feita com material impermeável e que permite limpeza e desinfecção adequada?'
            name='coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5'
            specify='coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5'
            formValue={props.form.getFieldValue(
              'coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5'
            )}
            updatedValue={
              updatedValues['coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='possuemNebulizadoresEOuSistemaDeVentilacao_5_6'
            title='5.6 - O(s) aviário(s) possuem nebulizadores e/ou sistema de ventilação que garantam no seu interior, temperatura, umidade relativa do ar e ventilação adequadas?'
            name='possuemNebulizadoresEOuSistemaDeVentilacao_5_6'
            specify='possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6'
            formValue={props.form.getFieldValue(
              'possuemNebulizadoresEOuSistemaDeVentilacao_5_6'
            )}
            updatedValue={
              updatedValues['possuemNebulizadoresEOuSistemaDeVentilacao_5_6']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            title='5.7 - Os bebedouros e comendouros são de fácil manejo e permitem adequada limpeza e definfecção?'
            name='bebedourosEComedourosDeFacilManejo_5_7'
            specify='bebedourosEComedourosDeFacilManejoEspecificacao_5_7'
            formValue={props.form.getFieldValue(
              'bebedourosEComedourosDeFacilManejo_5_7'
            )}
            updatedValue={
              updatedValues['bebedourosEComedourosDeFacilManejo_5_7']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='possuiCercaDeIsolamentoMimino1m_5_8'
            title='5.8 - Possui cerca de isolamento de no mínimo de 1m (um metro) de altura em volta do(s) aviário(s) ou do núcleo, com um afastamento mínimo de 5m (cinco metros), dotado de um único ponto de acesso?'
            name='possuiCercaDeIsolamentoMimino1m_5_8'
            specify='possuiCercaDeIsolamentoMimino1mEspecificacao_5_8'
            formValue={props.form.getFieldValue(
              'possuiCercaDeIsolamentoMimino1m_5_8'
            )}
            updatedValue={updatedValues['possuiCercaDeIsolamentoMimino1m_5_8']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='siloDeRacaoFeitoDeMaterialIpermeavel_5_9'
            title='5.9 - Silo(s) de ração é feito de material impermeável que permite sua adequada limpeza e desinfecção, e de forma que 
            impeça a entrada de roedores?'
            name='siloDeRacaoFeitoDeMaterialIpermeavel_5_9'
            specify='siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9'
            formValue={props.form.getFieldValue(
              'siloDeRacaoFeitoDeMaterialIpermeavel_5_9'
            )}
            updatedValue={
              updatedValues['siloDeRacaoFeitoDeMaterialIpermeavel_5_9']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='ausenciaDeArvoresAtrativasDePassaros_5_10'
            title='5.10 - Ausência de árvores atrativas de pássaros (frutíferas) dentro da granja?'
            name='ausenciaDeArvoresAtrativasDePassaros_5_10'
            specify='ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10'
            formValue={props.form.getFieldValue(
              'ausenciaDeArvoresAtrativasDePassaros_5_10'
            )}
            updatedValue={
              updatedValues['ausenciaDeArvoresAtrativasDePassaros_5_10']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='adotaSistemaDeCriacaoAoArLivre_5_11'
            title='5.11 - A granja adota sistema de criação ao ar livre, com a alimentação e água de bebida sendo fornecidas obrigatoriamente em instalações providas de proteção ao ambiente externo, por meio de telas com malha de medida não superior a 1 (uma) polegada ou outro meio que impeça a entrada de pássaros, animais domésticos e silvestres?'
            name='adotaSistemaDeCriacaoAoArLivre_5_11'
            specify='adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11'
            formValue={props.form.getFieldValue(
              'adotaSistemaDeCriacaoAoArLivre_5_11'
            )}
            updatedValue={updatedValues['adotaSistemaDeCriacaoAoArLivre_5_11']}
          />
        </Col>
      </Row>
      <Divider />
      <Col xs={24} lg={24}>
        <Form.Item
          label={'Observações'}
          name={'observacoesInstalacoesEquipamentos_5_12'}
        >
          <TextArea
            showCount
            rows={5}
            maxLength={2000}
            disabled={props.formDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
