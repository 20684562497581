import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import AuthorizationService from '../../core/auth/Authorization.service';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import { Usuario } from '../../sdk/@types';
import ErrorBoundary from '../components/ErrorBoundary';
import UsuarioForm from '../features/User/UsuarioForm';

export default function UsuarioView() {
  usePageTitle(AuthorizationService.getUsuarioSIZ()?.nome!);

  const { setMode, loading } = useLoadingPage();

  const [usuario, setUsuario] = useState<Usuario.Detailed>();

  useEffect(() => {
    const usuario = AuthorizationService.getUsuarioSIZ();
    if (usuario) setUsuario(usuario);
  }, []);
  return (
    <>
      <ErrorBoundary component='formulário'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <UsuarioForm usuario={usuario} />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
