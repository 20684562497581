import localforage from 'localforage';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import TFRIDBService from '../../sdk/services/indexeddb/TFRIDB.service';
import Service from '../../sdk/services/SIZ-API/Service';
import AuthorizationService from '../auth/Authorization.service';
import { TermoFiscalizacao } from '../../sdk/@types';

export default async function syncTFRs(manually?: boolean): Promise<string> {
  let errors = 0;

  const promise = new Promise<string>(async (resolve, reject) => {
    return await TFRIDBService.getAllFromInputTable()
      .then(async (listTFRsOffline) => {
        const saveTFRAsync = async () => {
          for (const tfrOffline of listTFRsOffline) {
            if (
              tfrOffline.status === 'SINCRONIZADO' ||
              tfrOffline.status === 'NAO FINALIZADO'
            ) {
              //@TODO preguiça de negar a condição. Arrumar depois
            } else {
              await localforage.getItem('token').then(async (token) => {
                let access_token;
                if (token) access_token = token;
                else access_token = AuthorizationService.getAccessToken();

                if (!access_token) {
                  throw new Error('Sessão expirada');
                }

                let tfrDTO: TermoFiscalizacao.Request;
                tfrDTO = {
                  ...tfrOffline.payload,
                };

                if (tfrDTO.revenda.endereco)
                  tfrDTO = {
                    ...tfrDTO,
                    revenda: {
                      ...tfrDTO.revenda,
                      endereco: {
                        ...tfrDTO.revenda.endereco,
                        municipio: {
                          ...tfrDTO.revenda.endereco.municipio,
                          //@ts-ignore
                          codgIBGE: String(
                            tfrDTO.revenda.endereco.municipio.id
                          ),
                        },
                      },
                    },
                  };

                //@ts-ignore
                if (tfrDTO.veterinario)
                  tfrDTO = {
                    ...tfrDTO,
                    servidores: [
                      {
                        //@ts-ignore
                        servidor: {
                          //@ts-ignore
                          id: tfrDTO.veterinario.id,
                        },
                      },
                    ],
                  };

                await fetch(`${Service.API_URL}/termos-fiscalizacao`, {
                  method: 'POST',
                  body: JSON.stringify(tfrDTO),
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`,
                  },
                })
                  .then(async (response) => {
                    if (response.status === 401) {
                      throw new Error('Sessão expirada');
                    }

                    if (response.ok) {
                      return await response.json().then(async (tfrResponse) => {
                        //update local FVER
                        let newTFR: ServiceIDBPayloadInput = {
                          ...tfrOffline,
                          payload: {
                            ...tfrDTO,
                            id: tfrResponse.id,
                            numero: tfrResponse.numero,
                          },
                          status: 'SINCRONIZADO',
                        };

                        await TFRIDBService.update(
                          //@ts-ignore
                          tfrOffline.id,
                          newTFR
                        ).catch((e) => {});
                      });
                    } else {
                      return await response.json().then(async (tfrResponse) => {
                        let motivoErro = tfrResponse.detail;

                        errors = errors + 1;
                        let newTFR: ServiceIDBPayloadInput = {
                          ...tfrOffline,
                          status: 'ERRO',
                          motivoErro,
                        };

                        return await TFRIDBService.update(
                          //@ts-ignore
                          tfrOffline.id,
                          newTFR
                        );
                      });
                    }
                  })
                  .catch((e) => {
                    throw new Error(
                      'Não conseguimos conectar com o servidor. Motivo: ' + e
                    );
                  });
              });
            }
          }
        };
        await saveTFRAsync();
      })
      .then(async () => {
        if (errors === 0) {
          return resolve('TFRs sincronizados com sucesso');
        } else {
          return reject(
            'A sincronização dos TFRs foi finalizada com erros. Alguns TFRs não puderam ser sincronizadas. Revise-os e tente novamente'
          );
        }
      })
      .catch((e) => {
        return reject(e.message);
      });
  });

  return await promise;
}
