import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Permissao } from '../../../sdk/@types/Permissao';
import UsuarioService from '../../../sdk/services/SIZ-API/Usuario.service';
import { Usuario } from '../../../sdk/@types';
import PermissaoService from '../../../sdk/services/SIZ-API/Permissao.service';
import useUsuario from '../../../core/hooks/useUsuario';

export default function ListUsuarioPermissoesView() {
  usePageTitle('Usuários');
  const params = useParams<{ userName: string }>();
  const [permissoes, setPermissoes] = useState<Permissao.Response[]>([]);

  const { fetching, fetchUsuarioByUsername, usuario } = useUsuario();

  const usuarioPossuiPermissao = useCallback(
    (permissaoId: number) => {
      const checked = usuario?.permissoes?.filter(
        (permissao) => permissao.id === permissaoId
      );

      return checked && checked?.length > 0;
    },
    [usuario]
  );

  const qtdadeChildrenChecked = useCallback(
    (permissao: Permissao.Response) => {
      let checked = 0;
      if (permissao.filhos)
        permissao.filhos.forEach((permissao) => {
          if (usuarioPossuiPermissao(permissao.id!)) checked++;
        });

      return checked;
    },
    [usuarioPossuiPermissao]
  );

  const isParentPermitionIndeterminate = useCallback(
    (permissao: Permissao.Response) => {
      if (!permissao.filhos || permissao.filhos!.length === 0) return false;

      let checked = qtdadeChildrenChecked(permissao);

      if (checked > 0 && checked < permissao.filhos.length) return true;

      return false;
    },
    [qtdadeChildrenChecked]
  );

  const isParentChecked = useCallback(
    (permissao: Permissao.Response) => {
      if (!permissao.filhos || permissao.filhos!.length === 0)
        return usuarioPossuiPermissao(permissao.id!);
      else if (qtdadeChildrenChecked(permissao) === permissao.filhos.length)
        return true;
      else return false;
    },
    [qtdadeChildrenChecked, usuarioPossuiPermissao]
  );

  const init = useCallback(
    async (userName: string) => {
      await fetchUsuarioByUsername(userName);
      await PermissaoService.getAll().then((permissoes): any => {
        setPermissoes(permissoes);
      });
    },
    [fetchUsuarioByUsername]
  );

  useEffect(() => {
    if (params.userName) init(params.userName);
  }, [init, params]);

  useEffect(() => {}, [permissoes]);

  useEffect(() => {}, [fetching, usuario]);

  const handleCheckboxChange = useCallback(
    async (checked, permissaoAlterada: Permissao.Response) => {
      let novasPermissoes: any = [];

      if (!checked) {
        if (permissaoAlterada.filhos && permissaoAlterada.filhos.length > 0) {
          novasPermissoes = usuario?.permissoes ? usuario?.permissoes : [];

          permissaoAlterada.filhos.forEach((permissaoAlteradaFilho) => {
            novasPermissoes = novasPermissoes.filter(
              (permissaoUsuario: any) =>
                permissaoUsuario.id !== permissaoAlteradaFilho.id
            );
          });
        } else {
          novasPermissoes = usuario?.permissoes?.filter(
            (permissaoUsuario) => permissaoUsuario.id !== permissaoAlterada.id
          );
        }
      } else {
        if (permissaoAlterada.filhos && permissaoAlterada.filhos.length > 0) {
          novasPermissoes = usuario?.permissoes;
          if (!novasPermissoes) {
            novasPermissoes = [];
          }

          permissaoAlterada.filhos?.forEach((permissaoFilho) => {
            if (!usuarioPossuiPermissao(permissaoFilho.id!))
              novasPermissoes.push(permissaoFilho);
          });
        } else {
          novasPermissoes = usuario?.permissoes;
          novasPermissoes.push(permissaoAlterada);
        }
      }

      const usuarioS: Usuario.Summary = {
        cpf: usuario!.cpf,
        email: usuario!.email,
        nome: usuario!.nome,
        status: usuario!.status,
        tipoUsuario: usuario!.tipoUsuario,
        userName: usuario!.userName,
        dataUltimoLogin: usuario!.dataUltimoLogin,
        telefoneFixo: usuario!.telefoneFixo,
      };

      const usuarioPermissoes: Usuario.UsuarioPermissao = {
        usuarios: [usuarioS],
        permissoes: novasPermissoes,
      };

      await UsuarioService.updatePermissoes(usuarioPermissoes).then(
        async () => {
          await fetchUsuarioByUsername(params.userName!);
        }
      );
    },
    [fetchUsuarioByUsername, params.userName, usuario]
  );

  return (
    <Spin spinning={fetching}>
      <>
        <Divider orientation='left'>Usuário</Divider>
        <Descriptions
          bordered
          column={3}
          labelStyle={{ fontWeight: 'bold' }}
          layout='vertical'
          size={'small'}
        >
          <Descriptions.Item label={'Nome'} span={3}>
            {usuario?.nome}
          </Descriptions.Item>
        </Descriptions>

        <Divider orientation='left'>Permissões</Divider>

        {permissoes.map((permissao) => {
          return (
            <Card
              bodyStyle={{
                paddingTop: '5px',
                paddingBottom: '8px',
              }}
              style={{ marginBottom: '12px' }}
              title={null}
            >
              <Typography.Title level={5}>
                <Space direction='horizontal' size={8}>
                  <Checkbox
                    checked={isParentChecked(permissao)}
                    indeterminate={isParentPermitionIndeterminate(permissao)}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, permissao)
                    }
                  />
                  {permissao.descricao}
                </Space>
              </Typography.Title>
              {permissao.filhos && permissao.filhos.length > 0 && (
                <>
                  <div
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, 0.06)',
                      width: '100%',
                    }}
                  />
                  <Row gutter={24}>
                    {permissao.filhos.map((permissao) => (
                      <Col span={8}>
                        <Space direction='horizontal' size={8}>
                          <Checkbox
                            checked={usuarioPossuiPermissao(permissao.id!)}
                            onChange={(e) =>
                              handleCheckboxChange(e.target.checked, permissao)
                            }
                          />
                          {permissao.descricao}
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Card>
          );
        })}
      </>
    </Spin>
  );
}
