import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useEffect } from 'react';
import { Usuario } from '../../../sdk/@types/Usuario';

type UsuarioFormType = {} & Usuario.Detailed;

type UsuarioFormProps = {
  usuario?: UsuarioFormType;
};

export default function UsuarioForm(props: UsuarioFormProps) {
  const [form] = Form.useForm<UsuarioFormType>();
  const { xs } = useBreakpoint();

  useEffect(() => {
    form.setFieldsValue({
      ...props.usuario,
    });
  }, [props.usuario]);

  return (
    <>
      <fieldset>
        <Form<UsuarioFormType>
          layout={'vertical'}
          form={form}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          size={!xs ? 'small' : 'large'}
        >
          <Typography.Title level={3}>Meus dados</Typography.Title>
          <Divider />

          <Row gutter={24}>
            <Col xs={24} sm={16}>
              <Form.Item label={'Nome'} name={['nome']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={8}>
              <Form.Item label={'CPF'} name={['cpf']}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={16}>
              <Form.Item label={'Email'} name={['email']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={8}>
              <Form.Item label={'Status'} name={['status']}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </>
  );
}
