import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PrintFVVView from '../features/FVV/PrintFVVView';

export default function FVVViewWrapper() {
  usePageTitle('FVV');

  const { setLoading, setMode } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setMode('VIEW');
  }, [setMode]);

  return (
    <>
      <ErrorBoundary>
        <PrintFVVView />
      </ErrorBoundary>
    </>
  );
}
