import { Municipio } from '../../@types';
import AbatedouroIDBService from './AbatedouroIDB.service';
import FormINIDBService from './FormINIDB.service';
import ProdutorIDBService from './ProdutorIDB.service';
import PropriedadeIDBService from './PropriedadeIDB.service';
import RecintoIDBService from './RecintoIDB.service';
import RevendaIDBService from './RevendasIDB.service';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
import SetorIDBService from './SetorIDB.service';

class MunicipioIDBService extends ServiceIDB {
  static DB_STORE_MUNICIPIO = 'municipio';
  static DB_STORE_MUNICIPIO_INDEX_KEY = 'payload.codgIBGE';
  static DB_STORE_MUNICIPIO_INDEX_NAME = 'municipio_index_id';

  static async getAll(): Promise<ServiceIDBPayload[]> {
    return await super._getAll(MunicipioIDBService.DB_STORE_MUNICIPIO);
  }

  static async get(id: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_MUNICIPIO,
      this.DB_STORE_MUNICIPIO_INDEX_NAME,
      id
    );
  }

  static async add(municipio: Municipio.Detailed): Promise<IDBValidKey> {
    const date = new Date();
    const obj = {
      date: date,
      payload: municipio,
    };

    return super._add(this.DB_STORE_MUNICIPIO, obj);
  }

  static async delete(codgIBGE: string): Promise<void> {
    const obj = await super._getFromIndex(
      this.DB_STORE_MUNICIPIO,
      this.DB_STORE_MUNICIPIO_INDEX_NAME,
      codgIBGE
    );

    if (!obj) return;

    const codg_ibge = obj.payload.codgIBGE;

    //@ts-ignore
    return await super
      //@ts-ignore
      ._delete(this.DB_STORE_MUNICIPIO, obj.id)
      .then(async () => {
        await PropriedadeIDBService.deleteAllByMunicipio(codg_ibge);
        await AbatedouroIDBService.deleteAllByMunicipio(codg_ibge);
        await RecintoIDBService.deleteAllByMunicipio(codg_ibge);
        await ProdutorIDBService.deleteAllByMunicipio(codg_ibge);
        await SetorIDBService.deleteAllByMunicipio(codg_ibge);
        await RevendaIDBService.deleteAllByMunicipio(codg_ibge);
        await FormINIDBService.deleteAllByMunicipio(codg_ibge);

        return Promise.resolve();
      });
  }

  static async deleteAll(): Promise<void> {
    super._clear(this.DB_STORE_MUNICIPIO);
    PropriedadeIDBService.deleteAll();
    AbatedouroIDBService.deleteAll();
    RecintoIDBService.deleteAll();
    ProdutorIDBService.deleteAll();
    SetorIDBService.deleteAll();
    RevendaIDBService.deleteAll();
    FormINIDBService.deleteAll();
  }
}

export default MunicipioIDBService;
