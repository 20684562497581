import { Recinto } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class RecintoIDBService extends ServiceIDB {
  static DB_STORE_RECINTO = 'recinto';
  static DB_STORE_RECINTO_INDEX_CODIGO_NAME = 'recinto_index_cpf';
  static DB_STORE_RECINTO_INDEX_CODIGO_KEY = 'payload.id';
  static DB_STORE_RECINTO_INDEX_MUNICIPIO_NAME = 'recinto_index_municipio';
  static DB_STORE_RECINTO_INDEX_MUNICIPIO_KEY = 'payload.municipio.codgIBGE';

  static async getByCodigo(codigo: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_RECINTO,
      this.DB_STORE_RECINTO_INDEX_CODIGO_NAME,
      codigo
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(RecintoIDBService.DB_STORE_RECINTO);
    return list;
  }

  static async getAllPayload(): Promise<Recinto.Summary[]> {
    let list: Recinto.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static add(recinto: Recinto.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: recinto,
      };

      db.add(this.DB_STORE_RECINTO, obj);
    });
  }

  static async addAllRecinto(lista: Recinto.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_RECINTO, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_RECINTO);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_RECINTO,
      this.DB_STORE_RECINTO_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default RecintoIDBService;
