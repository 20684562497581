import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import EditFormEduca from '../features/FormEduca/EditFormEDUCA';

export default function FormEducaEditView() {
  usePageTitle('Editando de FormEduca');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formEduca'>
        <EditFormEduca />
      </ErrorBoundary>
    </>
  );
}
