import { Footer } from 'antd/lib/layout/layout';
import EnvConfig from '../../EnvConfig';
import { StageType } from '../../../core/enums/StageType';

export default function DefaultLayoutFooter() {
  return (
    <Footer>
      {EnvConfig.stage() !== StageType.PROD
        ? EnvConfig.stage() + ' - ' + EnvConfig.version()
        : EnvConfig.version()}
    </Footer>
  );
}
