import {
  CheckCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Row,
  Col,
  Divider,
  Select,
  Button,
  Table,
  Typography,
  Input,
  notification,
  Tag,
  Descriptions,
  Switch,
  Popconfirm,
  Tooltip,
  Radio,
  SelectProps,
  Card,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTipoChavePrincipalFVER from '../../../core/hooks/useTipoChavePrincipalFVER';
import useTipoChaveSecundariaFVER from '../../../core/hooks/useTipoChaveSecundariaFVER';
import { useNavigate, useParams } from 'react-router-dom';
import * as FormGrafActions from '../../../core/store/FormGRAF.slice';
import useMunicipio from '../../../core/hooks/useMunicipio';

import {
  ChavePrincipalFVER,
  Servidor,
  TipoChaveSecundariaFVER,
  Municipio,
  FormGraf,
} from '../../../sdk/@types';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import ServidoresPanel from '../../components/ServidoresPanel';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { CustomModal } from '../../components/CustomModal';
import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import AuthorizationService from '../../../core/auth/Authorization.service';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { MediaOptions } from '../../../core/enums/MediaOptions';

export type FormFormGrafType = {
  dataDistribuicao: Moment;
  modalidadeDistribuicao: string;
  municipio?: Municipio.Detailed;
  quantidadePessoasAtingidas: number;
  servidores?: Servidor.Summary[];
  tiposApresentacaoMaterialEducativo?: string[];
  tiposChavesPrincipais: {
    id: number;
    nome: string;
  }[];
};
type FormFormGrafProps = {
  formGraf?: FormFormGrafType;
};

export default function FormGRAF(props: FormFormGrafProps) {
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { online } = useNavigatorStatus();
  const dispatch = useDispatch();
  const { mode, setLoading } = useLoadingPage();

  const [form] = Form.useForm<FormFormGrafType>();

  const [formDisabled] = useState<boolean>(false);

  useState<boolean>(false);
  const [selectedChavePrimaria, setSelectedChavePrimaria] = useState<
    { id: number; nome: string } | undefined
  >();
  const [municipioSelecionado, setMunicipioSelecionado] = useState<
    Municipio.Detailed | undefined
  >(props.formGraf?.municipio);

  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();

  const {
    tipoChavePrincipalFVER,
    fetchActive: fetchActiveTipoChavePrincipalFVER,
  } = useTipoChavePrincipalFVER();

  const [showModalTimePicker, setShowModalTimePicker] =
    useState<boolean>(false);
  const [showModalProgramas, setShowModalProgramas] = useState<boolean>(false);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const [dataSourceTableChavesPrimarias, setDatasourceTableChavesPrimarias] =
    useState<FormFormGrafType['tiposChavesPrincipais']>([]);

  const [listaServidores, setListaServidores] = useState<Servidor.Summary[]>(
    []
  );

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    if (props.formGraf?.servidores)
      setListaServidores(props.formGraf?.servidores);
    if (props.formGraf?.tiposChavesPrincipais) {
      setDatasourceTableChavesPrimarias(props.formGraf?.tiposChavesPrincipais);
      form.setFieldValue(
        'tiposChavesPrincipais',
        props.formGraf?.tiposChavesPrincipais
      );
    }
  }, [props.formGraf]);

  useEffect(() => {
    if (online) fetchMunicipiosByUf('mt');
  }, [fetchMunicipiosByUf, online]);

  useEffect(() => {
    const insertLoggedUser = async () => {
      if (mode === 'CREATE') {
        if (AuthorizationService.getUsuarioSIZ()) {
          await ServidorIDBService.getByCpf(
            //@ts-ignore
            AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
          ).then((servidor) => {
            if (servidor) {
              const newLista = listaServidores.concat(servidor.payload);
              setListaServidores(newLista);
            }
          });
        }
      }
    };

    insertLoggedUser();
  }, []);

  useEffect(() => {
    fetchActiveTipoChavePrincipalFVER();
  }, [fetchActiveTipoChavePrincipalFVER]);

  const saveFormFormGraf = useCallback(
    async (formGraf: FormGraf.Request) => {
      try {
        const action = await dispatch(
          //@ts-ignore
          formGraf.id
            ? FormGrafActions.updateFormGraf({
                id: Number(params.id),
                formGraf: formGraf,
              })
            : FormGrafActions.insertFormGraf(formGraf)
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const formGraf = action.payload;
          setDadosModalFinalizacao({
            id: formGraf.id,
          });

          setShowModalFinalizacao(true);
        }
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormFormGrafType) => {
      setLoading(true);

      const formGrafDTO: FormGraf.Request = {
        ...formParam,
        dataDistribuicao: formParam.dataDistribuicao!.format(
          'YYYY-MM-DD[T]HH:mm:ss[Z]'
        ),
        tiposChavesPrincipais: formParam.tiposChavesPrincipais,
        modalidadeDistribuicao: form.getFieldValue('modalidadeDistribuicao'),
        municipio: municipioSelecionado as Municipio.Detailed,
        quantidadePessoasAtingidas: form.getFieldValue(
          'quantidadePessoasAtingidas'
        ),
        servidores: listaServidores,
        tiposApresentacaoMaterialEducativo: form.getFieldValue(
          'tiposApresentacaoMaterialEducativo'
        ),
      };

      try {
        saveFormFormGraf(formGrafDTO);
      } catch (error) {
        notification.error({
          message: 'Erro ao enviar formulário',
          description: (error as Error).message,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      form,
      dataSourceTableChavesPrimarias,
      setLoading,
      listaServidores,
      municipioSelecionado,
      saveFormFormGraf,
    ]
  );

  const handleSelectChavePrimariaChange = (value: any) => {
    const chave = JSON.parse(value);
    setSelectedChavePrimaria(chave);
  };

  const cleanModalProgramasEAtividades = useCallback(() => {
    setSelectedChavePrimaria(undefined);
  }, [form]);
  const handleRemovePrograma = (id: number) => {
    const newChaves = dataSourceTableChavesPrimarias.filter(
      (chave) => chave.id !== id
    );
    setDatasourceTableChavesPrimarias(newChaves);
    form.setFieldsValue({ tiposChavesPrincipais: newChaves });
  };

  const handleAddPrograma = () => {
    if (selectedChavePrimaria) {
      const chaveExists = dataSourceTableChavesPrimarias.some(
        (chave) => chave.id === selectedChavePrimaria.id
      );

      if (chaveExists) {
        notification.warn({
          message: 'Esta chave principal já foi incluída',
          description: 'Selecione outra chave principal',
        });
        return;
      }

      setDatasourceTableChavesPrimarias([
        ...dataSourceTableChavesPrimarias,
        selectedChavePrimaria,
      ]);
      form.setFieldsValue({
        tiposChavesPrincipais: [
          ...dataSourceTableChavesPrimarias,
          selectedChavePrimaria,
        ],
      });
      notification.success({
        message: 'Programa e atividades incluído com sucesso',
      });
      setSelectedChavePrimaria(undefined);
      setShowModalProgramas(false);
    }
  };

  const mediaOptions: SelectProps['options'] = MediaOptions.keys().map(
    (key) => ({
      value: key.trim(),
      label: MediaOptions[key as keyof typeof MediaOptions],
    })
  );
  const handleSelectMunicipioChange = (nome: string) => {
    const selectedMunicipio = listaMunicipio?.find(
      (municipio) => municipio.nome === nome
    );
    setMunicipioSelecionado(selectedMunicipio);
  };
  const columns = [
    {
      dataIndex: 'nome',
      title: 'Chave Principal',
      responsive: ['sm'],
    },
    {
      dataIndex: 'id',
      title: 'Ações',
      responsive: ['sm'],
      width: 60,
      render: (id: number) => (
        <Button
          type='ghost'
          icon={<DeleteOutlined />}
          onClick={() => handleRemovePrograma(id)}
          danger
        />
      ),
    },
  ];
  return (
    <>
      <fieldset disabled={formDisabled}>
        <Form<FormFormGrafType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          autoComplete={'off'}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          initialValues={{
            ...props.formGraf,
            municipio: props.formGraf?.municipio?.nome,
          }}
        >
          <Row gutter={24}>
            <Form.Item name={'id'} hidden>
              <Input disabled hidden />
            </Form.Item>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Data da distribuição dos materiais educativos'}
                name={'dataDistribuicao'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
                initialValue={moment.tz(
                  moment().toLocaleString(),
                  'America/Cuiaba'
                )}
              >
                <DatePicker
                  onClick={() => {
                    scrollFieldToTop('dataDistribuicao');
                  }}
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(currentDate) =>
                    currentDate && currentDate.isBefore(moment().startOf('day'))
                  }
                  disabled={formDisabled}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={'Município'} style={{ width: '100%' }}>
                <Input.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Form.Item
                    name={'municipio'}
                    style={{
                      width: '100%',
                      marginBottom: '0',
                      marginTop: '0',
                    }}
                    initialValue={
                      props.formGraf?.municipio
                        ? props.formGraf.municipio.nome
                        : undefined
                    }
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder='Selecione um município'
                      optionFilterProp='children'
                      onChange={handleSelectMunicipioChange}
                      filterOption={(input, option) => {
                        if (option && option.children)
                          return (
                            option.children
                              .toString()
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .indexOf(input.toLowerCase()) >= 0
                          );

                        return false;
                      }}
                      value={
                        municipioSelecionado
                          ? municipioSelecionado.nome
                          : undefined
                      }
                    >
                      {listaMunicipio?.map((municipio) => (
                        <Select.Option
                          key={municipio.codgIBGE}
                          value={municipio.nome}
                        >
                          {municipio?.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Divider orientation='left'>Adicionar Servidores</Divider>
            <ServidoresPanel
              form={form}
              formDisabled={formDisabled}
              listaServidores={listaServidores}
              setListaServidores={setListaServidores}
            />
            <Divider orientation='left'>
              Sobre a distribuição do material educativo
            </Divider>
            <Col xs={24} lg={12}>
              <Form.Item
                label='Modalidade de distribuição'
                name='modalidadeDistribuicao'
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Radio.Group
                  disabled={formDisabled}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio style={{ padding: '10px 0px ' }} value='PRESENCIAL'>
                    Presencial
                  </Radio>
                  <Radio value='REMOTA'>Remota (internet)</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Divider />
            <Col xs={24} lg={24}>
              <Form.Item
                label='Tipo de apresentação do material educativo'
                name='tiposApresentacaoMaterialEducativo'
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  mode='tags'
                  style={{ width: '100%' }}
                  placeholder='Selecione um tipo de mídia'
                  options={mediaOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <CustomModal
            centered
            open={showModalProgramas}
            title={'Programas e atividades'}
            onCancel={() => {
              setShowModalProgramas(false);
              cleanModalProgramasEAtividades();
            }}
            width={750}
            footer={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  style={{ width: '50%' }}
                  danger
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setShowModalProgramas(false);
                    cleanModalProgramasEAtividades();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ width: '50%' }}
                  type={'primary'}
                  icon={<CheckCircleOutlined />}
                  onClick={() => {
                    form.validateFields([['modalTipoChavePrimaria', 'id']]);
                    handleAddPrograma();
                  }}
                >
                  Salvar
                </Button>
              </div>
            }
            maskClosable={false}
            destroyOnClose
          >
            <Form layout={'vertical'} form={form} autoComplete={'off'}>
              <Row gutter={24}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={'Chave Principal'}
                    name={['modalTipoChavePrimaria', 'id']}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                    valuePropName={'modaltipochaveprimaria'}
                  >
                    <Select
                      placeholder={'Selecione uma chave'}
                      onChange={handleSelectChavePrimariaChange}
                      placement={'bottomLeft'}
                    >
                      {tipoChavePrincipalFVER?.map((tipo) => (
                        <Select.Option
                          key={tipo.id}
                          value={JSON.stringify(tipo)}
                        >
                          {tipo.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </CustomModal>
          <Col xs={24} sm={24}>
            <Divider orientation='left'>Programas sanitários</Divider>
            <Form.Item
              validateTrigger={'onSubmit'}
              name='tiposChavesPrincipais'
              rules={[
                {
                  validator(_, value) {
                    if (dataSourceTableChavesPrimarias.length < 1)
                      return Promise.reject(
                        new Error('Deve ser informado pelo menos um programa')
                      );
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Table
                size={'small'}
                dataSource={dataSourceTableChavesPrimarias}
                rowKey={(record) => record.id.toString()}
                title={() => (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      Atividades
                    </Typography.Title>
                    <Button
                      icon={<PlusOutlined />}
                      type={'primary'}
                      onClick={() => setShowModalProgramas(true)}
                    />
                  </Row>
                )}
                columns={columns as any}
              />
            </Form.Item>
          </Col>

          <br />
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Quantidade de pessoas beneficiadas diretamente'}
              name={'quantidadePessoasAtingidas'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
          <Divider />
          <Row justify={'end'} gutter={24}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(`${online ? '/graf' : '/acoes-de-campo'}`)
                }
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/graf/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>

      <CustomModal
        centered
        open={showModalFinalizacao}
        width={750}
        footer={null}
        destroyOnClose
        closable={false}
        title={`FormGraf salvo com sucesso`}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          bordered={false}
        >
          <Row
            gutter={24}
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Col xs={24} sm={24}>
              <Typography.Paragraph>
                {online
                  ? 'O FormGraf foi salvo no servidor e não necessita sincronização'
                  : 'FormGraf salvo localmente'}
              </Typography.Paragraph>
            </Col>
            {dadosModalFinalizacao?.networkError && (
              <Col xs={24} sm={24}>
                <Typography.Paragraph>
                  Houve um problema enquanto tentamos nos comunicar com o
                  servidor.
                </Typography.Paragraph>
              </Col>
            )}
          </Row>

          <Row
            align='middle'
            style={{
              justifyContent: 'center',
            }}
            gutter={24}
          >
            <Col>
              <Button
                onClick={() => navigate(`${online ? '/graf' : '/'}`)}
                type={'primary'}
              >
                {' '}
                Voltar{' '}
              </Button>
            </Col>
            <Col>
              {online && (
                <Button
                  onClick={() =>
                    navigate(`/graf/visualizar/${dadosModalFinalizacao?.id}`)
                  }
                  type={'primary'}
                >
                  {' '}
                  Visualizar{' '}
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        title={null}
        footer={null}
        open={showModalTimePicker}
        bodyStyle={{ display: 'inline-block', float: 'left' }}
      >
        <div style={{ display: 'inline-block', float: 'left' }}>
          <StaticTimePicker
            defaultValue={moment.tz(
              moment().toLocaleString(),
              'America/Cuiaba'
            )}
            timezone='America/Cuiaba'
            localeText={{
              cancelButtonLabel: 'Cancelar',
              toolbarTitle: '',
            }}
            onAccept={(data: Moment | null) => {
              let hour =
                String(data?.get('hour')).padStart(2, '0') +
                ':' +
                String(data?.get('minute')).padStart(2, '0');
              form.setFieldValue('horaDaVisita', hour);

              setShowModalTimePicker(false);
            }}
            slots={{
              actionBar: undefined,
            }}
            onClose={() => {
              setShowModalTimePicker(false);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
}
