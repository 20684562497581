export enum IdadeRuminantesAlimentadosComRacao {
  DE_00_04 = 'De 0 a 4',
  DE_05_12 = 'De 5 a 12',
  DE_13_24 = 'De 13 a 24',
  DE_25_36 = 'De 25 a 36',
  ACIMA_36 = 'Acima de 36',
  ATE_12 = 'Até 12',
  ACIMA_12 = 'Acima de 12',
}
export namespace IdadeRuminantesAlimentadosComRacao {
  export function keys(): string[] {
    return Object.keys(IdadeRuminantesAlimentadosComRacao).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return IdadeRuminantesAlimentadosComRacao[
      key as keyof typeof IdadeRuminantesAlimentadosComRacao
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(IdadeRuminantesAlimentadosComRacao).indexOf(
      value as unknown as IdadeRuminantesAlimentadosComRacao
    );

    const key = Object.keys(IdadeRuminantesAlimentadosComRacao)[indexOfS];

    return key;
  }

  export function idadeRuminantesAlimentadosComRacao_Bovinos(): string[] {
    return [
      keyOf(IdadeRuminantesAlimentadosComRacao.DE_00_04),
      keyOf(IdadeRuminantesAlimentadosComRacao.DE_05_12),
      keyOf(IdadeRuminantesAlimentadosComRacao.DE_13_24),
      keyOf(IdadeRuminantesAlimentadosComRacao.DE_25_36),
      keyOf(IdadeRuminantesAlimentadosComRacao.ACIMA_36),
    ];
  }

  export function idadeRuminantesAlimentadosComRacao_Ovinos_Caprinos(): string[] {
    return [
      keyOf(IdadeRuminantesAlimentadosComRacao.ATE_12),
      keyOf(IdadeRuminantesAlimentadosComRacao.ACIMA_12),
    ];
  }
}
