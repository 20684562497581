import { Veterinario } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class VeterinarioIDBService extends ServiceIDB {
  static DB_STORE_VETERINARIO = 'veterinario';
  static DB_STORE_VETERINARIO_INDEX_CPF_NAME = 'veterinario_index_cpf';
  static DB_STORE_VETERINARIO_INDEX_CPF_KEY = 'payload.pessoa.cpfCnpj';

  static DB_STORE_VETERINARIO_INDEX_CRMV_NAME = 'veterinario_index_crmv';
  static DB_STORE_VETERINARIO_INDEX_CRMV_KEY = 'payload.numeroConselho';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(VeterinarioIDBService.DB_STORE_VETERINARIO)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByCpf(cpf: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_VETERINARIO,
      this.DB_STORE_VETERINARIO_INDEX_CPF_NAME,
      cpf
    );
  }

  static async getByCRMV(crmv: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_VETERINARIO,
      this.DB_STORE_VETERINARIO_INDEX_CRMV_NAME,
      crmv
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(VeterinarioIDBService.DB_STORE_VETERINARIO);
    return list;
  }

  static async getAllPayload(): Promise<Veterinario.Summary[]> {
    let list: Veterinario.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllVeterinario(lista: Veterinario.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_VETERINARIO, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_VETERINARIO);
  }
}

export default VeterinarioIDBService;
