import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  Radio,
  Divider,
  Typography,
  Col,
  Row,
  Space,
  Select,
  notification,
  Button,
  Switch,
  Card,
  Descriptions,
} from 'antd';

import {
  fetchFormRespostaById,
  sendFormRespostas,
  updateformRespostaOffline,
} from '../../../core/store/FormResposta.slice';
import { fetchFormCustomById } from '../../../core/store/FormCustom.slice';
import { AppDispatch, RootState } from '../../../core/store';

import PanelFVERInput from '../FVER/PanelFVER_Input';
import useFVER from '../../../core/hooks/useFVER';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { usePrompt } from '../../../core/hooks/usePrompt';
import useMunicipio from '../../../core/hooks/useMunicipio';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import FormularioRespostasIDBService from '../../../sdk/services/indexeddb/FormularioRespostaIDB.service';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import { FormularioRespostas, FVER, Municipio } from '../../../sdk/@types';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title, Paragraph } = Typography;

type Alternativa = {
  descricao: string;
  id: number;
  selecionada: boolean;
};

type Pergunta = {
  id?: number;
  descricao: string;
  ordem: number;
  tipoPergunta:
    | 'MULTIPLA_ESCOLHA'
    | 'NUMERICO'
    | 'PARAGRAFO'
    | 'RESPOSTA_CURTA';
  alternativas?: Alternativa[];
  status: 'ATIVO' | 'INATIVO';
};

type Resposta = {
  descricaoResposta: string;
  alternativa?: Alternativa;
  pergunta: Pergunta;
};

type FormDetailsType = {
  nome: string;
  fver: FVER.Summary;
  descricao: string;
  municipio: Municipio.Detailed;
  perguntas: Pergunta[];
  respostas?: Resposta[];
  formResposta: FormularioRespostas.Response;
};

type FormRespostaProps = {
  showPrintButton?: JSX.Element;
  formResposta?: FormDetailsType;
};

export default function FormResposta(props: FormRespostaProps) {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const formDetails: FormDetailsType | undefined = useSelector(
    (state: RootState) => state.formCustom.formCustomData as any
  );
  const formRespostasDetails: FormDetailsType | undefined = useSelector(
    (state: RootState) => state.formRespostas.formRespostasData as any
  );

  const formRespostaFver = useSelector(
    (state: RootState) => state.formRespostas.fver
  );

  const { fetchFVERById, fver } = useFVER();

  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);
  usePrompt(!showModalFinalizacao);
  const { online } = useNavigatorStatus();
  const [, setError] = useState<any>(null);
  const params = useParams<{ id: string; local?: string; problem?: string }>();
  const { mode } = useLoadingPage();
  const [shouldSaveOffline, setShouldSaveOffline] = useState<boolean>(false);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();

  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();

  useEffect(() => {
    if (online) {
      dispatch(fetchFormCustomById(Number(id)));
    } else {
      dispatch(fetchFormRespostaById(Number(id)));
    }
  }, [dispatch, id, online, params.local]);

  useEffect(() => {
    if (online) {
      fetchMunicipiosByUf('mt');
    } else {
      setMunicipioSelecionado(props.formResposta?.municipio);
    }
  }, [fetchMunicipiosByUf, online]);

  useEffect(() => {
    if (mode === 'CREATE') {
      fetchFVERById(Number(formRespostaFver?.id), params.local ? true : false)
        .catch((e) => {
          setError(new Error(e.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetchFVERById, mode, formRespostaFver?.id, params.local]);
  useEffect(() => {
    if (mode == 'EDIT' && params.local && online) {
      // Chamar fetchFVERById se estiver no modo de edição, local e online
      fetchFVERById(Number(props.formResposta?.fver?.id), false)
        .catch((e) => {
          setError(new Error(e.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetchFVERById, mode, params.local, online, props.formResposta?.fver.id]);
  useEffect(() => {
    if (mode === 'EDIT' && params.local && !online) {
      // Não chamar fetchFVERById se estiver offline
      setLoading(false);
    }
  }, [mode, params.local, online]);
  const saveFormRespostaLocal = useCallback(
    async (
      formResposta: FormularioRespostas.Request,
      problema_rede?: boolean
    ) => {
      if (params.id) {
        try {
          dispatch(
            updateformRespostaOffline({
              id: Number(params.id),
              formResposta,
            })
          );
          setDadosModalFinalizacao({
            id: Number(params.id),
            local: true,
          });

          setShowModalFinalizacao(true);
        } catch (e: any) {
          notification.error({
            message: 'Não foi possível atualizar o TFR no dispositivo',
            description: e.message,
          });
        }
      } else {
        try {
          const idP = Number(params.id);
          await FormularioRespostasIDBService.insert(idP, formResposta).then(
            (idFormResposta) => {
              setDadosModalFinalizacao({
                id: Number(idFormResposta),
                local: true,
              });
              setShowModalFinalizacao(true);
            }
          );
        } catch (e: any) {
          notification.error({
            message: 'Não foi possível salvar o FormResposta no dispositivo',
            description: e.message,
          });
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [params, setLoading, dispatch]
  );

  const saveFormRespostasOnline = useCallback(
    async (formulario: FormularioRespostas.Request) => {
      try {
        const action = await dispatch(
          sendFormRespostas({
            id: Number(id),
            resposta: formulario,
          })
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const formulario = action.payload;

          setDadosModalFinalizacao({
            //@ts-ignore
            id: Number(formulario.id),
          });

          setShowModalFinalizacao(true);
        }
      } catch (error) {
        console.error('Erro ao enviar formulário:', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, id]
  );

  const handleFormSubmit = async (values: any) => {
    const respostasProcessadas = values.respostas?.map(
      (resposta: any, index: number) => {
        const pergunta = formDetails?.perguntas[index];
        return {
          descricaoResposta: resposta.descricaoResposta,
          alternativa: pergunta?.alternativas?.find(
            (alt) => alt.id === resposta.alternativa
          ),
          pergunta: pergunta,
        };
      }
    );
    let fverValue;
    if (params.local || !online) {
      fverValue = props.formResposta?.fver;
    } else {
      fverValue = formRespostaFver;
    }
    const formCustomDTO: FormularioRespostas.Request = {
      fver: {
        codigoEstabelecimento: fverValue!.codigoEstabelecimento,
        codigoVerificador: fverValue!.codigoVerificador,
        dataDaVisita: fverValue!.dataDaVisita,
        nomeEstabelecimento: fverValue!.nomeEstabelecimento,
        id: fverValue!.id,
        fvv: fverValue!.fvv,
        laudoIFSEAC: fverValue!.laudoIFSEAC,
        numero: fverValue!.numero,
        resumo: fverValue!.resumo,
        status: fverValue!.status,
        tipoEstabelecimento: fverValue!.tipoEstabelecimento,
      },
      municipio: municipioSelecionado,
      respostas: respostasProcessadas,
    };

    if (online) {
      if (params.local) {
        saveFormRespostaLocal(formCustomDTO);
      } else {
        if (shouldSaveOffline) saveFormRespostaLocal(formCustomDTO);
        else saveFormRespostasOnline(formCustomDTO);
      }
    } else {
      //@ts-ignore
      if (!formCustomDTO.id) saveFormRespostaLocal(formCustomDTO);
      else saveFormRespostaLocal(formCustomDTO);
    }
    return false;
  };

  const renderQuestionTypeInput = (question: Pergunta, index: number) => {
    switch (question.tipoPergunta) {
      case 'MULTIPLA_ESCOLHA':
        return (
          <Form.Item
            name={['respostas', index, 'alternativa']}
            style={{ marginBottom: 20 }}
          >
            <Radio.Group>
              {question.alternativas?.map((alt) => (
                <Space
                  key={alt.id}
                  direction='vertical'
                  style={{ padding: 8, display: 'block' }}
                >
                  <Radio value={alt.id}>{alt.descricao}</Radio>
                </Space>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case 'NUMERICO':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            style={{ marginBottom: 20 }}
          >
            <Input type='number' />
          </Form.Item>
        );
      case 'PARAGRAFO':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            style={{ marginBottom: 20 }}
          >
            <Input.TextArea />
          </Form.Item>
        );
      case 'RESPOSTA_CURTA':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            style={{ marginBottom: 20 }}
          >
            <Input />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const renderQuestionOffLineTypeInput = (
    question: Pergunta,
    resposta: Resposta,
    index: number
  ) => {
    switch (question.tipoPergunta) {
      case 'MULTIPLA_ESCOLHA':
        return (
          <Form.Item
            name={['respostas', index, 'alternativa']}
            initialValue={
              resposta?.alternativa ? resposta.alternativa.id : undefined
            }
            style={{ marginBottom: 20 }}
          >
            <Radio.Group>
              {question?.alternativas?.map((alt) => (
                <Space
                  key={alt.id}
                  direction='vertical'
                  style={{ padding: 8, display: 'block' }}
                >
                  <Radio value={alt.id}>{alt.descricao}</Radio>
                </Space>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case 'NUMERICO':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            initialValue={resposta ? resposta.descricaoResposta : ''}
            style={{ marginBottom: 20 }}
          >
            <Input type='number' />
          </Form.Item>
        );
      case 'PARAGRAFO':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            initialValue={resposta ? resposta.descricaoResposta : ''}
            style={{ marginBottom: 20 }}
          >
            <Input.TextArea />
          </Form.Item>
        );
      case 'RESPOSTA_CURTA':
        return (
          <Form.Item
            name={['respostas', index, 'descricaoResposta']}
            initialValue={resposta ? resposta.descricaoResposta : ''}
            style={{ marginBottom: 20 }}
          >
            <Input />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };

  const initialValues = {
    ...props.formResposta,
    fver: {
      codigoEstabelecimento: props.formResposta?.fver.codigoEstabelecimento,
      codigoVerificador: props.formResposta?.fver.codigoVerificador,
      dataDaVisita: props.formResposta?.fver.dataDaVisita,
      nomeEstabelecimento: props.formResposta?.fver.nomeEstabelecimento,
      id: props.formResposta?.fver.id,
      fvv: props.formResposta?.fver.fvv,
      laudoIFSEAC: props.formResposta?.fver.laudoIFSEAC,
      numero: props.formResposta?.fver.numero,
      resumo: props.formResposta?.fver.resumo,
      status: props.formResposta?.fver.status,
      tipoEstabelecimento: props.formResposta?.fver.tipoEstabelecimento,
    },
    municipio: props.formResposta?.municipio?.nome,
    respostas: props.formResposta?.respostas?.map((resposta) => ({
      descricaoResposta: resposta.descricaoResposta,
      alternativa: resposta.alternativa?.id,
    })),
  };

  const formData = online ? formDetails : formRespostasDetails;

  return (
    <>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='FormResposta'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />
      {formData && online && (
        <Form<FormDetailsType>
          form={form}
          layout='vertical'
          onFinish={handleFormSubmit}
          initialValues={initialValues}
        >
          <Divider orientation='left'>Dados do Formulário</Divider>
          <Title level={4}>{formData?.nome}</Title>
          <Paragraph>{formData?.descricao}</Paragraph>
          {fver && (
            <Col xs={24} lg={24} style={{ marginBottom: '24px' }}>
              <PanelFVERInput fver={fver} />
            </Col>
          )}
          {formData?.municipio !== undefined && online && (
            <>
              <Divider orientation='left'>Vincular Município</Divider>
              <Col span={24}>
                <Form.Item
                  label={'Município'}
                  name={'municipio'}
                  style={{
                    width: '100%',
                    marginBottom: '0',
                    marginTop: '0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, Selecione um município',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder='Selecione um município'
                    optionFilterProp='children'
                    onChange={handleSelectMunicipioChange}
                    filterOption={(input, option) => {
                      if (option && option.children)
                        return (
                          option.children
                            .toString()
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(input.toLowerCase()) >= 0
                        );

                      return false;
                    }}
                  >
                    {listaMunicipio?.map((municipio) => (
                      <Select.Option
                        key={municipio.codgIBGE}
                        value={JSON.stringify(
                          municipio || props.formResposta?.municipio
                        )}
                      >
                        {municipio?.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Divider orientation='left'>Perguntas</Divider>
          {formData?.perguntas
            ?.filter((question) => question.status === 'ATIVO')
            .map((question, index) => (
              <React.Fragment key={question.id || index}>
                <Typography.Text strong>{`${index + 1}: ${
                  question.descricao
                }`}</Typography.Text>
                {renderQuestionTypeInput(question, index)}
              </React.Fragment>
            ))}
          <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
            {!params.local && online && (
              <Col xs={24}>
                <Row justify={'end'}>
                  <Col>
                    <Form.Item>
                      <Switch
                        checked={shouldSaveOffline}
                        onChange={setShouldSaveOffline}
                      />
                      <span> Salvar offline</span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={12} lg={6}>
              <Button style={{ width: '100%' }} onClick={() => navigate('/')}>
                Cancelar
              </Button>
            </Col>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                htmlType={'submit'}
                type={'primary'}
                loading={loading}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {formData && !online && (
        <Form<FormDetailsType>
          form={form}
          layout='vertical'
          onFinish={handleFormSubmit}
          initialValues={initialValues}
        >
          <Divider orientation='left'>Dados do Formulário</Divider>
          <Title level={4}>{formData?.nome}</Title>
          <Paragraph>{formData?.descricao}</Paragraph>
          {props.formResposta?.fver && (
            <Card
              title={
                <Row justify='space-between'>
                  <Typography.Title level={4}>{'FVER'}</Typography.Title>
                  {props.showPrintButton && (
                    <Link
                      rel='noopener noreferrer'
                      target='_blank'
                      to={`/public/visualizarFVER/${props.formResposta.fver.codigoVerificador}`}
                    >
                      <Button
                        className='top'
                        type='primary'
                        icon={<PrinterOutlined />}
                      >
                        Imprimir
                      </Button>
                    </Link>
                  )}
                </Row>
              }
            >
              <Descriptions column={3} labelStyle={{ fontWeight: 'bold' }}>
                <Descriptions.Item label='Número' span={1}>
                  {props.formResposta.fver?.numero ? (
                    props.formResposta.fver?.numero
                  ) : (
                    <b style={{ color: '#f11d1d' }}>FVER não Sincronizado</b>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label='Data' span={2}>
                  {moment(props.formResposta.fver?.dataDaVisita).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </Descriptions.Item>

                <Descriptions.Item label='Estabelecimento' span={3}>
                  {`${props.formResposta.fver?.nomeEstabelecimento}`}
                </Descriptions.Item>
                <Descriptions.Item label='Tipo do establecimento' span={2}>
                  {`${
                    props.formResposta.fver?.tipoEstabelecimento
                      ? props.formResposta.fver?.tipoEstabelecimento
                      : '-'
                  }`}
                </Descriptions.Item>
                <Descriptions.Item label='Status' span={2}>
                  {`${
                    props.formResposta.fver?.status
                      ? props.formResposta.fver?.status
                      : '-'
                  }`}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          {props.formResposta?.municipio && (
            <>
              <Divider orientation='left'>Vincular Município</Divider>
              <Col span={24}>
                <Form.Item
                  label={'Município'}
                  name={'municipio'}
                  style={{
                    width: '100%',
                    marginBottom: '0',
                    marginTop: '0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, Selecione um município',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder='Selecione um município'
                    disabled
                    optionFilterProp='children'
                    onChange={handleSelectMunicipioChange}
                    filterOption={(input, option) => {
                      if (option && option.children)
                        return (
                          option.children
                            .toString()
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(input.toLowerCase()) >= 0
                        );

                      return false;
                    }}
                  >
                    {listaMunicipio?.map((municipio) => (
                      <Select.Option
                        key={municipio.codgIBGE}
                        value={JSON.stringify(props.formResposta?.municipio)}
                      >
                        {municipio?.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}{' '}
          <Divider orientation='left'>Perguntas</Divider>
          {formData?.respostas
            ?.filter((resposta) => resposta.pergunta.status === 'ATIVO')
            .map((resposta, index) => (
              <React.Fragment key={resposta.pergunta.id || index}>
                <Typography.Text strong>{`${index + 1}: ${
                  resposta.pergunta.descricao
                }`}</Typography.Text>
                {renderQuestionOffLineTypeInput(
                  resposta.pergunta,
                  resposta,
                  index
                )}
              </React.Fragment>
            ))}
          <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
            {!params.local && online && (
              <Col xs={24}>
                <Row justify={'end'}>
                  <Col>
                    <Form.Item>
                      <Switch
                        checked={shouldSaveOffline}
                        onChange={setShouldSaveOffline}
                      />
                      <span> Salvar offline</span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={12} lg={6}>
              <Button style={{ width: '100%' }} onClick={() => navigate('/')}>
                Cancelar
              </Button>
            </Col>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                htmlType={'submit'}
                type={'primary'}
                loading={loading}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
