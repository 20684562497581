import { Divider, Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormGRAF from './FormGRAF';

export default function CreateGRAF() {
  const { loading } = useLoadingPage();

  return (
    <>
      <Divider orientation='left'>
        <Typography.Title level={3}>
          Novo FormGraf
        </Typography.Title>
      </Divider>

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormGRAF />
      </Spin>
    </>
  );
}
