import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import TFRView from '../features/FormTFR/TFRView';
import useLoadingPage from '../../core/hooks/useLoadingPage';

export default function TFRViewWrapper() {
  usePageTitle('FormTfr');
  const { setLoading, setMode, setFirstOpening } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='termo fiscalização'>
        <TFRView />
      </ErrorBoundary>
    </>
  );
}
