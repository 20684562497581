export enum FuncoesNoEstabelecimento {
  FUNCIONARIO = 'Funcionário (administrador, capataz, caseiro, etc)',
  MEDICO_VETERINARIO = 'Médico Veterinário',
  PARENTE = 'Parente',
  PRODUTOR = 'Produtor',
  PROPRIETARIO = 'Proprietário',
}

export namespace FuncoesNoEstabelecimento {
  export function keys(): string[] {
    return Object.keys(FuncoesNoEstabelecimento).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  /* export function list(funcao: FuncoesNoEstabelecimento): {
    key: string;
    value: string;
  }[] {
    return Object.keys(FuncoesNoEstabelecimento).map((key) => {
      return {
        key,
        value: FuncoesNoEstabelecimento[
          key as keyof typeof FuncoesNoEstabelecimento
        ] as string,
      };
    });
  } */

  export function valueOf(key: string) {
    return FuncoesNoEstabelecimento[
      key as keyof typeof FuncoesNoEstabelecimento
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(FuncoesNoEstabelecimento).indexOf(
      value as unknown as FuncoesNoEstabelecimento
    );

    const key = Object.keys(FuncoesNoEstabelecimento)[indexOfS];

    return key;
  }
}
