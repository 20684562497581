import localforage from 'localforage';
import {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from '../../sdk/services/indexeddb/ServiceIDB';
import FVVIDBService from '../../sdk/services/indexeddb/FVVIDB.service';
import FVERIDBService from '../../sdk/services/indexeddb/FVERIDB.service';
import AuthorizationService from '../auth/Authorization.service';
import LIAIDBService from '../../sdk/services/indexeddb/LIAIDB.service';
import { FVER } from '../../sdk/@types';
import FVERService from '../../sdk/services/SIZ-API/FVER.service';
import AtendimentoErroSincronizacaoService from '../../sdk/services/SIZ-API/AtendimentoErrosSincronizacao.service';
import EnvConfig from '../../app/EnvConfig';

export default function useSyncFVERs() {
  let erroDeSincronizacao = false;

  const setErroDeSincronizacao = (hasError: boolean) => {
    erroDeSincronizacao = hasError;
  };

  const temErroDeSincronizacao = () => {
    return erroDeSincronizacao;
  };

  const sync = async (manually?: boolean): Promise<string> => {
    const promise = new Promise<string>(async (resolve, reject) => {
      return await FVERIDBService.getAllFromInputTable()
        .then(async (listFVERsOffline) => {
          for (const fverOffline of listFVERsOffline) {
            if (
              fverOffline.status === 'SINCRONIZADO' ||
              fverOffline.status === 'NAO FINALIZADO'
            ) {
              //@TODO preguiça de negar a condição. Arrumar depois
            } else {
              await localforage.getItem('token').then(async (token) => {
                let access_token;
                if (token) access_token = token;
                else access_token = AuthorizationService.getAccessToken();

                if (!access_token) {
                  throw new Error('Sessão expirada');
                }

                let fverDTO: FVER.Input = {
                  ...fverOffline.payload,
                };

                try {
                  //add FVER
                  await FVERService.add(fverDTO)
                    .then(async (fverResponse) => {
                      let newFVER: ServiceIDBPayloadInput = {
                        ...fverOffline,
                        payload: {
                          ...fverDTO,
                          id: fverResponse.id,
                          numero: fverResponse.numero,
                        },
                        status: 'SINCRONIZADO',
                      };

                      //update local FVER
                      await FVERIDBService.update(
                        //@ts-ignore
                        fverOffline.id,
                        newFVER
                      );

                      //update local FVV
                      await FVVIDBService.getByCodigoVerificador(
                        fverResponse.codigoVerificador
                      )
                        .then(async (fvv) => {
                          if (fvv) {
                            let newFVV: ServiceIDBPayload = {
                              ...fvv,
                              payload: {
                                ...fvv.payload,
                                visitaPropriedadeRural: newFVER.payload,
                              },
                            };

                            await FVVIDBService.update(
                              //@ts-ignore
                              fvv.id,
                              newFVV
                            );
                          }
                        })
                        .catch((e) => {});

                      //update local LIA
                      await LIAIDBService.getByCodigoVerificador(
                        fverResponse.codigoVerificador
                      ).then(async (lia) => {
                        if (lia) {
                          let newLIA: ServiceIDBPayload = {
                            ...lia,
                            payload: {
                              ...lia.payload,
                              fver: newFVER.payload,
                            },
                          };

                          await LIAIDBService.update(
                            //@ts-ignore
                            lia.id,
                            newLIA
                          );
                        }
                      });
                    })
                    .catch(async (e) => {
                      console.log('e', e);

                      let motivoErro;
                      if (e.message.includes('400'))
                        motivoErro =
                          'Erro ao enviar os dados. Contate o administrador do sistema.';
                      else motivoErro = e.message;

                      setErroDeSincronizacao(true);
                      let newFVER: ServiceIDBPayloadInput = {
                        ...fverOffline,
                        status: 'ERRO',
                        motivoErro,
                      };

                      //update local
                      await FVERIDBService.update(
                        //@ts-ignore
                        fverOffline.id,
                        newFVER
                      );

                      //add atendimentoSincronizacao
                      await AtendimentoErroSincronizacaoService.add({
                        idbId: fverOffline.id!,
                        json: JSON.stringify(fverOffline),
                        motivo: motivoErro,
                        tipoFormulario: 'FVER',
                        versao: EnvConfig.version(),
                      });
                    });
                } catch (e: any) {
                  throw new Error(e.message);
                }
              });
            }
          }
        })
        .then(async () => {
          if (!temErroDeSincronizacao()) {
            return resolve('Os FVERs foram sincronizados com sucesso');
          } else {
            return reject(
              'A sincronização dos FVERs foi finalizada com erros. Alguns FVERs não puderam ser sincronizadas. Revise-os e tente novamente'
            );
          }
        });
    });

    return await promise;
  };

  return sync;
}
