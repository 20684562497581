import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';

import useAssinaturaBase64Image from '../../../core/hooks/useAssinaturaBase64Image';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';

import SignatureWrapper from '../../components/SignatureWrapper';

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function ParecerFinal(props: InformacoesCadastroProps) {
  const { Option } = Select;
  const [selectValueParecerFinal, setSelectValueParecerFinal] = useState<
    string | null
  >(null);
  const [formDisabled] = useState<boolean>(false);
  const [statusAssinaturaState, setStatusAssinaturaState] = useState<any>();
  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();

  const handleChangeParecerFinal = (selectedValue: string) => {
    setSelectValueParecerFinal(selectedValue);
    props.form.setFieldsValue({ parecer: selectedValue } as any);
  };
  const listaStatusAssinatura = StatusAssinatura.keys();

  useEffect(() => {
    if (props.form)
      setAssinaturaBase64Image(props.form.getFieldValue('assinatura'));
  }, [props.form, setAssinaturaBase64Image]);

  return (
    <>
      <Typography.Title level={5}>Parecer Final</Typography.Title>
      <Divider />
      <Row gutter={24}>
        <Col style={{ display: 'flex', alignItems: 'center' }} xs={24} lg={24}>
          <Col lg={12}>
            <Form.Item
              name={'parecer'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Select
                disabled={props.formDisabled}
                size={'large'}
                placeholder='Selecione'
                onChange={handleChangeParecerFinal}
                value={selectValueParecerFinal}
              >
                <Option value='FAVORAVEL_REGISTRO'>
                  Favorável ao Registro
                </Option>
                <Option value='NAO_FAVORAVEL_REGISTRO'>
                  Não Favorável ao Registro
                </Option>
                <Option value='FAVORAVEL_MANUTENCAO_REGISTRO'>
                  Favorável à Manutenção do Registro
                </Option>
                <Option value='FAVORAVEL_MANUTENCAO_REGISTRO_ADVERTIDO'>
                  Favorável à Manutenção do Registro – Estabelecimento Advertido
                </Option>
                <Option value='NAO_FAVORAVEL_MANUTENCAO_REGISTRO_SUSPENSO_ALOJAMENTO_AVES'>
                  Não Favorável à Manutenção do Registro – Suspenso para
                  Alojamento de Aves
                </Option>
                <Option value='NAO_FAVORAVEL_MANUTENCAO_REGISTRO_CANCELAMENTO'>
                  Não Favorável à Manutenção do Registro – Cancelamento do
                  Registro
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={15} lg={8}>
            <Form.Item
              name={'dataParecer'}
              rules={[{ required: true, message: 'O campo é obrigatório' }]}
              initialValue={moment.tz(
                moment().toLocaleString(),
                'America/Cuiaba'
              )}
            >
              <DatePicker
                onClick={() => {
                  scrollFieldToTop('dataParecer');
                }}
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                disabledDate={(date) => date.isAfter(moment().toDate())}
                disabled={props.formDisabled}
                inputReadOnly
              />
            </Form.Item>
          </Col>
        </Col>
        <Divider />
        <Divider orientation='left'>Assinatura do responsável</Divider>

        <Col xs={24} lg={24}>
          <Form.Item
            label={'Como foi realizada a assinatura?'}
            name={'statusAssinatura'}
            valuePropName={'checked'}
            rules={[{ required: true, message: 'O campo é obrigatório' }]}
            initialValue={props.form.getFieldValue('statusAssinatura')}
          >
            <Radio.Group
              disabled={props.formDisabled}
              value={
                statusAssinaturaState ||
                props.form.getFieldValue('statusAssinatura')
              }
              onChange={(e) => {
                setStatusAssinaturaState(e.target.value);
                props.form.setFieldsValue({ statusAssinatura: e.target.value });
              }}
            >
              <Space direction='vertical'>
                {listaStatusAssinatura?.map((statusAssinatura) => (
                  <Radio key={statusAssinatura} value={statusAssinatura}>
                    {StatusAssinatura.valueOf(statusAssinatura)}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>

        {statusAssinaturaState ===
          StatusAssinatura.keyOf(StatusAssinatura.ASSINADO_DIGITALMENTE) && (
          <Col span={24}>
            <Form.Item
              validateTrigger={'onSubmit'}
              name='assinatura'
              rules={[
                {
                  validator(_, value) {
                    if (
                      statusAssinaturaState ===
                      StatusAssinatura.keyOf(
                        StatusAssinatura.ASSINADO_DIGITALMENTE
                      )
                    ) {
                      if (!assinaturaBase64Image)
                        return Promise.reject(
                          new Error(
                            'Deve ser informada a assinatura do produtor'
                          )
                        );
                      return Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <SignatureWrapper
                default={props.form.getFieldValue('assinatura')}
                disabled={formDisabled}
                onSaveSignature={() => {
                  props.form.validateFields(['assinatura']);
                }}
                onClearSignature={() => {
                  props.form.setFieldsValue({ assinatura: undefined });
                }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
}
