export enum StageType {
  DEV = 'DESENVOLVIMENTO',
  HOMOLOG = 'HOMOLOGAÇÃO',
  PROD = 'PRODUÇÃO',
}
export namespace StageType {
  export function keys(): string[] {
    return Object.keys(StageType).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return StageType[key as keyof typeof StageType];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(StageType).indexOf(
      value as unknown as StageType
    );

    const key = Object.keys(StageType)[indexOfS];

    return key;
  }
}
