import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  notification,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface ProductEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues: any;
  onClearForm: () => void;
}

const ProductEditModal: React.FC<ProductEditModalProps> = ({
  visible,
  onCancel,
  onOk,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [tipo, setTipo] = useState<string | undefined>(
    initialValues?.tipo || ''
  );
  const [tipoBiologico, setTipoBiologico] = useState<string | undefined>(
    initialValues?.tipoBiologico || ''
  );
  const [armazenadoAdequadamente, setArmazenadoAdequadamente] = useState<
    string | undefined
  >(initialValues?.armazenadoAdequadamente || '');
  const [refrigerado, setRefrigerado] = useState<string | undefined>(
    initialValues?.refrigerado || ''
  );
  const [status, setStatus] = useState<string | undefined>(
    initialValues?.status || ''
  );
  const [produtoDevolvidoFornecedor, setProdutoDevolvidoFornecedor] = useState<
    string | undefined
  >(initialValues?.produtoDevolvidoFornecedor || '');

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues || {});
      setTipo(form.getFieldValue('tipoProduto') || '');
      setTipoBiologico(form.getFieldValue('subTipoProduto') || '');
      setArmazenadoAdequadamente(
        form.getFieldValue('produtoArmazenadoAdequadamente') || ''
      );
      setRefrigerado(form.getFieldValue('produtoPrecisaSerRefrigerado') || '');
      setStatus(form.getFieldValue('status') || '');
      setProdutoDevolvidoFornecedor(
        form.getFieldValue('produtoDevolvidoFornecedor') || ''
      );
    }
  }, [initialValues, form, visible]);

  const handleOk = (event: any) => {
    event.stopPropagation();
    form
      .validateFields()
      .then((values) => {
        if (status !== 'NAO_RECEBIDO') {
          values.produtoDevolvidoFornecedor = produtoDevolvidoFornecedor;
        }
        onOk({ ...initialValues, ...values });
        form.resetFields();
        onCancel();
        notification.success({
          message: 'Produto adicionado com sucesso',
        });
      })
      .catch((errorInfo) => {
        console.log('Erro ao adicionar o produto:', errorInfo);
      });
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
    if (value !== 'NAO_RECEBIDO') {
      form.setFieldsValue({ produtoDevolvidoFornecedor: 'NA' });
      setProdutoDevolvidoFornecedor('NA');
    } else {
      setProdutoDevolvidoFornecedor(undefined);
    }
  };

  return (
    <Modal
      title='Dados do produto'
      visible={visible}
      width={'50%'}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk}>
          Adicionar
        </Button>,
      ]}
    >
      <Form form={form} initialValues={initialValues} layout='vertical'>
        <Divider orientation='left'>Dados do produto</Divider>
        <Row gutter={24}>
          <Form.Item name={['produto', 'id']} hidden>
            <Input disabled hidden />
          </Form.Item>
          <Form.Item name={['produto', 'grupoProduto']} hidden>
            <Input disabled hidden />
          </Form.Item>
          <Form.Item name={['produto', 'laboratorioFarmaceutico']} hidden>
            <Input disabled hidden />
          </Form.Item>
          <Col xs={24} lg={24}>
            <Form.Item label='Nome comercial' name={['produto', 'nome']}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={18}>
            <Form.Item
              label='Laboratório'
              name={['produto', 'laboratorioFarmaceutico', 'nome']}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item
              label='Partida'
              name={['numeroPartida']}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Form.Item
              label='Validade'
              name='dataValidade'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label='Fabricação'
              name='dataFabricacao'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label='Tipo'
              name='tipoProduto'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select onChange={(value) => setTipo(value)}>
                <Select.Option value='FARMACO'>Fármaco</Select.Option>
                <Select.Option value='BIOLOGICO'>Biológico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {tipo === 'BIOLOGICO' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label='Tipo Biológico'
                name='subTipoProduto'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select onChange={(value) => setTipoBiologico(value)}>
                  <Select.Option value='BRUCELOSE'>Brucelose</Select.Option>
                  <Select.Option value='FEBRE_AFTOSA'>
                    Febre Aftosa
                  </Select.Option>
                  <Select.Option value='RAIVA'>Raiva</Select.Option>
                  <Select.Option value='OUTRO'>Outro</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label='Quantidade de doses'
                name='quantidade'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {tipoBiologico === 'OUTRO' && (
              <Col xs={24} lg={12}>
                <Form.Item
                  label='Outro tipo biológico'
                  name='outroTipoBiologico'
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        {tipo === 'FARMACO' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label='Quantidade'
                name='quantidade'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xs={24} lg={24}>
            <Form.Item label='Observação' name='observacao'>
              <TextArea rows={4} maxLength={4000} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation='left'>Conservação do produto</Divider>
        <Row gutter={24}>
          <Col xs={24} lg={24}>
            <Form.Item
              label='Produto armazenado adequadamente?'
              name='produtoArmazenadoAdequadamente'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Radio.Group
                onChange={(e) => setArmazenadoAdequadamente(e.target.value)}
              >
                <Radio value='SIM'>Sim</Radio>
                <Radio value='NAO'>Não</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {armazenadoAdequadamente === 'NAO' && (
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label='Justificativa para produto não armazenado adequadamente'
                name='justificativaProdutoArmazenadoAdequadamente'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <TextArea rows={4} maxLength={2000} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xs={24} lg={24}>
            <Form.Item
              label='Produto precisa ser refrigerado?'
              name='produtoPrecisaSerRefrigerado'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Radio.Group onChange={(e) => setRefrigerado(e.target.value)}>
                <Radio value='SIM'>Sim</Radio>
                <Radio value='NAO'>Não</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={24}>
            <Form.Item
              label='Temperatura da geladeira é adequada (+2ºC a +8ºC)?'
              name='temperaturaGeladeiraAdequada'
              rules={[
                {
                  required: refrigerado === 'SIM' ? true : false,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Radio.Group>
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NAO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              label='Temperatura atual'
              name='temperaturaAtual'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label='Status'
              name='status'
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select onChange={handleStatusChange}>
                <Select.Option value='RECEBIDO'>Recebido</Select.Option>
                <Select.Option value='PENDENTE'>Pendente</Select.Option>
                <Select.Option value='NAO_RECEBIDO'>Não Recebido</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {status === 'NAO_RECEBIDO' && (
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label='Produto deverá ser devolvido ao fornecedor?'
                name='produtoDevolvidoFornecedor'
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Radio.Group
                  onChange={(value) =>
                    setProdutoDevolvidoFornecedor(value.target.value)
                  }
                >
                  <Radio value='SIM'>Sim</Radio>
                  <Radio value='NAO'>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default ProductEditModal;
