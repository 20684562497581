import { useCallback, useState } from 'react';
import { Servidor } from '../../sdk/@types/Servidor';
import ServidorIDBService from '../../sdk/services/indexeddb/ServidorIDB.service';
import ServidorService from '../../sdk/services/SIZ-API/Servidor.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useServidor() {
  const { online } = useNavigatorStatus();
  const [fetchingServidor, setFetchingServidor] = useState<boolean>(false);

  const fetchExistingServidorByCpf = useCallback(
    async (
      cpf: string,
      fromApi?: boolean
    ): Promise<Servidor.Summary | null> => {
      try {
        setFetchingServidor(true);

        if (!fromApi) {
          return await ServidorIDBService.getByCpf(cpf).then(
            async (payload) => {
              if (payload) {
                return payload?.payload;
              } else if (online) {
                return await ServidorService.getByCpf(cpf);
              } else return null;
            }
          );
        } else return await ServidorService.getByCpf(cpf);
      } catch (err) {
        throw err;
      } finally {
        setFetchingServidor(false);
      }
    },
    [online]
  );

  const fetchExistingServidorByMatricula = useCallback(
    async (matricula: string, fromApi?: boolean) => {
      try {
        setFetchingServidor(true);

        if (!fromApi) {
          return await ServidorIDBService.getByMatricula(matricula).then(
            async (payload) => {
              if (payload) {
                return payload?.payload;
              } else if (online) {
                return await ServidorService.getByMatricula(matricula);
              } else return null;
            }
          );
        } else return await ServidorService.getByMatricula(matricula);
      } catch (err) {
        throw err;
      } finally {
        setFetchingServidor(false);
      }
    },
    [online]
  );

  return {
    fetchingServidor,
    fetchExistingServidorByCpf,
    fetchExistingServidorByMatricula,
  };
}
