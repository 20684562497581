import { TipoChavePrincipalFVER } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';

class TipoChavePrincipalFVERIDBService {
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL =
    'tipoChavePrincipalVisitaPropriedadeRural';
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL_INDEX_NAME =
    'tipoChavePrincipalVisitaPropriedadeRural_index_id';
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL_INDEX_KEY =
    'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(
        TipoChavePrincipalFVERIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL
      )
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      TipoChavePrincipalFVERIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL
    );
    return list;
  }

  static async getAllPayload(): Promise<TipoChavePrincipalFVER.Summary[]> {
    let list: TipoChavePrincipalFVER.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllTipoChavePrincipalVisitaPropriedadeRural(
    lista: TipoChavePrincipalFVER.Summary[]
  ) {
    try {
      const db = await openDB(DBConfig.DB_NAME);
      lista.forEach((tipo) => {
        const date = new Date();
        const obj = {
          date: date,
          payload: tipo,
        };

        db.add(
          this.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL,
          obj
        ).catch((e) => {
          return;
        });
      });
    } catch (e) {
      return;
    }
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL);
  }
}

export default TipoChavePrincipalFVERIDBService;
