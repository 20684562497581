import md5 from 'md5';
import { SessionCredentials } from '../../@types';
import { Usuario } from '../../@types/Usuario';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class UsuarioService extends Service {
  static getAll(query: Usuario.Query): Promise<Usuario.Summary[]> {
    const queryString = generateQueryString(query);

    return this.Http.get<Usuario.Summary[]>(
      '/usuarios'.concat(queryString)
    ).then(this.getData);
  }

  static async getByCpf(cpf: string): Promise<Usuario.Detailed> {
    return await this.Http.get<Usuario.Detailed>(`/usuarios/cpf/${cpf}`).then(
      this.getData
    );
  }

  static async getByUserName(userName: string): Promise<Usuario.Detailed> {
    return await this.Http.get<Usuario.Detailed>(`/usuarios/${userName}`).then(
      this.getData
    );
  }

  static async checkIfExists(version: string): Promise<boolean> {
    const queryString = generateQueryString({ version: version });
    return await this.Http.get<boolean>(
      `/usuarios/auth`.concat(queryString)
    ).then(this.getData);
  }

  static async login(
    credentials: SessionCredentials.Input
  ): Promise<Usuario.Detailed> {
    const credentialsMD5 = {
      userName: credentials.userName,
      password: md5(credentials.password),
    };
    return await this.Http.post<Usuario.Detailed>(
      '/login',
      credentialsMD5
    ).then((u) => {
      if (u) return this.getData(u);
      else return Promise.reject('Resposta inválida');
    });
  }

  static async updatePermissoes(usuarioPermissoes: Usuario.UsuarioPermissao) {
    return await this.Http.post<Usuario.UsuarioPermissao>(
      `/usuarios/permissoes`,
      usuarioPermissoes
    ).then(this.getData);
  }
}

export default UsuarioService;
