import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as BadgeStateActions from '../store/BadgeState.slice';

export default function useBadgeState() {
  const dispatch = useDispatch();

  const notificacoes = useSelector(
    (state: RootState) => state.badgeState.notificacoes
  );

  const quantidadeAcoesNaoSincronizadas = useSelector(
    (state: RootState) => state.badgeState.quantidadeAcoesNaoSincronizadas
  );

  const setNotificacoes = useCallback(
    (notificacoes: number) => {
      dispatch(BadgeStateActions.setNotificacoes(notificacoes));
    },
    [dispatch]
  );

  const setQuantidadeAcoesNaoSincronizadas = useCallback(
    (quantidadeAcoesNaoSincronizadas: number) => {
      dispatch(
        BadgeStateActions.setQuantidadeAcoesNaoSincronizadas(
          quantidadeAcoesNaoSincronizadas
        )
      );
    },
    [dispatch]
  );

  return {
    notificacoes,
    quantidadeAcoesNaoSincronizadas,
    setNotificacoes,
    setQuantidadeAcoesNaoSincronizadas,
  };
}
