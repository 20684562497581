import {
  Form,
  Row,
  Col,
  Divider,
  Select,
  Input,
  notification,
  Space,
  FormInstance,
  Button,
  Card,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';

import { CoordenadaGeografica, LocalAcaoEducativa } from '../../../sdk/@types';
import { useNavigate, useParams } from 'react-router-dom';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';

import { usePrompt } from '../../../core/hooks/usePrompt';
import { FormDataModalFinalizacao } from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import TextArea from 'antd/lib/input/TextArea';
import CoordenadasPanel from '../../components/CoordenadasPanel';
import { coordinatesDDtoDMS } from '../../../core/functions/coordinatesUtil';
import { TipoLocalAcaoEducativa } from '../../../core/enums/TipoLocalAcaoEducativaSelect';
import {
  insertLocalAcaoEducativa,
  updateLocalAcaoEducativa,
} from '../../../core/store/LocalAcaoEducativa.slice';
import { useDispatch } from 'react-redux';
import { CustomModal } from '../../components/CustomModal';

export type FormLocalAcaoEducativaType = {
  form: FormInstance;
  formDisabled: boolean;
} & LocalAcaoEducativa.Request;

type LocalAcaoEducativaProps = {
  localAcaoEducativa?: FormLocalAcaoEducativaType;
};

export default function FormCadastroLocalAcaoEducativa(
  props: LocalAcaoEducativaProps
) {
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const dispatch = useDispatch();
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { mode, setLoading } = useLoadingPage();

  const [form] = Form.useForm<FormLocalAcaoEducativaType>();

  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);
  }, [mode, form]);

  useEffect(() => {
    if (props.localAcaoEducativa) {
      form.setFieldsValue(props.localAcaoEducativa);
    }
  }, [props.localAcaoEducativa, form]);

  const saveLocalAcaoEducativa = useCallback(
    async (localAcaoEducativa: LocalAcaoEducativa.Request) => {
      try {
        const action = await dispatch(
          //@ts-ignore
          localAcaoEducativa.id
            ? updateLocalAcaoEducativa({
                id: Number(params.id),
                localAcaoEducativa: localAcaoEducativa,
              })
            : insertLocalAcaoEducativa(localAcaoEducativa)
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const localAcaoEducativa = action.payload;
          setDadosModalFinalizacao({
            //@ts-ignore
            id: localAcaoEducativa.id,
          });

          setShowModalFinalizacao(true);
        }
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormLocalAcaoEducativaType) => {
      setLoading(true);

      let coordenadaGeograficaRequest:
        | CoordenadaGeografica.Detailed
        | undefined = undefined;

      if (formParam.coordenadaGeografica) {
        coordenadaGeograficaRequest = {
          ...formParam.coordenadaGeografica,
          pessoa: {
            id: formParam.coordenadaGeografica.pessoa?.id!,
            cpfCnpj: formParam.coordenadaGeografica.pessoa?.cpfCnpj!,
          },
        };
      }

      const localAcaoEducativaDTO: LocalAcaoEducativa.Request = {
        ...formParam,
        coordenadaGeografica: coordenadaGeograficaRequest,
      };

      try {
        saveLocalAcaoEducativa(localAcaoEducativaDTO);
      } catch (error) {
        notification.error({
          message: 'Erro ao enviar formulário',
          description: (error as Error).message,
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, saveLocalAcaoEducativa]
  );

  const updateCoordsOnForm = (coords: GeolocationCoordinates) => {
    const coordenadaGeografica = coordinatesDDtoDMS(
      coords.latitude,
      coords.longitude
    );

    form.setFieldsValue({
      coordenadaGeografica: {
        latGrau: coordenadaGeografica.latGrau,
        latMin: coordenadaGeografica.latMin,
        latSeg: coordenadaGeografica.latSeg,
        longGrau: coordenadaGeografica.longGrau,
        longMin: coordenadaGeografica.longMin,
        longSeg: coordenadaGeografica.longSeg,
        orientacaoLatitude: coordenadaGeografica.orientacaoLatitude,
        orientacaoLongitude: coordenadaGeografica.orientacaoLongitude,
      },
    });
  };

  return (
    <>
      <fieldset disabled={formDisabled}>
        <Form<FormLocalAcaoEducativaType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          autoComplete={'off'}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={props.localAcaoEducativa}
        >
          <Row gutter={24}>
            <Form.Item name={'id'} hidden>
              <Input disabled hidden />
            </Form.Item>
          </Row>
          <Row>
            <Col xs={24} lg={24}>
              <Space direction={'vertical'} style={{ width: '100%' }}>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Tipo de local da ação educativa'}
                      name={'tipoLocalAcaoEducativa'}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                      ]}
                    >
                      <Select
                        onClick={() => {
                          scrollFieldToTop('tipoEstabelecimento');
                        }}
                        placeholder={'Selecione o tipo do estabelecimento'}
                        disabled={formDisabled}
                        placement={'bottomLeft'}
                      >
                        {TipoLocalAcaoEducativa.keys().map((key) => (
                          <Select.Option key={key} value={key}>
                            {TipoLocalAcaoEducativa.valueOf(key)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Nome'}
                      name={'nome'}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                      ]}
                    >
                      <Input type='text' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={24}>
                    <Form.Item
                      label={'Descrição'}
                      name={'descricao'}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                    {
                      <CoordenadasPanel
                        formDisabled={formDisabled}
                        form={form}
                        pathToCoordenada={[]}
                        onAcceptCoords={(coords) => {
                          updateCoordsOnForm(coords);
                        }}
                      />
                    }
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
          <Divider />

          <Row justify={'end'} gutter={24}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(
                    `${online ? '/locaisAcaoEducativa' : '/acoes-de-campo'}`
                  )
                }
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/locaisAcaoEducativa/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>
      <CustomModal
        centered
        open={showModalFinalizacao}
        width={750}
        footer={null}
        destroyOnClose
        closable={false}
        title={`Local da ação educativa salvo com sucesso`}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          bordered={false}
        >
          <Row
            gutter={24}
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Col xs={24} sm={24}>
              <Typography.Paragraph>
                {online
                  ? 'O Local da ação educativa foi salvo no servidor e não necessita sincronização'
                  : 'Local da ação educativa salvo localmente'}
              </Typography.Paragraph>
            </Col>
            {dadosModalFinalizacao?.networkError && (
              <Col xs={24} sm={24}>
                <Typography.Paragraph>
                  Houve um problema enquanto tentamos nos comunicar com o
                  servidor.
                </Typography.Paragraph>
              </Col>
            )}
          </Row>

          <Row
            align='middle'
            style={{
              justifyContent: 'center',
            }}
            gutter={24}
          >
            <Col>
              <Button
                onClick={() =>
                  navigate(`${online ? '/locaisAcaoEducativa' : '/'}`)
                }
                type={'primary'}
              >
                {' '}
                Voltar{' '}
              </Button>
            </Col>
            <Col>
              {online && (
                <Button
                  onClick={() =>
                    navigate(
                      `/locaisAcaoEducativa/visualizar/${dadosModalFinalizacao?.id}`
                    )
                  }
                  type={'primary'}
                >
                  {' '}
                  Visualizar{' '}
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </CustomModal>
    </>
  );
}
