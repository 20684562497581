export enum TipoEstabelecimento {
  ABATEDOURO = 'Abatedouro',
  PROPRIEDADE = 'Estabelecimento Rural',
  RECINTO = 'Recinto',
}
export namespace TipoEstabelecimento {
  export function keys(): string[] {
    return Object.keys(TipoEstabelecimento).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoEstabelecimento[key as keyof typeof TipoEstabelecimento];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoEstabelecimento).indexOf(
      value as unknown as TipoEstabelecimento
    );

    const key = Object.keys(TipoEstabelecimento)[indexOfS];

    return key;
  }
}
