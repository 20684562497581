import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';
import { LIA } from '../../@types/LIA';

class LIAIDBService extends ServiceIDB {
  static DB_STORE_LIA_INPUT = 'lifseac_request';

  static DB_STORE_LIA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME =
    'lifseac_list_fver_codigo_verificador';
  static DB_STORE_LIA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_KEY =
    'payload.fver.codigoVerificador';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(LIAIDBService.DB_STORE_LIA_INPUT)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByCodigoVerificador(
    codigoVerificador: string
  ): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_LIA_INPUT,
      this.DB_STORE_LIA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME,
      codigoVerificador
    );
  }

  static async getAll(): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(LIAIDBService.DB_STORE_LIA_INPUT);
    return list;
  }

  static async getAllFromInputTable(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(LIAIDBService.DB_STORE_LIA_INPUT);
    return list;
  }

  static async getAllFromInputTableOnlyPayload(): Promise<LIA.Request[]> {
    let list: LIA.Request[] = [];

    await this.getAllFromInputTable().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<LIA.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const lia = await db.get(LIAIDBService.DB_STORE_LIA_INPUT, id);

    return lia.payload;
  }

  static async getByIdFromInputTable(id: number): Promise<LIA.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const lia = await db.get(LIAIDBService.DB_STORE_LIA_INPUT, id);

    return lia.payload;
  }

  static async insert(lia: LIA.Request) {
    const db = await openDB(DBConfig.DB_NAME);

    const obj: ServiceIDBPayloadInput = {
      date: new Date(),
      payload: lia,
      status: 'NOVO',
    };

    return db.add(this.DB_STORE_LIA_INPUT, obj);
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_LIA_INPUT);
  }

  static async delete(id: number) {
    return await openDB(DBConfig.DB_NAME).then(
      async (db) => await db.delete(this.DB_STORE_LIA_INPUT, id)
    );
  }

  static async update(id: number, obj: ServiceIDBPayloadInput) {
    return await this.delete(id).then(
      async (id) =>
        await openDB(DBConfig.DB_NAME).then(
          async (db) => await db.add(this.DB_STORE_LIA_INPUT, obj)
        )
    );
  }

  static async put(id: number, obj: LIA.Request[]) {
    const db = await openDB(DBConfig.DB_NAME);
    const oldLIA: ServiceIDBPayloadInput = await db.get(
      LIAIDBService.DB_STORE_LIA_INPUT,
      id
    );
    const newLIA: ServiceIDBPayloadInput = {
      ...oldLIA,
      payload: obj,
    };

    db.put(LIAIDBService.DB_STORE_LIA_INPUT, newLIA);
  }

  static async count() {
    return this._count(this.DB_STORE_LIA_INPUT);
  }
}

export default LIAIDBService;
