import { FormGraf } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormGrafService extends Service {
  static BASE_URL = '/form-grafs';

  static getAll(query: FormGraf.Query): Promise<FormGraf.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<FormGraf.Paginated>(
      this.BASE_URL.concat(queryString)
    ).then(this.getData);
  }

  static async add(
    formGrafRequest: FormGraf.Request
  ): Promise<FormGraf.Request> {
    try {
      const response = await this.Http.post<FormGraf.Request>(
        this.BASE_URL,
        formGrafRequest
      );
      return this.getData(response);
    } catch (error) {
      throw error;
    }
  }

  static async update(id: number, data: FormGraf.Request) {

    return this.Http.put<FormGraf.Request>(`${this.BASE_URL}/${id}`, data).then(
      this.getData
    );
  }

  static getById(id: number) {
    return this.Http.get<FormGraf.Response>(`${this.BASE_URL}/${id}`).then(
      this.getData
    );
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`${this.BASE_URL}/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }
}

export default FormGrafService;
