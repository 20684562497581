import { FVERGeralReport } from '../../sdk/@types/FVERGeralReport';
import * as XLSX from 'xlsx';
import moment from 'moment';

const getReportData = async (visitas: FVERGeralReport.Summary[]) => {
  let data: any[] = [];
  let visitaArray: any[];

  const headers = [
    [
      'Município',
      'Código IBGE',
      'Número',
      'Data da Visita',
      'Data de Cadastro',
      'Tipo Estabelecimento',
      'Nome Estabelecimento',
      'Código Estabelecimento',
      'Código Exploração',
      'Nome Proprietário',

      'Latitude - grau',
      'Latitude - min',
      'Latitude - seg',
      'Longitude - grau',
      'Longitude - min',
      'Longitude - seg',

      'Resumo',
      'Chave Primária',
      'Abertura de cadastro pecuário novo',
      'Aplicação de vacina',
      'Aprensão',
      'Atualização do cadastro pecuário',
      'Autuação',
      'Captura',
      'Coleta de amostra para exame ou prova diagnóstica',
      'Controle de movimentação de subprodutos',
      'Desinfecção',
      'Desinterdição',
      'Destruição',
      'Educação Sanitária',
      'Fiscalização da vacinação',
      'Fiscalização do uso de farmoquímico',
      'Ingresso e trânsito de animal de outra UF',
      'Inspeção clínica',
      'Inspeção física e sanitária a estabelecimento',
      'Interdição',
      'Isolamento',
      'Laudo de Inspeção Física e Sanitária e Estabelecimento Avícola',
      'Marcação',
      'Notificação',
      'Sacrifício',
      'Saneamento',
      'Vistoria de Rebanho',
      'Servidores que Realizaram a Visita',
    ],
  ];

  if (visitas) {
    visitas.forEach((visita) => {
      visitaArray = [];

      visitaArray.push(visita.municipio);
      visitaArray.push(visita.codg_ibge);
      visitaArray.push(visita.numero);
      visitaArray.push(
        moment(visita.dataDaVisita).format('DD/MM/YYYY').toString()
      );
      visitaArray.push(
        moment(visita.dataCadastro).format('DD/MM/YYYY').toString()
      );
      visitaArray.push(visita.tipo_estabelecimento);
      visitaArray.push(visita.nome_propriedade);
      switch (visita.tipo_estabelecimento) {
        case 'PROPRIEDADE':
          visitaArray.push(
            '51' + visita.codigo_propriedade?.toString().padStart(9, '0')
          );
          break;
        case 'ABATEDOURO':
          visitaArray.push(
            '51' + visita.codigo_propriedade?.toString().padStart(8, '0')
          );
          break;
        case 'RECINTO':
          visitaArray.push(
            '51' + visita.codigo_propriedade?.toString().padStart(8, '0')
          );
          break;
      }

      visitaArray.push(
        visita.codigo_exploracao !== null
          ? '51' + visita.codigo_exploracao?.toString().padStart(8, '0')
          : ''
      );

      visitaArray.push(visita.nome_proprietario);

      visitaArray.push(visita.coordenadaGeografica?.latGrau);
      visitaArray.push(visita.coordenadaGeografica?.latMin);
      visitaArray.push(visita.coordenadaGeografica?.latSeg);
      visitaArray.push(visita.coordenadaGeografica?.longGrau);
      visitaArray.push(visita.coordenadaGeografica?.longMin);
      visitaArray.push(visita.coordenadaGeografica?.longSeg);

      visitaArray.push(visita.resumo);
      visitaArray.push(visita.chave_principal);
      visitaArray.push(visita.abert_de_cadastro_pec_novo);
      visitaArray.push(visita.aplicacao_de_vacina);
      visitaArray.push(visita.apreensao);
      visitaArray.push(visita.atual_cadastro_pec);
      visitaArray.push(visita.autuacao);
      visitaArray.push(visita.captura);
      visitaArray.push(visita.coleta_de_amostra);
      visitaArray.push(visita.controle_mov_subprod);
      visitaArray.push(visita.desinfeccao);
      visitaArray.push(visita.desinterdicao);
      visitaArray.push(visita.destruicao);
      visitaArray.push(visita.educacao_sanitaria);
      visitaArray.push(visita.fiscalizacao_vac);
      visitaArray.push(visita.fiscalizacao_uso_farmo);
      visitaArray.push(visita.ingresso_e_transito_animal);
      visitaArray.push(visita.inspecao_clinica);
      visitaArray.push(visita.inspecao_fisica_e_sanit);
      visitaArray.push(visita.interdicao);
      visitaArray.push(visita.isolamento);
      visitaArray.push(
        visita.laudo_de_inspecao_fisica_e_sanitaria_a_estabelevimento_avicola
      );
      visitaArray.push(visita.marcacao);
      visitaArray.push(visita.notificacao);
      visitaArray.push(visita.sacrificio);
      visitaArray.push(visita.saneamento);
      visitaArray.push(visita.vistoria_de_rebanho);
      visitaArray.push(visita.servidores_que_realizaram_visita);

      data.push(visitaArray);
    });
  }

  return { headers, data };
};

export default async function generateFVERReport(
  visitas: FVERGeralReport.Summary[]
) {
  const reportData = await getReportData(visitas);

  let worksheet = XLSX.utils.json_to_sheet(reportData.data);

  XLSX.utils.sheet_add_aoa(worksheet, reportData.headers, {
    origin: 'A1',
  });

  let workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, worksheet, 'FVER');

  XLSX.writeFile(workBook, `fvers.xlsx`);
}
