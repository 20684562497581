export enum MediaOptions {
  AUDIO = 'Áudio',
  CARD = 'Card (material mais espesso ou plastificado, não é entregue)',
  CARTILHA = 'Cartilha',
  CALENDARIO = 'Calendário',
  CARTAZ = 'Cartaz (fixado em paredes e murais)',
  BANNER = 'Banner (Grande dimensão, de uso perene)',
  FOLDER = 'Folder (Quatro páginas ou mais, com dobraduras no formato físico)',
  INFOGRAFICO = 'Infográfico',
  LIVRO = 'Livro',
  PANFLETO = 'Panfleto (Até duas páginas)',
  VIDEO = 'Vídeo',
}

export namespace MediaOptions {
  export function keys(): string[] {
    return Object.keys(MediaOptions).filter(
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return MediaOptions[key as keyof typeof MediaOptions];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(MediaOptions).indexOf(
      value as unknown as MediaOptions
    );

    const key = Object.keys(MediaOptions)[indexOfS];

    return key;
  }
}