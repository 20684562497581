import { Typography } from 'antd';

export interface ErrorDisplayProps {
  title?: string;
  message?: string;
  small?: boolean;
}

export default function ErrorDisplay(props: ErrorDisplayProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 8,
        alignItems: 'center',
        color: '#274060',
      }}
    >
      <Typography.Title level={2}>
        {props.title || 'Erro ao renderizar componente'}
      </Typography.Title>

      <span style={{ fontFamily: '"Roboto Mono", monospace', fontSize: 12 }}>
        {props.message || 'Erro desconhecido'}
      </span>
    </div>
  );
}
