import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormCadastroLocalAcaoEducativa from './FormCadastroLocalAcaoEducativa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as LocalAcaoEducativaActions from '../../../core/store/LocalAcaoEducativa.slice';

function EditLocalAcaoEducativa() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const localAcaoEducativa = useSelector(
    (state: RootState) => state.localAcaoEducativo.data
  );
  const loading = useSelector(
    (state: RootState) => state.localAcaoEducativo.loading
  );

  const prepareData = useCallback(
    (localAcaoEducativa) => ({
      ...localAcaoEducativa,
      dataDistribuicao: moment(localAcaoEducativa?.dataDistribuicao),
    }),
    []
  );
  useEffect(() => {
    if (!isNaN(Number(id))) {
      dispatch(
        LocalAcaoEducativaActions.fetchLocalAcaoEducativaById(Number(id))
      );
    }
  }, [id, local, dispatch]);

  return (
    <>
      {!localAcaoEducativa && <Skeleton active />}
      {localAcaoEducativa && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>
              Editando Local Ação Educativa
            </Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loading === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormCadastroLocalAcaoEducativa
              localAcaoEducativa={prepareData(localAcaoEducativa)}
            />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditLocalAcaoEducativa;
