import { FormIN } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class FormINIDBService extends ServiceIDB {
  static DB_STORE_FORM_IN = 'form_in';
  static DB_STORE_FORM_IN_INDEX_NUMERO_NAME = 'form_in_index_numero';
  static DB_STORE_FORM_IN_INDEX_NUMERO_KEY = 'payload.numero';

  static DB_STORE_FORM_IN_INDEX_MUNICIPIO_NAME = 'form_in_index_municipio';
  static DB_STORE_FORM_IN_INDEX_MUNICIPIO_KEY = 'payload.municipio.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(FormINIDBService.DB_STORE_FORM_IN)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByNumero(numeroFormIN: string): Promise<ServiceIDBPayload> {
    let numero = numeroFormIN;
    if (!numeroFormIN.includes('-')) {
      numero = numeroFormIN.replace(/(\d{7})/, '$1-');
    }
    return await super._getFromIndex(
      this.DB_STORE_FORM_IN,
      this.DB_STORE_FORM_IN_INDEX_NUMERO_NAME,
      numero
    );
  }

  static async getByMunicipio(id: string): Promise<ServiceIDBPayload[]> {
    return await super._getAllFromIndex(
      this.DB_STORE_FORM_IN,
      this.DB_STORE_FORM_IN_INDEX_MUNICIPIO_NAME,
      id
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FormINIDBService.DB_STORE_FORM_IN);
    return list;
  }

  static async getAllPayload(): Promise<FormIN.Summary[]> {
    let list: FormIN.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super
      ._getAllFromIndex(
        this.DB_STORE_FORM_IN,
        this.DB_STORE_FORM_IN_INDEX_MUNICIPIO_NAME,
        codg_ibge
      )
      .then(async (form_ins: any[]) => {
        return await Promise.all(
          form_ins.map((form_in) => {
            return super._delete(this.DB_STORE_FORM_IN, form_in.id);
          })
        );
      });
  }

  static async addAllFormIN(lista: FormIN.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_FORM_IN, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_FORM_IN);
  }
}

export default FormINIDBService;
