import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Typography,
} from 'antd';

import { useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';

type CustomCardProps = {
  title: string;
  name: string;
  specify: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChange: (
    name: string,
    value: string,
    specify: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  specify,
  formValue,
  formDisable,
  updatedValue,
  handleChange,
}) => {
  return (
    <Card title={title} key={formValue}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChange(name, e.target.value, specify, updatedValue)
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            {formValue === 'NAO' && (
              <Form.Item
                label={'Especificar'}
                name={specify}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea showCount rows={5} maxLength={2000} />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};
export default function TerrenoLocalizacaoControleAcesso(
  props: InformacoesCadastroProps
) {
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const handleChange = (name: string, value: string, specify: string) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [specify]: undefined,
      });
    }
  };

  return (
    <>
      <Typography.Title level={5}>Terreno e Localização</Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='terrenoELocalizacao'
            title='3.1 - O terreno de construção dos aviários possui solo permeável e seco com declividade adequada, 
            evitando áreas baixas onde possam se acumular água ou umidade em excesso; 
            A localização do terreno está em área não sujeita a condições adversas que possam interferir 
            na saúde e bem-estar das aves ou na qualidade do produto?'
            name='terrenoELocalizacao'
            specify='terrenoELocalizacao_3_1'
            formValue={props.form.getFieldValue('terrenoELocalizacao')}
            updatedValue={updatedValues['distanciaMinima3kmMatrizeiros_1_1']}
          />
          <Divider />
          <Col xs={24} lg={24}>
            <Form.Item
              label={'Observações'}
              name={'observacoesTerrenoELocalizacao'}
            >
              <TextArea
                showCount
                rows={5}
                maxLength={2000}
                disabled={props.formDisabled}
              />
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </>
  );
}
