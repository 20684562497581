import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../core/store';
import { fetchByCNPJ, clear } from '../../core/store/Revenda.slice';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import { CustomModal } from './CustomModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';
import { Revenda } from '../../sdk/@types';

interface RevendaProps {
  form: FormInstance;
  formDisabled: boolean;
  revendaSelecionada?: boolean;
  revendaOrigemDados?: Revenda.Summary;
  isReadOnly: boolean;
}

export default function RevendaPanelNotaFiscal(props: RevendaProps) {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const { xs } = useBreakpoint();
  const { revenda } = useSelector((state: RootState) => state.revenda);
  const [revendaSelecionada, setRevendaSelecionada] = useState<boolean>(false);
  const { firstOpening, setFirstOpening, mode, setLoading } = useLoadingPage();
  const [fetchingRevenda, setFetchingRevenda] = useState<boolean>(false);
  const [resultadosBusca, setResultadosBusca] = useState<any[]>();
  const [showModalRevendas, setShowModalRevendas] = useState<boolean>(false);
  const [revendaNome, setRevendaNome] = useState(null);
  const { online } = useNavigatorStatus();
  const [fetching, setFetching] = useState<boolean>(false);

  // Função para buscar revenda por CNPJ
  const fetchRevendaByCNPJ = useCallback(
    async (cnpj: string) => {
      if (mode === 'VIEW' || !cnpj) return;
      setLoading(true);
      try {
        const revendaData = await dispatch(fetchByCNPJ(cnpj)).unwrap();
        props.form.setFieldsValue({ revendaOrigem: revendaData[0] });
        setRevendaSelecionada(true);
        setShowModalRevendas(false);

        notification.success({
          message: `Revenda ${revendaData[0].nome} encontrada com sucesso`,
        });
      } catch (error) {
        console.error('Erro ao buscar Revenda:', error);
        notification.info({
          message: 'Erro ao buscar Revenda',
          description:
            'Não foi possível encontrar a revenda pelo CNPJ fornecido.',
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setLoading, mode, props.form]
  );

  const removeRevenda = useCallback(() => {
    setRevendaSelecionada(false);
    props.form.setFieldsValue({
      revendaOrigem: null,
    });
    setRevendaNome(null);
    dispatch(clear());
  }, [props.form, dispatch]);

  useEffect(() => {
    if (props.revendaOrigemDados) {
      // Se houver dados de revenda fornecidos, preencher o formulário
      props.form.setFieldsValue({ revendaOrigem: props.revendaOrigemDados });
      setRevendaSelecionada(true);
    }
  }, [props.revendaOrigemDados, props.form]);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (mode === 'EDIT' && revendaSelecionada) {
      const revendaNome = props.form.getFieldValue(['revendaOrigem', 'nome']);
      if (revendaNome) {
        notification.success({
          message: `Revenda ${revendaNome} selecionada`,
        });
      }
    }
  }, [mode, revendaSelecionada, props.form]);

  useEffect(() => {
    if (
      props.form.getFieldValue(['revendaOrigem', 'cpfCnpj']) &&
      props.form.getFieldValue(['revendaOrigem', 'nome'])
    ) {
      setRevendaSelecionada(true);
    }
  }, [props.form, setLoading, revendaSelecionada]);

  useEffect(() => {
    if (
      !props.form.getFieldValue(['revendaOrigem', 'cpfCnpj']) &&
      !props.form.getFieldValue(['revendaOrigem', 'nome'])
    )
      return;

    if (mode === 'VIEW') return;
    if (!fetchingRevenda) {
      if (!revenda || revenda.length === 0) {
        if (!firstOpening) {
        }
      } else {
        if (mode === 'EDIT') {
          setShowModalRevendas(false);
        } else {
          setShowModalRevendas(true);
        }
        if (revenda?.length === 1) {
          props.form.setFieldsValue({
            revenda: revenda[0],
          });
          setShowModalRevendas(false);
        } else {
          setResultadosBusca(revenda);
          setShowModalRevendas(true);
        }
        setRevendaSelecionada(true);
        setFirstOpening(false);
      }
    }
    setLoading(fetchingRevenda);
  }, [
    fetchingRevenda,
    setLoading,
    firstOpening,
    mode,
    props.form,
    setFirstOpening,
    revenda,
  ]);

  const handleSelectItem = (item: any) => {
    props.form.setFieldsValue({
      revenda: item,
    });

    notification.success({
      message: `Revenda ${item.nome} selecionada com sucesso`,
    });

    setRevendaSelecionada(true);
    setRevendaNome(item.nome);

    setShowModalRevendas(false);

    setResultadosBusca([]);
  };

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'CNPJ'}
            name={['revendaOrigem', 'cpfCnpj']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
              {
                validator: async () => {
                  const a = props.form.getFieldValue(['revendaOrigem']);

                  if (!a)
                    return Promise.reject('Nenhuma revenda foi selecionada');
                },
              },
            ]}
          >
            {props.isReadOnly === false ? (
              <Input.Search
                placeholder={'Informe o CNPJ da revenda'}
                disabled={
                  revendaSelecionada || props.formDisabled || props.isReadOnly
                }
                autoComplete='off'
                allowClear={false}
                loading={fetching}
                inputMode='numeric'
                addonAfter={
                  <Button
                    disabled={props.formDisabled}
                    icon={<DeleteOutlined />}
                    danger
                    onClick={removeRevenda}
                  />
                }
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onSearch={(value) => {
                  if (!value) return;

                  const cnpjFormatado = value.replace(/\D/g, '');
                  if (!cnpjFormatado) removeRevenda();
                  else fetchRevendaByCNPJ(cnpjFormatado);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              <Input
                placeholder={'CNPJ ou nome fantasia'}
                value={props.revendaOrigemDados?.cpfCnpj || ''}
                readOnly
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Tooltip title='Busca por nome disponível apenas no modo offline'>
            <Form.Item
              label={'Nome Fantasia'}
              name={['revendaOrigem', 'nome']}
              validateTrigger={'onSearch'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  validator: async () => {
                    const a = props.form?.getFieldValue(['revendaOrigem']);
                    if (!a)
                      return Promise.reject('Nenhuma revenda foi selecionada');
                  },
                },
              ]}
            >
              {props.formDisabled && props.isReadOnly === true ? (
                <Input.Search
                  placeholder={'Informe nome fantasia da revenda'}
                  disabled={
                    revendaSelecionada ||
                    props.formDisabled ||
                    online ||
                    props.isReadOnly
                  }
                  autoComplete='off'
                  allowClear={false}
                  inputMode='text'
                  loading={fetching}
                  addonAfter={
                    !props.isReadOnly && (
                      <Button
                        disabled={props.formDisabled}
                        icon={<DeleteOutlined />}
                        danger
                        onClick={removeRevenda}
                      />
                    )
                  }
                  onSearch={(value) => {
                    if (!value && !props.isReadOnly) {
                      removeRevenda();
                      setFetching(false);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              ) : (
                <Input
                  placeholder={'CNPJ ou nome fantasia'}
                  value={props.revendaOrigemDados?.nome || ''}
                  readOnly
                  disabled
                />
              )}
            </Form.Item>
          </Tooltip>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Email'}
            name={['revendaOrigem', 'email']}
            rules={[
              {
                validator: async (_, email) => {
                  if (!email) return;
                  if (
                    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                      email
                    )
                  )
                    return Promise.reject(new Error('Email inválido'));
                },
              },
            ]}
          >
            <Input
              inputMode='email'
              disabled
              autoComplete='off'
              value={props.revendaOrigemDados?.email || ''}
              readOnly
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={16}>
          <Form.Item
            label={'Endereço'}
            name={['revendaOrigem', 'endereco', 'bairro']}
          >
            <Input
              disabled
              value={props.revendaOrigemDados?.endereco?.bairro || ''}
              readOnly
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label={'ULE'} name={['revendaOrigem', 'ule', 'nome']}>
            <Input
              disabled
              value={props.revendaOrigemDados?.ule?.nome || ''}
              readOnly
            />
          </Form.Item>
        </Col>
      </Row>

      <CustomModal
        title={'Selecione uma revenda'}
        open={showModalRevendas}
        width={750}
        footer={
          <Button
            type='default'
            onClick={() => {
              setShowModalRevendas(false);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Table
          dataSource={resultadosBusca}
          size={'small'}
          rowKey={'id'}
          showHeader={false}
          columns={[
            {
              dataIndex: 'cpfCnpj',
              title: 'CPF',
              width: 'cpfCnpj',
              render(cnpj) {
                let cnpjFormatado = cnpj;
                if (cnpjFormatado) {
                  cnpjFormatado = cnpjFormatado.replace(/(\d{2})(\d)/, '$1.$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{3})(\d)/, '$1/$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{4})(\d)/, '$1-$2');
                }
                return cnpjFormatado;
              },
            },
            {
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              dataIndex: 'nome',
              width: xs ? '0' : '80px',
              render(nome, item) {
                return (
                  <Button
                    icon={<SelectOutlined />}
                    type='primary'
                    onClick={async () => {
                      setShowModalRevendas(false);
                      props.form.setFieldValue(['revenda', 'nome'], nome);
                      await handleSelectItem(item);
                    }}
                  >
                    {xs ? '' : 'Selecionar'}
                  </Button>
                );
              },
            },
          ]}
        />
      </CustomModal>
    </>
  );
}
