import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PublicFVERList from '../features/FVER/PublicFVERList';

export default function FVERPublicListWrapper() {
  usePageTitle('Formulário de Visita a Estabelecimento Rural');

  return (
    <>
      <ErrorBoundary component='os formulários de visita'>
        <PublicFVERList />
      </ErrorBoundary>
    </>
  );
}
