import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import SinaisClinicosPanel from '../../components/SinaisClinicosPanel';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { SimNao } from '../../../core/enums/SimNao';
import { useEffect, useState } from 'react';

type VigilanciaOvinosProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaOvinos(props: VigilanciaOvinosProps) {
  const [statusHaSuspeitaClinica, setStatusHaSuspeitaClinica] = useState();

  useEffect(() => {
    setStatusHaSuspeitaClinica(
      props.form.getFieldValue(['vigilanciaOvino', 'haSuspeitaClinica'])
    );
  }, [props.form]);

  const triggerRecountTotaExaminadosOvinos = () => {
    const _00_12_Femeas: number = props.form.getFieldValue([
      'vigilanciaOvino',
      'quantidadeInspecionadosAte_12_F',
    ]);
    const _00_12_Machos: number = props.form.getFieldValue([
      'vigilanciaOvino',
      'quantidadeInspecionadosAte_12_M',
    ]);
    const _12_Acima_Femeas: number = props.form.getFieldValue([
      'vigilanciaOvino',
      'quantidadeInspecionadosAcima_12_F',
    ]);
    const _12_Acima_Machos: number = props.form.getFieldValue([
      'vigilanciaOvino',
      'quantidadeInspecionadosAcima_12_M',
    ]);

    const dados = [
      _00_12_Femeas,
      _00_12_Machos,
      _12_Acima_Femeas,
      _12_Acima_Machos,
    ];

    let totalAnimaisExaminados: number = 0;

    dados.forEach((qtdade) => {
      if (!isNaN(Number(qtdade))) {
        totalAnimaisExaminados =
          Number(totalAnimaisExaminados) + Number(qtdade);
      }

      props.form.setFieldValue(
        ['vigilanciaOvino', 'quantidadeExaminados'],
        totalAnimaisExaminados
      );
    });
  };

  return (
    <>
      <Divider orientation='left'>Ovinos examinados</Divider>

      <Row gutter={24}>
        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Até 12 meses'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={['vigilanciaOvino', 'quantidadeInspecionadosAte_12_F']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosOvinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={['vigilanciaOvino', 'quantidadeInspecionadosAte_12_M']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosOvinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Acima de 12 meses'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaOvino',
                    'quantidadeInspecionadosAcima_12_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosOvinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaOvino',
                    'quantidadeInspecionadosAcima_12_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosOvinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Total'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Tooltip
                  title={
                    'Preencher com todos os animais observados, inclusive os examinados'
                  }
                >
                  <Form.Item
                    label={'Vistoriados'}
                    name={['vigilanciaOvino', 'quantidadeVistoriados']}
                    rules={[
                      {
                        validator(_, vistoriados) {
                          let isValid;
                          var examinados = props.form.getFieldValue([
                            'vigilanciaOvino',
                            'quantidadeExaminados',
                          ]);

                          console.log('value', vistoriados);
                          console.log('totalAnimaisExaminados', examinados);

                          if (
                            examinados === undefined &&
                            vistoriados === undefined
                          ) {
                            console.log('1');

                            isValid = true;
                          } else if (examinados === undefined) {
                            console.log('2');
                            isValid = true;
                          } else if (vistoriados === undefined) {
                            console.log('3');
                            isValid = false;
                          } else {
                            console.log('4');
                            isValid = vistoriados >= examinados;
                          }

                          console.log('VISTORIADOS, ', vistoriados, isValid);

                          if (isValid) return Promise.resolve();
                          else
                            return Promise.reject(
                              'Deve ser maior que o total de Examinados'
                            );
                        },
                      },
                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      inputMode='numeric'
                      maxLength={4}
                      autoComplete='off'
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Examinados'}
                  name={['vigilanciaOvino', 'quantidadeExaminados']}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Há suspeita clínica?'}
            name={['vigilanciaOvino', 'haSuspeitaClinica']}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHaSuspeitaClinica}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaOvino', 'haSuspeitaClinica'],
                  e.target.value
                );
                setStatusHaSuspeitaClinica(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            dependencies={['vigilanciaOvino', 'haSuspeitaClinica']}
            label={'Suspeita clinica'}
            name={['vigilanciaOvino', 'suspeitaClinica']}
            rules={[
              {
                required: statusHaSuspeitaClinica,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>

        <SinaisClinicosPanel
          listaSinaisClinicos={SinaisClinicos.ovinos()}
          pathToCoordenada={'vigilanciaOvino'}
          form={props.form}
          required={statusHaSuspeitaClinica}
        />
      </Row>
    </>
  );
}
