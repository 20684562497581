export enum TipoAcaoEducativa {
  CAPACITACAO = 'Capacitação',
  ENTREVISTA_JORNALISMO_DIGITAL_IMPRESSO = 'Entrevista Jornalismo Digital/Impresso',
  ENTREVISTA_RADIO = 'Entrevista Rádio',
  ENTREVISTA_TV = 'Entrevista TV',
  PALESTRA = 'Palestra',
  REUNIAO = 'Reunião',
}

export namespace TipoAcaoEducativa {
  export function keys(): string[] {
    return Object.keys(TipoAcaoEducativa).filter(
      (key) => typeof TipoAcaoEducativa[key as keyof typeof TipoAcaoEducativa] === 'string'
    );
  }

  export function valueOf(key: string) {
    return TipoAcaoEducativa[key as keyof typeof TipoAcaoEducativa];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoAcaoEducativa).indexOf(
      value as TipoAcaoEducativa
    );

    const key = Object.keys(TipoAcaoEducativa)[indexOfS];

    return key;
  }
}
