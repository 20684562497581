import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DeleteOutlined,
  EditTwoTone,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { FVV } from '../../../sdk/@types';

import { useNavigate } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import FilterPanelFVV from './FilterPanelFVV';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FVVActions from '../../../core/store/FVV.slice';
import FVVService from '../../../sdk/services/SIZ-API/FVV.service';
import ButtonToForm from '../../components/ButtonToForm';
import useDevOptions from '../../../core/hooks/useDevOptions';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

export default function ListFVV() {
  const { online } = useNavigatorStatus();
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const { setFirstOpening, setLoading } = useLoadingPage();
  const navigate = useNavigate();

  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, fvv: FVV.Summary) => {
    setExpandedKey((prev: any) => {
      const newKey = fvv.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };
  //end control expandedKeys

  const [error, setError] = useState<Error>();

  const fvvs = useSelector((state: RootState) => state.fvv.fvvs);
  const query = useSelector((state: RootState) => state.fvv.query);
  const fetching = useSelector((state: RootState) => state.fvv.fetching);

  if (!online)
    setError(Error('Não é possível visualizar a lista de FVV no modo offline'));

  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, [setFirstOpening]);

  useEffect(() => {}, [fvvs]);

  const deleteFVV = useCallback(
    async (id) => {
      if (id) {
        setDeleting(true);
        return await FVVService.remove(id)
          .then(() => {
            setDeleting(false);
            notification.success({
              message: 'FVV removido com sucesso',
            });
            dispatch(FVVActions.filter(query));
          })
          .catch((e) => {
            setDeleting(false);
            notification.error({
              message: 'Não foi possível remover o FVV',
              description: e.message,
            });
          });
      }
    },
    [dispatch, query]
  );

  return (
    <>
      <FilterPanelFVV />
      <br />

      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FVV.Summary>
                columns={[
                  {
                    dataIndex: 'id',
                    width: idInTables ? 100 : 0,
                    render(id) {
                      return (
                        idInTables && (
                          <Typography.Text>{`ID: ${id}`}</Typography.Text>
                        )
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    title: 'Detalhes',
                    render(_, fvv) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Typography.Text strong>{fvv.numero}</Typography.Text>

                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {moment(new Date(fvv.dataVigilancia)).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </Typography.Text>

                            <Typography>
                              {`${fvv.visitaPropriedadeRural?.codigoEstabelecimento} - ${fvv.visitaPropriedadeRural?.nomeEstabelecimento}`}
                            </Typography>
                          </Space>
                        </Space>
                      );
                    },
                  },

                  {
                    dataIndex: 'id',
                    title: '',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                            onClick={() =>
                              navigate(`/vigilancias/edicao/${id}`)
                            }
                            size={'small'}
                            type={'ghost'}
                          />

                          <Popconfirm
                            title={'Deseja remover o FVV?'}
                            onConfirm={() => {
                              deleteFVV(id);
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>

                          <Button
                            icon={<PrinterOutlined />}
                            onClick={() =>
                              navigate(`/vigilancias/visualizarP/${id}`)
                            }
                            size={'small'}
                            type={'ghost'}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={fvvs?.content}
                expandable={{
                  columnWidth: '50px',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <MinusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ) : (
                      <PlusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ),
                  expandRowByClick: true,
                  expandedRowKeys: [expandedKey],
                  expandedRowRender: (fvv) => (
                    <Space style={{ fontSize: '0.8em' }}>
                      <Typography.Text>
                        {fvv.visitaPropriedadeRural?.id === -1 ? (
                          'FVER - Pendente'
                        ) : (
                          <ButtonToForm
                            label={`FVER - ${fvv.visitaPropriedadeRural?.numero}`}
                            route={`/visitas/visualizar/${fvv.visitaPropriedadeRural?.id}`}
                            type='VIEW'
                          />
                        )}
                      </Typography.Text>
                    </Space>
                  ),
                  onExpand: onExpand,
                  expandedRowClassName: () => 'expanded-row-siz',
                }}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      FVVActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: fvvs?.totalElements,
                  pageSize: query.size,
                }}
                size={'small'}
                rowKey={'id'}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        FVVs
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<SyncOutlined />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(FVVActions.filter(query));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
                showHeader={false}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
