import { operatingSytem } from './operatingSytem';

export default function scrollFieldToTop(id: string, includeDesktop?: boolean) {
  const os = operatingSytem();
  const isDesktop = os !== 'Android' && os !== 'iOS';

  if (!includeDesktop && isDesktop) return;

  const element = document.querySelector(`#${id}`);

  //@ts-ignore
  const y = element?.getBoundingClientRect().top + window.scrollY - 130;

  window.scrollTo({ top: y, behavior: 'smooth' });
}
