import { useCallback, useEffect } from 'react';
import { Button, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Usuario } from '../../../sdk/@types';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as UsuarioActions from '../../../core/store/Usuario.slice';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';
import { formatCpf_Cnpj } from '../../../core/functions/formatCpf_Cnpj';
import { TipoUsuario } from '../../../core/enums/TipoUsuario';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

interface FilterPanelUsuarioProps {
  open?: boolean;
  defaultQuery?: Usuario.Query;
}

export default function FilterPanelUsuario(props: FilterPanelUsuarioProps) {
  const { online } = useNavigatorStatus();
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const { firstOpening, setFirstOpening } = useLoadingPage();

  const dispatch = useDispatch();
  const query = useSelector((state: RootState) => state.usuario.query);

  const listaTipoUsuario = TipoUsuario.keys();

  const fetchUsuarios = useCallback(() => {
    dispatch(UsuarioActions.filter(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (!firstOpening) {
      if (online) {
        dispatch(UsuarioActions.setFetching(true));
        try {
          fetchUsuarios();
        } finally {
          dispatch(UsuarioActions.setFetching(false));
        }
      }
    } else {
      setFirstOpening(false);
    }
  }, [dispatch, fetchUsuarios, query, firstOpening]);

  const handleFormSubmit = useCallback(
    (values) => {
      const formValues = form.getFieldsValue();

      dispatch(
        UsuarioActions.setQuery({
          nome: formValues.nome,
          userName: formValues.userName,
          cpf: formValues.cpf
            ? removeNonNumericCharactersFromString(formValues.cpf)
            : undefined,
          tipoUsuario: formValues.tipoUsuario,
          status: formValues.status,
        })
      );
    },
    [dispatch, form]
  );

  const removeNome = useCallback(() => {
    form.setFieldsValue({
      nome: null,
    });
  }, [form]);

  const removeUserName = useCallback(() => {
    form.setFieldsValue({
      userName: null,
    });
  }, [form]);

  const removeCpf = useCallback(() => {
    form.setFieldsValue({
      cpf: null,
    });
  }, [form]);

  const removeTipoUsuario = useCallback(() => {
    form.setFieldsValue({
      tipoUsuario: null,
    });
  }, [form]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          //defaultActiveKey={props.open ? '1' : '0'}
          defaultActiveKey={1}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label={'Nome'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'nome'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input inputMode='text' />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeNome}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'Login'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'userName'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input inputMode='text' />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeUserName}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'CPF'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'cpf'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input
                        inputMode='text'
                        maxLength={14}
                        onKeyPress={(event: any) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            !/./.test(event.key)
                          ) {
                            event.preventDefault();
                          }

                          event.currentTarget.value = formatCpf_Cnpj(
                            event.target.value
                          );
                        }}
                        onKeyUp={(event: any) => {
                          //handle ctrl+v
                          const code = event.which || event.keyCode;
                          let charCode =
                            String.fromCharCode(code).toLowerCase();
                          if (
                            (event.ctrlKey || event.metaKey) &&
                            charCode === 'v'
                          ) {
                            event.currentTarget.value = formatCpf_Cnpj(
                              event.target.value
                            );
                          }

                          //handle delete|backspace
                          if (event.keyCode === 8 || event.keyCode === 46) {
                            event.currentTarget.value = formatCpf_Cnpj(
                              event.target.value
                            );
                          }
                        }}
                      />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeCpf}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'Tipo'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'tipoUsuario'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select
                        placeholder={'Selecione o tipo'}
                        placement={'bottomLeft'}
                      >
                        {listaTipoUsuario?.map((tipo) => (
                          <Select.Option key={tipo} value={tipo}>
                            {TipoUsuario.valueOf(tipo)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeTipoUsuario}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      dispatch(UsuarioActions.setQuery({}));
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
