import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateRecebimentoRevenda from '../features/SOVIV/CreateRecebimentoRevenda';

export default function CreateRecebimentoRevendaView() {
  usePageTitle('Novo Formulário Recebimento de Insumos Pecuários - SVO IV');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateRecebimentoRevenda />
      </ErrorBoundary>
    </>
  );
}
