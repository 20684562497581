import { Permissao } from '../../@types/Permissao';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class PermissaoService extends Service {
  static getAll(nome?: string): Promise<Permissao.Response[]> {
    const queryString = generateQueryString({ nome });
    return this.Http.get<Permissao.Summary[]>(
      '/permissoes'.concat(queryString)
    ).then(this.getData);
  }
}

export default PermissaoService;
