import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import ErrorBoundary from '../components/ErrorBoundary';
import GlobalDataOffline from '../features/OfflineData/GlobalDataOffline';
import MunicipiosOfflineNew from '../features/OfflineData/MunicipiosOffline';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';

export default function OfflineMode() {
  const { loading } = useLoadingPage();

  const [space, setSpace] = useState<{ used: number; granted: number }>();
  const [erro, setErro] = useState(false);

  const bytesToSize = (bytes: number) => {
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i: number = parseInt(
      Math.floor(Math.log(bytes) / Math.log(1024)).toString()
    );
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  };

  const getPercentage = (atual: number, total: number): number => {
    if (total === 0) return 100;
    if (atual === 0) return 0;
    return Number(((atual * 100) / total).toFixed(2));
  };

  useEffect(() => {
    try {
      //@ts-ignore
      navigator.webkitPersistentStorage.queryUsageAndQuota(
        //@ts-ignore
        function (usedBytes, grantedBytes) {
          setSpace({
            used: usedBytes,
            granted: grantedBytes,
          });
        },
        //@ts-ignore
        function (e) {
          console.log(e);
          setErro(true);
        }
      );
    } catch {
      setErro(true);
    }
  }, []);

  return (
    <>
      <Divider orientation='left'>
        <>
          <span>Dados armazenados</span>
          <Button
            icon={<QuestionCircleOutlined />}
            onClick={() =>
              Modal.info({
                autoFocusButton: 'ok',
                closable: true,
                content: (
                  <>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                      Validade
                    </Divider>
                    <Typography.Text>
                      Os dados armazenados no dispositivo são considerado
                      válidos até 3 dias da data de sincronização. Após isso,
                      eles serão considerados EXPIRADOS. O que significa que
                      esses dados podem ter sofrido alterações no SINDESA e
                      estarem desatualizados.
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      Dados EXPIRADOS não serão excluídos automaticamente e
                      ainda estarão disponíveis para uso. Porém, recomendamos
                      que você mantenha seus dados sempre atualizados afim de se
                      evitar problemas no uso do SIZ.
                    </Typography.Text>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                      Atualização
                    </Divider>
                    <Typography.Text>
                      Os Dados Globais são atualizados automaticamente a cada 3
                      dias, e, se necessário, podem ser atualizados manualmente.
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      Os dados dos Municípios precisam ser atualizados
                      manualmente.
                    </Typography.Text>
                  </>
                ),
                icon: null,
                width: '600px',
              })
            }
            type='text'
          />
        </>
      </Divider>
      <Row gutter={24}>
        <>
          <Space size={24} direction={'vertical'} style={{ width: '100%' }}>
            <Col xs={24}>
              <ErrorBoundary component='dados dos municípios'>
                <Spin size='large' spinning={loading}>
                  <MunicipiosOfflineNew />
                </Spin>
              </ErrorBoundary>
            </Col>
            <Col xs={24}>
              <ErrorBoundary component='dados globais'>
                <Spin size='large' spinning={loading}>
                  <GlobalDataOffline />
                </Spin>
              </ErrorBoundary>
            </Col>
          </Space>
        </>
      </Row>

      {!erro && (
        <>
          <Divider orientation='left'>Uso de espaço em disco</Divider>

          <Row justify='center'>
            <Progress
              type='circle'
              strokeColor={{
                '0%': '#a1ff9a',
                '100%': '#ff0000',
              }}
              //@ts-ignore
              percent={getPercentage(space?.used, space?.granted)}
            />
          </Row>
        </>
      )}
    </>
  );
}
