import { Setor } from '../../@types/Setor';
import Service from './Service';

class SetorService extends Service {
  static getByMunicipio(codgIbge: string) {
    return this.Http.get<Setor.Summary[]>(`setores/municipio/${codgIbge}`, {
      timeout: 600000,
    }).then(this.getData);
  }

  static countByMunicipio(codgIbge: string) {
    return this.Http.get<number>(`setores/municipio/${codgIbge}/count`).then(
      this.getData
    );
  }
}

export default SetorService;
