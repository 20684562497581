import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormCustom } from '../../sdk/@types';
import FormCustomService from '../../sdk/services/SIZ-API/formCustom.service';
import { RootState } from '../../core/store';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface FormCustomState {
  formCustomData: FormCustom.Response | null;
  allForms: FormCustom.Summary[] | null;
  fetching: boolean;
  loading: LoadingState;
  errorMessage?: string | null;
  query: FormCustom.Query;
}

const initialState: FormCustomState = {
  formCustomData: null,
  allForms: null,
  fetching: false,
  loading: 'idle',
  errorMessage: null,
  query: { page: 0, size: 10 },
};

export const insertFormCustom = createAsyncThunk<
  FormCustom.Response,
  FormCustom.Request,
  { rejectValue: string }
>('formCustom/insert', async (formCustomRequest, { rejectWithValue }) => {
  try {
    return await FormCustomService.add(formCustomRequest);
  } catch (error) {
    console.error('Error in insertFormCustom:', error);
    return rejectWithValue('Falha ao inserir o formulário');
  }
});

export const fetchFormCustomById = createAsyncThunk<
  FormCustom.Response,
  number,
  { rejectValue: string }
>('formCustom/fetchById', async (id, { rejectWithValue }) => {
  try {
    return await FormCustomService.getById(id);
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue('Erro desconhecido');
    }
  }
});
export const filterFormCustoms = createAsyncThunk(
  'formCustom/filterFormCustoms',
  async (query: FormCustom.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const formCustoms = await FormCustomService.getAll(query);
      return formCustoms;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os Formulários')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);
export const activateFormCustom = createAsyncThunk(
  'formCustom/activate',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return await FormCustomService.activate(id);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Falha ao atualizar o formulário');
      }
    }
  }
);
export const deactivateFormCustom = createAsyncThunk(
  'formCustom/deactivate',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return await FormCustomService.deactivate(id);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Falha ao atualizar o formulário');
      }
    }
  }
);
export const updateFormCustom = createAsyncThunk(
  'formCustom/updateFormCustom',
  async (
    { id, formCustom }: { id: number; formCustom: FormCustom.Request },
    { rejectWithValue }
  ) => {
    return await FormCustomService.update(id, formCustom).catch(rejectWithValue);
  }
);

export const selectFormCustom = (state: RootState) =>
  state.formCustom.formCustomData;

const formCustomSlice = createSlice({
  name: 'formCustom',
  initialState,
  reducers: {
    clearFormCustomState: (state) => {
      return initialState;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FormCustom.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterFormCustoms.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(filterFormCustoms.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.allForms = action.payload;
        state.fetching = false;
      })
      .addCase(filterFormCustoms.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(insertFormCustom.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(insertFormCustom.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.formCustomData = action.payload;
      })
      .addCase(insertFormCustom.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.payload;
      })
      .addCase(fetchFormCustomById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFormCustomById.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.formCustomData = action.payload;
      })
      .addCase(fetchFormCustomById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.payload;
      })
      .addCase(activateFormCustom.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.formCustomData = action.payload;
      })
      .addCase(activateFormCustom.rejected, (state, action) => {
        state.loading = 'rejected';
        if (typeof action.payload === 'string') {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = 'Ocorreu um erro desconhecido';
        }
      })
      .addCase(deactivateFormCustom.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.formCustomData = action.payload;
      })
      .addCase(deactivateFormCustom.rejected, (state, action) => {
        state.loading = 'rejected';
        if (typeof action.payload === 'string') {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = 'Ocorreu um erro desconhecido';
        }
      });
  },
});

export const { clearFormCustomState, setQuery, setFetching} = formCustomSlice.actions;

export default formCustomSlice.reducer;
