import { Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormLIA from './FormLIA';
import { useEffect } from 'react';

export default function CreateLIA() {
  const { loading } = useLoadingPage();

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('CREATE');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <Typography.Title level={4}>
        LAUDO DE INSPEÇÃO FÍSICA SANITÁRIA
      </Typography.Title>

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormLIA />
      </Spin>
    </>
  );
}
