import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TermoFisacalizacaoService from '../../../sdk/services/SIZ-API/TermoFiscalizacao.service';
import { TermoFiscalizacao } from '../../../sdk/@types';
import moment from 'moment';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { CustomModal } from '../../components/CustomModal';

export default function TFRExternalList() {
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const params = useParams<{ cnpj: string }>();
  const [tfrs, setTfrs] = useState<TermoFiscalizacao.Paginated>();
  const [query, setQuery] = useState<TermoFiscalizacao.Query>({
    page: 0,
    size: 10,
  });
  const [finishedFetching, setFinishedFetching] = useState<boolean>(false);
  const [showModalConsulta, setShowModalConsulta] = useState(false);

  const fetchTFRsByCNPJ = useCallback(
    async (cnpjRevenda: string) => {
      if (!cnpjRevenda) {
        notification.warn({
          message: 'Nenhum CNPJ foi informado para a consulta',
        });
        setFinishedFetching(true);
        setShowModalConsulta(true);
        return;
      }

      await TermoFisacalizacaoService.getAll_PublicAccess({
        ...query,
        cnpjRevenda,
      })
        .then(async (lista) => {
          setTfrs(lista);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setFinishedFetching(true);
        });
    },
    [query]
  );

  const fetchTFRsByParams = useCallback(async () => {
    if (params.cnpj) fetchTFRsByCNPJ(params.cnpj);
    else
      notification.warn({
        message: 'Nenhum CNPJ foi informado para a consulta',
      });
  }, [fetchTFRsByCNPJ, params]);

  useEffect(() => {
    fetchTFRsByParams();
  }, [fetchTFRsByParams, params]);

  useEffect(() => {
    if (tfrs) {
      if (tfrs.content?.length === 0) {
        setShowModalConsulta(true);
      }
    }
  }, [tfrs]);

  if (error) throw error;

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
      }}
    >
      <Space direction={'vertical'} style={{ width: '100%' }} size={24}>
        <Spin
          size='large'
          spinning={!finishedFetching}
          tip={'Buscando Termos de Fiscalização'}
        >
          {tfrs && tfrs?.content && tfrs?.content.length > 0 && (
            <>
              <Typography.Title level={3}>
                Termos de Fiscalização
              </Typography.Title>
              <Divider style={{ marginTop: '-10px' }} />

              <Typography.Title level={5}>
                {tfrs?.content[0].nomeFiscalizado} - {params.cnpj}
              </Typography.Title>
            </>
          )}

          <Col span={24}>
            <Table<TermoFiscalizacao.Summary>
              rowKey={'id'}
              dataSource={tfrs?.content}
              size={'small'}
              pagination={{
                current: query.page ? query.page + 1 : 1,
                onChange: (page, pageSize) => {
                  setQuery({
                    ...query,
                    page: page - 1,
                    size: pageSize,
                  });
                },
                total: tfrs?.totalElements,
                pageSize: query.size,
              }}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      Termos de Fiscalização
                    </Typography.Title>
                  </Row>
                );
              }}
              columns={[
                {
                  dataIndex: 'numero',
                  title: 'Número',
                  width: 175,
                  responsive: ['xs', 'sm'],
                  render(numero: TermoFiscalizacao.Summary['numero']) {
                    return numero;
                  },
                },
                {
                  dataIndex: 'dataVisita',
                  title: 'Data',
                  width: 100,
                  responsive: ['xs', 'sm'],
                  render(data: TermoFiscalizacao.Summary['dataVisita']) {
                    return data
                      ? moment(new Date(data)).format('DD/MM/YYYY')
                      : undefined;
                  },
                },
                {
                  dataIndex: 'municipioFiscalizado',
                  title: 'Município',
                  width: 200,
                  responsive: ['md'],
                  render(
                    municipio: TermoFiscalizacao.Summary['municipioFiscalizado']
                  ) {
                    return municipio?.nome;
                  },
                },
                {
                  dataIndex: 'nomeFiscalizado',
                  title: 'Estabelecimento',
                  width: 200,
                  responsive: ['md'],
                  render(
                    nomeFiscalizado: TermoFiscalizacao.Summary['nomeFiscalizado']
                  ) {
                    return nomeFiscalizado;
                  },
                },
                {
                  dataIndex: 'id',
                  title: 'Ações',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(id: number) {
                    return (
                      <Button
                        icon={<SearchOutlined />}
                        size={'small'}
                        type={'ghost'}
                        onClick={() => {
                          navigate(`/public/visualizarTFR/${id}`);
                        }}
                      />
                    );
                  },
                },
              ]}
            ></Table>
          </Col>
        </Spin>
        <Row justify={'end'} style={{ width: '100%' }}>
          <Col xs={24} md={12}>
            <Button
              style={{ width: '100%' }}
              htmlType={'submit'}
              type={'primary'}
              onClick={() => navigate('/')}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </Space>

      <CustomModal
        centered
        open={showModalConsulta}
        title={'Consultar'}
        width={500}
        footer={null}
        style={{
          backgroundColor: 'transparent',
          minHeight: '350px',
        }}
        bodyStyle={{
          backgroundColor: '#f7f5f3',
          fontWeight: 'bold',
        }}
        closable
        maskClosable={false}
        onCancel={() => {
          setShowModalConsulta(false);
        }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <Form
          layout={'vertical'}
          size={'small'}
          onFinish={(formValues) => {
            notification.warn({
              message:
                'Não encontramos nenhum Termo de Fiscalização para este CNPJ',
            });
            setShowModalConsulta(false);
            navigate(`/public/visualizarTFRs/${formValues.cnpj}`);
          }}
        >
          {
            <div>
              <Alert
                message={
                  'Não encontramos nenhum Termo de Fiscalização para este CNPJ. Deseja consultar outro CNPJ?'
                }
                type='warning'
                showIcon
              />
              <br />

              <Col span={24}>
                <Form.Item
                  label={'Cpf/Cnpj'}
                  name={'cnpj'}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder='CPF ou CNPJ' />
                </Form.Item>
              </Col>

              <Button
                type='primary'
                style={{ fontWeight: 'bold', height: '60px', width: '100%' }}
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Consultar
              </Button>
            </div>
          }
        </Form>
      </CustomModal>
    </div>
  );
}
