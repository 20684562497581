import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { EstabelecimentoAvicola } from '../../sdk/@types/EstabelecimentoAvicola';
import EstabelecimentoAvicolaService from '../../sdk/services/SIZ-API/EstabelecimentoAvicola.service';
import { RootState } from '../../core/store';
import EstabelecimentoAvicolaIDBService from '../../sdk/services/indexeddb/EstabelecimentoAvicolaIDB.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface EstabelecimentoAvicolaState {
  loading: LoadingState;
  estabelecimentosAvicolas: EstabelecimentoAvicola.Summary[] | null;
  fetchingEstabelecimentosAvicolas: boolean;
  errorMessage?: string | null;
}

const initialState: EstabelecimentoAvicolaState = {
  estabelecimentosAvicolas: null,
  loading: 'idle',
  errorMessage: null,
  fetchingEstabelecimentosAvicolas: false,
};

export const fetchEstabelecimentoAvicolaByProdutorCpf = createAsyncThunk(
  'estabelecimentoAvicola/fetchByProducerCpf',
  async (cpf: string, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        return await EstabelecimentoAvicolaService.getByProdutorCpf(cpf);
      } else {
        const lista = await EstabelecimentoAvicolaIDBService.getByProdutorCpf(
          cpf
        );
        if (!lista) {
          throw Error(
            'Não foram encontradas Estabelecimentos Avícolas offline'
          );
        }
        return lista;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const listaEstabelecimentosAvicolas = (state: RootState) =>
  state.estabelecimentoAvicola.estabelecimentosAvicolas;

const estabelecimentoAvicolaSlice = createSlice({
  name: 'estabelecimentoAvicola',
  initialState,
  reducers: {
    setFetchingEstabelecimentosAvicolas(state, action: PayloadAction<boolean>) {
      state.fetchingEstabelecimentosAvicolas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstabelecimentoAvicolaByProdutorCpf.pending, (state) => {
        state.loading = 'pending';
        state.errorMessage = null;
      })
      .addCase(
        fetchEstabelecimentoAvicolaByProdutorCpf.fulfilled,
        (state, action) => {
          state.loading = 'fulfilled';
          //@ts-ignore
          state.estabelecimentosAvicolas = action.payload;
        }
      )
      .addCase(
        fetchEstabelecimentoAvicolaByProdutorCpf.rejected,
        (state, action) => {
          state.loading = 'rejected';
          state.errorMessage = action.error.message;
        }
      );
    // .addCase(fetchAllEstabelecimentosAvicolas.pending, (state) => {
    //   state.loading = 'pending';
    //   state.errorMessage = null;
    // })
    // .addCase(fetchAllEstabelecimentosAvicolas.fulfilled, (state, action) => {
    //   state.loading = 'fulfilled';
    //   //@ts-ignore
    //   state.estabelecimentosAvicolas = action.payload;
    // })
    // .addCase(fetchAllEstabelecimentosAvicolas.rejected, (state, action) => {
    //   state.loading = 'rejected';
    //   state.errorMessage = action.error.message;
    // });;
  },
});

export const { setFetchingEstabelecimentosAvicolas } =
  estabelecimentoAvicolaSlice.actions;

export default estabelecimentoAvicolaSlice.reducer;
