import { Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFVV from '../../../core/hooks/useFVV';
import { FVV } from '../../../sdk/@types';
import FVVIDBService from '../../../sdk/services/indexeddb/FVVIDB.service';
import FormFVV from './FormFVV';

function EditFVV() {
  const { loading } = useLoadingPage();

  const [error, setError] = useState<Error>();

  const { fetchById, fvv } = useFVV();

  const [fvvOffline, setFVVOffline] = useState<FVV.Input>();

  const params = useParams<{ id: string; local?: string }>();

  const fetchFVVOffline = useCallback(async () => {
    return await FVVIDBService.getById(Number(params.id))
      .then((v) => {
        setFVVOffline(v);
      })
      .catch((e) => {
        setError(e);
      });
  }, [params.id]);

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      if (!params.local) {
        fetchById(Number(params.id)).catch((e) => {
          setError(new Error(e.message));
        });
      } else {
        fetchFVVOffline().catch((e) => {
          setError(e);
        });
      }
    }
  }, [fetchById, fetchFVVOffline, params]);

  if (error) throw error;

  const prepareData = useCallback((fvv) => {
    const dataVigilancia = moment(fvv.dataVigilancia);

    return {
      ...fvv,
      dataVigilancia: dataVigilancia,
    };
  }, []);

  return (
    <>
      {!fvv && !fvvOffline && <Skeleton active />}

      {(fvv || fvvOffline) && (
        <>
          <Typography.Title level={2}>
            Editando FVV
            {fvv ? ` nº ${fvv?.numero}` : ' offline'}
          </Typography.Title>

          <Spin
            size='large'
            spinning={loading}
            style={{
              position: 'fixed',
              top: '20%',
              zIndex: '1',
            }}
          >
            <FormFVV fvv={prepareData(!params.local ? fvv : fvvOffline)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFVV;
