import { AtendimentoErroSincronizacao } from '../../@types/AtendimentoErroSincronizacao';
import { ErroSincronizacao } from '../../@types/ErroSincronizacao';
import { AndamentoErroSincronizacao } from '../../@types/AndamentoErroSincronizacao';
import Service from './Service';

class AtendimentoErroSincronizacaoService extends Service {
  static BASE_URL = '/erros-sincronizacao';

  static getById(atendimentoId: number) {
    return this.Http.get<AtendimentoErroSincronizacao.Response>(
      `${this.BASE_URL}/atendimentos/${atendimentoId}`
    ).then(this.getData);
  }

  static getAll() {
    return this.Http.get<AtendimentoErroSincronizacao.Summary[]>(
      `${this.BASE_URL}/atendimentos`
    ).then(this.getData);
  }

  static getAndamentos(atendimentoId: number) {
    return this.Http.get<AndamentoErroSincronizacao.Response[]>(
      `${this.BASE_URL}/atendimentos/${atendimentoId}/andamentos`
    ).then(this.getData);
  }

  static add(erro: ErroSincronizacao.Request) {
    return this.Http.post<AtendimentoErroSincronizacao.Response>(
      `${this.BASE_URL}`,
      erro
    ).then(this.getData);
  }

  static addAndamento(erro: AndamentoErroSincronizacao.Request) {
    return this.Http.post<AtendimentoErroSincronizacao.Response>(
      `${this.BASE_URL}/andamentos`,
      erro
    ).then(this.getData);
  }
}

export default AtendimentoErroSincronizacaoService;
