import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ViewFormVIN from '../features/FormVIN/ViewFormVIN';

export default function FormVINViewView() {
  usePageTitle('Visualizando FVER');

  const { setLoading, setMode } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setMode('VIEW');
  }, [setLoading, setMode]);

  return (
    <>
      <ErrorBoundary component='formulário'>
        <ViewFormVIN />
      </ErrorBoundary>
    </>
  );
}
