import { useEffect } from 'react';
import { Card, Descriptions, Row, Typography } from 'antd';
import { LIA } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';
import PrintInformacoesGranja from './PrintComponentsLaudo/PrintInformacoesGranja'; // Certifique-se de que o caminho está correto
import PrintDistanciasExternas from './PrintComponentsLaudo/PrintDistanciasExternas';
import PrintDistanciasInternas from './PrintComponentsLaudo/PrintDistanciasInternas';
import PrintTerrenoLocalizacao from './PrintComponentsLaudo/PrintTerrenoLocalizacao';
import PrintCrontroleAcesso from './PrintComponentsLaudo/PrintCrontroleAcesso';
import PrintInstalacoesEquipamentos from './PrintComponentsLaudo/PrintInstalacoesEquipamentos';
import PrintMedidasSanitarias from './PrintComponentsLaudo/PrintMedidasSanitarias';
import PrintRiscoBioseguridade from './PrintComponentsLaudo/PrintRiscoBioseguridade';
import PrintDocumentosProtocolacao from './PrintComponentsLaudo/PrintDocumentosProtocolacao';
import PrintParecerFinal from './PrintComponentsLaudo/PrintParecerFinal';

type PrintLaudoProps = {
  laudo: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintLaudo(props: PrintLaudoProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((item) => (
              <Descriptions.Item
                key={item.value}
                label={item.label}
                span={item.span ? item.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {item.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                Laudo de Inspeção Físico-Sanitária para Estabelecimento Avícola
                Comercial
              </Typography.Title>
            </Row>
            <br />

            {props.laudo?.fver &&
              panelDescription('FVER', [
                {
                  label: 'Número',
                  value: props?.laudo.fver?.numero,
                },
                {
                  label: 'Data',
                  value: moment(props?.laudo.fver?.dataDaVisita).format(
                    'DD/MM/YYYY HH:mm'
                  ),
                },
                {
                  label: 'Estabelecimento',
                  value: props?.laudo.fver?.propriedade.nome,
                },
                {
                  label: 'Esploração',
                  value: props?.laudo.fver?.exploracao?.codigo,
                },
                {
                  label: 'Produtor',
                  value: props?.laudo.fver?.proprietario?.nome,
                },
                {
                  label: 'Município',
                  value: props?.laudo.fver?.propriedade?.municipio.nome,
                },
                {
                  label: 'coordenadas',
                  value: `${props.laudo.fver?.propriedade?.coordenadaGeografica?.latGrau}º 
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.latMin}' 
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.latSeg}"
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.orientacaoLatitude}", 
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.longGrau}º 
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.longMin}' 
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.longSeg}"
                ${props.laudo.fver?.propriedade?.coordenadaGeografica?.orientacaoLongitude}"`,
                },
              ])}
            <br />
            <br />
            <br />
            <PrintInformacoesGranja data={props.laudo} />
            <PrintDistanciasExternas data={props.laudo} />
            <PrintDistanciasInternas data={props.laudo} />
            <PrintTerrenoLocalizacao data={props.laudo} />
            <PrintCrontroleAcesso data={props.laudo} />
            <PrintInstalacoesEquipamentos data={props.laudo} />
            <PrintMedidasSanitarias data={props.laudo} />
            <PrintRiscoBioseguridade data={props.laudo} />
            <PrintDocumentosProtocolacao data={props.laudo} />
            <PrintParecerFinal data={props.laudo} />
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
