import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormRecebimentoRevenda from './FormRecebimentoRevenda';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FormRecebimentoRevendaActions from '../../../core/store/RecebimentosRevendas.slice';

function EditFormRecebimentoRevenda() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const recebimentoRevenda = useSelector((state: RootState) => state.recebimentosRevendas.recebimentoRevenda);
  const loading = useSelector((state: RootState) => state.recebimentosRevendas.loading);

  const prepareData = useCallback(
    (formEduca) => ({
      ...formEduca,
      dataAcao: moment(formEduca?.dataAcao),
    }),
    []
  );

  useEffect(() => {
    if (!isNaN(Number(id))) {
      dispatch(FormRecebimentoRevendaActions.fetchRecebimentosRevendasById(Number(id)));
    }
  }, [id, local, dispatch]);
  
  return (
    <>
      {!recebimentoRevenda && <Skeleton active />}
      {recebimentoRevenda && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando Formulário Recebimento de Insumos Pecuários - SVO IV</Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loading === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormRecebimentoRevenda recebimentosRevendas={prepareData(recebimentoRevenda)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFormRecebimentoRevenda;
