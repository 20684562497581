import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Spin,
  Table,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ButtonGoogleMaps } from '../../components/ButtonGoogleMaps';
import { coordinatesDDtoDMS } from '../../../core/functions/coordinatesUtil';

interface NavigatorGeolocationTestProps {
  reset?: () => void;
}

interface PositionWatched {
  type: 'Sucesso' | 'Erro';
  position?: GeolocationPosition;
  positionError?: GeolocationPositionError;
}

export default function NavigatorGeolocationTest(
  props: NavigatorGeolocationTestProps
) {
  const [fetching, setFetching] = useState(false);

  const [position, setPosition] = useState<GeolocationPosition>();

  const [positionWatched, setPositionWatched] = useState<PositionWatched[]>([]);

  const [watchId, setWatchId] = useState<number>();

  const [positionError, setPositionError] =
    useState<GeolocationPositionError>();

  const navigatorGeolocationUpdateCoords = () => {
    //Dummy one, which will result in a working next statement.
    navigator.geolocation.getCurrentPosition(
      function () {},
      function () {},
      { timeout: 100 }
    );

    //The working next statement.
    setFetching(true);

    try {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPosition(position);

          notification.success({
            message: 'Sucesso',
          });

          setFetching(false);
        },
        function (positionError) {
          setPositionError(positionError);

          notification.error({
            message: 'Erro',
          });

          setFetching(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 100,
          timeout: 60000,
        }
      );
    } catch {
      setFetching(false);
    }
  };

  const stopWatch = useCallback(() => {
    if (watchId) {
      navigator.geolocation.clearWatch(watchId);
    }
  }, [watchId]);

  const watchPosition = useCallback(() => {
    if (watchId) stopWatch();
    setPositionWatched([]);

    const newWatchId = navigator.geolocation.watchPosition(
      function (position: GeolocationPosition) {
        setPositionWatched((prev: PositionWatched[]) => {
          return prev.concat({
            type: 'Sucesso',
            position,
          });
        });

        notification.info({
          message: 'Watch',
          description:
            position?.coords?.latitude + ' | ' + position?.coords?.longitude,
        });
      },
      function (positionError: GeolocationPositionError) {
        setPositionWatched((prev: PositionWatched[]) => {
          return prev.concat({
            type: 'Erro',
            positionError,
          });
        });

        notification.error({
          message: 'Watch',
          description: positionError.code + '|' + positionError.message,
        });
      },
      {
        enableHighAccuracy: true,
        maximumAge: 100,
        timeout: 60000,
      }
    );

    if (newWatchId) setWatchId(newWatchId);
  }, [stopWatch, watchId]);

  useEffect(() => {
    console.log('positionWatched', positionWatched);
  }, [positionWatched]);

  return (
    <>
      <Space direction='vertical' size={24} style={{ width: '100%' }}>
        <Card
          size='small'
          bodyStyle={{
            backgroundColor: '#607d8b',
            color: 'white',
          }}
        >
          Teste Novo Sistema
        </Card>

        <Descriptions column={1} bordered size='small'>
          <Descriptions.Item label='isGeolocationAvailable'>
            {navigator.geolocation ? 'sim' : 'não'}
          </Descriptions.Item>

          <Descriptions.Item label='isGeolocationEnabled'>
            {'isGeolocationEnabled' ? 'sim' : 'não'}
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={24}>
          <Col xs={12} sm={12} md={8}>
            <Button
              onClick={(e) => {
                navigatorGeolocationUpdateCoords();
              }}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Capturar coordenadas
            </Button>
          </Col>
          <Col xs={12} sm={12} md={8}>
            <Button
              onClick={props.reset}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Resetar
            </Button>
          </Col>
        </Row>

        <Spin spinning={fetching}>
          <Descriptions column={2} bordered size='small'>
            <Descriptions.Item span={1} label='statusRequisicao'>
              {!position && !positionError
                ? 'Teste não realizado'
                : position
                ? 'Sucesso'
                : 'Erro'}
            </Descriptions.Item>

            <Descriptions.Item
              label=''
              labelStyle={{ width: '0', padding: '0', margin: '0' }}
              contentStyle={{ width: '50px' }}
            >
              {position && position.coords && (
                <ButtonGoogleMaps
                  coordenadas={{
                    latGrau: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).latGrau,
                    latMin: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).latMin,
                    latSeg: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).latSeg,
                    longGrau: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).longGrau,
                    longMin: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).longMin,
                    longSeg: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).longSeg,
                    orientacaoLatitude: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).orientacaoLatitude,
                    orientacaoLongitude: coordinatesDDtoDMS(
                      position!.coords!.latitude,
                      position!.coords!.longitude
                    ).orientacaoLongitude,
                  }}
                />
              )}
            </Descriptions.Item>

            <Descriptions.Item span={2} label='coords'>
              <div>
                <span>
                  latitude - longitude:{' '}
                  {position?.coords?.latitude +
                    ' | ' +
                    position?.coords?.longitude}
                </span>
                <br />
                <span>accuracy: {position?.coords?.accuracy}</span>
                <br />
                <span>altitude: {position?.coords?.altitude}</span>
                <br />
                <span>
                  altitudeAccuracy: {position?.coords?.altitudeAccuracy}
                </span>
                <br />
                <span>heading: {position?.coords?.heading}</span>
                <br />
                <span>speed: {position?.coords?.speed}</span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item span={2} label='positionError'>
              <div>
                <span>{'code: ' + positionError?.code}</span>
                <br />
                <span>{'message: ' + positionError?.message}</span>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Spin>

        <Card
          size='small'
          bodyStyle={{
            backgroundColor: '#607d8b',
            color: 'white',
          }}
        >
          Teste Watch Position
        </Card>

        <Row gutter={24}>
          <Col xs={12} sm={12} md={8}>
            <Button
              onClick={(e) => {
                watchPosition();
              }}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Watch Position
            </Button>
          </Col>

          <Col xs={12} sm={12} md={8}>
            <Button
              onClick={(e) => {
                stopWatch();
              }}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Parar
            </Button>
          </Col>
        </Row>

        <Table
          dataSource={positionWatched}
          title={undefined}
          showHeader={false}
          columns={[
            {
              dataIndex: 'type',
              render(type) {
                return type;
              },
            },
            {
              dataIndex: 'position',
              render(_, positionWatched) {
                if (positionWatched.type === 'Sucesso')
                  return (
                    positionWatched.position?.coords.latitude +
                    ' | ' +
                    positionWatched.position?.coords.longitude
                  );
                else
                  return (
                    positionWatched.positionError?.code +
                    '|' +
                    positionWatched.positionError?.message
                  );
              },
            },
            {
              dataIndex: ['position', 'timestamp'],
              render(timestamp) {
                if (timestamp)
                  return moment
                    .tz(
                      moment(new Date(timestamp)).toLocaleString(),
                      'America/Cuiaba'
                    )
                    .format('DD/MM/YYYY HH:mm:ss');
                else return '';
              },
            },
          ]}
        />
      </Space>
    </>
  );
}
