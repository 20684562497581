import {
  Button,
  Card,
  Col,
  Descriptions,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { ButtonGoogleMaps } from '../../components/ButtonGoogleMaps';
import { coordinatesDDtoDMS } from '../../../core/functions/coordinatesUtil';

interface ReactGeolocatedLocationTestProps {
  reset?: () => void;
}

export default function ReactGeolocatedLocationTest(
  props: ReactGeolocatedLocationTestProps
) {
  const [fetching, setFetching] = useState(false);

  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
    timestamp,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 100,
      timeout: 60000,
    },
    suppressLocationOnMount: true,
    userDecisionTimeout: 5000,
    watchPosition: false,
    onError: (error) => console.log('useGeolocated.getPosition.onError', error),
    onSuccess: (position) =>
      console.log('useGeolocated.getPosition.onSuccess', position),
  });

  const hookUseGeolocationUpdateCoords = useCallback(() => {
    if (isGeolocationAvailable) {
      setFetching(true);
      getPosition();
    } else {
      Modal.error({
        title: 'Não foi possível obter a localização',
        content: (
          <>
            <Typography.Paragraph style={{ textAlign: 'justify' }}>
              Este dispositivo não é capaz de obter a sua localização.
            </Typography.Paragraph>
          </>
        ),
      });
    }
  }, [getPosition, isGeolocationAvailable]);

  useEffect(() => {
    if (coords && !positionError) {
      notification.success({
        message: 'Sucesso',
      });

      setFetching(false);
    } else if (!coords && positionError) {
      notification.error({
        message: 'Erro',
      });

      setFetching(false);
    }
  }, [coords, positionError]);

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <Card
        size='small'
        bodyStyle={{
          backgroundColor: '#607d8b',
          color: 'white',
        }}
      >
        Teste Sistema Atual
      </Card>

      {/* {PermissionsService.dev && (
          <Card size='small'>
            <Space direction='vertical'>
              <Form
                form={form}
                layout='horizontal'
                size='small'
                initialValues={{
                  enableHighAccuracy: enableHighAccuracy,
                  timeout: timeout,
                  userDecisionTimeout: userDecisionTimeout,
                  setWatchPosition: setWatchPosition,
                }}
              >
                <Form.Item label='enableHighAccuracy' name='enableHighAccuracy'>
                  <Switch onChange={setEnableHighAccuracy} />
                </Form.Item>
                <Form.Item label='timeout' name='timeout'>
                  <InputNumber
                    onChange={setTimeout}
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={timeout}
                  />
                </Form.Item>
                <Form.Item
                  label='userDecisionTimeout'
                  name='userDecisionTimeout'
                >
                  <Input
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={userDecisionTimeout}
                  />
                </Form.Item>
                <Form.Item label='setWatchPosition' name='setWatchPosition'>
                  <Switch onChange={setWatchPosition} />
                </Form.Item>
              </Form>
            </Space>
          </Card>
        )} */}

      <Descriptions column={1} bordered size='small'>
        <Descriptions.Item label='isGeolocationAvailable'>
          {isGeolocationAvailable ? 'sim' : 'não'}
        </Descriptions.Item>

        <Descriptions.Item label='isGeolocationEnabled'>
          {isGeolocationEnabled ? 'sim' : 'não'}
        </Descriptions.Item>
      </Descriptions>

      <Row gutter={24}>
        <Col xs={12} sm={12} md={8}>
          <Button
            onClick={hookUseGeolocationUpdateCoords}
            style={{ width: '100%' }}
            type={'primary'}
          >
            Capturar coordenadas
          </Button>
        </Col>

        <Col xs={12} sm={12} md={8}>
          <Button
            onClick={props.reset}
            style={{ width: '100%' }}
            type={'primary'}
          >
            Resetar
          </Button>
        </Col>
      </Row>

      <Spin spinning={fetching}>
        <Descriptions column={2} bordered size='small'>
          <Descriptions.Item label='statusRequisicao'>
            {!coords && !positionError
              ? 'Teste não realizado'
              : coords
              ? 'Sucesso'
              : 'Erro'}
          </Descriptions.Item>

          <Descriptions.Item
            label=''
            labelStyle={{ width: '0', padding: '0', margin: '0' }}
            contentStyle={{ width: '50px' }}
          >
            {coords && (
              <ButtonGoogleMaps
                coordenadas={{
                  latGrau: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).latGrau,
                  latMin: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).latMin,
                  latSeg: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).latSeg,
                  longGrau: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).longGrau,
                  longMin: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).longMin,
                  longSeg: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).longSeg,
                  orientacaoLatitude: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).orientacaoLatitude,
                  orientacaoLongitude: coordinatesDDtoDMS(
                    coords!.latitude,
                    coords!.longitude
                  ).orientacaoLongitude,
                }}
              />
            )}
          </Descriptions.Item>

          <Descriptions.Item span={2} label='coords'>
            <div>
              <span>
                latitude - longitude:{' '}
                {coords?.latitude + ' - ' + coords?.longitude}
              </span>
              <br />
              <span>accuracy: {coords?.accuracy}</span>
              <br />
              <span>altitude: {coords?.altitude}</span>
              <br />
              <span>altitudeAccuracy: {coords?.altitudeAccuracy}</span>
              <br />
              <span>heading: {coords?.heading}</span>
              <br />
              <span>speed: {coords?.speed}</span>
            </div>
          </Descriptions.Item>
          <Descriptions.Item span={2} label='positionError'>
            <div>
              <span>{'code: ' + positionError?.code}</span>
              <br />
              <span>{'message: ' + positionError?.message}</span>
            </div>
          </Descriptions.Item>
          <Descriptions.Item span={2} label='timestamp'>
            {timestamp}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Space>
  );
}
