import { useCallback, useState } from 'react';
import { Usuario } from '../../sdk/@types/Usuario';
import UsuarioService from '../../sdk/services/SIZ-API/Usuario.service';

export default function useUsuario() {
  const [usuarios, setUsuarios] = useState<Usuario.Summary[]>();
  const [usuario, setUsuario] = useState<Usuario.Detailed>();
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState<Usuario.Query>({
    nome: '',
    userName: '',
    cpf: '',
    tipoUsuario: '',
    status: '',
  });

  const fetchUsuarios = useCallback(async () => {
    return await UsuarioService.getAll(query).then(setUsuarios);
  }, [query]);

  const fetchUsuarioByCpf = useCallback(async (cpf: string) => {
    try {
      setFetching(true);
      await UsuarioService.getByCpf(cpf).then(setUsuario);
    } catch (e) {
      setFetching(false);
      throw e;
    } finally {
      setFetching(false);
    }
  }, []);

  const fetchUsuarioByUsername = useCallback(async (username: string) => {
    try {
      setFetching(true);
      await UsuarioService.getByUserName(username).then(setUsuario);
    } catch (e) {
      setFetching(false);
      throw e;
    } finally {
      setFetching(false);
    }
  }, []);

  return {
    fetching,
    fetchUsuarioByCpf,
    fetchUsuarioByUsername,
    fetchUsuarios,
    query,
    setQuery,
    usuario,
    usuarios,
  };
}
