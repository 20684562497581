import * as XLSX from 'xlsx';
import moment from 'moment';
import { FVVReport } from '../../sdk/@types/FVVReport';

const getReportData = async (listaFVVs: FVVReport.Simplificado[]) => {
  let data: any[] = [];
  let fvvArray: any[];

  const headers = [
    [
      'URS',
      'Município',
      'Código IBGE',
      'Número FVV',
      'Número FVER',
      'Data da Vigilância',

      'Data Cadastro',
      'Responsável',
      'tipo de emitente',
      'CPF do Responsável',
      'Nome Propriedade',
      'Código da propriedade',
      'Proprietário',
      'CPF do Proprietário',
      'Latitude (grau)',
      'Latitude (minuto)',
      'Latitude (segundo)',
      'Longitude (grau)',
      'Longitude (minuto)',
      'Longitude(segundo)',

      'Principais motivos da visita',
      'Houve investigação de alimentos a ruminantes?',
      'Finalidade da exploração de ruminantes na propriedade',
      'Sistema de produção de ruminantes',
      'Fase da produção',
      'Quantidade total de ruminantes: (Bovinos)',
      'Quantidade total de ruminantes: (Caprinos)',
      'Quantidade total de ruminantes: (Ovinos)',
      'Quantidade total de ruminantes: (Outros)',
      'Idade dos ruminantes alimentados com ração/suplementos',
      'Quantidade total de ruminantes expostos ao alimento ora fiscalizado (Bovinos)',
      'Quantidade total de ruminantes expostos ao alimento ora fiscalizado (Caprinos)',
      'Quantidade total de ruminantes expostos ao alimento ora fiscalizado (Ovinos)',
      'Tipo de alimentação',
      'Há criação avícola em sistema industrial na propriedade',
      'Época do ano em que acontece a suplementação',
      'Presença de cama de aviário na propriedade',
      'Se tiver cama de aviário, onde está sendo utilizada',
      'Utilização de cama de aviário na alimentação de ruminantes',
      'Há criação de suínos em sistema industrial',
      'Piscicultura com sistema de alimentação a base de ração',
      'Indícios de contaminação cruzada de ração de peixe X ração de ruminante',
      'Colheita de amostra de alimentos de ruminantes',
      'Tipo de vigilância',
      'Número da denúncia',
      'Uso do teste rápido',
      'Resultado do teste rápido',
      'Descrição do sistema de armazenamento e elaboração de rações para animais na propriedade',
      'Outras observações',

      'Total de bovinos examinados',
      'Total de bovinos vistoriados',
      'Há suspeita clínica (bovinos)',
      'Qual suspeita (bovinos)',
      'Sinais ou sintomas observados pelo MVO(bovinos)',
      'Total de bubalinos examinados',
      'Total de bubalinos vistoriados',
      'Há suspeita clínica (bubalinos)',
      'Qual suspeita (bubalinos)',
      'Sinais ou sintomas observados pelo MVO(bubalinos)',

      'Total de caprinos examinados',
      'Total de caprinos vistoriados',
      'Há suspeita clínica (caprinos)',
      'Qual suspeita (caprinos)',
      'Sinais ou sintomas observados pelo MVO(caprinos)',

      'Total de ovinos examinados',
      'Total de ovinos vistoriados',
      'Há suspeita clínica (ovinos)',
      'Qual suspeita (ovinos)',
      'Sinais ou sintomas observados pelo MVO(ovinos)',

      'Total de suínos examinados',
      'Total de suinos vistoriados',
      'Há suspeita clínica (suinos)',
      'Qual suspeita (suinos)',
      'Sinais ou sintomas observados pelo MVO (suinos)',
      'Há presença de suínos asselvajados na propriedade',
      'Há contato direto dos suínos domésticos com asselvajados na propriedade',
      'Destino dos cadáveres',

      'Total de aves examinados',
      'Total de aves vistoriados',
      'Há suspeita clínica (aves)',
      'Qual suspeita (aves)',
      'Sinais ou sintomas observados pelo MVO(aves)',

      'Total de peixes examinados',
      'Total de peixes vistoriados',
      'Há suspeita clínica (peixes)',
      'Qual suspeita (peixes)',
      'Sinais ou sintomas observados pelo MVO(peixes)',

      'Total Equídeos inspecionada',
      'Total Equídeos vistoriada',

      'Total de colméias examinados',
      'Total de colméias vistoriados',
      'Há suspeita clínica (colméia)',
      'Qual suspeita (colméia)',
      'Sinais ou sintomas observados pelo MVO (colméia)',
      'Histórico de sugaduras de morcegos',
      'Investigação epidemiológica',
      'Observação',
      'Houve emissão de Form In?',
    ],
  ];

  if (listaFVVs) {
    listaFVVs.forEach((fvv) => {
      fvvArray = [];

      fvvArray.push(fvv.urs);
      fvvArray.push(fvv.municipio);
      fvvArray.push(fvv.codigoIBGE);
      fvvArray.push(fvv.numeroFVV);
      fvvArray.push(fvv.numeroFVER);
      fvvArray.push(moment(fvv.dataVigilancia).format('DD/MM/YYYY').toString());
      fvvArray.push(moment(fvv.dataCadastro).format('DD/MM/YYYY').toString());
      fvvArray.push(fvv.responsavel);
      fvvArray.push(fvv.tipoEmitente);
      fvvArray.push(fvv.cpfResponsavel);
      fvvArray.push(fvv.propriedade);
      fvvArray.push(fvv.codigoPropriedade);
      fvvArray.push(fvv.proprietario);
      fvvArray.push(fvv.cpfProprietario);
      fvvArray.push(fvv.latGrau);
      fvvArray.push(fvv.latMin);
      fvvArray.push(fvv.latSeg);
      fvvArray.push(fvv.longGrau);
      fvvArray.push(fvv.longMin);
      fvvArray.push(fvv.longSeg);

      fvvArray.push(fvv.principaisMotivosVisita);
      fvvArray.push(fvv.houveInvestigacaoAlimRuminantes);
      fvvArray.push(fvv.finalidadeExploracaoRuminantes);
      fvvArray.push(fvv.sistemaCriacaoRuminantes);
      fvvArray.push(fvv.faseProducaoRuminantes);
      fvvArray.push(fvv.quantidadeBovinos);
      fvvArray.push(fvv.quantidadeCaprinos);
      fvvArray.push(fvv.quantidadeOvinos);
      fvvArray.push(fvv.quantidadeOutraEspecieRuminante);
      fvvArray.push(fvv.idadeRuminanteAlimentadoComRacao);
      fvvArray.push(fvv.quantidadeBovinosExpostos);
      fvvArray.push(fvv.quantidadeCaprinosExpostos);
      fvvArray.push(fvv.quantidadeOvinosExpostos);
      fvvArray.push(fvv.tipoAlimentacaoRuminantes);
      fvvArray.push(fvv.tipoExploracaoAvicolaSistemaIndustrial);
      fvvArray.push(fvv.epocaDoAnoQueAconteceASuplementacao);
      fvvArray.push(fvv.presencaDeCamaDeAviario);
      fvvArray.push(fvv.observacaoSobreCamaDeAviario);
      fvvArray.push(fvv.utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes);
      fvvArray.push(fvv.tipoExploracaoSuinosSistemaIndustrial);
      fvvArray.push(fvv.pisciculturaComSistemaDeAlimentacaoABaseDeRacao);
      fvvArray.push(fvv.contaminacaoCruzadaDeRacoesDePeixeERuminante);
      fvvArray.push(fvv.colheitaDeAmostraDeAlimentosDeRuminantes);
      fvvArray.push(fvv.tipoFiscalizacao);
      fvvArray.push(fvv.numeroDenuncia);
      fvvArray.push(fvv.usoTesteRapido);
      fvvArray.push(fvv.resultadoTesteRapido);
      fvvArray.push(fvv.descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes);
      fvvArray.push(fvv.outrasObservacoes);

      fvvArray.push(fvv.quantidadeBovinosExaminados);
      fvvArray.push(fvv.quantidadeBovinosVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaBovinos);
      fvvArray.push(fvv.suspeitaClinicaBovinos);
      fvvArray.push(fvv.sinaisSintomasObservadosBovinos);

      fvvArray.push(fvv.quantidadeBubalinosExaminados);
      fvvArray.push(fvv.quantidadeBubalinosVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaBubalinos);
      fvvArray.push(fvv.suspeitaClinicaBubalinos);
      fvvArray.push(fvv.sinaisSintomasObservadosBubalinos);

      fvvArray.push(fvv.quantidadeCaprinosExaminados);
      fvvArray.push(fvv.quantidadeCaprinosVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaCaprinos);
      fvvArray.push(fvv.suspeitaClinicaCaprinos);
      fvvArray.push(fvv.sinaisSintomasObservadosCaprinos);

      fvvArray.push(fvv.quantidadeOvinosExaminados);
      fvvArray.push(fvv.quantidadeOvinosVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaOvinos);
      fvvArray.push(fvv.suspeitaClinicaOvinos);
      fvvArray.push(fvv.sinaisSintomasObservadosOvinos);

      fvvArray.push(fvv.quantidadeSuideosExaminados);
      fvvArray.push(fvv.quantidadeSuideosVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaSuideos);
      fvvArray.push(fvv.suspeitaClinicaSuideos);
      fvvArray.push(fvv.sinaisSintomasObservadosSuideos);
      fvvArray.push(fvv.presencaSuinosAsselvajados);
      fvvArray.push(fvv.contatoDiretoDeSuinosDomesticosComAsselvajados);
      fvvArray.push(fvv.tipoDestinoCadaveres);

      fvvArray.push(fvv.quantidadeAvesExaminadas);
      fvvArray.push(fvv.quantidadeAvesVistoriadas);
      fvvArray.push(fvv.haSuspeitaClinicaAves);
      fvvArray.push(fvv.suspeitaClinicaAves);
      fvvArray.push(fvv.sinaisSintomasObservadosAves);

      fvvArray.push(fvv.quantidadePeixesExaminados);
      fvvArray.push(fvv.quantidadePeixesVistoriados);
      fvvArray.push(fvv.haSuspeitaClinicaPeixes);
      fvvArray.push(fvv.suspeitaClinicaPeixes);
      fvvArray.push(fvv.sinaisSintomasObservadosPeixes);

      fvvArray.push(fvv.quantidadeEquideosExaminados);
      fvvArray.push(fvv.quantidadeEquideosVistoriados);

      fvvArray.push(fvv.quantidadeColmeiasExaminadas);
      fvvArray.push(fvv.quantidadeColmeiasVistoriadas);
      fvvArray.push(fvv.haSuspeitaClinicaAbelhas);
      fvvArray.push(fvv.suspeitaClinicaAbelhas);
      fvvArray.push(fvv.sinaisSintomasObservadosAbelhas);

      fvvArray.push(fvv.historicoSugaduraMorcegos);
      fvvArray.push(fvv.doencas);
      fvvArray.push(fvv.observacao);
      fvvArray.push(fvv.emissaoFormIN);

      data.push(fvvArray);
    });
  }

  return { headers, data };
};

export default async function generateFVVSimplificadoReport(
  listaFVVs: FVVReport.Simplificado[]
) {
  const reportData = await getReportData(listaFVVs);

  let worksheet = XLSX.utils.json_to_sheet(reportData.data);

  XLSX.utils.sheet_add_aoa(worksheet, reportData.headers, {
    origin: 'A1',
  });

  let workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, worksheet, 'FVV');

  XLSX.writeFile(workBook, `fvvs.xlsx`);
}
