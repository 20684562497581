import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import EditFormGraf from '../features/FormGraf/EditFormGraf';

export default function FormGrafEditView() {
  usePageTitle('Editando de FormGraf');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formGraf'>
        <EditFormGraf />
      </ErrorBoundary>
    </>
  );
}
