import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { LocalAcaoEducativa } from '../../sdk/@types';
import LocalAcaoEducativaService from '../../sdk/services/SIZ-API/LocalAcaoEducativa.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface LocalAcaoEducativaState {
  data: LocalAcaoEducativa.Response | null;
  fetching: boolean;
  query: LocalAcaoEducativa.Query;
  localAcaoEducativas: LocalAcaoEducativa.Summary[] | null;
  loading: LoadingState;
  errorMessage?: string | null;
}

const initialState: LocalAcaoEducativaState = {
  data: null,
  fetching: false,
  query: { page: 0, size: 10 },
  localAcaoEducativas: null,
  loading: 'idle',
  errorMessage: null,
};

export const insertLocalAcaoEducativa = createAsyncThunk(
  'localAcaoEducativa/insert',
  async (
    LocalAcaoEducativaRequest: LocalAcaoEducativa.Request,
    { rejectWithValue }
  ) => {
    try {
      return await LocalAcaoEducativaService.add(LocalAcaoEducativaRequest);
    } catch (error) {
      console.error('Error in insertLocalAcaoEducativa:', error);
      return rejectWithValue('Falha ao inserir o local da ação educativa');
    }
  }
);
export const filterLocalAcaoEducativa = createAsyncThunk(
  'localAcaoEducativa/filterLocalAcaoEducativas',
  async (query: LocalAcaoEducativa.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const localAcaoEducativa = await LocalAcaoEducativaService.getAll(query);
      return localAcaoEducativa;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os Local ação Educativa')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);
export const fetchLocalAcaoEducativaById = createAsyncThunk<
  LocalAcaoEducativa.Response,
  number
>(
  'localAcaoEducativa/fetchLocalAcaoEducativaById',
  async (id: number, { rejectWithValue }) => {
    return await LocalAcaoEducativaService.getById(id).catch(rejectWithValue);
  }
);
export const updateLocalAcaoEducativa = createAsyncThunk(
  'localAcaoEducativa/updateLocalAcaoEducativa',
  async (
    {
      id,
      localAcaoEducativa,
    }: { id: number; localAcaoEducativa: LocalAcaoEducativa.Request },
    { rejectWithValue }
  ) => {
    return await LocalAcaoEducativaService.update(id, localAcaoEducativa).catch(
      rejectWithValue
    );
  }
);
export const activateLocalAcaoEducativa = createAsyncThunk(
  'localAcaoEducativa/activate',
  async (id: number , { rejectWithValue }) => {
    try {
      return await LocalAcaoEducativaService.activate(id);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Falha ao atualizar o formulário');
      }
    }
  }
);
export const deactivateLocalAcaoEducativa = createAsyncThunk(
  'localAcaoEducativa/deactivate',
  async ( id : number , { rejectWithValue }) => {

    try {
      return await LocalAcaoEducativaService.deactivate(id);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Falha ao atualizar o formulário');
      }
    }
  }
);
const localAcaoEducativaSlice = createSlice({
  name: 'localAcaoEducativa',
  initialState,
  reducers: {
    clearLocalAcaoEducativaState: (state) => {
      return initialState;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<LocalAcaoEducativa.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertLocalAcaoEducativa.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(insertLocalAcaoEducativa.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(insertLocalAcaoEducativa.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(filterLocalAcaoEducativa.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterLocalAcaoEducativa.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.localAcaoEducativas = action.payload;
        state.fetching = false;
      })
      .addCase(filterLocalAcaoEducativa.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(fetchLocalAcaoEducativaById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchLocalAcaoEducativaById.fulfilled,
        (
          state,
          action: PayloadAction<LocalAcaoEducativa.Response | undefined>
        ) => {
          state.loading = 'fulfilled';
          //@ts-ignore
          state.data = action.payload;
        }
      )
      .addCase(fetchLocalAcaoEducativaById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(updateLocalAcaoEducativa.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateLocalAcaoEducativa.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(updateLocalAcaoEducativa.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(activateLocalAcaoEducativa.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(activateLocalAcaoEducativa.rejected, (state, action) => {
        state.loading = 'rejected';
        if (typeof action.payload === 'string') {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = 'Ocorreu um erro desconhecido';
        }
      })
      .addCase(deactivateLocalAcaoEducativa.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(deactivateLocalAcaoEducativa.rejected, (state, action) => {
        state.loading = 'rejected';
        if (typeof action.payload === 'string') {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = 'Ocorreu um erro desconhecido';
        }
      });
  },
});

export const { clearLocalAcaoEducativaState, setFetching, setQuery } =
  localAcaoEducativaSlice.actions;

export default localAcaoEducativaSlice.reducer;
