interface CircleFilledProps {
  size?: string;
}

export default function CircleFilled(props: CircleFilledProps) {
  return (
    <span
      role='img'
      aria-label='desktop'
      className='anticon anticon-desktop ant-menu-item-icon'
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={props.size ? props.size : '18px'}
        height={props.size ? props.size : '18px'}
        color='#ffffff'
        viewBox='0 0 2048 2048'
        fill='#ffffff'
      >
        <path d='M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z'></path>
      </svg>
    </span>
  );
}
