import { FormVIN } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
class FormVINIDBService extends ServiceIDB {
  static DB_STORE_FORM_VIN = 'formVIN';
  //static DB_STORE_FORM_VIN_INDEX_NAME = 'formVIN_index_id';
  //static DB_STORE_FORM_VIN_INDEX_KEY = 'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(FormVINIDBService.DB_STORE_FORM_VIN)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll(): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FormVINIDBService.DB_STORE_FORM_VIN);
    return list;
  }

  static async getAllPayload(): Promise<FormVIN.Input[]> {
    let list: FormVIN.Input[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<FormVIN.Input> {
    const db = await openDB(DBConfig.DB_NAME);
    const formVIN = await db.get(FormVINIDBService.DB_STORE_FORM_VIN, id);

    return formVIN.payload;
  }

  static async addAllFormVIN(lista: FormVIN.Input[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_FORM_VIN, obj);
    });
  }

  static async addFormVIN(formVINInput: FormVIN.Input) {
    const db = await openDB(DBConfig.DB_NAME);

    const obj = {
      date: new Date(),
      payload: formVINInput,
    };

    return db.add(this.DB_STORE_FORM_VIN, obj);
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FORM_VIN);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_FORM_VIN, id);
  }

  static async update(id: number, object: ServiceIDBPayload) {
    this.delete(id);
    return this.addFormVIN(object.payload);
  }

  static async count() {
    return this._count(this.DB_STORE_FORM_VIN);
  }
}

export default FormVINIDBService;
