import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormGRAF from './FormGRAF';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FormGrafActions from '../../../core/store/FormGRAF.slice';

function EditFormGRAF() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const formGraf = useSelector((state: RootState) => state.formGraf.data);
  const loading = useSelector(
    (state: RootState) => state.formGraf.loading
  );


  const prepareData = useCallback(
    (formGraf) => ({
      ...formGraf,
      dataDistribuicao: moment(formGraf?.dataDistribuicao),
    }),
    []
  );
  useEffect(() => {
    if (!isNaN(Number(id))) {
      dispatch(FormGrafActions.fetchFormGrafById(Number(id)));
    }
  }, [id, local, dispatch]);

  return (
    <>
      {!formGraf && <Skeleton active />}
      {formGraf && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando FormGraf</Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loading === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormGRAF formGraf={prepareData(formGraf)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFormGRAF;
