import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Historico } from '../../sdk/@types/Historico';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface HistoricoEstabelecimentoType {
  atividadesEmExibição: Historico.Estabelecimento[];
  checkAll: boolean;
  checkedList: CheckboxValueType[];
  indeterminate: boolean;
  tipoAtividades: string[];
  todasAsAtividades: Historico.Estabelecimento[];
}

const initialState: HistoricoEstabelecimentoType = {
  atividadesEmExibição: [],
  checkAll: true,
  checkedList: [],
  indeterminate: false,
  tipoAtividades: [],
  todasAsAtividades: [],
};

export const filterAtividades = createAsyncThunk(
  'historicoEstabelecimento/filterAtividades',
  async (
    {
      checkedList,
      tipoAtividades,
      todasAsAtividades,
    }: {
      checkedList: CheckboxValueType[];
      tipoAtividades: string[];
      todasAsAtividades: Historico.Estabelecimento[];
    },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setCheckedList(checkedList));
    await dispatch(
      setIndeterminate(
        !!checkedList.length && checkedList.length < tipoAtividades.length
      )
    );
    await dispatch(setCheckAll(checkedList.length === tipoAtividades.length));
    const atividadesFiltradas = todasAsAtividades?.filter((atividade) =>
      checkedList.includes(atividade.tipo)
    );
    await dispatch(setAtividadesEmExibição(atividadesFiltradas));
  }
);

const HistoricoEstabelecimentoSlice = createSlice({
  initialState,
  name: 'historicoEstabelecimento',
  reducers: {
    setAtividadesEmExibição(
      state,
      action: PayloadAction<Historico.Estabelecimento[]>
    ) {
      state.atividadesEmExibição = action.payload;
    },
    setCheckAll(state, action: PayloadAction<boolean>) {
      state.checkAll = action.payload;
    },
    setCheckedList(state, action: PayloadAction<CheckboxValueType[]>) {
      state.checkedList = action.payload;
    },
    setIndeterminate(state, action: PayloadAction<boolean>) {
      state.indeterminate = action.payload;
    },
    setTipoAtividades(state, action: PayloadAction<string[]>) {
      state.tipoAtividades = action.payload;
    },
    setTodasAsAtividades(
      state,
      action: PayloadAction<Historico.Estabelecimento[]>
    ) {
      state.todasAsAtividades = action.payload;
    },
  },
});

export const {
  setAtividadesEmExibição,
  setCheckAll,
  setCheckedList,
  setIndeterminate,
  setTipoAtividades,
  setTodasAsAtividades,
} = HistoricoEstabelecimentoSlice.actions;

const HistoricoEstabelecimentoReducer = HistoricoEstabelecimentoSlice.reducer;
export default HistoricoEstabelecimentoReducer;
