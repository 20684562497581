import { Button, Divider, Row, Table, Tag, Tooltip, Typography } from 'antd';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useCallback, useEffect, useState } from 'react';
import { AtendimentoErroSincronizacao } from '../../../sdk/@types/AtendimentoErroSincronizacao';
import { ErroSincronizacao } from '../../../sdk/@types/ErroSincronizacao';
import moment from 'moment';
import { AuditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import AtendimentoErroSincronizacaoService from '../../../sdk/services/SIZ-API/AtendimentoErrosSincronizacao.service';
import useDevOptions from '../../../core/hooks/useDevOptions';
import { StatusAtendimentoErroSincronizacao } from '../../../core/enums/StatusAtendimentoErroSincronizacao';

export default function ListAtendimentoErrosSincronizacaoView() {
  usePageTitle('Erros de sincronização');
  const navigate = useNavigate();
  const { idInTables } = useDevOptions();

  const [
    listaAtendimentoErrosSincronizacao,
    setListaAtendimentoErrosSincronizacao,
  ] = useState<AtendimentoErroSincronizacao.Summary[]>();

  const fetchListAtendimentoErrosSincronizacao = useCallback(async () => {
    await AtendimentoErroSincronizacaoService.getAll().then(
      setListaAtendimentoErrosSincronizacao
    );
  }, []);

  useEffect(() => {
    fetchListAtendimentoErrosSincronizacao();
  }, [fetchListAtendimentoErrosSincronizacao]);

  return (
    <>
      <Typography.Title level={3}>Erros de Sincronização</Typography.Title>
      <Divider />

      <Table
        dataSource={listaAtendimentoErrosSincronizacao}
        size={'small'}
        rowKey={'id'}
        title={() => {
          return (
            <Row justify={'space-between'}>
              <Typography.Title level={5} style={{ color: 'white' }}>
                Atendimentos
              </Typography.Title>
            </Row>
          );
        }}
        columns={[
          {
            dataIndex: 'id',
            width: idInTables ? 100 : 0,
            render(id) {
              return (
                idInTables && <Typography.Text>{`ID: ${id}`}</Typography.Text>
              );
            },
          },
          {
            dataIndex: ['status'],
            title: 'Status',
            width: 50,
            responsive: ['xs', 'sm'],
            render(
              status: AtendimentoErroSincronizacao.Summary['status'],
              record
            ) {
              return (
                <Tag
                  style={{
                    backgroundColor: `${StatusAtendimentoErroSincronizacao.colorOf(
                      status!
                    )}`,
                    color: 'white',
                  }}
                >
                  {StatusAtendimentoErroSincronizacao.valueOf(status)}
                </Tag>
              );
            },
          },
          {
            dataIndex: ['erroSincronizacao', 'dataCadastro'],
            title: 'Data',
            responsive: ['sm'],
            width: 150,
            render(dataCadastro: ErroSincronizacao.Summary['dataCadastro']) {
              return moment(dataCadastro).format('DD/MM/YYYY HH:mm');
            },
          },
          {
            dataIndex: ['erroSincronizacao', 'motivo'],
            title: 'Motivo',
            responsive: ['sm'],
            render(motivo: ErroSincronizacao.Summary['motivo']) {
              return <Tooltip title={motivo}>{motivo}</Tooltip>;
            },
          },

          {
            dataIndex: 'id',
            responsive: ['sm'],
            width: 60,
            align: 'right',
            render(id, atendimentoErroSincronizacao) {
              return (
                <Button
                  icon={<AuditOutlined />}
                  size={'small'}
                  type={'text'}
                  onClick={() => {
                    navigate(`/atendimentoErrosSincronizacao/${id}`);
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
}
