import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type MedidasSanitariasProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  specify?: string;
};
export default function PrintMedidasSanitarias({
  data,
}: MedidasSanitariasProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.specify && (
                  <div>
                    <strong>Especificar:</strong> <br />
                    {row.specify}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {data &&
          panelDescription('Risco de Bioseguridade', [
            {
              label: 'Houve necessidade de avaliação de risco?',
              condition: data.houveNecessidadeDeAvaliacaoDeRisco_7_1,
            },
            {
              label: 'Risco proporcionado pela granja sob avaliação',
              condition: data.riscoProporcionadoPelaGranja_7_1_1,
            },
            {
              label:
                'É necessária execução de Medidas de Biosseguridade Compensatórias?',
              condition:
                data.necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2,
            },
            {
              label: 'Descreva as Medida(s)',
              condition: data.medidasDeBiosseguridadeCompensatorias, // Este campo contém uma descrição, portanto usamos 'value' em vez de 'condition'
            },
          ])}
      </div>
    </>
  );
}
