import { EstabelecimentoAvicola } from '../../@types/EstabelecimentoAvicola';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class EstabelecimentoAvicolaService extends Service {
  static getAll(): Promise<EstabelecimentoAvicola.Summary[]> {
    return this.Http.get<EstabelecimentoAvicola.Summary[]>(
      '/estabelecimentos-avicolas'
    ).then(this.getData);
  }
  static getByProdutorCpf(
    cpf: string
  ): Promise<EstabelecimentoAvicola.Summary[]> {
    const queryString = generateQueryString({ cpf });
    return this.Http.get<EstabelecimentoAvicola.Summary[]>(
      '/estabelecimentos-avicolas'.concat(queryString)
    ).then(this.getData);
  }
  static getByMunicipio(
    codigoIBGE: string
  ): Promise<EstabelecimentoAvicola.Summary[]> {
    const queryString = generateQueryString({ codigoIBGE });
    return this.Http.get<EstabelecimentoAvicola.Summary[]>(
      '/estabelecimentos-avicolas'.concat(queryString)
    ).then(this.getData);
  }

  // static countByMunicipio(codgIbge: string) {
  //   return this.Http.get<number>(
  //     `revendas/municipio/${codgIbge}/count`
  //   ).then(this.getData);
  // }
}

export default EstabelecimentoAvicolaService;
