import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FormEduca, FVER, TermoFiscalizacao } from '../../../sdk/@types';
import FilterPanelEDUCA from './FilterPanelEDUCA';
import { useNavigate, useParams } from 'react-router-dom';
import * as FormEducaActions from '../../../core/store/FormEDUCA.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useDevOptions from '../../../core/hooks/useDevOptions';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import GetLocationButton from '../../components/GetLocationButton';
import AddParticipantModal from '../../components/AddParticipantModal';

interface DMS {
  degrees: number;
  minutes: number;
  seconds: number;
}

interface CoordinatesDMS {
  latitude: DMS;
  longitude: DMS;
}

export default function ListEDUCAs() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState<Error>();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const dispatch = useDispatch();

  const formEducas = useSelector(
    (state: RootState) => state.formEduca.formEducas
  );
  const query = useSelector((state: RootState) => state.formEduca.query);
  const fetching = useSelector((state: RootState) => state.formEduca.fetching);
  const [locations, setLocations] = useState<{ [id: number]: CoordinatesDMS }>(
    {}
  );
  const [selectedFormEducaId, setSelectedFormEducaId] = useState<number | null>(
    null
  );

  if (!online)
    throw new Error('Não é possível visualizar a lista de TFR no modo offline');
  if (error) throw error;

  useEffect(() => {
    dispatch(FormEducaActions.filterFormEducas(query));
    setFirstOpening(true);
  }, [dispatch, query]);

  useEffect(() => {}, [formEducas]);

  const handleLocationFetch = (id: number, location: CoordinatesDMS) => {
    setLocations((prevLocations) => ({
      ...prevLocations,
      [id]: location,
    }));
  };
  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);
  const [selectResposta, setSelectResposta] = useState<FVER.Summary>();

  const handleDelete = useCallback(
    async (id) => {
      if (id) {
        setDeleting(true);
        try {
          await dispatch(FormEducaActions.removeFormEduca(id));
          setDeleting(false);
          notification.success({
            message: 'FORM-EDUCA removido com sucesso',
          });
          dispatch(FormEducaActions.filterFormEducas(query));
        } catch (e: any) {
          setDeleting(false);
          notification.error({
            message: 'Não foi possível remover o FORM-EDUCA',
            description: e.message,
          });
        }
      }
    },
    [dispatch, query]
  );
  const handleEditClick = (id: number) => {
    dispatch(FormEducaActions.clearFormEducaState());
    navigate(`/educa/edicao/${id}`);
  };
  const handleViewClick = (id: number) => {
    dispatch(FormEducaActions.clearFormEducaState());
    navigate(`/educa/visualizar/${id}`);
  };
  const handleAddParticipantClick = (formEducaId: number) => {
    setSelectedFormEducaId(formEducaId);
    setModalVisible(true);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setSelectedFormEducaId(null);
  };

  return (
    <>
      <FilterPanelEDUCA />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FormEduca.Summary>
                columns={[
                  {
                    dataIndex: 'dataAcao',
                    title: 'Data da Ação',
                    width: 200,
                    render(dataAcao) {
                      return (
                        <Typography.Text>
                          {moment(new Date(dataAcao)).format('DD/MM/YYYY') ||
                            '-'}
                        </Typography.Text>
                      );
                    },
                  },
                  {
                    dataIndex: 'nome',
                    title: 'Local',
                    render(nome) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>{nome || '-'}</Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<MailOutlined />}
                            onClick={() => handleAddParticipantClick(id)}
                          />
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Button
                            icon={<PrinterOutlined />}
                            onClick={() => handleViewClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Popconfirm
                            title={'Deseja remover esse FORM-EDUCA'}
                            onConfirm={() => {
                              handleDelete(id);
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={formEducas?.content}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      FormEducaActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: formEducas?.totalElements,
                  pageSize: query.size,
                }}
                rowKey={'id'}
                size={'small'}
                showHeader={false}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Form EDUCAs
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/educa/cadastro`)}
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(
                                  FormEducaActions.filterFormEducas(query)
                                );
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
      {selectedFormEducaId !== null && (
        <AddParticipantModal
          visible={modalVisible}
          onCancel={handleCancel}
          formEducaId={selectedFormEducaId}
        />
      )}
    </>
  );
}
