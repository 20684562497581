import { Abatedouro } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class AbatedouroIDBService extends ServiceIDB {
  static DB_STORE_ABATEDOURO = 'abatedouro';
  static DB_STORE_ABATEDOURO_INDEX_CPF_NAME = 'abatedouro_index_cpf';
  static DB_STORE_ABATEDOURO_INDEX_CPF_KEY = 'payload.pessoa.cpfCnpj';
  static DB_STORE_ABATEDOURO_INDEX_MUNICIPIO_NAME =
    'abatedouro_index_municipio';
  static DB_STORE_ABATEDOURO_INDEX_MUNICIPIO_KEY =
    'payload.pessoa.endereco.municipio.codgIBGE';

  static async getByCnpj(cpf: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_ABATEDOURO,
      this.DB_STORE_ABATEDOURO_INDEX_CPF_NAME,
      cpf
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(AbatedouroIDBService.DB_STORE_ABATEDOURO);
    return list;
  }

  static async getAllPayload(): Promise<Abatedouro.Summary[]> {
    let list: Abatedouro.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static add(abatedouro: Abatedouro.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: abatedouro,
      };

      db.add(this.DB_STORE_ABATEDOURO, obj);
    });
  }

  static async addAllAbatedouro(lista: Abatedouro.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_ABATEDOURO, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_ABATEDOURO);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_ABATEDOURO,
      this.DB_STORE_ABATEDOURO_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default AbatedouroIDBService;
