import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { StatusAssinatura } from '../../../../core/enums/StatusAssinatura';
import { LIA } from '../../../../sdk/@types';

type ParecerFinalProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};
export default function PrintParecerFinal({ data }: ParecerFinalProps) {
  const getParecerLabel = (parecerCode: string): string => {
    if (parecerCode === undefined) {
      return 'Desconhecido';
    }
    const parecerLabels: { [key: string]: string } = {
      FAVORAVEL_REGISTRO: 'Favorável ao Registro',
      NAO_FAVORAVEL_REGISTRO: 'Não Favorável ao Registro',
      FAVORAVEL_MANUTENCAO_REGISTRO: 'Favorável à Manutenção do Registro',
      FAVORAVEL_MANUTENCAO_REGISTRO_ADVERTIDO:
        'Favorável à Manutenção do Registro – Estabelecimento Advertido',
      NAO_FAVORAVEL_MANUTENCAO_REGISTRO_SUSPENSO_ALOJAMENTO_AVES:
        'Não Favorável à Manutenção do Registro – Suspenso para Alojamento de Aves',
      NAO_FAVORAVEL_MANUTENCAO_REGISTRO_CANCELAMENTO:
        'Não Favorável à Manutenção do Registro – Cancelamento do Registro',
    };

    const label =  parecerLabels[parecerCode];
    return label ?? 'Desconhecido';
  };
// Função ajustada para aceitar string ou undefined
// Função para converter o valor do enum StatusAssinatura em string
const getStatusAssinaturaLabel = (statusAssinatura:string): string => {
  
  const statusLabels: { [key: string]: string } = {
    ASSINADO_DIGITALMENTE : 'Assinado Digitalmente',
    ASSINADO_FISICAMENTE : 'Assinado em Formulário Físico',
    AUSENTE_ESTABELECIMENTO : 'Ausência de Pessoas no Estabelecimento',
    PRODUTOR_NAO_ALFABETIZADO : 'Responsável não alfabetizado',
    PRODUTOR_RECUSOU_ASSINAR : 'Responsável recusou-se a assinar',
  };
  const label = statusLabels[statusAssinatura];
  return label ?? 'Desconhecido';
};



  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };
  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };
  return (
    <>
      <div>
        {data &&
          panelDescription('Parecer Final', [
            {
              label: 'Parecer',
              value: getParecerLabel(data.parecer ?? ''),
            },
            {
              label: 'Data do Parecer',
              value: data.dataParecer,
            },
            {
              label: 'Status da Assinatura',
              value: getStatusAssinaturaLabel(data.statusAssinatura ?? ''),
            },
          ])}
        {data &&
          panelComponent(
            'Assinatura',
            <div>
              {data.statusAssinatura && (
                <Typography.Text>
                  {StatusAssinatura.valueOf(data.statusAssinatura)}
                </Typography.Text>
              )}
              {data.assinatura && (
                <img
                  src={data.assinatura}
                  alt='my signature'
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    width: '150px',
                  }}
                />
              )}
            </div>
          )}
        <br />
      </div>
    </>
  );
}
