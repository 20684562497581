export enum AtivaPassiva {
  ATIVA = 'Ativa',
  PASSIVA = 'Passiva',
}
export namespace AtivaPassiva {
  export function keys(): string[] {
    return Object.keys(AtivaPassiva).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return AtivaPassiva[key as keyof typeof AtivaPassiva];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(AtivaPassiva).indexOf(
      value as unknown as AtivaPassiva
    );

    const key = Object.keys(AtivaPassiva)[indexOfS];

    return key;
  }
}
