import React, { useMemo, useState } from 'react';
import { Select, SelectProps } from 'antd';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  staticOptions: any;
}

function DebounceSelect<ValueType extends { label: string; value: string | number }>({
  staticOptions,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [options, setOptions] = useState<ValueType[]>(staticOptions);

  // Essa função permite que o usuário filtre as opções diretamente no frontend
  const filterOption = (input: any, option: any) => 
    option?.label?.toLowerCase()?.includes(input?.toLowerCase());
  return (
    <Select
      showSearch
      filterOption={filterOption}
      {...props}
      optionFilterProp='children'
      options={staticOptions?.map((option: any )=> ({
        label: option?.label,
        value: option?.value,
      }))}
    />
  );
}


export default DebounceSelect;