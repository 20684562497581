import {
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd';

import { useEffect, useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllEmpresasIntegradoras,
  setEmpresaIntegradoraSelecionada,
} from '../../../core/store/EmpresaIntegradora.slice';
import { RootState } from '../../../core/store';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { useParams } from 'react-router-dom';
interface Empresa {
  cpfCnpj?: string;
  endereco?: {
    bairro?: string;
    id?: number;
    logradouro: string;
    municipio: {
      id: number;
      nome: string;
      uf: {
        id: number;
        nome: string;
        sigla: string;
      };
    };
    numero?: string;
  };
  id: number;
  nome: string;
}

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};
export default function InformacaoGranja(props: InformacoesCadastroProps) {
  const { Option } = Select;
  const params = useParams<{ id: string; local?: string }>();
  const dispatch = useDispatch();

  const [selectedValueTipoExploracao, setSelectedValueTipoExploracao] =
    useState('');
  const [checkedInspencao, setCheckedInspencao] = useState<number>(0);

  const [selectedEmpresa, setSelectedEmpresa] = useState<Empresa | null>(null);

  const empresasIntegradoras = useSelector(
    (state: RootState) => state.empresaIntegradora.empresasIntegradoras
  );

  const { mode } = useLoadingPage();
  const handleChangeEmpresaIntegradora = (selectedValue: string) => {
    const selectedEmpresa = empresasIntegradoras?.find(
      (empresa) => empresa.nome === selectedValue
    );
    if (selectedEmpresa) {
      setSelectedEmpresa(selectedEmpresa);
      props.form.setFieldsValue({ empresaIntegradora: selectedEmpresa });
    }
  };

  useEffect(() => {
    if (selectedEmpresa) {
      dispatch(setEmpresaIntegradoraSelecionada(selectedEmpresa));
    }
  }, [selectedEmpresa, props.form, dispatch]);
  const handleChangeInspencao = (selectedValue: string) => {
    props.form.setFieldsValue({ tipoServicoDeInspecao: selectedValue } as any);
  };

  const handleChangeTipoExploracao = (selectedValue: string) => {
    setSelectedValueTipoExploracao(selectedValue);
    props.form.setFieldsValue({ tipoExploracao: selectedValue } as any);
  };

  const handleCheckboxChange = (name: any) => {
    if (name === 'registro') {
      props.form.setFieldsValue({ registro: true, manutencaoRegistro: false });
    } else if (name === 'manutencaoRegistro') {
      props.form.setFieldsValue({ registro: false, manutencaoRegistro: true });
    }
  };

  useEffect(() => {
    dispatch(fetchAllEmpresasIntegradoras());
  }, [dispatch]);

  useEffect(() => {
    const valorCampo = props.form.getFieldValue('inspencaoCheckbox');
    setSelectedValueTipoExploracao(
      props.form.getFieldValue('tipoExploracao') || ''
    );

    if (valorCampo != null) {
      setCheckedInspencao(Number(valorCampo));
    }
  }, [props.form, props.form.getFieldValue, setSelectedValueTipoExploracao]);

  const empresaOptions =
    empresasIntegradoras?.map((empresa) => ({
      value: empresa.nome,
      label: empresa.nome,
      data: empresa,
    })) || [];
  const uniqueEmpresaOptions = empresaOptions.filter((option, index, self) => {
    return (
      index ===
      self.findIndex((t) => t.data && t.data.nome === option.data.nome)
    );
  });

  return (
    <fieldset disabled={props.formDisabled}>
      <>
        <Row gutter={24}>
          <Form.Item name={'id'} hidden>
            <Input disabled hidden />
          </Form.Item>
          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Form.Item
              name={'registro'}
              valuePropName='checked'
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Checkbox
                onChange={() => handleCheckboxChange('registro')}
                disabled={!params.local && mode !== 'CREATE'}
              >
                REGISTRO
              </Checkbox>
            </Form.Item>

            <Form.Item
              name={'manutencaoRegistro'}
              valuePropName='checked'
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Checkbox
                onChange={() => handleCheckboxChange('manutencaoRegistro')}
                disabled={!params.local && mode !== 'CREATE'}
              >
                MANUTENÇÃO DO REGISTRO
              </Checkbox>
            </Form.Item>
          </Row>
        </Row>

        <Col xs={24} lg={24}>
          <Divider orientation='left'>
            Informações da granja | Exploração
          </Divider>

          <Row gutter={24}>
            <Col xs={24} lg={16}>
              <Form.Item
                label={'Empresa Integradora'}
                name={['empresaIntegradora', 'nome']}
              >
                <Select
                  disabled={props.formDisabled}
                  key={uniqueEmpresaOptions.length}
                  placeholder='Selecione'
                  onChange={handleChangeEmpresaIntegradora}
                  options={uniqueEmpresaOptions}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item label='Tipo Exploração' name={'tipoExploracao'}>
                <Select
                  disabled={props.formDisabled}
                  placeholder='Selecione'
                  onChange={handleChangeTipoExploracao}
                  value={selectedValueTipoExploracao}
                >
                  <Option value='CORTE'>Corte</Option>
                  <Option value='POSTURA_COM_RECRIA'>Postura com recria</Option>
                  <Option value='POSTURA_SEM_RECRIA'>Postura sem recria</Option>
                  <Option value='OUTROS'>Outros</Option>
                </Select>
              </Form.Item>
            </Col>
            {selectedValueTipoExploracao === 'OUTROS' && (
              <Col xs={24} lg={24}>
                <Form.Item
                  label={'Descreva o Tipo da Exploração'}
                  name={'outros'}
                >
                  <TextArea showCount rows={5} maxLength={2000} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            {(selectedValueTipoExploracao === 'POSTURA_COM_RECRIA' ||
              selectedValueTipoExploracao === 'POSTURA_SEM_RECRIA') && (
              <>
                <Col xs={24} lg={24}>
                  <Divider orientation='left'>
                    Serviço de Inspeção Oficial
                  </Divider>

                  <>
                    <Row gutter={24}>
                      <Col xs={24} lg={8}>
                        <Form.Item name={'tipoServicoDeInspecao'}>
                          <Select
                            disabled={props.formDisabled}
                            placeholder='Selecione'
                            onChange={handleChangeInspencao}
                            options={[
                              { value: 'SIM', label: 'SIM' },
                              {
                                value: 'SISE',
                                label: 'SISE',
                              },
                              {
                                value: 'SUSAF',
                                label: 'SUSAF',
                              },
                              { value: 'SIF', label: 'SIF' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item name={'numeroServicoDeInspecao'}>
                          <Input
                            disabled={props.formDisabled}
                            placeholder='Número'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                </Col>
                <Divider />
              </>
            )}
          </Row>
          <br />
          <br />
        </Col>
      </>
    </fieldset>
  );
}
