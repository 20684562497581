import { Divider, Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormFVER from './FormFVER';

export default function CreateFVER() {
  const { loading } = useLoadingPage();

  return (
    <>
      <Divider orientation='left'>
        <Typography.Title level={3}>Novo FVER</Typography.Title>
      </Divider>

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormFVER />
      </Spin>
    </>
  );
}
