export enum SinaisClinicos {
  ABCESSO_CUTANEO = 'Abcesso cutâneo',
  ABCESSO_EM_ORGAOS = 'Abcesso em órgãos',
  ABORTAMENTO = 'Abortamento',
  ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA = 'Acúmulo de líquido na cavidade celomática',
  ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA_COM_ASPECTO_SANGUINOLENTO = 'Acúmulo de líquido na cavidade celomática com aspecto sanguinolento',
  ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA_COM_ASPECTO_SANGUINOLENTO_E_ODOR_FETIDO = 'Acúmulo de líquido na cavidade celomática com aspecto sanguinolento e odor fétido',
  AGALAXIA = 'Agalaxia',
  AGRESSIVIDADE = 'Agressividade',
  ALTERACAO_COMPORTAMENTAL = 'Alteração comportamental',
  ALTERACAO_POSTURA_LOCOMOCAO = 'Alteração de postura/locomoção',
  AMONTOAMENTO = 'Amontoamento',
  ANDAR_CAMBALEANTE = 'Andar cambaleante',
  ANDAR_EM_CIRCULOS = 'Andar em círculos',
  ANEMIA = 'Anemia',
  ANOREXIA_PERDA_DE_APETITE = 'Anorexia/Perda de apetite',
  APATIA_CANSACO = 'Apatia/Cansaço',
  ARTIBEUS_LITURATUS = 'Artibeus lituratus',
  ARTRITE = 'Artrite',
  ASAS_ATROFIADAS = 'Asas atrofiadas',
  ASAS_CAIDAS = 'Asas caídas',
  ATAXIA = 'Ataxia',
  AUMENTO_DE_BACO = 'Aumento de baço',
  AUMENTO_DE_FIGADO = 'Aumento de fígado',
  AUMENTO_DE_RIM = 'Aumento de rim',
  AUMENTO_DO_CORACAO_E_PERICARDIO = 'Aumento do coração e pericárdio (pericardite supurativa)',
  BAIXA_POPULACAO_DE_ABELHAS = 'Baixa população de abelhas',
  BRANQUIAS_COM_ASPECTO_MARROM = 'Branquias com aspecto marrom',
  BRANQUIAS_COM_LESOES_ESBRANQUICADAS = 'Branquias com lesões esbranquiçadas (sugestão de necrose)',
  BUSCA_DE_OXIGÊNIO_NA_SUPERFICIE_DA_AGUA = 'Busca de oxigênio na superfície da água',
  CAQUEXIA = 'Caquexia',
  CEGUEIRA = 'Cegueira',
  CIANOSE = 'Cianose',
  CIANOSE_EM_MEMBROS = 'Cianose em Membros',
  CIANOSE_EM_PONTAS_DE_ORELHAS_OU_FOCINHO = 'Cianose em pontas de orelhas ou focinho',
  CONSTIPACAO = 'Constipação',
  CLAUDICACAO_MANQUEIRA = 'Claudicação/Manqueira',
  COLICA = 'Cólica',
  COMPLICACOES_REPRODUTIVAS = 'Complicações reprodutivas',
  CONJUNTIVITE = 'Conjuntivite',
  CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA = 'Constipação intestinal, seguida de diarreia',
  CONVULSAO = 'Convulsão',
  CORIZA = 'Coriza',
  CORRIMENTO_NASAL = 'Corrimento nasal',
  CORRIMENTO_NASAL_PURULENTO = 'Corrimento Nasal Purulento',
  CORRIMENTO_SECRECAO_OCULAR = 'Corrimento/Secreção ocular',
  CORROSAO_EM_TORNO_DO_ANUS_E_PAPILA_UTOGENITAL = 'Corrosão em torno do anus e papila utogenital',
  CRIAS_FRACAS = 'Crias fracas',
  DEPRESSAO = 'Depressão',
  DESIDRATACAO = 'Desidratação',
  DESPOVOACAO_DA_COLMEIA = 'Despovoação da colmeia',
  DIARREIA = 'Diarreia',
  DIARREIA_AMARELADA = 'Diarreia amarelada',
  DIARREIA_AQUOSA = 'Diarreia aquosa',
  DIARREIA_ESVERDEADA = 'Diarreia esverdeada',
  DIFICULDADE_RESPIRATORIA = 'Dificuldade respiratória (animais na entrada da água)',
  DIARREIA_SANGUINOLENTA = 'Diarreia sanguinolenta',
  DIFICULDADE_DE_DEGLUTICAO = 'Dificuldade de deglutição',
  DIMINUICAO_NA_PRODUCAO_DE_MEL = 'Diminuição na produção de mel',
  DISPNEIA_DIFICULDADE_RESPIRATORIA = 'Dispneia/ Dificuldade respiratória',
  DISTENSAO_ABDOMINAL = 'Distensão abdominal',
  DISTURBIOS_REPRODUTIVOS = 'Distúrbios reprodutivos',
  EDEMA = 'Edema',
  EDEMA_DE_BARBELA = 'Edema de barbela',
  EDEMA_DE_MEMBROS = 'Edema de membros',
  EDEMA_FACIAL_E_DE_CABECA = 'Edema facial e de cabeça',
  EDEMA_SUBCUTANEO_NA_CABECA_OU_PESCOCO = 'Edema subcutâneo na cabeça ou pescoço',
  EMACIACAO = 'Emaciação',
  EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA = 'Emagrecimento/Perda de peso progressiva',
  ENFRAQUECIMENTO_DA_COLMEIA = 'Enfraquecimento da colmeia',
  EPIDIDIMITE = 'Epididimite',
  EPISTAXE = 'Epistaxe',
  ERITEMA_CUTANEO = 'Eritema cutâneo',
  ERICAMENTO_DE_ESCAMAS = 'Eriçamento de escamas',
  ESCURECIMENTO_DA_PELE = 'Escurecimento da pele',
  ESPIRROS = 'Espirros',
  EXOFTALMIA = 'Exoftalmia',
  FETO_MUMIFICADO = 'Feto mumificado',
  FORMACAO_DE_PAPA_NAS_CELULAS_DE_CRIACAO = 'Formação de papa nas células de criação',
  FRAQUEZA = 'Fraqueza',
  GRANULOMAS_EM_BACO = 'granulomas em baço',
  GRANULOMAS_EM_BRANQUIAS = 'granulomas em branquias',
  GRANULOMAS_EM_CORACAO = 'Granulomas em coração',
  GRANULOMAS_EM_CEREBRO = 'Granulomas em cerébro',
  GRANULOMAS_EM_FIGADO = 'Granulomas em fígado',
  GRANULOMAS_EM_RIM = 'granulomas em rim',
  HEMATURIA = 'Hematúria',
  HEMORRAGIA = 'Hemorragia',
  HEMORRAGIA_CUTANEA = 'Hemorragia Cutânea',
  HEMORRAGIA_NASAL = 'Hemorragia Nasal',
  HEMORRAGIA_INTESTINAL = 'Hemorragia intestinal',
  HEMORRAGIAS_DO_TIPO_PETEQUIAL = 'hemorragias do tipo petequial',
  HIPEREMIA_DE_MUCOSAS = 'Hiperemia de mucosas',
  HIPERESTESIA = 'Hiperestesia',
  HIPERTERMIA_FEBRE = 'Hipertermia/Febre',
  ICTERICIA = 'Icterícia',
  INAPETENCIA = 'Inapetência',
  INCOORDENACAO = 'Incoordenação',
  INFERTILIDADE_QUEDA_NA_FERTILIDADE = 'Infertilidade/queda na fertilidade',
  IRRITACAO_DE_PELE = 'Irritação de pele (peixes friccionando superfície rígida no viveiro)',
  LACRIMEJAMENTO = 'Lacrimejamento',
  LARVAS_AMARELADAS = 'Larvas amareladas',
  LARVAS_ESCURAS = 'Larvas escuras',
  LARVAS_FLACIDAS = 'Larvas flácidas',
  LARVAS_OPACAS = 'Larvas opacas',
  LESAO_CICATRIZADA_EM_BOCA_FOCINHO = 'Lesão cicatrizada em boca/focinho',
  LESAO_CICATRIZADA_EM_MEMBROS = 'Lesão cicatrizada em membros',
  LESAO_CICATRIZADA_EM_TETO_UBERE = 'Lesão cicatrizada em teto/úbere',
  LESOES_CIRCULARES_ESBRANQUICADAS_NA_PELE_NADADEIRAS_CORNEA_BOCA_E_CABECA = 'Lesões circulares esbranquiçadas na pele, nadadeiras, córnea, boca e cabeça',
  LESOES_CUTANEAS = 'Lesões cutâneas',
  LESOES_DETECTADAS_NO_ABATE = 'Lesões detectadas no abate',
  LESOES_NA_BOCA = 'Lesões na boca',
  LESOES_HEMORRAGICAS_NA_PELE = 'Lesões hemorrágicas na pele',
  LETARGIA = 'Letargia',
  LINFONODO_AUMENTADO = 'Linfonodo aumentado',
  MANCHAS_AMARRONZADAS_NA_PELE = 'Manchas amarronzadas na pele',
  MANCHAS_VERMELHAS = 'Manchas vermelhas',
  MASTITE = 'Mastite',
  MORTALIDADE_DE_AVES_10 = 'Mortalidade de aves > 10%',
  MORTALIDADE_DE_AVES_15 = 'Mortalidade de aves > 15%',
  MORTALIDADE_DE_AVES_20 = 'Mortalidade de aves > 20%',
  MORTE_DE_LARVAS = 'Morte de larvas',
  MORTE_SUBITA = 'Morte Súbita',
  MORTE_MORTALIDADE = 'Morte/ Mortalidade',
  MOVIMENTO_DE_PEDALAGEM = 'Movimento de pedalagem',
  NATIMORTALIDADE = 'Natimortalidade',
  NISTAGMO = 'Nistagmo',
  NAO_SE_APLICA = 'Não se aplica',
  NATACAO_EM_RODOPIO = 'Natação em rodopio',
  NATACAO_ERRATICA = 'Natação errática',
  NATACAO_LENTA = 'Natação lenta (letargia)',
  NODULOS_CUTANEOS = 'Nódulos cutâneos',
  NODULOS_EM_ORGAOS = 'Nódulos em órgãos',
  NODULOS_ESBRANQUICADOS_NO_BACO = 'Nódulos esbranquiçados no baço',
  NODULOS_ESBRANQUICADOS_NO_RIM = 'Nódulos esbranquiçados no rim',
  NODULOS_NA_CAVIDADE_NASAL = 'Nódulos na cavidade nasal',
  ODOR_DESAGRADAVEL_NA_COLMEIA = 'Odor desagradável na colmeia',
  OPACIDADE_DE_CORNEA = 'Opacidade de córnea',
  OPISTOTONO = 'Opistótono',
  ORQUITE = 'Orquite',
  PALIDEZ_DAS_BRANQUIAS = 'Palidez das branquias',
  PARALISIA = 'Paralisia',
  PARALISIA_DE_MEMBROS = 'Paralisia de membros',
  PARALISIA_FACIAL = 'Paralisia facial',
  PARALISIA_TOTAL = 'Paralisia total',
  PARESIA = 'Paresia',
  PARESIA_DE_POSTERIORES_QUARTO_CAIDO = 'Paresia de posteriores/Quarto caído',
  PENAS_ARREPIADAS = 'Penas arrepiadas',
  PERDAS_DE_ESCAMAS = 'Perdas de escamas',
  PERDA_DESCOLAMENTO_DE_UNHA_CASCO = 'Perda/descolamento de unha/casco',
  PERITONITE_COM_HEMORRAGIA_DIFUSA_EM_TODOS_OS_ORGAOS_DA_CAVIDADE_CELOMATICA = 'Peritonite, com hemorragia difusa em todos os órgãos da cavidade celomática',
  PNEUMONIA = 'Pneumonia',
  PROSTRACAO = 'Prostração',
  PRURIDO_COCEIRA = 'Prurido/Coceira',
  PUPAS_MUMIFICADAS = 'Pupas mumificadas',
  PUSTULAS = 'Pústulas',
  QUEDA_DE_POSTURA = 'Queda de postura',
  QUEDA_NA_PRODUCAO = 'Queda na produção',
  QUEDA_NO_CONSUMO_DE_ALIMENTO = 'Queda no consumo de alimento',
  QUEDA_NO_CONSUMO_DE_AGUA = 'Queda no consumo de água',
  QUEDAS_FREQUENTES = 'Quedas frequentes',
  RANGER_DE_DENTES = 'Ranger de dentes',
  REDUCAO_DO_APETITE = 'Redução do apetite (sobrando ração após o trato)',
  REPETICAO_DE_CIO = 'Repetição de cio',
  SECRECOES_NA_CAVIDADE_NASAL = 'Secreções na cavidade nasal',
  SIALORREIA_SALIVACAO = 'Sialorreia/Salivação',
  SINAIS = 'Sinais',
  TAQUICARDIA = 'Taquicardia',
  TAQUIPNEIA = 'Taquipneia',
  TENESMO = 'Tenesmo',
  TIMPANISMO = 'Timpanismo',
  TORCICOLO = 'Torcicolo',
  TOSSE = 'Tosse',
  TRAQUEITE = 'Traqueíte',
  TREMORES = 'Tremores',
  TUMORES = 'Tumores',
  VESICULAS = 'Vesículas',
  VESICULAS_EM_PATAS = 'Vesículas em patas',
  VESICULAS_EM_TETO_UBERE = 'Vesículas em teto/úbere',
  VESICULAS_NA_BOCA_FOCINHO = 'Vesículas na boca/focinho',
  VOMITO = 'Vômito',
  VOMITO_COM_SANGUE = 'Vômito com sangue',
  ULCERAS_NA_CAVIDADE_NASAL = 'Úlceras na cavidade nasal',
  ULCERACAO_DE_PELE_E_NADADEIRAS = 'Ulceração de pele e nadadeiras',
  ULCERACAO_DE_PELE_E_NADADEIRAS_COM_AREAS_HEMORRAGICAS = 'Ulceração de pele e nadadeiras, com áreas hemorrágicas',
}

export namespace SinaisClinicos {
  export function keys(): string[] {
    return Object.keys(SinaisClinicos).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return SinaisClinicos[key as keyof typeof SinaisClinicos];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(SinaisClinicos).indexOf(
      value as unknown as SinaisClinicos
    );

    const key = Object.keys(SinaisClinicos)[indexOfS];

    return key;
  }

  export function bovinos(): string[] {
    return [
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGALAXIA),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CAQUEXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CIANOSE),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL_PURULENTO),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIARREIA_SANGUINOLENTA),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EDEMA_DE_MEMBROS),
      keyOf(SinaisClinicos.EDEMA_SUBCUTANEO_NA_CABECA_OU_PESCOCO),
      keyOf(SinaisClinicos.EMACIACAO),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.FRAQUEZA),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HIPEREMIA_DE_MUCOSAS),
      keyOf(SinaisClinicos.HIPERESTESIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INCOORDENACAO),
      keyOf(SinaisClinicos.INFERTILIDADE_QUEDA_NA_FERTILIDADE),
      keyOf(SinaisClinicos.LACRIMEJAMENTO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_BOCA_FOCINHO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_MEMBROS),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_TETO_UBERE),
      keyOf(SinaisClinicos.LESOES_DETECTADAS_NO_ABATE),
      keyOf(SinaisClinicos.LESOES_NA_BOCA),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.NISTAGMO),
      keyOf(SinaisClinicos.OPISTOTONO),
      keyOf(SinaisClinicos.ORQUITE),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PERDA_DESCOLAMENTO_DE_UNHA_CASCO),
      keyOf(SinaisClinicos.PNEUMONIA),
      keyOf(SinaisClinicos.PROSTRACAO),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.PUSTULAS),
      keyOf(SinaisClinicos.QUEDA_NA_PRODUCAO),
      keyOf(SinaisClinicos.QUEDAS_FREQUENTES),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.REPETICAO_DE_CIO),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.SINAIS),
      keyOf(SinaisClinicos.TAQUICARDIA),
      keyOf(SinaisClinicos.TAQUIPNEIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TOSSE),
      keyOf(SinaisClinicos.TRAQUEITE),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
      keyOf(SinaisClinicos.VESICULAS_EM_PATAS),
      keyOf(SinaisClinicos.VESICULAS_EM_TETO_UBERE),
      keyOf(SinaisClinicos.VESICULAS_NA_BOCA_FOCINHO),
      keyOf(SinaisClinicos.VOMITO),
    ];
  }

  export function bufalos(): string[] {
    return [
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CAQUEXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CIANOSE),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL_PURULENTO),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIARREIA_SANGUINOLENTA),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EDEMA_DE_MEMBROS),
      keyOf(SinaisClinicos.EDEMA_SUBCUTANEO_NA_CABECA_OU_PESCOCO),
      keyOf(SinaisClinicos.EMACIACAO),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.EPISTAXE),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.FRAQUEZA),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HIPEREMIA_DE_MUCOSAS),
      keyOf(SinaisClinicos.HIPERESTESIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INFERTILIDADE_QUEDA_NA_FERTILIDADE),
      keyOf(SinaisClinicos.LACRIMEJAMENTO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_BOCA_FOCINHO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_MEMBROS),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_TETO_UBERE),
      keyOf(SinaisClinicos.LESOES_DETECTADAS_NO_ABATE),
      keyOf(SinaisClinicos.LESOES_NA_BOCA),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.ORQUITE),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PERDA_DESCOLAMENTO_DE_UNHA_CASCO),
      keyOf(SinaisClinicos.PNEUMONIA),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.PUSTULAS),
      keyOf(SinaisClinicos.QUEDA_NA_PRODUCAO),
      keyOf(SinaisClinicos.QUEDAS_FREQUENTES),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.REPETICAO_DE_CIO),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUIPNEIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TOSSE),
      keyOf(SinaisClinicos.TRAQUEITE),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
      keyOf(SinaisClinicos.VESICULAS_EM_PATAS),
      keyOf(SinaisClinicos.VESICULAS_EM_TETO_UBERE),
      keyOf(SinaisClinicos.VESICULAS_NA_BOCA_FOCINHO),
    ];
  }

  export function galinhas(): string[] {
    return [
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ASAS_CAIDAS),
      keyOf(SinaisClinicos.CORIZA),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DESIDRATACAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIARREIA_AMARELADA),
      keyOf(SinaisClinicos.DIARREIA_ESVERDEADA),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_FACIAL_E_DE_CABECA),
      keyOf(SinaisClinicos.ESPIRROS),
      keyOf(SinaisClinicos.FRAQUEZA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INFERTILIDADE_QUEDA_NA_FERTILIDADE),
      keyOf(SinaisClinicos.MORTALIDADE_DE_AVES_10),
      keyOf(SinaisClinicos.MORTALIDADE_DE_AVES_15),
      keyOf(SinaisClinicos.MORTALIDADE_DE_AVES_20),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MORTE_SUBITA),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.NAO_SE_APLICA),
      keyOf(SinaisClinicos.NODULOS_CUTANEOS),
      keyOf(SinaisClinicos.NODULOS_EM_ORGAOS),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARESIA),
      keyOf(SinaisClinicos.PENAS_ARREPIADAS),
      keyOf(SinaisClinicos.PROSTRACAO),
      keyOf(SinaisClinicos.QUEDA_DE_POSTURA),
      keyOf(SinaisClinicos.QUEDA_NA_PRODUCAO),
      keyOf(SinaisClinicos.QUEDA_NO_CONSUMO_DE_AGUA),
      keyOf(SinaisClinicos.QUEDA_NO_CONSUMO_DE_ALIMENTO),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TOSSE),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.TUMORES),
    ];
  }

  export function asininos(): string[] {
    return [
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COLICA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONJUNTIVITE),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL_PURULENTO),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIFICULDADE_DE_DEGLUTICAO),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.ERITEMA_CUTANEO),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.HIPERESTESIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INCOORDENACAO),
      keyOf(SinaisClinicos.LESOES_CUTANEAS),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.LINFONODO_AUMENTADO),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NODULOS_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PNEUMONIA),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUICARDIA),
      keyOf(SinaisClinicos.TAQUIPNEIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.ULCERAS_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.VESICULAS),
    ];
  }

  export function equinos(): string[] {
    return [
      keyOf(SinaisClinicos.ABCESSO_CUTANEO),
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGALAXIA),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COLICA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONJUNTIVITE),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL_PURULENTO),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIFICULDADE_DE_DEGLUTICAO),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.FRAQUEZA),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HIPERESTESIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INCOORDENACAO),
      keyOf(SinaisClinicos.LESOES_CUTANEAS),
      keyOf(SinaisClinicos.LESOES_DETECTADAS_NO_ABATE),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.LINFONODO_AUMENTADO),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.NODULOS_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.OPISTOTONO),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PNEUMONIA),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUICARDIA),
      keyOf(SinaisClinicos.TAQUIPNEIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
    ];
  }

  export function suinos(): string[] {
    return [
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.AMONTOAMENTO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CIANOSE),
      keyOf(SinaisClinicos.CIANOSE_EM_MEMBROS),
      keyOf(SinaisClinicos.CIANOSE_EM_PONTAS_DE_ORELHAS_OU_FOCINHO),
      keyOf(SinaisClinicos.CONSTIPACAO),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CONJUNTIVITE),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIARREIA_AMARELADA),
      keyOf(SinaisClinicos.DIARREIA_AQUOSA),
      keyOf(SinaisClinicos.DIARREIA_SANGUINOLENTA),
      keyOf(SinaisClinicos.DIFICULDADE_DE_DEGLUTICAO),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.ERITEMA_CUTANEO),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HEMORRAGIA_CUTANEA),
      keyOf(SinaisClinicos.HEMORRAGIA_NASAL),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.ICTERICIA),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INCOORDENACAO),
      keyOf(SinaisClinicos.INFERTILIDADE_QUEDA_NA_FERTILIDADE),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_BOCA_FOCINHO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_MEMBROS),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_TETO_UBERE),
      keyOf(SinaisClinicos.LESOES_CUTANEAS),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.LINFONODO_AUMENTADO),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NAO_SE_APLICA),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.OPISTOTONO),
      keyOf(SinaisClinicos.ORQUITE),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PERDA_DESCOLAMENTO_DE_UNHA_CASCO),
      keyOf(SinaisClinicos.PNEUMONIA),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUIPNEIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TOSSE),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
      keyOf(SinaisClinicos.VESICULAS_EM_PATAS),
      keyOf(SinaisClinicos.VESICULAS_EM_TETO_UBERE),
      keyOf(SinaisClinicos.VESICULAS_NA_BOCA_FOCINHO),
      keyOf(SinaisClinicos.VOMITO),
      keyOf(SinaisClinicos.VOMITO_COM_SANGUE),
    ];
  }

  export function caprinos(): string[] {
    return [
      keyOf(SinaisClinicos.ABCESSO_CUTANEO),
      keyOf(SinaisClinicos.ABCESSO_EM_ORGAOS),
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGALAXIA),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CIANOSE),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONJUNTIVITE),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DESIDRATACAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIFICULDADE_DE_DEGLUTICAO),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EDEMA_SUBCUTANEO_NA_CABECA_OU_PESCOCO),
      keyOf(SinaisClinicos.EMACIACAO),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.EPISTAXE),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HIPEREMIA_DE_MUCOSAS),
      keyOf(SinaisClinicos.HIPERESTESIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.LACRIMEJAMENTO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_BOCA_FOCINHO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_MEMBROS),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_TETO_UBERE),
      keyOf(SinaisClinicos.LESOES_CUTANEAS),
      keyOf(SinaisClinicos.LESOES_DETECTADAS_NO_ABATE),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.LINFONODO_AUMENTADO),
      keyOf(SinaisClinicos.MASTITE),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.NISTAGMO),
      keyOf(SinaisClinicos.OPISTOTONO),
      keyOf(SinaisClinicos.ORQUITE),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PERDA_DESCOLAMENTO_DE_UNHA_CASCO),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.QUEDA_NA_PRODUCAO),
      keyOf(SinaisClinicos.QUEDAS_FREQUENTES),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.REPETICAO_DE_CIO),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUICARDIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TIMPANISMO),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
      keyOf(SinaisClinicos.VESICULAS_EM_PATAS),
      keyOf(SinaisClinicos.VESICULAS_EM_TETO_UBERE),
      keyOf(SinaisClinicos.VESICULAS_NA_BOCA_FOCINHO),
      keyOf(SinaisClinicos.VOMITO),
    ];
  }

  export function ovinos(): string[] {
    return [
      keyOf(SinaisClinicos.ABCESSO_CUTANEO),
      keyOf(SinaisClinicos.ABCESSO_EM_ORGAOS),
      keyOf(SinaisClinicos.ABORTAMENTO),
      keyOf(SinaisClinicos.AGALAXIA),
      keyOf(SinaisClinicos.AGRESSIVIDADE),
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ALTERACAO_POSTURA_LOCOMOCAO),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANEMIA),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.APATIA_CANSACO),
      keyOf(SinaisClinicos.ARTRITE),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CIANOSE),
      keyOf(SinaisClinicos.CLAUDICACAO_MANQUEIRA),
      keyOf(SinaisClinicos.COMPLICACOES_REPRODUTIVAS),
      keyOf(SinaisClinicos.CONJUNTIVITE),
      keyOf(SinaisClinicos.CONSTIPACAO_INTESTINAL_SEGUIDA_DE_DIARREIA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.CORRIMENTO_NASAL),
      keyOf(SinaisClinicos.CORRIMENTO_SECRECAO_OCULAR),
      keyOf(SinaisClinicos.CRIAS_FRACAS),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DESIDRATACAO),
      keyOf(SinaisClinicos.DIARREIA),
      keyOf(SinaisClinicos.DIARREIA_SANGUINOLENTA),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTURBIOS_REPRODUTIVOS),
      keyOf(SinaisClinicos.EDEMA),
      keyOf(SinaisClinicos.EDEMA_DE_BARBELA),
      keyOf(SinaisClinicos.EDEMA_DE_MEMBROS),
      keyOf(SinaisClinicos.EDEMA_SUBCUTANEO_NA_CABECA_OU_PESCOCO),
      keyOf(SinaisClinicos.EMACIACAO),
      keyOf(SinaisClinicos.EMAGRECIMENTO_PERDA_DE_PESO_PROGRESSIVA),
      keyOf(SinaisClinicos.EPIDIDIMITE),
      keyOf(SinaisClinicos.EPISTAXE),
      keyOf(SinaisClinicos.FETO_MUMIFICADO),
      keyOf(SinaisClinicos.HEMATURIA),
      keyOf(SinaisClinicos.HEMORRAGIA),
      keyOf(SinaisClinicos.HIPEREMIA_DE_MUCOSAS),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.INFERTILIDADE_QUEDA_NA_FERTILIDADE),
      keyOf(SinaisClinicos.LACRIMEJAMENTO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_BOCA_FOCINHO),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_MEMBROS),
      keyOf(SinaisClinicos.LESAO_CICATRIZADA_EM_TETO_UBERE),
      keyOf(SinaisClinicos.LESOES_CUTANEAS),
      keyOf(SinaisClinicos.LESOES_DETECTADAS_NO_ABATE),
      keyOf(SinaisClinicos.LESOES_NA_BOCA),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.LINFONODO_AUMENTADO),
      keyOf(SinaisClinicos.MASTITE),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.NATIMORTALIDADE),
      keyOf(SinaisClinicos.NISTAGMO),
      keyOf(SinaisClinicos.OPISTOTONO),
      keyOf(SinaisClinicos.ORQUITE),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PARALISIA_TOTAL),
      keyOf(SinaisClinicos.PERDA_DESCOLAMENTO_DE_UNHA_CASCO),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.QUEDA_NA_PRODUCAO),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.REPETICAO_DE_CIO),
      keyOf(SinaisClinicos.SECRECOES_NA_CAVIDADE_NASAL),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TAQUICARDIA),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TIMPANISMO),
      keyOf(SinaisClinicos.TORCICOLO),
      keyOf(SinaisClinicos.TREMORES),
      keyOf(SinaisClinicos.VESICULAS),
      keyOf(SinaisClinicos.VESICULAS_EM_PATAS),
      keyOf(SinaisClinicos.VESICULAS_EM_TETO_UBERE),
      keyOf(SinaisClinicos.VESICULAS_NA_BOCA_FOCINHO),
      keyOf(SinaisClinicos.VOMITO),
    ];
  }

  export function abelhas(): string[] {
    return [
      keyOf(SinaisClinicos.ASAS_ATROFIADAS),
      keyOf(SinaisClinicos.BAIXA_POPULACAO_DE_ABELHAS),
      keyOf(SinaisClinicos.DESPOVOACAO_DA_COLMEIA),
      keyOf(SinaisClinicos.DIMINUICAO_NA_PRODUCAO_DE_MEL),
      keyOf(SinaisClinicos.ENFRAQUECIMENTO_DA_COLMEIA),
      keyOf(SinaisClinicos.FORMACAO_DE_PAPA_NAS_CELULAS_DE_CRIACAO),
      keyOf(SinaisClinicos.LARVAS_AMARELADAS),
      keyOf(SinaisClinicos.LARVAS_ESCURAS),
      keyOf(SinaisClinicos.LARVAS_FLACIDAS),
      keyOf(SinaisClinicos.LARVAS_OPACAS),
      keyOf(SinaisClinicos.MORTE_DE_LARVAS),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.ODOR_DESAGRADAVEL_NA_COLMEIA),
      keyOf(SinaisClinicos.PUPAS_MUMIFICADAS),
    ];
  }

  export function morcegos(): string[] {
    return [
      keyOf(SinaisClinicos.ALTERACAO_COMPORTAMENTAL),
      keyOf(SinaisClinicos.ANDAR_CAMBALEANTE),
      keyOf(SinaisClinicos.ANDAR_EM_CIRCULOS),
      keyOf(SinaisClinicos.ANOREXIA_PERDA_DE_APETITE),
      keyOf(SinaisClinicos.ARTIBEUS_LITURATUS),
      keyOf(SinaisClinicos.ATAXIA),
      keyOf(SinaisClinicos.CEGUEIRA),
      keyOf(SinaisClinicos.CONVULSAO),
      keyOf(SinaisClinicos.DEPRESSAO),
      keyOf(SinaisClinicos.DISPNEIA_DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.HIPERTERMIA_FEBRE),
      keyOf(SinaisClinicos.INAPETENCIA),
      keyOf(SinaisClinicos.LETARGIA),
      keyOf(SinaisClinicos.MORTE_MORTALIDADE),
      keyOf(SinaisClinicos.MOVIMENTO_DE_PEDALAGEM),
      keyOf(SinaisClinicos.PARALISIA),
      keyOf(SinaisClinicos.PARALISIA_DE_MEMBROS),
      keyOf(SinaisClinicos.PARALISIA_FACIAL),
      keyOf(SinaisClinicos.PRURIDO_COCEIRA),
      keyOf(SinaisClinicos.RANGER_DE_DENTES),
      keyOf(SinaisClinicos.SIALORREIA_SALIVACAO),
      keyOf(SinaisClinicos.TENESMO),
      keyOf(SinaisClinicos.TREMORES),
    ];
  }

  export function peixes(): string[] {
    return [
      keyOf(SinaisClinicos.ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA),
      keyOf(
        SinaisClinicos.ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA_COM_ASPECTO_SANGUINOLENTO
      ),
      keyOf(
        SinaisClinicos.ACUMULO_DE_LIQUIDO_NA_CAVIDADE_CELOMATICA_COM_ASPECTO_SANGUINOLENTO_E_ODOR_FETIDO
      ),
      keyOf(SinaisClinicos.AUMENTO_DE_BACO),
      keyOf(SinaisClinicos.AUMENTO_DE_FIGADO),
      keyOf(SinaisClinicos.AUMENTO_DE_RIM),
      keyOf(SinaisClinicos.AUMENTO_DO_CORACAO_E_PERICARDIO),
      keyOf(SinaisClinicos.BRANQUIAS_COM_ASPECTO_MARROM),
      keyOf(SinaisClinicos.BRANQUIAS_COM_LESOES_ESBRANQUICADAS),
      keyOf(SinaisClinicos.BUSCA_DE_OXIGÊNIO_NA_SUPERFICIE_DA_AGUA),
      keyOf(SinaisClinicos.CORROSAO_EM_TORNO_DO_ANUS_E_PAPILA_UTOGENITAL),
      keyOf(SinaisClinicos.DIFICULDADE_RESPIRATORIA),
      keyOf(SinaisClinicos.DISTENSAO_ABDOMINAL),
      keyOf(SinaisClinicos.ERICAMENTO_DE_ESCAMAS),
      keyOf(SinaisClinicos.ESCURECIMENTO_DA_PELE),
      keyOf(SinaisClinicos.EXOFTALMIA),
      keyOf(SinaisClinicos.GRANULOMAS_EM_BACO),
      keyOf(SinaisClinicos.GRANULOMAS_EM_BRANQUIAS),
      keyOf(SinaisClinicos.GRANULOMAS_EM_CORACAO),
      keyOf(SinaisClinicos.GRANULOMAS_EM_CEREBRO),
      keyOf(SinaisClinicos.GRANULOMAS_EM_FIGADO),
      keyOf(SinaisClinicos.GRANULOMAS_EM_RIM),
      keyOf(SinaisClinicos.HEMORRAGIA_INTESTINAL),
      keyOf(SinaisClinicos.HEMORRAGIAS_DO_TIPO_PETEQUIAL),
      keyOf(SinaisClinicos.IRRITACAO_DE_PELE),
      keyOf(
        SinaisClinicos.LESOES_CIRCULARES_ESBRANQUICADAS_NA_PELE_NADADEIRAS_CORNEA_BOCA_E_CABECA
      ),
      keyOf(SinaisClinicos.LESOES_HEMORRAGICAS_NA_PELE),
      keyOf(SinaisClinicos.NATACAO_EM_RODOPIO),
      keyOf(SinaisClinicos.NATACAO_ERRATICA),
      keyOf(SinaisClinicos.NATACAO_LENTA),
      keyOf(SinaisClinicos.NODULOS_ESBRANQUICADOS_NO_BACO),
      keyOf(SinaisClinicos.NODULOS_ESBRANQUICADOS_NO_RIM),
      keyOf(SinaisClinicos.OPACIDADE_DE_CORNEA),
      keyOf(SinaisClinicos.PALIDEZ_DAS_BRANQUIAS),
      keyOf(SinaisClinicos.PERDAS_DE_ESCAMAS),
      keyOf(
        SinaisClinicos.PERITONITE_COM_HEMORRAGIA_DIFUSA_EM_TODOS_OS_ORGAOS_DA_CAVIDADE_CELOMATICA
      ),
      keyOf(SinaisClinicos.REDUCAO_DO_APETITE),
      keyOf(SinaisClinicos.ULCERACAO_DE_PELE_E_NADADEIRAS),
      keyOf(
        SinaisClinicos.ULCERACAO_DE_PELE_E_NADADEIRAS_COM_AREAS_HEMORRAGICAS
      ),
    ];
  }
}
