import { useCallback, useEffect } from 'react';
import { Button, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { LocalAcaoEducativa } from '../../../sdk/@types';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import * as LocalAcaoEducativaActions from '../../../core/store/LocalAcaoEducativa.slice';

interface FilterPanelLocalAcaoEducativaProps {
  open?: boolean;
  defaultQuery?: LocalAcaoEducativa.Query;
}

export default function FilterPanelLocalAcaoEducativa(
  props: FilterPanelLocalAcaoEducativaProps
) {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();

  const dispatch = useDispatch<AppDispatch>();
  const query = useSelector(
    (state: RootState) => state.localAcaoEducativo.query
  );

  useEffect(() => {
    dispatch(LocalAcaoEducativaActions.filterLocalAcaoEducativa(query));
  }, [dispatch, query]);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const formValues = form.getFieldsValue();

        dispatch(
          LocalAcaoEducativaActions.setQuery({
            status: formValues?.status,
            page: 0,
          })
        );
      } catch (error) {
        console.error(
          'Erro ao buscar os Local Ação Educativa com filtro:',
          error
        );
      }
    },
    [dispatch, form]
  );

  const removeField = useCallback(
    (fieldName: string) => {
      form.setFieldsValue({
        [fieldName]: null,
      });
    },
    [form]
  );

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          defaultActiveKey={props.open ? '1' : '0'}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label='Status'>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Item
                      name='status'
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select placeholder='Selecione o status' allowClear>
                        <Select.Option value='ATIVO'>Ativo</Select.Option>
                        <Select.Option value='INATIVO'>Inativo</Select.Option>
                      </Select>
                    </Form.Item>
                    <Button
                      style={{
                        height: '32px',
                        marginLeft: '8px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('status')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      dispatch(LocalAcaoEducativaActions.setQuery({ page: 0 }));
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
