import { Divider, Skeleton, Spin, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormularioRespostas } from '../../../sdk/@types';
import FormularioRespostaIDBService from '../../../sdk/services/indexeddb/FormularioRespostaIDB.service';
import FormRespostas from './FormRespostas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FormRespostaActions from '../../../core/store/FormResposta.slice';

function EditformularioResposta() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();

  const formRespostaOnline = useSelector(
    FormRespostaActions.selectFormRespostas
  );
 
  const loadingState = useSelector(
    (state: RootState) => state.formRespostas.loading
  );

  const [formularioRespostaOffline, setFormularioRespostaOffline] =
    useState<FormularioRespostas.Request | null>(null);
  const [loading, setLoading] = useState(true);


  const fetchFormularioRespostaOffline = useCallback(async () => {
    try {
      const result = await FormularioRespostaIDBService.getByIdFromInputTable(Number(id));
      setFormularioRespostaOffline(result);
    } catch (error) {
      console.error('Erro ao buscar dados offline:', error);
    } finally {
      setLoading(false);
    }
  }, [id, local]);

  const prepareData = useCallback(
    (formResposta) => ({
      ...formResposta,
    }),
    []
  );

  useEffect(() => {
    if (loadingState === 'fulfilled' || loadingState === 'rejected') {
      setLoading(false);
    }
  }, [loadingState]);

  useEffect(() => {
    if (!isNaN(Number(id))) {
      setLoading(true);

      if (local) {
        fetchFormularioRespostaOffline();
      } else {
        dispatch(FormRespostaActions.fetchFormRespostaById(Number(id)));
      }
    }
  }, [fetchFormularioRespostaOffline, id, local, dispatch]);

  const dataToDisplay = local ? formularioRespostaOffline : formRespostaOnline;
  const preparedData = dataToDisplay ? prepareData(dataToDisplay) : null;

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && preparedData && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>
              Editando Formulário Resposta
            </Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loadingState === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormRespostas formResposta={preparedData} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditformularioResposta;
