import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FVV } from '../../sdk/@types';
import FVVService from '../../sdk/services/SIZ-API/FVV.service';

interface FVVState {
  fvvs: FVV.Paginated | undefined;
  fetching: boolean;
  query: FVV.Query;
}

const initialState: FVVState = {
  fvvs: undefined,
  fetching: false,
  query: { page: 0, size: 10 },
};

export const filter = createAsyncThunk(
  'fvv/filter',
  async (query: FVV.Query, { dispatch, rejectWithValue }) => {
    await FVVService.getAll(query)
      .then(async (fvvs) => await dispatch(storeFVV(fvvs)))
      .catch((e) => {
        rejectWithValue(new Error('Não foi possível buscar os FVVs'));
      });
  }
);

const FVVSlice = createSlice({
  initialState,
  name: 'fvv',
  reducers: {
    storeFVV(state, action: PayloadAction<FVV.Paginated>) {
      state.fvvs = action.payload;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FVV.Query>) {
      state.query = action.payload;
    },
  },
});

export const { storeFVV, setFetching, setQuery } = FVVSlice.actions;

const FVVReducer = FVVSlice.reducer;
export default FVVReducer;
