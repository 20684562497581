import { Recinto } from '../../@types/Recinto';
import Service from './Service';

class RecintoService extends Service {
  static getByCodigo(recintoId: number) {
    return this.Http.get<Recinto.Summary>(`/recintos/${recintoId}`).then(
      this.getData
    );
  }

  static getByMunicipio(codgIbge: string) {
    return this.Http.get<Recinto.Summary[]>(`recintos/municipio/${codgIbge}`, {
      timeout: 600000,
    }).then(this.getData);
  }

  static countByMunicipio(codgIbge: string) {
    return this.Http.get<number>(`recintos/municipio/${codgIbge}/count`).then(
      this.getData
    );
  }
}

export default RecintoService;
