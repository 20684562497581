import { Abatedouro } from '../../@types/Abatedouro';
import Service from './Service';

class AbatedouroService extends Service {
  static getByCNPJ(abatedouroCnpj: string) {
    return this.Http.get<Abatedouro.Summary>(
      `/abatedouros/cnpj/${abatedouroCnpj}`
    ).then(this.getData);
  }

  static getByMunicipio(codgIbge: string) {
    return this.Http.get<Abatedouro.Summary[]>(
      `abatedouros/municipio/${codgIbge}`,
      { timeout: 600000 }
    ).then(this.getData);
  }

  static countByMunicipio(codgIbge: string) {
    return this.Http.get<number>(
      `abatedouros/municipio/${codgIbge}/count`
    ).then(this.getData);
  }
}

export default AbatedouroService;
