import { Card, Descriptions, Divider, List, Typography } from 'antd';
import EnvConfig from '../../EnvConfig';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store';

export default function EnvironmentView() {
  usePageTitle('Dev Tools');

  const permissoes = useSelector(
    (state: RootState) => state.permissoesReducer.permissoes
  );

  useEffect(() => {}, [permissoes]);

  return (
    <>
      <Typography.Title level={3}>Dev Tools</Typography.Title>
      <Divider />

      <Card title='EnvConfig' bodyStyle={{ padding: '0' }}>
        <Descriptions size={'small'} column={1} bordered>
          <Descriptions.Item label={'REACT_APP_STAGE'} span={1}>
            {process.env.REACT_APP_STAGE}
          </Descriptions.Item>
          <Descriptions.Item label={'REACT_APP_API_URL_DEV'} span={1}>
            {process.env.REACT_APP_API_URL_DEV}
          </Descriptions.Item>
          <Descriptions.Item label={'REACT_APP_BASE_URL_DEV'} span={1}>
            {process.env.REACT_APP_BASE_URL_DEV}
          </Descriptions.Item>
          <Descriptions.Item label={'stage'} span={1}>
            {EnvConfig.stage()}
          </Descriptions.Item>
          <Descriptions.Item label={'apiUrl'}>
            {EnvConfig.apiUrl()}
          </Descriptions.Item>
          <Descriptions.Item label={'baseUrl'}>
            {EnvConfig.baseUrl()}
          </Descriptions.Item>
          <Descriptions.Item label={'version'}>
            {EnvConfig.version()}
          </Descriptions.Item>
          <Descriptions.Item label={'isDev?'}>
            {EnvConfig.isDev() ? 'true' : 'false'}
          </Descriptions.Item>
          <Descriptions.Item label={'isHom?'}>
            {EnvConfig.isHom() ? 'true' : 'false'}
          </Descriptions.Item>
          <Descriptions.Item label={'isProd?'}>
            {EnvConfig.isProd() ? 'true' : 'false'}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <br />

      <Card title='Permissões Persistidas'>
        <List
          dataSource={permissoes}
          itemLayout='vertical'
          size='small'
          renderItem={(permissao) => (
            <List.Item>
              <Typography.Text>{permissao.nome}</Typography.Text>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
}
