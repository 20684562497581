import axios from 'axios';
import Service from '../../sdk/services/SIZ-API/Service';
import AuthorizationService from './Authorization.service';
import EnvConfig from '../../app/EnvConfig';

Service.setRequestInterceptor(async (request) => {
  const accessToken = AuthorizationService.getAccessToken();

  if (accessToken) {
    //@ts-ignore
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return request;
});

Service.setResponseInterceptor(
  (response) => response,
  async (error) => {
    // recupera informações da requisição
    const originalRequest = error.config;

    // caso o erro seja de autenticação e ainda não foi feito o retry
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // recupera o code verifier e o refresh token
      const storage = {
        codeVerifier: AuthorizationService.getCodeVerifier(),
        refreshToken: AuthorizationService.getRefreshToken(),
      };

      const { codeVerifier, refreshToken } = storage;
      // caso algum não exista, não é possível renovar o token
      if (
        !refreshToken ||
        !codeVerifier ||
        refreshToken === 'undefined' ||
        codeVerifier === 'undefined'
      ) {
        //AuthService.logout();
        return error;
      }

      // renova o token
      const tokens = await AuthorizationService.renewToken({
        codeVerifier,
        refreshToken,
      });

      AuthorizationService.setAccessToken(tokens.access_token);
      AuthorizationService.setRefreshToken(tokens.refresh_token);
      // armazena os tokens para novas requisições
      // implementa o token na requisição
      originalRequest.headers[
        'Authorization'
      ] = `Bearer ${tokens.access_token}`;

      // retorna uma nova chamada do axios com essa requisição
      //return axios(originalRequest);
      const instance = axios.create({
        baseURL: EnvConfig.baseUrl(),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return instance(originalRequest);
    }

    return error;
  }
);
