import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
} from 'antd';
import { useCallback, useEffect } from 'react';
import AuthorizationService from '../../../core/auth/Authorization.service';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import UsuarioService from '../../../sdk/services/SIZ-API/Usuario.service';
import { SessionCredentials } from '../../../sdk/@types';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../../components/CustomModal';

type LoginSIZProps = {
  usuarioSIZ: any;
  setUsuarioSIZ: (any: any) => any;
  callback: () => any;
};

export default function LoginSIZ(props: LoginSIZProps) {
  const [form] = useForm<SessionCredentials.Input>();
  const navigate = useNavigate();

  useEffect(() => {}, [props.usuarioSIZ]);

  const handleFormSubmit = useCallback(() => {}, []);

  return (
    <>
      <CustomModal
        centered
        style={{
          width: '400px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        title={'Faça Login no SIZ para vincular ao MT Cidadão'}
        footer={
          <Space size={12} direction={'vertical'} style={{ width: '100%' }}>
            <Col xs={24}>
              <Button
                type='primary'
                style={{ width: '100%' }}
                icon={<LoginOutlined />}
                onClick={async () => {
                  form.validateFields(['userName', 'password']);

                  //setLogingIn(true);
                  await UsuarioService.login({
                    userName: form.getFieldValue('userName'),
                    password: form.getFieldValue('password'),
                  })
                    .then((usuario) => {
                      props.setUsuarioSIZ(usuario);
                      AuthorizationService.setUsuarioSIZ(usuario);
                      notification.success({
                        message:
                          'O seu usuário do SIZ foi vinculado ao MT Cidadão com sucesso',
                        description:
                          'Use somente a conta do MT Cidadão nos próximos acessos ao SIZ mobile',
                      });

                      AuthorizationService.setUsuarioVinculado(true);
                      props.callback();
                      navigate('/home');
                    })
                    .catch((e) => {
                      notification.error({
                        message: e.message,
                      });
                    });
                }}
              >
                Login
              </Button>
            </Col>
            <Col xs={24}>
              <Button
                key='/logout'
                danger
                style={{ width: '100%' }}
                onClick={() => {
                  AuthorizationService.logout();
                }}
                icon={<LogoutOutlined />}
              >
                Logar com outro usuário
              </Button>
            </Col>
          </Space>
        }
        open={true}
        closable={false}
      >
        <div>
          <Form<SessionCredentials.Input>
            form={form}
            layout='vertical'
            id='loginSIZ'
            onFinish={handleFormSubmit}
            onFinishFailed={() => {
              notification.error({
                message: 'Alguns campos obrigatórios não foram preenchidos',
                description: 'Por favor revise seus dados',
              });
            }}
          >
            <Row
              align={'middle'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              <Space size={15} direction='vertical' style={{ width: '100%' }}>
                <Col xs={24}>
                  <Form.Item
                    label={'Usuário'}
                    name={'userName'}
                    rules={[
                      { required: true, message: 'O campo é obrigatório' },
                    ]}
                    initialValue={
                      AuthorizationService.getUsuarioSIZ()?.userName
                    }
                  >
                    <Input id={'loginSIZ_username'} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={'Senha'}
                    name={'password'}
                    rules={[
                      { required: true, message: 'O campo é obrigatório' },
                    ]}
                  >
                    <Input.Password id={'loginSIZ_password'} />
                  </Form.Item>
                </Col>
              </Space>
            </Row>
          </Form>
        </div>
      </CustomModal>
    </>
  );
}
