import { DeleteOutlined } from '@ant-design/icons';
import {
  Col,
  FormInstance,
  Row,
  Form,
  Input,
  notification,
  Button,
  Divider,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import scrollFieldToTop from '../../core/functions/scrollFieldToTop';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import useRecinto from '../../core/hooks/useRecinto';
import useSetor from '../../core/hooks/useSetor';
import { Setor } from '../../sdk/@types/Setor';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';

interface RecintoProps {
  form: FormInstance;
  formDisabled: boolean;
}

export default function RecintoPanel(props: RecintoProps) {
  const { online } = useNavigatorStatus();
  const { mode, firstOpening, setFirstOpening, setLoading } = useLoadingPage();

  const { recinto, fetchingRecinto, fetchRecintoByCodigo, setFetchingRecinto } =
    useRecinto();
  const [recintoSelecionado, setRecintoSelecionado] = useState<boolean>(false);
  const { fetchSetoresByMunicipio } = useSetor();

  const fetchRecinto = useCallback(
    async (codigoRecinto: number) => {
      if (!codigoRecinto) return;
      if (isNaN(Number(codigoRecinto))) return;

      setLoading(true);
      await fetchRecintoByCodigo(codigoRecinto)
        .catch((e) => {
          setLoading(false);
          setFetchingRecinto(false);
          throw e;
        })
        .finally(() => {
          setLoading(false);
          setFetchingRecinto(false);
        });
    },
    [fetchRecintoByCodigo, setFetchingRecinto, setLoading]
  );

  const fetchSetores = useCallback(async () => {
    if (recinto?.municipio.codgIBGE) {
      setLoading(true);
      try {
        await fetchSetoresByMunicipio('' + recinto.municipio.codgIBGE)
          .catch(() => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } finally {
        setLoading(false);
      }
    }
  }, [fetchSetoresByMunicipio, recinto, setLoading]);

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      fetchRecinto(props.form.getFieldValue(['recinto', 'id'])).then(() => {
        setLoading(false);
      });
    }
  }, [mode]);

  useEffect(() => {
    if (!props.form.getFieldValue(['recinto', 'id'])) return;
    if (!online && mode === 'EDIT') return;
    if (mode === 'VIEW') return;

    if (!fetchingRecinto) {
      if (!recinto) {
        if (!firstOpening)
          notification.info({
            message: `Não foi possível encontrar nenhum recinto`,
          });
      } else {
        props.form.setFieldsValue({
          recinto: recinto,
        });

        setRecintoSelecionado(true);
        fetchSetores();

        notification.success({
          message: `Recinto ${recinto.pessoa.codigo}-${recinto.pessoa.nome} selecionado`,
        });
        setFirstOpening(false);
      }
    }
    setLoading(fetchingRecinto);
  }, [fetchingRecinto]);

  const removeRecinto = useCallback(() => {
    setRecintoSelecionado(false);
    props.form.setFieldsValue({
      recinto: undefined,
      setor: undefined,
    });
  }, []);

  return (
    <>
      <Divider orientation='left'>Dados do recinto</Divider>

      <Row gutter={24} align={'bottom'}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Código do recinto'}
            name={['recinto', 'id']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
              {
                validator: async (_, codigoRecinto) => {
                  if (!codigoRecinto) {
                    return Promise.reject();
                  }
                  if (isNaN(Number(codigoRecinto))) {
                    return Promise.reject(
                      new Error('O código precisa ser um número')
                    );
                  }
                },
              },
            ]}
          >
            <Input.Search
              placeholder={'Informe o código do recinto'}
              loading={fetchingRecinto}
              allowClear={false}
              disabled={recintoSelecionado || mode === 'EDIT'}
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled || mode === 'EDIT'}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeRecinto}
                />
              }
              onClick={() => {
                scrollFieldToTop('recinto_id');
              }}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onSearch={(codigoRecinto) => {
                if (!codigoRecinto) removeRecinto();
                else fetchRecinto(Number(codigoRecinto));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item label={'Nome'} name={['recinto', 'pessoa', 'nome']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Form.Item name={['recinto', 'municipio', 'id']} hidden>
          <Input disabled />
        </Form.Item>

        <Form.Item name={['recinto', 'municipio', 'uf']} hidden>
          <Input disabled />
        </Form.Item>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Município'}
            name={['recinto', 'municipio', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item hidden name={['setor', 'id']}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={'Setor'}
            name={['setor', 'nome']}
            /* rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]} */
          >
            <Input disabled />
            {/* <Select
              onChange={handleSelectSetorChange}
              loading={fetchingRecinto}
              placeholder={`${
                listaSetor
                  ? 'Selecione o setor'
                  : 'Selecione o recinto primeiro'
              }`}
              placement={'bottomLeft'}
              disabled={props.formDisabled}
            >
              {listaSetor?.map((setor) => (
                <Select.Option key={setor.id} value={JSON.stringify(setor)}>
                  <Card
                    style={{
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {setor.numero} - {setor.nome}
                  </Card>
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
