import { Divider, Spin, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FVER } from '../../../sdk/@types';
import useFVER from '../../../core/hooks/useFVER';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import FormFVV from './FormFVV';

export default function CreateFVV() {
  const { loading } = useLoadingPage();
  const [error, setError] = useState<Error>();
  const params = useParams<{ id: string; local?: string }>();

  const [, setFVEROffline] = useState<FVER.Input>();
  const { fetchFVERById } = useFVER();

  const fetchFVEROffline = useCallback(async () => {
    return await FVERIDBService.getByIdFromInput_Table(Number(params.id)).then(
      (v) => {
        setFVEROffline(v);
      }
    );
  }, [params.id]);

  useEffect(() => {
    const fetch = async () => {
      if (!isNaN(Number(params.id))) {
        if (!params.local) {
          await fetchFVERById(Number(params.id)).catch((e) => {
            setError(e);
          });
        } else {
          await fetchFVEROffline().catch((e) => {
            setError(e);
          });
        }
      }
    };

    fetch();
  }, [fetchFVERById, fetchFVEROffline, params]);

  if (error) throw error;

  return (
    <>
      <Typography.Title level={2}>Novo FVV</Typography.Title>

      <Divider />

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormFVV />
      </Spin>
    </>
  );
}
