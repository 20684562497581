import { useEffect } from 'react';
import { Card, Descriptions, Row, Typography } from 'antd';
import { RecebimentosRevendas } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';

type PrintRecebimentoRevendaProps = {
  recebimentoRevenda?: RecebimentosRevendas.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintRecebimentoRevenda(
  props: PrintRecebimentoRevendaProps
) {
  useEffect(() => {}, [props.recebimentoRevenda]);

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item) => (
                <Descriptions.Item
                  key={item.value}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };
  const mapStatus = (status: string) => {
    switch (status) {
      case 'NAO_RECEBIDO':
        return 'Não Recebido';
      case 'RECEBIDO':
        return 'Recebido';
      default:
        return 'Pendente';
    }
  };
  
  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO RECEBIMENTO DE INSUMOS PECUÁRIOS - SVO IV
              </Typography.Title>
            </Row>
            <br />
            {panelDescription('Dados do Recebimento', [
              {
                label: 'Data do Recebimento',
                value: moment(props.recebimentoRevenda?.dataRecebimento).format(
                  'DD/MM/YYYY HH:mm'
                ),
              },
              {
                label: 'Número da NFE',
                value: props.recebimentoRevenda?.numeroNFE,
              },
              {
                label: 'Documento de Entrada ID',
                value: props.recebimentoRevenda?.documentoEntradaId,
              },
            ])}
            <br />
            {panelDescription('Revenda de Origem', [
              {
                label: 'Nome',
                value: props.recebimentoRevenda?.revendaOrigem?.nome,
              },
              {
                label: 'CNPJ',
                value: props.recebimentoRevenda?.revendaOrigem?.cpfCnpj,
              },
              {
                label: 'Endereço',
                value: `${props.recebimentoRevenda?.revendaOrigem?.endereco?.logradouro}, ${props.recebimentoRevenda?.revendaOrigem?.endereco?.numero} - ${props.recebimentoRevenda?.revendaOrigem?.endereco?.bairro}, ${props.recebimentoRevenda?.revendaOrigem?.endereco?.municipio.nome} - ${props.recebimentoRevenda?.revendaOrigem?.endereco?.municipio.uf.sigla}`,
                span: 2,
              },
            ])}
            <br />
            {panelDescription('Revenda de Destino', [
              {
                label: 'Nome',
                value: props.recebimentoRevenda?.revendaDestino?.nome,
              },
              {
                label: 'CNPJ',
                value: props.recebimentoRevenda?.revendaDestino?.cpfCnpj,
              },
              {
                label: 'Endereço',
                value: `${props.recebimentoRevenda?.revendaDestino?.endereco?.logradouro}, ${props.recebimentoRevenda?.revendaDestino?.endereco?.numero} - ${props.recebimentoRevenda?.revendaDestino?.endereco?.bairro}, ${props.recebimentoRevenda?.revendaDestino?.endereco?.municipio.nome} - ${props.recebimentoRevenda?.revendaDestino?.endereco?.municipio.uf.sigla}`,
                span: 2,
              },
            ])}
            <br />
            {props.recebimentoRevenda?.itens &&
              panelComponent(
                'Itens Recebidos',
                props.recebimentoRevenda?.itens?.map((item, index) =>
                  panelDescription(`Item ${index + 1}`, [
                    {
                      label: 'Produto',
                      value: item.produto.nome,
                      span: 1,
                    },
                    {
                      label: 'Status',
                      value: mapStatus(item.status),
                      span: 2,
                    },
                    {
                      label: 'Número da Partida',
                      value: item.numeroPartida,
                      span: 1,
                    },
                    {
                      label: 'Data de Fabricação',
                      value: item.dataFabricacao,
                      span: 1,
                    },
                    {
                      label: 'Data de Validade',
                      value: item.dataValidade,
                      span: 1,
                    },
                    {
                      label: 'Quantidade',
                      value: item.quantidade,
                      span: 1,
                    },
                    {
                      label: 'Produto Armazenado Adequadamente',
                      value: item.produtoArmazenadoAdequadamente,
                      span: 1,
                    },
                    {
                      label: 'Produto Devolvido ao Fornecedor',
                      value: item.produtoDevolvidoFornecedor,
                      span: 1,
                    },
                    {
                      label: 'Observações',
                      value: item.observacao,
                      span: 2,
                    },
                  ])
                )
              )}
            <br />
            {props.recebimentoRevenda?.servidores &&
              panelComponent(
                'Servidores',
                props.recebimentoRevenda?.servidores?.map((servidor, index) => (
                  <div key={index}>
                    {panelDescription('', [
                      {
                        label: 'Nome',
                        value: servidor.pessoa.nome,
                      },
                      {
                        label: 'CPF',
                        value: servidor.pessoa?.cpfCnpj?.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                          '$1.$2.$3-$4'
                        ),
                        span: 2,
                      },
                      {
                        label: 'Matrícula',
                        value: servidor.matricula,
                      },
                    ])}
                  </div>
                ))
              )}

            <br />
            {props.recebimentoRevenda?.termoFiscalizacao &&
              panelDescription('Termo de Fiscalização de Revenda', [
                {
                  label: 'Número',
                  value: props.recebimentoRevenda?.termoFiscalizacao.numero,
                },
                {
                  label: 'Data',
                  value: moment(
                    props.recebimentoRevenda?.termoFiscalizacao.dataVisita
                  ).format('DD/MM/YYYY HH:mm'),
                },
                {
                  label: 'Nome Fiscalizado',
                  value:
                    props.recebimentoRevenda?.termoFiscalizacao.nomeFiscalizado,
                },
                {
                  label: 'Município Fiscalizado',
                  value:
                    props.recebimentoRevenda?.termoFiscalizacao
                      .municipioFiscalizado?.nome,
                },
              ])}

            <br />
            {props.recebimentoRevenda &&
              panelComponent(
                'Assinatura',
                <div style={{ marginTop: '-10px' }}>
                  {props.recebimentoRevenda.statusAssinatura && (
                    <Typography.Text>
                      {(() => {
                        let label;
                        switch (props.recebimentoRevenda.statusAssinatura) {
                          case 'ASSINADO_DIGITALMENTE':
                            label = 'Assinado Digitalmente';
                            break;
                          case 'ASSINADO_FISICAMENTE':
                            label = 'Assinado Fisicamente';
                            break;
                          case 'PRODUTOR_RECUSOU_ASSINAR':
                            label = 'Responsável recusou-se a assinar';
                            break;
                          default:
                            label = '';
                        }
                        return label;
                      })()}
                    </Typography.Text>
                  )}
                  {props.recebimentoRevenda?.assinatura && (
                    <img
                      src={props.recebimentoRevenda?.assinatura}
                      alt='my signature'
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        width: '150px',
                        height: '20vh',
                      }}
                    />
                  )}
                </div>
              )}
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
