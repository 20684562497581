import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useWindowDimensions from '../../core/hooks/useWindowDimensions';
import { Col, Row } from 'antd';

export default function BreakpointsTable() {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const { width } = useWindowDimensions();

  return (
    <Row align='middle' gutter={6}>
      <Col>{`${width}w`}</Col>

      <Col>
        <table className='table-break-points' style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>xs</th>
              <th>sm</th>
              <th>md</th>
              <th>lg</th>
              <th>xl</th>
              <th>xxl</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderTop: '1px solid' }}>
              <td>{xs ? 'x' : '-'}</td>
              <td>{sm ? 'x' : '-'}</td>
              <td>{md ? 'x' : '-'}</td>
              <td>{lg ? 'x' : '-'}</td>
              <td>{xl ? 'x' : '-'}</td>
              <td>{xxl ? 'x' : '-'}</td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
