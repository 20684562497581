export enum TipoExploracaoSuinosSistemaIndustrial {
  CRIA = 'Cria',
  ENGORDA = 'Engorda',
  RECRIA = 'Recria',
  NAO_EXISTE = 'Não existe',
}
export namespace TipoExploracaoSuinosSistemaIndustrial {
  export function keys(): string[] {
    return Object.keys(TipoExploracaoSuinosSistemaIndustrial).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoExploracaoSuinosSistemaIndustrial[
      key as keyof typeof TipoExploracaoSuinosSistemaIndustrial
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(
      TipoExploracaoSuinosSistemaIndustrial
    ).indexOf(value as unknown as TipoExploracaoSuinosSistemaIndustrial);

    const key = Object.keys(TipoExploracaoSuinosSistemaIndustrial)[indexOfS];

    return key;
  }
}
