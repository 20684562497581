import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FVER } from '../../sdk/@types';

interface FVERDraftState {
  fverDraft: FVER.Input | undefined;
}

const initialState: FVERDraftState = {
  fverDraft: undefined,
};

const FVERDraftSlice = createSlice({
  initialState,
  name: 'fver',
  reducers: {
    storeFVERDraft(state, action: PayloadAction<FVER.Input | undefined>) {
      state.fverDraft = action.payload;
    },
  },
});

export const { storeFVERDraft } = FVERDraftSlice.actions;

const FVERDraftReducer = FVERDraftSlice.reducer;
export default FVERDraftReducer;
