import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormEduca } from '../../sdk/@types';
import FormEducaService from '../../sdk/services/SIZ-API/FormEduca.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface FormCustomState {
  data: FormEduca.Response | null;
  dataParticipant: FormEduca.ParticipanteRequest | null;
  formEducas: FormEduca.Paginated | null;
  fetching: boolean;
  attachment: string | null;
  loading: LoadingState;
  errorMessage?: string | null;
  query: FormEduca.Query;
}

const initialState: FormCustomState = {
  data: null,
  dataParticipant: null,
  formEducas: null,
  attachment: null,
  fetching: false,
  loading: 'idle',
  errorMessage: null,
  query: { page: 0, size: 10 },
};

export const filterFormEducas = createAsyncThunk(
  'formEduca/filterFormEducas',
  async (query: FormEduca.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const formEducas = await FormEducaService.getAll(query);
      return formEducas;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os Termo Fiscalizacaos')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);

export const insertFormEduca = createAsyncThunk(
  'formEduca/insert',
  async (
    {
      formEducaRequest,
      files,
    }: { formEducaRequest: FormEduca.Request; files: File[] },
    { rejectWithValue }
  ) => {
    try {
      return await FormEducaService.add(formEducaRequest, files);
    } catch (error) {
      console.error('Error in insertFormEduca:', error);
      return rejectWithValue('Falha ao inserir o formulário');
    }
  }
);

export const updateFormEduca = createAsyncThunk(
  'formEduca/updateFormEduca',
  async (
    {
      id,
      formEduca
    }: { id: number; formEduca: FormEduca.Request },
    { rejectWithValue }
  ) => {
    return await FormEducaService.update(id, formEduca).catch(
      rejectWithValue
    );
  }
);

export const fetchFormEducaById = createAsyncThunk<FormEduca.Response, number>(
  'formEduca/fetchformEducaById',
  async (id: number, { rejectWithValue }) => {
    return await FormEducaService.getById(id).catch(rejectWithValue);
  }
);

export const removeFormEduca = createAsyncThunk(
  'formEduca/remove',
  async (id: number, { rejectWithValue }) => {
    return await FormEducaService.remove(id).catch(rejectWithValue);
  }
);

export const insertParticipant = createAsyncThunk(
  'formEduca/insertParticipant',
  async (
    {
      formEducaId,
      participante,
    }: {
      formEducaId: number;
      participante: FormEduca.ParticipanteRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await FormEducaService.addParticipant(
        formEducaId,
        participante
      );
      console.log('Add Participant Result:', response);
      return response;
    } catch (error) {
      console.error('Error in addParticipant:', error);
      return rejectWithValue('Falha ao adicionar participante');
    }
  }
);
export const fetchAttachmentByName = createAsyncThunk(
  'formEduca/fetchAttachmentByName',
  async (nomeArquivo: string, { rejectWithValue }) => {
    try {
      return await FormEducaService.getAttachmentByName(nomeArquivo);
    } catch (error) {
      return rejectWithValue('Erro ao buscar o arquivo');
    }
  }
);

const formEducaSlice = createSlice({
  name: 'formEduca',
  initialState,
  reducers: {
    clearFormEducaState: (state) => {
      return initialState;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FormEduca.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertFormEduca.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(insertFormEduca.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(insertFormEduca.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(filterFormEducas.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterFormEducas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.formEducas = action.payload;
        state.fetching = false;
      })
      .addCase(filterFormEducas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(updateFormEduca.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(updateFormEduca.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(updateFormEduca.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(fetchFormEducaById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchFormEducaById.fulfilled,
        (state, action: PayloadAction<FormEduca.Response | null>) => {
          state.loading = 'fulfilled';
          state.data = action.payload;
        }
      )
      .addCase(fetchFormEducaById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(insertParticipant.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(insertParticipant.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.dataParticipant = action.payload;
        state.fetching = false;
      })
      .addCase(insertParticipant.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      });
    builder.addCase(fetchAttachmentByName.pending, (state) => {
      state.loading = 'pending';
      state.fetching = true;
    });
    builder.addCase(fetchAttachmentByName.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.attachment = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchAttachmentByName.rejected, (state, action) => {
      state.loading = 'rejected';
      state.errorMessage = action.error.message;
      state.fetching = false;
    });
  },
});

export const { clearFormEducaState, setFetching, setQuery } =
  formEducaSlice.actions;

const FormEducaReducer = formEducaSlice.reducer;
export default FormEducaReducer;
