import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as LoadingPageActions from '../store/LoadingPage.slice';

export default function useLoadingPage() {
  const dispatch = useDispatch();

  const firstOpening = useSelector(
    (state: RootState) => state.loadingPage.firstOpening
  );
  const loading = useSelector((state: RootState) => state.loadingPage.loading);
  const mode = useSelector((state: RootState) => state.loadingPage.mode);

  const setFirstOpening = useCallback(
    (fistOpening: boolean) => {
      dispatch(LoadingPageActions.setFirstOpening(fistOpening));
    },
    [dispatch]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch(LoadingPageActions.setLoading(loading));
    },
    [dispatch]
  );

  const setMode = useCallback(
    (mode: 'CREATE' | 'EDIT' | 'VIEW') => {
      dispatch(LoadingPageActions.setMode(mode));
    },
    [dispatch]
  );

  return {
    firstOpening,
    loading,
    mode,
    setFirstOpening,
    setLoading,
    setMode,
  };
}
