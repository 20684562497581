export const SYNC_VISITA_NEEDED = 'SYNC_VISITA_NEEDED';
export const SYNC_VISITA_DONE = 'SYNC_VISITA_DONE';
export const SYNC_FORM_VIN_NEEDED = 'SYNC_FORM_VIN_NEEDED';
export const SYNC_FORM_VIN_DONE = 'SYNC_FORM_VIN_DONE';
export const SYNC_VIGILANCIA_NEEDED = 'SYNC_VIGILANCIA_NEEDED';
export const SYNC_VIGILANCIA_DONE = 'SYNC_VIGILANCIA_DONE';
export const SYNC_MUNICIPIO_DONE = 'SYNC_MUNICIPIO_DONE';
export const SYNC_TERMOFISCALIZACAO_NEEDED = 'SYNC_TERMOFISCALIZACAO_NEEDED';
export const SYNC_TERMOFISCALIZACAO_DONE = 'SYNC_TERMOFISCALIZACAO_DONE';
export const SYNC_MUNICIPIO_DONE_WITH_ERROR = 'SYNC_MUNICIPIO_DONE_WITH_ERROR';
export const SYNC_LIA_NEEDED = 'SYNC_LIA_NEEDED';
export const SYNC_LIA_DONE = 'SYNC_LIA_DONE';
export const SYNC_FORM_CUSTOM_NEEDED = 'SYNC_FORM_CUSTOM_NEEDED';
export const SYNC_FORM_CUSTOM_DONE = 'SYNC_FORM_CUSTOM_DONE';
export const SYNC_FORM_RESPOSTA_NEEDED = 'SYNC_FORM_RESPOSTA_NEEDED';
export const SYNC_FORM_RESPOSTA_DONE = 'SYNC_FORM_RESPOSTA_DONE';
