import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type MedidasSanitariasProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  specify?: string;
};
export default function PrintMedidasSanitarias({
  data,
}: MedidasSanitariasProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.specify && (
                  <div>
                    <strong>Especificar:</strong> <br />
                    {row.specify}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {data &&
          panelDescription('Medidas Sanitárias', [
            {
              label: '6.1 - Adota vazio sanitário?',
              condition: data.adotaVazioSanitario_6_1,
              specify: data.adotaVazioSanitarioEspecificacao_6_1,
            },
            {
              label: '6.2 - Adota procedimento adequado para o destino de águas utilizadas, aves mortas, ovos descartados, esterco e embalagem, de modo a garantir a biosseguridade do estabelecimento?',
              condition: data.adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2,
              specify: data.adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2,
            },
            {
              label: '6.3 - Executa programa de limpeza e desinfecção nos aviários após a saída de cada lote de aves?',
              condition: data.executaProgramaDeLimpezaEDesinfeccao_6_3,
              specify: data.executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3,
            },
            {
              label: '6.4 - Possui protocolo e registro de limpeza e desinfecção dos reservatórios de captação e distribuição de águas servidas?',
              condition: data.possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4,
              specify: data.possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4,
            },
            {
              label: '6.5 - Mantem registros do programa de controle de pragas, a fim de manter os aviários e os locais para armazenagem de alimentos ou ovos livres de insetos e roedores, animais silvestres ou domésticos?',
              condition: data.mantemRegistroDeControleDePragas_6_5,
              specify: data.mantemRegistroDeControleDePragasEspecificacao_6_5,
            },
            {
              label: '6.6 - Está realizando com periodicidade anual a análise microbiológica da água? Está dentro dos padrões previstos em normativas vigentes?',
              condition: data.realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6,
              specify: data.realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6,
            },
            {
              label: '6.7 - A água utilizada para o consumo das aves e para o sistema de nebulização dos aviários é tratada com cloro obtendo uma concentração residual mínima de 3ppm?',
              condition: data.aguaUtilizadaETratadaComCloro_6_7,
              specify: data.aguaUtilizadaETratadaComCloroEspecificacao_6_7,
            },
            {
              label: '6.8 - Mantém os registros das atividades de trânsito de aves à disposição do serviço oficial?',
              condition: data.mantemRegistroDasAtividadesDeTransitoDeAves_6_8,
              specify: data.mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8,
            },
            {
              label: '6.9 - Mantém os registros das ações sanitárias executadas à disposição do serviço oficial?',
              condition: data.mantemRegistroDasAcoesSanitariasExecutadas_6_9,
              specify: data.mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9,
            },
            {
              label: '6.10 - Mantém os registros de vacinações e medicações utilizadas com datas das visitas e recomendações do responsável técnico e do médico veterinário oficial?',
              condition: data.mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10,
              specify: data.mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10,
            },
            {
              label: '6.11- A Anotação de Responsabilidade Técnica do médico veterinário RT está dentro do prazo de validade?',
              condition: data.anotacaoDeRTDentroDoPrazoDeValiade_6_11,
              specify: data.anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11,
            },
            {
              label: "Observações",
              condition: data.observacoesMedidasSanitarias_6_12,
            },
          ])}
      </div>
    </>
  );
}
