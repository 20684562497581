export enum EpocaDoAnoSuplementacao {
  TODO_O_ANO = 'Todo o ano',
  SECA = 'Ná época da seca',
  AGUAS = 'Nas águas',
  ESPORADICAMENTE = 'Esporadicamente',
}
export namespace EpocaDoAnoSuplementacao {
  export function keys(): string[] {
    return Object.keys(EpocaDoAnoSuplementacao).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return EpocaDoAnoSuplementacao[key as keyof typeof EpocaDoAnoSuplementacao];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(EpocaDoAnoSuplementacao).indexOf(
      value as unknown as EpocaDoAnoSuplementacao
    );

    const key = Object.keys(EpocaDoAnoSuplementacao)[indexOfS];

    return key;
  }
}
