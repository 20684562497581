import { TFRServidor } from '../../@types';
import Service from './Service';

class TFRServidorService extends Service {
  static getByCpf(cpf: string) {
    return this.Http.get<TFRServidor.Summary>(`/servidores/cpf/${cpf}`).then(
      this.getData
    );
  }

  static getByMatricula(matricula: string) {
    return this.Http.get<TFRServidor.Summary>(
      `/servidores/matricula/${matricula}`
    ).then(this.getData);
  }

  static getAllActive() {
    return this.Http.get<TFRServidor.Summary[]>('/servidores').then(
      this.getData
    );
  }
}

export default TFRServidorService;
