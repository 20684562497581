import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Typography,
} from 'antd';

import { useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';

type CustomCardProps = {
  title: string;
  name: string;
  specify: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChange: (
    name: string,
    value: string,
    specify: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  specify,
  formValue,
  formDisable,
  updatedValue,
  handleChange,
}) => {
  return (
    <Card title={title} key={formValue}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChange(name, e.target.value, specify, updatedValue)
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            {formValue === 'NAO' && (
              <Form.Item
                label={'Especificar'}
                name={specify}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea
                  showCount
                  rows={5}
                  maxLength={2000}
                  disabled={formDisable}
                />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};
export default function TerrenoLocalizacaoControleAcesso(
  props: InformacoesCadastroProps
) {
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const handleChange = (name: string, value: string, specify: string) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [specify]: undefined,
      });
    }
  };

  return (
    <>
      <Typography.Title level={5}>
        Controle do acesso de pessoas, materiais, equipamentos e veículos
      </Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='granjaPossuiSinaisDeAviso_4_1'
            title='4.1 - A granja possui sinais de aviso para evitar a entrada de pessoas alheias ao processo produtivo?'
            name='granjaPossuiSinaisDeAviso_4_1'
            specify='granjaPossuiSinaisDeAvisoEspecificacao_4_1'
            formValue={props.form.getFieldValue(
              'granjaPossuiSinaisDeAviso_4_1'
            )}
            updatedValue={updatedValues['granjaPossuiSinaisDeAviso_4_1']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='realizaControleDeTransitoDeVeiculos_4_2'
            title='4.2 - Realiza controle e registro do trânsito de veículos?'
            name='realizaControleDeTransitoDeVeiculos_4_2'
            specify='realizaControleDeTransitoDeVeiculosEspecificacao_4_2'
            formValue={props.form.getFieldValue(
              'realizaControleDeTransitoDeVeiculos_4_2'
            )}
            updatedValue={
              updatedValues['realizaControleDeTransitoDeVeiculos_4_2']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='executaProcedimentosParaDesinfeccaoDeVeiculos_4_3'
            title='4.3 - Executa procedimentos para a desinfecção de veículos, na entrada e na saida do estabelecimento avícola?'
            name='executaProcedimentosParaDesinfeccaoDeVeiculos_4_3'
            specify='executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3?'
            formValue={props.form.getFieldValue(
              'executaProcedimentosParaDesinfeccaoDeVeiculos_4_3'
            )}
            updatedValue={
              updatedValues['executaProcedimentosParaDesinfeccaoDeVeiculos_4_3']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='realizaControleDeAcessoDePessoas_4_4'
            title='4.4 - Realiza controle e registro de acesso de pessoas a granja?'
            name='realizaControleDeAcessoDePessoas_4_4'
            specify='realizaControleDeAcessoDePessoasEspecificacao_4_4'
            formValue={props.form.getFieldValue(
              'realizaControleDeAcessoDePessoas_4_4'
            )}
            updatedValue={updatedValues['realizaControleDeAcessoDePessoas_4_4']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='granjaPossuiBarreiraSanitaria_4_5'
            title='4.5 - A granja possui barreira sanitária provida de sanitários internos e vestiários com piso, parede e teto impremeáveis, pia com torneira para higienização da mãos e lavador de botas?'
            name='granjaPossuiBarreiraSanitaria_4_5'
            specify='granjaPossuiBarreiraSanitariaEspecificacao_4_5'
            formValue={props.form.getFieldValue(
              'granjaPossuiBarreiraSanitaria_4_5'
            )}
            updatedValue={updatedValues['granjaPossuiBarreiraSanitaria_4_5']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6'
            title='4.6 - O ingresso e egresso de equipamento e materiais na área interna do estabelecimento avícola ocorre quando cumpridas rigorosas medidas de limpesa e desinfecção ou outra medida de biosseguridade aprovada pelo INDEA-MT?'
            name='ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6'
            specify='ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6'
            formValue={props.form.getFieldValue(
              'ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6'
            )}
            updatedValue={
              updatedValues[
                'ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7'
            title='4.7 - Os funcionários e/ou pessoa autorizada (incluindo visitantes) adentram o estabelecimento avícola utilizando roupas e calçados limpos e exclusivos para a atividade?'
            name='funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7'
            specify='funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7'
            formValue={props.form.getFieldValue(
              'funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7'
            )}
            updatedValue={
              updatedValues[
                'funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8'
            title='4.8 - A granja possui fluxo operacional e medidas higiênicas-sanitárias a fim de evitar a contaminação do material limpo e desinfetado a ser utilizado na produção com os demais descartes da produção?'
            name='granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8'
            specify='granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8'
            formValue={props.form.getFieldValue(
              'granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8'
            )}
            updatedValue={
              updatedValues[
                'granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8'
              ]
            }
          />
        </Col>
      </Row>
      <Divider />
      <Col xs={24} lg={24}>
        <Form.Item
          label={'Observações'}
          name={'observacoesControleAcessoDePessoas'}
        >
          <TextArea
            showCount
            rows={5}
            maxLength={2000}
            disabled={props.formDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
