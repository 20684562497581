import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';
import SinaisClinicosPanel from '../../components/SinaisClinicosPanel';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { Especie } from '../../../core/enums/Especie';
import { VigilanciaAvesEspecie } from '../../../sdk/@types/VigilanciaAvesEspecie';
import { CustomModal } from '../../components/CustomModal';
import { SimNao } from '../../../core/enums/SimNao';

type VigilanciaAvesProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaAvesPanel(props: VigilanciaAvesProps) {
  const { xs } = useBreakpoint();
  const [statusHaSuspeitaClinica, setStatusHaSuspeitaClinica] = useState();

  const listaEspecieAves = Especie.aves();

  const [dataSourceVigilanciaAves, setDatasourceVigilanciaAves] = useState<
    VigilanciaAvesEspecie.Input[]
  >([]);

  const [showModalVigilanciaAves, setShowModalVigilanciaAves] =
    useState<boolean>(false);

  useEffect(() => {
    setDatasourceVigilanciaAves(
      props.form.getFieldValue(['vigilanciaAves', 'vigilanciaAvesEspecies'])
    );
  }, [props.form]);

  useEffect(() => {
    setStatusHaSuspeitaClinica(
      props.form.getFieldValue(['vigilanciaAves', 'haSuspeitaClinica'])
    );
  }, [props.form]);

  const clearModalVigilanciaAves = useCallback(() => {
    props.form.setFieldsValue({ modalVigilanciaAves: undefined });
  }, [props.form]);

  const vigilanciaAvesTableActions = (
    vigilanciaAvesToBeRemoved: VigilanciaAvesEspecie.Input
  ) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          disabled={props.formDisabled}
          title={'Deseja remover a espécie?'}
          onConfirm={() => {
            setDatasourceVigilanciaAves(
              dataSourceVigilanciaAves.filter(
                (vigilanciaAves) =>
                  vigilanciaAves.especie !== vigilanciaAvesToBeRemoved.especie
              )
            );
            notification.success({
              message: `Espécie ${Especie.valueOf(
                vigilanciaAvesToBeRemoved.especie
              )} removida`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            type={'ghost'}
            title={'Remover espécie'}
            disabled={props.formDisabled}
          />
        </Popconfirm>
      </Row>
    );
  };

  const handleAddVigilanciaAves = useCallback(() => {
    const investigacaoEpidemiologica_modal: VigilanciaAvesEspecie.Input =
      props.form.getFieldValue('modalVigilanciaAves');

    let newDataSource;
    if (!dataSourceVigilanciaAves) {
      newDataSource = [investigacaoEpidemiologica_modal];
    } else {
      newDataSource = dataSourceVigilanciaAves.concat(
        investigacaoEpidemiologica_modal
      );
    }

    setDatasourceVigilanciaAves(newDataSource);
    props.form.setFieldsValue({
      vigilanciaAves: { vigilanciaAvesEspecies: newDataSource },
    });

    setShowModalVigilanciaAves(false);
    clearModalVigilanciaAves();
    notification.success({
      message: 'Vigilância de aves incluída com sucesso',
    });
  }, [clearModalVigilanciaAves, dataSourceVigilanciaAves, props.form]);

  return (
    <>
      <Col xs={24} sm={24}>
        <Form.Item
          validateTrigger={'onSubmit'}
          name='tableInvestigacaoEpidemiologicas'
        >
          <Table<VigilanciaAvesEspecie.Input>
            size={'small'}
            dataSource={dataSourceVigilanciaAves}
            rowKey={(record) => record.especie}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Vigilância de Aves
                  </Typography.Title>
                  <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() => {
                      setShowModalVigilanciaAves(true);
                    }}
                    disabled={props.formDisabled}
                  />
                </Row>
              );
            }}
            columns={[
              {
                dataIndex: 'especie',
                title: 'Espécie',
                responsive: ['sm'],
                render(especie: string) {
                  return Especie.valueOf(especie);
                },
              },

              {
                dataIndex: 'quantidadeVistoriados',
                title: 'Vistoriados',
                responsive: ['sm'],
              },

              {
                dataIndex: 'quantidadeInspecionados',
                title: 'Inspecionados',
                responsive: ['sm'],
              },

              {
                dataIndex: 'id',
                title: 'Ações',
                responsive: ['sm'],
                width: 60,
                render(id, vigilanciaAves) {
                  return vigilanciaAvesTableActions(vigilanciaAves);
                },
              },
            ]}
          />
        </Form.Item>
      </Col>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Há suspeita clínica?'}
            name={['vigilanciaAves', 'haSuspeitaClinica']}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHaSuspeitaClinica}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaAves', 'haSuspeitaClinica'],
                  e.target.value
                );
                setStatusHaSuspeitaClinica(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            dependencies={['vigilanciaAves', 'haSuspeitaClinica']}
            label={'Suspeita clinica'}
            name={['vigilanciaAves', 'suspeitaClinica']}
            rules={[
              {
                required: statusHaSuspeitaClinica,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>

        <SinaisClinicosPanel
          listaSinaisClinicos={SinaisClinicos.galinhas()}
          pathToCoordenada={'vigilanciaAves'}
          form={props.form}
          required={statusHaSuspeitaClinica}
        />
      </Row>

      <CustomModal
        centered
        open={showModalVigilanciaAves}
        title={'Cadastro de Vigilância de Aves'}
        onCancel={() => {
          setShowModalVigilanciaAves(false);
          clearModalVigilanciaAves();
        }}
        width={750}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Form layout={'vertical'} form={props.form} autoComplete={'off'}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Espécie'}
                name={['modalVigilanciaAves', 'especie']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione uma espécie'}
                  placement={'bottomLeft'}
                >
                  {listaEspecieAves?.map((especie) => (
                    <Select.Option key={especie} value={especie}>
                      {Especie.valueOf(especie)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Tooltip
                title={
                  'Preencher com todos os animais observados, inclusive os examinados'
                }
              >
                <Form.Item
                  label={'Vistoriados'}
                  name={['modalVigilanciaAves', 'quantidadeVistoriados']}
                  rules={[
                    {
                      required: true,
                      validator(_, vistoriados) {
                        let isValid;
                        var examinados = props.form.getFieldValue([
                          'modalVigilanciaAves',
                          'quantidadeInspecionados',
                        ]);

                        console.log('examinados', examinados);
                        console.log('vistoriados', vistoriados);

                        if (!vistoriados) {
                          return Promise.reject('O campo é obrigatório');
                        } else if (isNaN(vistoriados)) {
                          return Promise.reject('Deve ser um número');
                        }

                        vistoriados = vistoriados
                          ? Number(vistoriados)
                          : undefined;
                        examinados = examinados
                          ? Number(examinados)
                          : undefined;

                        if (
                          examinados === undefined &&
                          vistoriados === undefined
                        ) {
                          console.log('1');

                          isValid = true;
                        } else if (examinados === undefined) {
                          console.log('2');
                          isValid = true;
                        } else if (vistoriados === undefined) {
                          console.log('3');
                          isValid = false;
                        } else {
                          console.log('4', vistoriados, examinados);
                          isValid = Number(vistoriados) >= Number(examinados);
                        }

                        console.log('VISTORIADOS, ', vistoriados, isValid);

                        if (isValid) return Promise.resolve();
                        else
                          return Promise.reject(
                            'Deve ser maior que o total de Examinados'
                          );
                      },
                    },
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input
                    inputMode='numeric'
                    maxLength={6}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Examinados'}
                name={['modalVigilanciaAves', 'quantidadeInspecionados']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input
                  inputMode='numeric'
                  autoComplete='off'
                  maxLength={6}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={24} justify={'end'}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                danger
                icon={<CloseOutlined />}
                onClick={() => {
                  setShowModalVigilanciaAves(false);
                  clearModalVigilanciaAves();
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                type={'primary'}
                icon={<CheckCircleOutlined />}
                onClick={() => {
                  props.form
                    .validateFields([
                      ['modalVigilanciaAves', 'especie'],
                      ['modalVigilanciaAves', 'quantidadeVistoriados'],
                      ['modalVigilanciaAves', 'quantidadeInspecionados'],
                    ])
                    .then(() => {
                      handleAddVigilanciaAves();
                    })
                    .catch((e) => {
                      return;
                    });
                }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  );
}
