import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import {
  selectHasNAOValueDistanciasInternas,
  selectHasNAOValueDistanciasExternas,
} from '../../../core/store/LIA.slice';
import { useEffect, useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};
export default function RiscoBioseguridade(props: InformacoesCadastroProps) {
  const [selectedValueExecucaoMedidas, setSelectedValueExecucaoMedidas] =
    useState('');
  const [
    isFormDisabledFromDistanciasInternas,
    setIsFormDisabledFromDistanciasInternas,
  ] = useState(props.formDisabled);
  const [
    isFormDisabledFromDistanciasExternas,
    setIsFormDisabledFromDistanciasExternas,
  ] = useState(props.formDisabled);
  const [selectedAvaliacaoRisco, setSelectedAvaliacaoRisco] = useState('');
  const handleChangeAvaliacaoRisco = (e: any) => {
    setSelectedAvaliacaoRisco(e.target.value);
    props.form.setFieldsValue({
      houveNecessidadeDeAvaliacaoDeRisco_7_1:
        e.target.value === 'SIM' ? 'SIM' : 'NAO',
    });
  };
  const handleChangeExecucaoMedidas = (e: any) => {
    setSelectedValueExecucaoMedidas(e.target.value);
    props.form.setFieldsValue({
      necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2:
        e.target.value === 'SIM' ? 'SIM' : 'NAO',
    });
  };

  const hasNAOValueFromDistanciasInternas = useSelector(
    selectHasNAOValueDistanciasInternas
  );
  const hasNAOValueFromDistanciasExternas = useSelector(
    selectHasNAOValueDistanciasExternas
  );

  useEffect(() => {
    if (hasNAOValueFromDistanciasInternas === true) {
      props.form.setFieldsValue({
        necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2: 'SIM',
      });
      setSelectedValueExecucaoMedidas('SIM');
      setIsFormDisabledFromDistanciasInternas(true);
    } else if (hasNAOValueFromDistanciasInternas === false) {
      props.form.setFieldsValue({
        necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2: 'NAO',
      });
      setSelectedValueExecucaoMedidas('NAO');
      setIsFormDisabledFromDistanciasInternas(false);
    } else {
      const valorAtual = props.form.getFieldValue(
        'necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2'
      );
      setSelectedValueExecucaoMedidas(valorAtual);
      setIsFormDisabledFromDistanciasInternas(true);
    }
  }, [hasNAOValueFromDistanciasInternas, props.form]);

  useEffect(() => {
    if (hasNAOValueFromDistanciasExternas === true) {
      props.form.setFieldsValue({
        houveNecessidadeDeAvaliacaoDeRisco_7_1: 'SIM',
      });
      setSelectedAvaliacaoRisco('SIM');
      setIsFormDisabledFromDistanciasExternas(true);
    } else if (hasNAOValueFromDistanciasExternas === false) {
      props.form.setFieldsValue({
        houveNecessidadeDeAvaliacaoDeRisco_7_1: 'NAO',
      });
      setSelectedAvaliacaoRisco('NAO');
      setIsFormDisabledFromDistanciasExternas(false);
    } else {
      const valorAtual = props.form.getFieldValue(
        'houveNecessidadeDeAvaliacaoDeRisco_7_1'
      );
      setSelectedAvaliacaoRisco(valorAtual);
      setIsFormDisabledFromDistanciasExternas(true);
    }
  }, [hasNAOValueFromDistanciasExternas, props.form]);

  useEffect(() => {
    setSelectedAvaliacaoRisco(
      props.form.getFieldValue('houveNecessidadeDeAvaliacaoDeRisco_7_1') || ''
    );
    setSelectedValueExecucaoMedidas(
      props.form.getFieldValue(
        'necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2'
      ) || ''
    );
  }, [props.form]);

  return (
    <fieldset disabled={props.formDisabled}>
      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Card>
            <Typography.Title level={5}>
              Risco/Bioseguridade complementar
            </Typography.Title>
            <Col lg={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
                label={'Houve necessidade de avaliação de risco ?'}
                name={'houveNecessidadeDeAvaliacaoDeRisco_7_1'}
              >
                <Radio.Group
                  disabled={isFormDisabledFromDistanciasExternas}
                  onChange={handleChangeAvaliacaoRisco}
                  value={selectedAvaliacaoRisco}
                >
                  <Radio value='SIM'>SIM</Radio>
                  <Radio value='NAO'>NÃO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Divider />
            {(!hasNAOValueFromDistanciasExternas ||
              selectedAvaliacaoRisco === 'SIM') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Risco proporcionado pela granja sob avaliação'}
                  name={'riscoProporcionadoPelaGranja_7_1_1'}
                  rules={[
                    {
                      required: selectedAvaliacaoRisco === 'SIM',
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    disabled={
                      props.formDisabled || selectedAvaliacaoRisco === 'NAO'
                    }
                    placeholder='Selecione'
                    options={[
                      { value: 'ALTO', label: 'Alto' },
                      {
                        value: 'BAIXO',
                        label: 'Baixo',
                      },
                      {
                        value: 'DESPREZIVEL',
                        label: 'Desprezível',
                      },
                      { value: 'MEDIO', label: 'Médio' },
                      { value: 'MUITO_ALTO', label: 'Muito Alto' },
                      { value: 'MUITO_BAIXO', label: 'Muito Baixo' },
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            <Divider />
            <Col lg={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
                label={
                  'É necessária execução de Medidas de Biosseguridade Compensatórias ?'
                }
                name={
                  'necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2'
                }
              >
                <Radio.Group
                  disabled={isFormDisabledFromDistanciasInternas}
                  onChange={handleChangeExecucaoMedidas}
                  value={selectedValueExecucaoMedidas}
                >
                  <Radio value='SIM'>SIM</Radio>
                  <Radio value='NAO'>NÃO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Divider />
            {(!hasNAOValueFromDistanciasInternas ||
              selectedValueExecucaoMedidas === 'SIM') && (
              <Col xs={24} lg={24}>
                <Form.Item
                  rules={[
                    {
                      required: selectedValueExecucaoMedidas === 'SIM',
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  label={'Descreva as Medida(s)'}
                  name={'medidasDeBiosseguridadeCompensatorias'}
                >
                  <TextArea
                    showCount
                    rows={5}
                    maxLength={2000}
                    disabled={
                      props.formDisabled ||
                      selectedValueExecucaoMedidas === 'NAO'
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Card>
        </Col>
      </Row>
    </fieldset>
  );
}
