import { Button, Space, Table, Tag, Typography } from 'antd';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useEffect, useState } from 'react';
import { Usuario } from '../../../sdk/@types/Usuario';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { TipoUsuario } from '../../../core/enums/TipoUsuario';
import UserPermissions from '../../Icons/UserPermissions';
import { formatCpf_Cnpj } from '../../../core/functions/formatCpf_Cnpj';
import FilterPanelUsuario from './FilterPanelUsuario';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

export default function ListUsuarioView() {
  const { online } = useNavigatorStatus();
  usePageTitle('Usuários');
  const navigate = useNavigate();
  const { setFirstOpening } = useLoadingPage();
  const [error, setError] = useState<Error>();

  const usuarios = useSelector((state: RootState) => state.usuario.usuarios);
  const fetching = useSelector((state: RootState) => state.usuario.fetching);

  if (!online)
    setError(
      new Error('Não é possível visualizar a lista de FVER no modo offline')
    );

  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, [setFirstOpening]);

  useEffect(() => {}, [usuarios]);

  return (
    <>
      <FilterPanelUsuario />
      <br />

      <Table<Usuario.Summary>
        loading={fetching}
        dataSource={usuarios}
        size={'small'}
        rowKey={'id'}
        columns={[
          {
            dataIndex: 'nome',
            title: 'Nome',
          },
          {
            dataIndex: 'cpf',
            title: 'CPF',
            width: 130,
            render(cpf: Usuario.Summary['cpf']) {
              return formatCpf_Cnpj(cpf);
            },
          },
          {
            dataIndex: 'tipoUsuario',
            title: 'Tipo Usuário',
            width: 130,
            render(tipoUsuario: Usuario.Summary['tipoUsuario']) {
              return <Tag>{TipoUsuario.valueOf(tipoUsuario)}</Tag>;
            },
          },

          {
            dataIndex: 'status',
            title: 'Status',
            width: 90,
            render(status: Usuario.Summary['status']) {
              return (
                <>
                  {status === 'ATIVO' && (
                    <Tag
                      style={{
                        backgroundColor: '#b8d8a8',
                        color: 'white',
                      }}
                    >
                      {status}
                    </Tag>
                  )}
                  {status === 'EXPIRADO' && (
                    <Tag
                      style={{
                        backgroundColor: '#e98d8d',
                        color: 'white',
                      }}
                    >
                      {status}
                    </Tag>
                  )}
                  {status === 'INATIVO' && (
                    <Tag
                      style={{
                        backgroundColor: '#d6d6d6',
                      }}
                    >
                      {status}
                    </Tag>
                  )}
                </>
              );
            },
          },
          {
            dataIndex: 'dataUltimoLogin',
            title: 'Último login',
            width: 110,
            render(dataUltimoLogin: Usuario.Summary['dataUltimoLogin']) {
              return moment(dataUltimoLogin).format('DD/MM/YYYY');
            },
          },
          {
            dataIndex: 'userName',
            title: 'UserName',
          },
          {
            dataIndex: 'userName',
            responsive: ['sm'],
            width: 80,
            align: 'right',
            render(userName, usuario) {
              return (
                <>
                  <Space>
                    <Button
                      icon={<UserPermissions />}
                      size={'small'}
                      type={'text'}
                      onClick={() => {
                        navigate(`/usuarios/${userName}/permissoes`);
                      }}
                    />
                  </Space>
                </>
              );
            },
          },
        ]}
        title={() => (
          <Typography.Title level={5} style={{ color: 'white' }}>
            Usuários
          </Typography.Title>
        )}
      />
    </>
  );
}
