import { useCallback, useState } from 'react';
import { Setor } from '../../sdk/@types/Setor';
import SetorIDBService from '../../sdk/services/indexeddb/SetorIDB.service';
import SetorService from '../../sdk/services/SIZ-API/Setor.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useSetor() {
  const { online } = useNavigatorStatus();
  const [listaSetor, setListaSetor] = useState<Setor.Summary[]>();
  const [fetchingSetores, setFetchingSetores] = useState<boolean>(false);

  const fetchSetoresByMunicipio = useCallback(
    async (codgIbge: string, fromApi?: boolean) => {
      try {
        setFetchingSetores(true);

        if (!fromApi) {
          return await SetorIDBService.getByMunicipio(codgIbge).then(
            async (lista) => {
              if (!lista || lista.length === 0) {
                if (online)
                  return await SetorService.getByMunicipio(codgIbge).then(
                    (lista) => {
                      setListaSetor(lista);
                    }
                  );
              } else {
                setListaSetor(
                  lista.map((item) => {
                    return item.payload;
                  })
                );
              }
            }
          );
        } else {
          return await SetorService.getByMunicipio(codgIbge).then((lista) =>
            setListaSetor(lista)
          );
        }
      } catch (err) {
        throw err;
      } finally {
        setFetchingSetores(false);
      }
    },
    [online]
  );

  return {
    listaSetor,
    fetchingSetores,
    fetchSetoresByMunicipio,
  };
}
