import {
  CheckCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Row,
  Col,
  Divider,
  Select,
  Button,
  Typography,
  Input,
  notification,
  Space,
  Card,
  Table,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useTipoChavePrincipalFVER from '../../../core/hooks/useTipoChavePrincipalFVER';
import {
  Servidor,
  LocalAcaoEducativa,
  Municipio,
  FormEduca,
} from '../../../sdk/@types';
import useMunicipio from '../../../core/hooks/useMunicipio';
import * as LocalAcaoEducativaActions from '../../../core/store/LocalAcaoEducativa.slice';
import * as FormEducativaActions from '../../../core/store/FormEDUCA.slice';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import ServidoresPanel from '../../components/ServidoresPanel';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import AuthorizationService from '../../../core/auth/Authorization.service';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { CustomModal } from '../../components/CustomModal';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { usePrompt } from '../../../core/hooks/usePrompt';
import { FormDataModalFinalizacao } from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import Dragger from 'antd/lib/upload/Dragger';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import { ModalidadeAcaoEducativa } from '../../../core/enums/ModalidadeAcaoEducativa';
import { TipoAcaoEducativa } from '../../../core/enums/TipoAcaoEducativa';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';

import { useNavigate, useParams } from 'react-router';

type Anexo = {
  id: number;
  name: string;
  nomeArquivo: string;
  nomeUpload: string;
};

export type FormEDUCAType = {
  dataAcao?: Moment;
  tiposChavesPrincipais: {
    id: number;
    nome: string;
  }[];
  educa: FormEduca.Summary;
  id: number;
  anexos: Anexo[];
  servidores: Servidor.Summary[];
  localAcaoEducativa: LocalAcaoEducativa.Summary;
  municipio: Municipio.Detailed;
} & Omit<FormEduca.Request, 'dataAcao'>;

type FormEDUCAProps = {
  educa?: FormEDUCAType;
};

type CustomUploadFile = UploadFile & { originFileObj: File };

export default function FormEDUCA(props: FormEDUCAProps) {
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { online } = useNavigatorStatus();
  const dispatch = useDispatch<AppDispatch>();

  const { mode, setLoading } = useLoadingPage();
  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();
  const [dataSourceTableChavesPrimarias, setDatasourceTableChavesPrimarias] =
    useState<FormEDUCAType['tiposChavesPrincipais']>([]);
  const [localAcaoEducativaSelecionado, setLocalAcaoEducativaSelecionado] =
    useState<LocalAcaoEducativa.Summary>();
  const [selectedChavePrimaria, setSelectedChavePrimaria] = useState<
    { id: number; nome: string } | undefined
  >();
  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();
  const [form] = Form.useForm<FormEDUCAType>();

  const [formDisabled] = useState<boolean>(false);
  const [fileList, setFileList] = useState<CustomUploadFile[]>([]);

  const {
    tipoChavePrincipalFVER,
    fetchActive: fetchActiveTipoChavePrincipalFVER,
  } = useTipoChavePrincipalFVER();

  const [showModalTimePicker, setShowModalTimePicker] =
    useState<boolean>(false);
  const [showModalProgramas, setShowModalProgramas] = useState<boolean>(false);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const [listaServidores, setListaServidores] = useState<Servidor.Summary[]>(
    []
  );

  const ListaLocalAcaoEducativa = useSelector(
    (state: RootState) => state.localAcaoEducativo.localAcaoEducativas
  );

  const query = useSelector(
    (state: RootState) => state.localAcaoEducativo.query
  );

  const uploadProps: UploadProps = {
    onRemove: (file: UploadFile) => {
      setFileList((prevFileList) =>
        prevFileList.filter((f) => f.uid !== file.uid)
      );
    },
    beforeUpload: (file: RcFile & File) => {
      const customFile: CustomUploadFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        originFileObj: file,
      };
      setFileList((prevFileList) => [...prevFileList, customFile]);
      return false;
    },
    fileList: fileList,
  };

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const prepareFilesForDragger = (anexos: any): CustomUploadFile[] => {
    return anexos.map((anexo: any, index: any) => ({
      uid: `${-index}`,
      name: anexo.nomeArquivo,
      status: 'done' as const,
      originFileObj: new File([], anexo.nomeArquivo),
    }));
  };

  useEffect(() => {
    if (online) fetchMunicipiosByUf('mt');
  }, [fetchMunicipiosByUf, online]);

  useEffect(() => {}, [dadosModalFinalizacao]);

  useEffect(() => {
    if (props.educa?.anexos && props.educa.anexos.length > 0) {
      setLoading(true);

      const promises = props.educa.anexos.map((anexo) =>
        dispatch(
          FormEducativaActions.fetchAttachmentByName(anexo.nomeUpload)
        ).unwrap()
      );

      Promise.all(promises)
        .then((urls) => {
          const anexosPreparados = urls.map((url, index) => ({
            uid: `${-index}`,
            name: props.educa?.anexos[index].nomeArquivo,
            status: 'done' as const,
            url,
          }));
          setFileList(anexosPreparados as any);
          form.setFieldsValue({
            ...props.educa,
            anexos: anexosPreparados,
          });
        })
        .catch((error) => {
          console.error('Error fetching attachments', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, props.educa?.anexos]);

  useEffect(() => {
    dispatch(LocalAcaoEducativaActions.filterLocalAcaoEducativa(query));
    setFirstOpening(true);
  }, [dispatch, query]);

  useEffect(() => {
    if (props.educa) {
      const anexosPreparados = prepareFilesForDragger(props.educa.anexos || []);
      form.setFieldsValue({
        ...props.educa,
        anexos: anexosPreparados,
      });
    }
  }, [props.educa, form]);

  useEffect(() => {
    if (props.educa?.servidores) {
      setListaServidores(props.educa?.servidores);
      form.setFieldValue(
        'servidores',
        listaServidores || props.educa?.servidores
      );
    }
    if (props.educa?.tiposChavesPrincipais) {
      setDatasourceTableChavesPrimarias(props.educa?.tiposChavesPrincipais);
      form.setFieldValue(
        'tiposChavesPrincipais',
        props.educa?.tiposChavesPrincipais
      );
    }
    if (props.educa?.municipio) {
      setMunicipioSelecionado(props.educa.municipio);
      form.setFieldValue('municipio', props.educa.municipio.nome);
    }
    if (props.educa?.localAcaoEducativa) {
      setLocalAcaoEducativaSelecionado(props.educa.localAcaoEducativa);
      form.setFieldValue(
        'localAcaoEducativa',
        props.educa.localAcaoEducativa.nome
      );
    }
    if (params.local) setLoading(false);
  }, [props.educa, params, setLoading, listaServidores]);

  useEffect(() => {
    const insertLoggedUser = async () => {
      if (mode === 'CREATE') {
        if (AuthorizationService.getUsuarioSIZ()) {
          await ServidorIDBService.getByCpf(
            //@ts-ignore
            AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
          ).then((servidor) => {
            if (servidor) {
              const newLista = listaServidores.concat(servidor.payload);
              setListaServidores(newLista);
            }
          });
        }
      }
    };

    insertLoggedUser();
  }, []);

  useEffect(() => {
    fetchActiveTipoChavePrincipalFVER();
  }, [fetchActiveTipoChavePrincipalFVER]);

  const saveFormEduca = useCallback(
    async (formEduca: FormEduca.Request) => {
      const anexosField = form.getFieldValue('anexos');
      const anexosFiles = params.id
        ? undefined
        : anexosField?.fileList.map(
            (fileWrapper: UploadFile) => fileWrapper.originFileObj
          ) || [];
      try {
        const action = await dispatch(
          //@ts-ignore
          formEduca.id
            ? FormEducativaActions.updateFormEduca({
                id: Number(params.id),
                formEduca: formEduca,
              })
            : FormEducativaActions.insertFormEduca({
                formEducaRequest: formEduca,
                files: anexosFiles,
              })
        );

        if (action.payload) {
          //@ts-ignore
          const formEduca = action.payload;
          setDadosModalFinalizacao({
            //@ts-ignore
            id: formEduca.id,
          });
          setShowModalFinalizacao(true);
        }
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormEDUCAType) => {
      setLoading(true);
      const dataAcaoFormatted = formParam.dataAcao
        ? formParam.dataAcao.format('YYYY-MM-DD[T]HH:mm:ss')
        : '';

      const formEducaDTO: FormEduca.Request = {
        ...formParam,
        dataAcao: dataAcaoFormatted,
        tiposChavesPrincipais: formParam.tiposChavesPrincipais,
        servidores: listaServidores,
        localAcaoEducativa:
          localAcaoEducativaSelecionado as LocalAcaoEducativa.Summary,
        municipio: municipioSelecionado as Municipio.Detailed,
      };

      try {
        saveFormEduca(formEducaDTO);
      } catch (error) {
        notification.error({
          message: 'Erro ao enviar formulário',
          description: (error as Error).message,
        });
      } finally {
        setLoading(false);
      }
    },
    [form, listaServidores, online, params, saveFormEduca, setLoading]
  );

  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };

  const handleSelectLocalAcaoEducativaChange = (value: any) => {
    setLocalAcaoEducativaSelecionado(JSON.parse(value));
  };

  const handleSelectChavePrimariaChange = (value: string) => {
    const chave = JSON.parse(value);
    setSelectedChavePrimaria(chave);
  };

  const handleAddPrograma = () => {
    if (selectedChavePrimaria) {
      const chaveExists = dataSourceTableChavesPrimarias.some(
        (chave) => chave.id === selectedChavePrimaria.id
      );

      if (chaveExists) {
        notification.warn({
          message: 'Esta chave principal já foi incluída',
          description: 'Selecione outra chave principal',
        });
        return;
      }

      setDatasourceTableChavesPrimarias([
        ...dataSourceTableChavesPrimarias,
        selectedChavePrimaria,
      ]);
      form.setFieldsValue({
        tiposChavesPrincipais: [
          ...dataSourceTableChavesPrimarias,
          selectedChavePrimaria,
        ],
      });
      notification.success({
        message: 'Programa e atividades incluído com sucesso',
      });
      setSelectedChavePrimaria(undefined);
      setShowModalProgramas(false);
    }
  };

  const handleRemovePrograma = (id: number) => {
    const newChaves = dataSourceTableChavesPrimarias.filter(
      (chave) => chave.id !== id
    );
    setDatasourceTableChavesPrimarias(newChaves);
    form.setFieldsValue({ tiposChavesPrincipais: newChaves });
  };

  const cleanModalProgramasEAtividades = () => {
    setSelectedChavePrimaria(undefined);
  };

  const columns = [
    {
      dataIndex: 'nome',
      title: 'Chave Principal',
      responsive: ['sm'],
    },
    {
      dataIndex: 'id',
      title: 'Ações',
      responsive: ['sm'],
      width: 60,
      render: (id: number) => (
        <Button
          type='ghost'
          icon={<DeleteOutlined />}
          onClick={() => handleRemovePrograma(id)}
          danger
        />
      ),
    },
  ];

  const modalidadeOptions = ModalidadeAcaoEducativa.keys().map((key) => ({
    value: key,
    label: ModalidadeAcaoEducativa.valueOf(key),
  }));

  const tipoAcaoEducativaOptions = TipoAcaoEducativa.keys().map((key) => ({
    value: key,
    label: TipoAcaoEducativa.valueOf(key),
  }));

  const removeMunicipio = useCallback(() => {
    form.setFieldsValue({
      municipio: undefined,
    });
    setMunicipioSelecionado(undefined);
  }, [form]);

  return (
    <>
      <fieldset disabled={formDisabled}>
        <Form<FormEDUCAType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          autoComplete={'off'}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={{
            ...props.educa,
            municipio: props.educa?.municipio.nome,
            localAcaoEducativa: props.educa?.localAcaoEducativa.nome,
            tiposChavesPrincipais: props.educa?.tiposChavesPrincipais,
          }}
        >
          <Row gutter={24}>
            <Form.Item name={'id'} hidden>
              <Input disabled hidden />
            </Form.Item>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Nome da ação/atividade educativa'}
                name={'nome'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input type='text' />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Data da ação/atividade educativa'}
                name={'dataAcao'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
                initialValue={moment.tz(
                  moment().toLocaleString(),
                  'America/Cuiaba'
                )}
              >
                <DatePicker
                  onClick={() => {
                    scrollFieldToTop('dataAcao');
                  }}
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(currentDate) =>
                    currentDate && currentDate.isBefore(moment().startOf('day'))
                  }
                  disabled={formDisabled}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={'Município'} style={{ width: '100%' }}>
                <Input.Group
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Form.Item
                    name={'municipio'}
                    style={{
                      width: '100%',
                      marginBottom: '0',
                      marginTop: '0',
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder='Selecione um município'
                      optionFilterProp='children'
                      onChange={handleSelectMunicipioChange}
                      filterOption={(input, option) => {
                        if (option && option.children)
                          return (
                            option.children
                              .toString()
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .indexOf(input.toLowerCase()) >= 0
                          );

                        return false;
                      }}
                    >
                      {listaMunicipio?.map((municipio) => (
                        <Select.Option
                          key={municipio.codgIBGE}
                          value={JSON.stringify(municipio)}
                        >
                          {municipio.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    style={{
                      height: '34px',
                    }}
                    icon={<DeleteOutlined />}
                    danger
                    onClick={removeMunicipio}
                  />
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={24}>
              <Space direction={'vertical'} style={{ width: '100%' }}>
                <Col xs={0} lg={0}>
                  <Form.Item name={'id'} hidden>
                    <Input hidden />
                  </Form.Item>
                </Col>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Local de Ação Educativa'}
                      name={'localAcaoEducativa'}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <Select
                        disabled={formDisabled}
                        showSearch
                        onChange={handleSelectLocalAcaoEducativaChange}
                        optionFilterProp='children'
                        onClick={() => {
                          scrollFieldToTop('localAcaoEducativa');
                        }}
                        placeholder='Selecione o local de ação educativa'
                        placement={'bottomLeft'}
                        filterOption={(input, option) => {
                          if (option && option.children)
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(input.toLowerCase()) >= 0
                            );

                          return false;
                        }}
                      >
                        {ListaLocalAcaoEducativa?.map((local) => (
                          <Select.Option
                            key={local.id}
                            value={JSON.stringify(local)}
                          >
                            <Typography.Text key={local.id}>
                              {local.nome}
                            </Typography.Text>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Modalidade de Ação Educativa'}
                      name={'modalidadeAcaoEducativa'}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <Select
                        placeholder='Selecione a modalidade de ação educativa'
                        disabled={formDisabled}
                      >
                        {modalidadeOptions.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Tipo de Ação Educativa'}
                      name={'tipoAcaoEducativa'}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <Select
                        placeholder='Selecione o tipo de ação educativa'
                        disabled={formDisabled}
                      >
                        {tipoAcaoEducativaOptions.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={'Público atingido'}
                      name={'quantidadePessoasAtingidas'}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                      ]}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Col xs={24} sm={24}>
                  <Divider orientation='left'>Adicionar Servidores</Divider>
                  <ServidoresPanel
                    form={form}
                    formDisabled={formDisabled}
                    listaServidores={listaServidores}
                    setListaServidores={setListaServidores}
                  />
                </Col>
                <CustomModal
                  centered
                  open={showModalProgramas}
                  title={'Programas e atividades'}
                  onCancel={() => {
                    setShowModalProgramas(false);
                    cleanModalProgramasEAtividades();
                  }}
                  width={750}
                  footer={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        style={{ width: '50%' }}
                        danger
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setShowModalProgramas(false);
                          cleanModalProgramasEAtividades();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        style={{ width: '50%' }}
                        type={'primary'}
                        icon={<CheckCircleOutlined />}
                        onClick={() => {
                          form.validateFields([
                            ['modalTipoChavePrimaria', 'id'],
                          ]);
                          handleAddPrograma();
                        }}
                      >
                        Salvar
                      </Button>
                    </div>
                  }
                  maskClosable={false}
                  destroyOnClose
                >
                  <Form layout={'vertical'} form={form} autoComplete={'off'}>
                    <Row gutter={24}>
                      <Col xs={24} lg={24}>
                        <Form.Item
                          label={'Chave Principal'}
                          name={['modalTipoChavePrimaria', 'id']}
                          rules={[
                            {
                              required: true,
                              message: 'O campo é obrigatório',
                            },
                          ]}
                          valuePropName={'modaltipochaveprimaria'}
                        >
                          <Select
                            placeholder={'Selecione uma chave'}
                            onChange={handleSelectChavePrimariaChange}
                            placement={'bottomLeft'}
                          >
                            {tipoChavePrincipalFVER?.map((tipo) => (
                              <Select.Option
                                key={tipo.id}
                                value={JSON.stringify(tipo)}
                              >
                                {tipo.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </CustomModal>
                <Col xs={24} sm={24}>
                  <Divider orientation='left'>Programas sanitários</Divider>
                  <Form.Item
                    validateTrigger={'onSubmit'}
                    name='tiposChavesPrincipais'
                    rules={[
                      {
                        validator(_, value) {
                          if (dataSourceTableChavesPrimarias.length < 1)
                            return Promise.reject(
                              new Error(
                                'Deve ser informado pelo menos um programa'
                              )
                            );
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Table
                      size={'small'}
                      dataSource={dataSourceTableChavesPrimarias}
                      rowKey={(record) => record.id.toString()}
                      title={() => (
                        <Row justify={'space-between'}>
                          <Typography.Title
                            level={5}
                            style={{ color: 'white' }}
                          >
                            Atividades
                          </Typography.Title>
                          <Button
                            icon={<PlusOutlined />}
                            type={'primary'}
                            onClick={() => setShowModalProgramas(true)}
                          />
                        </Row>
                      )}
                      columns={columns as any}
                    />
                  </Form.Item>
                </Col>

                <Divider orientation='left'>
                  Evidências da realização da Atividade
                </Divider>
                <Form.Item name='anexos'>
                  <Dragger
                    {...uploadProps}
                    disabled={mode === 'EDIT' || mode === 'VIEW' ? true : false}
                    name='file'
                    fileList={fileList}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Clique ou arraste o arquivo para esta área para fazer o
                      upload
                    </p>
                    <p className='ant-upload-hint'>
                      Obrigatório inserir o arquivo digitalizado ou print de: da
                      Lista de presença, do ofício convite de entrevista a
                      rádio/TV/jornal ou arquivo da própria entrevista.
                    </p>
                  </Dragger>
                </Form.Item>
                {/**
                 <Col xs={24} lg={24}>
                  <Form.Item
                    label={'Descrição'}
                    name={'descricao'}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                 */}
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row justify={'end'} gutter={24}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(`${online ? '/visitas' : '/acoes-de-campo'}`)
                }
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/visitas/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>
      <CustomModal
        centered
        open={showModalFinalizacao}
        width={750}
        footer={null}
        destroyOnClose
        closable={false}
        title={`FormGraf salvo com sucesso`}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          bordered={false}
        >
          <Row
            gutter={24}
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Col xs={24} sm={24}>
              <Typography.Paragraph>
                {online
                  ? 'O FormEduca foi salvo no servidor e não necessita sincronização'
                  : 'FormEduca salvo localmente'}
              </Typography.Paragraph>
            </Col>
            {dadosModalFinalizacao?.networkError && (
              <Col xs={24} sm={24}>
                <Typography.Paragraph>
                  Houve um problema enquanto tentamos nos comunicar com o
                  servidor.
                </Typography.Paragraph>
              </Col>
            )}
          </Row>

          <Row
            align='middle'
            style={{
              justifyContent: 'center',
            }}
            gutter={24}
          >
            <Col>
              <Button
                onClick={() => navigate(`${online ? '/educa' : '/'}`)}
                type={'primary'}
              >
                Voltar
              </Button>
            </Col>
            <Col>
              {online && (
                <Button
                  onClick={() =>
                    navigate(`/educa/visualizar/${dadosModalFinalizacao?.id}`)
                  }
                  type={'primary'}
                >
                  Visualizar
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        title={null}
        footer={null}
        open={showModalTimePicker}
        bodyStyle={{ display: 'inline-block', float: 'left' }}
      >
        <div style={{ display: 'inline-block', float: 'left' }}>
          <StaticTimePicker
            defaultValue={moment.tz(
              moment().toLocaleString(),
              'America/Cuiaba'
            )}
            timezone='America/Cuiaba'
            localeText={{
              cancelButtonLabel: 'Cancelar',
              toolbarTitle: '',
            }}
            onAccept={(data: Moment | null) => {
              let hour =
                String(data?.get('hour')).padStart(2, '0') +
                ':' +
                String(data?.get('minute')).padStart(2, '0');
              form.setFieldValue('horaDaVisita', hour);

              setShowModalTimePicker(false);
            }}
            slots={{
              actionBar: undefined,
            }}
            onClose={() => {
              setShowModalTimePicker(false);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
}
