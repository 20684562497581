import {
  CloseCircleOutlined,
  DeleteOutlined,
  DownSquareOutlined,
  PlusOutlined,
  UpSquareOutlined,
  EditTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Typography,
  Radio,
  Row,
  Select,
  Space,
  Checkbox,
  Switch,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import TextArea from 'antd/lib/input/TextArea';

import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FormCustom } from '../../../sdk/@types';
import confirm from 'antd/lib/modal/confirm';
import * as FormCustomActions from '../../../core/store/FormCustom.slice';

import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

const { Option } = Select;
const { Title, Paragraph } = Typography;

type PerguntaType = {
  descricao: string;
  tipoPergunta:
    | 'MULTIPLA_ESCOLHA'
    | 'NUMERICO'
    | 'PARAGRAFO'
    | 'RESPOSTA_CURTA';
  alternativas?: Alternative[];
  status: 'ATIVO' | 'INATIVO';
};
type Alternative = {
  descricao: string;
};
type FormFormCustomType = {
  perguntas: PerguntaType[];
  ordem: number;
  nome: string;
  descricao: string;
  municipio: boolean;
};

type FormFormCustomProps = {
  formCustom?: FormFormCustomType;
};

export default function FormFormCustom(props: FormFormCustomProps) {
  const dispatch = useDispatch();
  const params = useParams<{ id: string; local?: string }>();
  const [form] = Form.useForm<FormFormCustomType>();
  const { xs } = useBreakpoint();
  const [error, setError] = useState<Error>();
  const { mode, firstOpening, setFirstOpening, setLoading } = useLoadingPage();
  const navigate = useNavigate();
  const [okButtonEnabled, setOkButtonEnabled] = useState(false);
  const { online } = useNavigatorStatus();
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const [formDisabled] = useState<boolean>();
  const [isValid, setIsValid] = useState(false);

  if (!window.navigator.onLine)
    throw new Error(
      'Não é possível cadastrar um formulário customizável no modo offline'
    );
  if (error) throw error;
  const saveFormCustom = useCallback(
    async (formulario: FormCustom.Request) => {
      try {
        const action = await dispatch(
          //@ts-ignore
          formulario.id || params.id
            ? FormCustomActions.updateFormCustom({
                id: Number(params.id),
                formCustom: formulario,
              })
            : FormCustomActions.insertFormCustom(formulario)
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const formulario = action.payload;

          setDadosModalFinalizacao({
            //@ts-ignore
            id: Number(formulario.id),
          });

          setShowModalFinalizacao(true);
        }
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  const [perguntaFocada, setPerguntaFocada] = useState<number | null>(-1);

  const handleInputChange = () => {
    const nome = form.getFieldValue('nome');
    const descricao = form.getFieldValue('descricao');
    setIsValid(!!nome && !!descricao);
  };

  const handleFormularioClick = () => {
    setPerguntaFocada(-1);
  };

  const isLastQuestionComplete = () => {
    const perguntas = form.getFieldValue('perguntas') || [];

    if (perguntas.length === 0) {
      return false; // Não permite salvar sem perguntas
    }

    const ultimaPergunta = perguntas[perguntas.length - 1];

    if (!ultimaPergunta?.descricao || !ultimaPergunta?.tipoPergunta) {
      return false;
    }

    if (ultimaPergunta?.tipoPergunta === 'MULTIPLA_ESCOLHA') {
      return (
        ultimaPergunta?.alternativas &&
        ultimaPergunta?.alternativas.every(
          (alt: Alternative) => alt.descricao && alt.descricao.trim() !== ''
        )
      );
    }

    return true;
  };

  const handlePerguntaClick = (index: number) => {
    setPerguntaFocada(index);
  };

  const moveQuestion = (index: number, direction: string) => {
    form.validateFields().then(() => {
      const perguntas = [...form.getFieldValue('perguntas')];
      if (direction === 'up' && index > 0) {
        [perguntas[index], perguntas[index - 1]] = [
          perguntas[index - 1],
          perguntas[index],
        ];
      } else if (direction === 'down' && index < perguntas.length - 1) {
        [perguntas[index], perguntas[index + 1]] = [
          perguntas[index + 1],
          perguntas[index],
        ];
      }
      // Atualiza a ordem baseado na nova posição
      const perguntasAtualizadas = perguntas.map((p, idx) => ({
        ...p,
        ordem: idx + 1,
      }));
      form.setFieldsValue({ perguntas: perguntasAtualizadas });
    });
  };

  const validateLastQuestion = async () => {
    const perguntas = form.getFieldValue('perguntas') || [];
    const lastQuestionIndex = perguntas.length - 1;
    if (lastQuestionIndex < 0) return true;

    try {
      // Validar a descrição e o tipo da última pergunta.
      await form.validateFields([
        ['perguntas', lastQuestionIndex, 'descricao'],
        ['perguntas', lastQuestionIndex, 'tipoPergunta'],
      ]);

      // Se a última pergunta for de múltipla escolha, validar as alternativas.
      if (perguntas[lastQuestionIndex].tipoPergunta === 'MULTIPLA_ESCOLHA') {
        const alternativas = perguntas[lastQuestionIndex].alternativas || [];
        if (alternativas.length === 0) {
          throw new Error(
            'Adicione pelo menos uma alternativa para a pergunta de múltipla escolha.'
          );
        }

        for (let i = 0; i < alternativas.length; i++) {
          await form.validateFields([
            ['perguntas', lastQuestionIndex, 'alternativas', i, 'descricao'],
          ]);
        }
      }

      return true; // A última pergunta e suas alternativas são válidas.
    } catch (error) {
      // Tratar erros de validação.
      notification.error({
        message: 'Alguns campos obrigatórios não foram preenchidos',
        description:
          'Por favor revise a última pergunta antes de adicionar uma nova',
      });
      return false;
    }
  };

  const handleFormSubmit = useCallback(
    async (formParam: FormFormCustomType) => {
      const formCustomDTO: FormCustom.Request = {
        ...formParam,
        nome: form.getFieldValue('nome'),
        descricao: form.getFieldValue('descricao'),
        municipio: form.getFieldValue('municipio'),
        perguntas: form.getFieldValue('perguntas'),
        respostas: form.getFieldValue('respostas'),
      };

      try {
        saveFormCustom(formCustomDTO);
      } catch (error) {
        notification.error({
          message: 'Erro ao enviar formulário',
          description: (error as Error).message,
        });
      } finally {
        setLoading(false);
      }
    },
    [form, saveFormCustom]
  );

  const checkIfButtonShouldBeEnabled = () => {
    const perguntas = form.getFieldValue('perguntas') || [];
    const allValid = perguntas.every(
      (pergunta: PerguntaType, index: number) => {
        // Habilitar o botão OK para perguntas que vêm do payload no modo de edição
        if (
          mode === 'EDIT' &&
          props.formCustom?.perguntas &&
          index < props.formCustom.perguntas.length
        ) {
          return true;
        }

        if (!pergunta?.descricao || !pergunta?.tipoPergunta) return false;
        if (pergunta?.tipoPergunta === 'MULTIPLA_ESCOLHA') {
          return (
            pergunta?.alternativas &&
            pergunta?.alternativas.every(
              (alt: Alternative) => alt.descricao && alt.descricao.trim() !== ''
            )
          );
        }
        return true;
      }
    );

    setOkButtonEnabled(allValid);
  };

  const handleAddAlternative = (
    add: (defaultValue?: Partial<Alternative>) => void,
    index: number
  ) => {
    const alternativas =
      form.getFieldValue(['perguntas', index, 'alternativas']) || [];
    const lastAlternative = alternativas[alternativas.length - 1];

    if (
      lastAlternative &&
      (!lastAlternative.descricao || lastAlternative.descricao.trim() === '')
    ) {
      notification.error({
        message: 'Erro ao adicionar nova alternativa',
        description:
          'Preencha a descrição da última alternativa antes de adicionar uma nova.',
      });
    } else {
      add({ descricao: '' });
    }
  };

  return (
    <>
      <fieldset disabled={formDisabled}>
        <Form<FormFormCustomType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          onValuesChange={checkIfButtonShouldBeEnabled}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={props.formCustom}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <div
            style={{
              background: '#FFFFFF',
              padding: 40,
              margin: '40px 0',
              borderRadius: 4,
            }}
          >
            {perguntaFocada === -1 ? (
              <Row>
                <Col span={24}>
                  <Form.Item
                    label='Nome do Formulário'
                    name='nome'
                    rules={[
                      {
                        required: true,
                        message: 'Insira um nome para o formulário',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Nome'
                      size='large'
                      onChange={handleInputChange}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='Descrição'
                    name='descricao'
                    rules={[
                      {
                        required: true,
                        message: 'Insira uma descrição para o formulário',
                      },
                    ]}
                  >
                    <TextArea
                      rows={2}
                      placeholder='Descrição'
                      size='large'
                      onChange={handleInputChange}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='Vincular Município'
                    name='municipio'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={handleInputChange}
                      disabled={formDisabled}
                    >
                      Município
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <>
                <div>
                  <Title level={4}>{form.getFieldValue(['nome'])}</Title>
                  <Paragraph>{form.getFieldValue(['descricao'])}</Paragraph>
                </div>
                <Divider />
                <Col span={24}>
                  <Form.Item
                    label='Vincular Município'
                    name='municipio'
                    valuePropName='checked'
                  >
                    <Checkbox onChange={handleInputChange} disabled>
                      Município
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Paragraph>{form.getFieldValue(['municipio'])}</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Button
                    size={'small'}
                    type={'ghost'}
                    onClick={handleFormularioClick}
                    icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                    disabled={formDisabled}
                  />
                </div>
              </>
            )}
          </div>
          <Form.List name='perguntas'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Col span={24} key={field.key}>
                    <Row
                      gutter={24}
                      style={{
                        padding: 40,
                        borderRadius: 4,
                        margin: '20px 0',
                        background: '#FFFFFF',
                      }}
                      key={field.key}
                    >
                      {perguntaFocada === index ? (
                        <>
                          <Col
                            span={24}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Space
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <UpSquareOutlined
                                style={{ fontSize: '24px', color: '#08c' }}
                                onClick={() => moveQuestion(index, 'up')}
                              />
                              <DownSquareOutlined
                                style={{ fontSize: '24px', color: '#08c' }}
                                onClick={() => moveQuestion(index, 'down')}
                              />
                            </Space>
                          </Col>

                          <Col span={!xs ? 12 : 24}>
                            <Form.Item
                              label='Descrição da Pergunta'
                              {...field}
                              name={[field.name, 'descricao']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Insira a descrição da pergunta',
                                },
                              ]}
                            >
                              <Input
                                placeholder='Digite uma pergunta'
                                size={'large'}
                                disabled={
                                  form.getFieldValue([
                                    'perguntas',
                                    index,
                                    'status',
                                  ]) === 'INATIVO' || formDisabled
                                }
                                onChange={checkIfButtonShouldBeEnabled}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={!xs ? 12 : 24}>
                            <Form.Item
                              label='Tipo da Resposta'
                              {...field}
                              name={[field.name, 'tipoPergunta']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Selecione um tipo de resposta',
                                },
                              ]}
                            >
                              <Select
                                placeholder='Tipo da Resposta'
                                onChange={(value) => {
                                  checkIfButtonShouldBeEnabled();
                                  form.setFieldsValue({
                                    perguntas: form
                                      .getFieldValue('perguntas')
                                      .map((p: PerguntaType, i: number) =>
                                        i === index
                                          ? { ...p, tipoPergunta: value }
                                          : p
                                      ),
                                  });
                                }}
                                disabled={
                                  mode === 'EDIT' &&
                                  field.name <
                                    (props.formCustom?.perguntas?.length || 0)
                                }
                              >
                                <Option value='MULTIPLA_ESCOLHA'>
                                  Múltipla Escolha
                                </Option>
                                <Option value='NUMERICO'>Numérico</Option>
                                <Option value='PARAGRAFO'>Parágrafo</Option>
                                <Option value='RESPOSTA_CURTA'>
                                  Resposta Curta
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Divider />
                          <Col span={24}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'ordem']}
                              initialValue={index + 1}
                              hidden
                            >
                              <Input size={'large'} />
                            </Form.Item>
                          </Col>
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'RESPOSTA_CURTA' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'resposta']}
                              >
                                <Input
                                  size={'large'}
                                  placeholder='Texto de resposta curta'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'NUMERICO' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'resposta']}
                              >
                                <Input
                                  size={'large'}
                                  type='number'
                                  placeholder='Texto de resposta numérica'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'PARAGRAFO' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'resposta']}
                              >
                                <TextArea
                                  size={'large'}
                                  rows={4}
                                  placeholder='Texto de resposta longa'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'MULTIPLA_ESCOLHA' && (
                            <Form.List name={[field.name, 'alternativas']}>
                              {(
                                altFields,
                                { add: addAlt, remove: removeAlt }
                              ) => (
                                <Col span={24}>
                                  {altFields.map((altField) => (
                                    <Row gutter={24} key={altField.key}>
                                      <Col span={!xs ? 12 : 20}>
                                        <Form.Item
                                          {...altField}
                                          name={[altField.name, 'descricao']}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                'Insira a descrição da alternativa',
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder='Descrição da Alternativa'
                                            size='large'
                                            onChange={
                                              checkIfButtonShouldBeEnabled
                                            }
                                            disabled={
                                              mode === 'EDIT' &&
                                              index <
                                                (props.formCustom?.perguntas
                                                  ?.length || 0)
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={!xs ? 12 : 2}>
                                        <Row justify='end'>
                                          <Button
                                            icon={
                                              <CloseCircleOutlined
                                                onClick={() =>
                                                  removeAlt(altField.name)
                                                }
                                              />
                                            }
                                            style={{
                                              minWidth: 30,
                                              maxWidth: 30,
                                            }}
                                            size={'small'}
                                            type={'ghost'}
                                            danger
                                            disabled={
                                              mode === 'EDIT' &&
                                              index <
                                                (props.formCustom?.perguntas
                                                  ?.length || 0)
                                            }
                                          />
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))}
                                  <Button
                                    type='dashed'
                                    onClick={() =>
                                      handleAddAlternative(addAlt, index)
                                    }
                                    icon={<PlusOutlined />}
                                    disabled={
                                      mode === 'EDIT' &&
                                      index <
                                        (props.formCustom?.perguntas?.length ||
                                          0)
                                    }
                                  >
                                    Adicionar Alternativa
                                  </Button>
                                </Col>
                              )}
                            </Form.List>
                          )}
                        </>
                      ) : (
                        <Col span={24}>
                          <Title level={5}>
                            {form.getFieldValue([
                              'perguntas',
                              index,
                              'descricao',
                            ])}
                          </Title>
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'MULTIPLA_ESCOLHA' && (
                            <Col span={!xs ? 12 : 24}>
                              <Radio.Group
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {form
                                  .getFieldValue([
                                    'perguntas',
                                    index,
                                    'alternativas',
                                  ])
                                  ?.map(
                                    (alt: Alternative, altIndex: number) => (
                                      <Radio
                                        key={altIndex}
                                        value={alt?.descricao}
                                        style={{
                                          padding: '10px 0',
                                          fontSize: 16,
                                        }}
                                      >
                                        {alt?.descricao}
                                      </Radio>
                                    )
                                  )}
                              </Radio.Group>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'NUMERICO' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                name={['perguntas', index, 'respostaNumerica']}
                              >
                                <Input
                                  size='large'
                                  type='number'
                                  placeholder='Texto de resposta numérica'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'PARAGRAFO' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                name={['perguntas', index, 'respostaParagrafo']}
                              >
                                <TextArea
                                  rows={2}
                                  placeholder='Texto de resposta longa'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {form.getFieldValue([
                            'perguntas',
                            index,
                            'tipoPergunta',
                          ]) === 'RESPOSTA_CURTA' && (
                            <Col span={!xs ? 12 : 24}>
                              <Form.Item
                                name={['perguntas', index, 'respostaCurta']}
                              >
                                <Input
                                  size='large'
                                  type='string'
                                  placeholder='Texto de resposta curta'
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Col>
                      )}
                      <Divider />
                      <Col span={24}>
                        <Row justify='end'>
                          <Space>
                            {perguntaFocada === index ? (
                              <Button
                                disabled={
                                  mode === 'EDIT' &&
                                  props.formCustom?.perguntas &&
                                  index < props.formCustom.perguntas.length
                                    ? false
                                    : !okButtonEnabled
                                }
                                size='small'
                                type='primary'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPerguntaFocada(-1);
                                }}
                              >
                                OK
                              </Button>
                            ) : (
                              <Button
                                size='small'
                                type='ghost'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePerguntaClick(index);
                                }}
                                icon={<EditTwoTone twoToneColor='#84aee6' />}
                              />
                            )}
                            {mode === 'EDIT' && (
                              <Switch
                                checked={
                                  form.getFieldValue([
                                    'perguntas',
                                    index,
                                    'status',
                                  ]) === 'ATIVO'
                                }
                                onChange={(checked) => {
                                  const perguntas =
                                    form.getFieldValue('perguntas');
                                  perguntas[index].status = checked
                                    ? 'ATIVO'
                                    : 'INATIVO';
                                  form.setFieldsValue({ perguntas });
                                  setPerguntaFocada(-1);
                                }}
                              />
                            )}
                            {mode === 'CREATE' ||
                            (mode === 'EDIT' &&
                              index >=
                                (props.formCustom?.perguntas?.length || 0)) ? (
                              <Button
                                icon={<DeleteOutlined />}
                                size='small'
                                type='ghost'
                                danger
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Space>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
                <div>
                  <Button
                    type='dashed'
                    onClick={async () => {
                      if (!(await validateLastQuestion())) return;
                      add({
                        descricao: '',
                        tipoPergunta: undefined,
                        status: 'ATIVO',
                      });
                      setPerguntaFocada(fields.length);
                      checkIfButtonShouldBeEnabled();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Adicionar Pergunta
                  </Button>

                  <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
                    <Col xs={12} lg={6}>
                      <Button
                        style={{ width: '100%' }}
                        onClick={() => {
                          if (formDisabled)
                            navigate(
                              `${
                                window.navigator.onLine
                                  ? '/formFormCustom'
                                  : '/'
                              }`
                            );
                          else
                            confirm({
                              title: 'Deseja cancelar o cadastro?',
                              content:
                                'Ao confirmar, todos os dados digitados serão perdidos e você será redirecionado para a lista de Visitas',
                              onOk: () => navigate('/'),
                            });
                        }}
                      >
                        {formDisabled ? 'Voltar' : 'Cancelar'}
                      </Button>
                    </Col>
                    {!formDisabled && (
                      <Col xs={12} lg={6}>
                        <Button
                          style={{ width: '100%' }}
                          htmlType={'submit'}
                          type={'primary'}
                          disabled={!isLastQuestionComplete()}
                        >
                          Salvar
                        </Button>
                      </Col>
                    )}
                    {formDisabled && (
                      <Col xs={12} lg={6}>
                        <Button
                          style={{ width: '100%' }}
                          htmlType={'submit'}
                          type={'primary'}
                        >
                          Editar
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </>
            )}
          </Form.List>
        </Form>
      </fieldset>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='FormCustom'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />
    </>
  );
}
