import { useEffect } from 'react';
import { Card, Descriptions, Row, Typography } from 'antd';
import { FVER } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';

type PrintFVERProps = {
  fver?: FVER.Input | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintFVER(props: PrintFVERProps) {
  useEffect(() => {}, [props.fver]);

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((item) => (
              <Descriptions.Item
                key={item.value}
                label={item.label}
                span={item.span ? item.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {item.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          //bodyStyle={{ padding: '0px' }}
        >
          {children}
        </Card>
      </div>
    );
  };

  const getServidoresFormated = (): DescriptionRow[] => {
    const lista = props.fver?.servidores.map((s) => {
      const servidorFormated: DescriptionRow = {
        span: 2,
        value: s.pessoa.nome,
      };
      return servidorFormated;
    });

    //@ts-ignore
    return lista;
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} align={'middle'} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO DE VISITA A ESTABELECIMENTO RURAL
              </Typography.Title>
            </Row>
            <br />

            {props.fver && props.fver?.status !== 'VALIDO' && (
              <>
                {panelDescription('Visita Cancelada', [
                  {
                    label: 'Motivo',
                    value: props.fver.resumo,
                  },
                ])}
                <br />
              </>
            )}

            {panelDescription('Dados da Visita', [
              {
                label: 'Número',
                value: props.fver?.numero,
              },
              {
                label: 'Data',
                value: moment(props.fver?.dataDaVisita).format(
                  'DD/MM/YYYY HH:mm'
                ),
              },
              {
                label: 'Veículo',
                value: props.fver?.placaVeiculo,
              },
            ])}
            <br />

            {props.fver?.proprietario &&
              panelDescription('Produtor', [
                {
                  label: 'Produtor',
                  value: props.fver?.proprietario?.nome,
                  span: 2,
                },
                {
                  label: 'Cpf',
                  value: props.fver?.proprietario?.cpfCnpj?.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                  ),
                },
                {
                  label: 'Apelido',
                  value: props.fver?.proprietario?.apelido,
                },
                {
                  label: 'Telefone',
                  value: props.fver?.proprietario?.endereco?.telefone,
                },
                {
                  label: 'Email',
                  value: props.fver?.proprietario?.email,
                },
              ])}
            {props.fver?.proprietario && <br />}

            {props.fver?.proprietario &&
              panelDescription('Estabelecimento Rural', [
                {
                  label: 'Nome',
                  value: props.fver?.propriedade?.nome,
                  span: 2,
                },
                {
                  label: 'Código',
                  value:
                    '51' + ('' + props.fver?.propriedade?.id).padStart(9, '0'),
                },
                {
                  label: 'Exploração',
                  value: props.fver?.exploracao?.codigo,
                },
                {
                  label: 'Município',
                  value: props.fver?.propriedade?.municipio.nome,
                },
                {
                  label: 'Coordenada',
                  value: `${props.fver?.propriedade?.coordenadaGeografica?.latGrau}º 
                    ${props.fver?.propriedade?.coordenadaGeografica?.latMin}' 
                    ${props.fver?.propriedade?.coordenadaGeografica?.latSeg}"
                    ${props.fver?.propriedade?.coordenadaGeografica?.orientacaoLatitude}", 
                    ${props.fver?.propriedade?.coordenadaGeografica?.longGrau}º 
                    ${props.fver?.propriedade?.coordenadaGeografica?.longMin}' 
                    ${props.fver?.propriedade?.coordenadaGeografica?.longSeg}"
                    ${props.fver?.propriedade?.coordenadaGeografica?.orientacaoLongitude}"`,
                },

                {
                  label: 'Endereço',
                  value: props.fver?.propriedade?.viaAcesso,
                  span: 2,
                },
              ])}
            {props.fver?.proprietario && <br />}

            {props.fver?.recinto &&
              panelDescription('Recinto', [
                {
                  label: 'Nome',
                  value: props.fver?.recinto?.pessoa.nome,
                  span: 2,
                },
                {
                  label: 'Código',
                  value: props.fver?.recinto?.id,
                },
                {
                  label: 'Município',
                  value: props.fver?.recinto?.municipio.nome,
                },
              ])}
            {props.fver?.recinto && <br />}

            {props.fver?.abatedouro &&
              panelDescription('abatedouro', [
                {
                  label: 'Nome',
                  value: props.fver?.abatedouro?.pessoa.nome,
                  span: 2,
                },
                {
                  label: 'CNPJ',
                  value: props.fver?.abatedouro?.pessoa.cpfCnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5'
                  ),
                },
                {
                  label: 'Código',
                  value: props.fver?.abatedouro?.id,
                },
                {
                  label: 'Nº de Inspeção',
                  value: props.fver?.abatedouro?.numeroInspecao,
                },
                {
                  label: 'Tipo de Inspeção',
                  value: props.fver?.abatedouro?.tipoInspecao.nome,
                },
                {
                  label: 'Município',
                  value:
                    props.fver?.abatedouro?.pessoa.endereco?.municipio.nome,
                },
                {
                  label: 'Coordenada',
                  value: `${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.latGrau}º 
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.latMin}' 
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.latSeg}"
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.orientacaoLatitude}", 
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.longGrau}º 
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.longMin}' 
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.longSeg}"
                    ${props.fver?.abatedouro?.pessoa.coordenadaGeografica?.orientacaoLongitude}"`,
                },
              ])}
            {props.fver?.abatedouro && <br />}

            {panelDescription('Resumo', [
              {
                value: props.fver?.resumo,
              },
            ])}
            <br />

            {panelComponent(
              'Programas e Atividades',
              //@ts-ignore
              props.fver?.listChavePrincipalVisitaPropriedadeRural.map(
                (chavePrimaria) => (
                  <div className='newPage'>
                    {panelDescription(
                      chavePrimaria.tipoChavePrincipalVisitaPropriedadeRural
                        .nome,
                      chavePrimaria.listChaveSecundariaVisitaPropriedadeRural.map(
                        (chaveSecundaria) => ({
                          value:
                            chaveSecundaria
                              .tipoChaveSecundariaVisitaPropriedadeRural?.nome,
                          span: 2,
                        })
                      )
                    )}
                  </div>
                )
              ),
              false
            )}
            <br />

            {props.fver?.servidores &&
              panelDescription('Servidores', getServidoresFormated())}
            <br />

            {props.fver &&
              panelComponent(
                'Assinatura do Produtor',
                <div style={{ marginTop: '-10px' }}>
                  {props.fver.statusAssinatura && (
                    <Typography.Text>
                      {StatusAssinatura.valueOf(props.fver.statusAssinatura)}
                    </Typography.Text>
                  )}
                  {props.fver?.assinatura && (
                    <img
                      src={props.fver?.assinatura}
                      alt='my signature'
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        width: '150px',
                      }}
                    />
                  )}
                </div>
              )}
            <br />

            {panelDescription('Código Verificador', [
              {
                value: props.fver?.codigoVerificador,
              },
            ])}
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
