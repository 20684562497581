import { useCallback, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Button, Col, Collapse, Divider, Form, Input, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import * as RecebimentoRevendaActions from '../../../core/store/RecebimentosRevendas.slice';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { RecebimentosRevendas } from '../../../sdk/@types';

interface FilterPanelRecebimentoRevendaProps {
  open?: boolean;
  defaultQuery?: RecebimentosRevendas.Query;
}

export default function FilterPanelRecebimentoRevenda(
  props: FilterPanelRecebimentoRevendaProps
) {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const { online } = useNavigatorStatus();
  const { firstOpening, setFirstOpening } = useLoadingPage();
  const dispatch = useDispatch<AppDispatch>();
  
  const query = useSelector(
    (state: RootState) => state.recebimentosRevendas.query
  );

  const fetchRecebimentoRevendas = useCallback(() => {
    dispatch(RecebimentoRevendaActions.filterRecebimentosRevendas(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (!firstOpening && online) {
      dispatch(RecebimentoRevendaActions.setFetching(true));
      try {
        fetchRecebimentoRevendas();
      } finally {
        dispatch(RecebimentoRevendaActions.setFetching(false));
      }
    } else {
      setFirstOpening(false);
    }
  }, [
    dispatch,
    fetchRecebimentoRevendas,
    firstOpening,
    online,
    setFirstOpening,
  ]);

  function unformatCNPJ(cnpj: string): string {
    return cnpj.replace(/[^\d]+/g, '');
  }

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const formValues = form.getFieldsValue();
        const unformattedCNPJ = formValues.cnpjRevenda
          ? unformatCNPJ(formValues.cnpjRevenda)
          : undefined;

        dispatch(
          RecebimentoRevendaActions.setQuery({
            cnpj: unformattedCNPJ,
            numero: formValues.numeroNFE ? formValues.numeroNFE : undefined,
            page: 0,
          })
        );
      } catch (error) {
        console.error(
          'Erro ao buscar os Recebimentos de Revenda com filtro:',
          error
        );
      }
    },
    [dispatch, form]
  );

  const removeField = useCallback(
    (fieldName: string) => {
      form.setFieldsValue({
        [fieldName]: null,
      });
    },
    [form]
  );

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          defaultActiveKey={props.open ? '1' : '0'}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label={'CNPJ'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'cnpjRevenda'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <InputMask
                        onClick={() => {
                          scrollFieldToTop('cnpjRevenda');
                        }}
                        mask='99.999.999/9999-99' // Máscara para CNPJ
                      >
                        <Input />
                      </InputMask>
                    </Form.Item>

                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('cnpjRevenda')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Número'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'numeroNFE'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('numeroNFE')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      dispatch(
                        RecebimentoRevendaActions.setQuery({
                          page: 0,
                          cnpj: undefined,
                          numero: undefined,
                        })
                      );
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
