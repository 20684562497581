import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DevOptionsType {
  idInTables: boolean;
  useMock: boolean;
}

const initialState: DevOptionsType = {
  idInTables: false,
  useMock: false,
};

const DevOptionsSlice = createSlice({
  initialState,
  name: 'idInTables',
  reducers: {
    setIdInTables(state, action: PayloadAction<boolean>) {
      state.idInTables = action.payload;
    },
    setUseMock(state, action: PayloadAction<boolean>) {
      state.useMock = action.payload;
    },
  },
});

export const { setIdInTables, setUseMock } = DevOptionsSlice.actions;

const DevOptionsReducer = DevOptionsSlice.reducer;
export default DevOptionsReducer;
