import { useCallback, useState } from 'react';
import { Veterinario } from '../../sdk/@types/Veterinario';
import VeterinarioIDBService from '../../sdk/services/indexeddb/VeterinarioIDB.service';
import VeterinarioService from '../../sdk/services/SIZ-API/Veterinario.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useVeterinario() {
  const { online } = useNavigatorStatus();
  const [veterinario, setVeterinario] = useState<Veterinario.Summary>();
  const [veterinarios, setVeterinarios] = useState<Veterinario.Summary[]>();
  const [fetchingVeterinario, setFetchingVeterinario] =
    useState<boolean>(false);
  const [query, setQuery] = useState<Veterinario.Query>({
    profissionalOficial: true,
  });

  const fetchVeterinarios = useCallback(() => {
    VeterinarioService.getAll(query).then(setVeterinarios);
  }, [query]);

  const fetchExistingVeterinarioByCRMV = useCallback(
    async (crmv: string, fromApi?: boolean) => {
      try {
        setFetchingVeterinario(true);

        if (!fromApi) {
          return await VeterinarioIDBService.getByCRMV(crmv).then(
            async (payload) => {
              if (payload) setVeterinario(payload.payload);
              else if (online) {
                return await VeterinarioService.getByCRMV(crmv).then(
                  setVeterinario
                );
              }
            }
          );
        } else if (online)
          return await VeterinarioService.getByCRMV(crmv).then(setVeterinario);
      } catch (err) {
        throw err;
      } finally {
        setFetchingVeterinario(false);
      }
    },
    [online]
  );

  const fetchExistingVeterinarioByCPF = useCallback(
    async (cpf: string, fromApi?: boolean) => {
      try {
        setFetchingVeterinario(true);

        if (!fromApi) {
          return await VeterinarioIDBService.getByCpf(cpf).then(
            async (payload) => {
              if (payload) setVeterinario(payload.payload);
              else if (online) {
                return await VeterinarioService.getByCpf(cpf).then(
                  setVeterinario
                );
              }
            }
          );
        } else if (online)
          return await VeterinarioService.getByCpf(cpf).then(setVeterinario);
      } catch (err) {
        throw err;
      } finally {
        setFetchingVeterinario(false);
      }
    },
    [online]
  );

  return {
    fetchingVeterinario,
    fetchExistingVeterinarioByCPF,
    fetchExistingVeterinarioByCRMV,
    fetchVeterinarios,
    veterinarios,
    query,
    setQuery,
    veterinario,
  };
}
