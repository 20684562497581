import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import {
  Col,
  Input,
  Row,
  Form,
  Select,
  FormInstance,
  notification,
  Typography,
  Divider,
  Card,
  Button,
  Table,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { coordinatesDDtoDMS } from '../../core/functions/coordinatesUtil';
import scrollFieldToTop from '../../core/functions/scrollFieldToTop';
import validatePhoneInput from '../../core/functions/validatePhoneInput';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePropriedade from '../../core/hooks/usePropriedade';
import { Exploracao, Produtor, Propriedade } from '../../sdk/@types';
import CoordenadasPanel from './CoordenadasPanel';
import ProdutorIDBService from '../../sdk/services/indexeddb/ProdutorIDB.service';
import { CustomModal } from './CustomModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { formatCpf_Cnpj } from '../../core/functions/formatCpf_Cnpj';
import removeNonNumericCharactersFromString from '../../core/functions/removeNonNumericCharactersFromString';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';

interface PropriedadePanelProps {
  form: FormInstance;
  formDisabled: boolean;
  showHeader?: boolean;
}

export default function PropriedadePanel(props: PropriedadePanelProps) {
  const { online } = useNavigatorStatus();
  const { xs } = useBreakpoint();
  const [exploracoes, setExploracoes] = useState<Exploracao.Summary[]>();
  const {
    listaPropriedade,
    fetchingPropriedades,
    fetchPropriedadesByProdutorCpf,
  } = usePropriedade();
  const [listaProdutores, setListaProdutores] = useState<any[]>();

  const [showModalProdutores, setShowModalProdutores] =
    useState<boolean>(false);

  const [fetching, setFetching] = useState<boolean>(false);

  const { firstOpening, mode, setFirstOpening, setLoading } = useLoadingPage();

  const [propriedade, setPropriedade] = useState<Propriedade.Summary>();

  const [proprietarioSelecionado, setProprietarioSelecionado] =
    useState<boolean>(false);

  const [emailEditavel, setEmailEditavel] = useState<boolean>(true);

  const fetchPropriedadeByCpfProdutor = useCallback(
    async (cpf: string) => {
      if (mode === 'VIEW') return;

      if (!cpf) return;

      setFetching(true);
      setLoading(true);
      try {
        await fetchPropriedadesByProdutorCpf(cpf, false)
          .catch((e) => {
            setLoading(false);
            throw e;
          })
          .finally(() => {
            setFetching(false);
          });
      } finally {
      }
    },
    [fetchPropriedadesByProdutorCpf, mode, setLoading]
  );

  const fetchProdutorByNome = useCallback(
    async (nome: string) => {
      if (mode === 'VIEW') return;

      if (!nome) return;

      await ProdutorIDBService.getByNome(nome)
        .then(async (listaProdutores) => {
          const listaSemDuplicatas = Array.from(
            new Set(listaProdutores.map((produtor) => JSON.stringify(produtor)))
          ).map((produtorJSON) => JSON.parse(produtorJSON));

          if (listaSemDuplicatas && listaSemDuplicatas.length > 1) {
            let listaOrdenada = listaSemDuplicatas.sort((a, b) =>
              a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0
            );

            setListaProdutores(listaOrdenada);
            setShowModalProdutores(true);
          } else if (listaSemDuplicatas && listaSemDuplicatas.length === 1) {
            props.form.setFieldValue(
              ['proprietario', 'cpfCnpj'],
              listaSemDuplicatas[0].cpf.toString()
            );
            await fetchPropriedadeByCpfProdutor(
              listaSemDuplicatas[0].cpf.toString()
            );
          }
        })
        .catch(console.log);
    },
    [fetchPropriedadeByCpfProdutor, mode, props.form]
  );

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      fetchPropriedadeByCpfProdutor(
        props.form.getFieldValue(['proprietario', 'cpfCnpj'])
      ).then(() => {
        setLoading(false);
      });
    }
  }, [mode]);

  useEffect(() => {
    if (!props.form.getFieldValue(['proprietario', 'cpfCnpj'])) return;
    if (!online && mode === 'EDIT') return;
    if (mode === 'VIEW') return;

    let proprietario = null;
    const cpf: string = removeNonNumericCharactersFromString(
      props.form.getFieldValue(['proprietario', 'cpfCnpj'])
    );

    if (!fetchingPropriedades) {
      if (!listaPropriedade || listaPropriedade.length === 0) {
        console.log('1');

        if (!firstOpening) console.log('2');
        if (!props.formDisabled)
          notification.info({
            message: `Não foi possível encontrar nenhum produtor com o cpf: ${cpf}`,
          });
      } else {
        console.log('3', propriedade);
        listaPropriedade.forEach((propriedade) => {
          propriedade.exploracaos?.forEach((exploracao) => {
            exploracao.produtores?.forEach((produtor) => {
              if (
                removeNonNumericCharactersFromString(
                  produtor.produtor.cpfCnpj
                ) === removeNonNumericCharactersFromString(cpf)
              ) {
                proprietario = produtor;
              }
            });
          });
        });
        console.log('4', proprietario);

        const produtorSummaryToInput = (
          summary: Produtor.Summary
        ): Produtor.Input => {
          let input: Produtor.Input;

          input = {
            cpfCnpj: summary.cpfCnpj,
            //@ts-ignore
            email: summary.email,
            //@ts-ignore
            endereco: summary.endereco,
            nome: summary.nome,
            id: summary.id,
          };

          return input;
        };

        if (proprietario) {
          setProprietarioSelecionado(true);
          setEmailEditavel(false);
          //@ts-ignore
          if (proprietario.produtor.acessaSistema === 'S')
            setEmailEditavel(false);
          else setEmailEditavel(true);

          props.form.setFieldsValue({
            //@ts-ignore
            proprietario: produtorSummaryToInput(proprietario.produtor),
          });

          notification.success({
            message: `Produtor ${
              //@ts-ignore
              proprietario.produtor.nome
            } selecionado`,
          });
        }

        if (mode || 'VIEW') {
          setPropriedade(props.form.getFieldValue('propriedade'));
        }

        if (mode === 'EDIT') {
          listaPropriedade.forEach((propriedade) => {
            if (
              propriedade.id === props.form.getFieldValue(['propriedade', 'id'])
            )
              setPropriedade(propriedade);
          });
        }

        setFirstOpening(false);
        setLoading(false);
      }

      if (listaPropriedade?.length === 1) {
        props.form.setFieldsValue({
          propriedade: listaPropriedade[0],
        });
        setPropriedade(listaPropriedade[0]);
      }
    }
    setLoading(fetchingPropriedades);
  }, [fetchingPropriedades, setLoading]);

  useEffect(() => {
    if (propriedade) {
      props.form.setFieldsValue({
        propriedade: {
          ...propriedade,
          coordenadaGeografica: {
            ...propriedade.coordenadaGeografica,
            orientacaoLatitude: 'S',
            orientacaoLongitude: 'O',
          },
        },
      });

      let exploracoesDoProdutor;
      const cpf = removeNonNumericCharactersFromString(
        props.form.getFieldValue(['proprietario', 'cpfCnpj'])
      );

      exploracoesDoProdutor = propriedade.exploracaos?.filter((exploracao) => {
        return (
          exploracao.produtores.filter((produtor) => {
            return produtor.produtor.cpfCnpj === cpf;
          }).length > 0
        );
      });
      setExploracoes(exploracoesDoProdutor);
      //fetchSetores();

      if (propriedade.setor)
        props.form.setFieldsValue({ setor: propriedade.setor });

      setLoading(false);
      setFirstOpening(false);

      notification.success({
        message: `Propriedade ${propriedade.id}-${propriedade.nome} selecionada`,
      });
    }
  }, [propriedade]);

  useEffect(() => {
    if (exploracoes?.length === 1) {
      props.form.setFieldsValue({
        exploracao: exploracoes[0],
      });
    }
  }, [exploracoes]);

  const removeProprietario = useCallback(() => {
    setPropriedade(undefined);
    setExploracoes(undefined);
    setProprietarioSelecionado(false);
    setEmailEditavel(false);
    /* setOptions([]); */

    props.form.setFieldsValue({
      proprietario: undefined,
      propriedade: undefined,
      setor: undefined,
    });
  }, [props.form]);

  const handleSelectPropriedadeChange = (value: any) => {
    let propriedadeSelecionada: Propriedade.Summary = JSON.parse(value);

    if (propriedadeSelecionada) {
      props.form.setFieldsValue({
        propriedade: propriedadeSelecionada,
        exploracao: undefined,
      });

      setPropriedade(propriedadeSelecionada);
    }
  };

  const handleChangeSelectExploracao = (value: any) => {
    let obj: Exploracao.Summary = JSON.parse(value);

    props.form.setFieldsValue({
      exploracao: obj,
    });
  };

  const updateCoordsOnForm = (coords: GeolocationCoordinates) => {
    if (!propriedade) {
      notification.error({
        message: 'Nenhuma propriedade selecionada',
      });
      return;
    }

    const coordenadaGeografica = coordinatesDDtoDMS(
      coords.latitude,
      coords.longitude
    );

    props.form.setFieldsValue({
      propriedade: {
        ...propriedade,
        coordenadaGeografica: {
          latGrau: coordenadaGeografica.latGrau,
          latMin: coordenadaGeografica.latMin,
          latSeg: coordenadaGeografica.latSeg,
          longGrau: coordenadaGeografica.longGrau,
          longMin: coordenadaGeografica.longMin,
          longSeg: coordenadaGeografica.longSeg,
          orientacaoLatitude: coordenadaGeografica.orientacaoLatitude,
          orientacaoLongitude: coordenadaGeografica.orientacaoLongitude,
        },
      },
    });
  };

  /* const [, setAllOptions] = useState<{ value: string }[]>(); */
  /* const [, setOptions] = useState<{ value: string }[]>(); */

  /* useEffect(() => {
    const init = async () => {
      setLoading(true);
      return await ProdutorIDBService.getAll()
        .then(async (listaProdutores) => {
          const listaSemDuplicatas = Array.from(
            new Set(
              listaProdutores.map((produtor) =>
                JSON.stringify(produtor.produtor)
              )
            )
          ).map((produtorJSON) => JSON.parse(produtorJSON));

          setAllOptions(
            listaSemDuplicatas.map((l) => ({
              value:
                l.cpf
                  .replace(/(\d{3})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d{1,2})$/, '$1-$2') +
                ' - ' +
                l.nome,
            }))
          );
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    };
    init();
  }, []); */

  return (
    <>
      {props.showHeader && (
        <Divider orientation='left'>Dados do produtor e propriedade</Divider>
      )}

      <Row gutter={24} align={'bottom'}>
        <Form.Item hidden name={['proprietario', 'cpfCnpj']}>
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name={['proprietario', 'id']}>
          <Input disabled />
        </Form.Item>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Cpf do produtor'}
            name={['proprietario', 'cpfCnpj']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input.Search
              onClick={() => {
                scrollFieldToTop('proprietario_cpfCnpj');
              }}
              width={'100%'}
              placeholder={'Informe o cpf do produtor'}
              allowClear={false}
              disabled={
                proprietarioSelecionado || props.formDisabled || mode === 'EDIT'
              }
              loading={fetching}
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled || mode === 'EDIT'}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeProprietario}
                />
              }
              onKeyUp={(event: any) => {
                //handle ctrl+v
                const code = event.which || event.keyCode;
                let charCode = String.fromCharCode(code).toLowerCase();
                if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
                  event.currentTarget.value = formatCpf_Cnpj(
                    event.target.value
                  );
                }

                //handle delete|backspace
                if (event.keyCode === 8 || event.keyCode === 46) {
                  event.currentTarget.value = formatCpf_Cnpj(
                    event.target.value
                  );
                }
              }}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                  event.preventDefault();
                }

                event.currentTarget.value = formatCpf_Cnpj(event.target.value);
              }}
              onSearch={(cpf) => {
                if (!cpf) {
                  removeProprietario();
                  setFetching(false);
                } else fetchPropriedadeByCpfProdutor(cpf);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={16}>
          <Tooltip title='Busca por nome disponível apenas no modo offline'>
            <Form.Item
              label={'Produtor'}
              name={['proprietario', 'nome']}
              validateTrigger={'onSearch'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Input.Search
                onClick={() => {
                  scrollFieldToTop('proprietario_nome');
                }}
                width={'100%'}
                placeholder={'Informe o nome do produtor'}
                allowClear={false}
                disabled={
                  proprietarioSelecionado ||
                  props.formDisabled ||
                  mode === 'EDIT'
                }
                loading={fetching}
                inputMode='text'
                addonAfter={
                  <Button
                    disabled={props.formDisabled || mode === 'EDIT'}
                    icon={<DeleteOutlined />}
                    danger
                    onClick={removeProprietario}
                  />
                }
                onSearch={(nome) => {
                  if (!nome) {
                    removeProprietario();
                    setFetching(false);
                  } else fetchProdutorByNome(nome);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Tooltip>
        </Col>
        {/* <Col xs={24} lg={24}>
          <Form.Item label={'Proprietário'} style={{ width: '100%' }}>
            <Input.Group
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Form.Item
                name={['proprietario', 'nome']}
                validateTrigger={'onSearch'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    validator: async (_, codigo) => {
                      if (props.formDisabled) return Promise.resolve();
                      if (proprietarioSelecionado) return Promise.resolve();
                      if (!options || options.length === 0) {
                        return Promise.reject(
                          'Nenhum produtor encontrado. Verifique se o município do estabelecimento foi sincronizado'
                        );
                      }
                    },
                  },
                ]}
                style={{
                  width: '100%',
                  marginBottom: '0',
                  marginTop: '0',
                }}
              >
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  onSelect={onSelect}
                  disabled={
                    proprietarioSelecionado ||
                    props.formDisabled ||
                    mode === 'EDIT'
                  }
                />
              </Form.Item>
              <Button
                style={{
                  height: '34px',
                }}
                icon={<DeleteOutlined />}
                danger
                onClick={removeProprietario}
                disabled={props.formDisabled || mode === 'EDIT'}
              />
            </Input.Group>
          </Form.Item>
        </Col> */}
        <Col xs={24} lg={8}>
          <Form.Item label={'Apelido'} name={['proprietario', 'apelido']}>
            <Input
              autoComplete='off'
              onClick={() => {
                scrollFieldToTop('proprietario_apelido');
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Email'}
            name={['proprietario', 'email']}
            rules={[
              {
                validator: async (_, email) => {
                  if (!email) return;
                  if (
                    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                      email
                    )
                  )
                    return Promise.reject(new Error('Email inválido'));
                },
              },
            ]}
          >
            <Input
              inputMode='email'
              autoComplete='off'
              onClick={() => {
                scrollFieldToTop('proprietario_email');
              }}
              disabled={!emailEditavel}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item hidden name={['proprietario', 'endereco', 'id']}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={'Telefone'}
            name={['proprietario', 'endereco', 'telefone']}
          >
            <Input
              inputMode='tel'
              autoComplete='off'
              onKeyPress={validatePhoneInput}
              onClick={() => {
                scrollFieldToTop('proprietario_endereco_telefone');
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            hidden
            name={['proprietario', 'endereco', 'tipoLogradouro', 'id']}
          >
            <Input hidden />
          </Form.Item>

          <Form.Item
            hidden
            label={'Tipo Logradouro'}
            name={['proprietario', 'endereco', 'tipoLogradouro', 'nome']}
          >
            <Input hidden autoComplete='off' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            hidden
            label={'Logradouro'}
            name={['proprietario', 'endereco', 'logradouro']}
          >
            <Input hidden />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            hidden
            label={'Bairro'}
            name={['proprietario', 'endereco', 'bairro']}
          >
            <Input autoComplete='off' hidden />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            hidden
            label={'Via de acesso'}
            name={['proprietario', 'endereco', 'complemento']}
          >
            <Input autoComplete='off' hidden />
          </Form.Item>
        </Col>
        <Col xs={24} lg={16}>
          <Form.Item
            hidden
            label={'Referência'}
            name={['proprietario', 'endereco', 'referencia']}
          >
            <Input autoComplete='off' hidden />
          </Form.Item>

          <Form.Item
            hidden
            name={['proprietario', 'endereco', 'municipio', 'id']}
          >
            <Input hidden />
          </Form.Item>

          <Form.Item
            hidden
            name={['proprietario', 'endereco', 'municipio', 'codgIBGE']}
          >
            <Input hidden />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={0} lg={0}>
          <Form.Item label={'Id'} name={['propriedade', 'id']} hidden>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            label={'Estabelecimento rural'}
            name={['propriedade', 'nome']}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Select
              onClick={() => {
                scrollFieldToTop('propriedade_nome');
              }}
              onChange={handleSelectPropriedadeChange}
              loading={fetching}
              placeholder={`${
                listaPropriedade
                  ? 'Selecione a propriedade'
                  : 'Selecione o produtor primeiro'
              }`}
              placement={'bottomLeft'}
              disabled={props.formDisabled || mode === 'EDIT'}
            >
              {listaPropriedade?.map((propriedade) => (
                <Select.Option
                  key={propriedade.id}
                  value={JSON.stringify(propriedade)}
                >
                  {propriedade.id} - {propriedade.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label={'Código'} name={['propriedade', 'id']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item label={'Nome'} name={['propriedade', 'nome']} hidden>
            <Input disabled />
          </Form.Item>
        </Col>

        <Form.Item name={['propriedade', 'municipio', 'id']} hidden>
          <Input disabled />
        </Form.Item>

        <Form.Item hidden name={['propriedade', 'municipio', 'codgIBGE']}>
          <Input hidden />
        </Form.Item>

        <Col xs={24} lg={24}>
          <Form.Item
            label={'Endereço'}
            name={['propriedade', 'enderecoPropriedade']}
            hidden
          >
            <Input
              key={'enderecoPropriedade'}
              onClick={() => {
                scrollFieldToTop('propriedade_enderecoPropriedade');
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item
            label={'Via de acesso'}
            name={['propriedade', 'viaAcesso']}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <TextArea
              rows={2}
              maxLength={200}
              showCount
              onClick={() => {
                scrollFieldToTop('propriedade_viaAcesso');
              }}
            />
          </Form.Item>
        </Col>

        <Form.Item name={['propriedade', 'municipio', 'uf']} hidden>
          <Input disabled />
        </Form.Item>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Município'}
            name={['propriedade', 'municipio', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item hidden name={['setor', 'id']}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={'Setor Nº'}
            name={['setor', 'nome']}
            /* rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]} */
          >
            <Input disabled />
            {/*  <Select
              onChange={handleSelectSetorChange}
              loading={fetching}
              placeholder={`${
                listaSetor
                  ? 'Selecione o setor'
                  : 'Selecione a propriedade primeiro'
              }`}
              placement={'bottomLeft'}
              disabled={props.formDisabled}
            >
              {listaSetor?.map((setor) => (
                <Select.Option key={setor.id} value={JSON.stringify(setor)}>
                  <Card
                    style={{
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {setor.numero} - {setor.nome}
                  </Card>
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Unidade'}
            name={['propriedade', 'unidade', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item hidden name={['exploracao', 'id']}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'Exploração'} name={['exploracao', 'codigo']}>
            <Select
              disabled={props.formDisabled || mode === 'EDIT'}
              onClick={() => {
                scrollFieldToTop('exploracao_codigo');
              }}
              onChange={handleChangeSelectExploracao}
              loading={fetching}
              placeholder={`${
                exploracoes
                  ? 'Selecione a exploração'
                  : 'Selecione a propriedade primeiro'
              }`}
              placement={'bottomLeft'}
            >
              {exploracoes?.map((exploracao) => (
                <Select.Option
                  key={exploracao.codigo}
                  value={JSON.stringify(exploracao)}
                >
                  <Card key={exploracao.codigo}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography.Text style={{ fontWeight: 'bold' }}>
                        {exploracao.codigo}
                      </Typography.Text>

                      {exploracao.produtores.map((produtor) => (
                        <Typography.Text key={produtor.id}>
                          {produtor.produtor.nome}
                        </Typography.Text>
                      ))}
                    </div>
                  </Card>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {
        <CoordenadasPanel
          formDisabled={props.formDisabled || propriedade === undefined}
          form={props.form}
          pathToCoordenada={['propriedade']}
          onAcceptCoords={(coords) => {
            updateCoordsOnForm(coords);
          }}
        />
      }

      <CustomModal
        title={'Selecione o produtor'}
        open={showModalProdutores}
        width={700}
        footer={
          <Button
            type='default'
            onClick={() => {
              setShowModalProdutores(false);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Table
          dataSource={listaProdutores}
          size={'small'}
          rowKey={'id'}
          showHeader={false}
          columns={[
            {
              dataIndex: 'cpf',
              title: 'CPF',
              width: '130px',
              render(cpf) {
                let cpfFormatado = cpf;

                if (cpfFormatado) {
                  cpfFormatado = cpfFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                  cpfFormatado = cpfFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                  cpfFormatado = cpfFormatado.replace(
                    /(\d{3})(\d{1,2})$/,
                    '$1-$2'
                  );
                }
                return cpfFormatado;
              },
            },
            {
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              dataIndex: 'cpf',
              width: xs ? '0' : '80px',
              render(cpf, produtor) {
                return (
                  <Button
                    icon={<SelectOutlined />}
                    type='primary'
                    onClick={async () => {
                      setShowModalProdutores(false);

                      props.form.setFieldValue(
                        ['proprietario', 'cpfCnpj'],
                        cpf
                      );
                      await fetchPropriedadeByCpfProdutor(cpf);
                    }}
                  >
                    {xs ? '' : 'Selecionar'}
                  </Button>
                );
              },
            },
          ]}
        />
      </CustomModal>
    </>
  );
}
