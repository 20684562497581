import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import FormCustomView from '../features/FormCustom/FormCustomView';
import useLoadingPage from '../../core/hooks/useLoadingPage';

export default function FormCustomViewWrapper() {
  usePageTitle('formulario');
  const { setLoading, setMode, setFirstOpening } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formulário customizado'>
        <FormCustomView />
      </ErrorBoundary>
    </>
  );
}
