import {
  CheckCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Row,
  Col,
  Divider,
  Select,
  Button,
  Table,
  Typography,
  Input,
  notification,
  Tag,
  Space,
  Descriptions,
  Switch,
  Popconfirm,
  Tooltip,
  Radio,
  Modal,
  Skeleton,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useTipoChavePrincipalFVER from '../../../core/hooks/useTipoChavePrincipalFVER';
import useTipoChaveSecundariaFVER from '../../../core/hooks/useTipoChaveSecundariaFVER';
import {
  ChavePrincipalFVER,
  Servidor,
  TipoChaveSecundariaFVER,
  FVER,
  CoordenadaGeografica,
} from '../../../sdk/@types';
import PropriedadePanel from '../../components/PropriedadePanel';
import AbatedouroPanel from '../../components/AbatedouroPanel';
import RecintoPanel from '../../components/RecintoPanel';
import SignatureWrapper from '../../components/SignatureWrapper';
import useFVER from '../../../core/hooks/useFVER';
import useAssinaturaBase64Image from '../../../core/hooks/useAssinaturaBase64Image';
import { useNavigate, useParams } from 'react-router-dom';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import ServidoresPanel from '../../components/ServidoresPanel';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import AuthorizationService from '../../../core/auth/Authorization.service';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import { CustomModal } from '../../components/CustomModal';
import generateHash from '../../../core/functions/generateHash';
import Service from '../../../sdk/services/SIZ-API/Service';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import * as FVERDraftActions from '../../../core/store/FVERDraft.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';

const { TextArea } = Input;

export type FormFVERType = {
  dataDaVisita?: Moment;
  horaDaVisita?: string;
  modalTipoChavePrimaria?: {
    id: number;
    nome: string;
  };
  modalListaTipoChaveSecundaria?: TipoChaveSecundariaFVER.Summary[];
  quantidadeApreensoes?: number;
  quantidadeDestruicoes?: number;
  servidorBusca?: Servidor.Summary;
} & Omit<FVER.Input, 'dataDaVisita'>;

type FormFVERProps = {
  fver?: FormFVERType;
};

export default function FormFVER(props: FormFVERProps) {
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { online } = useNavigatorStatus();

  const { mode, setLoading } = useLoadingPage();
  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();

  const [form] = Form.useForm<FormFVERType>();

  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [shouldSaveOffline, setShouldSaveOffline] = useState<boolean>(false);

  const dispatch = useDispatch();
  const fverDraft = useSelector(
    (state: RootState) => state.fverDraft.fverDraft
  );

  const { updateFVEROffline } = useFVER();

  const [waitFVERDraftEvaluation, setWaitFVERDraftEvaluation] = useState(true);

  const [showAbatedouroPanel, setShowAbatedouroPanel] =
    useState<boolean>(false);
  const [showDropdownChaveSecundaria, setShowDropdownChaveSecundaria] =
    useState<boolean>(false);
  const [showPropriedadePanel, setShowPropriedadePanel] =
    useState<boolean>(false);
  const [showRecintoPanel, setShowRecintoPanel] = useState<boolean>(false);

  const [statusAssinaturaState, setStatusAssinaturaState] = useState();

  const {
    tipoChavePrincipalFVER,
    fetchActive: fetchActiveTipoChavePrincipalFVER,
  } = useTipoChavePrincipalFVER();

  const {
    tipoChaveSecundariaFVER,
    fetchActive: fetchActiveTipoChaveSecundariaFVER,
  } = useTipoChaveSecundariaFVER();

  const [showModalTimePicker, setShowModalTimePicker] =
    useState<boolean>(false);
  const [showModalProgramas, setShowModalProgramas] = useState<boolean>(false);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const [dataSourceTableChavesPrimarias, setDatasourceTableChavesPrimarias] =
    useState<FormFVERType['listChavePrincipalVisitaPropriedadeRural']>([]);

  const [listaServidores, setListaServidores] = useState<Servidor.Summary[]>(
    []
  );

  const listaStatusAssinatura = StatusAssinatura.keys();

  /*  
  //"prevent" back button, but loses state
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  window.onpopstate = (e) => {
    //navigator scrolls to same location as before
    navigator.push('/visitas/cadastro');

    //possível usar um modal para retornar à página. mas só faz sentido se estiver salvando state do formulário
    Modal.confirm({
      title: 'TESTE',
    });
  }; */

  const insertLoggedUser = useCallback(async () => {
    if (mode === 'CREATE') {
      if (AuthorizationService.getUsuarioSIZ()) {
        await ServidorIDBService.getByCpf(
          //@ts-ignore
          AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
        ).then((servidor) => {
          if (servidor) {
            const newLista = listaServidores.concat(servidor.payload);
            setListaServidores(newLista);
          }
        });
      }
    }
  }, [listaServidores, mode]);

  const initNewFVER = useCallback(() => {
    insertLoggedUser();
    form.setFieldValue(
      'dataDaVisita',
      moment.tz(moment().toLocaleString(), 'America/Cuiaba')
    );
    form.setFieldValue(
      'horaDaVisita',
      String(
        moment.tz(moment().toLocaleString(), 'America/Cuiaba').get('hour')
      ).padStart(2, '0') +
        ':' +
        String(
          moment.tz(moment().toLocaleString(), 'America/Cuiaba').get('minute')
        ).padStart(2, '0')
    );
  }, [form, insertLoggedUser]);

  const validateFVERDraft = useCallback(() => {
    if (fverDraft) {
      Modal.confirm({
        title: 'Encontramos o rascunho de um FVER não finalizado',
        content: (
          <Typography.Paragraph>
            Você pode continuar preenchendo ou descartá-lo
          </Typography.Paragraph>
        ),
        cancelText: 'Descartar',
        okText: 'Continuar',
        cancelButtonProps: { danger: true },
        onCancel: () => {
          dispatch(FVERDraftActions.storeFVERDraft(undefined));
          initNewFVER();
          setWaitFVERDraftEvaluation(false);
        },
        onOk: () => {
          setWaitFVERDraftEvaluation(false);
        },
      });
    } else {
      setWaitFVERDraftEvaluation(false);
    }
  }, [dispatch, fverDraft, initNewFVER]);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {}, [dadosModalFinalizacao]);

  useEffect(() => {
    switchPanel(form.getFieldValue('tipoEstabelecimento'));

    if (props.fver?.servidores) setListaServidores(props.fver?.servidores);
    if (props.fver?.listChavePrincipalVisitaPropriedadeRural) {
      setDatasourceTableChavesPrimarias(
        props.fver?.listChavePrincipalVisitaPropriedadeRural
      );
    }
    if (params.local) setLoading(false);

    if (props.fver?.assinatura) setAssinaturaBase64Image(props.fver.assinatura);
  }, [props.fver, params, setAssinaturaBase64Image, setLoading]);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);

    if (mode !== 'CREATE')
      setStatusAssinaturaState(form.getFieldValue('statusAssinatura'));
  }, [mode, form]);

  useEffect(() => {
    initNewFVER();
    validateFVERDraft();
  }, []);

  useEffect(() => {
    fetchActiveTipoChavePrincipalFVER();
    fetchActiveTipoChaveSecundariaFVER();
  }, [fetchActiveTipoChavePrincipalFVER, fetchActiveTipoChaveSecundariaFVER]);

  const saveFVERLocal = useCallback(
    async (fver: FVER.Input, problema_rede?: boolean) => {
      if (params.id) {
        await updateFVEROffline(Number(params.id), fver)
          .then((idFVER) => {
            setDadosModalFinalizacao({
              id: Number(params.id),
              numero: '',
              local: true,
              tipoEstabelecimento: fver.tipoEstabelecimento,
            });

            setShowModalFinalizacao(true);
          })
          .catch((e) => {
            notification.error({
              message: 'Não foi possível atualizar o FVER no dispositivo',
              description: e.message,
            });
          });
      } else {
        fver.codigoVerificador = generateHash(fver);
        await FVERIDBService.insert(fver)
          .then((idVisita) => {
            setDadosModalFinalizacao({
              id: Number(idVisita.toString()),
              numero: '',
              local: true,
              tipoEstabelecimento: fver.tipoEstabelecimento,
            });

            setShowModalFinalizacao(true);
          })
          .catch((e) => {
            notification.error({
              message: 'Não foi possível salvar o FVER no dispositivo',
              description: e.message,
            });
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [params, setLoading, updateFVEROffline]
  );

  const saveFVEROnline = useCallback(
    async (fver: FVER.Input) => {
      if (!fver.id) {
        fver.codigoVerificador = generateHash(fver);
      }
      await FVERService.add(fver)
        .then((visita) => {
          setDadosModalFinalizacao({
            id: visita.id,
            numero: visita.numero,
            tipoEstabelecimento: fver.tipoEstabelecimento,
          });

          setShowModalFinalizacao(true);
        })
        .catch(async (e) => {
          console.log('3', e.message);
          console.log('4', JSON.stringify(e));

          if (
            e.message === Service.NETWORK_ERROR ||
            e.message.includes('Serviço indisponível')
          ) {
            if (!fver.id) {
              await saveFVERLocal(fver, true);
            }
          } else {
            notification.error({
              message: 'Não foi possível salvar o FVER',
              description: e.message,
            });
          }

          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [saveFVERLocal, setLoading]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormFVERType) => {
      setLoading(true);
      const lista: FVER.Input['listChavePrincipalVisitaPropriedadeRural'] =
        dataSourceTableChavesPrimarias.map((chave) => {
          return {
            motivoPrincipal: chave.motivoPrincipal,
            tipoChavePrincipalVisitaPropriedadeRural: {
              id: chave.tipoChavePrincipalVisitaPropriedadeRural.id,
              nome: chave.tipoChavePrincipalVisitaPropriedadeRural.nome,
            },

            listChaveSecundariaVisitaPropriedadeRural:
              chave.listChaveSecundariaVisitaPropriedadeRural,
            quantidadeApreensoes: form.getFieldValue('quantidadeApreensoes'),
            quantidadeDestruicoes: form.getFieldValue('quantidadeDestruicoes'),
          };
        });

      const data: Moment = form.getFieldValue('dataDaVisita');
      const hora: string = form.getFieldValue('horaDaVisita');

      let dataMoment = data.set({
        hour: Number(hora.split(':')[0]),
        minute: Number(hora.split(':')[1]),
      });

      let coordenadaGeograficaRequest:
        | CoordenadaGeografica.Detailed
        | undefined = undefined;

      if (formParam.abatedouro) {
        coordenadaGeograficaRequest = {
          ...formParam.abatedouro.pessoa.coordenadaGeografica!,
          pessoa: {
            id: formParam.abatedouro.id!,
            cpfCnpj: formParam.abatedouro.pessoa.cpfCnpj,
          },
        };
      } else if (formParam.propriedade) {
        coordenadaGeograficaRequest = {
          ...formParam.propriedade.coordenadaGeografica!,
          //@ts-ignore
          pessoa: {
            id: formParam.propriedade.id!,
          },
        };
      }

      const fverDTO: FVER.Input = {
        ...formParam,
        coordenadaGeografica: coordenadaGeograficaRequest,
        dataDaVisita: dataMoment.format(`YYYY-MM-DD[T]HH:mm:ss[Z]`),
        //@ts-ignore
        horaDaVisita: '',
        listChavePrincipalVisitaPropriedadeRural: lista,
        servidores: listaServidores,
        statusAssinatura: statusAssinaturaState,
        assinatura: assinaturaBase64Image,
      };

      console.log('fverDTO', fverDTO);
      if (online) {
        if (params.local) {
          saveFVERLocal(fverDTO);
        } else {
          if (shouldSaveOffline) saveFVERLocal(fverDTO);
          else saveFVEROnline(fverDTO);
        }
      } else {
        if (!fverDTO.id) saveFVERLocal(fverDTO);
        else saveFVEROnline(fverDTO);
      }
      return false;
    },
    [
      dataSourceTableChavesPrimarias,
      form,
      listaServidores,
      assinaturaBase64Image,
      online,
      params,
      saveFVERLocal,
      saveFVEROnline,
      setLoading,
      shouldSaveOffline,
      statusAssinaturaState,
    ]
  );

  const handleSelectChavePrimariaChange = (value: any) => {
    let obj: FormFVERType['modalTipoChavePrimaria'] = JSON.parse(value);

    if (obj)
      form.setFieldsValue({
        modalTipoChavePrimaria: { id: obj.id, nome: obj.nome },
      });
  };

  const handleSelectChaveSecundariaChange = (list: any[]) => {
    let listaParseada: any[] = [];

    list.forEach((obj, i) => {
      listaParseada = listaParseada.concat(JSON.parse(obj));
    });

    form.setFieldsValue({
      modalListaTipoChaveSecundaria: listaParseada,
    });
  };

  const cleanModalProgramasEAtividades = useCallback(() => {
    form.setFieldsValue({
      modalTipoChavePrimaria: undefined,
      modalListaTipoChaveSecundaria: undefined,
      quantidadeApreensoes: undefined,
      quantidadeDestruicoes: undefined,
    });
  }, [form]);

  const handleAddPrograma = useCallback(() => {
    const tipoChaveSendoInserida: ChavePrincipalFVER.Input['tipoChavePrincipalVisitaPropriedadeRural'] =
      form.getFieldValue('modalTipoChavePrimaria');

    const arrayDeChavesIguaisAChaveQueEstaSendoInserida =
      dataSourceTableChavesPrimarias.filter((chave) => {
        return (
          chave.tipoChavePrincipalVisitaPropriedadeRural.id ===
          tipoChaveSendoInserida.id
        );
      });

    if (arrayDeChavesIguaisAChaveQueEstaSendoInserida.length > 0) {
      notification.warn({
        message: 'Esta chave principal já foi incluída',
        description: 'Selecione outra chave principal',
      });
      return;
    }

    const listaTipoChaveSecundaria: TipoChaveSecundariaFVER.Summary[] =
      form.getFieldValue('modalListaTipoChaveSecundaria');

    let list = listaTipoChaveSecundaria?.map((s) => {
      return { tipoChaveSecundariaVisitaPropriedadeRural: s };
    });

    const novaChaveASerIncluida: typeof dataSourceTableChavesPrimarias = [
      {
        motivoPrincipal:
          dataSourceTableChavesPrimarias.length > 0 ? 'NAO' : 'SIM',
        tipoChavePrincipalVisitaPropriedadeRural: {
          id: tipoChaveSendoInserida.id,
          nome: tipoChaveSendoInserida.nome,
        },
        listChaveSecundariaVisitaPropriedadeRural: list,
        quantidadeApreensoes: form.getFieldValue('quantidadeApreensoes'),
        quantidadeDestruicoes: form.getFieldValue('quantidadeDestruicoes'),
      },
    ];

    setDatasourceTableChavesPrimarias(
      dataSourceTableChavesPrimarias.concat(novaChaveASerIncluida)
    );
    setShowModalProgramas(false);
    cleanModalProgramasEAtividades();
    form.validateFields(['tableChavesPrimarias']);
    notification.success({
      message: 'Programa e atividades incluído com sucesso',
    });
  }, [dataSourceTableChavesPrimarias, form, cleanModalProgramasEAtividades]);

  const switchPanel = useCallback(
    (value: FVER.Input['tipoEstabelecimento']) => {
      if (value === 'ABATEDOURO') {
        setShowAbatedouroPanel(true);
        setShowPropriedadePanel(false);
        setShowRecintoPanel(false);
      } else if (value === 'PROPRIEDADE') {
        setShowAbatedouroPanel(false);
        setShowPropriedadePanel(true);
        setShowRecintoPanel(false);
      } else if (value === 'RECINTO') {
        setShowAbatedouroPanel(false);
        setShowPropriedadePanel(false);
        setShowRecintoPanel(true);
      }

      /*
      form.setFieldsValue({
        propriedade: undefined,
        abatedouro: undefined,
        recinto: undefined,
        proprietario: undefined,
      });
      */
    },
    []
  );

  const onChangeSwitchMotivoPrincipal = useCallback(
    (value, chavePrincipal: ChavePrincipalFVER.Input) => {
      setDatasourceTableChavesPrimarias(
        dataSourceTableChavesPrimarias.map((chave) => {
          if (
            chave.tipoChavePrincipalVisitaPropriedadeRural.id ===
            chavePrincipal.tipoChavePrincipalVisitaPropriedadeRural.id
          ) {
            if (dataSourceTableChavesPrimarias.length > 1) {
              if (chave.motivoPrincipal === 'SIM') {
                notification.warn({
                  message: 'Não foi possível desmarcar o item',
                  description:
                    'Pelo menos um programa deve ser o motivo principal da visita',
                });
              } else chave.motivoPrincipal = value ? 'SIM' : 'NAO';
            } else if (chave.motivoPrincipal === 'SIM') {
              notification.warn({
                message: 'Não foi possível desmarcar o item',
                description:
                  'Pelo menos um programa deve ser o motivo principal da visita',
              });
            } else {
              chave.motivoPrincipal = 'SIM';
            }
          } else if (value) {
            chave.motivoPrincipal = 'NAO';
          }

          return chave;
        })
      );
    },
    [dataSourceTableChavesPrimarias]
  );

  const chavePrimariaTableActions = (
    chavePrimaria: ChavePrincipalFVER.Input
  ) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          disabled={chavePrimaria.motivoPrincipal === 'SIM'}
          title={'Deseja remover a chave primária?'}
          onConfirm={() => {
            setDatasourceTableChavesPrimarias(
              dataSourceTableChavesPrimarias.filter(
                (chave) =>
                  chave.tipoChavePrincipalVisitaPropriedadeRural.id !==
                  chavePrimaria.tipoChavePrincipalVisitaPropriedadeRural.id
              )
            );
            notification.success({
              message: `Chave ${chavePrimaria.tipoChavePrincipalVisitaPropriedadeRural.nome} removida`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            disabled={chavePrimaria.motivoPrincipal === 'SIM' || formDisabled}
            type={'ghost'}
            title={'Remover chave primária'}
          />
        </Popconfirm>
      </Row>
    );
  };

  return (
    <>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='FVER'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />

      {!waitFVERDraftEvaluation && (
        <fieldset disabled={formDisabled}>
          <Form<FormFVERType>
            layout={'vertical'}
            form={form}
            size={!xs ? 'small' : 'large'}
            onFinish={handleFormSubmit}
            autoComplete={'off'}
            onFinishFailed={() => {
              notification.error({
                message: 'Alguns campos obrigatórios não foram preenchidos',
                description: 'Por favor revise o formulário',
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            initialValues={mode === 'CREATE' ? fverDraft : props.fver}
          >
            <Row gutter={24}>
              <Form.Item name={'id'} hidden>
                <Input disabled hidden />
              </Form.Item>

              <Form.Item name={'codigoVerificador'} hidden>
                <Input disabled hidden />
              </Form.Item>
              <Form.Item name={'numero'} hidden>
                <Input disabled hidden />
              </Form.Item>

              <Col xs={15} lg={5}>
                <Form.Item
                  label={'Data da visita'}
                  name={'dataDaVisita'}
                  rules={[{ required: true, message: 'O campo é obrigatório' }]}
                >
                  <DatePicker
                    onClick={() => {
                      scrollFieldToTop('dataDaVisita');
                    }}
                    style={{ width: '100%' }}
                    format={'DD/MM/YYYY'}
                    disabledDate={(date) => date.isAfter(moment().toDate())}
                    disabled={formDisabled}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
              <Col xs={9} lg={3}>
                <Form.Item
                  label={'Hora'}
                  name={'horaDaVisita'}
                  rules={[{ required: true, message: 'O campo é obrigatório' }]}
                >
                  <Input
                    inputMode='numeric'
                    autoComplete='off'
                    onClick={() => {
                      scrollFieldToTop('horaDaVisita');
                      setShowModalTimePicker(true);
                    }}
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={0}>
                <br />
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Placa do veículo utilizado na visita'}
                  name={'placaVeiculo'}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Input
                    autoComplete='off'
                    onBlur={(event) => {
                      dispatch(
                        FVERDraftActions.storeFVERDraft({
                          ...fverDraft!,
                          placaVeiculo: event.currentTarget.value,
                        })
                      );
                    }}
                    onClick={() => {
                      scrollFieldToTop('placaVeiculo');
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Tipo estabelecimento visitado'}
                  name={'tipoEstabelecimento'}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatorio',
                    },
                  ]}
                >
                  <Select
                    onClick={() => {
                      scrollFieldToTop('tipoEstabelecimento');
                    }}
                    placeholder={'Selecione o tipo do estabelecimento'}
                    onChange={switchPanel}
                    disabled={formDisabled || mode === 'EDIT'}
                    placement={'bottomLeft'}
                  >
                    <Select.Option key={'ABATEDOURO'} value={'ABATEDOURO'}>
                      Abatedouro
                    </Select.Option>
                    <Select.Option key={'PROPRIEDADE'} value={'PROPRIEDADE'}>
                      Estabelecimento Rural
                    </Select.Option>
                    <Select.Option key={'RECINTO'} value={'RECINTO'}>
                      Recinto
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {showAbatedouroPanel && (
              <AbatedouroPanel form={form} formDisabled={formDisabled} />
            )}
            {showPropriedadePanel && (
              <PropriedadePanel
                form={form}
                formDisabled={formDisabled}
                showHeader
              />
            )}
            {showRecintoPanel && (
              <RecintoPanel form={form} formDisabled={formDisabled} />
            )}

            <Divider orientation='left'>Dados da Visita</Divider>

            <Row gutter={24}>
              <Space direction={'vertical'} style={{ width: '100%' }}>
                <Col xs={0} lg={0}>
                  <Form.Item
                    name={'listChavePrincipalVisitaPropriedadeRural'}
                    hidden
                  >
                    <Input hidden />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={24}>
                  <Form.Item
                    label={'Resumo das atividades realizadas'}
                    name={'resumo'}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <TextArea
                      showCount
                      rows={5}
                      maxLength={2000}
                      onClick={() => {
                        scrollFieldToTop('resumo');
                      }}
                      onBlur={(event) => {
                        dispatch(
                          FVERDraftActions.storeFVERDraft({
                            ...fverDraft!,
                            resumo: event.currentTarget.value,
                          })
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <CustomModal
                  centered
                  open={showModalProgramas}
                  title={'Programas e atividades'}
                  onCancel={() => {
                    setShowModalProgramas(false);
                    cleanModalProgramasEAtividades();
                  }}
                  width={750}
                  footer={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        style={{ width: '50%' }}
                        danger
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setShowModalProgramas(false);
                          cleanModalProgramasEAtividades();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        style={{ width: '50%' }}
                        type={'primary'}
                        icon={<CheckCircleOutlined />}
                        onClick={() => {
                          form.validateFields([
                            ['modalTipoChavePrimaria', 'id'],
                          ]);
                          form.validateFields([
                            'modalListaTipoChaveSecundaria',
                          ]);

                          const tipoChavePrimaria = form.getFieldValue(
                            'modalTipoChavePrimaria'
                          );

                          const listaTipoChaveSecundaria = form.getFieldValue(
                            'modalListaTipoChaveSecundaria'
                          );

                          if (!tipoChavePrimaria || !listaTipoChaveSecundaria)
                            return;
                          handleAddPrograma();
                        }}
                      >
                        Salvar
                      </Button>
                    </div>
                  }
                  maskClosable={false}
                  destroyOnClose
                >
                  <Form layout={'vertical'} form={form} autoComplete={'off'}>
                    <Row gutter={24}>
                      <Col xs={24} lg={24}>
                        <Form.Item
                          label={'Chave Principal'}
                          name={['modalTipoChavePrimaria', 'id']}
                          rules={[
                            {
                              required: true,
                              message: 'O campo é obrigatório',
                            },
                          ]}
                          valuePropName={'modaltipochaveprimaria'}
                        >
                          <Select
                            placeholder={'Selecione uma chave'}
                            onChange={handleSelectChavePrimariaChange}
                            placement={'bottomLeft'}
                          >
                            {tipoChavePrincipalFVER?.map((tipo) => (
                              <Select.Option
                                key={tipo.nome}
                                value={JSON.stringify(tipo)}
                              >
                                {tipo.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={0} lg={0}>
                        <Form.Item
                          name={['modalTipoChavePrimaria', 'nome']}
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={24}>
                        <Form.Item
                          label={'Chave Secundária'}
                          name={'modalListaTipoChaveSecundaria'}
                          valuePropName={'checked'}
                          rules={[
                            {
                              required: true,
                              message: 'O campo é obrigatório',
                            },
                          ]}
                        >
                          <Select
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div style={{ textAlign: 'right' }}>
                                  <Button
                                    icon={<CloseOutlined />}
                                    onClick={() =>
                                      setShowDropdownChaveSecundaria(false)
                                    }
                                    style={{ marginRight: '5px' }}
                                    type='primary'
                                  >
                                    Fechar
                                  </Button>
                                </div>
                              </>
                            )}
                            mode='multiple'
                            placeholder={'Selecione as chaves secundárias'}
                            onChange={handleSelectChaveSecundariaChange}
                            onDropdownVisibleChange={(visible) =>
                              setShowDropdownChaveSecundaria(visible)
                            }
                            open={showDropdownChaveSecundaria}
                            showSearch={false}
                            placement={'bottomLeft'}
                          >
                            {tipoChaveSecundariaFVER?.map((tipo) => (
                              <Select.Option
                                key={tipo.nome}
                                value={JSON.stringify(tipo)}
                              >
                                {tipo.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={8}>
                        <Form.Item
                          label={'Quantidade de apreensões'}
                          name={'modalQuantidadeApreensoes'}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={8}>
                        <Form.Item
                          label={'Quantidade de destruições'}
                          name={'modalQuantidadeDestruicoes'}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </CustomModal>

                <Col xs={24} sm={24}>
                  <Form.Item
                    validateTrigger={'onSubmit'}
                    name='tableChavesPrimarias'
                    rules={[
                      {
                        validator(_, value) {
                          if (dataSourceTableChavesPrimarias.length < 1)
                            return Promise.reject(
                              new Error(
                                'Deve ser informado pelo menos um programa'
                              )
                            );
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Table<ChavePrincipalFVER.Input>
                      size={'small'}
                      dataSource={dataSourceTableChavesPrimarias}
                      rowKey={(record) =>
                        record.tipoChavePrincipalVisitaPropriedadeRural.nome
                      }
                      title={() => {
                        return (
                          <Row justify={'space-between'}>
                            <Typography.Title
                              level={5}
                              style={{ color: 'white' }}
                            >
                              Programas e Atividades
                            </Typography.Title>
                            <Button
                              icon={<PlusOutlined />}
                              type={'primary'}
                              onClick={() => {
                                setShowModalProgramas(true);

                                scrollFieldToTop('tableChavesPrimarias');
                              }}
                              disabled={formDisabled}
                            />
                          </Row>
                        );
                      }}
                      columns={[
                        {
                          dataIndex: [
                            'tipoChavePrincipalVisitaPropriedadeRural',
                            'id',
                          ],
                          responsive: ['xs'],
                          render(_, chavePrimaria) {
                            return (
                              <Descriptions size={'small'} column={1} bordered>
                                <Descriptions.Item label={'Chave principal'}>
                                  {
                                    chavePrimaria
                                      .tipoChavePrincipalVisitaPropriedadeRural
                                      .nome
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label={'Motivo principal?'}>
                                  <Switch
                                    disabled={formDisabled}
                                    defaultChecked={false}
                                    checked={
                                      chavePrimaria.motivoPrincipal === 'SIM'
                                        ? true
                                        : false
                                    }
                                    onChange={(value) =>
                                      onChangeSwitchMotivoPrincipal(
                                        value,
                                        chavePrimaria
                                      )
                                    }
                                  />
                                </Descriptions.Item>
                                <Descriptions.Item label={'Chaves secundárias'}>
                                  {chavePrimaria.listChaveSecundariaVisitaPropriedadeRural?.map(
                                    (chave) => (
                                      <Tooltip
                                        title={
                                          chave
                                            .tipoChaveSecundariaVisitaPropriedadeRural
                                            ?.nome
                                        }
                                      >
                                        <Tag
                                          style={{
                                            maxWidth: '160px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                          }}
                                          key={
                                            chave
                                              .tipoChaveSecundariaVisitaPropriedadeRural
                                              ?.id
                                          }
                                        >
                                          {
                                            chave
                                              .tipoChaveSecundariaVisitaPropriedadeRural
                                              ?.nome
                                          }
                                        </Tag>
                                      </Tooltip>
                                    )
                                  )}
                                </Descriptions.Item>

                                <Descriptions.Item label='Ações'>
                                  {chavePrimariaTableActions(chavePrimaria)}
                                </Descriptions.Item>
                              </Descriptions>
                            );
                          },
                        },
                        {
                          dataIndex: [
                            'tipoChavePrincipalVisitaPropriedadeRural',
                            'nome',
                          ],
                          title: 'Chave primária',
                          responsive: ['sm'],
                        },
                        {
                          dataIndex: 'motivoPrincipal',
                          title: 'Motivo Principal?',
                          responsive: ['sm'],
                          render(
                            motivoPrincipal: ChavePrincipalFVER.Input['motivoPrincipal'],
                            chave: ChavePrincipalFVER.Input
                          ) {
                            return (
                              <Switch
                                disabled={formDisabled}
                                checked={
                                  motivoPrincipal === 'SIM' ? true : false
                                }
                                onChange={(value) =>
                                  onChangeSwitchMotivoPrincipal(value, chave)
                                }
                              />
                            );
                          },
                        },

                        {
                          dataIndex:
                            'listChaveSecundariaVisitaPropriedadeRural',
                          title: 'Chaves secundárias',
                          responsive: ['sm'],
                          width: 550,
                          render(
                            chaves: FormFVERType['listChavePrincipalVisitaPropriedadeRural'][0]['listChaveSecundariaVisitaPropriedadeRural']
                          ) {
                            return chaves?.map((chave) => (
                              <Tag
                                key={
                                  chave
                                    .tipoChaveSecundariaVisitaPropriedadeRural
                                    ?.id
                                }
                              >
                                {
                                  chave
                                    .tipoChaveSecundariaVisitaPropriedadeRural
                                    ?.nome
                                }
                              </Tag>
                            ));
                          },
                        },
                        {
                          dataIndex: 'id',
                          title: 'Ações',
                          responsive: ['sm'],
                          width: 60,
                          render(id, chavePrimaria) {
                            return chavePrimariaTableActions(chavePrimaria);
                          },
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <ServidoresPanel
                  form={form}
                  formDisabled={formDisabled}
                  listaServidores={listaServidores}
                  setListaServidores={setListaServidores}
                />

                <Divider orientation='left'>Assinatura do responsável</Divider>

                <Col xs={24} lg={24}>
                  <Form.Item
                    label={'Como foi realizada a assinatura?'}
                    name={'statusAssinatura'}
                    valuePropName={'checked'}
                    rules={[
                      { required: true, message: 'O campo é obrigatório' },
                    ]}
                    initialValue={form.getFieldValue('statusAssinatura')}
                  >
                    <Radio.Group
                      value={statusAssinaturaState}
                      onChange={(e) => {
                        setStatusAssinaturaState(e.target.value);
                      }}
                    >
                      <Space direction='vertical'>
                        {listaStatusAssinatura?.map((statusAssinatura) => (
                          <Radio
                            key={statusAssinatura}
                            value={statusAssinatura}
                          >
                            {StatusAssinatura.valueOf(statusAssinatura)}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {statusAssinaturaState ===
                  StatusAssinatura.keyOf(
                    StatusAssinatura.ASSINADO_DIGITALMENTE
                  ) && (
                  <Col span={24}>
                    <Form.Item
                      validateTrigger={'onSubmit'}
                      name='assinatura'
                      rules={[
                        {
                          validator(_, value) {
                            if (
                              statusAssinaturaState ===
                              StatusAssinatura.keyOf(
                                StatusAssinatura.ASSINADO_DIGITALMENTE
                              )
                            ) {
                              if (!assinaturaBase64Image)
                                return Promise.reject(
                                  new Error(
                                    'Deve ser informada a assinatura do produtor'
                                  )
                                );
                              return Promise.resolve();
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <SignatureWrapper
                        default={props.fver?.assinatura}
                        disabled={formDisabled}
                        onSaveSignature={() => {
                          form.validateFields(['assinatura']);
                        }}
                        onClearSignature={() => {
                          form.setFieldsValue({ assinatura: undefined });
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Space>
            </Row>

            <Divider />

            <Row justify={'end'} gutter={24}>
              {!params.local && online && (
                <Col xs={24}>
                  <Row justify={'end'}>
                    <Col>
                      <Form.Item>
                        <Switch
                          checked={shouldSaveOffline}
                          onChange={setShouldSaveOffline}
                        />
                        <span> Salvar offline</span>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  onClick={() =>
                    navigate(`${online ? '/visitas' : '/acoes-de-campo'}`)
                  }
                >
                  {formDisabled ? 'Voltar' : 'Cancelar'}
                </Button>
              </Col>
              {!formDisabled && (
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Salvar
                  </Button>
                </Col>
              )}
              {formDisabled && (
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                    onClick={() => {
                      navigate(`/visitas/edicao/${params.id}`);
                    }}
                  >
                    Editar
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </fieldset>
      )}

      {waitFVERDraftEvaluation && (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}

      <CustomModal
        title={null}
        footer={null}
        open={showModalTimePicker}
        bodyStyle={{ display: 'inline-block', float: 'left' }}
      >
        <div style={{ display: 'inline-block', float: 'left' }}>
          <StaticTimePicker
            defaultValue={moment.tz(
              moment().toLocaleString(),
              'America/Cuiaba'
            )}
            timezone='America/Cuiaba'
            localeText={{
              cancelButtonLabel: 'Cancelar',
              toolbarTitle: '',
            }}
            onAccept={(data: Moment | null) => {
              let hour =
                String(data?.get('hour')).padStart(2, '0') +
                ':' +
                String(data?.get('minute')).padStart(2, '0');
              form.setFieldValue('horaDaVisita', hour);

              setShowModalTimePicker(false);
            }}
            slots={{
              actionBar: undefined,
            }}
            onClose={() => {
              setShowModalTimePicker(false);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
}
