import useBadgeState from './useBadgeState';
import NotificacaoSistemaService from '../../sdk/services/SIZ-API/NotificacaoSistema.service';
import AuthorizationService from '../auth/Authorization.service';
import * as PermissoesActions from '../store/Permissoes.slice';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import useNavigatorStatus from './useNavigatorStatus';

export default function useStartUp() {
  const { online } = useNavigatorStatus();
  const dispatch = useDispatch();

  const { setNotificacoes } = useBadgeState();

  const init = useCallback(async () => {
    if (online) {
      await NotificacaoSistemaService.getByUsuario(
        //@ts-ignore
        AuthorizationService.getUsuarioSIZ()?.userName
      )
        .then((notificacoesSistema) => {
          if (notificacoesSistema && notificacoesSistema.length > 0) {
            notificacoesSistema.sort((n1, n2) => {
              if (n1.id > n2.id) return -1;
              if (n1.id < n2.id) return 1;

              return 0;
            });

            setNotificacoes(notificacoesSistema.length);
          }
        })
        .catch(() => {});

      dispatch(PermissoesActions.fetchAll());
    }
  }, [dispatch, setNotificacoes, online]);

  return {
    init,
  };
}
