import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditTwoTone,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useNavigate } from 'react-router-dom';
import syncFormRespostas from '../../../core/functions/syncFormRespostas';
import { SYNC_FORM_RESPOSTA_DONE } from '../../../sdk/@types/ServiceWorker.types';
import { ServiceIDBPayloadInput } from '../../../sdk/services/indexeddb/ServiceIDB';

import useSyncingState from '../../../core/hooks/useSyncingState';
import TroubleshootingButton from '../../components/TroubleshootingButton';
import { CustomModal } from '../../components/CustomModal';
import FormularioRespostaIDBService from '../../../sdk/services/indexeddb/FormularioRespostaIDB.service';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useDevOptions from '../../../core/hooks/useDevOptions';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import ButtonToForm from '../../components/ButtonToForm';
import FormCustomIDBService from '../../../sdk/services/indexeddb/FormCustomIDB.service';

interface ListFormRespostaOfflineProps {
  visible?: boolean;
}
type FormRespostaComErro = {
  erro: boolean;
  formularioNome?: string;
} & ServiceIDBPayloadInput;

export default function ListFormRespostaOffline(
  props: ListFormRespostaOfflineProps
) {
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [, setSyncing] = useState(false);
  const [listaFormRespostasComErro, setListaFormRespostasComErro] =
    useState<FormRespostaComErro[]>();
  const [listaFormRespostas, setListaFormRespostas] =
    useState<ServiceIDBPayloadInput[]>();

  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const { online } = useNavigatorStatus();
  const [fetching, setFetching] = useState(false);
  const [syncFormRespostaDone, setSyncFormRespostaDone] = useState<
    string | undefined
  >(undefined);

  const [showModalMotivoErro, setShowModalMotivoErro] =
    useState<boolean>(false);

  const [showModalDeleteFormRespostas, setShowModalDeleteFormRespostas] =
    useState<boolean>(false);
    
  const [motivoErro, setMotivoErro] = useState<string>();

  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, formResposta: ServiceIDBPayloadInput) => {
    setExpandedKey((prev: any) => {
      const newKey = formResposta.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };

  const { formResposta, setFormResposta } = useSyncingState();

  if (navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === SYNC_FORM_RESPOSTA_DONE) {
        fetchFormRespostasOffline();
        setSyncFormRespostaDone(event.data.message);
      }
    };

  const fetchFormRespostasOffline = useCallback(async () => {
    setFetching(true);
    const formularios: any = await FormCustomIDBService.getAll();
    const formularioMap = formularios.reduce((acc: any, form: any) => {
      if (form.payload.id && form.payload.nome) {
        acc[form.payload.id] = form.payload.nome;
      }
      return acc;
    }, {});

    await FormularioRespostaIDBService.getAllFromInputTable()
      .then(async (formRespostas) => {
        setListaFormRespostas(formRespostas);
        const enrichedFormRespostas = formRespostas.map((formResposta) => ({
          ...formResposta,
          erro: false,
          formularioNome:
            formResposta.id !== undefined
              ? formularioMap[formResposta.id]
              : 'Nome não disponível',
        }));

        let newList = [];
        let newFormResposta;
        let quantidadeFormRespostasSincronizados = 0;

        for (const formResposta of enrichedFormRespostas) {
          if (formResposta.status === 'SINCRONIZADO')
            quantidadeFormRespostasSincronizados++;

          if (!formResposta.payload.fver.codigoVerificador) {
            
            newFormResposta = { ...formResposta, erro: true };
          } else {
            newFormResposta =
              await FVERIDBService.getAllFromInputTableOnlyPayload().then(
                async (lista) => {
                  const fverLocal = lista.filter(
                    (fver) =>
                      fver.codigoVerificador ===
                      formResposta.payload.fver.codigoVerificador
                  );
                  if (fverLocal.length > 0)
                    return { ...formResposta, erro: false };
                  else {
                    if (online) {
                      return await FVERService.getByCodigoVerificador(
                        formResposta.payload.fver.codigoVerificador
                        //'bd69c284cefd8b9b2e5ba145be5e65f67750df07'
                      )
                        .then(async (fver) => {
                          if (fver) {
                            if (fver.content && fver.content?.length > 0) {
                              return { ...formResposta, erro: false };
                            } else return { ...formResposta, erro: true };
                          } else return { ...formResposta, erro: true };
                        })
                        .catch((e) => ({ ...formResposta, erro: true }));
                    } else {
                      return { ...formResposta, erro: true };
                    }
                  }
                }
              );
          }

          newList.push(newFormResposta);
        }

        if (quantidadeFormRespostasSincronizados >= 10)
          setShowModalDeleteFormRespostas(true);

        return newList;
      })
      .then(async (lista) => {
        setListaFormRespostasComErro(lista);
        setFetching(false);
      })
      .catch((e) => {
        notification.error({
          message: 'Houve um erro ao exibir os Formulários',
          description: `Motivo: ${e.message}`,
        });
        setListaFormRespostasComErro(undefined);

        if (e.code === 8) return;
        else {
          setError(e);
          throw e;
        }
      })
      .finally(() => setFetching(false));
  }, [online]);

  const deleteFormCustom = useCallback(
    async (formResposta) => {
      setSyncing(true);
      setFormResposta(true);
      if (formResposta.id) {
        await FormularioRespostaIDBService.delete(formResposta.id)
          .then(() => {
            fetchFormRespostasOffline();
          })
          .finally(() => {
            setSyncing(false);
            setFormResposta(false);
            navigate(`/acoes-de-campo`);
          });
      }
    },
    [fetchFormRespostasOffline, setFormResposta, navigate]
  );

  const deleteSynced = useCallback(async () => {
    const listFormRespostasSynced = listaFormRespostas?.filter(
      (formResposta) => formResposta.status === 'SINCRONIZADO'
    );

    const listFormRespostasNotSynced = listaFormRespostas?.filter(
      (formResposta) => formResposta.status !== 'SINCRONIZADO'
    );

    if (listFormRespostasSynced)
      for (const formResposta of listFormRespostasSynced) {
        //@ts-ignore
        await FormCustomIDBService.delete(formResposta.id);
      }

    setListaFormRespostas(listFormRespostasNotSynced);
    notification.success({
      message: 'Todas os formulários sincronizados foram removidos',
    });
  }, [listaFormRespostas]);

  useEffect(() => {}, [syncFormRespostaDone]);

  useEffect(() => {
    fetchFormRespostasOffline();
  }, [fetchFormRespostasOffline, formResposta]);

  useEffect(() => {
    setFetching(false);
  }, [listaFormRespostasComErro]);

  useEffect(() => {}, [formResposta]);

  if (error) throw error;

  const syncAllFormRespostas = async () => {
    if (listaFormRespostas) {
      for (const formResposta of listaFormRespostas) {
        await syncFormRespostas(formResposta?.id)
          .then((message) => {
            notification.success({
              message: message,
            });
          })
          .catch((e) => {
            notification.error({
              message: 'Não foi possível sincronizar',
              description: e.message,
            });
          });
      }
      await fetchFormRespostasOffline();
    }
  };

  return (
    <>
      <Row>
        <Space size={4} direction='vertical' style={{ width: '100%' }}>
          <Col hidden={!syncFormRespostaDone} xs={24}>
            <Alert
              message={syncFormRespostaDone}
              type='info'
              showIcon
              closable={false}
            ></Alert>
          </Col>
          <Col span={24}>
            <Table
              columns={[
                {
                  dataIndex: 'id',
                  width: idInTables ? 100 : 0,
                  render(_, formRespostasIdb) {
                    return (
                      idInTables && (
                        <>
                          <Typography.Text>
                            {`IDB: ${formRespostasIdb.id}`}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {`ID: ${
                              formRespostasIdb.payload.id
                                ? formRespostasIdb.payload.id
                                : '-'
                            }`}
                          </Typography.Text>
                        </>
                      )
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  render(_, formRespostasIdb) {
                    return (
                      <Space direction='vertical' size={0}>
                        <Space direction='horizontal' size={8} wrap={xs}>
                          {formRespostasIdb.payload.id && (
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{
                                    backgroundColor: 'rgba(131, 239, 156, 0.5)',
                                    borderRadius: '5px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                  }}
                                />
                              }
                              onClick={() =>
                                navigate(
                                  `/formRespostas/visualizar/${formRespostasIdb.payload?.id}`
                                )
                              }
                              style={{ fontWeight: 'bold', paddingLeft: 0 }}
                              type='text'
                            >
                              {formRespostasIdb.payload.numero}
                            </Button>
                          )}
                          <Tag
                            style={{
                              backgroundColor: `${
                                formRespostasIdb.status === 'NOVO'
                                  ? '#09f'
                                  : formRespostasIdb.status === 'SINCRONIZADO'
                                  ? '#2baf57'
                                  : '#ff4e4e'
                              }`,
                              color: 'white',
                            }}
                          >
                            {formRespostasIdb.status}
                          </Tag>

                          {formRespostasIdb.status === 'ERRO' && (
                            <Button
                              danger
                              onClick={() => {
                                setShowModalMotivoErro(true);
                                setMotivoErro(formRespostasIdb.motivoErro);
                              }}
                              style={{ paddingLeft: 0 }}
                              type='link'
                            >
                              <span style={{ borderBottom: '1px solid' }}>
                                Detalhes
                              </span>
                            </Button>
                          )}
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em', fontWeight: '500' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {moment(new Date(formRespostasIdb.date)).format(
                              'DD/MM/YYYY HH:mm'
                            )}
                          </Typography.Text>

                          <Typography.Text>
                            {formRespostasIdb.formularioNome}
                          </Typography.Text>
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {
                              formRespostasIdb.payload.municipioFiscalizado
                                ?.nome
                            }
                          </Typography.Text>

                          <Typography.Text>
                            {formRespostasIdb.payload.nomeEstabelecimento}
                          </Typography.Text>
                        </Space>
                      </Space>
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(id, formResposta) {
                    return (
                      <>
                        <Space>
                          {formResposta.status !== 'SINCRONIZADO' && (
                            <Button
                              icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                              onClick={() =>
                                navigate(`/formRespostas/edit/${id}/${'local'}`)
                              }
                              size={'small'}
                              type={'ghost'}
                            />
                          )}
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'ghost'}
                            danger
                            onClick={() => {
                              Modal.confirm({
                                content: (
                                  <>
                                    <Typography.Paragraph>
                                      Deseja proseguir?
                                    </Typography.Paragraph>
                                  </>
                                ),
                                onOk: async () => {
                                  try {
                                    await deleteFormCustom(formResposta);
                                    notification.success({
                                      message:
                                        'Formulário resposta removido com sucesso',
                                    });
                                  } catch (e) {
                                    throw e;
                                  }
                                },
                              });
                            }}
                          />
                        </Space>
                      </>
                    );
                  },
                },
              ]}
              dataSource={listaFormRespostasComErro}
              expandable={{
                columnWidth: '50px',
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <MinusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ) : (
                    <PlusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ),
                expandRowByClick: true,
                expandedRowKeys: [expandedKey],
                expandedRowRender: (formRespostaIdb) => (
                  <Space style={{ fontSize: '0.8em' }}>
                    {formRespostaIdb.erro && (
                      <Typography>
                        Formulário sem FVER{' '}
                        {
                          <ButtonToForm
                            alternativeBackgroundColor='rgb(222, 80, 80)'
                            alternateIcon={<WarningFilled />}
                            label='Solucionar'
                            route={`/formRespostas/edit/${
                              formRespostaIdb.id
                            }/${'local'}/${'problem'}`}
                            type='INCLUDE'
                          />
                        }
                      </Typography>
                    )}
                    {!formRespostaIdb.erro && (
                      <Typography.Text>
                        {!formRespostaIdb.payload.fver?.numero ? (
                          <ButtonToForm
                            alternativeBackgroundColor='rgba(111, 239, 253, 0.5)'
                            label={'FVER - Pendente'}
                            route={`/visitas/edicao/${formRespostaIdb.id}/local`}
                            type='VIEW'
                          />
                        ) : (
                          <ButtonToForm
                            label={`FVER - ${formRespostaIdb.payload.fver?.numero}`}
                            route={`/visitas/visualizar/${formRespostaIdb.payload.fver?.id}`}
                            type='VIEW'
                          />
                        )}
                      </Typography.Text>
                    )}
                  </Space>
                ),
                onExpand: onExpand,
                expandedRowClassName: () => 'expanded-row-siz',
              }}
              loading={formResposta || fetching}
              rowKey={'id'}
              size={'small'}
              showHeader={false}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      Formulários Respostas
                    </Typography.Title>

                    <Space>
                      <Popconfirm
                        title={'Deseja remover todos os sincronizados??'}
                        onConfirm={deleteSynced}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          size={'small'}
                          type={'primary'}
                          danger
                        />
                      </Popconfirm>
                      <Popconfirm
                        title={'Confirma a sincronização?'}
                        disabled={
                          !listaFormRespostas ||
                          listaFormRespostas.length === 0 ||
                          !online
                        }
                        onConfirm={syncAllFormRespostas}
                      >
                        <Button
                          icon={<CloudUploadOutlined />}
                          type={'primary'}
                          disabled={
                            !listaFormRespostas ||
                            listaFormRespostas.length === 0 ||
                            !online
                          }
                        />
                      </Popconfirm>

                      <TroubleshootingButton
                        component='FormRespostas'
                        data={listaFormRespostas}
                      />
                    </Space>
                  </Row>
                );
              }}
            />
          </Col>
        </Space>
      </Row>

      <CustomModal
        open={showModalMotivoErro}
        title={'Motivo'}
        footer={
          <Button
            style={{ width: '100%' }}
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => {
              setShowModalMotivoErro(false);
              setMotivoErro(undefined);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          {motivoErro}
        </Typography.Title>
      </CustomModal>

      <CustomModal
        open={showModalDeleteFormRespostas}
        title={'Aviso'}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '100%' }}
              type='default'
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalDeleteFormRespostas(false);
              }}
            >
              Fechar
            </Button>

            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<CloseOutlined />}
              onClick={async () => {
                await deleteSynced().finally(() =>
                  setShowModalDeleteFormRespostas(false)
                );
              }}
            >
              Remover
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>
          Notamos que você ainda está mantendo no seu dispositivo muitos TFRs
          que já foram sincronizados.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Deseja removê-los? Essa ação não irá afetar os TFRs no banco de dados.
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
