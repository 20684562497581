import {
  Affix,
  Button,
  Col,
  Divider,
  Row,
  Skeleton,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as LIAActions from '../../../core/store/LIA.slice';
import PrintLaudo from '../reports/PrintLIA';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { LIA } from '../../../sdk/@types';

export default function LIAView() {
  usePageTitle('Laudo');

  const { loading, setLoading } = useLoadingPage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });
  const laudo = useSelector((state: RootState) => state.lia.data);

  useEffect(() => {}, [params]);

  useEffect(() => {
    setLoading(true);
    // @ts-ignore
    if (!isNaN(Number(params.id))) {
      dispatch(LIAActions.fetchLIAById(Number(params.id)));
    }
  }, [params.id, dispatch, setLoading]);

  if (error) throw error;

  return (
    <Spin
      size='large'
      spinning={loading}
      style={{
        position: 'fixed',
        top: '20%',
        zIndex: '1',
      }}
    >
      <div
        style={{
          maxWidth: '90%',
          margin: 'auto',
        }}
      >
        <Affix offsetTop={80} className='printButton'>
          <Row gutter={12}>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  navigate('/home');
                }}
              >
                Voltar
              </Button>
            </Col>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  //window.print();
                  reactPrint();
                }}
                icon={<PrinterOutlined />}
              >
                Imprimir
              </Button>
            </Col>
          </Row>
        </Affix>
        <div ref={printRef}>
          <table
            className='tablePrintLaudo'
            style={{
              tableLayout: 'fixed',
              width: '100%',
            }}
          >
            <PrintLaudo laudo={laudo as LIA.Response} />
          </table>
        </div>
      </div>
    </Spin>
  );
}
