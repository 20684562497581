import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditTwoTone,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useNavigate } from 'react-router-dom';
import syncLIAs from '../../../core/functions/syncLIAs';
import { SYNC_LIA_DONE } from '../../../sdk/@types/ServiceWorker.types';
import { ServiceIDBPayloadInput } from '../../../sdk/services/indexeddb/ServiceIDB';

import useSyncingState from '../../../core/hooks/useSyncingState';
import TroubleshootingButton from '../../components/TroubleshootingButton';
import { CustomModal } from '../../components/CustomModal';
import LIAIDBService from '../../../sdk/services/indexeddb/LIAIDB.service';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useDevOptions from '../../../core/hooks/useDevOptions';
import ButtonToForm from '../../components/ButtonToForm';
import { ParecerLaudoInspecao } from '../../../core/enums/ParecerLaudoInspecao';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';

interface ListLIAOfflineProps {
  visible?: boolean;
}

type LIAComErro = {
  erro: boolean;
} & ServiceIDBPayloadInput;

export default function ListLIAOffline(props: ListLIAOfflineProps) {
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [, setSyncing] = useState(false);

  const [listaLIAsComErro, setlistaLIAsComErro] = useState<LIAComErro[]>();
  const [listaLIAs, setListaLIAs] = useState<ServiceIDBPayloadInput[]>();

  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const { online } = useNavigatorStatus();
  const [fetching, setFetching] = useState(false);
  const [syncLIADone, setSyncLIADone] = useState<string | undefined>(undefined);
  const [showModalMotivoErro, setShowModalMotivoErro] =
    useState<boolean>(false);
  const [showModalDeleteLIAs, setShowModalDeleteLIAs] =
    useState<boolean>(false);
  const [motivoErro, setMotivoErro] = useState<string>();

  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, lia: ServiceIDBPayloadInput) => {
    setExpandedKey((prev: any) => {
      const newKey = lia.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };
  //end control expandedKeys

  const { lia, setLIA } = useSyncingState();

  if (navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === SYNC_LIA_DONE) {
        fetchLIAsOffline();
        setSyncLIADone(event.data.message);
      }
    };

  const fetchLIAsOffline = useCallback(async () => {
    setFetching(true);

    await LIAIDBService.getAllFromInputTable()
      .then(async (lias) => {
        setListaLIAs(lias);

        let newList = [];
        let newLIA;
        let quantidadeLIAsSincronizados = 0;

        for (const lia of lias) {
          if (lia.status === 'SINCRONIZADO') quantidadeLIAsSincronizados++;

          if (!lia.payload.fver.codigoVerificador) {
            newLIA = { ...lia, erro: true };
          } else {
            newLIA =
              await FVERIDBService.getAllFromInputTableOnlyPayload().then(
                async (lista) => {
                  const fverLocal = lista.filter(
                    (fver) =>
                      fver.codigoVerificador ===
                      lia.payload.fver.codigoVerificador
                  );
                  if (fverLocal.length > 0) return { ...lia, erro: false };
                  else {
                    if (online) {
                      return await FVERService.getByCodigoVerificador(
                        lia.payload.fver.codigoVerificador
                        //'bd69c284cefd8b9b2e5ba145be5e65f67750df07'
                      )
                        .then(async (fver) => {
                          if (fver) {
                            if (fver.content && fver.content?.length > 0) {
                              return { ...lia, erro: false };
                            } else return { ...lia, erro: true };
                          } else return { ...lia, erro: true };
                        })
                        .catch((e) => ({ ...lia, erro: true }));
                    } else {
                      return { ...lia, erro: true };
                    }
                  }
                }
              );
          }

          newList.push(newLIA);
        }

        if (quantidadeLIAsSincronizados >= 10) setShowModalDeleteLIAs(true);

        return newList;
      })
      .then(async (lista) => {
        setlistaLIAsComErro(lista);
        setFetching(false);
      })
      .catch((e) => {
        notification.error({
          message: 'Houve um erro ao exibir os Laudos de Inspeção',
          description: `Motivo: ${e.message}`,
        });
        setlistaLIAsComErro(undefined);

        if (e.code === 8) return;
        else {
          setError(e);
          throw e;
        }
      })
      .finally(() => setFetching(false));
  }, [online]);

  const deleteLIA = useCallback(
    async (lia) => {
      setSyncing(true);
      setLIA(true);
      if (lia.id) {
        await LIAIDBService.delete(lia.id)
          .then(() => {
            fetchLIAsOffline();
          })
          .finally(() => {
            setSyncing(false);
            setLIA(false);
            navigate(`/acoes-de-campo`);
          });
      }
    },
    [fetchLIAsOffline, setLIA, navigate]
  );

  const deleteSynced = useCallback(async () => {
    const listLIAsSynced = listaLIAs?.filter(
      (lia) => lia.status === 'SINCRONIZADO'
    );

    const listLIAsNotSynced = listaLIAs?.filter(
      (lia) => lia.status !== 'SINCRONIZADO'
    );

    if (listLIAsSynced)
      for (const lia of listLIAsSynced) {
        //@ts-ignore
        await LIAIDBService.delete(lia.id);
      }

    setListaLIAs(listLIAsNotSynced);
    notification.success({
      message: 'Todas os LIAs sincronizados foram removidos',
    });
  }, [listaLIAs]);

  useEffect(() => {}, [syncLIADone]);

  useEffect(() => {
    setFetching(false);
  }, [listaLIAsComErro]);

  useEffect(() => {
    fetchLIAsOffline();
  }, [fetchLIAsOffline, lia]);

  if (error) throw error;

  return (
    <>
      <Row>
        <Space size={4} direction='vertical' style={{ width: '100%' }}>
          <Col hidden={!syncLIADone} xs={24}>
            <Alert
              message={syncLIADone}
              type='info'
              showIcon
              closable={false}
            ></Alert>
          </Col>
          <Col span={24}>
            <Table
              columns={[
                {
                  dataIndex: 'id',
                  width: idInTables ? 100 : 0,
                  render(_, liaIdb) {
                    return (
                      idInTables && (
                        <>
                          <Typography.Text>
                            {`IDB: ${liaIdb.id}`}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {`ID: ${
                              liaIdb.payload.id ? liaIdb.payload.id : '-'
                            }`}
                          </Typography.Text>
                        </>
                      )
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  render(_, liaIdb) {
                    return (
                      <Space direction='vertical' size={0}>
                        <Space direction='horizontal' size={8} wrap={xs}>
                          {liaIdb.payload.numero && (
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{
                                    backgroundColor: 'rgba(131, 239, 156, 0.5)',
                                    borderRadius: '5px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                  }}
                                />
                              }
                              onClick={() =>
                                navigate(
                                  `/lia/visualizar/${liaIdb.payload?.id}`
                                )
                              }
                              style={{ fontWeight: 'bold', paddingLeft: 0 }}
                              type='text'
                            >
                              {liaIdb.payload.numero}
                            </Button>
                          )}
                          <Tag
                            style={{
                              backgroundColor: `${
                                liaIdb.status === 'NOVO'
                                  ? '#09f'
                                  : liaIdb.status === 'SINCRONIZADO'
                                  ? '#2baf57'
                                  : '#ff4e4e'
                              }`,
                              color: 'white',
                            }}
                          >
                            {liaIdb.status}
                          </Tag>

                          {liaIdb.status === 'ERRO' && (
                            <Button
                              danger
                              onClick={() => {
                                setShowModalMotivoErro(true);
                                setMotivoErro(liaIdb.motivoErro);
                              }}
                              style={{ paddingLeft: 0 }}
                              type='link'
                            >
                              <span style={{ borderBottom: '1px solid' }}>
                                Detalhes
                              </span>
                            </Button>
                          )}
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em', fontWeight: '500' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {moment(
                              new Date(liaIdb.payload.dataParecer)
                            ).format('DD/MM/YYYY HH:mm')}
                          </Typography.Text>

                          <Typography.Text>
                            {liaIdb.payload.registro
                              ? 'REGISTRO'
                              : 'MANUTENÇÃO DO REGISTRO'}
                          </Typography.Text>
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {liaIdb.payload.municipioFiscalizado?.nome}
                          </Typography.Text>

                          <Typography.Text>
                            {liaIdb.payload.nomeEstabelecimento}
                          </Typography.Text>

                          <Typography.Text>
                            {`Parecer ${ParecerLaudoInspecao.valueOf(
                              liaIdb.payload.parecer
                            )}`}
                          </Typography.Text>
                        </Space>
                      </Space>
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(id, lia) {
                    return (
                      <>
                        <Space>
                          {lia.status !== 'SINCRONIZADO' && (
                            <Button
                              icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                              onClick={() =>
                                navigate(`/lia/edicao/${id}/${'local'}`)
                              }
                              size={'small'}
                              type={'ghost'}
                            />
                          )}
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'ghost'}
                            danger
                            onClick={() => {
                              Modal.confirm({
                                content: (
                                  <>
                                    <Typography.Paragraph>
                                      Deseja proseguir?
                                    </Typography.Paragraph>
                                  </>
                                ),
                                onOk: async () => {
                                  try {
                                    await deleteLIA(lia);
                                    notification.success({
                                      message: 'LIA removido com sucesso',
                                    });
                                  } catch (e) {
                                    throw e;
                                  }
                                },
                              });
                            }}
                          />
                        </Space>
                      </>
                    );
                  },
                },
              ]}
              dataSource={listaLIAsComErro}
              expandable={{
                columnWidth: '50px',
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <MinusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ) : (
                    <PlusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ),
                expandRowByClick: true,
                expandedRowKeys: [expandedKey],
                expandedRowRender: (liaIdb) => (
                  <Space style={{ fontSize: '0.8em' }}>
                    {liaIdb.erro && (
                      <Typography>
                        Laudo sem FVER{' '}
                        {
                          <ButtonToForm
                            alternativeBackgroundColor='rgb(222, 80, 80)'
                            alternateIcon={<WarningFilled />}
                            label='Solucionar'
                            route={`/lia/edicao/${
                              liaIdb.id
                            }/${'local'}/${'problem'}`}
                            type='INCLUDE'
                          />
                        }
                      </Typography>
                    )}
                    {!liaIdb.erro && (
                      <Typography.Text>
                        {!liaIdb.payload.fver?.numero ? (
                          <ButtonToForm
                            alternativeBackgroundColor='rgba(111, 239, 253, 0.5)'
                            label={'FVER - Pendente'}
                            route={`/visitas/edicao/${liaIdb.id}/local`}
                            type='VIEW'
                          />
                        ) : (
                          <ButtonToForm
                            label={`FVER - ${liaIdb.payload.fver?.numero}`}
                            route={`/visitas/visualizar/${liaIdb.payload.fver?.id}`}
                            type='VIEW'
                          />
                        )}
                      </Typography.Text>
                    )}
                  </Space>
                ),
                onExpand: onExpand,
                expandedRowClassName: () => 'expanded-row-siz',
              }}
              loading={lia || fetching}
              rowKey={'id'}
              size={'small'}
              showHeader={false}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      Laudo de Inspeção
                    </Typography.Title>

                    <Space>
                      <Popconfirm
                        title={'Deseja remover todos os sincronizados??'}
                        onConfirm={deleteSynced}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          size={'small'}
                          type={'primary'}
                          danger
                        />
                      </Popconfirm>
                      <Popconfirm
                        title={'Confirma a sincronização?'}
                        disabled={
                          !listaLIAs || listaLIAs.length === 0 || !online
                        }
                        onConfirm={async () => {
                          setSyncing(true);
                          setLIA(true);
                          await syncLIAs(true)
                            .then(async (message) => {
                              notification.success({
                                message: message,
                              });
                            })
                            .then(async () => {
                              await syncLIAs(true).finally(() => setLIA(false));
                            })
                            .then(async () => {
                              await fetchLIAsOffline();
                            })
                            .catch(async (e) => {
                              await fetchLIAsOffline();
                              notification.error({
                                message: 'Não foi possível sincronizar',
                                description: e.message,
                              });
                            })
                            .finally(() => {
                              setSyncing(false);
                              setLIA(false);
                              navigate(`/acoes-de-campo`);
                            });
                        }}
                      >
                        <Button
                          icon={<CloudUploadOutlined />}
                          type={'primary'}
                          disabled={
                            !listaLIAs || listaLIAs.length === 0 || !online
                          }
                        />
                      </Popconfirm>

                      <TroubleshootingButton component='LIA' data={listaLIAs} />
                    </Space>
                  </Row>
                );
              }}
            />
          </Col>
        </Space>
      </Row>

      <CustomModal
        open={showModalMotivoErro}
        title={'Motivo'}
        footer={
          <Button
            style={{ width: '100%' }}
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => {
              setShowModalMotivoErro(false);
              setMotivoErro(undefined);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          {motivoErro}
        </Typography.Title>
      </CustomModal>

      <CustomModal
        open={showModalDeleteLIAs}
        title={'Aviso'}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '100%' }}
              type='default'
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalDeleteLIAs(false);
              }}
            >
              Fechar
            </Button>

            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<CloseOutlined />}
              onClick={async () => {
                await deleteSynced().finally(() =>
                  setShowModalDeleteLIAs(false)
                );
              }}
            >
              Remover
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>
          Notamos que você ainda está mantendo no seu dispositivo muitos LIAs
          que já foram sincronizados.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Deseja removê-los? Essa ação não irá afetar os LIAs no banco de dados.
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
