import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PublicFVVPrintView from '../features/FVV/PublicFVVPrintView';

export default function FVVPublicPrintWrapper() {
  usePageTitle('FVV');

  return (
    <>
      <ErrorBoundary component='o formulário de vigilância'>
        <PublicFVVPrintView />
      </ErrorBoundary>
    </>
  );
}
