import { Col, Drawer, Form, Input, Row, Switch } from 'antd';
import { useCallback, useState } from 'react';
import BreakpointsTable from './BreakpointsTable';
import useDevOptions from '../../core/hooks/useDevOptions';
import PermissionsService from '../../core/auth/Permissions.service';
import { ArrowDownOutlined } from '@ant-design/icons';
import { operatingSytem } from '../../core/functions/operatingSytem';

export default function DevTools() {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { idInTables, useMock, setIdInTables, setUseMock } = useDevOptions();

  const handleIdInTablesChange = useCallback(
    (newState) => setIdInTables(newState),
    [setIdInTables]
  );

  const handleUseMockChange = useCallback(
    (newState) => setUseMock(newState),
    [setUseMock]
  );

  return (
    <>
      {PermissionsService.dev && (
        <div className='drawer-dev-tools' onClick={() => setShowDrawer(true)}>
          <ArrowDownOutlined />
        </div>
      )}
      <Drawer
        closable={false}
        destroyOnClose
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        placement='top'
        size='default'
        title='Dev Tools'
      >
        <Form layout='vertical'>
          <Row>
            <Col xs={24} sm={4}>
              <Form.Item label='Tela'>
                <BreakpointsTable />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item label='Exibir IDs'>
                <Switch
                  checked={idInTables}
                  defaultChecked={idInTables}
                  onChange={handleIdInTablesChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item label='Usar Mock'>
                <Switch
                  checked={useMock}
                  defaultChecked={useMock}
                  onChange={handleUseMockChange}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={4}>
              <Form.Item label='OS'>{operatingSytem()}</Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
