import { Servidor } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class ServidorIDBService extends ServiceIDB {
  static DB_STORE_SERVIDOR = 'servidor';
  static DB_STORE_SERVIDOR_INDEX_CPF_NAME = 'servidor_index_cpf';
  static DB_STORE_SERVIDOR_INDEX_CPF_KEY = 'payload.pessoa.cpfCnpj';

  static DB_STORE_SERVIDOR_INDEX_MATRICULA_NAME = 'servidor_index_matricula';
  static DB_STORE_SERVIDOR_INDEX_MATRICULA_KEY = 'payload.matricula';

  static async getFirst(): Promise<ServiceIDBPayload> {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(ServidorIDBService.DB_STORE_SERVIDOR)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByCpf(cpf: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_SERVIDOR,
      this.DB_STORE_SERVIDOR_INDEX_CPF_NAME,
      cpf
    );
  }

  static async getByMatricula(cpf: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_SERVIDOR,
      this.DB_STORE_SERVIDOR_INDEX_MATRICULA_NAME,
      cpf
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(ServidorIDBService.DB_STORE_SERVIDOR);
    return list;
  }

  static async getAllPayload(): Promise<Servidor.Summary[]> {
    let list: Servidor.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllServidor(lista: Servidor.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_SERVIDOR, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_SERVIDOR);
  }
}

export default ServidorIDBService;
