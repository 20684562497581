import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ViewFVV from '../features/FVV/ViewFVV';

export default function VigilanciaVeterinariaViewView() {
  usePageTitle('Visualizando FVV');

  const { setLoading, setMode } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setMode('VIEW');
  }, [setLoading, setMode]);

  return (
    <>
      <ErrorBoundary component='formulário'>
        <ViewFVV />
      </ErrorBoundary>
    </>
  );
}
