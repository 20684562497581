import { Spin } from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ListRecebimentoRevenda from '../features/SOVIV/ListRecebimentoRevenda';
import { useEffect } from 'react';

export default function RecebimentoRevendaList() {
  usePageTitle('SIZ - Lista de Recebimento de Insumos Pecuários - SVO IV');
  const { setLoading, setMode, setFirstOpening, loading } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='a lista de Recebimento de Insumos Pecuários'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <ListRecebimentoRevenda />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
