import { useEffect, useState } from 'react';

export default function useNavigatorStatus() {
  const [online, setOnline] = useState(window.navigator.onLine);

  const offlineListener = () => {
    if (window.navigator.onLine) {
      setOnline(true);
    } else {
      setOnline(false);
    }
  };

  useEffect(() => {
    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', offlineListener);
  });

  return {
    online,
  };
}
