import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFormVIN from '../../../core/hooks/useFormVIN';
import { FormVIN } from '../../../sdk/@types';
import FormVINIDBService from '../../../sdk/services/indexeddb/FormVINIDB.service';
import FormFormVIN from './FormFormVIN';

function EditFormVIN() {
  const { loading } = useLoadingPage();

  const [error, setError] = useState<Error>();

  const { fetchFormVINById, formVIN } = useFormVIN();

  const [formVINOffline, setFormVINOffline] = useState<FormVIN.Input>();

  const params = useParams<{ id: string; local?: string }>();

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      if (!params.local) {
        fetchFormVINById(Number(params.id)).catch((e) => {
          setError(new Error(e.message));
        });
      } else {
        FormVINIDBService.getById(Number(params.id))
          .then(setFormVINOffline)
          .catch((e) => {
            setError(new Error(e.message));
          });
      }
    }
  }, [fetchFormVINById, params]);

  if (error) throw error;

  const prepareData = useCallback((formVIN) => {
    return {
      ...formVIN,
      dataInspecao: moment(formVIN.dataInspecao),
    };
  }, []);

  if (!formVIN && !formVINOffline) return <Skeleton active />;

  return (
    <>
      <Typography.Title level={2}>
        Editando FormVIN
        {formVIN ? ` nº ${formVIN?.id}` : ' offline'}
      </Typography.Title>

      <Divider />

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormFormVIN
          formVIN={prepareData(!params.local ? formVIN : formVINOffline)}
        />
      </Spin>
    </>
  );
}

export default EditFormVIN;
