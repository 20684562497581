export enum TipoUsuario {
  ADMINISTRATIVO_SVO = 'Administrativo SVO',
  AFEDAF1_SVO = 'Afedaf 1',
  LABORATORIO = 'Laboratório',
  SERVICO_DE_INSPECAO = 'Serviço de Inspeção',
  UNIVERSIDADE = 'Universidade',
  VETERINARIO_SVO = 'Veterinário SVO',
}
export namespace TipoUsuario {
  export function keys(): string[] {
    return Object.keys(TipoUsuario).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoUsuario[key as keyof typeof TipoUsuario];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoUsuario).indexOf(
      value as unknown as TipoUsuario
    );

    const key = Object.keys(TipoUsuario)[indexOfS];

    return key;
  }
}
