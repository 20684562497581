import generateHash from '../../../core/functions/generateHash';
import { TermoFiscalizacao } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class TermoFisacalizacaoService extends Service {
  static getAll(
    query: TermoFiscalizacao.Query
  ): Promise<TermoFiscalizacao.Paginated> {
    const queryString = generateQueryString(query);

    return this.Http.get<TermoFiscalizacao.Paginated>(
      '/termos-fiscalizacao'.concat(queryString)
    ).then(this.getData);
  }

  static add(formTFRRequest: TermoFiscalizacao.Request) {
    let tfrDTO: TermoFiscalizacao.Request = {
      ...formTFRRequest,
      codigoVerificador: generateHash(formTFRRequest),
    };

    if (tfrDTO.revenda.endereco)
      tfrDTO = {
        ...tfrDTO,
        revenda: {
          ...tfrDTO.revenda,
          endereco: {
            ...tfrDTO.revenda.endereco,
            municipio: {
              ...tfrDTO.revenda.endereco.municipio,
              //@ts-ignore
              codgIBGE: String(tfrDTO.revenda.endereco.municipio.id),
            },
          },
        },
      };

    return this.Http.post<TermoFiscalizacao.Request>(
      '/termos-fiscalizacao',
      tfrDTO
    ).then(this.getData);
  }

  static async update(id: number, data: TermoFiscalizacao.Request) {
    return this.Http.put<TermoFiscalizacao.Request>(
      `/termos-fiscalizacao/${id}`,
      data
    ).then(this.getData);
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`/termos-fiscalizacao/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get<TermoFiscalizacao.Response>(
      `/termos-fiscalizacao/${id}`
    ).then(this.getData);
  }

  static getById_PublicAccess(
    termoFiscalizacaoId: number
  ): Promise<TermoFiscalizacao.Response> {
    return fetch(
      `${Service.API_URL}/termos-fiscalizacao/${termoFiscalizacaoId}/publico`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(async (response) => {
      if (response.ok) return await response.json();
    });
  }

  static getAll_PublicAccess(
    query: TermoFiscalizacao.Query
  ): Promise<TermoFiscalizacao.Paginated> {
    return fetch(
      `${Service.API_URL}/termos-fiscalizacao/publico`.concat(
        generateQueryString(query)
      ),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(async (response) => {
      if (response.ok) return await response.json();
    });
  }
}

export default TermoFisacalizacaoService;
