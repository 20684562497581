import { Veterinario } from '../../@types/Veterinario';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class VeterinarioService extends Service {
  static getByCRMV(crmv: string): Promise<Veterinario.Summary> {
    return this.Http.get<Veterinario.Summary>(`/veterinarios/cpf/${crmv}`).then(
      this.getData
    );
  }

  static getByCpf(cpf: string): Promise<Veterinario.Summary> {
    return this.Http.get<Veterinario.Summary>(`/veterinarios/cpf/${cpf}`).then(
      this.getData
    );
  }

  static getAll(query: Veterinario.Query): Promise<Veterinario.Summary[]> {
    const queryString = generateQueryString(query);
    return this.Http.get<Veterinario.Summary[]>(
      '/veterinarios'.concat(queryString),
      { timeout: 600000 }
    ).then(this.getData);
  }
}

export default VeterinarioService;
