import localforage from 'localforage';
import { FVV } from '../../sdk/@types';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import FVVIDBService from '../../sdk/services/indexeddb/FVVIDB.service';
import Service from '../../sdk/services/SIZ-API/Service';
import AuthorizationService from '../auth/Authorization.service';

export default async function syncFVVs(manually?: boolean): Promise<string> {
  const promise = new Promise<string>(async (resolve, reject) => {
    let errors = 0;
    let fvvsWithFVERsUnsynced = 0;
    let fvvsSynced = 0;

    return await FVVIDBService.getAll()
      .then(async (listaFVVsOffline) => {
        const saveFVVAsync = async () => {
          for (const fvvOffline of listaFVVsOffline) {
            if (
              fvvOffline.status === 'SINCRONIZADO' ||
              fvvOffline.status === 'NAO FINALIZADO'
            ) {
              //@TODO preguiça de negar a condição. Arrumar depois
            } else if (fvvOffline.payload.visitaPropriedadeRural.id !== -1) {
              let fvvDTO: FVV.Input;
              let contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected =
                undefined;
              let presencaDeSuinosAsselvajadosCorrected = undefined;

              if (fvvOffline.payload.vigilanciaSuideos) {
                //contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected
                if (
                  fvvOffline.payload.vigilanciaSuideos
                    .contatoDiretoDeSuinosDomesticosComAsselvajados === 'SIM' ||
                  fvvOffline.payload.vigilanciaSuideos
                    .contatoDiretoDeSuinosDomesticosComAsselvajados === 'NAO'
                ) {
                  contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected =
                    fvvOffline.payload.vigilanciaSuideos
                      .contatoDiretoDeSuinosDomesticosComAsselvajados;
                } else {
                  if (
                    fvvOffline.payload.vigilanciaSuideos
                      .contatoDiretoDeSuinosDomesticosComAsselvajados === true
                  )
                    contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected =
                      'SIM';
                  else if (
                    fvvOffline.payload.vigilanciaSuideos
                      .contatoDiretoDeSuinosDomesticosComAsselvajados === false
                  )
                    contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected =
                      'NAO';
                }

                //contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected
                if (
                  fvvOffline.payload.vigilanciaSuideos
                    .presencaDeSuinosAsselvajados === 'SIM' ||
                  fvvOffline.payload.vigilanciaSuideos
                    .presencaDeSuinosAsselvajados === 'NAO'
                ) {
                  presencaDeSuinosAsselvajadosCorrected =
                    fvvOffline.payload.vigilanciaSuideos
                      .presencaDeSuinosAsselvajados;
                } else {
                  if (
                    fvvOffline.payload.vigilanciaSuideos
                      .presencaDeSuinosAsselvajados === true
                  )
                    presencaDeSuinosAsselvajadosCorrected = 'SIM';
                  else if (
                    fvvOffline.payload.vigilanciaSuideos
                      .presencaDeSuinosAsselvajados === false
                  )
                    presencaDeSuinosAsselvajadosCorrected = 'NAO';
                }
              }

              fvvDTO = {
                ...fvvOffline.payload,
                visitaPropriedadeRural: {
                  ...fvvOffline.payload.visitaPropriedadeRural,
                  dataDaVisita: undefined,
                },
                vigilanciaSuideos: {
                  ...fvvOffline.payload.vigilanciaSuideos,
                  //fix para um bug antigo
                  ContatoDiretoDeSuinosDomesticosComAsselvajados: undefined,
                  contatoDiretoDeSuinosDomesticosComAsselvajados:
                    contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected,
                  presencaDeSuinosAsselvajados:
                    presencaDeSuinosAsselvajadosCorrected,
                },
              };

              console.log('fvvDTO', fvvDTO);

              await localforage.getItem('token').then(async (token) => {
                let access_token;
                if (token) access_token = token;
                else access_token = AuthorizationService.getAccessToken();

                if (!access_token) {
                  throw new Error('Sessão expirada');
                }

                await fetch(`${Service.API_URL}/vigilancias-veterinarias`, {
                  method: 'POST',
                  body: JSON.stringify(fvvDTO),
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`,
                  },
                })
                  .then(async (response) => {
                    if (response.status === 401) {
                      throw new Error('Sessão expirada');
                    }

                    if (response.ok) {
                      return await response.json().then(async (fvvResponse) => {
                        let newFVV: ServiceIDBPayloadInput = {
                          ...fvvOffline,
                          payload: {
                            ...fvvOffline.payload,
                            id: fvvResponse.id,
                            numero: fvvResponse.numero,
                          },
                          status: 'SINCRONIZADO',
                        };

                        await FVVIDBService.update(
                          //@ts-ignore
                          fvvOffline.id,
                          newFVV
                        );

                        fvvsSynced++;
                      });
                    } else {
                      return await response.json().then(async (fvvResponse) => {
                        let motivoErro = fvvResponse.detail;

                        errors = errors + 1;
                        let newFVV: ServiceIDBPayloadInput = {
                          ...fvvOffline,
                          status: 'ERRO',
                          motivoErro,
                        };

                        return await FVVIDBService.update(
                          //@ts-ignore
                          fvvOffline.id,
                          newFVV
                        );
                      });
                    }
                  })
                  .catch((e) => {
                    throw new Error(
                      'Não conseguimos conectar com o servidor. Tente novamente mais tarde'
                    );
                  });
              });
            } else {
              fvvsWithFVERsUnsynced++;
            }
          }
        };

        await saveFVVAsync();
      })
      .then(async () => {
        if (errors === 0 && fvvsWithFVERsUnsynced === 0 && fvvsSynced === 0)
          return resolve('Sem FVVs para sincronizar');
        else if (errors === 0 && fvvsWithFVERsUnsynced === 0 && fvvsSynced > 0)
          return resolve('Todos os FVVs foram sincronizados com sucesso');
        else if (errors === 0 && fvvsWithFVERsUnsynced > 0 && fvvsSynced > 0)
          return resolve(
            'Alguns FVVs não foram sincronizados. Verifique se o FVER correspondente já está sincronizado'
          );
        else if (errors === 0 && fvvsWithFVERsUnsynced > 0 && fvvsSynced === 0)
          return resolve(
            'Nenhum FVV foi sincronizado. Verifique se o FVER correspondente já está sincronizado'
          );
        else {
          return reject(
            'A sincronização dos FVVs foi finalizada com erros. Alguns FVVs não puderam ser sincronizadas. Revise-os e tente novamente'
          );
        }
      })
      .catch((e) => {
        return reject(e.message);
      });
  });

  return await promise;
}
