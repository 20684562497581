import { TipoChaveSecundariaFVER } from '../../@types';
import Service from './Service';

class TipoChaveSecundariaFVERService extends Service {
  static getAllActive() {
    return this.Http.get<TipoChaveSecundariaFVER.Summary[]>(
      '/tipoChaveSecundariaVisitaPropriedadeRural',
      { timeout: 600000 }
    ).then(this.getData);
  }
}

export default TipoChaveSecundariaFVERService;
