import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import RecebimentosRevendasService from '../../sdk/services/SIZ-API/RecebimentosRevendas.service';
import { RecebimentosRevendas } from '../../sdk/@types';
import localforage from 'localforage';
import AuthorizationService from '../auth/Authorization.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface RecebimentosRevendasState {
  recebimentoRevenda: RecebimentosRevendas.Response | null;
  recebimentosRevendas: RecebimentosRevendas.Paginated | null;
  loading: LoadingState;
  fetching: boolean;
  errorMessage?: string;
  query: RecebimentosRevendas.Query;
  items: RecebimentosRevendas.Response['itens'];
}

const initialState: RecebimentosRevendasState = {
  recebimentoRevenda: null,
  recebimentosRevendas: null,
  loading: 'idle',
  fetching: false,
  errorMessage: undefined,
  query: { page: 0, size: 10 },
  items: [],
};

export const insertRecebimentosRevendas = createAsyncThunk(
  'recebimentosRevendas/insert',
  async (
    recebimentosRevendasRequest: RecebimentosRevendas.Request,
    { rejectWithValue }
  ) => {
    try {
      if (navigator.onLine) {
        return await RecebimentosRevendasService.add(
          recebimentosRevendasRequest
        );
      } else {
        if (navigator.serviceWorker) {
          const swRegistration = await navigator.serviceWorker.ready;
          //@ts-ignore
          if (swRegistration.sync) {
            localforage.setItem('token', AuthorizationService.getAccessToken());
            const registration = await navigator.serviceWorker.ready;
            //@ts-ignore
            registration.sync.register(SYNC_TERMOFISCALIZACAO_NEEDED);
          }
        }
        return RecebimentosRevendasService.add(recebimentosRevendasRequest);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCnpjAndNumero = createAsyncThunk(
  'recebimentosRevendas/fetchCnpjAndNumero',
  async (
    { cnpj, numero }: { cnpj: string; numero: number },
    { rejectWithValue }
  ) => {
    try {
      return await RecebimentosRevendasService.getByCnpjAndNumero(cnpj, numero);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Erro desconhecido';
      return rejectWithValue(errorMessage);
    }
  }
);

export const filterRecebimentosRevendas = createAsyncThunk(
  'recebimentosRevendas/filterRecebimentosRevendas',
  async (query: RecebimentosRevendas.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const RecebimentosRevendas = await RecebimentosRevendasService.getAll(
        query
      );
      return RecebimentosRevendas;
    } catch (e) {
      rejectWithValue(new Error('Não foi possível buscar as Notas Fiscais'));
    } finally {
      dispatch(setFetching(false));
    }
  }
);

export const fetchRecebimentosRevendasById = createAsyncThunk<
  RecebimentosRevendas.Response,
  number
>(
  'recebimentosRevendas/fetchRecebimentosRevendasById',
  async (id: number, { rejectWithValue }) => {
    return await RecebimentosRevendasService.getById(id).catch(rejectWithValue);
  }
);

export const updateRecebimentosRevendas = createAsyncThunk(
  'recebimentosRevendas/updateRecebimentosRevendas',
  async (
    {
      id,
      recebimentosRevendas,
    }: { id: number; recebimentosRevendas: RecebimentosRevendas.Request },
    { rejectWithValue }
  ) => {
    return await RecebimentosRevendasService.update(
      id,
      recebimentosRevendas
    ).catch(rejectWithValue);
  }
);

const RecebimentosRevendasSlice = createSlice({
  name: 'recebimentosRevendas',
  initialState,
  reducers: {
    clearRecebimentosRevendasState: (state) => {
      return initialState;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<RecebimentosRevendas.Query>) {
      state.query = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertRecebimentosRevendas.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(insertRecebimentosRevendas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.RecebimentoRevenda = action.payload;
      })
      .addCase(insertRecebimentosRevendas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchCnpjAndNumero.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCnpjAndNumero.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.RecebimentosRevendas = action.payload;
      })
      .addCase(fetchCnpjAndNumero.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(filterRecebimentosRevendas.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterRecebimentosRevendas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.recebimentosRevendas = action.payload;
        state.fetching = false;
      })
      .addCase(filterRecebimentosRevendas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(updateRecebimentosRevendas.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateRecebimentosRevendas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.recebimentoRevenda = action.payload;
      })
      .addCase(updateRecebimentosRevendas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchRecebimentosRevendasById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchRecebimentosRevendasById.fulfilled,
        (
          state,
          action: PayloadAction<RecebimentosRevendas.Response | undefined>
        ) => {
          state.loading = 'fulfilled';
          //@ts-ignore
          state.recebimentoRevenda = action.payload;
        }
      )
      .addCase(fetchRecebimentosRevendasById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      });
  },
});
export const {
  clearRecebimentosRevendasState,
  setFetching,
  setQuery,
} = RecebimentosRevendasSlice.actions;
export default RecebimentosRevendasSlice.reducer;
