import { Skeleton, Spin, Typography, Divider } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFormVIN from '../../../core/hooks/useFormVIN';
import { FormVIN } from '../../../sdk/@types';
import FormFormVIN from './FormFormVIN';

export default function ViewFormVIN() {
  const { loading } = useLoadingPage();

  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();

  const { fetchFormVINById, formVIN } = useFormVIN();

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      fetchFormVINById(Number(params.id)).catch((e) => {
        setError(new Error(e.message));
      });
    }
  }, [fetchFormVINById, params]);

  if (error) throw error;

  const prepareData = useCallback((formVIN: FormVIN.Input) => {
    return {
      ...formVIN,
      dataInspecao: moment(formVIN.dataInspecao),
    };
  }, []);

  if (!formVIN) return <Skeleton active />;

  return (
    <>
      <Typography.Title level={2}>
        Form VIN
        {formVIN ? ` nº ${formVIN?.numeroInspecao}` : ' offline'}
      </Typography.Title>

      <Divider />

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormFormVIN formVIN={prepareData(formVIN)} />
      </Spin>
    </>
  );
}
