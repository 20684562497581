import { Servidor } from '../../@types/Servidor';
import Service from './Service';

class ServidorService extends Service {
  static getByCpf(cpf: string) {
    return this.Http.get<Servidor.Summary>(`/servidores/cpf/${cpf}`).then(
      this.getData
    );
  }

  static getByMatricula(matricula: string) {
    return this.Http.get<Servidor.Summary>(
      `/servidores/matricula/${matricula}`
    ).then(this.getData);
  }

  static getAllActive() {
    return this.Http.get<Servidor.Summary[]>('/servidores', {
      timeout: 600000,
    }).then(this.getData);
  }
}

export default ServidorService;
