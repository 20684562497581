import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';
import { Propriedade } from '../../@types/Propriedade';
import Service from './Service';

class PropriedadeService extends Service {
  static getById(propriedadeId: number) {
    return this.Http.get<Propriedade.Summary>(
      `/propriedades/${propriedadeId}`
    ).then(this.getData);
  }

  static getByProdutorCpf(cpf: string) {
    return this.Http.get<Propriedade.Summary[]>(
      `propriedades/produtor/${removeNonNumericCharactersFromString(cpf)}`
    ).then(this.getData);
  }

  static getByMunicipio(municipioCodgIbge: string) {
    return this.Http.get<Propriedade.Summary[]>(
      `propriedades/municipio/${municipioCodgIbge}`,
      { timeout: 600000 }
    ).then(this.getData);
  }

  static countByMunicipio(municipioCodgIbge: string) {
    return this.Http.get<number>(
      `propriedades/municipio/${municipioCodgIbge}/count`
    ).then(this.getData);
  }
}

export default PropriedadeService;
