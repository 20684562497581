import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import useVeterinario from '../../core/hooks/useVeterinario';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';

interface VeterinarioProps {
  form: FormInstance;
  formDisabled: boolean;
}

export default function VeterinarioPanel(props: VeterinarioProps) {
  const { online } = useNavigatorStatus();
  const { firstOpening, setFirstOpening, mode, setLoading } = useLoadingPage();
  const [veterinarioSelecionado, setVeterinarioSelecionado] =
    useState<boolean>(false);
  const {
    veterinario,
    fetchExistingVeterinarioByCRMV,
    fetchExistingVeterinarioByCPF,
  } = useVeterinario();
  const [fetchingVeterinario, setFetchingVeterinario] =
    useState<boolean>(false);

  const fetchVeterinarioByCRMV = useCallback(
    async (crmv: string) => {
      if (!crmv) {
        return;
      }
      setLoading(true);
      setFetchingVeterinario(true);

      await fetchExistingVeterinarioByCRMV(crmv)
        .then((veterinario) => {
          setVeterinarioSelecionado(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setFetchingVeterinario(false);
          throw e;
        })
        .finally(() => {
          setLoading(false);
          setFetchingVeterinario(false);
        });
    },
    [fetchExistingVeterinarioByCRMV, setLoading]
  );

  const fetchVeterinarioByCPF = useCallback(
    async (crmv: string) => {
      setLoading(true);
      setFetchingVeterinario(true);

      await fetchExistingVeterinarioByCPF(crmv)
        .then((veterinario) => {
          setVeterinarioSelecionado(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setFetchingVeterinario(false);
          throw e;
        })
        .finally(() => {
          setLoading(false);
          setFetchingVeterinario(false);
        });
    },
    [fetchExistingVeterinarioByCPF, setLoading]
  );

  const removeVeterinario = useCallback(() => {
    setVeterinarioSelecionado(false);
    props.form.setFieldsValue({
      veterinario: undefined,
    });
  }, [props]);

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      fetchVeterinarioByCRMV(
        props.form.getFieldValue(['veterinario', 'numeroConselho'])
      ).then(() => {
        setLoading(false);
      });
    }
  }, [mode]);

  useEffect(() => {
    if (!veterinarioSelecionado)
      if (
        props.form.getFieldValue(['veterinario', 'numeroConselho']) &&
        props.form.getFieldValue(['veterinario', 'pessoa', 'cpfCnpj'])
      )
        setVeterinarioSelecionado(true);
  }, []);

  useEffect(() => {
    if (
      !props.form.getFieldValue(['veterinario', 'numeroConselho']) &&
      !props.form.getFieldValue(['veterinario', 'pessoa', 'cpfCnpj'])
    )
      return;
    if (!online && mode === 'EDIT') return;
    if (mode === 'VIEW') return;

    if (!fetchingVeterinario) {
      if (!veterinario) {
        if (!firstOpening) {
          /* notification.info({
            message: `Não foi possível encontrar nenhum veterinário`,
          }); */
        }
      } else {
        props.form.setFieldsValue({
          veterinario: veterinario,
        });

        setVeterinarioSelecionado(true);

        setFirstOpening(false);
        notification.success({
          message: `Veterinário ${veterinario.pessoa?.nome} encontrado`,
        });
      }
    }
    setLoading(fetchingVeterinario);
  }, [fetchingVeterinario, setLoading]);

  return (
    <>
      <Divider orientation='left'>Dados do Veterinário</Divider>

      <Row gutter={24} align={'bottom'}>
        <Col xs={0} lg={0}>
          <Form.Item label={'Id'} name={['veterinario', 'id']} hidden>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Cpf do veterinario'}
            name={['veterinario', 'pessoa', 'cpfCnpj']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
              {
                validator: async (_, codigoVeterinario) => {
                  if (!codigoVeterinario) {
                    return Promise.reject();
                  }
                  if (isNaN(Number(codigoVeterinario))) {
                    return Promise.reject(
                      new Error('O CPF precisa ser um número')
                    );
                  }
                  const a = props.form.getFieldValue(['veterinario']);

                  if (!a)
                    return Promise.reject('Nenhum veterinário foi selecionado');
                },
              },
            ]}
          >
            <Input.Search
              placeholder={'Informe o CPF do veterinario'}
              disabled={veterinarioSelecionado || props.formDisabled}
              autoComplete='off'
              allowClear={false}
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeVeterinario}
                />
              }
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onSearch={(cpfVeterinario) => {
                if (!cpfVeterinario) removeVeterinario();
                else fetchVeterinarioByCPF(cpfVeterinario);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'CRMV do veterinario'}
            name={['veterinario', 'numeroConselho']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
              {
                validator: async (_, codigoVeterinario) => {
                  if (!codigoVeterinario) {
                    return Promise.reject();
                  }
                  if (isNaN(Number(codigoVeterinario))) {
                    return Promise.reject(
                      new Error('O CRMV precisa ser um número')
                    );
                  }
                  const a = props.form.getFieldValue(['veterinario']);

                  if (!a)
                    return Promise.reject('Nenhum veterinário foi selecionado');
                },
              },
            ]}
          >
            <Input.Search
              placeholder={'Informe o CRMV do veterinario'}
              disabled={veterinarioSelecionado || props.formDisabled}
              autoComplete='off'
              allowClear={false}
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeVeterinario}
                />
              }
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onSearch={(crmvVeterinario) => {
                if (!crmvVeterinario) removeVeterinario();
                else fetchVeterinarioByCRMV(crmvVeterinario);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item label={'Nome'} name={['veterinario', 'pessoa', 'nome']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label={'Unidade'} name={['veterinario', 'ule', 'nome']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Matrícula SVO'}
            name={['veterinario', 'matricula']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Tipo Emitente'}
            name={['veterinario', 'tipoEmitente', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
