import { PrivateRoute } from './PrivateRoute';

interface Props {
  component: React.ComponentType;
  isprivate?: boolean;
}

export const CustomRoute: React.FC<Props> = ({
  component: ReactComponent,
  isprivate,
}) => {
  if (isprivate) {
    return <PrivateRoute component={ReactComponent} />;
  } else {
    return <ReactComponent />;
  }
};
