import {
  Button,
  Col,
  Row,
  message,
  Progress,
  Divider,
  Card,
  Form,
  Input,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import { fetchFormRespostas } from '../../../core/store/FormResposta.slice';
import { exportToXLSX } from '../../../sdk/utils/xlsxUtil';
import FormRespostasService from '../../../sdk/services/SIZ-API/formularioResposta.service';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import Title from 'antd/lib/typography/Title';

import * as FormCustomActions from '../../../core/store/FormCustom.slice';

import { CustomModal } from '../../components/CustomModal';
import DebounceSelect from '../../components/DebounceSelect'; // Certifique-se de ajustar o caminho conforme necessário

export default function ListFormRespostas() {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const [modalVisible, setModalVisible] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const [selectedForm, setSelectedForm] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error] = useState<Error>();

  const formularios = useSelector(
    (state: RootState) => state.formCustom.allForms
  );

  const query = useSelector((state: RootState) => state.formCustom.query);
  const fetching = useSelector((state: RootState) => state.formCustom.fetching);

  if (!window.navigator.onLine)
    throw new Error(
      'Não é possível visualizar a lista de formulários no modo offline'
    );
  if (error) throw error;

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(setFirstOpening(true));
  }, [dispatch]);
  useEffect(() => {
    dispatch(FormCustomActions.clearFormCustomState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(FormCustomActions.filterFormCustoms(query));
  }, [dispatch, query]);

  const handleFormChange = (value: {
    label: string;
    value: string | number;
  }) => {
    form.setFieldsValue({ formulario: value.toString() });
    if (value && value.value) {
      setSelectedForm(value.toString());
    }
  };

  const handleExport = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      setModalVisible(true);
      setProgress(0);
      setModalMessage('Gerando arquivo...');
      setProgressVisible(false);

      const responses = await dispatch(
        fetchFormRespostas(values.formulario)
      ).unwrap();

      const formattedData = FormRespostasService.formatDataForXLSX(responses);

      const selectedFormId = Number(values.formulario);
      const formName =
        formularios?.find((f) => f.id === selectedFormId)?.nome || 'formulario';

      if (formattedData.length > 0) {
        setProgressVisible(true);
      }

      const success = exportToXLSX(formattedData, formName);

      if (success) {
        setProgress(50);
        setProgress(70);
        setProgress(100);
        setModalMessage('Exportação realizada com sucesso!');
      } else {
        setModalMessage(
          'Nenhum dado para exportar. O formulário não possui respostas.'
        );
      }
    } catch (error) {
      console.error('Error during export:', error);
      setModalMessage('Erro ao exportar respostas');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Divider orientation='left'>
          <Title level={3}>Exportar Respostas dos Formulários</Title>
        </Divider>
        <br />
        <Col span={24}>
          <Form form={form} onFinish={handleExport} layout='vertical'>
            <Form.Item
              label='Selecione um formulário'
              name='formulario'
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione um formulário',
                },
              ]}
            >
              <Input.Group compact style={{ display: 'flex' }}>
                <DebounceSelect
                  style={{ flex: 1 }}
                  placeholder='Selecione um formulário'
                  staticOptions={
                    formularios
                      ? [...formularios]
                          .filter((form) => form.nome != null)
                          .sort((a, b) =>
                            (a.nome || '').localeCompare(b.nome || '')
                          )
                          .map((form) => ({
                            label: form.nome,
                            value: form.id?.toString(),
                          }))
                      : []
                  }
                  onChange={handleFormChange}
                />
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={fetching || loading}
                  style={{ marginLeft: 8 }}
                >
                  Buscar
                </Button>
              </Input.Group>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <CustomModal
        centered
        visible={modalVisible}
        closable={false}
        footer={[
          <Button key='close' onClick={() => setModalVisible(false)}>
            Fechar
          </Button>,
        ]}
        maskClosable={false}
        title={'Gerando Arquivo Xslx'}
      >
        <Card bordered={false}>
          {progressVisible && (
            <Progress
              percent={progress}
              status={progress === 100 ? 'normal' : 'active'}
            />
          )}
          <div style={{ marginTop: 16 }}>{modalMessage}</div>
        </Card>
      </CustomModal>
    </>
  );
}
