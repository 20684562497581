export enum FinalidadeExploracaoBovinosEBubalinos {
  CORTE = 'Corte',
  LEITE = 'Leite',
  MISTA = 'Mista',
}

export namespace FinalidadeExploracaoBovinosEBubalinos {
  export function keys(): string[] {
    return Object.keys(FinalidadeExploracaoBovinosEBubalinos).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return FinalidadeExploracaoBovinosEBubalinos[
      key as keyof typeof FinalidadeExploracaoBovinosEBubalinos
    ];
  }
  export function keyOf(value: string) {
    const indexOfS = Object.values(
      FinalidadeExploracaoBovinosEBubalinos
    ).indexOf(value as unknown as FinalidadeExploracaoBovinosEBubalinos);

    const key = Object.keys(FinalidadeExploracaoBovinosEBubalinos)[indexOfS];

    return key;
  }
}
