import {
  Col,
  FormInstance,
  Input,
  Row,
  Form,
  notification,
  Button,
  Divider,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { coordinatesDDtoDMS } from '../../core/functions/coordinatesUtil';
import scrollFieldToTop from '../../core/functions/scrollFieldToTop';
import useAbatedouro from '../../core/hooks/useAbatedouro';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import useSetor from '../../core/hooks/useSetor';
import CoordenadasPanel from './CoordenadasPanel';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';
import { DeleteOutlined } from '@ant-design/icons';

interface AbatedouroProps {
  form: FormInstance;
  formDisabled: boolean;
}

export default function AbatedouroPanel(props: AbatedouroProps) {
  const { online } = useNavigatorStatus();
  const { mode, firstOpening, setFirstOpening, setLoading } = useLoadingPage();

  const { abatedouro, fetching, fetchExistingAbatedouroByCNPJ } =
    useAbatedouro();
  const [abatedouroSelecionado, setAbatedouroSelecionado] =
    useState<boolean>(false);

  const { fetchSetoresByMunicipio } = useSetor();

  const fetchAbatedouroByCnpj = useCallback(
    async (cnpj: string) => {
      if (!cnpj) return;
      if (isNaN(Number(cnpj))) return;

      setLoading(true);
      try {
        await fetchExistingAbatedouroByCNPJ(cnpj)
          .catch((e) => {
            setLoading(false);
            throw e;
          })
          .finally(() => {
            setLoading(false);
          });
      } finally {
      }
    },
    [fetchExistingAbatedouroByCNPJ, setLoading]
  );

  const fetchSetores = useCallback(async () => {
    if (abatedouro?.pessoa?.endereco?.municipio.codgIBGE) {
      setLoading(true);
      try {
        await fetchSetoresByMunicipio(
          '' + abatedouro.pessoa.endereco.municipio.codgIBGE
        )
          .catch(() => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } finally {
        setLoading(false);
      }
    }
  }, [fetchSetoresByMunicipio, abatedouro, setLoading]);

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      fetchAbatedouroByCnpj(
        props.form.getFieldValue(['abatedouro', 'pessoa', 'cpfCnpj'])
      ).then(() => {
        setLoading(false);
      });
    }
  }, [mode]);

  useEffect(() => {
    if (!props.form.getFieldValue(['abatedouro', 'pessoa', 'cpfCnpj'])) return;
    if (!online && mode === 'EDIT') return;
    if (mode === 'VIEW') return;

    if (!fetching) {
      if (!abatedouro) {
        if (!firstOpening)
          notification.info({
            message: `Não foi possível encontrar nenhum abatedouro`,
          });
      } else {
        props.form.setFieldsValue({
          abatedouro: abatedouro,
        });

        setAbatedouroSelecionado(true);
        fetchSetores();

        notification.success({
          message: `Abatedouro ${abatedouro.pessoa.codigo}-${abatedouro.pessoa.nome} selecionado`,
        });
        setFirstOpening(false);
      }
    }
    setLoading(fetching);
  }, [fetching]);

  const removeAbatedouro = useCallback(() => {
    setAbatedouroSelecionado(false);
    props.form.setFieldsValue({
      abatedouro: undefined,
    });
  }, [props.form]);

  const updateCoordsOnForm = (coords: GeolocationCoordinates) => {
    if (!abatedouro) {
      notification.error({
        message: 'Nenhuma propriedade selecionada',
      });
      return;
    }

    const coordenadaGeografica = coordinatesDDtoDMS(
      coords.latitude,
      coords.longitude
    );

    props.form.setFieldsValue({
      abatedouro: {
        ...abatedouro,
        pessoa: {
          ...props.form.getFieldValue(['abatedouro', 'pessoa']),
          coordenadaGeografica: {
            latGrau: coordenadaGeografica.latGrau,
            latMin: coordenadaGeografica.latMin,
            latSeg: coordenadaGeografica.latSeg,
            longGrau: coordenadaGeografica.longGrau,
            longMin: coordenadaGeografica.longMin,
            longSeg: coordenadaGeografica.longSeg,
            orientacaoLatitude: coordenadaGeografica.orientacaoLatitude,
            orientacaoLongitude: coordenadaGeografica.orientacaoLongitude,
          },
        },
      },
    });
  };

  return (
    <>
      <Divider orientation='left'>Dados do abatedouro</Divider>

      <Row gutter={24} align={'bottom'}>
        <Col xs={0} lg={0}>
          <Form.Item label={'Id'} name={['abatedouro', 'id']} hidden>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'CNPJ do abatedouro'}
            name={['abatedouro', 'pessoa', 'cpfCnpj']}
            validateTrigger={'onSearch'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
              {
                validator: async (_, codigoAbatedouro) => {
                  if (!codigoAbatedouro) {
                    return Promise.reject();
                  }
                  if (isNaN(Number(codigoAbatedouro))) {
                    return Promise.reject(
                      new Error('O cnpj precisa ser um número')
                    );
                  }
                },
              },
            ]}
          >
            <Input.Search
              width={'100%'}
              placeholder={'Informe o cnpj do abatedouro'}
              allowClear={false}
              loading={fetching}
              style={{ width: '100%' }}
              disabled={
                abatedouroSelecionado || props.formDisabled || mode === 'EDIT'
              }
              inputMode='numeric'
              addonAfter={
                <Button
                  disabled={props.formDisabled || mode === 'EDIT'}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={removeAbatedouro}
                />
              }
              onClick={() => {
                scrollFieldToTop('abatedouro_pessoa_cpfCnpj');
              }}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onSearch={async (cnpj) => {
                if (!cnpj) removeAbatedouro();
                else return await fetchAbatedouroByCnpj(cnpj);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item label={'Nome'} name={['abatedouro', 'pessoa', 'nome']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label={'Código'} name={['abatedouro', 'pessoa', 'codigo']}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Numero de Inspeção'}
            name={['abatedouro', 'numeroInspecao']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            hidden
            label={'Tipo de Inspeção'}
            name={['abatedouro', 'tipoInspecao', 'id']}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={'Tipo de Inspeção'}
            name={['abatedouro', 'tipoInspecao', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Form.Item
          name={['abatedouro', 'pessoa', 'endereco', 'municipio', 'id']}
          hidden
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['abatedouro', 'pessoa', 'endereco', 'municipio', 'uf']}
          hidden
        >
          <Input disabled />
        </Form.Item>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Município'}
            name={['abatedouro', 'pessoa', 'endereco', 'municipio', 'nome']}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item hidden name={['setor', 'id']}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={'Setor'}
            name={['setor', 'nome']}
            /* rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]} */
          >
            <Input disabled />
            {/* <Select
              onChange={handleSelectSetorChange}
              loading={fetching}
              placeholder={`${
                listaSetor
                  ? 'Selecione o setor'
                  : 'Selecione o abatedouro primeiro'
              }`}
              placement={'bottomLeft'}
              disabled={props.formDisabled}
            >
              {listaSetor?.map((setor) => (
                <Select.Option key={setor.id} value={JSON.stringify(setor)}>
                  <Card
                    style={{
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {setor.numero} - {setor.nome}
                  </Card>
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
        </Col>
      </Row>

      {
        <CoordenadasPanel
          form={props.form}
          formDisabled={props.formDisabled}
          pathToCoordenada={['abatedouro', 'pessoa']}
          onAcceptCoords={(coords) => {
            updateCoordsOnForm(coords);
          }}
        />
      }
    </>
  );
}
