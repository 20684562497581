import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingPageState {
  firstOpening: boolean;
  loading: boolean;
  mode: 'CREATE' | 'EDIT' | 'VIEW';
  databaseStatus: 'VOID' | 'SYNCING' | 'FINISHED';
}

const initialState: LoadingPageState = {
  firstOpening: true,
  loading: false,
  mode: 'CREATE',
  databaseStatus: 'VOID',
};

const LoadingPageSlice = createSlice({
  initialState,
  name: 'loadingPage',
  reducers: {
    setFirstOpening(state, action: PayloadAction<boolean>) {
      state.firstOpening = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMode(state, action: PayloadAction<'CREATE' | 'EDIT' | 'VIEW'>) {
      state.mode = action.payload;
    },

    setDataBaseStatus(
      state,
      action: PayloadAction<'VOID' | 'SYNCING' | 'FINISHED'>
    ) {
      state.databaseStatus = action.payload;
    },
  },
});

export const { setDataBaseStatus, setFirstOpening, setLoading, setMode } =
  LoadingPageSlice.actions;

const LoadingPageReducer = LoadingPageSlice.reducer;
export default LoadingPageReducer;
