import { Alert, Button, Empty, Input, Spin, Table, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FVV, FormularioRespostas, LIA } from '../../../sdk/@types';
import { FVERFormulariosAnexos } from '../../../sdk/@types/FVERFormulariosAnexos';
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import FormCustomService from '../../../sdk/services/SIZ-API/formCustom.service';
import removeAcentos from '../../../core/functions/removeAcentos';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import { CustomModal } from '../../components/CustomModal';
import { Link } from 'react-router-dom';

interface FormulariosAnexosFVERProps {
  idFver: number;
  local?: boolean;
  numero?: string;
  onClose?: () => void;
  open: boolean;
  closed?: boolean;
}

enum FormularioType {
  FVV = 'FVV',
  LIA = 'LIA',
  CUSTOM = 'CUSTOM',
}

interface Formulario {
  formulario?: FVV.Summary | LIA.Summary | FormularioRespostas.Summary;
  label: string;
  routToNew: string;
  routToPrint?: string | undefined;
  type: FormularioType;
}

export default function FormulariosAnexosFVER(
  props: FormulariosAnexosFVERProps
) {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(false);

  const [formulariosAnexos, setFormulariosAnexos] =
    useState<FVERFormulariosAnexos.Response>();
  const [formularios, setFormularios] = useState<Formulario[]>([]);
  const [formulariosFiltrados, setFormulariosFiltrados] = useState<
    Formulario[]
  >([]);

  const fverClosed = useCallback(() => {
    return props.closed;
  }, [props.closed]);

  const linkNewFVV = useCallback((): string => {
    return `/vigilancias/cadastro/${props.idFver}${
      props.local ? '/local' : ''
    }`;
  }, [props]);

  const linkNewLIA = useCallback((): string => {
    return `/lia/cadastro/${props.idFver}${props.local ? '/local' : ''}`;
  }, [props]);

  const linkNewFormCustom = useCallback((id: number): string => {
    return `/formRespostas/cadastro/${id}`;
  }, []);

  const linkPrintViewLIA = useCallback((id: number): string => {
    return `/lia/visualizar/${id}`;
  }, []);

  const linkPrintViewFVV = useCallback((id: number): string => {
    return `/vigilancias/visualizarP/${id}`;
  }, []);

  const linkPrintViewFormCustom = useCallback((id: number): string => {
    return `/formRespostas/visualizar/${id}`;
  }, []);

  const respostaFormulario = useCallback(
    (idFormulario?: number): FormularioRespostas.Summary | undefined => {
      if (idFormulario && formulariosAnexos) {
        const result = formulariosAnexos.formulariosRespostas?.filter(
          (resposta) => {
            return resposta.formulario!.id === idFormulario;
          }
        );
        if (result && result.length > 0) return result[0];
        else return undefined;
      } else return undefined;
    },
    [formulariosAnexos]
  );

  const populateFormulariosAnexosInfo = useCallback(async () => {
    // TODO buscar formulários OFFLINE
    const formulariosCustomizados = !props.local
      ? await FormCustomService.getAll({
          page: 0,
          ativo: true,
        })
      : [];

    let formulariosNaoPreenchidos: Formulario[] = [];
    let formulariosPreenchidos: Formulario[] = [];
    let novoFormulario: Formulario;

    novoFormulario = {
      formulario: formulariosAnexos?.fvv,
      label: 'Formulário de Vigilância Veterinária',
      routToNew: linkNewFVV(),
      routToPrint: formulariosAnexos?.fvv
        ? linkPrintViewFVV(formulariosAnexos?.fvv.id)
        : undefined,
      type: FormularioType.FVV,
    };

    if (!fverClosed()) {
      if (formulariosAnexos?.fvv) {
        formulariosPreenchidos.push(novoFormulario);
      } else {
        formulariosNaoPreenchidos.push(novoFormulario);
      }
    } else if (novoFormulario.formulario) {
      formulariosNaoPreenchidos.push(novoFormulario);
    }

    novoFormulario = {
      formulario: formulariosAnexos?.lia,
      label: 'Laudo de Inspeção',
      routToNew: linkNewLIA(),
      routToPrint: formulariosAnexos?.lia
        ? linkPrintViewLIA(formulariosAnexos?.lia.id!)
        : undefined,
      type: FormularioType.LIA,
    };

    if (!fverClosed()) {
      if (formulariosAnexos?.lia) {
        formulariosPreenchidos.push(novoFormulario);
      } else {
        formulariosNaoPreenchidos.push(novoFormulario);
      }
    } else if (novoFormulario.formulario) {
      formulariosNaoPreenchidos.push(novoFormulario);
    }

    let routToNew = undefined;
    let routToPrint = undefined;
    let resposta: FormularioRespostas.Summary | undefined = undefined;

    if (formulariosAnexos && formulariosAnexos.formulariosRespostas) {
      formulariosAnexos.formulariosRespostas.forEach((formulario) => {
        resposta = formulario;
        routToNew = linkNewFormCustom(formulario.id!);
        routToPrint = resposta
          ? linkPrintViewFormCustom(resposta.id!)
          : undefined;

        novoFormulario = {
          formulario: resposta,
          label: formulario.formulario!.nome
            ? formulario.formulario!.nome
            : '-',
          routToNew,
          routToPrint,
          type: FormularioType.CUSTOM,
        };

        if (!fverClosed()) {
          formulariosPreenchidos.push(novoFormulario);
        } else if (novoFormulario.formulario) {
          formulariosPreenchidos.push(novoFormulario);
        }
      });

      formulariosPreenchidos = formulariosPreenchidos.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
    }

    let jaEstaPreenchido = undefined;
    formulariosCustomizados.forEach((formulario) => {
      //verifica se já está na lista de preenchidos
      jaEstaPreenchido = undefined;
      jaEstaPreenchido = formulariosPreenchidos.filter((f) => {
        if (f.type === FormularioType.CUSTOM) {
          //@ts-ignore
          if (f.formulario.formulario.id === formulario.id) return f;
          else return false;
        }
        return false;
      });

      resposta = respostaFormulario(formulario.id);
      routToNew = linkNewFormCustom(formulario.id!);
      routToPrint = resposta
        ? linkPrintViewFormCustom(resposta.id!)
        : undefined;

      novoFormulario = {
        formulario: resposta,
        label: formulario.nome ? formulario.nome : '-',
        routToNew,
        routToPrint,
        type: FormularioType.LIA,
      };

      if (!jaEstaPreenchido || jaEstaPreenchido.length === 0)
        if (!fverClosed()) {
          formulariosNaoPreenchidos.push(novoFormulario);
        } else if (novoFormulario.formulario) {
          formulariosNaoPreenchidos.push(novoFormulario);
        }
    });

    formulariosNaoPreenchidos = formulariosNaoPreenchidos.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );

    let todosformulariosAnexos = [];
    todosformulariosAnexos.push(...formulariosPreenchidos);
    todosformulariosAnexos.push(...formulariosNaoPreenchidos);

    setFormularios(todosformulariosAnexos);
    setFormulariosFiltrados(todosformulariosAnexos);
  }, [
    fverClosed,
    linkNewFVV,
    linkNewLIA,
    linkNewFormCustom,
    linkPrintViewFVV,
    linkPrintViewLIA,
    linkPrintViewFormCustom,
    formulariosAnexos,
    props.local,
    respostaFormulario,
  ]);

  const fetchFormulariosAnexosOnline = async (id: number) => {
    setFetching(true);
    return await FVERService.getFormulariosAssociados(id)
      .then(setFormulariosAnexos)
      .finally(() => setFetching(false));
  };

  useEffect(() => {
    if (!props.local) fetchFormulariosAnexosOnline(props.idFver);
  }, [props]);

  useEffect(() => {
    populateFormulariosAnexosInfo();
  }, [populateFormulariosAnexosInfo]);

  interface DescriptionItemProps {
    formulario: Formulario;
  }

  const formularioAnexo: React.FC<DescriptionItemProps> = (buttonProps) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <Typography.Text>{buttonProps.formulario.label}</Typography.Text>
        </div>

        <div style={{ textAlign: 'right', width: '60px' }}>
          {!buttonProps.formulario.routToPrint && (
            <Button
              disabled={fverClosed()}
              icon={<PlusOutlined />}
              onClick={() => navigate(buttonProps.formulario.routToNew)}
              type='primary'
            />
          )}
          {buttonProps.formulario.routToPrint && (
            <Button
              icon={<PrinterOutlined />}
              onClick={() => {
                if (buttonProps.formulario.routToPrint)
                  navigate(buttonProps.formulario.routToPrint);
              }}
              type='default'
            />
          )}
        </div>
      </div>
    );
  };

  const filter = useCallback(
    (value?: string) => {
      let forms: Formulario[] = formularios;

      if (value) {
        forms = formularios.filter((formulario) =>
          removeAcentos(formulario.label.toLowerCase()).includes(
            removeAcentos(value.toLowerCase())
          )
        );
      }

      setFormulariosFiltrados(forms);
    },

    [formularios]
  );

  return (
    <CustomModal
      centered
      closable={false}
      destroyOnClose
      footer={
        <Button
          onClick={props.onClose}
          style={{ width: '100%' }}
          type='primary'
        >
          Voltar
        </Button>
      }
      keyboard={true}
      maskClosable={true}
      onCancel={props.onClose}
      open={props.open}
      width={550}
      title={`FVER ${props.numero ? props.numero : ' - Rascunho'}`}
    >
      <Spin spinning={fetching}>
        {fverClosed() && props.local && (
          <>
            <Alert
              description={
                <span>
                  <Link to={'/visitas'}>Clique aqui</Link> para continuar para a
                  listagem de FVERs
                </span>
              }
              message='Não é possível incluir um formulário para um FVER já sincronizado'
              showIcon
              type='warning'
            />
            <br />
          </>
        )}

        {fverClosed() && !props.local && (
          <>
            <Alert
              message='Não é possível incluir um formulário para um FVER cancelado'
              showIcon
              type='warning'
            />
            <br />
          </>
        )}

        <Table
          columns={[
            {
              dataIndex: 'label',
              title: (
                <Input
                  placeholder='Pesquisar'
                  onChange={(event) => filter(event.currentTarget.value)}
                  style={{ borderRadius: '20px' }}
                ></Input>
              ),
              render(label, formulario) {
                return <>{formularioAnexo({ formulario })}</>;
              },
            },
          ]}
          dataSource={formulariosFiltrados}
          locale={{
            emptyText: <Empty description='Nenhum formulário cadastrado' />,
          }}
          key={'label'}
          size='small'
          title={undefined}
        />

        {/* {fverPossuiFormulariosAssociados() &&
        formularios.map((formulario, id) => {
          return (
            <>
              {formularioAnexo({ formulario })}
              {id !== formularios.length - 1 && (
                <Divider style={{ marginBottom: '5px', marginTop: '5px' }} />
              )}
            </>
          );
        })} */}
      </Spin>
    </CustomModal>
  );
}
