import AbatedouroIDBService from './AbatedouroIDB.service';
import DBConfig from './DB.config';
import FormVINIDBService from './FormVINIDB.service';
import MunicipioIDBService from './MunicipioIDB.service';
import ProdutorIDBService from './ProdutorIDB.service';
import PropriedadeIDBService from './PropriedadeIDB.service';
import RecintoIDBService from './RecintoIDB.service';
import ServidorIDBService from './ServidorIDB.service';
import SetorIDBService from './SetorIDB.service';
import TipoChavePrincipalFVERIDBService from './TipoChavePrincipalFVERIDB.service';
import TipoChaveSecundariaFVERIDBService from './TipoChaveSecundariaFVERIDB.service';
import TipoChavePrincipalTFRIDBService from './TipoChavePrincipalTFRIDB.service';
import VeterinarioIDBService from './VeterinarioIDB.service';
import FVERIDBService from './FVERIDB.service';
import ServidorService from '../../services/SIZ-API/Servidor.service';
import TipoChavePrincipalFVERService from '../SIZ-API/TipoChavePrincipalFVER.service';
import TipoChaveSecundariaFVERService from '../SIZ-API/TipoChaveSecundariaFVER.service';
import VeterinarioService from '../../services/SIZ-API/Veterinario.service';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
import FormINIDBService from './FormINIDB.service';
import { store } from '../../../core/store/index';
import { setDataBaseStatus } from '../../../core/store/LoadingPage.slice';
import FVVIDBService from './FVVIDB.service';
import TipoChavePrincipalTFRService from '../SIZ-API/TipoChavePrincipalTFR.service';
import RevendaIDBService from './RevendasIDB.service';
import TFRIDBService from './TFRIDB.service';
import EmpresaIntegradoraIDBService from './EmpresaIntegradoraIDB.service';
import EmpresaIntegradoraService from '../SIZ-API/EmpresaIntegradora.service';
import LIAIDBService from './LIAIDB.service';
import FormCustomService from '../SIZ-API/formCustom.service';
import FormCustomIDBService from './FormCustomIDB.service';
import { FormCustom } from '../../@types';
import FormularioRespostasIDBService from './FormularioRespostaIDB.service';

export enum InitializerStatus {
  CREATED = 'CREATED',
  ERROR = 'ERROR',
  STILL_VALID = 'STILL_VALID',
  UPDATED = 'UPDATED',
}

export async function initializeIndexedDB_v1() {
  let db: IDBDatabase;
  const request = indexedDB.open(DBConfig.DB_NAME, DBConfig.DB_VERSION);

  let versions: {
    version: number;
    func: (db: IDBDatabase) => any;
  }[] = [
    {
      version: 13,
      func: version13,
    },
    {
      version: 14,
      func: version14,
    },
    {
      version: 15,
      func: version15,
    },
    {
      version: 16,
      func: version16,
    },
    {
      version: 17,
      func: version17,
    },
    
    {
      version: 18,
      func: version18,
    },
  ];

  request.onupgradeneeded = (e) => {
    console.log('New Version', e.newVersion);
    console.log('Old Version', e.oldVersion);

    db = request.result;

    if (e.oldVersion === 0) {
      console.log('PRIMEIRO ACESSO', e);
      //execute all versions
      versions.forEach((v) => {
        v.func(db);
      });
    } else {
      versions.forEach((v) => {
        if (e.newVersion)
          if (v.version > e.oldVersion) {
            v.func(db);
          }
      });
    }
  };
}
function version13(db: IDBDatabase) {
  console.log('version13');

  createStore(MunicipioIDBService.DB_STORE_MUNICIPIO, db).createIndex(
    MunicipioIDBService.DB_STORE_MUNICIPIO_INDEX_NAME,
    MunicipioIDBService.DB_STORE_MUNICIPIO_INDEX_KEY,
    { unique: true }
  );

  createStore(
    TipoChavePrincipalFVERIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL,
    db
  ).createIndex(
    TipoChavePrincipalFVERIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL_INDEX_NAME,
    TipoChavePrincipalFVERIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_VISITA_PROPRIEDADE_RURAL_INDEX_KEY,
    { unique: true }
  );

  createStore(
    TipoChaveSecundariaFVERIDBService.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL,
    db
  ).createIndex(
    TipoChaveSecundariaFVERIDBService.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL_INDEX_NAME,
    TipoChaveSecundariaFVERIDBService.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL_INDEX_KEY,
    { unique: true }
  );

  const propriedadeStore = createStore(
    PropriedadeIDBService.DB_STORE_PROPRIEDADE,
    db
  );

  propriedadeStore.createIndex(
    PropriedadeIDBService.DB_STORE_PROPRIEDADE_INDEX_ID_NAME,
    PropriedadeIDBService.DB_STORE_PROPRIEDADE_INDEX_ID_KEY,
    {
      unique: true,
    }
  );

  propriedadeStore.createIndex(
    PropriedadeIDBService.DB_STORE_PROPRIEDADE_INDEX_MUNICIPIO_NAME,
    PropriedadeIDBService.DB_STORE_PROPRIEDADE_INDEX_MUNICIPIO_KEY
  );

  const produtorStore = createStore(ProdutorIDBService.DB_STORE_PRODUTOR, db);

  produtorStore.createIndex(
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_ID_NAME,
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_ID_KEY,
    {
      unique: true,
    }
  );

  produtorStore.createIndex(
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_CPF_NAME,
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_CPF_KEY,
    {
      multiEntry: true,
    }
  );

  produtorStore.createIndex(
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_NOME_NAME,
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_NOME_KEY,
    {
      multiEntry: true,
    }
  );

  produtorStore.createIndex(
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_MUNICIPIO_NAME,
    ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_MUNICIPIO_KEY
  );

  createStore(SetorIDBService.DB_STORE_SETOR, db).createIndex(
    SetorIDBService.DB_STORE_SETOR_INDEX_MUNICIPIO_NAME,
    SetorIDBService.DB_STORE_SETOR_INDEX_MUNICIPIO_KEY,
    {
      multiEntry: true,
    }
  );

  const abatedouroStore = createStore(
    AbatedouroIDBService.DB_STORE_ABATEDOURO,
    db
  );

  abatedouroStore.createIndex(
    AbatedouroIDBService.DB_STORE_ABATEDOURO_INDEX_CPF_NAME,
    AbatedouroIDBService.DB_STORE_ABATEDOURO_INDEX_CPF_KEY,
    {
      multiEntry: true,
    }
  );

  abatedouroStore.createIndex(
    AbatedouroIDBService.DB_STORE_ABATEDOURO_INDEX_MUNICIPIO_NAME,
    AbatedouroIDBService.DB_STORE_ABATEDOURO_INDEX_MUNICIPIO_KEY
  );

  const recintoStore = createStore(RecintoIDBService.DB_STORE_RECINTO, db);

  recintoStore.createIndex(
    RecintoIDBService.DB_STORE_RECINTO_INDEX_CODIGO_NAME,
    RecintoIDBService.DB_STORE_RECINTO_INDEX_CODIGO_KEY,
    {
      unique: true,
    }
  );

  recintoStore.createIndex(
    RecintoIDBService.DB_STORE_RECINTO_INDEX_MUNICIPIO_NAME,
    RecintoIDBService.DB_STORE_RECINTO_INDEX_MUNICIPIO_KEY
  );

  const servidorStore = createStore(ServidorIDBService.DB_STORE_SERVIDOR, db);

  servidorStore.createIndex(
    ServidorIDBService.DB_STORE_SERVIDOR_INDEX_CPF_NAME,
    ServidorIDBService.DB_STORE_SERVIDOR_INDEX_CPF_KEY,
    {
      unique: true,
    }
  );

  servidorStore.createIndex(
    ServidorIDBService.DB_STORE_SERVIDOR_INDEX_MATRICULA_NAME,
    ServidorIDBService.DB_STORE_SERVIDOR_INDEX_MATRICULA_KEY
  );

  const vetrinarioStore = createStore(
    VeterinarioIDBService.DB_STORE_VETERINARIO,
    db
  );

  vetrinarioStore.createIndex(
    VeterinarioIDBService.DB_STORE_VETERINARIO_INDEX_CPF_NAME,
    VeterinarioIDBService.DB_STORE_VETERINARIO_INDEX_CPF_KEY,
    {
      unique: true,
    }
  );

  vetrinarioStore.createIndex(
    VeterinarioIDBService.DB_STORE_VETERINARIO_INDEX_CRMV_NAME,
    VeterinarioIDBService.DB_STORE_VETERINARIO_INDEX_CRMV_KEY
  );

  createStore(FVERIDBService.DB_STORE_FVER_INPUT, db);

  const visitaPropriedadeRuralStore = createStore(
    FVERIDBService.DB_STORE_FVER_LIST,
    db
  );

  visitaPropriedadeRuralStore.createIndex(
    FVERIDBService.DB_STORE_FVER_LIST_INDEX_NUMERO_NAME,
    FVERIDBService.DB_STORE_FVER_LIST_INDEX_NUMERO_KEY,
    {
      unique: true,
    }
  );

  visitaPropriedadeRuralStore.createIndex(
    FVERIDBService.DB_STORE_FVER_LIST_INDEX_CODG_IBGE_NAME,
    FVERIDBService.DB_STORE_FVER_LIST_INDEX_CODG_IBGE_KEY
  );

  const vigilanciaVeterinariaStore = createStore(
    FVVIDBService.DB_STORE_FVV,
    db
  );
  vigilanciaVeterinariaStore.createIndex(
    FVVIDBService.DB_STORE_VIGILANCIA_VETERINARIA_INDEX_NAME,
    FVVIDBService.DB_STORE_VIGILANCIA_VETERINARIA_INDEX_KEY
  );

  createStore(FormVINIDBService.DB_STORE_FORM_VIN, db);

  const formINStore = createStore(FormINIDBService.DB_STORE_FORM_IN, db);

  formINStore.createIndex(
    FormINIDBService.DB_STORE_FORM_IN_INDEX_NUMERO_NAME,
    FormINIDBService.DB_STORE_FORM_IN_INDEX_NUMERO_KEY,
    {
      unique: true,
    }
  );

  formINStore.createIndex(
    FormINIDBService.DB_STORE_FORM_IN_INDEX_MUNICIPIO_NAME,
    FormINIDBService.DB_STORE_FORM_IN_INDEX_MUNICIPIO_KEY
  );

  console.log('FINISHING version13');
}

function version14(db: IDBDatabase) {
  console.log('STARTING version14');

  const revendaStore = createStore(RevendaIDBService.DB_STORE_REVENDA, db);

  revendaStore.createIndex(
    RevendaIDBService.DB_STORE_REVENDA_INDEX_CNPJ_NAME,
    RevendaIDBService.DB_STORE_REVENDA_INDEX_CNPJ_KEY,
    {
      multiEntry: true,
    }
  );
  revendaStore.createIndex(
    RevendaIDBService.DB_STORE_REVENDA_INDEX_REVENDA_NAME,
    RevendaIDBService.DB_STORE_REVENDA_INDEX_REVENDA_KEY,
    {
      multiEntry: true,
    }
  );
  revendaStore.createIndex(
    RevendaIDBService.DB_STORE_REVENDA_INDEX_MUNICIPIO_NAME,
    RevendaIDBService.DB_STORE_REVENDA_INDEX_MUNICIPIO_KEY,
    {
      multiEntry: true,
    }
  );

  createStore(
    TipoChavePrincipalTFRIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA,
    db
  ).createIndex(
    TipoChavePrincipalTFRIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA_INDEX_NAME,
    TipoChavePrincipalTFRIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA_INDEX_KEY,
    { unique: true }
  );

  const tfrStore = createStore(TFRIDBService.DB_STORE_TFR_INPUT, db);

  tfrStore.createIndex(
    TFRIDBService.DB_STORE_TFR_LIST_INDEX_NUMERO_NAME,
    TFRIDBService.DB_STORE_TFR_LIST_INDEX_NUMERO_KEY,
    {
      multiEntry: true,
    }
  );
  tfrStore.createIndex(
    TFRIDBService.DB_STORE_TFR_LIST_INDEX_CODG_IBGE_NAME,
    TFRIDBService.DB_STORE_TFR_LIST_INDEX_CODG_IBGE_KEY,
    {
      multiEntry: true,
    }
  );

  console.log('FINISHING version14');
}

function version15(db: IDBDatabase) {
  console.log('SKIPPING version15');
}

async function version16(db: IDBDatabase) {
  console.log('STARTING version16');

  console.log(
    'Deleting table refactored and needed to go in another version becasue HOMLOGATION server'
  );

  /* await openDB(DBConfig.DB_NAME).then((db) => {
    db.deleteObjectStore(LIAIDBService.DB_STORE_LIA_INPUT);
  }); */

  try {
    db.deleteObjectStore(LIAIDBService.DB_STORE_LIA_INPUT);
  } catch {}
  try {
    db.deleteObjectStore(
      EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA
    );
  } catch {}

  console.log('FINISHING version16');
}

function version17(db: IDBDatabase) {
  console.log('STARTING version17');

  const liaStore = createStore(LIAIDBService.DB_STORE_LIA_INPUT, db);

  liaStore.createIndex(
    LIAIDBService.DB_STORE_LIA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME,
    LIAIDBService.DB_STORE_LIA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_KEY,
    {
      multiEntry: true,
    }
  );

  const empresaIntegradoraStore = createStore(
    EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA,
    db
  );

  empresaIntegradoraStore.createIndex(
    EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA_INDEX_NAME,
    EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA_INDEX_KEY,
    {
      unique: true,
    }
  );

  console.log('FINISHING version17');
}

function version18(db: IDBDatabase) {
  console.log('STARTING version18');
  
  const formCustomStore = createStore(
    FormCustomIDBService.DB_STORE_FORM_CUSTOM,
    db
  );

  formCustomStore.createIndex(
    FormCustomIDBService.DB_STORE_FORM_CUSTOM_INDEX_NAME,
    FormCustomIDBService.DB_STORE_FORM_CUSTOM_INDEX_KEY,
    {
      multiEntry: true,
    }
  );
  const formRespostaStore = createStore(
    FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
    db
  );

  formRespostaStore.createIndex(
    FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME,
    FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_KEY,
    {
      multiEntry: true,
    }
  );
  formRespostaStore.createIndex(
    FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INDEX_MUNICIPIO_NAME,
    FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INDEX_MUNICIPIO_KEY,
    {
      multiEntry: true,
    }
  );

  
  console.log('FINISHING version18');
}

export async function persistOfflineGlobalData(
  forceUpdate?: boolean
): Promise<InitializerStatus[]> {
  console.log('INICIO SINCRONIZAÇÃO DADOS GLOBAIS');
  store.dispatch(setDataBaseStatus('SYNCING'));

  return await Promise.all([
    await persistTipoChavePrincipal(forceUpdate),
    await persistTipoChaveSecundaria(forceUpdate),
    await persistTipoChavePrincipalTFR(forceUpdate),
    await persistServidor(forceUpdate),
    await persistVeterinario(forceUpdate),
    await persistEmpresaIntegradora(forceUpdate),
    await persistFormCustom(forceUpdate),
    await persistFormResposta(forceUpdate),
  ])
    .then((e): Promise<InitializerStatus[]> => {
      store.dispatch(setDataBaseStatus('FINISHED'));
      if (e.includes(InitializerStatus.ERROR)) {
        console.log('ERRO NA SINCRONIZAÇÃO DADOS GLOBAIS');
        throw Promise.resolve(InitializerStatus.ERROR);
      } else if (
        e.includes(InitializerStatus.CREATED) ||
        e.includes(InitializerStatus.UPDATED)
      ) {
        console.log('FINALIZAÇÃO SINCRONIZAÇÃO DADOS GLOBAIS');
        return Promise.resolve([InitializerStatus.UPDATED]);
      } else {
        console.log('FINALIZAÇÃO SINCRONIZAÇÃO DADOS GLOBAIS');
        return Promise.resolve([InitializerStatus.STILL_VALID]);
      }
    })
    .catch((e) => {
      console.log('ERRO NA SINCRONIZAÇÃO DADOS GLOBAIS');
      store.dispatch(setDataBaseStatus('FINISHED'));
      return Promise.resolve([InitializerStatus.ERROR]);
    })
    .finally(() => {
      store.dispatch(setDataBaseStatus('FINISHED'));
    });
}

function createStore(storeName: string, db: IDBDatabase) {
  const oS: IDBObjectStore = db.createObjectStore(storeName, {
    keyPath: 'id',
    autoIncrement: true,
  });

  return oS;
}

export async function persistTipoChavePrincipal(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalTipoChavePrincipal: ServiceIDBPayload[];

  return await TipoChavePrincipalFVERIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalTipoChavePrincipal = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;

        await TipoChavePrincipalFVERIDBService.deleteAll();
      } else if (
        listLocalTipoChavePrincipal &&
        listLocalTipoChavePrincipal.length > 0
      ) {
        if (ServiceIDB.isDataExpired(listLocalTipoChavePrincipal[0].date)) {
          type = InitializerStatus.UPDATED;
          await TipoChavePrincipalFVERIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiTipoChavePrincipal;
        await TipoChavePrincipalFVERService.getAllActive()
          .then(async (result) => {
            listApiTipoChavePrincipal = result;

            await TipoChavePrincipalFVERIDBService.addAllTipoChavePrincipalVisitaPropriedadeRural(
              listApiTipoChavePrincipal
            );
          })
          .catch((e) => {
            return Promise.resolve(InitializerStatus.ERROR);
          });
      }

      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}

export async function persistTipoChaveSecundaria(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalTipoChaveSecundaria: any;
  return await TipoChaveSecundariaFVERIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalTipoChaveSecundaria = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;
        await TipoChaveSecundariaFVERIDBService.deleteAll();
      } else if (
        listLocalTipoChaveSecundaria &&
        listLocalTipoChaveSecundaria.length > 0
      ) {
        if (ServiceIDB.isDataExpired(listLocalTipoChaveSecundaria[0].date)) {
          type = InitializerStatus.UPDATED;
          await TipoChaveSecundariaFVERIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiTipoChaveSecundaria;
        await TipoChaveSecundariaFVERService.getAllActive().then(
          async (result) => {
            listApiTipoChaveSecundaria = result;

            await TipoChaveSecundariaFVERIDBService.addAllTipoChaveSecundariaVisitaPropriedadeRural(
              listApiTipoChaveSecundaria
            );
          }
        );
      }
      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}

export async function persistTipoChavePrincipalTFR(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalTipoChavePrincipal: ServiceIDBPayload[];

  return await TipoChavePrincipalTFRIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalTipoChavePrincipal = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;

        await TipoChavePrincipalTFRIDBService.deleteAll();
      } else if (
        listLocalTipoChavePrincipal &&
        listLocalTipoChavePrincipal.length > 0
      ) {
        if (ServiceIDB.isDataExpired(listLocalTipoChavePrincipal[0].date)) {
          type = InitializerStatus.UPDATED;
          await TipoChavePrincipalTFRIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiTipoChavePrincipal;
        await TipoChavePrincipalTFRService.getAllActive()
          .then(async (result) => {
            listApiTipoChavePrincipal = result;

            await TipoChavePrincipalTFRIDBService.addAllTipoChavePrincipalTermoFiscalizacaoRevenda(
              listApiTipoChavePrincipal
            );
          })
          .catch((e) => {
            return Promise.resolve(InitializerStatus.ERROR);
          });
      }

      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}

export async function persistServidor(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalServidor: any;
  return await ServidorIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalServidor = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;
        await ServidorIDBService.deleteAll();
      } else if (listLocalServidor && listLocalServidor.length > 0) {
        const a = ServiceIDB.isDataExpired(listLocalServidor[0].date);

        if (a) {
          type = InitializerStatus.UPDATED;
          await ServidorIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiServidor;
        await ServidorService.getAllActive().then(async (result) => {
          listApiServidor = result;

          await ServidorIDBService.addAllServidor(listApiServidor);
        });
      }
      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}

export async function persistVeterinario(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalVeterinario: any;
  return await VeterinarioIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalVeterinario = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;
        await VeterinarioIDBService.deleteAll();
      } else if (listLocalVeterinario && listLocalVeterinario.length > 0) {
        if (ServiceIDB.isDataExpired(listLocalVeterinario[0].date)) {
          type = InitializerStatus.UPDATED;
          await VeterinarioIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiVeterinario;
        const query = { profissionalOficial: true };
        await VeterinarioService.getAll(query).then(async (result) => {
          listApiVeterinario = result;

          await VeterinarioIDBService.addAllVeterinario(listApiVeterinario);
        });
      }
      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}
export async function persistEmpresaIntegradora(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalEmpresaIntegradora: ServiceIDBPayload[];

  return await EmpresaIntegradoraIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalEmpresaIntegradora = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;

        await EmpresaIntegradoraIDBService.deleteAll();
      } else if (
        listLocalEmpresaIntegradora &&
        listLocalEmpresaIntegradora.length > 0
      ) {
        if (ServiceIDB.isDataExpired(listLocalEmpresaIntegradora[0].date)) {
          type = InitializerStatus.UPDATED;
          await EmpresaIntegradoraIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiEmpresaIntegradora;
        await EmpresaIntegradoraService.getAllEmpresasIntegradoras()
          .then(async (result) => {
            listApiEmpresaIntegradora = result;

            await EmpresaIntegradoraIDBService.addAllEmpresaIntegradora(
              listApiEmpresaIntegradora
            );
          })
          .catch((e) => {
            return Promise.resolve(InitializerStatus.ERROR);
          });
      }

      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}
export async function persistFormCustom(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalFormCustom: ServiceIDBPayload[];

  return await FormCustomIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalFormCustom = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;

        await FormCustomIDBService.deleteAll();
      } else if (listLocalFormCustom && listLocalFormCustom.length > 0) {
        if (ServiceIDB.isDataExpired(listLocalFormCustom[0].date)) {
          type = InitializerStatus.UPDATED;
          await FormCustomIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiFormCustom;
        const query = { ativo: true };
        await FormCustomService.getAll(query)
          .then(async (result) => {
            listApiFormCustom = result;

            await FormCustomIDBService.addAllFormCustom(
              listApiFormCustom as FormCustom.Summary[]
            );
          })
          .catch((e) => {
            return Promise.resolve(InitializerStatus.ERROR);
          });
      }

      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}
export async function persistFormResposta(
  forceUpdate?: boolean
): Promise<InitializerStatus> {
  let listLocalFormResposta: any;

  return await FormularioRespostasIDBService.getAll()
    .then(async (result) => {
      let type: InitializerStatus;

      listLocalFormResposta = result;

      if (forceUpdate) {
        type = InitializerStatus.UPDATED;

        await FormularioRespostasIDBService.deleteAll();
      } else if (listLocalFormResposta && listLocalFormResposta.length > 0) {
        if (ServiceIDB.isDataExpired(listLocalFormResposta[0].date)) {
          type = InitializerStatus.UPDATED;
          await FormularioRespostasIDBService.deleteAll();
        } else {
          type = InitializerStatus.STILL_VALID;
        }
      } else type = InitializerStatus.CREATED;

      if (type !== InitializerStatus.STILL_VALID) {
        let listApiFormResposta;
        await FormularioRespostasIDBService.getAll()
          .then(async (result) => {
            listApiFormResposta = result;

            await FormularioRespostasIDBService.addAllFormularioRespostas(
              listApiFormResposta
            );
          })
          .catch((e) => {
            return Promise.resolve(InitializerStatus.ERROR);
          });
      }

      return Promise.resolve(type);
    })
    .catch((e) => {
      return Promise.resolve(InitializerStatus.ERROR);
    });
}
