export enum SimNao {
  SIM = 'Sim',
  NAO = 'Não',
}
export namespace SimNao {
  export function keys(): string[] {
    return Object.keys(SimNao).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return SimNao[key as keyof typeof SimNao];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(SimNao).indexOf(value as unknown as SimNao);

    const key = Object.keys(SimNao)[indexOfS];

    return key;
  }
}
