import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ListFVER from '../features/FVER/ListFVER';

export default function FVERList() {
  usePageTitle('SIZ - Lista de Visitas a Propriedades Rurais');

  return (
    <>
      <ErrorBoundary component='a lista de FVERs'>
        {/* <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        > */}
        <ListFVER />
        {/* </Spin> */}
      </ErrorBoundary>
    </>
  );
}
