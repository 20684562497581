import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateFVER from '../features/FVER/CreateFVER';

export default function CreateFVERView() {
  usePageTitle('Novo FVER');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateFVER />
      </ErrorBoundary>
    </>
  );
}
