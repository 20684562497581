import { useEffect } from 'react';
import { Card, Descriptions, Row, Tag, Typography } from 'antd';
import { LocalAcaoEducativa } from '../../../sdk/@types';
import HeaderGovernoMt from './HeaderGovernoMt.report';

type PrintTLocalAcaoEducativaProps = {
  localAcaoEducativa?: LocalAcaoEducativa.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number | React.ReactNode;
  span?: number;
};

export default function PrintLocalAcaoEducativa(
  props: PrintTLocalAcaoEducativaProps
) {

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO LOCAL AÇÃO EDUCATIVA
              </Typography.Title>
            </Row>
            <br />

            {panelDescription('Dados do Local Ação Educativa ', [
              {
                label: 'Nome',
                value: props.localAcaoEducativa?.nome,
              },
              {
                label: 'Status',
                value: props.localAcaoEducativa?.status,
              },
              {
                span: 2,
                label: 'Descrição',
                value: props.localAcaoEducativa?.descricao,
              },
              {
                label: 'Tipo Local Ação Educativa',
                value: props.localAcaoEducativa?.tipoLocalAcaoEducativa,
              },
              {
                label: 'Coordenadas',
                value: `${(props.localAcaoEducativa?.coordenadaGeografica?.latGrau)?.toFixed(0)}º 
                  ${(props.localAcaoEducativa?.coordenadaGeografica?.latMin)?.toFixed(0)}' 
                  ${(props.localAcaoEducativa?.coordenadaGeografica?.latSeg)?.toFixed(0)}"
                  ${props.localAcaoEducativa?.coordenadaGeografica?.orientacaoLatitude}", 
                  ${(props.localAcaoEducativa?.coordenadaGeografica?.longGrau)?.toFixed(0)}º 
                  ${(props.localAcaoEducativa?.coordenadaGeografica?.longMin)?.toFixed(0)}' 
                  ${(props.localAcaoEducativa?.coordenadaGeografica?.longSeg)?.toFixed(0)}"
                  ${props.localAcaoEducativa?.coordenadaGeografica?.orientacaoLongitude}"`,
              },
            ])}
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
