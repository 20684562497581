import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PublicFVERCompleto from '../features/FVER/PublicFVERCompleto';

export default function FVERPublicCompletoWrapper() {
  usePageTitle('Formulário de Visita a Estabelecimento Rural');

  useEffect(() => {}, []);

  return (
    <>
      <ErrorBoundary component='o formulário de visita'>
        <PublicFVERCompleto />
      </ErrorBoundary>
    </>
  );
}
