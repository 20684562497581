export enum StatusAssinatura {
  ASSINADO_DIGITALMENTE = 'Assinado Digitalmente',
  ASSINADO_FISICAMENTE = 'Assinado em Formulário Físico',
  AUSENTE_ESTABELECIMENTO = 'Ausência de Pessoas no Estabelecimento',
  PRODUTOR_NAO_ALFABETIZADO = 'Responsável não alfabetizado',
  PRODUTOR_RECUSOU_ASSINAR = 'Responsável recusou-se a assinar',
}
export namespace StatusAssinatura {
  export function keys(): string[] {
    return Object.keys(StatusAssinatura).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return StatusAssinatura[key as keyof typeof StatusAssinatura];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(StatusAssinatura).indexOf(
      value as unknown as StatusAssinatura
    );

    const key = Object.keys(StatusAssinatura)[indexOfS];

    return key;
  }
}
