import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import FormRespostaView from '../features/FormRespostas/FormRespostasView';
import useLoadingPage from '../../core/hooks/useLoadingPage';

export default function FormRespostasViewWrapper() {
  usePageTitle('FormRespostas');
  const { setLoading, setMode, setFirstOpening } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formulário customizado'>
        <FormRespostaView />
      </ErrorBoundary>
    </>
  );
}
