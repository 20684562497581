import {
  Button,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
  Switch,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';

import {
  PrinterOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  EditOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FormCustom } from '../../../sdk/@types';
import { Link, useNavigate } from 'react-router-dom';
import { CustomModal } from '../../components/CustomModal';
import * as FormCustomActions from '../../../core/store/FormCustom.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import FilterPanelFormCustom from './FilterPanelFormCustom';

export default function ListFormCustom() {
  const [deleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();
  const navigate = useNavigate();
  const [modalSummaryVisible, setModalSummaryVisible] = useState(false);
  const [FormCustomsSummary, setFormCustomsSummary] =
    useState<FormCustom.Summary>();

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const formularios = useSelector(
    (state: RootState) => state.formCustom.allForms
  );
  const query = useSelector((state: RootState) => state.formCustom.query);
  const fetching = useSelector((state: RootState) => state.formCustom.fetching);

  if (!window.navigator.onLine)
    throw new Error(
      'Não é possível visualizar a lista de formulários no modo offline'
    );
  if (error) throw error;

  const handleUpdate = useCallback(
    async (id, isActive) => {
      if (id) {
        try {
          setLoading(true);
          if (isActive) {
            await dispatch(FormCustomActions.deactivateFormCustom({ id }));
            notification.success({
              message: `Formulário inativado com sucesso.`,
            });
          } else {
            await dispatch(FormCustomActions.activateFormCustom({ id }));
            notification.success({
              message: `Formulário ativado com sucesso.`,
            });
          }
        } catch (error) {
          notification.error({
            message: `Erro ao ${isActive ? 'inativar' : 'ativar'} formulário.`,
            description:
              error instanceof Error ? error.message : 'Erro desconhecido',
          });
        } finally {
          setLoading(false);
          dispatch(FormCustomActions.filterFormCustoms(query));
        }
      }
    },
    [dispatch, setLoading, query]
  );

  useEffect(() => {
    setFirstOpening(true);
  }, []);

  useEffect(() => {
    dispatch(FormCustomActions.filterFormCustoms(query));
  }, [dispatch, query]);

  const handleEditClick = (id: number) => {
    dispatch(FormCustomActions.clearFormCustomState());
    navigate(`/formCustom/edicao/${id}`);
  };

  const handleViewClick = (id: number) => {
    dispatch(FormCustomActions.clearFormCustomState());
    navigate(`/formCustom/visualizar/${id}`);
  };
  const handlePrintClick = (id: number) => {
    dispatch(FormCustomActions.clearFormCustomState());
    navigate(`/formCustom/impressao/${id}`);
  };
  return (
    <>
      <FilterPanelFormCustom />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FormCustom.Summary>
                rowKey={'id'}
                dataSource={formularios as FormCustom.Summary[]}
                loading={deleting || fetching}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Formulários Customizados
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Link to={'/formCustom/cadastro'}>
                            <Button
                              icon={<PlusOutlined />}
                              type={'primary'}
                              title={'Novo'}
                            />
                          </Link>
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type='primary'
                            title='Atualizar'
                            onClick={async () => {
                              setLoading(true);
                              try {
                                await dispatch(
                                  FormCustomActions.filterFormCustoms(query)
                                );
                              } catch (e) {
                                setError(
                                  new Error(
                                    'Erro ao atualizar a lista de formulários'
                                  )
                                );
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
                size={'small'}
                showHeader={false}
                columns={[
                  {
                    dataIndex: 'nome',
                    title: 'Nome',
                    responsive: ['xs', 'sm'],
                    render(nome: FormCustom.Summary['nome']) {
                      return nome;
                    },
                  },

                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number, record: FormCustom.Summary) {
                      return (
                        <Space>
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Switch
                            checked={record.ativo}
                            onChange={() =>
                              handleUpdate(record.id, record.ativo)
                            }
                            size='small'
                          />
                          <Link to={`/formCustom/impressao/${id}`}>
                            <Button
                              icon={<PrinterOutlined />}
                              size={'small'}
                              type={'ghost'}
                            />
                          </Link>
                          <Link to={`/formCustom/visualizar/${id}`}>
                            <Button
                              icon={<SearchOutlined />}
                              size={'small'}
                              type={'ghost'}
                            />
                          </Link>
                        </Space>
                      );
                    },
                  },
                ]}
              />
              <CustomModal
                centered
                open={modalSummaryVisible}
                title={`Formulário nº ${FormCustomsSummary?.id}/${
                  FormCustomsSummary?.id ? FormCustomsSummary?.id : 's/n'
                }`}
                closable={false}
                onCancel={() => {
                  setModalSummaryVisible(false);
                  setFormCustomsSummary(undefined);
                }}
                maskClosable={true}
                keyboard={true}
                footer={
                  <Button
                    type='primary'
                    onDoubleClick={() => {
                      setModalSummaryVisible(false);
                      setFormCustomsSummary(undefined);
                    }}
                  >
                    Fechar
                  </Button>
                }
              >
                {FormCustomsSummary && (
                  <Descriptions size={'small'}>
                    <Descriptions.Item label={'Form IN'}>
                      {FormCustomsSummary.id}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Nome'}>
                      <Tooltip title={FormCustomsSummary.nome}>
                        {FormCustomsSummary.nome}
                      </Tooltip>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </CustomModal>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
