import { Alert, Button, Col, Row, Space, Tag, Typography } from 'antd';
import FVERIDBService from '../../sdk/services/indexeddb/FVERIDB.service';
import generateHash from '../../core/functions/generateHash';
import FVVIDBService from '../../sdk/services/indexeddb/FVVIDB.service';
import TFRIDBService from '../../sdk/services/indexeddb/TFRIDB.service';
import { StatusAssinatura } from '../../core/enums/StatusAssinatura';
import ListFVEROffline from '../features/FVER/ListFVEROffline';
import ListFVVOffline from '../features/FVV/ListFVVOffline';
import ErrorBoundary from '../components/ErrorBoundary';
import ListTFROffline from '../features/FormTFR/ListTFROffline';
import ListLIAOffline from '../features/LIA/ListLIAOffline';
import ListFormRespostaOffline from '../features/FormRespostas/ListFormRespostaOffline';
import EnvConfig from '../EnvConfig';
import LIAIDBService from '../../sdk/services/indexeddb/LIAIDB.service';
import PermissionsService from '../../core/auth/Permissions.service';
import FormularioRespostasIDBService from '../../sdk/services/indexeddb/FormularioRespostaIDB.service';

export default function AcoesDeCampo() {
  /* const [backGroundSync, setBackGroundSync] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.serviceWorker)
      navigator.serviceWorker.ready.then((swRegistration) => {
        //@ts-ignore
        if (swRegistration.sync) {
          setBackGroundSync(true);
        }
      });
  }, []); */

  const clear = async () => {
    await FVERIDBService.deleteAll_InputTable();
    await FVVIDBService.deleteAll();
    await TFRIDBService.deleteAll_InputTable();
    await LIAIDBService.deleteAll();
    await FormularioRespostasIDBService.deleteAll();
    window.location.reload();
  };

  const generate = async (erro?: boolean) => {
    const baseFVER = {
      dataDaVisita: '2024-03-14T14:19:46Z',
      horaDaVisita: '',
      placaVeiculo: 'SPC9F49 ',
      tipoEstabelecimento: 'PROPRIEDADE',
      listChavePrincipalVisitaPropriedadeRural: [
        {
          motivoPrincipal: 'SIM',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 5,
            nome: 'PREVENÇÃO E ERRADICACAO DA FEBRE AFTOSA',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 4,
            nome: 'CONTROLE DA RAIVA DOS HERBÍVOROS',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 1,
            nome: 'BEM ESTAR ANIMAL',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 2,
            nome: 'CONTROLE E ERRADICAÇÃO DA BRUCELOSE ANIMAL',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 6,
            nome: 'PREVENÇÃO E VIGILÂNCIA DAS ENCEFALOPATIAS ESPONGIFORMES TRANSMISSÍVEIS',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 9,
            nome: 'SANIDADE AVÍCOLA',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 12,
            nome: 'SANIDADE DOS EQUÍDEOS',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
        {
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 13,
            nome: 'SANIDADE DOS SUÍDEOS',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 26,
                nome: 'VISTORIA DE REBANHO',
              },
            },
          ],
        },
      ],
      resumo:
        'Visita para vigilância veterinária ativa para vigilância veterinária ativa para bovinos, equinos, aves, suínos, controle da raiva dos herbívoros, educação sanitária e orientação sobre vacinação contra a brucelose bovina e bubalina e sobre bem estar animal .',
      statusAssinatura: 'ASSINADO_FISICAMENTE',
      proprietario: {
        cpfCnpj: '627.860.911-87',
        nome: 'João Gonçalves da Silva Filho ',
        endereco: {
          telefone: '(66) 9.9623-6213',
          tipoLogradouro: {},
          municipio: {},
        },
      },
      propriedade: {
        id: 1041014,
        nome: 'SITIO AGUA AZUL',
        municipio: {
          id: 5103601,
          codgIBGE: '5103601',
          uf: {
            id: 51,
            nome: 'MATO GROSSO',
            sigla: 'MT',
          },
          nome: 'DOM AQUINO',
        },
        enderecoPropriedade: 'NA PROPRIEDADE',
        viaAcesso:
          'DE FRENTE DO CAMPO DE AVIAÇÃO.\r\n\r\nDom Aquino a jaciara +- 1,0 km a direita +- 0,6 km vira a esquerda +- \r\n0,5 km entra a direita +- 1,3 km é a sede',
        unidade: {
          nome: 'ULE DOM AQUINO',
        },
        coordenadaGeografica: {
          id: 205126,
          pessoa: {},
          orientacaoLatitude: 'S',
          orientacaoLongitude: 'O',
          latGrau: 15,
          latMin: 49,
          latSeg: 27.1,
          longGrau: 54,
          longMin: 56,
          longSeg: 30.5,
        },
      },
      setor: {
        id: 483,
        nome: 'SETOR 3',
      },
      exploracao: {},
      coordenadaGeografica: {
        id: 205126,
        pessoa: {},
        orientacaoLatitude: 'S',
        orientacaoLongitude: 'O',
        latGrau: 15,
        latMin: 49,
        latSeg: 27.1,
        longGrau: 54,
        longMin: 56,
        longSeg: 30.5,
      },
      servidores: [
        {
          id: 1057597,
          pessoa: {
            id: 1057597,
            tipoPessoa: 'F',
            cpfCnpj: '92534619187',
            nome: 'ANDRÉ RIBEIRO LEMOS',
            inscricaoEstadual: null,
            apelido: 'ANDRE LEMOS',
            codigo: null,
            email: 'andrelemos@indea.mt.gov.br',
            endereco: {
              id: 1057597,
              logradouro: 'BANDEIRANTES',
              numero: '3956',
              bairro: 'JARDIM ASSUNÇÃO',
              complemento: null,
              referencia: null,
              cep: '78720198',
              municipio: {
                id: 5107602,
                nome: 'RONDONOPOLIS',
                codgIBGE: '5107602',
                uf: {
                  id: 51,
                  nome: 'MATO GROSSO',
                  sigla: 'MT',
                },
              },
              telefone: '66997219222',
              tipoLogradouro: {
                id: 1,
                nome: 'AVENIDA',
              },
            },
            situacaoCadastro: {
              id: 'A',
              nome: 'CADASTRO ATIVO',
            },
            coordenadaGeografica: null,
          },
          ule: {
            id: 59,
            nome: 'ULE DOM AQUINO',
            sigla: 'DAQ',
            codigo: '5100000044',
            tipoUnidade: 'UL',
            uvl: 59,
            urs: {
              id: 6,
              nome: 'REGIONAL RONDONÓPOLIS',
              sigla: 'URS ROO',
              codigo: '5100400000',
              tipoUnidade: 'UR',
              uvl: null,
              urs: {
                id: 1,
                nome: 'INSTITUTO DEFESA AGROPECUÁRIA DO ESTADO DE MATO GROSSO',
                sigla: 'INDEA',
                codigo: '5110000000',
                tipoUnidade: 'UC',
                uvl: null,
                urs: null,
                municipio: {
                  id: 5103403,
                  nome: 'CUIABA',
                  codgIBGE: '5103403',
                  uf: {
                    id: 51,
                    nome: 'MATO GROSSO',
                    sigla: 'MT',
                  },
                },
              },
              municipio: {
                id: 5107602,
                nome: 'RONDONOPOLIS',
                codgIBGE: '5107602',
                uf: {
                  id: 51,
                  nome: 'MATO GROSSO',
                  sigla: 'MT',
                },
              },
            },
            municipio: {
              id: 5103601,
              nome: 'DOM AQUINO',
              codgIBGE: '5103601',
              uf: {
                id: 51,
                nome: 'MATO GROSSO',
                sigla: 'MT',
              },
            },
          },
          matricula: '321307',
        },
      ],
    };

    const newFVER = {
      ...baseFVER,
      codigoVerificador: generateHash(baseFVER),
    };
    //@ts-ignore
    await FVERIDBService.insert(newFVER);

    const baseFVV = {
      emissaoDeFormIN: 'NAO',
      veterinario: {
        numeroConselho: '1686',
        id: 705,
        pessoa: {
          id: 705,
          tipoPessoa: 'F',
          cpfCnpj: '56961626149',
          nome: 'KELEN REGINA MALHADO DE SIQUEIRA',
          inscricaoEstadual: null,
          apelido: 'KELEN SIQUEIRA',
          codigo: null,
          email: 'ule_vgrande@indea.mt.gov.br',
          endereco: {
            id: 705,
            logradouro: 'CRISTOVÃO COLOMBO',
            numero: '0',
            bairro: 'JARDIM IMPERADOR',
            complemento: null,
            referencia: null,
            cep: '78056842',
            municipio: {
              id: 5103403,
              nome: 'CUIABA',
              codgIBGE: '5103403',
              uf: {
                id: 51,
                nome: 'MATO GROSSO',
                sigla: 'MT',
              },
            },
            telefone: '6536378576',
            tipoLogradouro: {
              id: 2,
              nome: 'RUA',
            },
          },
          situacaoCadastro: {
            id: 'A',
            nome: 'CADASTRO ATIVO',
          },
          coordenadaGeografica: null,
        },
        ule: {
          id: 17,
          nome: 'ULE VARZEA GRANDE',
          sigla: 'VGA',
          codigo: '5100000007',
          tipoUnidade: 'UL',
          uvl: 3,
          urs: {
            id: 2,
            nome: 'REGIONAL CUIABÁ',
            sigla: 'URS CBA',
            codigo: '5100100000',
            tipoUnidade: 'UR',
            uvl: null,
            urs: {
              id: 1,
              nome: 'INSTITUTO DEFESA AGROPECUÁRIA DO ESTADO DE MATO GROSSO',
              sigla: 'INDEA',
              codigo: '5110000000',
              tipoUnidade: 'UC',
              uvl: null,
              urs: null,
              municipio: {
                id: 5103403,
                nome: 'CUIABA',
                codgIBGE: '5103403',
                uf: {
                  id: 51,
                  nome: 'MATO GROSSO',
                  sigla: 'MT',
                },
              },
            },
            municipio: {
              id: 5103403,
              nome: 'CUIABA',
              codgIBGE: '5103403',
              uf: {
                id: 51,
                nome: 'MATO GROSSO',
                sigla: 'MT',
              },
            },
          },
          municipio: {
            id: 5108402,
            nome: 'VARZEA GRANDE',
            codgIBGE: '5108402',
            uf: {
              id: 51,
              nome: 'MATO GROSSO',
              sigla: 'MT',
            },
          },
        },
        matricula: '91676',
        proprietarioInstituicao: 'N',
        profissionalOficial: 'S',
        pesquisador: null,
        tipoEmitente: {
          id: 'VE',
          nome: 'VETERINÁRIO ESTADUAL',
        },
        conselho: {
          id: 'CRMV ',
          nome: 'CONSELHO REGIONAL DE MEDICINA VETERINÁRIA',
        },
      },
      dataVigilancia: '2023-05-17T15:11:34.561Z',
      exploracao: {
        id: 302830,
        codigo: '5100302830',
      },
      historicoDeSugadurasDeMorcegos: 'NAO',
      observacao: `Teste: ${Math.random().toString(36)}`,
      propriedade: {
        id: 1091945,
        nome: 'CHACARA SAO MATEUS',
        municipio: {
          id: 5100250,
          uf: {
            id: 51,
            nome: 'MATO GROSSO',
            sigla: 'MT',
          },
          nome: 'ALTA FLORESTA',
        },
        enderecoPropriedade: null,
        viaAcesso:
          'MT 320 - COMUNIDADE CENTRALZINHA - HIDROPONIA MAIS \r\nBRASIL- ANTES DA SEDE COM. CENTRAL 0,2KM. SITUA-SE A \r\n2,8KM DA MT 325 E 8,7KM DA MT 208',
        unidade: {
          nome: 'ULE ALTA FLORESTA',
        },
        coordenadaGeografica: {
          id: 264155,
          pessoa: {},
          orientacaoLatitude: 'S',
          orientacaoLongitude: 'O',
          latGrau: 9,
          latMin: 57,
          latSeg: 33.8,
          longGrau: 56,
          longMin: 5,
          longSeg: 21.1,
        },
      },
      proprietario: {
        cpfCnpj: '00097154105',
        id: 1132395,
        nome: 'LAIR PEREIRA CARDOSO',
        email: null,
        endereco: {
          id: 1132395,
          telefone: '66992564758',
          tipoLogradouro: {
            id: 2,
            nome: 'RUA',
          },
          logradouro: 'RUA DOIS',
          bairro: 'COM. CENTRAL',
          complemento: null,
          referencia: null,
          municipio: {
            id: 5100250,
          },
        },
      },
      setor: {
        id: 520,
        nome: 'SETOR 02',
      },
      vigilanciaAlimentosRuminantes: {
        presencaDeCamaDeAviario: 'NAO',
        utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes: 'NAO',
        pisciculturaComSistemaDeAlimentacaoABaseDeRacao: 'NAO',
        contaminacaoCruzadaDeRacoesDePeixeERuminante: 'NAO',
        colheitaDeAmostraDeAlimentosDeRuminantes: 'NAO',
        usoTesteRapido: 'NAO',
      },
      vigilanciaBovinos: {},
      vigilanciaBubalino: {},
      vigilanciaCaprino: {},
      vigilanciaOvino: {},
      vigilanciaPeixes: {},
      vigilanciaSuideos: {
        presencaDeSuinosAsselvajados: 'NAO',
        contatoDiretoDeSuinosDomesticosComAsselvajados: 'NAO',
      },
      vigilanciaAbelha: {},
      vigilanciaAsinino: {},
      vigilanciaEquino: {},
      vigilanciaMuar: {},
      visitaPropriedadeRural: newFVER,
    };

    const newFVV = {
      ...baseFVV,
      visitaPropriedadeRural: {
        ...baseFVV.visitaPropriedadeRural,
        id: -1,
        codigoVerificador: erro
          ? baseFVV.visitaPropriedadeRural.codigoVerificador + 'a'
          : baseFVV.visitaPropriedadeRural.codigoVerificador,
      },
      codigoVerificador: generateHash(baseFVER),
    };

    //@ts-ignore
    await FVVIDBService.insert(newFVV);

    const baseTFR = {
      dataVisita: '2024-01-17T12:30:48Z',
      ocorrencias: 'teste',
      nomeFiscalizado: 'AGRO & SOUZA',
      revenda: {
        id: 1166858,
        codigo: '5101166858',
        nome: 'AGRO & SOUZA',
        apelido: 'AGROFORTUNA',
        cpfCnpj: '62441227000120',
        email: 'agrofortuna@hotmai.com',
        endereco: {
          id: 1166858,
          logradouro: 'SDFASF',
          numero: 'SDF',
          bairro: 'SDF',
          municipio: {
            id: 5100102,
            nome: 'ACORIZAL',
            uf: {
              id: 51,
              nome: 'MATO GROSSO',
              sigla: 'MT',
            },
          },
        },
      },
      ule: {
        id: 19,
        nome: 'ULE ACORIZAL',
        sigla: 'ACZ',
        codigo: '5100000006',
        tipoUnidade: 'UL',
        uvl: 19,
        municipio: {
          id: 5100102,
          nome: 'ACORIZAL',
          codgIBGE: '5100102',
          uf: {
            id: 51,
            nome: 'MATO GROSSO',
            sigla: 'MT',
          },
        },
      },
      servidores: [
        {
          id: 40907,
          servidor: {
            id: 227376,
            pessoa: {
              id: 227376,
              tipoPessoa: 'F',
              cpfCnpj: '00360932118',
              nome: 'PABLO DIEGO KOLAKOWSKI',
              apelido: 'PABLO KOLAKOWSKI',
              email: 'pablo.indea@gmail.com',
              endereco: {
                id: 227376,
                logradouro: 'BRASIL',
                numero: '32',
                bairro: 'CENTRO',
                cep: '78548000',
                municipio: {
                  id: 5106190,
                  nome: 'NOVA SANTA HELENA',
                  codgIBGE: '5106190',
                  uf: {
                    id: 51,
                    nome: 'MATO GROSSO',
                    sigla: 'MT',
                  },
                },
                telefone: '6699550125',
                tipoLogradouro: {
                  id: 1,
                  nome: 'AVENIDA',
                },
              },
              situacaoCadastro: {
                id: 'A',
                nome: 'CADASTRO ATIVO',
              },
              municipio: {
                id: 5106190,
                nome: 'NOVA SANTA HELENA',
                codgIBGE: '5106190',
                uf: {
                  id: 51,
                  nome: 'MATO GROSSO',
                  sigla: 'MT',
                },
              },
            },
            ule: {
              id: 104,
              nome: 'ULE NOVA SANTA HELENA',
              sigla: 'NSH',
              codigo: '5100000170',
              tipoUnidade: 'UL',
              uvl: 104,
              urs: {
                id: 14,
                nome: 'REGIONAL MATUPA',
                sigla: 'URS MTP',
                codigo: '5101200000',
                tipoUnidade: 'UR',
                uvl: null,
                urs: {
                  id: 1,
                  nome: 'INSTITUTO DEFESA AGROPECUÁRIA DO ESTADO DE MATO GROSSO',
                  sigla: 'INDEA',
                  codigo: '5110000000',
                  tipoUnidade: 'UC',
                  uvl: null,
                  urs: null,
                  municipio: {
                    id: 5103403,
                    nome: 'CUIABA',
                    codgIBGE: '5103403',
                    uf: {
                      id: 51,
                      nome: 'MATO GROSSO',
                      sigla: 'MT',
                    },
                  },
                },
                municipio: {
                  id: 5105606,
                  nome: 'MATUPA',
                  codgIBGE: '5105606',
                  uf: {
                    id: 51,
                    nome: 'MATO GROSSO',
                    sigla: 'MT',
                  },
                },
              },
              municipio: {
                id: 5106190,
                nome: 'NOVA SANTA HELENA',
                codgIBGE: '5106190',
                uf: {
                  id: 51,
                  nome: 'MATO GROSSO',
                  sigla: 'MT',
                },
              },
            },
            matricula: '226814',
            funcao: {
              id: 4,
              nome: 'AGENTE FISCAL DE DEFESA AGROPECUÁRIA E FLORESTAL II',
            },
            profissao: {
              id: 5,
              nome: 'AGENTE FISCAL II',
            },
          },
        },
      ],
      chavesPrincipaisTermoFiscalizacao: [
        {
          id: 66771,
          tipoChavePrincipalTermoFiscalizacao: {
            id: 3,
            nome: 'Fiscalização do comércio de aves vivas',
            tiposChavesSecundariasTermoFiscalizacao: [
              {
                id: 30,
                nome: 'Bem estar animal',
              },
              {
                id: 31,
                nome: 'Controle do livro de registro de comercializações',
              },
              {
                id: 32,
                nome: 'Controle de biosseguridade',
              },
            ],
          },
          chavesSecundariasTermoFiscalizacao: [
            {
              id: 190555,
              tipoChaveSecundariaTermoFiscalizacao: {
                id: 30,
                nome: 'Bem estar animal',
              },
            },
            {
              id: 190556,
              tipoChaveSecundariaTermoFiscalizacao: {
                id: 32,
                nome: 'Controle de biosseguridade',
              },
            },
          ],
        },
        {
          id: 66772,
          tipoChavePrincipalTermoFiscalizacao: {
            id: 9,
            nome: 'COMÉRCIO DE MATERIAL GENÉTICO',
            tiposChavesSecundariasTermoFiscalizacao: [],
          },
          chavesSecundariasTermoFiscalizacao: [],
        },
      ],
      statusAssinatura: 'ASSINADO_FISICAMENTE',
    };
    const newTFR = {
      ...baseTFR,
    };
    //@ts-ignore
    await TFRIDBService.insert(newTFR);

    const baseLIA = {
      fver: newFVER,
      registro: false,
      manutencaoRegistro: true,
      nomeFiscalizado: 'VILSON TAQUES DE SOUZA',
      nomeEstabelecimento: 'SITIO SAO JOAO',
      municipioFiscalizado: {
        id: 247,
        nome: 'Barão de Melgaço',
        codgIBGE: '5101605',
        uf: {
          id: 25,
          nome: 'Mato Grosso',
          sigla: 'MT',
        },
      },
      tipoExploracao: 'POSTURA_COM_RECRIA',
      quantidadeNucleoRecria: 34,
      quantidadeNucleoProducao: 3,
      quantidadeAviarioRecria: 23,
      quantidadeAviarioProducao: 667,
      capacidadeTotalAlojamento: 564,
      empresaIntegradora: {
        id: 9948,
        nome: 'FRIGORIFICO CASTELO',
        cpfCnpj: '06194582000170',
        endereco: {
          id: 9948,
          logradouro: 'ROD. MT 325, S/N',
          numero: 'S/N',
          bairro: 'SETOR OESTE',
          municipio: {
            id: 5105101,
            nome: 'JUARA',
            uf: {
              id: 51,
              nome: 'MATO GROSSO',
              sigla: 'MT',
            },
          },
        },
      },
      tipoServicoDeInspecao: 'SISE',
      numeroServicoDeInspecao: '200',
      todosAviariosPertencemAoProprietario: null,
      responsavelAviario: null,
      nomeResponsavelAviario: null,
      vinculoComProprietario: null,
      distanciaMinima3kmMatrizeiros_1_1: 'NAO',
      distanciaMatrizeiros_1_1: '2km',
      distanciaMinima3kmAvesOrnamentais_1_2: 'NAO',
      distanciaAvesOrnamentais_1_2: '3km',
      distanciaMinima3kmEstabelecimentoEnsino_1_3: 'NAO',
      distanciaEstabelecimentoEnsino_1_3: '2km',
      distanciaMinima3kmIncubatorios_1_4: 'NAO',
      distanciaIncubatorios_1_4: '33km',
      distanciaMinima10kmCriacaoDeAves_1_5: 'NAO',
      distanciaCriacaoDeAves_1_5: '10km',
      distanciaMinima10kmCriacoesAteMilAves_1_6: 'NAO',
      distanciaCriacoesAteMilAves_1_6: '12000',
      distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7: 'NAO',
      distanciaEntreAvesCorteAvesPoedeiras_1_7: '2.5km',
      distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8: 'NAO',
      distanciaEntreAvesCortesDiferentesIntegradoras_1_8: '3.2km',
      distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9: 'NAO',
      distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9: '100m',
      distanciaMinima1kmGranjaAvesPoedeiras_1_10: 'NAO',
      distanciaGranjaAvesPoedeiras_1_10: '3km',
      distanciaMinima3kmAviarioFabricaRacao_1_11: 'NAO',
      distanciaAviarioFabricaRacao_1_11: '100m',
      distanciaMinima3kmIndustriaProcessamentoProdutos_1_12: 'NAO',
      distanciaIndustriaProcessamentoProdutos_1_12: '2km',
      distanciaMinima3kmAterroSanitarioLixao_1_13: 'NAO',
      distanciaAterroSanitarioLixao_1_13: '100m',
      distanciaMinima3kmLagoaResiduo_1_14: 'NAO',
      distanciaLagoaResiduo_1_14: '2km',
      distanciaMinima1kmBiodigestores_1_15: 'NAO',
      distanciaBiodigestores_1_15: '34km',
      distanciaMinima4kmCriacaoDeAves_1_16: 'NAO',
      distanciaCriacaoDeAves_1_16: 'fdsfds',
      observacoesDistanciasExternas_1_17: 'teste',
      distanciaMinima5mDaCercaIsolamento_2_1: 'NAO',
      distanciaDaCercaIsolamento_2_1: '3km',
      distanciaMinima20mDasResidencias_2_2: 'SIM',
      distanciaDasResidencias_2_2: null,
      distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3: 'NA',
      distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3: null,
      distanciaMinima200mEntreNucleoAvesCorte_2_4: 'SIM',
      distanciaEntreNucleoAvesCorte_2_4: null,
      distanciaMinima100mDaEstradaVicinal_2_5: 'NA',
      distanciaDaEstradaVicinal_2_5: null,
      distanciaMinima30mDosLimitesPerifericos_2_6: 'SIM',
      distanciaDosLimitesPerifericos_2_6: null,
      distanciaMinima500mDaFabricaRacaoPropria_2_7: 'NA',
      distanciaDaFabricaRacaoPropria_2_7: null,
      distanciaMinima600mEntreNucleoDeAvesESuinos_2_8: 'SIM',
      distanciaEntreNucleoDeAvesESuinos_2_8: null,
      distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9: 'NAO',
      distanciaEntreNucleoAvesEOutrasEspecies_2_9: '120m',
      observacoesDistanciasInternas: 'teste 2',
      terrenoELocalizacao: 'SIM',
      observacoesTerrenoELocalizacao: 'teste 1',
      granjaPossuiSinaisDeAviso_4_1: 'SIM',
      granjaPossuiSinaisDeAvisoEspecificacao_4_1: null,
      realizaControleDeTransitoDeVeiculos_4_2: 'SIM',
      realizaControleDeTransitoDeVeiculosEspecificacao_4_2: null,
      executaProcedimentosParaDesinfeccaoDeVeiculos_4_3: 'SIM',
      executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3: null,
      realizaControleDeAcessoDePessoas_4_4: 'SIM',
      realizaControleDeAcessoDePessoasEspecificacao_4_4: null,
      granjaPossuiBarreiraSanitaria_4_5: 'SIM',
      granjaPossuiBarreiraSanitariaEspecificacao_4_5: null,
      ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6:
        'SIM',
      ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6:
        null,
      funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7: 'SIM',
      funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7:
        null,
      granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8: 'NAO',
      granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8:
        'teste4',
      observacoesControleAcessoDePessoas: 'teste 4.8',
      instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1: 'SIM',
      instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1:
        null,
      pisoFeitoTerraCompactadaOuCimentado_5_2: 'SIM',
      pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2: null,
      muretaDeAlvenariaAdequadoParaLimpeza_5_3: 'SIM',
      muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3: null,
      malhaDaTelaDosAviariosAteUmaPolegada_5_4: 'SIM',
      malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4: null,
      coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5: 'SIM',
      coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5: null,
      possuemNebulizadoresEOuSistemaDeVentilacao_5_6: 'SIM',
      possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6: null,
      bebedourosEComedourosDeFacilManejo_5_7: 'SIM',
      bebedourosEComedourosDeFacilManejoEspecificacao_5_7: null,
      possuiCercaDeIsolamentoMimino1m_5_8: 'SIM',
      possuiCercaDeIsolamentoMimino1mEspecificacao_5_8: null,
      siloDeRacaoFeitoDeMaterialIpermeavel_5_9: 'SIM',
      siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9: null,
      ausenciaDeArvoresAtrativasDePassaros_5_10: 'SIM',
      ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10: null,
      adotaSistemaDeCriacaoAoArLivre_5_11: 'SIM',
      adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11: null,
      observacoesInstalacoesEquipamentos_5_12: 'teste 5.112',
      adotaVazioSanitario_6_1: 'SIM',
      adotaVazioSanitarioEspecificacao_6_1: null,
      adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2: 'SIM',
      adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2:
        null,
      executaProgramaDeLimpezaEDesinfeccao_6_3: 'SIM',
      executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3: null,
      possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4: 'SIM',
      possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4: null,
      mantemRegistroDeControleDePragas_6_5: 'SIM',
      mantemRegistroDeControleDePragasEspecificacao_6_5: null,
      realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6: 'SIM',
      realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6: null,
      aguaUtilizadaETratadaComCloro_6_7: 'SIM',
      aguaUtilizadaETratadaComCloroEspecificacao_6_7: null,
      mantemRegistroDasAtividadesDeTransitoDeAves_6_8: 'SIM',
      mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8: null,
      mantemRegistroDasAcoesSanitariasExecutadas_6_9: 'SIM',
      mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9: null,
      mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10: 'SIM',
      mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10: null,
      anotacaoDeRTDentroDoPrazoDeValiade_6_11: 'SIM',
      anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11: null,
      observacoesMedidasSanitarias_6_12: 'teste 6.12',
      houveNecessidadeDeAvaliacaoDeRisco_7_1: 'SIM',
      riscoProporcionadoPelaGranja_7_1_1: 'DESPREZIVEL',
      necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2: 'SIM',
      medidasDeBiosseguridadeCompensatorias: 'teste',
      possuiRequerimentoSolicitacao_8_1: 'SIM',
      requerimentoSolicitacaoNomeUpload_8_1: null,
      possuiCadastroProprietario_8_2: 'SIM',
      cadastroProprietarioDescricao_8_2: null,
      possuiResponsabilidadeTecnica_8_3: 'SIM',
      responsabilidadeTecnicaDescricao_8_3: null,
      possuiPlantaLocalizacaoPropriedade_8_4: 'SIM',
      plantaLocalizacaoPropriedadeDescricao_8_4: null,
      possuiPlantaBaixaEstabelecimento_8_5: 'SIM',
      plantaBaixaEstabelecimentoDescricao_8_5: null,
      possuiMemorialMedidasHigienicoSanitarias_8_6: 'SIM',
      memorialMedidasHigienicoSanitariasDescricao_8_6: null,
      possuiDocumentoComprobatorioQualidadeAgua_8_7: 'SIM',
      documentoComprobatorioQualidadeAguaDescricao_8_7: null,
      statusAssinatura: 'ASSINADO_FISICAMENTE',
      veterinario: null,
      nomeVeterinario: null,
      parecer: 'FAVORAVEL_MANUTENCAO_REGISTRO',
      dataParecer: '2023-12-04T17:05:31Z',
    };
    const newLIA = {
      ...baseLIA,
    };
    //@ts-ignore
    LIAIDBService.insert(newLIA);
    window.location.reload();
  };

  return (
    <Space size={24} direction='vertical' style={{ width: '100%' }}>
      <Alert
        message={
          <span style={{ fontWeight: 'bold' }}>
            Sobre as ações de campo offline
          </span>
        }
        description={
          <>
            <Typography.Text>
              Aqui listamos todos os registros que foram cadastrados no modo
              offline.
            </Typography.Text>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              Registros com o status{' '}
              <Tag
                style={{
                  backgroundColor: '#2baf57',
                  color: 'white',
                }}
              >
                SINCRONIZADO
              </Tag>
              já estão salvos no servidor e podem ser deletados do dispositivo
              sem perda de dados.
            </Typography.Paragraph>
          </>
        }
        type='info'
        showIcon
        closable={false}
      />
      {/* {!backGroundSync && (
            <Alert
              message='Sincronização automática indisponível neste dispositivo.'
              type='warning'
              showIcon
              closable={false}
            />
          )} */}

      {PermissionsService.dev && (
        <Row gutter={24}>
          <Col>
            <Button type='primary' onClick={() => generate()}>
              Gerar massa de teste
            </Button>
          </Col>

          <Col>
            <Button type='primary' onClick={() => generate(true)}>
              Gerar massa de teste com erros
            </Button>
          </Col>

          <Col>
            <Button danger type='primary' onClick={clear}>
              Limpar
            </Button>
          </Col>
        </Row>
      )}

      <ErrorBoundary component='a lista de FVERs offline'>
        <ListFVEROffline visible={true} />
      </ErrorBoundary>

      <ErrorBoundary component='a lista de FVVs offline'>
        <ListFVVOffline visible={true} />
      </ErrorBoundary>
      <ErrorBoundary component='a lista de TFRs offline'>
        <ListTFROffline visible={true} />
      </ErrorBoundary>
      <ErrorBoundary component='a lista de LIAs offline'>
        <ListLIAOffline visible={true} />
      </ErrorBoundary>
      <ErrorBoundary component='a lista de Formularios Respostas offline'>
        <ListFormRespostaOffline visible={true} />
      </ErrorBoundary>
      {/* <FormVINOffline visible={true} /> */}
    </Space>
  );
}
