import { FVV } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';

class FVVIDBService extends ServiceIDB {
  static DB_STORE_FVV = 'vigilancia_veterinaria';
  static DB_STORE_VIGILANCIA_VETERINARIA_INDEX_NAME =
    'vigilanciaVeterinaria_visita_codigo_verificador';
  static DB_STORE_VIGILANCIA_VETERINARIA_INDEX_KEY =
    'payload.visitaPropriedadeRural.codigoVerificador';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(FVVIDBService.DB_STORE_FVV)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FVVIDBService.DB_STORE_FVV);
    return list;
  }

  static async getAllPayload(): Promise<FVV.Input[]> {
    let list: FVV.Input[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<FVV.Input> {
    const db = await openDB(DBConfig.DB_NAME);
    const fvv = await db.get(FVVIDBService.DB_STORE_FVV, id);

    return fvv.payload;
  }

  static async getByCodigoVerificador(
    codigoVerificador: string
  ): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_FVV,
      this.DB_STORE_VIGILANCIA_VETERINARIA_INDEX_NAME,
      codigoVerificador
    );
  }

  static async insert(fvv: FVV.Input) {
    const db = await openDB(DBConfig.DB_NAME);

    const obj: ServiceIDBPayloadInput = {
      date: new Date(),
      payload: fvv,
      status: 'NOVO',
    };

    return db.add(this.DB_STORE_FVV, obj);
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FVV);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_FVV, id);
  }

  static async update(id: number, object: ServiceIDBPayloadInput) {
    this.delete(id);
    const db = await openDB(DBConfig.DB_NAME);
    return await db.add(this.DB_STORE_FVV, object);
  }

  static async count() {
    return this._count(this.DB_STORE_FVV);
  }
}

export default FVVIDBService;
