import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormCustom from './FormFormCustom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FormCustomActions from '../../../core/store/FormCustom.slice';

function EditFormFormCustom() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const formCustom = useSelector((state: RootState) => state.formCustom.formCustomData);
  const loading = useSelector(
    (state: RootState) => state.formCustom.loading
  );


  const prepareData = useCallback(
    (formCustom) => ({
      ...formCustom,
      dataDistribuicao: moment(formCustom?.dataDistribuicao),
    }),
    []
  );
  useEffect(() => {
    if (!isNaN(Number(id))) {
      dispatch(FormCustomActions.fetchFormCustomById(Number(id)));
    }
  }, [id, local, dispatch]);

  return (
    <>
      {!formCustom && <Skeleton active />}
      {formCustom && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando Formulário</Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loading === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormCustom formCustom={prepareData(formCustom)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFormFormCustom;
