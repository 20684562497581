import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssinaturaBase64ImageState {
  assinaturaBase64Image: string | undefined;
}

const initialState: AssinaturaBase64ImageState = {
  assinaturaBase64Image: undefined,
};

const AssinaturaBase64ImageSlice = createSlice({
  initialState,
  name: 'assinaturaBase64Image',
  reducers: {
    storeString(state, action: PayloadAction<string | undefined>) {
      state.assinaturaBase64Image = action.payload;
    },
  },
});

export const { storeString } = AssinaturaBase64ImageSlice.actions;

const AssinaturaBase64ImageReducer = AssinaturaBase64ImageSlice.reducer;
export default AssinaturaBase64ImageReducer;
