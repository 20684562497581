import { openDB } from 'idb';
import { Setor } from '../../@types';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class SetorIDBService extends ServiceIDB {
  static DB_STORE_SETOR = 'setor';
  static DB_STORE_SETOR_INDEX_MUNICIPIO_KEY = 'payload.municipio.codgIBGE';
  static DB_STORE_SETOR_INDEX_MUNICIPIO_NAME = 'setor_index_municipio_id';

  static async getAll(): Promise<ServiceIDBPayload[]> {
    return await super._getAll(SetorIDBService.DB_STORE_SETOR);
  }

  static async getByMunicipio(codgIBGE: string): Promise<ServiceIDBPayload[]> {
    return await super._getAllFromIndex(
      this.DB_STORE_SETOR,
      this.DB_STORE_SETOR_INDEX_MUNICIPIO_NAME,
      codgIBGE
    );
  }

  static async get(id: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_SETOR,
      this.DB_STORE_SETOR_INDEX_MUNICIPIO_NAME,
      id
    );
  }

  static add(setor: Setor.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: setor,
      };

      db.add(this.DB_STORE_SETOR, obj);
    });
  }

  static async addAllSetor(lista: Setor.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((propriedade) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: propriedade,
      };

      db.add(this.DB_STORE_SETOR, obj);
    });
  }

  static async delete(id: number): Promise<void> {
    const obj = await super._getFromIndex(
      this.DB_STORE_SETOR,
      this.DB_STORE_SETOR_INDEX_MUNICIPIO_NAME,
      id
    );
    //@ts-ignore
    return await super._delete(this.DB_STORE_SETOR, obj.id);
  }

  static async deleteAll(): Promise<void> {
    return super._clear(this.DB_STORE_SETOR);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_SETOR,
      this.DB_STORE_SETOR_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default SetorIDBService;
