import { Button, ButtonProps, notification } from 'antd';
import { FunctionComponent, useCallback } from 'react';
import GoogleMapsSvg from '../Icons/GoogleMaps';
import { CoordenadaGeografica } from '../../sdk/@types';
import { coordinatesDMStoDD } from '../../core/functions/coordinatesUtil';

type ButtonGoogleMapsProps = {
  coordenadas: CoordenadaGeografica.Detailed;
} & ButtonProps;

export const ButtonGoogleMaps: FunctionComponent<ButtonGoogleMapsProps> = (
  props
) => {
  const googleMapsLink = useCallback(() => {
    console.log('props.coordenadas', props.coordenadas);

    if (!props.coordenadas) return;

    let coordenadasDD = coordinatesDMStoDD(props.coordenadas);

    if (coordenadasDD) {
      let link;
      if (!navigator.userAgent.match(/(Android|BlackBerry|IEMobile)/)) {
        link = `https://www.google.com/maps/search/?api=1&query=${coordenadasDD}&zoom=13`;
      } else {
        link = `https://www.google.com/maps/search/?api=1&query=${coordenadasDD}&zoom=13`;
      }

      return link;
    } else {
      return;
    }
  }, [props.coordenadas]);

  const redirectToGoogleMap = useCallback(() => {
    const link = googleMapsLink();
    if (link)
      //@ts-ignore
      window.open(link, '_blank').focus();
    else
      notification.warn({
        message: 'Coordenadas inválidas!',
      });
  }, [googleMapsLink]);

  return (
    <Button
      icon={<GoogleMapsSvg />}
      onClick={redirectToGoogleMap}
      style={{
        height: '22px',
        fontSize: '11px',
        padding: '0',
        ...props.style,
      }}
      type='text'
    />
  );
};
