import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormularioRespostas, FVER } from '../../sdk/@types';
import FormRespostaService from '../../sdk/services/SIZ-API/formularioResposta.service';
import { RootState } from '.';
import localforage from 'localforage';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import FormularioRespostasIDBService from '../../sdk/services/indexeddb/FormularioRespostaIDB.service';
import AuthorizationService from '../auth/Authorization.service';
import { SYNC_FORM_RESPOSTA_NEEDED } from '../../sdk/@types/ServiceWorker.types';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface FormRespostasState {
  fver: FVER.Summary | null;
  formRespostasData: FormularioRespostas.Response | null;
  allForms: FormularioRespostas.Summary[] | null;
  fetching: boolean;
  loading: LoadingState;
  errorMessage?: string | null;
  query: FormularioRespostas.Query;
}

const initialState: FormRespostasState = {
  fver: null,
  formRespostasData: null,
  allForms: null,
  fetching: false,
  loading: 'idle',
  errorMessage: null,
  query: { page: 0, size: 10 },
};

export const filterFormCustomsRespostas = createAsyncThunk(
  'formResposta/filterFormRespostas',
  async (query: FormularioRespostas.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const formResposta = await FormRespostaService.getAll(query);
      return formResposta;
    } catch (error) {
      return rejectWithValue('Não foi possível buscar todos os formulários');
    } finally {
      dispatch(setFetching(false));
    }
  }
);

export const fetchFormRespostaById = createAsyncThunk(
  'formRespostas/fetchRespostaById',

  async (id: number, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        const formRespostaOnline = await FormRespostaService.getRespostaById(
          id
        );
        return formRespostaOnline;
      } else {
        const formRespostaIDB = await FormularioRespostasIDBService.getByIdFromInputTable(id);
        return formRespostaIDB;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFormRespostas = createAsyncThunk<
  FormularioRespostas.Response[],
  string,
  { rejectValue: string }
>('formRespostas/fetchFormResponses', async (formId, { rejectWithValue }) => {
  try {
    return await FormRespostaService.getFormResponses(formId);
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue('Erro desconhecido');
    }
  }
});

export const sendFormRespostas = createAsyncThunk(
  'formRespostas/addRespostas',
  async (
    { id, resposta }: { id: number; resposta: FormularioRespostas.Request },
    { rejectWithValue }
  ) => {
    try {
      if (navigator.onLine) {
        const data = await FormRespostaService.addRespostas(id, resposta);
        return data;
      } else {
        if (navigator.serviceWorker) {
          const swRegistration = await navigator.serviceWorker.ready;
          //@ts-ignore
          if (swRegistration.sync) {
            localforage.setItem('token', AuthorizationService.getAccessToken());
            const registration = await navigator.serviceWorker.ready;
            //@ts-ignore
            registration.sync.register(SYNC_FORM_RESPOSTA_NEEDED);
          }
        }
        return FormularioRespostasIDBService.insert(id, resposta);
      }
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Falha ao adicionar respostas ao formulário');
      }
    }
  }
);

export const updateformRespostaOffline = createAsyncThunk(
  'formRespostas/updateFormCustomOffline',
  async ({
    id,
    formResposta,
  }: {
    id: number;
    formResposta: FormularioRespostas.Request;
  }) => {
    //const codigoVerificador = generateHash(formResposta);
    let newFormResposta: ServiceIDBPayloadInput = {
      id: id,
      payload: {
        ...formResposta,
      },
      status: 'NOVO',
      date: new Date(),
    };

    return await FormularioRespostasIDBService.update(id, newFormResposta);
  }
);

export const selectFormRespostas = (state: RootState) =>
  state.formRespostas.formRespostasData;

const formRespostasSlice = createSlice({
  name: 'formRespostas',
  initialState,
  reducers: {
    clearFormRespostasState: (state) => {
      return initialState;
    },
    setFver: (state, action: PayloadAction<FVER.Summary | null>) => {
      state.fver = action.payload;
    },
    clearFver: (state) => {
      state.fver = null;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FormularioRespostas.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(filterFormCustomsRespostas.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(filterFormCustomsRespostas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.allForms = action.payload;
        state.fetching = false;
      })
      .addCase(filterFormCustomsRespostas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(fetchFormRespostaById.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(fetchFormRespostaById.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.formRespostasData = action.payload;
      })
      .addCase(fetchFormRespostaById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchFormRespostas.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFormRespostas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.formRespostasData = action.payload;
      })
      .addCase(fetchFormRespostas.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.payload;
      })
      .addCase(sendFormRespostas.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(sendFormRespostas.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.formRespostasData = action.payload;
      })
      .addCase(sendFormRespostas.rejected, (state, action) => {
        state.loading = 'rejected';
        if (typeof action.payload === 'string') {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = 'Ocorreu um erro desconhecido';
        }
      });
  },
});

export const {
  clearFormRespostasState,
  setFver,
  clearFver,
  setQuery,
  setFetching,
} = formRespostasSlice.actions;

export default formRespostasSlice.reducer;
