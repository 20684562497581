import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type InformacoesGranjaProps = {
  data: LIA.Response;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};
type TipoExploracao =
  | 'CORTE'
  | 'POSTURA_COM_RECRIA'
  | 'POSTURA_SEM_RECRIA'
  | 'OUTROS';

type TipoExploracaoLabels = {
  [key in TipoExploracao]: string;
};

export default function PrintInformacoesGranja({
  data,
}: InformacoesGranjaProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((item) => (
              <Descriptions.Item
                key={item.value}
                label={item.label}
                span={item.span ? item.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {item.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };
  const tipoExploracaoLabels: TipoExploracaoLabels = {
    CORTE: 'Corte',
    POSTURA_COM_RECRIA: 'Postura com recria',
    POSTURA_SEM_RECRIA: 'Postura sem recria',
    OUTROS: 'Outros',
  };

  const getTipoExploracaoLabel = (codigo?: string): string => {
    return (
      tipoExploracaoLabels[codigo as keyof TipoExploracaoLabels] ||
      'Não especificado'
    );
  };
  return (
    <>
      <div>
        {data &&
          panelDescription('Informações da Granja', [
            ...(data?.registro
              ? [
                  {
                    label: 'Registro',
                    value: 'Sim',
                  },
                ]
              : []),
            ...(data?.manutencaoRegistro
              ? [
                  {
                    label: 'Manutenção do Registro',
                    value: 'Sim',
                  },
                ]
              : []),
            {
              label: 'Tipo de Exploração',
              value: getTipoExploracaoLabel(data?.tipoExploracao),
            },
          ])}
      </div>
    </>
  );
}
