import { FormularioRespostas } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';

class FormularioRespostasIDBService extends ServiceIDB {
  static DB_STORE_FORM_CUSTOM_RESPOSTA = 'form_custom_resposta';
  static DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT = 'form_custom_resposta_request';

  static DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME =
    'form_custom_resposta_list_fver_codigo_verificador';
  static DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_KEY =
    'payload.fver.codigoVerificador';
  static DB_STORE_FORM_CUSTOM_RESPOSTA_INDEX_MUNICIPIO_NAME =
    'form_custom_resposta_index_municipio';
  static DB_STORE_FORM_CUSTOM_RESPOSTA_INDEX_MUNICIPIO_KEY =
    'payload.municipio.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(
        FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT
      )
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }
  
  static async getByCodigoVerificador(
    codigoVerificador: string
  ): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
      this
        .DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT_INDEX_FVER_CODIGO_VERIFICADOR_NAME,
      codigoVerificador
    );
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT
    );
    return list;
  }

  static async getAllPayload(): Promise<FormularioRespostas.Summary[]> {
    let list: FormularioRespostas.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getAllFromInputTable(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT
    );
    return list;
  }

  static async getAllFromInputTableOnlyPayload(): Promise<
    FormularioRespostas.Request[]
  > {
    let list: FormularioRespostas.Request[] = [];

    await this.getAllFromInputTable().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<FormularioRespostas.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const formularioResposta = await db.get(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
      id
    );
    return formularioResposta.payload;
  }

  static async getByIdFromInputTable(
    id: number
  ): Promise<FormularioRespostas.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const formularioResposta = await db.get(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
      id
    );
    return formularioResposta ? formularioResposta.payload : null;
  }

  static async insert(id: Number, formResposta: FormularioRespostas.Request) {
    const db = await openDB(DBConfig.DB_NAME);
    const obj: ServiceIDBPayloadInput = {
      date: new Date(),
      payload: { id, ...formResposta },
      status: 'NOVO',
    };

    return db.add(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT, obj);
  }

  static async addAllFormularioRespostas(lista: FormularioRespostas.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT, obj).catch((e) => {
        return;
      });
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT);
  }

  static async deleteAll_InputTable() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT, id);
  }

  static async update(id: number, obj: ServiceIDBPayloadInput) {
    this.delete(id);

    const db = await openDB(DBConfig.DB_NAME);
    return db.add(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT, obj);
  }

  static async put(id: number, obj: FormularioRespostas.Request[]) {
    const db = await openDB(DBConfig.DB_NAME);
    const old: ServiceIDBPayloadInput = await db.get(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
      id
    );
    const newFormularioRespostas: ServiceIDBPayloadInput = {
      ...old,
      payload: obj,
    };

    db.put(
      FormularioRespostasIDBService.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT,
      newFormularioRespostas
    );
  }

  static async count() {
    return this._count(this.DB_STORE_FORM_CUSTOM_RESPOSTA_INPUT);
  }
  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_FORM_CUSTOM_RESPOSTA,
      this.DB_STORE_FORM_CUSTOM_RESPOSTA_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default FormularioRespostasIDBService;
