import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Revenda } from '../../sdk/@types/Revenda';
import RevendasIDBService from '../../sdk/services/indexeddb/RevendasIDB.service';
import RevendaService from '../../sdk/services/SIZ-API/Revenda.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface RevendaState {
  revenda: Revenda.Summary[] | null;
  listaRevenda: Revenda.Summary[] | null;
  fetching: boolean;
  loading: LoadingState;
  query: Revenda.Query;
}

const initialState: RevendaState = {
  revenda: null,
  listaRevenda: null,
  fetching: false,
  loading: 'idle',
  query: {} as Revenda.Query,
};

export const fetchAllRevendasByMunicipio = createAsyncThunk(
  'revendas/fetchAllRevendasByMunicipio',
  async (codgIBGE: string, { rejectWithValue }) => {
    try {
      const result = await RevendaService.getByMunicipio(codgIBGE);
      return result;
    } catch (error) {
      console.error('Error in fetchAllRevendas:', error);
      return rejectWithValue(error);
    }
  }
);
export const fetchByCNPJ = createAsyncThunk<Revenda.Summary[], string>(
  'revendas/fetchByCNPJ',
  async (cnpj: string, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        const result = await RevendaService.getByCNPJ(cnpj);
        return result;
      } else {
        const lista = await RevendasIDBService.getByCnpj(cnpj);
        if (!lista) {
          throw new Error('Não foram encontradas revendas offline');
        }
        return Array.isArray(lista.payload) ? lista.payload : [lista.payload];
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error in fetchByCnpj:', error);
        return rejectWithValue(
          error.message || 'Erro desconhecido ao buscar por cnpj'
        );
      } else {
        return rejectWithValue('Erro desconhecido');
      }
    }
  }
);

export const fetchByNome = createAsyncThunk<Revenda.Summary[], string>(
  'revendas/fetchByNome',
  async (nome: string, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        const result = await RevendaService.getByNome(nome);
        return result;
      } else {
        const lista = await RevendasIDBService.getByNome(nome);
        if (!lista || lista.length === 0) {
          throw new Error('Não foram encontradas revendas offline');
        }
        return lista.map((item) => item.payload);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error in fetchByNome:', error);
        return rejectWithValue(
          error.message || 'Erro desconhecido ao buscar por nome'
        );
      } else {
        return rejectWithValue('Erro desconhecido');
      }
    }
  }
);

export const clearRevenda = () => (dispatch: any) => {
  dispatch({ type: 'revenda/clear' });
};

const revendasSlice = createSlice({
  name: 'revendas',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<Revenda.Query>) => {
      state.query = action.payload;
    },
    clear: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRevendasByMunicipio.pending, (state) => {
        state.fetching = true;
        state.loading = 'pending';
      })
      .addCase(fetchAllRevendasByMunicipio.fulfilled, (state, action) => {
        state.fetching = false;
        state.loading = 'fulfilled';
        state.revenda = action.payload as Revenda.Summary[];
      })
      .addCase(fetchAllRevendasByMunicipio.rejected, (state, action) => {
        state.fetching = false;
        state.loading = 'rejected';
        state.revenda = null;
      })
      .addCase(fetchByCNPJ.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchByCNPJ.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.revenda = action.payload as Revenda.Summary[];
      })
      .addCase(fetchByCNPJ.rejected, (state, action) => {
        state.loading = 'rejected';
        state.revenda = null;
      })
      // Manipulando o thunk fetchByNome
      .addCase(fetchByNome.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchByNome.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.revenda = action.payload as Revenda.Summary[];
      })
      .addCase(fetchByNome.rejected, (state, action) => {
        state.loading = 'rejected';
        state.revenda = null;
      });
  },
});

export const { setQuery, clear } = revendasSlice.actions;

export default revendasSlice.reducer;
