import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import SinaisClinicosPanel from '../../components/SinaisClinicosPanel';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { useEffect, useState } from 'react';
import { SimNao } from '../../../core/enums/SimNao';

type VigilanciaBubalinosProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaBubalinos(props: VigilanciaBubalinosProps) {
  const [statusHaSuspeitaClinica, setStatusHaSuspeitaClinica] = useState();

  useEffect(() => {
    setStatusHaSuspeitaClinica(
      props.form.getFieldValue(['vigilanciaBubalino', 'haSuspeitaClinica'])
    );
  }, [props.form]);

  const triggerRecountTotaExaminadosBubalinos = () => {
    const _00_12_Femeas: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados00_04_F',
    ]);
    const _00_12_Machos: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados00_04_M',
    ]);
    const _05_12_Femeas: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados05_12_F',
    ]);
    const _05_12_Machos: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados05_12_M',
    ]);
    const _13_24_Femeas: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados13_24_F',
    ]);
    const _13_24_Machos: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados13_24_M',
    ]);
    const _25_36_Femeas: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados25_36_F',
    ]);
    const _25_36_Machos: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionados25_36_M',
    ]);
    const _36_AcimaFemeas: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionadosAcima36_F',
    ]);
    const _36_AcimaMachos: number = props.form.getFieldValue([
      'vigilanciaBubalino',
      'quantidadeInspecionadosAcima36_M',
    ]);

    const dados = [
      _00_12_Femeas,
      _00_12_Machos,
      _05_12_Femeas,
      _05_12_Machos,
      _13_24_Femeas,
      _13_24_Machos,
      _25_36_Femeas,
      _25_36_Machos,
      _36_AcimaFemeas,
      _36_AcimaMachos,
    ];

    let totalAnimaisExaminados: number = 0;

    dados.forEach((qtdade) => {
      if (!isNaN(Number(qtdade))) {
        totalAnimaisExaminados =
          Number(totalAnimaisExaminados) + Number(qtdade);
      }

      props.form.setFieldValue(
        ['vigilanciaBubalino', 'quantidadeExaminados'],
        totalAnimaisExaminados
      );
    });
  };

  return (
    <>
      <Divider orientation='left'>Bubalinos examinados</Divider>

      <Row gutter={24}>
        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='00-04'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados00_04_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados00_04_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='05-12'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados05_12_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados05_12_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='13-24'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados13_24_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados13_24_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='25-36'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados25_36_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionados25_36_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Acima de 36'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Fêmeas'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionadosAcima36_F',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Machos'}
                  name={[
                    'vigilanciaBubalino',
                    'quantidadeInspecionadosAcima36_M',
                  ]}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaExaminadosBubalinos();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Total'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Tooltip
                  title={
                    'Preencher com todos os animais observados, inclusive os examinados'
                  }
                >
                  <Form.Item
                    label={'Vistoriados'}
                    name={['vigilanciaBubalino', 'quantidadeVistoriados']}
                    rules={[
                      {
                        validator(_, vistoriados) {
                          let isValid;
                          var examinados = props.form.getFieldValue([
                            'vigilanciaBubalino',
                            'quantidadeExaminados',
                          ]);

                          console.log('value', vistoriados);
                          console.log('totalAnimaisExaminados', examinados);

                          if (
                            examinados === undefined &&
                            vistoriados === undefined
                          ) {
                            console.log('1');

                            isValid = true;
                          } else if (examinados === undefined) {
                            console.log('2');
                            isValid = true;
                          } else if (vistoriados === undefined) {
                            console.log('3');
                            isValid = false;
                          } else {
                            console.log('4');
                            isValid = vistoriados >= examinados;
                          }

                          console.log('VISTORIADOS, ', vistoriados, isValid);

                          if (isValid) return Promise.resolve();
                          else
                            return Promise.reject(
                              'Deve ser maior que o total de Examinados'
                            );
                        },
                      },
                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      inputMode='numeric'
                      maxLength={4}
                      autoComplete='off'
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Examinados'}
                  name={['vigilanciaBubalino', 'quantidadeExaminados']}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Há suspeita clínica?'}
            name={['vigilanciaBubalino', 'haSuspeitaClinica']}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHaSuspeitaClinica}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaBubalino', 'haSuspeitaClinica'],
                  e.target.value
                );
                setStatusHaSuspeitaClinica(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            dependencies={['vigilanciaBubalino', 'haSuspeitaClinica']}
            label={'Suspeita clinica'}
            name={['vigilanciaBubalino', 'suspeitaClinica']}
            rules={[
              {
                required: statusHaSuspeitaClinica,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>

        <SinaisClinicosPanel
          listaSinaisClinicos={SinaisClinicos.bufalos()}
          pathToCoordenada={'vigilanciaBubalino'}
          form={props.form}
          required={statusHaSuspeitaClinica}
        />
      </Row>
    </>
  );
}
