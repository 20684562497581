import { Row, Typography, Descriptions, Card, Button } from 'antd';
import { LIA } from '../../../sdk/@types';
import moment from 'moment';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ParecerLaudoInspecao } from '../../../core/enums/ParecerLaudoInspecao';

interface PanelLIAProps {
  customTitle?: string;
  lia: LIA.Response;
  showPrintButton?: boolean;
}

export default function PanelLIA(props: PanelLIAProps) {
  return (
    <Card
      title={
        <Row justify='space-between'>
          <Typography.Title level={4}>
            {props.customTitle ? props.customTitle : 'LIA'}
          </Typography.Title>

          {props.showPrintButton && (
            <Link
              rel='noopener noreferrer'
              target='_blank'
              to={`/public/visualizarLIA/${props.lia.codigoVerificador}`}
            >
              <Button className='top' type='primary' icon={<PrinterOutlined />}>
                Imprimir
              </Button>
            </Link>
          )}
        </Row>
      }
    >
      <Descriptions column={3} labelStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label='Identificador' span={1}>
          {props.lia?.id}
        </Descriptions.Item>

        <Descriptions.Item label='Tipo' span={2}>
          {props.lia?.registro ? 'Registro' : 'Manutenção do Registro'}
        </Descriptions.Item>

        <Descriptions.Item label='Parecer Final' span={2}>
          {ParecerLaudoInspecao.valueOf(props.lia.parecer!)}
        </Descriptions.Item>

        <Descriptions.Item label='Data' span={2}>
          {moment(props.lia?.dataParecer).format('DD/MM/YYYY HH:mm')}
        </Descriptions.Item>

        <Descriptions.Item label='Tipo da Exploração' span={3}>
          {props.lia.tipoExploracao}
        </Descriptions.Item>

        <Descriptions.Item
          label='Houve necessidade de avaliação de risco?'
          span={3}
        >
          {props.lia.houveNecessidadeDeAvaliacaoDeRisco_7_1}
        </Descriptions.Item>

        <Descriptions.Item
          label='É necessária execução de Medidas de Biosseguridade Compensatórias?'
          span={3}
        >
          {
            props.lia
              .necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2
          }
        </Descriptions.Item>

        {/* <Descriptions.Item label='Documentos' span={3}>
          {props.lia.requerimentoSolicitacaoDescricao_8_1 &&
            'Requerimento de solicitação ao INDEA-MT, na forma do Anexo II (disponível no site do INDEA)'}
          <br />
          {props.lia.possuiCadastroProprietario_8_2 &&
            'Cadastro de proprietário e cadastro de propriedade no INDEA/MT'}
          <br />
          {props.lia.possuiResponsabilidadeTecnica_8_3 &&
            'Anotação de responsabilidade técnica do médico veterinário que realiza o controle higiênico-sanitário do estabelecimento avícola'}
          <br />
          {props.lia.possuiPlantaLocalizacaoPropriedade_8_4 &&
            'Planta de localização da propriedade ou outro instrumento, a critério do INDEA-MT, capaz de demonstrar as instalações, estradas, cursos d`água, propiedades limítrofes e suas respectivas atividades'}
          <br />
          {props.lia.possuiPlantaBaixaEstabelecimento_8_5 &&
            'Planta baixa das instalações do estabelecimento ou outro instrumento, a critério do INDEAS-MT, capaz de demontrar toda a infraestrutura instalada'}
          <br />
          {props.lia.possuiMemorialMedidasHigienicoSanitarias_8_6 &&
            'Memorial descritivo das medidas higiênico-sanitárias e de biossegurança que serão adotadas pelo estabelecimento avícola e dos processos tecnológicos, contendo descrição'}
          <br />
          {props.lia.possuiDocumentoComprobatorioQualidadeAgua_8_7 &&
            'Documento comprobatório da qualidade microbiológica da água de consumo das aves'}
        </Descriptions.Item> */}
      </Descriptions>
    </Card>
  );
}
