import generateHash from '../../../core/functions/generateHash';
import { LIA } from '../../@types/LIA';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class LIAService extends Service {
  static BASE_URL = '/laudos-ifseac';

  static getAll(query: LIA.Query): Promise<LIA.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<LIA.Paginated>(this.BASE_URL.concat(queryString)).then(
      this.getData
    );
  }

  static add(liaRequest: LIA.Request) {
    const liaDTO = {
      ...liaRequest,
      codigoVerificador: generateHash(liaRequest),
    };

    return this.Http.post<LIA.Request>(this.BASE_URL, liaDTO).then(
      this.getData
    );
  }

  static async update(id: number, data: LIA.Request) {
    const liaDTO = {
      ...data,
      codigoVerificador: generateHash(data),
    };

    return this.Http.put<LIA.Request>(`${this.BASE_URL}/${id}`, liaDTO).then(
      this.getData
    );
  }

  static getById(id: number) {
    return this.Http.get<LIA.Response>(`${this.BASE_URL}/${id}`).then(
      this.getData
    );
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`${this.BASE_URL}/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static getByCodigoVerificador_PublicAccess(codigoVerificador: string) {
    return this.Http.get<LIA.Response>(
      `${this.BASE_URL}/publico/codigo/${codigoVerificador}`
    ).then(this.getData);
  }
}

export default LIAService;
