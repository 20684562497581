import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Affix, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFVER from '../../../core/hooks/useFVER';
import PrintFVER from '../reports/PrintFVER';

export default function PrintFVERView() {
  usePageTitle('FVER');

  const { setLoading, setMode } = useLoadingPage();

  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();

  const { fetchFVERById, fver } = useFVER();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      fetchFVERById(Number(params.id)).catch((e) => {
        setError(new Error(e.message));
      });
    }
  }, [fetchFVERById, params]);

  useEffect(() => {}, [fver]);

  if (error) throw error;

  useEffect(() => {
    setLoading(true);
    setMode('VIEW');
  }, [setLoading, setMode]);

  return (
    <>
      <Affix offsetTop={80} className='printButton'>
        <Button
          className='top'
          type='primary'
          onClick={() => {
            //window.print();
            reactPrint();
          }}
          icon={<PrinterOutlined />}
        >
          Imprimir
        </Button>
      </Affix>
      <div ref={printRef}>
        {fver?.status === 'CANCELADO' && (
          <div className='marcadagua'>{'CANCELADO'}</div>
        )}
        <table
          style={{
            tableLayout: 'fixed',
            width: '100%',
          }}
        >
          <PrintFVER fver={fver} />
        </table>
      </div>
    </>
  );
}
