import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Descriptions,
  Divider,
  Form,
  List,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  notification,
} from 'antd';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { useCallback, useEffect, useState } from 'react';
import { AtendimentoErroSincronizacao } from '../../../sdk/@types/AtendimentoErroSincronizacao';
import moment from 'moment';
import {
  ArrowRightOutlined,
  CopyOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';
import { StatusAtendimentoErroSincronizacao } from '../../../core/enums/StatusAtendimentoErroSincronizacao';
import TextArea from 'antd/lib/input/TextArea';
import AtendimentoErroSincronizacaoService from '../../../sdk/services/SIZ-API/AtendimentoErrosSincronizacao.service';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import { ServiceIDBPayloadInput } from '../../../sdk/services/indexeddb/ServiceIDB';
import PermissionsService from '../../../core/auth/Permissions.service';
import { AndamentoErroSincronizacao } from '../../../sdk/@types/AndamentoErroSincronizacao';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

export default function FormAtendimentoErrosSincronizacaoView() {
  usePageTitle('Erro de sincronização');
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(false);

  const listaStatusAtendimentoErroSincronizacao =
    StatusAtendimentoErroSincronizacao.keys();

  const [atendimentoErroSincronizacao, setAtendimentoErroSincronizacao] =
    useState<AtendimentoErroSincronizacao.Response>();

  const fetchAtendimentoErrosSincronizacao = useCallback(async () => {
    setFetching(true);
    await AtendimentoErroSincronizacaoService.getById(Number(params.id))
      .then(setAtendimentoErroSincronizacao)
      .finally(() => setFetching(false));
  }, [params]);

  const addJSON = useCallback(
    async (
      tipoFormulario: AtendimentoErroSincronizacao.Summary['tipoFormulario'],
      json: string
    ) => {
      if (tipoFormulario === 'FVER') {
        const fver: ServiceIDBPayloadInput = JSON.parse(json);
        await FVERIDBService.insert(fver.payload, {
          status: fver.status,
          motivoErro: fver.motivoErro,
        })
          .then(() =>
            notification.info({ message: 'FVER incluído com sucesso' })
          )
          .catch((e) =>
            notification.error({
              message: 'Ocorreu um erro ao incluir o FVER',
              description: e.message,
            })
          );
      } else if (tipoFormulario === 'FVV') {
        //add FVV
      } else if (tipoFormulario === 'LIA') {
        //add FVV
      } else if (tipoFormulario === 'TFR') {
        //add FVV
      }
    },
    []
  );

  const handleFormAdicionarAndamentoSubmit = useCallback(
    async (formValues) => {
      await AtendimentoErroSincronizacaoService.addAndamento({
        atendimento: atendimentoErroSincronizacao!,
        mensagem: formValues.mensagem,
        status: formValues.status,
      }).then(async () => {
        notification.success({
          message: 'Andamento incluído',
        });
        await fetchAtendimentoErrosSincronizacao();
        form.resetFields();
      });
    },
    [atendimentoErroSincronizacao, fetchAtendimentoErrosSincronizacao, form]
  );

  const getAndamentosComAlteracaoDeStatus = useCallback(() => {
    return orderAndamentoByIdDesc(
      atendimentoErroSincronizacao?.andamentos
    ).filter((andamento) => andamento.status);
  }, [atendimentoErroSincronizacao]);

  const orderAndamentoByIdDesc = (
    andamentos: AtendimentoErroSincronizacao.Response['andamentos']
  ) => {
    if (andamentos) {
      return andamentos.sort((n1, n2) => {
        if (n1.id > n2.id) return -1;
        if (n1.id < n2.id) return 1;

        return 0;
      });
    } else return [];
  };

  const getPreviousStatus = useCallback(
    (
      andamentoId: number
    ): AndamentoErroSincronizacao.Response['status'] | undefined => {
      const andamentosComAlteracaoDeStatus =
        getAndamentosComAlteracaoDeStatus();
      console.log(
        'andamentosComAlteracaoDeStatus',
        andamentosComAlteracaoDeStatus
      );

      const pos = andamentosComAlteracaoDeStatus
        .map((andamento) => andamento.id)
        .indexOf(andamentoId);

      let statusAnterior = undefined;
      if (pos + 1 < andamentosComAlteracaoDeStatus.length)
        statusAnterior = andamentosComAlteracaoDeStatus[pos + 1].status;
      console.log('statusAnterior', statusAnterior);

      return statusAnterior;
    },
    [getAndamentosComAlteracaoDeStatus]
  );

  useEffect(() => {}, [atendimentoErroSincronizacao, fetching]);

  useEffect(() => {
    fetchAtendimentoErrosSincronizacao();
  }, [fetchAtendimentoErrosSincronizacao]);

  return (
    <>
      <div style={{ marginTop: '25px' }} />
      {!atendimentoErroSincronizacao && (
        <>
          <Typography.Title level={4}>
            Não foi possível encontrar o Atendimento
          </Typography.Title>
        </>
      )}
      {atendimentoErroSincronizacao && (
        <>
          <Divider orientation='left'>Informações gerais</Divider>
          <Card title={undefined}>
            <Descriptions
              column={3}
              labelStyle={{ fontWeight: 'bold' }}
              layout='vertical'
              size={'small'}
              colon={false}
            >
              <Descriptions.Item label={'Formulário'} span={1}>
                {atendimentoErroSincronizacao.tipoFormulario}
              </Descriptions.Item>
              <Descriptions.Item label={'Data'} span={1}>
                {moment(
                  atendimentoErroSincronizacao.erroSincronizacao!?.dataCadastro
                ).format('DD/MM/YYYY HH:mm')}
              </Descriptions.Item>

              <Descriptions.Item label={'Status'} span={1}>
                <Tag
                  style={{
                    backgroundColor: `${StatusAtendimentoErroSincronizacao.colorOf(
                      atendimentoErroSincronizacao.status!
                    )}`,
                    color: 'white',
                  }}
                >
                  {StatusAtendimentoErroSincronizacao.valueOf(
                    atendimentoErroSincronizacao.status
                  )}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={'Usuário'} span={2}>
                {atendimentoErroSincronizacao.usuario?.nome}
              </Descriptions.Item>
              <Descriptions.Item label={'Versão'} span={1}>
                {atendimentoErroSincronizacao.erroSincronizacao.versao}
              </Descriptions.Item>
              <Descriptions.Item label={'Motivo'} span={3}>
                {atendimentoErroSincronizacao.erroSincronizacao.motivo}
              </Descriptions.Item>
              {PermissionsService.erroSincronizacao_editar && (
                <Descriptions.Item
                  label={'JSON'}
                  span={3}
                  contentStyle={{ overflow: 'hidden' }}
                >
                  <Row>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        display: 'block',
                        textOverflow: 'ellipsis',
                        maxWidth: '80%',
                      }}
                    >
                      {atendimentoErroSincronizacao.erroSincronizacao.json}
                    </span>

                    <Space>
                      <Button
                        type='primary'
                        onClick={async () => {
                          if (navigator.clipboard) {
                            await navigator.clipboard
                              .writeText(
                                atendimentoErroSincronizacao.erroSincronizacao
                                  .json!
                              )
                              .then(() => {
                                notification.success({
                                  message:
                                    'JSON copiadao para a área de transferência',
                                });
                              })
                              .catch((e) => {
                                notification.error({
                                  message:
                                    'Erro ao copiar o JSON para a área de transferência',
                                  description: e,
                                });
                              });
                          } else {
                            notification.error({
                              message:
                                'Erro ao copiar o JSON para a área de transferência',
                              description:
                                'Este dispositivo não tem permissão para realizar essa tarefa',
                            });
                          }
                        }}
                        icon={<CopyOutlined />}
                      />
                      <Button
                        icon={<PlusOutlined />}
                        onClick={async () =>
                          addJSON(
                            atendimentoErroSincronizacao.tipoFormulario,
                            atendimentoErroSincronizacao.erroSincronizacao.json!
                          )
                        }
                        type='primary'
                      />
                    </Space>
                  </Row>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>

          <br />
          <Divider orientation='left'>Histórico</Divider>

          <VerticalTimeline layout='1-column-left' lineColor={'#337ab7'}>
            {atendimentoErroSincronizacao.andamentos?.map((andamento) => (
              <VerticalTimelineElement
                className='vertical-timeline-element--work'
                id={andamento.id + andamento.status!}
                icon={
                  <Typography.Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {<UserOutlined />}
                  </Typography.Text>
                }
                iconStyle={{ backgroundColor: 'grey' }}
              >
                {andamento.status === 'NOVO' && (
                  <Typography.Text>Ocorrência iniciada.</Typography.Text>
                )}

                {!andamento.status && (
                  <Comment
                    author={
                      <>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1.4em',
                            color: 'black',
                          }}
                        >
                          {andamento.usuario.nome}
                        </span>
                      </>
                    }
                    content={<>{andamento.mensagem}</>}
                    datetime={moment(andamento.dataCadastro).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    style={{ backgroundColor: 'white' }}
                  />
                )}

                {andamento.status && andamento.status !== 'NOVO' && (
                  <Comment
                    author={
                      <>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1.4em',
                            color: 'black',
                          }}
                        >
                          Alteração de status
                        </span>
                      </>
                    }
                    content={
                      <div style={{ fontSize: '1.5em' }}>
                        {getPreviousStatus(andamento.id) && (
                          <Tag
                            style={{
                              backgroundColor: `${StatusAtendimentoErroSincronizacao.colorOf(
                                getPreviousStatus(andamento.id)!
                              )}`,
                              color: 'white',
                              fontSize: '100%',
                              lineHeight: '100%',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                            }}
                          >
                            {StatusAtendimentoErroSincronizacao.valueOf(
                              getPreviousStatus(andamento.id)!
                            )}
                          </Tag>
                        )}

                        <ArrowRightOutlined style={{ marginRight: '8px' }} />

                        <Tag
                          style={{
                            backgroundColor: `${StatusAtendimentoErroSincronizacao.colorOf(
                              andamento.status
                            )}`,
                            color: 'white',
                            fontSize: '100%',
                            lineHeight: '100%',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {StatusAtendimentoErroSincronizacao.valueOf(
                            andamento.status
                          )}
                        </Tag>
                      </div>
                    }
                    datetime={moment(andamento.dataCadastro).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    style={{ backgroundColor: 'white' }}
                  />
                )}
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>

          <br />
          <Divider orientation='left'>Adicionar Andamento</Divider>
          <Card title={undefined}>
            <Form
              autoComplete={'off'}
              form={form}
              layout={'vertical'}
              onFinish={handleFormAdicionarAndamentoSubmit}
              size={'small'}
            >
              {PermissionsService.erroSincronizacao_editar && (
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={'Status'}
                    name={'status'}
                    rules={[
                      {
                        validator() {
                          let isValid = false;
                          const status = form.getFieldValue('status');
                          const mensagem = form.getFieldValue('mensagem');

                          if (status || mensagem) isValid = true;

                          if (isValid) return Promise.resolve();
                          else
                            return Promise.reject(
                              'Preencha pelo menos um campo'
                            );
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder={'Selecione uma opção'}
                      placement={'bottomLeft'}
                    >
                      {listaStatusAtendimentoErroSincronizacao?.map((tipo) => (
                        <Select.Option key={tipo} value={tipo}>
                          {StatusAtendimentoErroSincronizacao.valueOf(tipo)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  label={'Mensagem'}
                  name={'mensagem'}
                  rules={[
                    {
                      validator() {
                        let isValid = false;
                        const status = form.getFieldValue('status');
                        const mensagem = form.getFieldValue('mensagem');

                        if (status || mensagem) isValid = true;

                        if (isValid) return Promise.resolve();
                        else
                          return Promise.reject('Preencha pelo menos um campo');
                      },
                    },
                  ]}
                >
                  <TextArea maxLength={2000} rows={4} showCount />
                </Form.Item>
              </Col>

              <Col xs={24} lg={6} md={8} sm={12}>
                <Button
                  htmlType='submit'
                  style={{ width: '100%' }}
                  type='primary'
                >
                  Adicionar
                </Button>
              </Col>
            </Form>
          </Card>
          <br />
          <Row justify='end'>
            <Col xs={12} lg={6}>
              <Button
                onClick={() => navigate('/atendimentosErrosSincronizacao')}
                style={{ width: '100%' }}
                type='primary'
              >
                Voltar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
