import {
  CloseOutlined,
  CloudUploadOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  EditTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useNavigate } from 'react-router-dom';
import useSyncFVERs from '../../../core/hooks/useSyncFVERs';
import { SYNC_VISITA_DONE } from '../../../sdk/@types/ServiceWorker.types';
import {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from '../../../sdk/services/indexeddb/ServiceIDB';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import syncFVVs from '../../../core/functions/syncFVVs';
import useSyncingState from '../../../core/hooks/useSyncingState';
import { TipoEstabelecimento } from '../../../core/enums/TipoEstabelecimento';
import FVVIDBService from '../../../sdk/services/indexeddb/FVVIDB.service';
import TroubleshootingButton from '../../components/TroubleshootingButton';
import { CustomModal } from '../../components/CustomModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useDevOptions from '../../../core/hooks/useDevOptions';
import LIAIDBService from '../../../sdk/services/indexeddb/LIAIDB.service';
import syncLIAs from '../../../core/functions/syncLIAs';
import { FVER } from '../../../sdk/@types';
import FormulariosAnexosFVER from './FormulariosAnexosFVER';
import { setFver as setFverInFormResposta } from '../../../core/store/FormResposta.slice';
import { useDispatch } from 'react-redux';

interface ListFVEROfflineProps {
  visible?: boolean;
}

export type FverOfflineList = {
  fvv?: ServiceIDBPayload | undefined;
  laudoIFSEAC?: ServiceIDBPayload | undefined;
} & ServiceIDBPayloadInput;

export default function ListFVEROffline(props: ListFVEROfflineProps) {
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const dispatch = useDispatch();
  const [syncing, setSyncing] = useState(false);
  const [listaFVERs, setListaFVERs] = useState<FverOfflineList[]>();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const { online } = useNavigatorStatus();
  const [fetching, setFetching] = useState(false);
  const [syncFVERDone, setSyncFVERDone] = useState<string | undefined>(
    undefined
  );
  const [showModalMotivoErro, setShowModalMotivoErro] =
    useState<boolean>(false);
  const [showModalDeleteFVERs, setShowModalDeleteFVERs] =
    useState<boolean>(false);
  const [motivoErro, setMotivoErro] = useState<string>();

  const { fver, setFver, setFvv, setLIA } = useSyncingState();
  const syncFVERs = useSyncFVERs();

  const [dadosModalFormulariosAnexosFVER, setDadosModalFormulariosAnexosFVER] =
    useState<{
      fver: FVER.Summary;
      id: number;
      local?: boolean;
    }>();

  const [
    modalFormulariosAssociadossVisible,
    setModalFormulariosAssociadossVisible,
  ] = useState(false);
  const [selectResposta, setSelectResposta] = useState<FVER.Summary>();

  useEffect(() => {
    dispatch(setFverInFormResposta(selectResposta as FVER.Summary));
  }, [selectResposta, dispatch]);

  if (navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === SYNC_VISITA_DONE) {
        fetchOfflineFVERs();
        setSyncFVERDone(event.data.message);
      }
    };

  const fetchFVVOfflineByFVER_CodigoVerificador = async (
    codigoVerificador: string
  ) => {
    if (!codigoVerificador) return undefined;

    return await FVVIDBService.getByCodigoVerificador(codigoVerificador);
  };

  const fetchLIAOfflineByFVER_CodigoVerificador = async (
    codigoVerificador: string
  ) => {
    if (!codigoVerificador) return undefined;

    return await LIAIDBService.getByCodigoVerificador(codigoVerificador);
  };

  const fetchOfflineFVERs = useCallback(async () => {
    setFetching(true);

    await FVERIDBService.getAllFromInputTable()
      .then(async (fvers) => {
        let quantidadeFVERsSincronizados = 0;
        let fverListAUX: FverOfflineList[] = [];
        let fverAUX: FverOfflineList;

        for (const fver of fvers) {
          if (fver.status === 'SINCRONIZADO') quantidadeFVERsSincronizados++;
          const fvv = await fetchFVVOfflineByFVER_CodigoVerificador(
            fver.payload.codigoVerificador
          );

          const lia = await fetchLIAOfflineByFVER_CodigoVerificador(
            fver.payload.codigoVerificador
          );

          fverAUX = {
            ...fver,
            fvv: fvv,
            laudoIFSEAC: lia,
          };

          fverListAUX.push(fverAUX);
        }

        setListaFVERs(fverListAUX);
        setFetching(false);

        if (quantidadeFVERsSincronizados >= 10) setShowModalDeleteFVERs(true);
      })
      .catch((e) => {
        if (e.code === 8) return;
        else {
          setError(e);
          throw e;
        }
      });
  }, []);

  const deleteFVER = useCallback(
    async (fver) => {
      setSyncing(true);
      setFver(true);
      setFvv(true);
      setLIA(true);
      if (fver.id) {
        await FVERIDBService.delete(fver.id)
          .then(async () => {
            if (fver.status !== 'SINCRONIZADO') {
              await FVVIDBService.getByCodigoVerificador(
                fver.payload.codigoVerificador
              ).then(async (vigilancia) => {
                if (vigilancia)
                  //@ts-ignore
                  await FVVIDBService.delete(vigilancia.id);
              });

              await LIAIDBService.getByCodigoVerificador(
                fver.payload.codigoVerificador
              ).then(async (lia: any) => {
                if (lia)
                  //@ts-ignore
                  await LIAIDBService.delete(lia.id);
              });
            }
          })
          .finally(async () => {
            await fetchOfflineFVERs();
            setSyncing(false);
            setFver(false);
            setFvv(false);
            setLIA(false);
            navigate(`/acoes-de-campo`);
          });
      }
    },
    [fetchOfflineFVERs, setFver, setFvv, setLIA, navigate]
  );

  const deleteSynced = useCallback(async () => {
    const listFVERsSynced = listaFVERs?.filter(
      (fver) => fver.status === 'SINCRONIZADO'
    );

    const listFVERsNotSynced = listaFVERs?.filter(
      (fver) => fver.status !== 'SINCRONIZADO'
    );

    if (listFVERsSynced)
      for (const fver of listFVERsSynced) {
        //@ts-ignore
        await FVERIDBService.delete(fver.id);
      }

    setListaFVERs(listFVERsNotSynced);
    notification.success({
      message: 'Todas os FVERs sincronizados foram removidos',
    });
  }, [listaFVERs]);

  useEffect(() => {}, [syncFVERDone]);

  useEffect(() => {
    const fetchFVERsAsync = async () => {
      await fetchOfflineFVERs();
    };
    fetchFVERsAsync();
  }, [fetchOfflineFVERs]);

  useEffect(() => {
    setFetching(false);
  }, [listaFVERs]);

  useEffect(() => {}, [fver]);

  if (error) throw error;

  return (
    <>
      <Row>
        <Space size={4} direction='vertical' style={{ width: '100%' }}>
          <Col hidden={!syncFVERDone} xs={24}>
            <Alert
              message={syncFVERDone}
              type='info'
              showIcon
              closable={false}
            ></Alert>
          </Col>
          <Col span={24}>
            <Table<FverOfflineList>
              dataSource={listaFVERs}
              size={'small'}
              rowKey={'id'}
              loading={fver}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      FVERs
                    </Typography.Title>

                    <Space>
                      <Popconfirm
                        title={'Deseja remover todos os sincronizados??'}
                        onConfirm={deleteSynced}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          size={'small'}
                          type={'primary'}
                          danger
                        />
                      </Popconfirm>
                      <Popconfirm
                        title={'Confirma a sincronização?'}
                        disabled={
                          !listaFVERs || listaFVERs.length === 0 || !online
                        }
                        onConfirm={async () => {
                          setSyncing(true);
                          setFver(true);
                          setFvv(true);
                          setLIA(true);
                          await syncFVERs()
                            .then(async (message) => {
                              notification.success({
                                message: message,
                              });
                            })
                            .then(async () => {
                              await syncFVVs(true).finally(() => setFvv(false));
                              await syncLIAs(true).finally(() => setLIA(false));
                            })
                            .then(async () => {
                              await fetchOfflineFVERs();
                            })
                            .catch(async (e) => {
                              await fetchOfflineFVERs();
                              setSyncing(false);
                              setFver(false);
                              setFvv(false);
                              setLIA(false);
                              notification.error({
                                message: 'Não foi possível sincronizar',
                                description: e,
                              });
                            })
                            .finally(() => {
                              setSyncing(false);
                              setFver(false);
                              setFvv(false);
                              setLIA(false);
                              navigate(`/acoes-de-campo`);
                            });
                        }}
                      >
                        <Button
                          icon={<CloudUploadOutlined />}
                          type={'primary'}
                          disabled={
                            !listaFVERs || listaFVERs.length === 0 || !online
                          }
                        />
                      </Popconfirm>

                      <TroubleshootingButton
                        component='FVER'
                        data={listaFVERs}
                      />
                    </Space>
                  </Row>
                );
              }}
              columns={[
                {
                  dataIndex: 'id',
                  width: idInTables ? 100 : 0,
                  render(_, fverIdb) {
                    return (
                      idInTables && (
                        <>
                          <Typography.Text>
                            {`IDB: ${fverIdb.id}`}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {`ID: ${
                              fverIdb.payload.id ? fverIdb.payload.id : '-'
                            }`}
                          </Typography.Text>
                        </>
                      )
                    );
                  },
                },
                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  title: 'Detalhes',
                  render(_, fverIdb) {
                    return (
                      <Space direction='vertical' size={0}>
                        <Space direction='horizontal' size={8} wrap={xs}>
                          {fverIdb.payload.numero && (
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{
                                    backgroundColor: 'rgba(131, 239, 156, 0.5)',
                                    borderRadius: '5px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                  }}
                                />
                              }
                              onClick={() =>
                                navigate(
                                  `/visitas/visualizar/${fverIdb.payload?.id}`
                                )
                              }
                              style={{ fontWeight: 'bold', paddingLeft: 0 }}
                              type='text'
                            >
                              {fverIdb.payload.numero}
                            </Button>
                          )}

                          <Tag
                            style={{
                              backgroundColor: `${
                                fverIdb.status === 'NOVO'
                                  ? '#09f'
                                  : fverIdb.status === 'SINCRONIZADO'
                                  ? '#2baf57'
                                  : '#ff4e4e'
                              }`,
                              color: 'white',
                            }}
                          >
                            {fverIdb.status}
                          </Tag>

                          {fverIdb.status === 'ERRO' && (
                            <Button
                              danger
                              onClick={() => {
                                setShowModalMotivoErro(true);
                                setMotivoErro(fverIdb.motivoErro);
                              }}
                              style={{ paddingLeft: 0 }}
                              type='link'
                            >
                              <span style={{ borderBottom: '1px solid' }}>
                                Detalhes
                              </span>
                            </Button>
                          )}
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {moment(
                              new Date(fverIdb.payload.dataDaVisita)
                            ).format('DD/MM/YYYY HH:mm')}
                          </Typography.Text>

                          <Tag>
                            {TipoEstabelecimento.valueOf(
                              fverIdb.payload.tipoEstabelecimento
                            ).toString()}
                          </Tag>
                          {fverIdb.payload.tipoEstabelecimento ===
                            TipoEstabelecimento.keyOf(
                              TipoEstabelecimento.ABATEDOURO
                            ) &&
                            `${fverIdb.payload.abatedouro.id} - ${fverIdb.payload.abatedouro.pessoa.nome}`}
                          {fverIdb.payload.tipoEstabelecimento ===
                            TipoEstabelecimento.keyOf(
                              TipoEstabelecimento.PROPRIEDADE
                            ) &&
                            `${fverIdb.payload.propriedade.id} - ${fverIdb.payload.propriedade.nome}`}
                          {fverIdb.payload.tipoEstabelecimento ===
                            TipoEstabelecimento.keyOf(
                              TipoEstabelecimento.RECINTO
                            ) &&
                            `${fverIdb.payload.recinto.id} - ${fverIdb.payload.recinto.pessoa.nome}`}
                        </Space>
                      </Space>
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(id, fver: FverOfflineList) {
                    return (
                      <>
                        <Space>
                          {fver.status !== 'SINCRONIZADO' && (
                            <Button
                              icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                              onClick={() =>
                                navigate(`/visitas/edicao/${id}/${'local'}`)
                              }
                              size={'small'}
                              type={'ghost'}
                            />
                          )}

                          <Button
                            icon={<DatabaseOutlined />}
                            onClick={async () => {
                              setDadosModalFormulariosAnexosFVER({
                                fver: fver.payload,
                                id: fver.id!,
                                local: true,
                              });
                              setModalFormulariosAssociadossVisible(true);
                              setSelectResposta(fver.payload);
                            }}
                            size={'small'}
                            type={'ghost'}
                          />

                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size={'small'}
                            onClick={async () => {
                              try {
                                await deleteFVER(fver);
                                notification.success({
                                  message: 'FVER removido com sucesso',
                                  description: fver.fvv
                                    ? 'O FVV vinculado ao FVER também foi removido'
                                    : '',
                                });
                              } catch (e) {
                                notification.error({
                                  message: 'Erro ao remover o FVER',
                                  description: e,
                                });
                              }
                            }}
                            type={'ghost'}
                          />
                        </Space>
                      </>
                    );
                  },
                },
              ]}
              showHeader={false}
            />
          </Col>
        </Space>
      </Row>

      <CustomModal
        open={showModalMotivoErro}
        title={'Motivo'}
        footer={
          <Button
            style={{ width: '100%' }}
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => {
              setShowModalMotivoErro(false);
              setMotivoErro(undefined);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          {motivoErro}
        </Typography.Title>
      </CustomModal>

      <CustomModal
        open={showModalDeleteFVERs}
        title={'Aviso'}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '100%' }}
              type='default'
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalDeleteFVERs(false);
              }}
            >
              Fechar
            </Button>

            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<CloseOutlined />}
              onClick={async () => {
                await deleteSynced().finally(() =>
                  setShowModalDeleteFVERs(false)
                );
              }}
            >
              Remover
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>
          Notamos que você ainda está mantendo no seu dispositivo muitos FVERs
          que já foram sincronizados.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Deseja removê-los? Essa ação não irá afetar os FVERs no banco de
          dados.
        </Typography.Paragraph>
      </CustomModal>

      {dadosModalFormulariosAnexosFVER && (
        <FormulariosAnexosFVER
          idFver={dadosModalFormulariosAnexosFVER.id}
          local={dadosModalFormulariosAnexosFVER.local}
          numero={dadosModalFormulariosAnexosFVER.fver.numero}
          open={modalFormulariosAssociadossVisible}
          onClose={() => {
            setDadosModalFormulariosAnexosFVER(undefined);
            setModalFormulariosAssociadossVisible(false);
            setSelectResposta(undefined);
          }}
          closed={dadosModalFormulariosAnexosFVER.fver.id ? true : false}
        />
      )}
    </>
  );
}
