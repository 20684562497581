import { FormCustom } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormCustomService extends Service {
  static getAll(query: FormCustom.Query): Promise<FormCustom.Summary[]> {
    const queryClone = { ...query };

    // Criação de um objeto separado para os parâmetros da URL
    const queryParams = Object.keys(queryClone).reduce((acc, key) => {
      const value = queryClone[key as keyof FormCustom.Query];
      if (value !== undefined) {
        acc[key] =
          key === 'ativo'
            ? String(value)
            : Array.isArray(value)
            ? value.join(',')
            : String(value);
      }
      return acc;
    }, {} as { [key: string]: string });

    // Verificação explícita para incluir 'ativo=false' se não estiver incluído
    if (queryClone.ativo === false) {
      queryParams.ativo = 'false';
    }

    const queryString = generateQueryString(queryParams);

    return this.Http.get<FormCustom.Summary[]>(
      '/formularios'.concat(queryString)
    ).then(this.getData);
  }

  static async add(
    formFormCustomRequest: FormCustom.Request
  ): Promise<FormCustom.Request> {
    try {
      const response = await this.Http.post<FormCustom.Request>(
        '/formularios',
        formFormCustomRequest
      );
      return this.getData(response);
    } catch (error) {
      throw error;
    }
  }

  static async getById(id: number): Promise<FormCustom.Response> {
    try {
      const response = await this.Http.get<FormCustom.Response>(
        `/formularios/${id}`
      );
      return this.getData(response);
    } catch (error) {
      throw error;
    }
  }

  static async activate(id: number): Promise<FormCustom.Request> {
    try {
      const response = await this.Http.put<FormCustom.Request>(
        `/formularios/${id}/ativar`
      );
      return this.getData(response);
    } catch (error) {
      console.error('Erro ao ativar formulário:', error);
      throw error;
    }
  }

  static async deactivate(id: number): Promise<FormCustom.Request> {
    try {
      const response = await this.Http.put<FormCustom.Request>(
        `/formularios/${id}/inativar`
      );
      return this.getData(response);
    } catch (error) {
      console.error('Erro ao inativar formulário:', error);
      throw error;
    }
  }

  static async update(id: number, data: FormCustom.Request) {
    return this.Http.put<FormCustom.Request>(`formularios/${id}`, data).then(
      this.getData
    );
  }
}

export default FormCustomService;
