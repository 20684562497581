import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PrintFVERView from '../features/FVER/PrintFVERView';

export default function FVERViewWrapper() {
  usePageTitle('FVER');

  const { setLoading, setMode } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setMode('VIEW');
  }, [setLoading, setMode]);

  return (
    <>
      <ErrorBoundary>
        <PrintFVERView />
      </ErrorBoundary>
    </>
  );
}
