import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import PublicFVERPrintView from '../features/FVER/PublicFVERPrintView';

export default function FVERPublicPrintWrapper() {
  usePageTitle('FVER');

  return (
    <>
      <ErrorBoundary component='a visita'>
        <PublicFVERPrintView />
      </ErrorBoundary>
    </>
  );
}
