import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFVER from '../../../core/hooks/useFVER';
import { FVER } from '../../../sdk/@types';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import FormFVER from './FormFVER';

function EditFVER() {
  const { loading } = useLoadingPage();

  const { fetchFVERById, fver } = useFVER();

  const [fverOffline, setFVEROffline] = useState<FVER.Input>();

  const params = useParams<{ id: string; local?: string }>();

  const fetchFVEROffline = useCallback(async () => {
    return await FVERIDBService.getByIdFromInput_Table(Number(params.id)).then(
      (v) => {
        setFVEROffline(v);
      }
    );
  }, [params.id]);

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      if (!params.local) {
        fetchFVERById(Number(params.id));
      } else {
        fetchFVEROffline();
      }
    }
  }, [fetchFVERById, fetchFVEROffline, params]);

  const prepareData = useCallback((fver) => {
    const dataDaVisita = moment(fver.dataDaVisita);

    return {
      ...fver,
      dataDaVisita: dataDaVisita,
      horaDaVisita:
        (dataDaVisita.get('hour') + '').padStart(2, '0') +
        ':' +
        (dataDaVisita.get('minute') + '').padStart(2, '0'),
    };
  }, []);

  return (
    <>
      {!fver && !fverOffline && <Skeleton active />}

      {(fver || fverOffline) && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando FVER</Typography.Title>
          </Divider>

          <Spin
            size='large'
            spinning={loading}
            style={{
              position: 'fixed',
              top: '20%',
              zIndex: '1',
            }}
          >
            <FormFVER fver={prepareData(!params.local ? fver : fverOffline)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditFVER;
