export enum StatusAtendimentoErroSincronizacao {
  CORRIGIDO = 'Corrigido',
  EM_ANALISE = 'Em análise',
  EM_ESPERA = 'Em espera',
  NAO_NECESSITA_CORRECAO = 'Não necessita correção',
  NOVO = 'Novo',
}
export namespace StatusAtendimentoErroSincronizacao {
  export function keys(): string[] {
    return Object.keys(StatusAtendimentoErroSincronizacao).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) =>
        key !== 'keys' &&
        key !== 'valueOf' &&
        key !== 'keyOf' &&
        key !== 'colorOf'
    );
  }

  export function valueOf(key: string) {
    return StatusAtendimentoErroSincronizacao[
      key as keyof typeof StatusAtendimentoErroSincronizacao
    ];
  }

  export function colorOf(status: string) {
    const value = valueOf(status);

    switch (value) {
      case StatusAtendimentoErroSincronizacao.CORRIGIDO: {
        return '#67eba9';
      }
      case StatusAtendimentoErroSincronizacao.EM_ESPERA: {
        return '#d6f9a5';
      }
      case StatusAtendimentoErroSincronizacao.EM_ANALISE: {
        return '#99d2f1';
      }
      case StatusAtendimentoErroSincronizacao.NAO_NECESSITA_CORRECAO: {
        return '#c3d4e0';
      }
      case StatusAtendimentoErroSincronizacao.NOVO: {
        return '#d75c43';
      }
      default: {
        return 'white';
      }
    }
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(StatusAtendimentoErroSincronizacao).indexOf(
      value as unknown as StatusAtendimentoErroSincronizacao
    );

    const key = Object.keys(StatusAtendimentoErroSincronizacao)[indexOfS];

    return key;
  }
}
