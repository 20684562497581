import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { TermoFiscalizacao } from '../../../sdk/@types';
import FilterPanelTFR from './FilterPanelTFR';
import { useNavigate } from 'react-router-dom';
import * as TFRActions from '../../../core/store/TFR.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useDevOptions from '../../../core/hooks/useDevOptions';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

export default function ListTFRs() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const tfrs = useSelector((state: RootState) => state.formTfr.tfrs);
  const query = useSelector((state: RootState) => state.formTfr.query);
  const fetching = useSelector((state: RootState) => state.formTfr.fetching);

  if (!online)
    throw new Error('Não é possível visualizar a lista de TFR no modo offline');
  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, []);

  useEffect(() => {}, [tfrs]);

  const handleDelete = useCallback(
    async (id) => {
      if (id) {
        setDeleting(true);
        try {
          await dispatch(TFRActions.removeTFR(id));
          setDeleting(false);
          notification.success({
            message: 'TFR removido com sucesso',
          });
          dispatch(TFRActions.filterTFRs(query));
        } catch (e: any) {
          setDeleting(false);
          notification.error({
            message: 'Não foi possível remover o TFR',
            description: e.message,
          });
        }
      }
    },
    [dispatch, query]
  );

  return (
    <>
      <FilterPanelTFR />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<TermoFiscalizacao.Summary>
                columns={[
                  {
                    dataIndex: 'id',
                    width: idInTables ? 100 : 0,
                    render(id) {
                      return (
                        idInTables && (
                          <Typography.Text>{`ID: ${id}`}</Typography.Text>
                        )
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    render(_, tfr) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Typography.Text strong>{tfr.numero}</Typography.Text>

                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {moment(new Date(tfr.dataVisita!)).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </Typography.Text>

                            <Typography.Text>
                              {tfr.municipioFiscalizado?.nome}
                            </Typography.Text>

                            <Typography.Text>
                              {tfr.nomeFiscalizado}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<SearchOutlined />}
                            onClick={() => navigate(`/tfr/visualizar/${id}`)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Popconfirm
                            title={'Deseja remover a TFR'}
                            onConfirm={() => {
                              handleDelete(id);
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={tfrs?.content}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      TFRActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: tfrs?.totalElements,
                  pageSize: query.size,
                }}
                rowKey={'id'}
                size={'small'}
                showHeader={false}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Form TFRs
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/tfr/cadastro`)}
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(TFRActions.filterTFRs(query));
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
