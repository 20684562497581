import { TipoChavePrincipalTFR } from '../../@types';
import Service from './Service';

class TipoChavePrincipalTFRService extends Service {
  static getAllActive() {
    return this.Http.get<TipoChavePrincipalTFR.Summary[]>(
      '/tipos-chaves-principais-termo-fiscalizacao',
      { timeout: 600000 }
    ).then(this.getData);
  }
}

export default TipoChavePrincipalTFRService;
