import { TipoChavePrincipalTFR } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';

class TipoChavePrincipalTFRIDBService {
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA =
    'tipoChavePrincipalTermoFiscalizacaoRevendas';
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA_INDEX_NAME =
    'tipoChavePrincipalTermoFiscalizacaoRevenda_index_id';
  static DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA_INDEX_KEY =
    'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(
        TipoChavePrincipalTFRIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA
      )
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      TipoChavePrincipalTFRIDBService.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA
    );
    return list;
  }

  static async getAllPayload(): Promise<TipoChavePrincipalTFR.Summary[]> {
    let list: TipoChavePrincipalTFR.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllTipoChavePrincipalTermoFiscalizacaoRevenda(
    lista: TipoChavePrincipalTFR.Summary[]
  ) {
    try {
      const db = await openDB(DBConfig.DB_NAME);
      lista.forEach((tipo) => {
        const date = new Date();
        const obj = {
          date: date,
          payload: tipo,
        };

        db.add(
          this.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA,
          obj
        ).catch((e) => {
          return;
        });
      });
    } catch (e) {
      return;
    }
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_TIPO_CHAVE_PRINCIPAL_TERMO_FISCALIZACAO_REVENDA);
  }
}

export default TipoChavePrincipalTFRIDBService;
