import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { useNavigate } from 'react-router-dom';
import * as LocalAcaoEducativaActions from '../../../core/store/LocalAcaoEducativa.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { LocalAcaoEducativa } from '../../../sdk/@types';
import FilterPanelLocalAcaoEducativa from './FilterPanelLocalAcaoEducativa';

export default function ListLocalAcaoEducativa() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { xs, sm } = useBreakpoint();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const localAcaoEducativa = useSelector(
    (state: RootState) => state.localAcaoEducativo.localAcaoEducativas
  );
  const query = useSelector(
    (state: RootState) => state.localAcaoEducativo.query
  );
  const fetching = useSelector(
    (state: RootState) => state.localAcaoEducativo.fetching
  );

  if (!online)
    throw new Error(
      'Não é possível visualizar a lista de Local Ação Educativa no modo offline'
    );
  if (error) throw error;

  useEffect(() => {
    dispatch(LocalAcaoEducativaActions.filterLocalAcaoEducativa(query));
    setFirstOpening(true);
  }, [dispatch, query]);

  const handleUpdate = useCallback(
    async (id, isActive) => {

      if (id) {
        try {
          setLoading(true);
          if (isActive === 'ATIVO') {
            await dispatch(
              LocalAcaoEducativaActions.deactivateLocalAcaoEducativa(id)
            );
            notification.success({
              message: `Formulário inativado com sucesso.`,
            });
          } else {
            await dispatch(
              LocalAcaoEducativaActions.activateLocalAcaoEducativa(id)
            );
            notification.success({
              message: `Formulário ativado com sucesso.`,
            });
          }
        } catch (error) {
          notification.error({
            message: `Erro ao ${
              isActive === 'INATIVAR' ? 'inativar' : 'ativar'
            } formulário.`,
            description:
              error instanceof Error ? error.message : 'Erro desconhecido',
          });
        } finally {
          setLoading(false);
          dispatch(LocalAcaoEducativaActions.filterLocalAcaoEducativa(query));
        }
      }
    },
    [dispatch, setLoading, query]
  );
  const handleEditClick = (id: number) => {
    dispatch(LocalAcaoEducativaActions.clearLocalAcaoEducativaState());
    navigate(`/locaisAcaoEducativa/edicao/${id}`);
  };

  const handleViewClick = (id: number) => {
    dispatch(LocalAcaoEducativaActions.clearLocalAcaoEducativaState());
    navigate(`/locaisAcaoEducativa/visualizar/${id}`);
  };

  return (
    <>
      <FilterPanelLocalAcaoEducativa />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<LocalAcaoEducativa.Summary>
                columns={[
                  {
                    dataIndex: 'nome',
                    title: 'Nome',
                    render(nome) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>{nome || '-'}</Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'tipoLocalAcaoEducativa',
                    title: 'Local da ação educativa',
                    render(tipoLocalAcaoEducativa) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {tipoLocalAcaoEducativa || '-'}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'status',
                    title: 'Status',
                    render(id: number, record: LocalAcaoEducativa.Summary) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Switch
                            checked={record.status === 'ATIVO'}
                            onChange={() =>
                              handleUpdate(record.id, record.status)
                            }
                            size='small'
                          />
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Button
                            icon={<PrinterOutlined />}
                            onClick={() => handleViewClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={localAcaoEducativa as LocalAcaoEducativa.Summary[]}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      LocalAcaoEducativaActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  //MODIFICAR COM DADOS REAIS
                  total: [localAcaoEducativa].length,
                  pageSize: query.size,
                }}
                rowKey={'id'}
                size={'small'}
                showHeader={true}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Lista de Locais Ação Educativa
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() =>
                              navigate(`/locaisAcaoEducativa/cadastro`)
                            }
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(
                                  LocalAcaoEducativaActions.filterLocalAcaoEducativa(
                                    query
                                  )
                                );
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
