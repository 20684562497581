import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import { EmpresaIntegradora } from '../../sdk/@types/EmpresaIntegradora';
import LIAIDBService from '../../sdk/services/indexeddb/LIAIDB.service';
import { LIA } from '../../sdk/@types/LIA';
import LIAService from '../../sdk/services/SIZ-API/LIA.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface LIAState {
  loading: LoadingState;
  data: LIA.Response | null;
  empresasIntegradoras: EmpresaIntegradora.Summary[] | null;
  empresaIntegradoraSelecionada: EmpresaIntegradora.Summary | null;
  lias: LIA.Paginated | null;
  fetching: boolean;
  query: LIA.Query;
  errorMessage?: string | null;
  formDataSteps: any;
  isFavoravelRegistro: boolean | null;
  lia: number | null;
  hasFieldWithNoValueDistanciasInternas: boolean | null;
  hasFieldWithNoValueDistanciasExternas: boolean | null;
}

const initialState: LIAState = {
  data: null,
  lias: null,
  empresasIntegradoras: null,
  loading: 'idle',
  errorMessage: null,
  query: { page: 0, size: 10 },
  fetching: false,
  formDataSteps: {},
  isFavoravelRegistro: null,
  lia: null as number | null,
  hasFieldWithNoValueDistanciasInternas: null,
  hasFieldWithNoValueDistanciasExternas: null,
  empresaIntegradoraSelecionada: null,
};

export const insertLIA = createAsyncThunk(
  'lia/insert',
  async (liaRequest: LIA.Request, { rejectWithValue }) => {
    if (navigator.onLine) {
      return await LIAService.add(liaRequest).catch(rejectWithValue);
    } else {
      return LIAIDBService.insert(liaRequest).catch(rejectWithValue);
    }
  }
);
export const filterLIA = createAsyncThunk(
  'lia/filterLIAs',
  async (query: LIA.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const lias = await LIAService.getAll(query);
      return lias;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os Termo Fiscalizacaos')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);
export const fetchLIAById = createAsyncThunk<LIA.Response, number>(
  'lia/fetchLIAById',
  async (id: number, { rejectWithValue }) => {
    return await LIAService.getById(id).catch(rejectWithValue);
  }
);
export const updateLIA = createAsyncThunk(
  'lia/updateLIA',
  async (
    { id, lia }: { id: number; lia: LIA.Request },
    { rejectWithValue }
  ) => {
    return await LIAService.update(id, lia).catch(rejectWithValue);
  }
);

export const removeLIA = createAsyncThunk(
  'lia/remove',
  async (id: number, { rejectWithValue }) => {
    return await LIAService.remove(id).catch(rejectWithValue);
  }
);
export const updateLIAOffline = createAsyncThunk(
  'lia/updateLIAOffline',
  async (
    { id, lia }: { id: number; lia: LIA.Request },
    { rejectWithValue }
  ) => {
    let newLIA: ServiceIDBPayloadInput = {
      id: id,
      payload: {
        ...lia,
      },
      status: 'NOVO',
      date: new Date(),
    };

    return await LIAIDBService.update(id, newLIA).catch(rejectWithValue);
  }
);

export const selectLIA = (state: RootState) => state.lia.data;

export const selectHasNAOValueDistanciasInternas = (state: RootState) =>
  state.lia.hasFieldWithNoValueDistanciasInternas;

export const selectHasNAOValueDistanciasExternas = (state: RootState) =>
  state.lia.hasFieldWithNoValueDistanciasExternas;

const LiaSlice = createSlice({
  name: 'lia',
  initialState,
  reducers: {
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<LIA.Query>) {
      state.query = action.payload;
    },
    updateFormatDataSteps: (state, action) => {
      state.formDataSteps = { ...state.formDataSteps, ...action.payload };
    },
    resetFormatDataSteps: (state) => {
      state.formDataSteps = {};
    },
    setIsFavoravelRegistro: (state, action) => {
      state.isFavoravelRegistro = action.payload;
    },
    setHasFieldWithNoValueDistanciasInternas: (state, action) => {
      state.hasFieldWithNoValueDistanciasInternas = action.payload;
    },
    setHasFieldWithNoValueDistanciasExternas: (state, action) => {
      state.hasFieldWithNoValueDistanciasExternas = action.payload;
    },
    setLIAId: (state, action: PayloadAction<number | null>) => {
      state.lia = action.payload;
    },
    resetLIAId: (state) => {
      state.lia = null;
    },
    setEmpresaIntegradoraSelecionada: (
      state,
      action: PayloadAction<EmpresaIntegradora.Summary | null>
    ) => {
      state.empresaIntegradoraSelecionada = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertLIA.pending, (state) => {
        state.loading = 'pending';
        state.errorMessage = null;
      })
      .addCase(insertLIA.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(insertLIA.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(filterLIA.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterLIA.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.lias = action.payload;
        state.fetching = false;
      })
      .addCase(filterLIA.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(updateLIA.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateLIA.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(updateLIA.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchLIAById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchLIAById.fulfilled,
        (state, action: PayloadAction<LIA.Response | undefined>) => {
          state.loading = 'fulfilled';
          //@ts-ignore
          state.data = action.payload;
        }
      )
      .addCase(fetchLIAById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(updateLIAOffline.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateLIAOffline.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
      })
      .addCase(updateLIAOffline.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      });
  },
});
export const {
  setQuery,
  setFetching,
  updateFormatDataSteps,
  resetFormatDataSteps,
  setIsFavoravelRegistro,
  setLIAId,
  setHasFieldWithNoValueDistanciasInternas,
  setHasFieldWithNoValueDistanciasExternas,
  setEmpresaIntegradoraSelecionada,
} = LiaSlice.actions;

const LiaReducer = LiaSlice.reducer;
export default LiaReducer;
