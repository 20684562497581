export enum TipoDestinoCadaveres {
  ENTERRIO = 'Enterrio',
  COMPOSTEIRA = 'Composteira',
  BIODIGESTOR = 'Biodigestor',
  OUTRO = 'Outro',
}
export namespace TipoDestinoCadaveres {
  export function keys(): string[] {
    return Object.keys(TipoDestinoCadaveres).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoDestinoCadaveres[key as keyof typeof TipoDestinoCadaveres];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoDestinoCadaveres).indexOf(
      value as unknown as TipoDestinoCadaveres
    );

    const key = Object.keys(TipoDestinoCadaveres)[indexOfS];

    return key;
  }
}
