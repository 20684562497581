import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import localforage from 'localforage';
import AuthorizationService from '../auth/Authorization.service';
import { RootState } from '../../core/store';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import { EmpresaIntegradora } from '../../sdk/@types/EmpresaIntegradora';
import EmpresaIntegradoraIDBService from '../../sdk/services/indexeddb/EmpresaIntegradoraIDB.service';
import EmpresaIntegradoraService from '../../sdk/services/SIZ-API/EmpresaIntegradora.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface EmpresaIntegradoraState {
  loading: LoadingState;
  errorMessage?: string | null;
  empresasIntegradoras: EmpresaIntegradora.Summary[] | null;
  empresaIntegradoraSelecionada: EmpresaIntegradora.Summary | null;
}

const initialState: EmpresaIntegradoraState = {
  empresasIntegradoras: null,
  loading: 'idle',
  errorMessage: null,
  empresaIntegradoraSelecionada: null,
};

export const fetchAllEmpresasIntegradoras = createAsyncThunk(
  'lia/fetchAllEmpresasIntegradoras',
  async (_, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        return await EmpresaIntegradoraService.getAllEmpresasIntegradoras();
      } else {
        const lista = await EmpresaIntegradoraIDBService.getAllPayload();
        if (!lista || lista.length === 0) {
          throw Error('Não foram empresas independentes offline');
        }
        return lista;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const liaSlice = createSlice({
  name: 'lia',
  initialState,
  reducers: {
    setEmpresaIntegradoraSelecionada: (
      state,
      action: PayloadAction<EmpresaIntegradora.Summary | null>
    ) => {
      state.empresaIntegradoraSelecionada = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmpresasIntegradoras.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchAllEmpresasIntegradoras.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.empresasIntegradoras =
          action.payload as EmpresaIntegradora.Summary[];
      })
      .addCase(fetchAllEmpresasIntegradoras.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      });
  },
});
export const { setEmpresaIntegradoraSelecionada } = liaSlice.actions;

export default liaSlice.reducer;
