import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Affix, Button, Col, Row, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { FVV } from '../../../sdk/@types';
import FVVService from '../../../sdk/services/SIZ-API/FVV.service';
import PrintFVV from './PrintFVV';

export default function PublicFVVPrintView() {
  usePageTitle('FVV');

  const navigate = useNavigate();

  const [error, setError] = useState<Error>();

  const params = useParams<{ codigoVerificador: string }>();
  const [fvv, setFVV] = useState<FVV.Detailed>();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });

  const fetchFVVByCodigoVerificador = useCallback(
    async (codigoVerificador: string) => {
      await FVVService.getByCodigoVerificador_PublicAccess(codigoVerificador)
        .then(setFVV)
        .catch((e) => {
          /* setError(e); */
          notification.error({
            message: e.message,
          });
        });
    },
    []
  );

  useEffect(() => {}, [params]);

  useEffect(() => {
    // @ts-ignore
    fetchFVVByCodigoVerificador(params.codigoVerificador)
      .then(() => {})
      .catch((e) => {
        setError(new Error(e.message));
      });
  }, [fetchFVVByCodigoVerificador, params]);

  useEffect(() => {}, [fvv]);

  if (error) throw error;

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
      }}
    >
      <Affix offsetTop={80} className='printButton'>
        <Row gutter={12}>
          <Col>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                navigate('/home');
              }}
            >
              Voltar
            </Button>
          </Col>
          <Col>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                //window.print();
                reactPrint();
              }}
              icon={<PrinterOutlined />}
            >
              Imprimir
            </Button>
          </Col>
        </Row>
      </Affix>
      <div ref={printRef}>
        {/* {fvv?.status === 'CANCELADO' && (
          <div className='marcadagua'>{'CANCELADO'}</div>
        )} */}
        <table
          style={{
            tableLayout: 'fixed',
            width: '100%',
          }}
        >
          <PrintFVV fvv={fvv!} />
        </table>
      </div>
    </div>
  );
}
