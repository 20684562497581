import {
  LogoutOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Drawer,
  Image,
  Layout,
  Menu,
  Row,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import AuthorizationService from '../../../core/auth/Authorization.service';
import useBadgeState from '../../../core/hooks/useBadgeState';
import EnvConfig from '../../EnvConfig';
import DevTools from '../../components/DevTools';
const { Header } = Layout;

export default function DefaultLayoutHeader() {
  const { online } = useNavigatorStatus();
  const { xs } = useBreakpoint();

  const navigate = useNavigate();
  const { notificacoes } = useBadgeState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log('window.location.pathname', window.location.pathname);
  }, []);

  return (
    <>
      <Header
        className='header'
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 5,
          width: '100%',
          backgroundColor: `${!online ? '#f57878' : ''}`,
        }}
      >
        <Row
          align={'middle'}
          justify={'space-between'}
          style={{
            height: '64px',
            right: 0,
            top: 0,
            position: 'sticky',
            marginLeft: '30px',
          }}
        >
          {window.location.pathname !== '/' && (
            <Row align={'middle'} justify={'start'}>
              <Link
                style={{ display: 'flex', flexDirection: 'row' }}
                to='/home'
              >
                <Image width={50} src='/SIZ-64x64.png' preview={false} />

                <Row
                  align={'middle'}
                  justify={'start'}
                  style={{
                    paddingLeft: 6,
                  }}
                >
                  <div>
                    <Typography.Title
                      level={3}
                      style={{ padding: 0, margin: 0, color: 'white' }}
                    >
                      SIZ
                    </Typography.Title>

                    {!xs && (
                      <Typography.Title
                        level={5}
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: '12px',
                          color: 'white',
                        }}
                      >
                        {`Sistema de Informações Zoossanitárias, v${EnvConfig.version()}`}
                      </Typography.Title>
                    )}
                  </div>
                </Row>
              </Link>
            </Row>
          )}

          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: '45px',
              }}
            >
              <div>
                {(!online || AuthorizationService.isAuthenticated()) && (
                  <Button
                    icon={
                      <Badge
                        count={notificacoes}
                        overflowCount={99}
                        size='small'
                        showZero={false}
                        title={`Você tem ${notificacoes} novas notificações`}
                      >
                        <UserOutlined style={{ color: 'white' }} />
                      </Badge>
                    }
                    type={'text'}
                    style={{
                      height: '100%',
                      color: 'white',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <span style={{ paddingLeft: '10px' }}>
                      {!xs
                        ? AuthorizationService.getUsuarioSIZ()?.nome.split(
                            ' '
                          )[0]
                        : ''}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </>
        </Row>
      </Header>

      <Drawer
        title={AuthorizationService.getUsuarioSIZ()?.nome}
        placement='right'
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Menu mode='inline' style={{ height: '100%', borderRight: 0 }}>
          <Menu.Item
            key='/usuario'
            icon={<UserOutlined />}
            onClick={() => {
              setOpen(false);
              navigate('/usuario');
            }}
          >
            Meus dados
          </Menu.Item>

          <Menu.Item
            key='/notificacoes'
            icon={<NotificationOutlined />}
            onClick={() => {
              setOpen(false);
              navigate('/notificacoes');
            }}
          >
            <Badge
              count={notificacoes}
              overflowCount={99}
              size='small'
              showZero={false}
              title={`Você tem ${notificacoes} novas notificações`}
            >
              Notificações
            </Badge>
          </Menu.Item>

          <Menu.Item
            key='/logout'
            onClick={() => {
              AuthorizationService.logout();
            }}
            icon={<LogoutOutlined />}
          >
            Sair
          </Menu.Item>
        </Menu>
      </Drawer>

      <DevTools />
    </>
  );
}
