export enum TipoVinculoEpidemiologico {
  ANIMAIS_SILVESTRES_OU_CRIADOS_SOLTOS = 'Animais silvestres ou outros animais criados soltos',
  CESSAO_DE_INSTALACOES = 'Cessão de instalaçãoes para manejo de animais do estabelecimento investigado',
  EMPRESTIMO_DE_ANIMAIS = 'Empréstimo de animais (reprodutores ou de trabalho)',
  ENVIO_DE_ANIMAIS_PARA_ESTABELECIMENTO_INVESTIGADO = 'Envio de animais para o estabelecimento investigado',
  FATORES_ECOLOGICOS = 'Fatores ecológicos, incluindo presença de vetores ou hospedeiros silvestres, bem como a presença de abrigos de morcegos hematófagos',
  INGRESSO_DE_PESSOAS = 'Ingresso de pessoas (médicos veterinários, técnicos agrícolas, trabalhadores rurais, parentes, etc) que tiveram contato com animais do estabelecimento',
  INGRESSO_DE_VEICULOS = 'Ingresso de veículos que passaram pelo estabelecimento sob investigação',
  INTERCAMBIO_DE_PRODUTOS = 'Intercâmbio de produtos ou subprodutos de origem animal com estabelecimento investigado',
  INVESTIGACAO_OU_RECOMENDACAO_DE_DENUNCIA = 'Investigação originada por denúncia ou recomendação de pessoas do estabelecimento sob investigação ou demais estabelecimentos com vínculo epidemiológico',
  MEDICAMENTOS_EM_COMUM = 'Medicamentos ou vacinas em comum',
  MESMA_FONTE_DE_AGUA = "Mesma fonte d'água",
  MESMA_ORIGEM_DOS_ANIMAIS = 'Mesma origem dos animais',
  MESMA_ORIGEM_FONTE_DE_ALIMENTOS = 'Mesma origem/fonte de alimentos',
  MESMO_PROPRIETARIO = 'Mesmo proprietário ou produtor',
  PARTICIPACAO_EM_MESMO_EVENTO = 'Participação em mesmo evento com aglomeração',
  PROXIMIDADE_GEOGRAFICA = 'Proximidade geográfica',
  RECEPCAO_DE_ANIMAIS = 'Recepção de animais do estabelecimento investigado',
  RELACAO_GENEALOGICA = 'Relação genealógica',
  USO_DE_EQUIPAMENTOS = 'Uso de equipamentos ou ferramentas do estabelecimento investigado',
  USO_DE_INSTALACOES = 'Uso de instalações do estabelecimento investigado para manejar seus animais',
  VISITA_AO_ESTABELECIMENTO_INVESTIGADO = 'Alguém deste estabelecimento visitou o estabelecimento sob investigação',
  VIZINHO_DE_CERCA = 'Vizinho de cerca',
}

export namespace TipoVinculoEpidemiologico {
  export function keys(): string[] {
    return Object.keys(TipoVinculoEpidemiologico).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoVinculoEpidemiologico[
      key as keyof typeof TipoVinculoEpidemiologico
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoVinculoEpidemiologico).indexOf(
      value as unknown as TipoVinculoEpidemiologico
    );

    const key = Object.keys(TipoVinculoEpidemiologico)[indexOfS];

    return key;
  }
}
