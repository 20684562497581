import { Municipio } from '../../@types/Municipio';
import Service from './Service';

class MunicipioService extends Service {
  static getByUf(uf: string) {
    return this.Http.get<Municipio.Detailed[]>(`/municipios/uf/${uf}`).then(
      this.getData
    );
  }
}

export default MunicipioService;
