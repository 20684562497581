import { Button, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import PageNotFound from '../../assets/404.jpg';

export default function NotFoundPage() {
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!xs && (
          <img
            src={PageNotFound}
            alt={'PageNotFound'}
            height={512}
            width={512}
          />
        )}

        {xs && <img src={PageNotFound} alt={'PageNotFound'} width={'100%'} />}
        <br />
        <Button
          style={{ width: '100%' }}
          type={'text'}
          onClick={() => navigate('/')}
        >
          <Typography.Title level={4}>Ir para Home</Typography.Title>
        </Button>
      </div>
    </>
  );
}
