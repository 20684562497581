import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Typography,
} from 'antd';
import usePageTitle from '../../core/hooks/usePageTitle';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';
import { Historico } from '../../sdk/@types/Historico';
import { formatCodigoEstabelecimentoRural } from '../../core/functions/formatCodigoEstabelecimentoRural';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import * as HistoricoEstabelecimentoActions from '../../core/store/HistoricoEstabelecimento.slice';

export default function HistoricoEstabelecimentoView() {
  usePageTitle('Histórico do estabelecimento');

  const [form] = Form.useForm();
  const { xs } = useBreakpoint();

  const dispatch = useDispatch();
  const atividadesEmExibição = useSelector(
    (state: RootState) => state.historicoEstabelecimento.atividadesEmExibição
  );
  const checkAll = useSelector(
    (state: RootState) => state.historicoEstabelecimento.checkAll
  );
  const checkedList = useSelector(
    (state: RootState) => state.historicoEstabelecimento.checkedList
  );
  const indeterminate = useSelector(
    (state: RootState) => state.historicoEstabelecimento.indeterminate
  );
  const tipoAtividades = useSelector(
    (state: RootState) => state.historicoEstabelecimento.tipoAtividades
  );
  const todasAsAtividades = useSelector(
    (state: RootState) => state.historicoEstabelecimento.todasAsAtividades
  );

  const onChange = (list: CheckboxValueType[]) => {
    dispatch(
      HistoricoEstabelecimentoActions.filterAtividades({
        checkedList: list,
        tipoAtividades,
        todasAsAtividades,
      })
    );
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    dispatch(
      HistoricoEstabelecimentoActions.filterAtividades({
        checkedList: e.target.checked ? tipoAtividades : [],
        tipoAtividades,
        todasAsAtividades,
      })
    );
  };

  useEffect(() => {
    const data: Historico.Estabelecimento[] = [
      {
        tipo: 'FVER',
        id: 77854,
        data: moment('2018-10-22 08:20:00').format('DD/MM/YYYY'),
        numero: '5106505-0077/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 521213,
        nomePropriedade: 'FAZENDA BOM JESUS',
        nomeProprietario: 'ARISTIDES QUINTAM',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 77857,
        data: moment('2018-11-14 13:30:00').format('DD/MM/YYYY'),
        numero: '5101605-0037/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 1036972,
        nomePropriedade: 'CHACARA PARAISO',
        nomeProprietario: 'JOSENIL ANTONIO DE AMORIM',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 78290,
        data: moment('2018-11-21 14:00:00').format('DD/MM/YYYY'),
        numero: '5106174-0040/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 226511,
        nomePropriedade: 'RETIRO DOS PAMPAS',
        nomeProprietario: 'SADI DORNELES BRASIL',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 45022,
        data: moment('2017-08-29 09:30:00').format('DD/MM/YYYY'),
        numero: '5101605-0057/2017',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 7273,
        nomePropriedade: 'FAZENDA USINA FLEXAS',
        nomeProprietario: 'GERALDO XAVIER GRUNWALD',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 77818,
        data: moment('2018-11-07 17:00:00').format('DD/MM/YYYY'),
        numero: '5102504-2412/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 67401,
        nomePropriedade: 'FAZENDA MATAO',
        nomeProprietario: 'GEORGE MARCELO RIBEIRO E OUTRO',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 77820,
        data: moment('2018-11-14 12:04:00').format('DD/MM/YYYY'),
        numero: '5104526-0052/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 186645,
        nomePropriedade: 'FAZENDA PAULO GABRIEL UT S',
        nomeProprietario: 'PAULO SERGIO FRANZ',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVER',
        id: 77843,
        data: moment('2018-11-10 12:50:00').format('DD/MM/YYYY'),
        numero: '5106455-0048/2018',
        tipoEstabelecimento: 'PROPRIEDADE',
        codigoPropriedade: 431767,
        nomePropriedade: 'SITIO TRES IRMAOS',
        nomeProprietario: 'OSMAR PINTO DA COSTA',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },

      {
        tipo: 'FVV',
        id: 15939,
        data: moment('2018-04-10 00:00:00').format('DD/MM/YYYY'),
        numero: '5102793-0030/2018',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 379633,
        nomePropriedade: 'FAZENDA ALEGRIA',
        nomeProprietario: 'RAFAEL LOURENCO FADEL',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 16089,
        data: moment('2018-04-16 00:00:00').format('DD/MM/YYYY'),
        numero: '5100201-0017/2018',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 21928,
        nomePropriedade: 'FAZ HERANCA',
        nomeProprietario: 'NADIR LINDERMAYER',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 16451,
        data: moment('2018-04-25 00:00:00').format('DD/MM/YYYY'),
        numero: '5103601-0019/2018',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 571852,
        nomePropriedade: 'SITIO: BOA ESPERANCA',
        nomeProprietario: 'FELIPE STAATS',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 18060,
        data: moment('2018-06-26 00:00:00').format('DD/MM/YYYY'),
        numero: '5105101-0282/2018',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 169357,
        nomePropriedade: 'ESTANCIA JUREMA',
        nomeProprietario: 'FERNANDO DE MORAES OLIVEIRA',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 19161,
        data: moment('2017-11-22 00:00:00').format('DD/MM/YYYY'),
        numero: '5105622-0070/2017',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 195759,
        nomePropriedade: 'CHACARA NOVA',
        nomeProprietario: 'CELSO DA SILVA DANTAS',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 21928,
        data: moment('2018-11-08 00:00:00').format('DD/MM/YYYY'),
        numero: '5105150-0041/2018',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 177757,
        nomePropriedade: 'HARAS KREFTA',
        nomeProprietario: 'LANA FOCHI KREFTA',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 26325,
        data: moment('2019-06-08 00:00:00').format('DD/MM/YYYY'),
        numero: '5105507-0308/2019',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 468767,
        nomePropriedade: 'FAZENDA CACULE',
        nomeProprietario: 'VALDECIR DOS REIS LIMA',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 42915,
        data: moment('2022-11-10 00:00:00').format('DD/MM/YYYY'),
        numero: '5100102-0002/2022',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 12345,
        nomePropriedade: 'undefined',
        nomeProprietario: 'MARCIA DA GUIA MENDES',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
      {
        tipo: 'FVV',
        id: 42916,
        data: moment('2022-11-10 00:00:00').format('DD/MM/YYYY'),
        numero: '5100102-0003/2022',
        tipoEstabelecimento: undefined,
        codigoPropriedade: 12345,
        nomePropriedade: 'undefined',
        nomeProprietario: 'MARCIA DA GUIA MENDES',
        statusAssinatura: 'ASSINADO_FISICAMENTE',
      },
    ];

    const tiposAtividades = Array.from(
      new Set(data.map((atividade) => atividade.tipo))
    ).sort((a, b) => (a > b ? 1 : -1));

    const dataOrdered = data.sort((a, b) => (a.data > b.data ? 1 : -1));

    dispatch(
      HistoricoEstabelecimentoActions.setTipoAtividades(tiposAtividades)
    );
    dispatch(HistoricoEstabelecimentoActions.setCheckedList(tiposAtividades));
    dispatch(HistoricoEstabelecimentoActions.setTodasAsAtividades(dataOrdered));
    dispatch(
      HistoricoEstabelecimentoActions.setAtividadesEmExibição(dataOrdered)
    );
  }, [dispatch]);

  useEffect(() => {}, [
    tipoAtividades,
    todasAsAtividades,
    checkAll,
    checkedList,
    atividadesEmExibição,
    indeterminate,
  ]);

  const fetchVisitas = useCallback(async (values) => {
    //const formValues = form.getFieldsValue();
  }, []);

  return (
    <>
      <Spin
        size='large'
        spinning={false}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <Row style={{ width: '100%' }}>
          <Collapse
            defaultActiveKey={1}
            style={{ width: '100%', backgroundColor: '#e0e4e8' }}
          >
            <Collapse.Panel
              showArrow={false}
              collapsible='header'
              header={
                <span style={{ fontWeight: 'bold' }}>
                  Histórico de Estabelecimento
                </span>
              }
              key={1}
            >
              <Form
                layout={'horizontal'}
                form={form}
                size={!xs ? 'small' : 'large'}
                onFinish={fetchVisitas}
                autoComplete={'off'}
              >
                <Row gutter={24} style={{ width: '100%' }}>
                  {/* <Col xs={24} sm={12}>
                    <Form.Item
                      label={'Data Início'}
                      name={'dataIni'}
                      initialValue={moment().set(
                        'month',
                        moment().get('month') - 1
                      )}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        inputReadOnly
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={'Data Fim'}
                      name={'dataFim'}
                      initialValue={moment()}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        inputReadOnly
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Col> */}

                  <Col span={24}>
                    <Form.Item
                      label={'Código da propriedade'}
                      style={{ width: '100%' }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify={'end'} gutter={24}>
                  <Col xs={12} lg={6}>
                    <Button
                      style={{ width: '100%' }}
                      htmlType={'submit'}
                      type={'primary'}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Row>

        <br />

        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Selecionar todos
        </Checkbox>
        <br />

        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'row' }}
          options={tipoAtividades}
          value={checkedList}
          onChange={onChange}
        />

        <Row>
          <Col span={24}>
            <VerticalTimeline lineColor={'#337ab7'}>
              {atividadesEmExibição?.map((node: Historico.Estabelecimento) => (
                <VerticalTimelineElement
                  id={node.tipo + node.numero}
                  className='vertical-timeline-element--work'
                  position={`${node.tipo === 'FVER' ? 'left' : 'right'}`}
                  date={node.data}
                  iconStyle={{
                    background: `${
                      node.tipo === 'FVER'
                        ? 'rgb(127, 192, 246)'
                        : 'rgb(70, 195, 116)'
                    }`,
                    color: '#fff',
                  }}
                  icon={
                    <Typography.Text
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {node.tipo}
                    </Typography.Text>
                  }
                >
                  <h3 className='vertical-timeline-element-title'>
                    {node.tipo + ' - ' + node.numero}
                  </h3>
                  <Divider style={{ marginTop: 0, marginBottom: 5 }} />
                  <h4 className='vertical-timeline-element-subtitle'>
                    {node.nomePropriedade +
                      ' - ' +
                      formatCodigoEstabelecimentoRural(node.codigoPropriedade)}
                  </h4>
                  <p>
                    Creative Direction, User Experience, Visual Design, Project
                    Management, Team Leading
                  </p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </Col>
        </Row>
      </Spin>
    </>
  );
}
