import { useCallback, useState } from 'react';
import { Propriedade } from '../../sdk/@types/Propriedade';
import PropriedadeIDBService from '../../sdk/services/indexeddb/PropriedadeIDB.service';
import PropriedadeService from '../../sdk/services/SIZ-API/Propriedade.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function usePropriedade() {
  const { online } = useNavigatorStatus();
  const [propriedade, setPropriedade] = useState<Propriedade.Summary>();
  const [listaPropriedade, setlistaPropriedade] = useState<
    Propriedade.Summary[] | null
  >();
  const [fetchingPropriedade, setFetchingPropriedade] =
    useState<boolean>(false);
  const [fetchingPropriedades, setFetchingPropriedades] =
    useState<boolean>(false);

  const fetchExistingPropriedadeByCodigo = useCallback((id: number) => {
    try {
      setFetchingPropriedade(true);
      PropriedadeService.getById(id).then(setPropriedade);
    } finally {
      setFetchingPropriedade(false);
    }
  }, []);

  const fetchPropriedadesByProdutorCpf = useCallback(
    async (cpf: string, fromApi?: boolean) => {
      try {
        setFetchingPropriedades(true);
        if (!fromApi) {
          return await PropriedadeIDBService.getByProdutorCpf(cpf).then(
            async (payload) => {
              if (!payload || payload.length === 0) {
                if (online) {
                  return await PropriedadeService.getByProdutorCpf(cpf).then(
                    setlistaPropriedade
                  );
                }
              } else if (payload.length > 0) {
                setlistaPropriedade(payload);
              } else {
                setlistaPropriedade(null);
              }
            }
          );
        } else {
          return await PropriedadeService.getByProdutorCpf(cpf).then(
            setlistaPropriedade
          );
        }
      } catch (err) {
        throw err;
      } finally {
        setFetchingPropriedades(false);
      }
    },
    [online]
  );

  const fetchPropriedadesById = useCallback(
    async (id: number, fromApi?: boolean) => {
      try {
        setFetchingPropriedades(true);

        if (!fromApi) {
          return await PropriedadeIDBService.getById(id).then(
            async (propriedade) => {
              if (!propriedade) {
                if (online)
                  return await PropriedadeService.getById(id).then(
                    setPropriedade
                  );
              }

              if (propriedade) setPropriedade(propriedade.payload);
            }
          );
        } else {
          return await PropriedadeService.getById(id).then(setPropriedade);
        }
      } catch (err) {
        throw err;
      } finally {
        setFetchingPropriedades(false);
      }
    },
    [online]
  );

  const fetchPropriedadesByMunicipio = useCallback((codgIbge: string) => {
    try {
      setFetchingPropriedades(true);
      return PropriedadeService.getByMunicipio(codgIbge).then(
        setlistaPropriedade
      );
    } finally {
      setFetchingPropriedades(false);
    }
  }, []);

  return {
    propriedade,
    listaPropriedade,
    fetchingPropriedade,
    fetchingPropriedades,
    fetchExistingPropriedadeByCodigo,
    fetchPropriedadesById,
    fetchPropriedadesByMunicipio,
    fetchPropriedadesByProdutorCpf,
  };
}
