export default function UserPermissions() {
  return (
    <span
      role='img'
      aria-label='desktop'
      className='anticon anticon-desktop ant-menu-item-icon'
    >
      <svg
        height={'24px'}
        width={'24px'}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 256 256'
        enable-background='new 0 0 256 256'
        xmlSpace='preserve'
      >
        <metadata>
          Svg Vector Icons : http://www.onlinewebfonts.com/icon{' '}
        </metadata>
        <g>
          <g>
            <path
              fill='#000000'
              d='M214.8,187.7c-2.2-1.9-5.5-1.8-7.4,0.3c-1.8,2.1-1.5,5.4,0.7,7.3c2.2,1.9,5.5,1.8,7.3-0.3C217.3,192.9,217,189.6,214.8,187.7z'
            />
            <path
              fill='#000000'
              d='M198.6,160.5c-26.2,0-47.4,21.2-47.4,47.4s21.2,47.4,47.4,47.4s47.4-21.2,47.4-47.4S224.8,160.5,198.6,160.5z M221.4,207.2c-3.9,4.5-9.9,6.1-15.6,4.9l-21.5,24.6l-8,0.3c-1.2,0.1-2.8-1.3-2.9-2.5l-1-9.9l0.1-0.1l5.9-0.2l-0.6-5.7l5.7-0.2l-0.5-5.7l6.1-0.8l-0.4-6.2l4.3-4.9c-2.1-5.5-1.3-11.8,2.6-16.4c5.9-6.8,15.7-7.9,22.8-1.6C225.4,189.1,227.3,200.5,221.4,207.2z'
            />
            <path
              fill='#000000'
              d='M175.4,141.5c-0.4-0.5-0.7-0.8-1.2-1.2c-16.7-16.5-39-27-63.6-27.9c29.9-2.4,53.5-26.4,53.5-55.7c0-30.9-26.1-55.9-58.4-55.9c-32.2,0-58.4,25-58.4,55.9c0,29.6,24.1,53.9,54.5,55.8c-47.5,2.6-86,40.6-91.6,89.2c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.5,0,0.8c0,5.7,4.4,10.3,9.9,10.7c0.2,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0,0,85.6,0,108,0c3.6-0.6,6.4-3.4,7.1-7c0.6-24.5,14.2-45.4,35.7-55c0.4-0.1,0.8-0.3,1.2-0.5c2.1-1.1,3.5-3.4,3.5-5.9C176.9,144.1,176.3,142.7,175.4,141.5z'
            />
          </g>
        </g>
      </svg>
    </span>
  );
}
