import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreategGRAF from '../features/FormGraf/CreateGRAF';

export default function GRAFCreateView() {
  usePageTitle('Novo Formulário para Registro da Distribuição de Materiais Educativos');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreategGRAF />
      </ErrorBoundary>
    </>
  );
}
