import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as IDInTablesActions from '../store/DevOptions.slice';

export default function useDevOptions() {
  const dispatch = useDispatch();

  const idInTables = useSelector(
    (state: RootState) => state.devOptions.idInTables
  );

  const useMock = useSelector((state: RootState) => state.devOptions.useMock);

  const setIdInTables = useCallback(
    (newState: boolean) => {
      dispatch(IDInTablesActions.setIdInTables(newState));
    },
    [dispatch]
  );

  const setUseMock = useCallback(
    (newState: boolean) => {
      dispatch(IDInTablesActions.setUseMock(newState));
    },
    [dispatch]
  );

  return {
    idInTables,
    useMock,
    setIdInTables,
    setUseMock,
  };
}
