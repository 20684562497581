import { useCallback, useEffect } from 'react';
import { Button, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FormCustom } from '../../../sdk/@types';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import * as FormCustomActions from '../../../core/store/FormCustom.slice';
interface FilterPanelFormCustomProps {
  open?: boolean;
  defaultQuery?: FormCustom.Query;
}

export default function FilterPanelFormCustom(
  props: FilterPanelFormCustomProps
) {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();

  const dispatch = useDispatch<AppDispatch>();
  const query = useSelector((state: RootState) => state.formCustom.query);

  useEffect(() => {
    dispatch(FormCustomActions.filterFormCustoms(query));
  }, [dispatch, query]);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const formValues = form.getFieldsValue();
        const filteredValues = Object.fromEntries(
          Object.entries(formValues).filter(
            ([_, v]) => v !== null && v !== undefined
          )
        );
        dispatch(
          FormCustomActions.setQuery({
            ...filteredValues,
            page: 0,
          })
        );
      } catch (error) {
        console.error('Erro ao buscar os FormCustoms com filtro:', error);
      }
    },
    [dispatch, form]
  );

  const removeField = useCallback(
    (fieldName: string) => {
      form.setFieldsValue({
        [fieldName]: null,
      });
    },
    [form]
  );

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          defaultActiveKey={props.open ? '1' : '0'}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label={'Nome'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'nome'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input inputMode='text' />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('nome')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Status'} style={{ width: '100%' }}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name='ativo'
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        options={[
                          { value: true, label: 'Ativo' },
                          { value: false, label: 'Inativo' },
                        ]}
                      />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('ativo')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      dispatch(FormCustomActions.setQuery({ page: 0 }));
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
