import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateFVV from '../features/FVV/CreateFVV';

export default function VigilanciaVeterinariaCreateView() {
  usePageTitle('Novo FVV');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateFVV />
      </ErrorBoundary>
    </>
  );
}
