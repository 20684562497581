import { Button, ButtonProps } from 'antd';
import { FunctionComponent } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

type GoBackButtonProps = {
  label?: string;
  to: string;
} & ButtonProps;

export const GoBackButton: FunctionComponent<GoBackButtonProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      onClick={() => navigate(props.to)}
      type={'link'}
    >
      {props.label}
    </Button>
  );
};
