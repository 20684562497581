import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import FormEducaView from '../features/FormEduca/FormEducaView';
import useLoadingPage from '../../core/hooks/useLoadingPage';

export default function FormEducaViewWrapper() {
  usePageTitle('FormEduca');
  const { setLoading, setMode, setFirstOpening } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='FormEduca'>
        <FormEducaView />
      </ErrorBoundary>
    </>
  );
}
