import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as OfflinePersistStateActions from '../store/OfflinePersistState.slice';

export default function useOfflinePersistState() {
  const dispatch = useDispatch();

  const done = useSelector(
    (state: RootState) => state.offlinePersistState.done
  );
  const none = useSelector(
    (state: RootState) => state.offlinePersistState.none
  );
  const syncing = useSelector(
    (state: RootState) => state.offlinePersistState.syncing
  );

  const setDone = useCallback(
    (done: boolean) => {
      dispatch(OfflinePersistStateActions.setDone(done));
    },
    [dispatch]
  );

  const setNone = useCallback(
    (none: boolean) => {
      dispatch(OfflinePersistStateActions.setNone(none));
    },
    [dispatch]
  );

  const setSyncing = useCallback(
    (syncing: boolean) => {
      dispatch(OfflinePersistStateActions.setSyncing(syncing));
    },
    [dispatch]
  );

  return {
    none,
    done,
    syncing,
    setNone,
    setDone,
    setSyncing,
  };
}
