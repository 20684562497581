import { Button, Modal, Space } from 'antd';
import { useEffect } from 'react';
import AuthorizationService from '../../core/auth/Authorization.service';
import ErrorDisplay from './ErrorDisplay';
import { CustomModal } from './CustomModal';

export default function SessionExpired() {
  return (
    <>
      <CustomModal
        centered
        open={false}
        width={400}
        footer={null}
        destroyOnClose
        closable={false}
      >
        <Space size={12} style={{ width: '100%' }} direction={'vertical'}>
          <div
            style={{
              padding: 24,
              border: '1px solid ',
            }}
          >
            <ErrorDisplay
              title={'Sessão expirada'}
              message={'Faça login novamente'}
            />
          </div>
          <Button
            onClick={() => {
              AuthorizationService.logout();
            }}
            style={{ width: '100%' }}
            type={'primary'}
          >
            Sair
          </Button>
        </Space>
      </CustomModal>
    </>
  );
}
