import { Spin } from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';

import { useEffect } from 'react';
import ListLocalAcaoEducativa from '../features/FormCadastroLocalAcaoEducativa/ListLocalAcaoEducativa';

export default function LocalAcaoEducativaList() {
  usePageTitle('SIZ - Lista de Locais Ação Educativa');
  const { setLoading, setMode, setFirstOpening, loading } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='a lista de locais Ação educativa'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <ListLocalAcaoEducativa />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
