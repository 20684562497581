import { Modal } from 'antd';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useConfirmExit = (
  confirmExit: (confirmNavigation: () => void) => void,
  when = true
) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const confirm = () => push(...args);
      confirmExit(confirm);
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (when: boolean, onContinue?: () => void) => {
  const message = 'Você está saindo da página de cadastro';
  const description = 'Sair da página fará com que você perca os dados atuais';

  // This is to ensure that work is saved on page accidental exit or refresh
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(
    (confirmNavigation: () => void) => {
      const onConfirm = () => {
        if (onContinue) onContinue();
        confirmNavigation();
      };

      Modal.confirm({
        cancelButtonProps: { danger: true, style: { width: '45%' } },
        cancelText: 'Sair',
        closable: false,
        content: description,
        icon: null,
        keyboard: false,
        maskClosable: false,
        okButtonProps: { style: { width: '45%' } },
        okText: 'Ficar',
        onCancel: onConfirm,
        title: message,
      });
    },
    [message, onContinue]
  );

  useConfirmExit(confirmExit, when);
};
