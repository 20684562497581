import { FormularioRespostas } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormRespostasService extends Service {
  static BASE_URL = '/formularios';

  static async getAll(
    query: FormularioRespostas.Query
  ): Promise<FormularioRespostas.Summary> {
    const queryString = generateQueryString(query);
    return this.Http.get<FormularioRespostas.Summary>(
      this.BASE_URL.concat(queryString)
    ).then(this.getData);
  }

  static async getRespostaById(
    id: number
  ): Promise<FormularioRespostas.Response> {
    try {
      const response = await this.Http.get<FormularioRespostas.Response>(
        `${this.BASE_URL}/respostas/${id}`
      );
      return this.getData(response);
    } catch (error) {
      console.error('Erro ao buscar respostas do formulário:', error);
      throw error;
    }
  }

  static async addRespostas(
    id: number,
    respostas: FormularioRespostas.Request
  ): Promise<FormularioRespostas.Request> {
    try {
      const response = await this.Http.post(
        `${this.BASE_URL}/${id}/respostas`,
        respostas
      );
      return this.getData(response);
    } catch (error) {
      console.error('Erro ao enviar respostas do formulário:', error);
      throw error;
    }
  }

  static async getFormResponses(
    formId: string
  ): Promise<FormularioRespostas.Response[]> {
    try {
      const response = await this.Http.get<FormularioRespostas.Response[]>(
        `${this.BASE_URL}/${formId}/respostas`
      );
      return this.getData(response);
    } catch (error) {
      console.error('Erro ao buscar respostas do formulário:', error);
      throw error;
    }
  }

  static formatDataForXLSX(responses: FormularioRespostas.Response[]) {
    if (responses.length === 0) return [];

    // Extrai todas as perguntas únicas removendo espaços extras
    const perguntasSet = new Set<string>();
    responses.forEach((response: any) => {
      response.respostas?.forEach((r: any) => {
        perguntasSet.add(r.pergunta.descricao.trim());
      });
    });
    const perguntas = Array.from(perguntasSet);

    // Cria uma linha de cabeçalho com as perguntas e inclui o município
    const headers: { [key: string]: string } = { Município: 'Município' };
    perguntas.forEach((pergunta) => {
      headers[pergunta] = pergunta;
    });

    // Formata as respostas
    const data = responses.map((response: any) => {
      const formattedResponse: { [key: string]: string } = {
        Município: response.municipio?.nome || '-',
      };
      perguntas.forEach((pergunta) => {
        const resposta = response.respostas.find(
          (r: any) => r.pergunta.descricao.trim() === pergunta
        );
        formattedResponse[pergunta] = resposta
          ? resposta.descricaoResposta
          : '';
      });
      return formattedResponse;
    });

    return [headers, ...data];
  }
}

export default FormRespostasService;
