import localforage from 'localforage';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import FormularioRespostaIDBService from '../../sdk/services/indexeddb/FormularioRespostaIDB.service';
import Service from '../../sdk/services/SIZ-API/Service';
import AuthorizationService from '../auth/Authorization.service';
import FormularioRespostaService from '../../sdk/services/SIZ-API/formularioResposta.service';

export default async function syncFormRespostas(
  id: number | undefined,
): Promise<string> {
  let errors = 0;
  let formRespostasWithFVERsUnsynced = 0;
  let formRespostasSynced = 0;

  const promise = new Promise<string>(async (resolve, reject) => {
    return await FormularioRespostaIDBService.getAllFromInputTable()
      .then(async (listFormRespostasOffline) => {
        const saveFormRespostaAsync = async () => {
          for (const formRespostaOffline of listFormRespostasOffline) {
            if (
              formRespostaOffline.status === 'SINCRONIZADO' ||
              formRespostaOffline.status === 'NAO FINALIZADO'
            ) {
              //@TODO preguiça de negar a condição. Arrumar depois
            } else if (formRespostaOffline.payload.fver.id !== -1) {
              console.log('FormRespostaDTO', formRespostaOffline.payload);

              await localforage.getItem('token').then(async (token) => {
                let access_token;
                if (token) access_token = token;
                else access_token = AuthorizationService.getAccessToken();

                if (!access_token) {
                  throw new Error('Sessão expirada');
                }

                await fetch(
                  `${Service.API_URL}${FormularioRespostaService.BASE_URL}/${id}/respostas`,
                  {
                    method: 'POST',
                    body: JSON.stringify(formRespostaOffline.payload),
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                  .then(async (response) => {
                    if (response.status === 401) {
                      throw new Error('Sessão expirada');
                    }

                    if (response.ok) {
                      return await response
                        .json()
                        .then(async (formRespostasResponse) => {
                          let newFormResposta: ServiceIDBPayloadInput = {
                            ...formRespostaOffline,
                            payload: {
                              ...formRespostaOffline.payload,
                              id: formRespostasResponse.id,
                              numero: formRespostasResponse.numero,
                            },
                            status: 'SINCRONIZADO',
                          };

                          await FormularioRespostaIDBService.update(
                            //@ts-ignore
                            formRespostaOffline.id,
                            newFormResposta
                          ).catch((e) => {});

                          formRespostasSynced++;
                        });
                    } else {
                      return await response
                        .json()
                        .then(async (formRespostaResponse) => {
                          let motivoErro = formRespostaResponse.detail;

                          errors = errors + 1;
                          let newFormResposta: ServiceIDBPayloadInput = {
                            ...formRespostaOffline,
                            status: 'ERRO',
                            motivoErro,
                          };

                          return await FormularioRespostaIDBService.update(
                            //@ts-ignore
                            formRespostaOffline.id,
                            newFormResposta
                          );
                        });
                    }
                  })
                  .catch((e) => {
                    throw new Error(
                      'Não conseguimos conectar com o servidor. Motivo: ' + e
                    );
                  });
              });
            } else {
              formRespostasWithFVERsUnsynced++;
            }
          }
        };
        await saveFormRespostaAsync();
      })
      .then(async () => {
        if (
          errors === 0 &&
          formRespostasWithFVERsUnsynced === 0 &&
          formRespostasSynced === 0
        )
          return resolve('Sem FVVs para sincronizar');
        else if (
          errors === 0 &&
          formRespostasWithFVERsUnsynced === 0 &&
          formRespostasSynced > 0
        )
          return resolve(
            'Todos os Laudos de Inspeção foram sincronizados com sucesso'
          );
        else if (
          errors === 0 &&
          formRespostasWithFVERsUnsynced > 0 &&
          formRespostasSynced > 0
        )
          return resolve(
            'Alguns Laudos de Inspeção não foram sincronizados. Verifique se o FVER correspondente já está sincronizado'
          );
        else if (
          errors === 0 &&
          formRespostasWithFVERsUnsynced > 0 &&
          formRespostasSynced === 0
        )
          return resolve(
            'Nenhum Laudo de Inspeção foi sincronizado. Verifique se o FVER correspondente já está sincronizado'
          );
        else {
          return reject(
            'A sincronização dos Laudos de Inspeção foi finalizada com erros. Alguns Laudos de Inspeção não puderam ser sincronizadas. Revise-os e tente novamente'
          );
        }
      })
      .catch((e) => {
        return reject(e.message);
      });
  });

  return await promise;
}
