import { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Button, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { TermoFiscalizacao } from '../../../sdk/@types';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../core/store';
import * as TFRActions from '../../../core/store/TFR.slice';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { Municipio } from '../../../sdk/@types/Municipio';
import useMunicipio from '../../../core/hooks/useMunicipio';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
interface FilterPanelTFRProps {
  open?: boolean;
  defaultQuery?: TermoFiscalizacao.Query;
}

export default function FilterPanelTFR(props: FilterPanelTFRProps) {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const { online } = useNavigatorStatus();
  const { firstOpening, setFirstOpening } = useLoadingPage();
  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();
  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();
  const dispatch = useDispatch<AppDispatch>();
  const query = useSelector((state: RootState) => state.formTfr.query);

  useEffect(() => {
    if (online) fetchMunicipiosByUf('mt');
  }, [fetchMunicipiosByUf, online]);

  const fetchTFRs = useCallback(() => {
    dispatch(TFRActions.filterTFRs(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (!firstOpening && online) {
      dispatch(TFRActions.setFetching(true));
      try {
        fetchTFRs();
      } finally {
        dispatch(TFRActions.setFetching(false));
      }
    } else {
      setFirstOpening(false);
    }
  }, [dispatch, fetchTFRs, firstOpening]);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const formValues = form.getFieldsValue();

        dispatch(
          TFRActions.setQuery({
            numero: formValues.numero ? formValues.numero : undefined,
            dataVisita: formValues.dataVisita,
            nomeFiscalizado: formValues.nomeFiscalizado,
            codigoIBGE: municipioSelecionado?.codgIBGE,
            page: 0,
          })
        );
      } catch (error) {
        console.error('Erro ao buscar os TFRs com filtro:', error);
      }
    },
    [dispatch, form, municipioSelecionado]
  );
  const removeMunicipio = useCallback(() => {
    form.setFieldsValue({
      municipio: null,
    });
    setMunicipioSelecionado(undefined);
  }, [form]);
  const removeField = useCallback(
    (fieldName: string) => {
      form.setFieldsValue({
        [fieldName]: null,
      });
    },
    [form]
  );
  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };
  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          defaultActiveKey={props.open ? '1' : '0'}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label={'Número'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'numero'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <InputMask
                        onClick={() => {
                          scrollFieldToTop('horaDaVisita');
                        }}
                        mask={[
                          /^([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          '-',
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          '/',
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                        ]}
                      >
                        <Input />
                      </InputMask>
                    </Form.Item>

                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('numero')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Data da Visita'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'dataVisita'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <InputMask
                        onClick={() => {
                          scrollFieldToTop('dataDaVisita');
                        }}
                        mask={[
                          /[0-3]/,
                          /[0-9]/,
                          '/',
                          /[0-1]/,
                          /[0-9]/,
                          '/',
                          /[1-2]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      >
                        <Input />
                      </InputMask>
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('dataVisita')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Município'} style={{ width: '100%' }}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'municipioFiscalizado'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder='Selecione um município'
                        optionFilterProp='children'
                        onChange={handleSelectMunicipioChange}
                        filterOption={(input, option) => {
                          if (option && option.children)
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(input.toLowerCase()) >= 0
                            );

                          return false;
                        }}
                      >
                        {listaMunicipio?.map((municipio) => (
                          <Select.Option
                            key={municipio.codgIBGE}
                            value={JSON.stringify(municipio)}
                          >
                            {municipio?.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeMunicipio}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'Nome Estabelecimento'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'nomeFiscalizado'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input inputMode='text' />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeField('nomeFiscalizado')}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      dispatch(
                        TFRActions.setQuery({ page: 0, numero: undefined })
                      );
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
