import { useCallback, useState } from 'react';
import { FormIN } from '../../sdk/@types';
import FormINIDBService from '../../sdk/services/indexeddb/FormINIDB.service';
import FormINService from '../../sdk/services/SIZ-API/FormIN.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useFormIN() {
  const { online } = useNavigatorStatus();
  const [formIN, setFormIN] = useState<FormIN.Summary | null>();
  const [formINs, setFormINs] = useState<FormIN.Summary[]>();
  const [query, setQuery] = useState<FormIN.Query>({});
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchExistingFormINByNumero = useCallback(
    async (cnpj: string, fromApi?: boolean) => {
      try {
        setFetching(true);

        if (!fromApi) {
          return await FormINIDBService.getByNumero(cnpj).then(
            async (payload) => {
              if (payload) {
                setFormIN(payload?.payload);
              } else if (online) {
                return await FormINService.getByNumero(cnpj).then(setFormIN);
              } else setFormIN(null);
            }
          );
        } else {
          return await FormINService.getByNumero(cnpj).then(setFormIN);
        }
      } catch (err) {
        throw err;
      } finally {
        setFetching(false);
      }
    },
    [online]
  );

  const fetchFormINsByMunicipio = useCallback(
    async (codgIbge: string, fromApi?: boolean) => {
      try {
        setFetching(true);

        if (!fromApi) {
          return await FormINIDBService.getByMunicipio(codgIbge).then(
            async (lista) => {
              if (!lista || lista.length === 0) {
                if (online)
                  return await FormINService.getByMunicipio(
                    codgIbge,
                    query
                  ).then(setFormINs);
              } else {
                setFormINs(
                  lista.map((item) => {
                    return item.payload;
                  })
                );
              }
            }
          );
        } else {
          return await FormINService.getByMunicipio(codgIbge, query).then(
            setFormINs
          );
        }
      } catch (err) {
        throw err;
      } finally {
        setFetching(false);
      }
    },
    []
  );

  return {
    formIN,
    formINs,
    fetchExistingFormINByNumero,
    fetchFormINsByMunicipio,
    fetching,
    query,
    setQuery,
  };
}
