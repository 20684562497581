import { Spin } from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ListGRAF from '../features/FormGraf/ListGRAF';
import { useEffect } from 'react';

export default function GRAFList() {
  usePageTitle('SIZ - Lista de GRAFs');
  const { setLoading, setMode, setFirstOpening, loading } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='a lista de GRAF'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <ListGRAF />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
