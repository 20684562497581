import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Table,
  Typography,
  Modal,
  Card,
  Radio,
  Space,
  Tag,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useAssinaturaBase64Image from '../../../core/hooks/useAssinaturaBase64Image';
import {
  RecebimentosRevendas,
  Revenda,
  Servidor,
  TermoFiscalizacao,
} from '../../../sdk/@types';
import { RootState } from '../../../core/store';
import * as RecebimentosRevendasActions from '../../../core/store/RecebimentosRevendas.slice';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import NotaFiscalPanel from '../../components/NotaFiscalPanel';
import RevendaPanelNotaFiscal from '../../components/RevendaDestinoPanel';
import ProductEditModal from '../../components/ProductEditModal';
import SignatureWrapper from '../../components/SignatureWrapper';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';
import TFRPanel from '../../components/TFRPanel';
import RevendaOrigemPanel from '../../components/RevendaOrigemPanel';
import AuthorizationService from '../../../core/auth/Authorization.service';
import ServidoresPanel from '../../components/ServidoresPanel';

type FormRecebimentosRevendasType = {
  horaDaVisita?: string;
  dataRecebimento?: Moment;
  numeroNFE: number;
  documentoEntradaId: number;
  servidores?: Servidor.Summary;
  revendaOrigem?: Revenda.Summary;
  revendaDestino?: Revenda.Summary;
  termoFiscalizacao?: TermoFiscalizacao.Summary;
  assinatura?: string;
  statusAssinatura: string;
  itens: RecebimentosRevendas.Response['itens'];
} & Omit<RecebimentosRevendas.Request, 'dataRecebimento'>;

type FormRecebimentosRevendasProps = {
  recebimentosRevendas?: FormRecebimentosRevendasType;
};

export default function FormRecebimentosRevendas(
  props: FormRecebimentosRevendasProps
) {
  const dispatch = useDispatch();
  const [statusAssinaturaState, setStatusAssinaturaState] = useState<string>();
  const [form] = Form.useForm<FormRecebimentosRevendasType>();
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { setLoading, mode } = useLoadingPage();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [showModalTimePicker, setShowModalTimePicker] =
    useState<boolean>(false);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [listaServidores, setListaServidores] = useState<Servidor.Summary[]>(
    []
  );
  const [currentDate,] = useState<Moment>(moment());
  const [selectTFR, setSelectTFR] = useState<TermoFiscalizacao.Summary | null>(
    null
  );

  const [itens, setItens] = useState<RecebimentosRevendas.Response['itens']>(
    []
  );
  const listaStatusAssinatura = StatusAssinatura.keys();
  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const loading = useSelector(
    (state: RootState) => state.recebimentosRevendas.loading
  );

  const [notaFiscalSelecionada, setNotaFiscalSelecionada] =
    useState<RecebimentosRevendas.Response | null>(null);
  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();

  usePrompt(!showModalFinalizacao);

// ************** useEffect hooks ************** //

  useEffect(() => {
    form.setFieldsValue({
      dataRecebimento: currentDate,
    });
  }, [form, currentDate]);

  useEffect(() => {
    if (props.recebimentosRevendas?.servidores)
      setListaServidores(props.recebimentosRevendas.servidores);
  }, [props.recebimentosRevendas, params, setLoading]);

  useEffect(() => {
    if (props.recebimentosRevendas?.termoFiscalizacao)
      setSelectTFR(props.recebimentosRevendas.termoFiscalizacao);
  }, [props.recebimentosRevendas, params, setLoading]);

  useEffect(() => {
    if (props.recebimentosRevendas?.itens)
      setItens(props.recebimentosRevendas.itens);
  }, [props.recebimentosRevendas, params, setLoading]);

  useEffect(() => {
    const insertLoggedUser = async () => {
      if (mode === 'CREATE') {
        if (AuthorizationService.getUsuarioSIZ()) {
          await ServidorIDBService.getByCpf(
            //@ts-ignore
            AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
          ).then((servidor) => {
            if (servidor) {
              const newLista = listaServidores.concat(servidor.payload);
              setListaServidores(newLista);
            }
          });
        }
      }
    };
    insertLoggedUser();
  }, []);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);
    if (mode !== 'CREATE')
      setStatusAssinaturaState(form.getFieldValue('statusAssinatura'));
  }, [mode, form]);

  useEffect(() => {
    if (form) setAssinaturaBase64Image(form.getFieldValue('assinatura'));
  }, [form, setAssinaturaBase64Image]);

  useEffect(() => {
    if (props.recebimentosRevendas?.assinatura)
      setAssinaturaBase64Image(props.recebimentosRevendas.assinatura);
  }, [setAssinaturaBase64Image, props]);

  useEffect(() => {
    form.setFieldsValue({ servidores: listaServidores });
  }, [listaServidores, form]);

  useEffect(() => {
    if (loading === 'pending') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loading, setLoading]);

  useEffect(() => {
    if (props.recebimentosRevendas?.dataRecebimento) {
      form.setFieldsValue({
        dataRecebimento: moment(props.recebimentosRevendas.dataRecebimento),
      });
    }
  }, [props.recebimentosRevendas, form]);

  // ************** Handles e Funções de manipulação ************** //

  const saveRecebimentoRevendaOnline = useCallback(
    async (recebimentoRevenda: RecebimentosRevendas.Request) => {
      try {
        const action = await dispatch(
          params.id
            ? RecebimentosRevendasActions.updateRecebimentosRevendas({
                id: Number(params.id),
                recebimentosRevendas: recebimentoRevenda,
              })
            : RecebimentosRevendasActions.insertRecebimentosRevendas(
                recebimentoRevenda
              )
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const fiscalizacao = action.payload;
          
          if (fiscalizacao && fiscalizacao.id && !isNaN(Number(fiscalizacao.id))) {
            setDadosModalFinalizacao({
              id: Number(fiscalizacao.id),
            });
            setShowModalFinalizacao(true);
          } else {
            throw new Error("Houve um erro ao salvar os dados.");
          }
        } else {
          throw new Error("Falha no envio do formulário, sem payload.");
        }
      } catch (e) {
      
        setShowModalFinalizacao(false);
        notification.error({
          message: 'Houve um erro ao salvar os dados',
        });
        console.error('Erro ao salvar o recebimento:', e);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );
  

  const handleFormSubmit = useCallback(
    async (formParam: FormRecebimentosRevendasType) => {
      setLoading(true);

      const hasIncompleteConservationInfo = itens.some((item) => {
        return !item.produtoArmazenadoAdequadamente || !item.produtoPrecisaSerRefrigerado;
      });
  
      if (hasIncompleteConservationInfo) {
        notification.error({
          message: 'Informações incompletas sobre a conservação dos produtos',
          description: 'Por favor, preencha todas as informações sobre a conservação dos produtos antes de salvar.',
        });
        setLoading(false);
        return;
      }
      const itensAtualizados: RecebimentosRevendas.Response['itens'] = itens?.map(item => {
        if (item.status !== 'NAO_RECEBIDO' && !item.produtoDevolvidoFornecedor) {
          return {
            ...item,
            produtoDevolvidoFornecedor: 'NA',
          };
        }
        return item;
      });
      const data: Moment = form.getFieldValue('dataRecebimento');
      const hora: string = form.getFieldValue('horaDaVisita');

      let dataMoment = data.set({
        hour: Number(hora.split(':')[0]),
        minute: Number(hora.split(':')[1]),
      });

      const formRecebimentoRevendaDTO: RecebimentosRevendas.Request = {
        ...formParam,
        dataRecebimento: dataMoment.format(`YYYY-MM-DD[T]HH:mm:ss[Z]`),
        numeroNFE: formParam.numeroNFE,
        documentoEntradaId: Number(formParam.documentoEntradaId),
        revendaOrigem: form.getFieldValue('revendaOrigem'),
        revendaDestino: form.getFieldValue('revendaDestino'),
        assinatura: assinaturaBase64Image,
        statusAssinatura: form.getFieldValue('statusAssinatura'),
        servidores: listaServidores as any,
        termoFiscalizacao: form.getFieldValue('termoFiscalizacao'),
        itens: itensAtualizados,
      };
      saveRecebimentoRevendaOnline(formRecebimentoRevendaDTO);
    },
    [
      assinaturaBase64Image,
      form,
      setLoading,
      saveRecebimentoRevendaOnline,
      listaServidores,
      itens,
    ]
  );

  const showModal = (product: any) => {
    const productToEdit =
      itens.find((item) => item.id === product.id) || product;
    setSelectedProduct(productToEdit);
    setIsProductModalVisible(true);
  };

  const handleProductModalCancel = () => {
    setIsProductModalVisible(false);
  };

  const handleNotaFiscalSelect = useCallback(
    (nota: RecebimentosRevendas.Response) => {
      const preservedDate = form.getFieldValue('dataRecebimento');
      setNotaFiscalSelecionada(nota);
      if (nota && nota.itens) {
        setItens(nota.itens);
      }
      form.setFieldsValue({
        dataRecebimento: preservedDate || currentDate,
      });
    },
    []
  );

  const handleClearForm = () => {
    Modal.confirm({
      title: 'Você tem certeza?',
      content:
        'Ao confirmar, todos os dados do formulário serão perdidos, exceto a data/hora de recebimento.',
      onOk: () => {
        form.resetFields();
        setNotaFiscalSelecionada(null);
        setStatusAssinaturaState(undefined);
        setAssinaturaBase64Image(undefined);
        setItens([]);
        setListaServidores([]);
        const preservedDate = form.getFieldValue('dataRecebimento');
        form.setFieldsValue({
          dataRecebimento: preservedDate || currentDate,
        });
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  };

  const handleAddProduct = (updatedProduct: any) => {
    console.log('Produtos', updatedProduct)
    setItens((prevItens) => {
      const productIndex = prevItens.findIndex(
        (item) => item.id === updatedProduct.id
      );

      if (productIndex !== -1) {
        const newItems = [...prevItens];
        newItems[productIndex] = updatedProduct;
        return newItems;
      }
      return [...prevItens, updatedProduct];
    });

    setIsProductModalVisible(false);
    setSelectedProduct(null);
  };
console.log('itens', selectedProduct)
  return (
    <>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='SOVIV'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />

      <fieldset disabled={formDisabled}>
        <Form<FormRecebimentosRevendasType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={{
            ...props.recebimentosRevendas,
            dataRecebimento: props.recebimentosRevendas?.dataRecebimento
              ? moment(props.recebimentosRevendas.dataRecebimento)
              : null,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input disabled hidden />
          </Form.Item>

          <Divider />

          <Row gutter={24}>
            <Col xs={15} lg={5}>
              <Form.Item
                label={'Data de Recebimento'}
                name={'dataRecebimento'}
                rules={[
                  { required: true, message: 'O campo é obrigatório' },
                  {
                    validator: (_, value) =>
                      value && moment(value, 'DD/MM/YYYY', true).isValid()
                        ? Promise.resolve()
                        : Promise.reject('Data inválida!'),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(date) => date.isAfter(moment())}
                  disabled={formDisabled || mode !== 'CREATE'}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
            <Col xs={9} lg={3}>
              <Form.Item
                label={'Hora'}
                name={'horaDaVisita'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
                initialValue={
                  String(
                    moment
                      .tz(moment().toLocaleString(), 'America/Cuiaba')
                      .get('hour')
                  ).padStart(2, '0') +
                  ':' +
                  String(
                    moment
                      .tz(moment().toLocaleString(), 'America/Cuiaba')
                      .get('minute')
                  ).padStart(2, '0')
                }
              >
                <Input
                  inputMode='numeric'
                  autoComplete='off'
                  onClick={() => {
                    setShowModalTimePicker(true);
                  }}
                  readOnly
                  disabled={formDisabled || mode !== 'CREATE'}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Row gutter={24}>
                <Col xs={24} lg={24}>
                  <NotaFiscalPanel
                    form={form}
                    formDisabled={!params.local && mode !== 'CREATE'}
                    onSelectNota={handleNotaFiscalSelect}
                    onClearForm={handleClearForm}
                  />
                </Col>
              </Row>
              <Divider orientation='left'>Dados da Revenda de Origem</Divider>
              <Row gutter={24}>
                <Col xs={24} lg={24}>
                  <Card>
                    <RevendaOrigemPanel
                      form={form}
                      formDisabled={!params.local && mode !== 'CREATE'}
                      revendaOrigemDados={notaFiscalSelecionada?.revendaOrigem}
                      isReadOnly={false}
                    />
                  </Card>
                </Col>
              </Row>
              <Divider orientation='left'>Dados da Revenda de Destino</Divider>
              <Row gutter={24}>
                <Col xs={24} lg={24}>
                  <RevendaPanelNotaFiscal
                    form={form}
                    formDisabled={!params.local && mode !== 'CREATE'}
                    revendaDestinoDados={
                      notaFiscalSelecionada?.revendaDestino as any
                    }
                    allowSearch={false}
                    isReadOnly={true}
                    onClearForm={handleClearForm}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider orientation='left'>
            Informação sobre a conservação dos produtos
          </Divider>
          <Row gutter={24}>
            <Col xs={24} sm={24}>
              <br />
              <Form.Item
                validateTrigger={'onSubmit'}
                name='tableChavesPrimarias'
              >
                <Table<any>
                  size={'small'}
                  dataSource={itens}
                  rowKey={(record) => record.id}
                  title={() => (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: '#FFF' }}>
                        Produtos
                      </Typography.Title>
                    </Row>
                  )}
                  columns={[
                    {
                      title: 'Produto',
                      dataIndex: ['produto', 'nome'],
                    },
                    {
                      title: 'Status',
                      dataIndex: 'status',
                      render: (status: string) => {
                        const getColor = (status: string) => {
                          switch (status) {
                            case 'RECEBIDO':
                              return 'green';
                            case 'NAO_RECEBIDO':
                              return 'red';
                            default:
                              return 'yellow';
                          }
                        };

                        const getLabel = (status: string) => {
                          switch (status) {
                            case 'RECEBIDO':
                              return 'Recebido';
                            case 'NAO_RECEBIDO':
                              return 'Não Recebido';
                            default:
                              return 'Pendente';
                          }
                        };

                        return (
                          <Tag color={getColor(status)} key={status}>
                            {getLabel(status)}
                          </Tag>
                        );
                      },
                    },
                    {
                      title: 'Ações',
                      width: 100,
                      render: (
                        _,
                        record: RecebimentosRevendas.Response['itens']
                      ) => (
                        <Button
                          icon={<EditOutlined />}
                          type='primary'
                          onClick={() => showModal(record)}
                        >
                          Editar
                        </Button>
                      ),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation='left'>Adicionar Servidores</Divider>
          <ServidoresPanel
            form={form}
            formDisabled={formDisabled}
            listaServidores={listaServidores}
            setListaServidores={setListaServidores}
          />
          <br />
          <TFRPanel
            form={form}
            formDisabled={formDisabled}
            selectTFR={selectTFR as any}
            setSelectTFR={setSelectTFR}
          />
          <br />
          <Divider orientation='left'>Assinatura do responsável</Divider>

          <Col xs={24} lg={24}>
            <Form.Item
              label={'Como foi realizada a assinatura?'}
              name={'statusAssinatura'}
              valuePropName={'checked'}
              rules={[{ required: true, message: 'O campo é obrigatório' }]}
              initialValue={form.getFieldValue('statusAssinatura')}
            >
              <Radio.Group
                value={
                  statusAssinaturaState ||
                  form.getFieldValue('statusAssinatura')
                }
                onChange={(e) => {
                  setStatusAssinaturaState(e.target.value);
                  form.setFieldsValue({ statusAssinatura: e.target.value });
                }}
              >
                <Space direction='vertical'>
                  {listaStatusAssinatura?.map((statusAssinatura) => {
                    let label;

                    // Mapeia o statusAssinatura para o label correspondente
                    switch (statusAssinatura) {
                      case 'ASSINADO_DIGITALMENTE':
                        label = 'Assinado Digitalmente';
                        break;
                      case 'ASSINADO_FISICAMENTE':
                        label = 'Assinado Fisicamente';
                        break;
                      case 'AUSENTE_ESTABELECIMENTO':
                        label = 'Ausência de Pessoas no Estabelecimento';
                        break;
                      case 'PRODUTOR_NAO_ALFABETIZADO':
                        label = 'Produtor Não Alfabetizado';
                        break;
                      case 'PRODUTOR_RECUSOU_ASSINAR':
                        label = 'Responsável recusou-se a assinar';
                        break;
                      default:
                        label = '';
                    }
                    if (label) {
                      return (
                        <Radio key={statusAssinatura} value={statusAssinatura}>
                          {label}
                        </Radio>
                      );
                    }
                    return null;
                  })}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>

          {statusAssinaturaState ===
            StatusAssinatura.keyOf(StatusAssinatura.ASSINADO_DIGITALMENTE) && (
            <Col span={24}>
              <Form.Item
                validateTrigger={'onSubmit'}
                name='assinatura'
                rules={[
                  {
                    validator(_, value) {
                      if (
                        statusAssinaturaState ===
                        StatusAssinatura.keyOf(
                          StatusAssinatura.ASSINADO_DIGITALMENTE
                        )
                      ) {
                        if (!assinaturaBase64Image)
                          return Promise.reject(
                            new Error(
                              'Deve ser informada a assinatura do produtor'
                            )
                          );
                        return Promise.resolve();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <SignatureWrapper
                  default={props.recebimentosRevendas?.assinatura}
                  disabled={formDisabled}
                  onSaveSignature={() => {
                    form.validateFields(['assinatura']);
                  }}
                  onClearSignature={() => {
                    // form.setFieldsValue(() => []);
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <br />
          <br />
          <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() => navigate(`${'/recebimentoRevenda'}`)}
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/recebimentosRevendas/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>

      <Modal
        title='Selecionar Hora'
        visible={showModalTimePicker}
        onCancel={() => setShowModalTimePicker(false)}
        footer={null}
      >
        <DatePicker
          showTime
          format='HH:mm'
          onChange={(time) => {
            form.setFieldsValue({
              horaDaVisita: time?.format('HH:mm'),
            });
            setShowModalTimePicker(false);
          }}
        />
      </Modal>

      <ProductEditModal
        visible={isProductModalVisible}
        onCancel={handleProductModalCancel}
        onOk={handleAddProduct}
        initialValues={selectedProduct}
        onClearForm={handleClearForm}
      />
    </>
  );
}
