export enum TipoLocalAcaoEducativa {
  ASSOCIACAO_DE_PRODUTORES = 'Associação de produtores',
  AUTARQUIA = 'Autarquias / Secretarias estaduais',
  CAMARA_MUNICIPAL = 'Câmaras municipais',
  COMUNIDADE_RURAL = 'Comunidade Rural',
  COOPERATIVA = 'Cooperativa',
  EMISSORA_DE_TV = 'Emissora de TV',
  EMISSORA_DE_RADIO = 'Emissora de rádio',
  ESCOLA = 'Escola',
  EVENTO_AGROPECUARIO = 'Evento Agropecuário',
  INDUSTRIA = 'Indústria',
  LOCAL_DE_EVENTOS = 'Local para eventos (clube, buffet, restaurantes, salão paroquial, etc)',
  LOJA_VETERINARIA = 'Loja Veterinária',
  PREFEITURA = 'Prefeitura',
  REVENDA_DE_PRODUTOS_VETERINARIOS = 'Revenda de Produtos Veterinários',
  SINDICATO_RURAL = 'Sindicato Rural',
  SINDICATO_DE_TRABALHADORES = 'Sindicato de Trabalhadores'
}

export namespace TipoLocalAcaoEducativa {
  export function keys(): string[] {
    return Object.keys(TipoLocalAcaoEducativa).filter(
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoLocalAcaoEducativa[key as keyof typeof TipoLocalAcaoEducativa];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoLocalAcaoEducativa).indexOf(
      value as unknown as TipoLocalAcaoEducativa
    );

    const key = Object.keys(TipoLocalAcaoEducativa)[indexOfS];
    return key;
  }
}
