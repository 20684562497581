import { Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useFVV from '../../../core/hooks/useFVV';
import FormFVV from './FormFVV';

export default function ViewFVV() {
  const { loading, setLoading } = useLoadingPage();

  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();

  const { fetchById, fvv } = useFVV();

  useEffect(() => {
    if (!isNaN(Number(params.id))) {
      fetchById(Number(params.id)).catch((e) => {
        setError(e);
      });
    }
  }, [fetchById, params]);

  useEffect(() => {
    setLoading(false);
  }, [fvv]);

  if (error) throw error;

  const prepareData = useCallback((fvv) => {
    const dataVigilancia = moment(fvv.dataVigilancia);

    return {
      ...fvv,
      dataVigilancia: dataVigilancia,
    };
  }, []);

  return (
    <>
      {!fvv && <Skeleton active />}

      {fvv && (
        <>
          <Typography.Title level={2}>
            FVV
            {fvv ? ` nº ${fvv?.numero}` : ' offline'}
          </Typography.Title>

          <Spin
            size='large'
            spinning={loading}
            style={{
              position: 'fixed',
              top: '20%',
              zIndex: '1',
            }}
          >
            <FormFVV fvv={prepareData(fvv)} />
          </Spin>
        </>
      )}
    </>
  );
}
