import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import {LIA } from '../../../../sdk/@types';

type ControleAcessoProps = {
  data:LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  specify?: string;
};
export default function PrintControleAcesso({ data }: ControleAcessoProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.specify && (
                  <div style={{ marginTop: '10px' }}>
                    <strong>Especificar:</strong> <br/>{row.specify}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {panelDescription('Controle de Acesso', [
          {
            label:
              '4.1 - A granja possui sinais de aviso para evitar a entrada de pessoas alheias ao processo produtivo?',
            condition: data?.granjaPossuiSinaisDeAviso_4_1,
            specify: data?.granjaPossuiSinaisDeAvisoEspecificacao_4_1,
          },
          {
            label: '4.2 - Realiza controle e registro do trânsito de veículos?',
            condition: data?.realizaControleDeTransitoDeVeiculos_4_2,
            specify: data?.realizaControleDeTransitoDeVeiculosEspecificacao_4_2,
          },
          {
            label:
              '4.3 - Executa procedimentos para a desinfecção de veículos, na entrada e na saída do estabelecimento avícola?',
            condition: data?.executaProcedimentosParaDesinfeccaoDeVeiculos_4_3,
            specify:
              data?.executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3,
          },
          {
            label:
              '4.4 - Realiza controle e registro de acesso de pessoas a granja?',
            condition: data?.realizaControleDeAcessoDePessoas_4_4,
            specify: data?.realizaControleDeAcessoDePessoasEspecificacao_4_4,
          },
          {
            label:
              '4.5 - A granja possui barreira sanitária provida de sanitários internos e vestiários com piso, parede e teto impermeáveis, pia com torneira para higienização das mãos e lavador de botas?',
            condition: data?.granjaPossuiBarreiraSanitaria_4_5,
            specify: data?.granjaPossuiBarreiraSanitariaEspecificacao_4_5,
          },
          {
            label:
              '4.6 - O ingresso e egresso de equipamento e materiais na área interna do estabelecimento avícola ocorre quando cumpridas rigorosas medidas de limpeza e desinfecção ou outra medida de biosseguridade aprovada pelo INDEA-MT?',
            condition:
              data?.ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6,
            specify:
              data?.ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6,
          },
          {
            label:
              '4.7 - Os funcionários e/ou pessoa autorizada (incluindo visitantes) adentram o estabelecimento avícola utilizando roupas e calçados limpos e exclusivos para a atividade?',
            condition:
              data?.funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7,
            specify:
              data?.funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7,
          },
          {
            label:
              '4.8 - A granja possui fluxo operacional e medidas higiênico-sanitárias a fim de evitar a contaminação do material limpo e desinfetado a ser utilizado na produção com os demais descartes da produção?',
            condition:
              data?.granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8,
            specify:
              data?.granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8,
          },
          {
            label: 'Observações',
            condition: data?.observacoesControleAcessoDePessoas,
          },
        ])}
      </div>
    </>
  );
}
