import { LocalAcaoEducativa } from '../../@types';
import Service from './Service';
import generateQueryString from '../../utils/generateQueryString';

class LocalAcaoEducativaService extends Service {
  static BASE_URL = '/locais-acao-educativa';

  static getAll(
    query: LocalAcaoEducativa.Query
  ): Promise<LocalAcaoEducativa.Summary> {
    const queryString = generateQueryString(query);
    return this.Http.get<LocalAcaoEducativa.Summary>(
      this.BASE_URL.concat(queryString)
    ).then(this.getData);
  }
  static async add(
    LocalAcaoEducativaRequest: LocalAcaoEducativa.Request
  ): Promise<LocalAcaoEducativa.Request> {
    try {
      const response = await this.Http.post<LocalAcaoEducativa.Request>(
        this.BASE_URL,
        LocalAcaoEducativaRequest
      );
      return this.getData(response);
    } catch (error) {
      throw error;
    }
  }
  static async update(id: number, data: LocalAcaoEducativa.Request) {
    const localAcaoEducativaDTO = {
      ...data,
    };

    return this.Http.put<LocalAcaoEducativa.Request>(
      `${this.BASE_URL}/${id}`,
      localAcaoEducativaDTO
    ).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get<LocalAcaoEducativa.Response>(
      `${this.BASE_URL}/${id}`
    ).then(this.getData);
  }

  static activate(id: number) {
    return this.Http.post<boolean>(
      `${this.BASE_URL}/${id}/ativar`
    ).then(this.getData);
  }

  static deactivate(id: number){
    return this.Http.delete<boolean>(
        `${this.BASE_URL}/${id}/inativar`
      ).then(this.getData)
    }
}

export default LocalAcaoEducativaService;
