import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type InstalacaoEquipamentosProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  specify?: string;
};
export default function PrintInstalacaoEquipamentos({
  data,
}: InstalacaoEquipamentosProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.specify && (
                  <div>
                    <strong>Especificar:</strong> <br />
                    {row.specify}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {data &&
          panelDescription('Instalações e Equipamentos', [
            {
              label:
                '5.1 - As instalações foram construídas com materiais que permitem limpeza e desinfecção adequadas?',
              condition:
                data.instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1,
              specify:
                data.instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1,
            },
            {
              label:
                '5.2 - O piso do(s) aviário(s) é feito de terra compactada ou cimentada?',
              condition: data.pisoFeitoTerraCompactadaOuCimentado_5_2,
              specify:
                data.pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2,
            },
            {
              label:
                '5.3 - A mureta é de alvenaria com acabamento adequado à limpeza e desinfecção?',
              condition: data.muretaDeAlvenariaAdequadoParaLimpeza_5_3,
              specify:
                data.muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3,
            },
            {
              label:
                '5.4 - A malha da tela dos aviários é igual ou menor que 1 (uma) polegada ou 2,54 cm?',
              condition: data.malhaDaTelaDosAviariosAteUmaPolegada_5_4,
              specify:
                data.malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4,
            },
            {
              label:
                '5.5 - A cobertura do telhado é feita com material impermeável e que permita limpeza e desinfecção adequada?',
              condition: data.coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5,
              specify:
                data.coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5,
            },
            {
              label:
                '5.6 - O(s) aviário(s) possuem nebulizadores e/ou sistema de ventilação adequados?',
              condition: data.possuemNebulizadoresEOuSistemaDeVentilacao_5_6,
              specify:
                data.possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6,
            },
            {
              label:
                '5.7 - Os bebedouros e comedouros são de fácil manejo e permitem adequada limpeza e desinfecção?',
              condition: data.bebedourosEComedourosDeFacilManejo_5_7,
              specify: data.bebedourosEComedourosDeFacilManejoEspecificacao_5_7,
            },
            {
              label:
                '5.8 - Possui cerca de isolamento de no mínimo 1m de altura em volta do(s) aviário(s) ou do núcleo?',
              condition: data.possuiCercaDeIsolamentoMimino1m_5_8,
              specify: data.possuiCercaDeIsolamentoMimino1mEspecificacao_5_8,
            },
            {
              label:
                '5.9 - Silo(s) de ração é feito de material impermeável que permite sua adequada limpeza e desinfecção?',
              condition: data.siloDeRacaoFeitoDeMaterialIpermeavel_5_9,
              specify:
                data.siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9,
            },
            {
              label:
                '5.10 - Ausência de árvores atrativas de pássaros (frutíferas) dentro da granja?',
              condition: data.ausenciaDeArvoresAtrativasDePassaros_5_10,
              specify:
                data.ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10,
            },
            {
              label:
                '5.11 - A granja adota sistema de criação ao ar livre com proteção adequada?',
              condition: data.adotaSistemaDeCriacaoAoArLivre_5_11,
              specify: data.adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11,
            },
            {
              label: 'Observações',
              condition: data.observacoesInstalacoesEquipamentos_5_12,
            },
          ])}
      </div>
    </>
  );
}
