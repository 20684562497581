import { useCallback, useState } from 'react';
import { TipoChavePrincipalFVER } from '../../sdk/@types';
import TipoChavePrincipalFVERIDBService from '../../sdk/services/indexeddb/TipoChavePrincipalFVERIDB.service';
import TipoChavePrincipalFVERService from '../../sdk/services/SIZ-API/TipoChavePrincipalFVER.service';

export default function useTipoChavePrincipalFVER() {
  const [tipoChavePrincipalFVER, setTipoChavePrincipalFVER] =
    useState<TipoChavePrincipalFVER.Summary[]>();

  const fetchActive = useCallback(async (fromApi?: boolean) => {
    try {
      if (!fromApi) {
        await TipoChavePrincipalFVERIDBService.getAllPayload().then((lista) => {
          if (!lista || lista.length !== 0) setTipoChavePrincipalFVER(lista);
          else
            throw Error('Não foram encontrados Tipo Chave Principal offline');
        });
      } else {
        await TipoChavePrincipalFVERService.getAllActive().then(
          setTipoChavePrincipalFVER
        );
      }
    } catch (e: any) {
      await TipoChavePrincipalFVERService.getAllActive().then(
        setTipoChavePrincipalFVER
      );
    } finally {
    }
  }, []);

  return {
    tipoChavePrincipalFVER,
    fetchActive,
  };
}
