import * as XLSX from 'xlsx';

export const exportToXLSX = (data: any[], formName: string): boolean => {

  // Checando se há dados além do cabeçalho
  if (data.length === 0) {
    return false;
  }

  const truncatedFormName = formName.slice(0, 31);

  // Headers (perguntas) na primeira linha
  const headers = Object.keys(data[0]);
  const worksheetData = [headers];

  // Adicionando respostas para cada linha subsequente
  data.forEach(row => {
    const rowData = headers.map(header => row[header]);
    worksheetData.push(rowData);
  });

  // Criar worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Adicionando estilos ao cabeçalho
  const headerStyle = {
    font: { bold: true, sz: 14 },
    alignment: { horizontal: 'center' },
  };

  headers.forEach((header, index) => {
    const cellRef = XLSX.utils.encode_cell({ c: index, r: 0 }); // Primeira linha para cabeçalho
    if (!worksheet[cellRef]) worksheet[cellRef] = {};
    worksheet[cellRef].s = headerStyle;
  });

  // Inicializando colWidths como um array de números
  const colWidths = headers.map(header => ({
    wch: Math.min(45, Math.max(15, header.length + 10)),
  }));

  worksheet['!cols'] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, truncatedFormName);
  XLSX.writeFile(workbook, `${formName}.xlsx`);
  return true; // Retorna true se os dados foram exportados com sucesso
};
