import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  notification,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import useMunicipio from '../../../core/hooks/useMunicipio';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { Municipio } from '../../../sdk/@types/Municipio';
import MunicipioIDBService from '../../../sdk/services/indexeddb/MunicipioIDB.service';
import moment from 'moment';
import ServiceIDB, {
  ServiceIDBPayload,
} from '../../../sdk/services/indexeddb/ServiceIDB';
import confirm from 'antd/lib/modal/confirm';
import { useForm } from 'antd/lib/form/Form';
import PropriedadeIDBService from '../../../sdk/services/indexeddb/PropriedadeIDB.service';
import SetorIDBService from '../../../sdk/services/indexeddb/SetorIDB.service';
import AbatedouroIDBService from '../../../sdk/services/indexeddb/AbatedouroIDB.service';
import RecintoIDBService from '../../../sdk/services/indexeddb/RecintoIDB.service';
import RevendaIDBService from '../../../sdk/services/indexeddb/RevendasIDB.service';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import PropriedadeService from '../../../sdk/services/SIZ-API/Propriedade.service';
import {
  Abatedouro,
  Propriedade,
  Recinto,
  Setor,
  Revenda,
} from '../../../sdk/@types';
import AbatedouroService from '../../../sdk/services/SIZ-API/Abatedouro.service';
import SetorService from '../../../sdk/services/SIZ-API/Setor.service';
import RecintoService from '../../../sdk/services/SIZ-API/Recinto.service';
import RevendaService from '../../../sdk/services/SIZ-API/Revenda.service';
import { SYNC_MUNICIPIO_DONE } from '../../../sdk/@types/ServiceWorker.types';
import { CustomModal } from '../../components/CustomModal';

type ForMunicipiosOfflineType = {
  municipio: Municipio.Detailed;
};
export default function MunicipiosOffline() {
  const [form] = useForm<ForMunicipiosOfflineType>();
  const [
    showModalCadastroMunicipioOffline,
    setShowModalCadastroMunicipioOffline,
  ] = useState(false);

  const [showDetailError, setShowDetailError] = useState(false);
  const { online } = useNavigatorStatus();

  const { setLoading } = useLoadingPage();

  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();

  const [saving, setSaving] = useState<boolean>(false);
  const [savingComplete, setSavingComplete] = useState<boolean>(false);

  const [removendoMunicipio, setRemovendoMunicipio] = useState<boolean>(false);

  const [fetchingAbatedouro, setFetchingAbatedouro] = useState<boolean>(false);

  const [fetchingEstabelecimentoRural, setFetchingEstabelecimentoRural] =
    useState<boolean>(false);
  const [fetchingRecinto, setFetchingRecinto] = useState<boolean>(false);
  const [fetchingSetor, setFetchingSetor] = useState<boolean>(false);
  const [fetchingRevenda, setFetchingRevenda] = useState<boolean>(false);

  const [erroSalvandoAbatedouro, setErroSalvandoAbatedouro] =
    useState<boolean>(false);
  const [
    erroSalvandoEstabelecimentoRural,
    setErroSalvandoEstabelecimentoRural,
  ] = useState<boolean>(false);
  const [erroSalvandoRecinto, setErroSalvandoRecinto] =
    useState<boolean>(false);
  const [erroSalvandoSetor, setErroSalvandoSetor] = useState<boolean>(false);
  const [erroSalvandoRevenda, setErroSalvandoRevenda] =
    useState<boolean>(false);

  const [abatedouroSincronizado, setAbatedouroSincronizado] =
    useState<boolean>(false);
  const [
    estabelecimentoRuralSincronizado,
    setEstabelecimentoRuralSincronizado,
  ] = useState<boolean>(false);

  const [recintoSincronizado, setRecintoSincronizado] =
    useState<boolean>(false);
  const [setorSincronizado, setSetorSincronizado] = useState<boolean>(false);
  const [revendaSincronizado, setRevendaSincronizado] =
    useState<boolean>(false);

  const [error, setError] = useState<Error>();

  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();
  const [municipiosSincronizados, setMunicipiosSincronizados] = useState<
    ServiceIDBPayload[]
  >([]);

  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };

  const [totalAbatedouro, setTotalAbatedouro] = useState(0);
  const [totalEstabelecimentoRural, setTotalEstabelecimentoRural] = useState(0);
  const [totalRecinto, setTotalRecinto] = useState(0);
  const [totalSetor, setTotalSetor] = useState(0);
  const [totalRevenda, setTotalRevenda] = useState(0);

  const [contadorAbatedouro, setContadorAbatedouro] = useState(0);
  const [contadorEstabelecimentoRural, setContadorEstabelecimentoRural] =
    useState(0);
  const [contadorRecinto, setContadorRecinto] = useState(0);
  const [contadorSetor, setContadorSetor] = useState(0);
  const [contadorRevenda, setContadorRevenda] = useState(0);

  const fetchMunicipiosSincronizados = useCallback(async () => {
    try {
      setMunicipiosSincronizados([]);
      await MunicipioIDBService.getAll().then(setMunicipiosSincronizados);
    } catch (e) {
      setError(
        new Error(
          'Erro ao buscar dados offline. Contate o administrador do sistema.'
        )
      );
    }
  }, []);

  const getPercentage = (atual: number, total: number): number => {
    if (removendoMunicipio) return 0;
    if (total === 0) return 100;
    return Number(((atual * 100) / total).toFixed(2));
  };

  useEffect(() => {}, [
    contadorAbatedouro,
    erroSalvandoAbatedouro,
    fetchingAbatedouro,
    totalAbatedouro,
    abatedouroSincronizado,
  ]);

  useEffect(() => {}, [
    contadorEstabelecimentoRural,
    erroSalvandoEstabelecimentoRural,
    fetchingEstabelecimentoRural,
    totalEstabelecimentoRural,
    estabelecimentoRuralSincronizado,
  ]);

  useEffect(() => {}, [
    contadorRecinto,
    erroSalvandoRecinto,
    fetchingRecinto,
    totalRecinto,
    recintoSincronizado,
  ]);

  useEffect(() => {}, [
    contadorSetor,
    erroSalvandoSetor,
    fetchingSetor,
    totalSetor,
    setorSincronizado,
  ]);

  useEffect(() => {}, [
    contadorRevenda,
    erroSalvandoRevenda,
    fetchingRevenda,
    totalRevenda,
    revendaSincronizado,
  ]);

  useEffect(() => {}, [saving, savingComplete]);

  useEffect(() => {
    fetchMunicipiosSincronizados();
  }, [fetchMunicipiosSincronizados]);

  useEffect(() => {
    fetchMunicipiosByUf('mt').catch((e) => {
      setError(new Error(e.message));
    });
  }, [fetchMunicipiosByUf]);

  const limparDadosDeControle = () => {
    setShowModalCadastroMunicipioOffline(false);
    setSaving(false);
    setSavingComplete(false);
    setRemovendoMunicipio(false);
    setMunicipioSelecionado(undefined);
    form.setFieldValue('municipio', undefined);

    setContadorAbatedouro(0);
    setErroSalvandoAbatedouro(false);
    setFetchingAbatedouro(false);
    setTotalAbatedouro(0);
    setAbatedouroSincronizado(false);

    setContadorEstabelecimentoRural(0);
    setErroSalvandoEstabelecimentoRural(false);
    setFetchingEstabelecimentoRural(false);
    setTotalEstabelecimentoRural(0);
    setEstabelecimentoRuralSincronizado(false);

    setContadorRecinto(0);
    setErroSalvandoRecinto(false);
    setFetchingRecinto(false);
    setTotalRecinto(0);
    setRecintoSincronizado(false);

    setContadorSetor(0);
    setErroSalvandoSetor(false);
    setFetchingSetor(false);
    setTotalSetor(0);
    setSetorSincronizado(false);

    setContadorRevenda(0);
    setErroSalvandoRevenda(false);
    setFetchingRevenda(false);
    setTotalRevenda(0);
    setRevendaSincronizado(false);
  };

  const countAbatedouro = useCallback(async (codgIBGE: string) => {
    await AbatedouroService.countByMunicipio(codgIBGE)
      .then(async (total) => {
        if (total === 0) {
          setAbatedouroSincronizado(true);
          setFetchingAbatedouro(false);
          return;
        }
        setTotalAbatedouro(total);
      })
      .catch((e) => {
        setError(e);
        setFetchingAbatedouro(false);
      });
  }, []);

  const countEstabelecimentoRural = useCallback(async (codgIBGE: string) => {
    await PropriedadeService.countByMunicipio(codgIBGE)
      .then(async (total) => {
        if (total === 0) {
          setEstabelecimentoRuralSincronizado(true);
          setFetchingEstabelecimentoRural(false);
          return;
        }
        setTotalEstabelecimentoRural(total);
      })
      .catch((e) => {
        setError(e);
        setFetchingEstabelecimentoRural(false);
      });
  }, []);

  const countRecinto = useCallback(async (codgIBGE: string) => {
    await RecintoService.countByMunicipio(codgIBGE)
      .then(async (total) => {
        if (total === 0) {
          setRecintoSincronizado(true);
          setFetchingRecinto(false);
          return;
        }
        setTotalRecinto(total);
      })
      .catch((e) => {
        setError(e);
        setFetchingRecinto(false);
      });
  }, []);

  const countSetor = useCallback(async (codgIBGE: string) => {
    await SetorService.countByMunicipio(codgIBGE)
      .then(async (total) => {
        if (total === 0) {
          setSetorSincronizado(true);
          setFetchingSetor(false);
          return;
        }
        setTotalSetor(total);
      })
      .catch((e) => {
        setError(e);
        setFetchingSetor(false);
      });
  }, []);

  const countRevenda = useCallback(async (codgIBGE: string) => {
    await RevendaService.countByMunicipio(codgIBGE)
      .then(async (total) => {
        if (total === 0) {
          setRevendaSincronizado(true);
          setFetchingRevenda(false);
          return;
        }
        setTotalRevenda(total);
      })
      .catch((e) => {
        setError(e);
        setFetchingRevenda(false);
      });
  }, []);

  const syncAbatedouro = useCallback(
    async (codgIBGE: string): Promise<boolean> => {
      setFetchingAbatedouro(true);
      return new Promise(async (resolve) => {
        return await AbatedouroService.getByMunicipio(codgIBGE)
          .then(async (lista: Abatedouro.Summary[]) => {
            setFetchingAbatedouro(false);

            for await (const abatedouro of lista) {
              let erroSalvandoAbatedouro = false;
              await AbatedouroIDBService.add(abatedouro)
                .then(() => {
                  setContadorAbatedouro((prevState: number) => prevState + 1);
                })
                .catch((e) => {
                  erroSalvandoAbatedouro = true;
                  setError(e);
                  setErroSalvandoAbatedouro(true);
                  setFetchingAbatedouro(false);
                });

              if (erroSalvandoAbatedouro) return resolve(false);
            }
            setFetchingAbatedouro(false);
            setAbatedouroSincronizado(true);
            return resolve(true);
          })
          .catch((e) => {
            setError(e);
            setErroSalvandoAbatedouro(true);
            setFetchingAbatedouro(false);
            return resolve(false);
          });
      });
    },
    []
  );

  const syncEstabelecimentoRural = useCallback(
    async (codgIBGE: string): Promise<boolean> => {
      setFetchingEstabelecimentoRural(true);

      return new Promise(async (resolve) => {
        return await PropriedadeService.getByMunicipio(codgIBGE)
          .then(async (lista: Propriedade.Summary[]) => {
            setFetchingEstabelecimentoRural(false);

            for await (const estabelecimentoRural of lista) {
              let erroSalvandoEstabelecimentoRural = false;
              await PropriedadeIDBService.add(estabelecimentoRural)
                .then(() => {
                  setContadorEstabelecimentoRural(
                    (prevState: number) => prevState + 1
                  );
                })
                .catch((e) => {
                  erroSalvandoEstabelecimentoRural = true;
                  setError(e);
                  setErroSalvandoEstabelecimentoRural(true);
                  setFetchingEstabelecimentoRural(false);
                });

              if (erroSalvandoEstabelecimentoRural) return resolve(false);
            }
            setFetchingEstabelecimentoRural(false);
            setEstabelecimentoRuralSincronizado(true);
            return resolve(true);
          })
          .catch((e) => {
            setError(e);
            setErroSalvandoEstabelecimentoRural(true);
            setFetchingEstabelecimentoRural(false);
            return resolve(false);
          });
      });
    },
    []
  );

  const syncRecinto = useCallback(
    async (codgIBGE: string): Promise<boolean> => {
      setFetchingRecinto(true);

      return new Promise(async (resolve) => {
        return await RecintoService.getByMunicipio(codgIBGE)
          .then(async (lista: Recinto.Summary[]) => {
            setFetchingRecinto(false);

            for await (const recinto of lista) {
              let erroSalvandoRecinto = false;
              await RecintoIDBService.add(recinto)
                .then(() => {
                  setContadorRecinto((prevState: number) => prevState + 1);
                })
                .catch((e) => {
                  erroSalvandoRecinto = true;
                  setError(e);
                  setErroSalvandoRecinto(true);
                  setFetchingRecinto(false);
                });

              if (erroSalvandoRecinto) return resolve(false);
            }
            setFetchingRecinto(false);
            setRecintoSincronizado(true);
            return resolve(true);
          })
          .catch((e) => {
            setError(e);
            setErroSalvandoRecinto(true);
            setFetchingRecinto(false);
            return resolve(false);
          });
      });
    },
    []
  );

  const syncSetor = useCallback(async (codgIBGE: string): Promise<boolean> => {
    setFetchingSetor(true);

    return new Promise(async (resolve) => {
      return await SetorService.getByMunicipio(codgIBGE)
        .then(async (lista: Setor.Summary[]) => {
          setFetchingSetor(false);

          for await (const setor of lista) {
            let erroSalvandoSetor = false;
            await SetorIDBService.add(setor)
              .then(() => {
                setContadorSetor((prevState: number) => prevState + 1);
              })
              .catch((e) => {
                erroSalvandoSetor = true;
                setError(e);
                setErroSalvandoSetor(true);
                setFetchingSetor(false);
              });

            if (erroSalvandoSetor) return resolve(false);
          }
          setFetchingSetor(false);
          setSetorSincronizado(true);
          return resolve(true);
        })
        .catch((e) => {
          setError(e);
          setErroSalvandoSetor(true);
          setFetchingSetor(false);
          return resolve(false);
        });
    });
  }, []);

  const syncRevenda = useCallback(
    async (codgIBGE: string): Promise<boolean> => {
      setFetchingRevenda(true);

      return new Promise(async (resolve) => {
        return await RevendaService.getByMunicipio(codgIBGE)
          .then(async (lista: Revenda.Summary[]) => {
            setFetchingRevenda(false);

            for await (const abatedouro of lista) {
              let erroSalvandoRevenda = false;
              await RevendaIDBService.add(abatedouro)
                .then(() => {
                  setContadorRevenda((prevState: number) => prevState + 1);
                })
                .catch((e) => {
                  erroSalvandoRevenda = true;
                  setError(e);
                  setErroSalvandoRevenda(true);
                  setFetchingRevenda(false);
                });

              if (erroSalvandoRevenda) return resolve(false);
            }
            setFetchingRevenda(false);
            setRevendaSincronizado(true);
            return resolve(true);
          })
          .catch((e) => {
            setError(e);
            setErroSalvandoRevenda(true);
            setFetchingRevenda(false);
            return resolve(false);
          });
      });
    },
    []
  );

  const removerMunicipios = useCallback(async () => {
    setLoading(true);

    await MunicipioIDBService.deleteAll()
      .then(() => {
        setMunicipiosSincronizados([]);
        notification.success({ message: 'Dados removidos com sucesso' });
        setLoading(false);
      })
      .catch((e) => {
        notification.error({
          message:
            'Houve um erro ao remover os dados. Contate o administrador do sistema',
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const removerMunicipio = useCallback(
    async (idMunicipio: string, showMessage?: boolean) => {
      setLoading(true);
      await MunicipioIDBService.delete(idMunicipio)
        .then(() => {
          const helper = municipiosSincronizados;
          setMunicipiosSincronizados(
            helper.filter((payload) => payload.payload.codgIBGE !== idMunicipio)
          );

          if (showMessage)
            notification.success({ message: 'Dados removidos com sucesso' });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [municipiosSincronizados, setLoading]
  );

  const sincronizarMunicipio = useCallback(async () => {
    setSaving(true);

    if (municipioSelecionado && municipioSelecionado?.codgIBGE) {
      setRemovendoMunicipio(true);
      // Step: DELETE MUNICIPIO
      console.log('MUNICIPIO -> START DELETE');
      await MunicipioIDBService.delete(municipioSelecionado?.codgIBGE)
        .then(() => setRemovendoMunicipio(false))
        .catch((e) => {
          setRemovendoMunicipio(false);
          throw e;
        });
      console.log('MUNICIPIO -> FIM DELETE');

      // Step: ADICIONA MUNICIPIO
      console.log('MUNICIPIO -> START ADICIONA');
      await MunicipioIDBService.add(municipioSelecionado);
      console.log('MUNICIPIO -> FIM ADICIONA');

      // Step: COUNT ABATEDOURO
      console.log('ABATEDOURO -> START COUNT');
      await countAbatedouro(municipioSelecionado?.codgIBGE);
      console.log('ABATEDOURO -> FIM COUNT');

      // Step: COUNT ESTABELECIMENTO RURAL
      console.log('ESTABELECIMENTO RURAL -> START COUNT');
      await countEstabelecimentoRural(municipioSelecionado?.codgIBGE);
      console.log('ESTABELECIMENTO RURAL -> FIM COUNT');

      // Step: COUNT RECINTO
      console.log('RECINTO -> START COUNT');
      await countRecinto(municipioSelecionado?.codgIBGE);
      console.log('RECINTO -> FIM COUNT');

      // Step: COUNT SETORES
      console.log('SETORES -> START COUNT');
      await countSetor(municipioSelecionado?.codgIBGE);
      console.log('SETORES -> FIM COUNT');

      // Step: COUNT REVENDA
      console.log('REVENDA -> START COUNT');
      await countRevenda(municipioSelecionado?.codgIBGE);
      console.log('REVENDA -> FIM COUNT');

      let statusSyncAbatedouro = true;
      let statusSyncEstabelecimentoRural = true;
      let statusSyncRecinto = true;
      let statusSyncSetor = true;
      let statusSyncRevenda = true;

      if (
        statusSyncAbatedouro &&
        statusSyncEstabelecimentoRural &&
        statusSyncRecinto &&
        statusSyncSetor &&
        statusSyncRevenda
      ) {
        // Step: SYNC ABATEDOURO
        console.log('ABATEDOURO -> START SYNC');
        statusSyncAbatedouro = await syncAbatedouro(
          municipioSelecionado?.codgIBGE
        );
        console.log('ABATEDOURO -> FIM FETCH');
      }
      if (
        statusSyncAbatedouro &&
        statusSyncEstabelecimentoRural &&
        statusSyncRecinto &&
        statusSyncSetor &&
        statusSyncRevenda
      ) {
        // Step: SYNC ESTABELECIMENTO RURAL
        console.log('ESTABELECIMENTO RURAL -> START SYNC');
        statusSyncEstabelecimentoRural = await syncEstabelecimentoRural(
          municipioSelecionado?.codgIBGE
        );
        console.log('ESTABELECIMENTO RURAL -> FIM FETCH');
      }
      if (
        statusSyncAbatedouro &&
        statusSyncEstabelecimentoRural &&
        statusSyncRecinto &&
        statusSyncSetor &&
        statusSyncRevenda
      ) {
        // Step: SYNC RECINTO

        console.log('RECINTO -> START SYNC');
        statusSyncRecinto = await syncRecinto(municipioSelecionado?.codgIBGE);
        console.log('RECINTO -> FIM FETCH');
      }
      if (
        statusSyncAbatedouro &&
        statusSyncEstabelecimentoRural &&
        statusSyncRecinto &&
        statusSyncSetor &&
        statusSyncRevenda
      ) {
        // Step: SYNC SETOR
        console.log('SETOR -> START SYNC');
        statusSyncSetor = await syncSetor(municipioSelecionado?.codgIBGE);
        console.log('SETOR -> FIM FETCH');
      }
      if (
        statusSyncAbatedouro &&
        statusSyncEstabelecimentoRural &&
        statusSyncRecinto &&
        statusSyncSetor &&
        statusSyncRevenda
      ) {
        // Step: SYNC REVENDA
        console.log('REVENDA -> START SYNC');
        statusSyncRevenda = await syncRevenda(municipioSelecionado?.codgIBGE);
        console.log('REVENDA -> FIM FETCH');
      }

      // Step: VALIDA ERROS

      if (
        !statusSyncAbatedouro ||
        !statusSyncEstabelecimentoRural ||
        !statusSyncRecinto ||
        !statusSyncSetor ||
        !statusSyncRevenda
      ) {
        await removerMunicipio(municipioSelecionado?.codgIBGE);
      }

      // STEP: FINALIZAÇÃO
      await fetchMunicipiosSincronizados();
      navigator.serviceWorker?.controller?.postMessage(SYNC_MUNICIPIO_DONE);
      setSavingComplete(true);
    } else {
      notification.warn({
        message: 'Nenhum município selecionado!',
      });
    }
  }, [
    countAbatedouro,
    countEstabelecimentoRural,
    countSetor,
    countRecinto,
    countRevenda,
    fetchMunicipiosSincronizados,
    municipioSelecionado,
    removerMunicipio,
    syncAbatedouro,
    syncEstabelecimentoRural,
    syncSetor,
    syncRecinto,
    syncRevenda,
  ]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Table<ServiceIDBPayload>
            dataSource={municipiosSincronizados}
            rowKey={'id'}
            size={'small'}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Municípios
                  </Typography.Title>

                  <Col>
                    <Space>
                      <Button
                        icon={<DeleteOutlined />}
                        type={'primary'}
                        danger
                        title={'Remover todos'}
                        onClick={() => {
                          confirm({
                            title: 'Deseja remover todos os municípios?',
                            onOk: () => {
                              removerMunicipios();
                            },
                          });
                        }}
                      />

                      <Button
                        icon={<PlusOutlined />}
                        type={'primary'}
                        title={`${
                          !online
                            ? 'Sem conexão com a internet. Não é possível sincronizar'
                            : 'Adicionar'
                        }`}
                        disabled={!online}
                        onClick={() => {
                          setShowModalCadastroMunicipioOffline(true);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              );
            }}
            columns={[
              {
                dataIndex: ['payload', 'nome'],
                title: 'Nome',
                width: '50%',
              },
              {
                dataIndex: 'date',
                title: 'Data de sincronização',
                render(date: string) {
                  return moment(date).format('DD/MM/YYYY');
                },
              },

              {
                dataIndex: 'date',
                title: 'Status',
                render(date: Date) {
                  const expired = ServiceIDB.isDataExpired(date);
                  return (
                    <Tag
                      color={expired === undefined || expired ? 'red' : 'green'}
                    >
                      {expired === undefined
                        ? 'NÃO SINCRONIZADO'
                        : expired
                        ? 'EXPIRADO'
                        : 'VÁLIDO'}
                    </Tag>
                  );
                },
              },

              {
                dataIndex: ['payload', 'id'],
                title: 'Ações',
                width: 100,
                render(id, row) {
                  return (
                    <>
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        title='Remover dados'
                        size='small'
                        type='ghost'
                        onClick={() => {
                          confirm({
                            title: `Deseja remover os dados do município ${row.payload.nome}`,
                            onOk: () => {
                              removerMunicipio(row.payload.codgIBGE, true);
                            },
                          });
                        }}
                      />
                      <Button
                        icon={<SyncOutlined />}
                        title='Atualizar'
                        size='small'
                        type='ghost'
                        onClick={() => {
                          //:todo
                        }}
                      />
                    </>
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>

      <CustomModal
        centered
        open={showModalCadastroMunicipioOffline}
        title={
          saving
            ? `Sincronizando ${municipioSelecionado?.nome}`
            : 'Escolha um município para sincronizar'
        }
        onCancel={() => {
          setShowModalCadastroMunicipioOffline(false);
        }}
        footer={null}
        destroyOnClose
        closable={false}
        maskClosable={false}
        keyboard={!saving}
      >
        <Form<ForMunicipiosOfflineType>
          layout='vertical'
          onFinish={sincronizarMunicipio}
          form={form}
        >
          <Row>
            {!saving && (
              <Col xs={24} lg={24}>
                <Form.Item
                  label='Município'
                  name={['municipio', 'nome']}
                  rules={[{ required: true, message: 'O campo é obrigatório' }]}
                >
                  <Select
                    autoFocus
                    showAction={['focus']}
                    style={{ width: '100%' }}
                    showSearch
                    placeholder='Selecione um município'
                    optionFilterProp='children'
                    onChange={handleSelectMunicipioChange}
                    disabled={saving}
                    filterOption={(input, option) => {
                      if (option && option.children)
                        return (
                          option.children
                            .toString()
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(input.toLowerCase()) >= 0
                        );

                      return false;
                    }}
                  >
                    {listaMunicipio?.map((municipio) => (
                      <Select.Option
                        key={municipio.codgIBGE}
                        value={JSON.stringify(municipio)}
                      >
                        {municipio.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          {(erroSalvandoAbatedouro ||
            erroSalvandoEstabelecimentoRural ||
            erroSalvandoRecinto ||
            erroSalvandoSetor ||
            erroSalvandoRevenda) && (
            <Alert
              action={
                <Button
                  size='small'
                  danger
                  onClick={() => {
                    if (showDetailError) setShowDetailError(false);
                    else setShowDetailError(true);
                  }}
                >
                  {!showDetailError ? 'Detalhes' : 'Fechar'}
                </Button>
              }
              description={showDetailError ? error?.message : null}
              style={{ marginBottom: '8px' }}
              message={
                <span style={{ fontWeight: 'bold' }}>
                  Não foi possível finalizar a sincronização.
                </span>
              }
              type='error'
            />
          )}

          {saving && !savingComplete && (
            <Alert
              description='Esta operação pode demorar mais do que o esperado em locais com condições não ideais de internet.'
              showIcon
              style={{ marginBottom: '8px' }}
              type='warning'
            />
          )}

          {savingComplete &&
            !erroSalvandoAbatedouro &&
            !erroSalvandoEstabelecimentoRural &&
            !erroSalvandoRecinto &&
            !erroSalvandoSetor &&
            !erroSalvandoRevenda && (
              <Alert
                description='Sincronização finalizada com sucesso.'
                showIcon
                style={{ marginBottom: '8px' }}
                type='success'
              />
            )}

          {saving && (
            <Spin
              spinning={removendoMunicipio}
              size='small'
              tip={'Removendo dados desatualizados...'}
              style={{
                width: '100%',
              }}
            >
              <>
                <Typography.Text>
                  Abatedouros ({totalAbatedouro})
                </Typography.Text>
                <Spin
                  spinning={fetchingAbatedouro}
                  size='small'
                  tip={'Buscando...'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Progress
                    percent={
                      fetchingAbatedouro
                        ? 0
                        : getPercentage(contadorAbatedouro, totalAbatedouro)
                    }
                    status={
                      erroSalvandoAbatedouro
                        ? 'exception'
                        : abatedouroSincronizado
                        ? 'success'
                        : 'active'
                    }
                  />
                </Spin>
              </>

              <>
                <Typography.Text>
                  Estabelecimentos Rurais ({totalEstabelecimentoRural})
                </Typography.Text>
                <Spin
                  spinning={fetchingEstabelecimentoRural}
                  size='small'
                  tip={'Buscando...'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Progress
                    percent={
                      fetchingEstabelecimentoRural
                        ? 0
                        : getPercentage(
                            contadorEstabelecimentoRural,
                            totalEstabelecimentoRural
                          )
                    }
                    status={
                      erroSalvandoEstabelecimentoRural
                        ? 'exception'
                        : estabelecimentoRuralSincronizado
                        ? 'success'
                        : 'active'
                    }
                  />
                </Spin>
              </>

              <>
                <Typography.Text>Recintos ({totalRecinto})</Typography.Text>
                <Spin
                  spinning={fetchingRecinto}
                  size='small'
                  tip={'Buscando...'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Progress
                    percent={
                      fetchingRecinto
                        ? 0
                        : getPercentage(contadorRecinto, totalRecinto)
                    }
                    status={
                      erroSalvandoRecinto
                        ? 'exception'
                        : setorSincronizado
                        ? 'success'
                        : 'active'
                    }
                  />
                </Spin>
              </>

              <>
                <Typography.Text>Setores ({totalSetor})</Typography.Text>
                <Spin
                  spinning={fetchingSetor}
                  size='small'
                  tip={'Buscando...'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Progress
                    percent={
                      fetchingSetor
                        ? 0
                        : getPercentage(contadorSetor, totalSetor)
                    }
                    status={
                      erroSalvandoSetor
                        ? 'exception'
                        : setorSincronizado
                        ? 'success'
                        : 'active'
                    }
                  />
                </Spin>
              </>

              <>
                <Typography.Text>Revendas ({totalRevenda})</Typography.Text>
                <Spin
                  spinning={fetchingRevenda}
                  size='small'
                  tip={'Buscando...'}
                  style={{
                    width: '100%',
                  }}
                >
                  <Progress
                    percent={
                      fetchingRevenda
                        ? 0
                        : getPercentage(contadorRevenda, totalRevenda)
                    }
                    status={
                      erroSalvandoRevenda
                        ? 'exception'
                        : revendaSincronizado
                        ? 'success'
                        : 'active'
                    }
                  />
                </Spin>
              </>
            </Spin>
          )}

          <Divider />
          <Row gutter={24} justify={'end'}>
            {(!saving || savingComplete) && (
              <Col xs={12} lg={12}>
                <Button
                  style={{ width: '100%' }}
                  danger
                  icon={<CloseOutlined />}
                  onClick={() => {
                    limparDadosDeControle();
                    setShowModalCadastroMunicipioOffline(false);
                  }}
                >
                  {savingComplete ? 'Fechar' : 'Cancelar'}
                </Button>
              </Col>
            )}

            {!saving && (
              <Col xs={12} lg={12}>
                <Button
                  style={{ width: '100%' }}
                  type={'primary'}
                  htmlType='submit'
                  icon={<CheckCircleOutlined />}
                  loading={saving}
                >
                  Sincronizar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </CustomModal>
    </>
  );
}
