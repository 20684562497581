import { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Button, Col, Collapse, Divider, Form, Input, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Municipio, FVER } from '../../../sdk/@types';
import useMunicipio from '../../../core/hooks/useMunicipio';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import { DeleteOutlined } from '@ant-design/icons';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FVERActions from '../../../core/store/FVER.slice';
import useLoadingPage from '../../../core/hooks/useLoadingPage';

interface FilterPanelFVERProps {
  open?: boolean;
  defaultQuery?: FVER.Query;
}

export default function FilterPanelFVER(props: FilterPanelFVERProps) {
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const { online } = useNavigatorStatus();
  const { firstOpening, setFirstOpening } = useLoadingPage();
  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();
  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();
  const dispatch = useDispatch();

  const query = useSelector((state: RootState) => state.fver.query);

  useEffect(() => {
    if (online) fetchMunicipiosByUf('mt');
  }, [fetchMunicipiosByUf, online]);

  const fetchFVERs = useCallback(() => {
    dispatch(FVERActions.filter(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (!firstOpening) {
      if (online) {
        dispatch(FVERActions.setFetching(true));
        try {
          fetchFVERs();
        } finally {
          dispatch(FVERActions.setFetching(false));
        }
      }
    } else {
      setFirstOpening(false);
    }
  }, [dispatch, fetchFVERs, query, firstOpening]);

  const handleFormSubmit = useCallback(
    (values) => {
      const formValues = form.getFieldsValue();

      dispatch(
        FVERActions.setQuery({
          codigoEstabelecimento: formValues.codigoEstabelecimento,
          //@ts-ignore
          codigoMunicipio: municipioSelecionado?.codgIBGE,
          nomeEstabelecimento: formValues.nomeEstabelecimento,
          numero: formValues.numero
            ? formValues.numero.replace(/\D/g, '')
            : undefined,
          tipoEstabelecimento: formValues.tipoEstabelecimento,
          page: 0,
        })
      );
    },
    [dispatch, form, municipioSelecionado]
  );

  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };

  const removeNumero = useCallback(() => {
    form.setFieldsValue({
      numero: null,
    });
  }, [form]);

  const removeMunicipio = useCallback(() => {
    form.setFieldsValue({
      municipio: null,
    });
    setMunicipioSelecionado(undefined);
  }, [form]);

  const removeTipoEstabelecimento = useCallback(() => {
    form.setFieldsValue({
      tipoEstabelecimento: null,
    });
  }, [form]);

  const removeCodigoEstabelecimento = useCallback(() => {
    form.setFieldsValue({
      codigoEstabelecimento: null,
    });
  }, [form]);

  const removeNomeEstabelecimento = useCallback(() => {
    form.setFieldsValue({
      nomeEstabelecimento: null,
    });
  }, [form]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Collapse
          defaultActiveKey={props.open ? '1' : '0'}
          style={{ width: '100%', backgroundColor: '#e0e4e8' }}
        >
          <Collapse.Panel
            header={<span style={{ fontWeight: 'bold' }}>Filtrar</span>}
            key={1}
          >
            <Form
              layout={'horizontal'}
              form={form}
              size={!xs ? 'small' : 'large'}
              onFinish={handleFormSubmit}
              autoComplete={'off'}
            >
              <Col span={24}>
                <Form.Item label={'Número'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'numero'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <InputMask
                        onClick={() => {
                          scrollFieldToTop('horaDaVisita');
                        }}
                        mask={[
                          /^([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          '-',
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          '/',
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                          /([0-9])/,
                        ]}
                      >
                        <Input />
                      </InputMask>
                    </Form.Item>

                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeNumero}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'Município'} style={{ width: '100%' }}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'municipio'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder='Selecione um município'
                        optionFilterProp='children'
                        onChange={handleSelectMunicipioChange}
                        filterOption={(input, option) => {
                          if (option && option.children)
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(input.toLowerCase()) >= 0
                            );

                          return false;
                        }}
                      >
                        {listaMunicipio?.map((municipio) => (
                          <Select.Option
                            key={municipio.codgIBGE}
                            value={JSON.stringify(municipio)}
                          >
                            {municipio.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeMunicipio}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={'Tipo Estabelecimento'}
                  style={{ width: '100%' }}
                >
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'tipoEstabelecimento'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Select
                        onClick={() => {
                          scrollFieldToTop('tipoEstabelecimento');
                        }}
                        placeholder={'Selecione o tipo'}
                        placement={'bottomLeft'}
                      >
                        <Select.Option key={'ABATEDOURO'} value={'ABATEDOURO'}>
                          Abatedouro
                        </Select.Option>
                        <Select.Option
                          key={'PROPRIEDADE'}
                          value={'PROPRIEDADE'}
                        >
                          Estabelecimento Rural
                        </Select.Option>
                        <Select.Option key={'RECINTO'} value={'RECINTO'}>
                          Recinto
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeTipoEstabelecimento}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'Código Estabelecimento'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'codigoEstabelecimento'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input
                        inputMode='numeric'
                        autoComplete='off'
                        onKeyPress={(event: any) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeCodigoEstabelecimento}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={'Nome Estabelecimento'}>
                  <Input.Group
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Form.Item
                      name={'nomeEstabelecimento'}
                      style={{
                        width: '100%',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      <Input inputMode='text' />
                    </Form.Item>
                    <Button
                      style={{
                        height: '34px',
                      }}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeNomeEstabelecimento}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>

              <Divider />

              <Row justify={'end'} gutter={24}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      form.resetFields();
                      setMunicipioSelecionado(undefined);
                      dispatch(
                        FVERActions.setQuery({ page: 0, numero: undefined })
                      );
                    }}
                  >
                    Limpar
                  </Button>
                </Col>

                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    htmlType={'submit'}
                    type={'primary'}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </>
  );
}
