import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  Radio,
  Divider,
  Typography,
  Space,
  Col,
  Button,
  Row,
  Checkbox,
} from 'antd';
import { AppDispatch, RootState } from '../../../core/store';
import { fetchFormCustomById } from '../../../core/store/FormCustom.slice';

const { Title, Paragraph } = Typography;

type Alternativa = {
  descricao: string;
  id: number;
};

type Pergunta = {
  id?: number;
  descricao: string;
  ordem: number;
  tipoPergunta:
    | 'MULTIPLA_ESCOLHA'
    | 'NUMERICO'
    | 'PARAGRAFO'
    | 'RESPOSTA_CURTA';
  alternativas?: Alternativa[];
};

export default function FormCustomView() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const [formDisabled] = useState<boolean>(false);
  const formDetails = useSelector(
    (state: RootState) => state.formCustom.formCustomData
  );

  useEffect(() => {
    dispatch(fetchFormCustomById(Number(id)));
  }, [dispatch, id]);

  const renderQuestionTypeInput = (question: Pergunta, index: number) => {
    switch (question.tipoPergunta) {
      case 'MULTIPLA_ESCOLHA':
        return (
          <Radio.Group disabled>
            {question.alternativas?.map((alt) => (
              <Space style={{ display: 'block', padding: 8 }} key={alt.id}>
                <Radio value={alt.id}>{alt.descricao}</Radio>
              </Space>
            ))}
          </Radio.Group>
        );
      case 'NUMERICO':
        return <Input type='number' disabled />;
      case 'PARAGRAFO':
        return <Input.TextArea disabled />;
      case 'RESPOSTA_CURTA':
        return <Input disabled />;
      default:
        return null;
    }
  };

  return (
    <>
      <Space style={{ marginBottom: 50 }}>
        <Col>
          <Button
            type='primary'
            onClick={() => {
              navigate('/formCustom/visualizar');
            }}
          >
            Voltar
          </Button>
        </Col>
      </Space>

      <Divider orientation='left'>
        <Typography.Title level={3}>
          Visualizar Formulário Customizado
        </Typography.Title>
      </Divider>
      <Form layout='vertical'>
        <Divider orientation='left'>Dados do Formulário</Divider>
        <br />
        <Title level={4}>{formDetails?.nome}</Title>
        <Paragraph>{formDetails?.descricao}</Paragraph>
        <br />
        <Divider orientation='left'>Vincular Município</Divider>
        <Checkbox checked={formDetails?.municipio} disabled>
          Município
        </Checkbox>
        <br />
        <Divider orientation='left'>Perguntas</Divider>
        <br />
        {formDetails?.perguntas?.map((question: any, index: number) => (
          <Form.Item key={index} label={`${index + 1}: ${question.descricao}`}>
            {renderQuestionTypeInput(question, index)}
          </Form.Item>
        ))}
        <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
          <Col xs={12} lg={6}>
            <Button style={{ width: '100%' }} disabled={true}>
              {formDisabled ? 'Voltar' : 'Cancelar'}
            </Button>
          </Col>
          {!formDisabled && (
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                htmlType={'submit'}
                type={'primary'}
                disabled={true}
              >
                Salvar
              </Button>
            </Col>
          )}
          {formDisabled && (
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                htmlType={'submit'}
                type={'primary'}
              >
                Editar
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
}
