export enum ParecerLaudoInspecao {
  FAVORAVEL_MANUTENCAO_REGISTRO = 'Favorável à Manutenção do Registro',
  FAVORAVEL_MANUTENCAO_REGISTRO_ADVERTIDO = 'Favorável à Manutenção do Registro – Estabelecimento Advertido',
  FAVORAVEL_REGISTRO = 'Favorável ao Registro',
  NAO_FAVORAVEL_MANUTENCAO_REGISTRO_CANCELAMENTO = 'Não Favorável à Manutenção do Registro – Cancelamento do Registro',
  NAO_FAVORAVEL_MANUTENCAO_REGISTRO_SUSPENSO_ALOJAMENTO_AVES = 'Não Favorável à Manutenção do Registro – Suspenso para Alojamento de Aves',
  NAO_FAVORAVEL_REGISTRO = 'Não Favorável ao Registro',
}
export namespace ParecerLaudoInspecao {
  export function keys(): string[] {
    return Object.keys(ParecerLaudoInspecao).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return ParecerLaudoInspecao[key as keyof typeof ParecerLaudoInspecao];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(ParecerLaudoInspecao).indexOf(
      value as unknown as ParecerLaudoInspecao
    );

    const key = Object.keys(ParecerLaudoInspecao)[indexOfS];

    return key;
  }
}
