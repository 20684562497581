import { TermoFiscalizacao } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';

class TFRIDBService extends ServiceIDB {
  static DB_STORE_TFR_LIST = 'tfr_list';

  static DB_STORE_TFR_INPUT = 'tfr_request';

  static DB_STORE_TFR_LIST_INDEX_NUMERO_NAME = 'tfr_list_index_numero';
  static DB_STORE_TFR_LIST_INDEX_NUMERO_KEY = 'payload.numero';

  static DB_STORE_TFR_LIST_INDEX_CODG_IBGE_NAME = 'tfr_list_index_codg_ibge';
  static DB_STORE_TFR_LIST_INDEX_CODG_IBGE_KEY = 'payload.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(TFRIDBService.DB_STORE_TFR_LIST)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByNumero(numero: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_TFR_LIST,
      this.DB_STORE_TFR_LIST_INDEX_NUMERO_NAME,
      numero
    );
  }

  static async getAll(): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(TFRIDBService.DB_STORE_TFR_LIST);
    return list;
  }

  static async getAllFromInputTable(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(TFRIDBService.DB_STORE_TFR_INPUT);
    return list;
  }

  static async getAllFromInputTableOnlyPayload(): Promise<
    TermoFiscalizacao.Request[]
  > {
    let list: TermoFiscalizacao.Request[] = [];

    await this.getAllFromInputTable().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<TermoFiscalizacao.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const tfr = await db.get(TFRIDBService.DB_STORE_TFR_LIST, id);

    return tfr.payload;
  }

  static async getByIdFromInputTable(
    id: number
  ): Promise<TermoFiscalizacao.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const tfr = await db.get(TFRIDBService.DB_STORE_TFR_INPUT, id);

    return tfr.payload;
  }

  static async insert(tfr: TermoFiscalizacao.Request) {
    const db = await openDB(DBConfig.DB_NAME);

    const obj: ServiceIDBPayloadInput = {
      date: new Date(),
      payload: tfr,
      status: 'NOVO',
    };

    return db.add(this.DB_STORE_TFR_INPUT, obj);
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_TFR_LIST);
  }

  static async deleteAll_InputTable() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_TFR_INPUT);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_TFR_INPUT, id);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super
      ._getAllFromIndex(
        this.DB_STORE_TFR_LIST,
        this.DB_STORE_TFR_LIST_INDEX_CODG_IBGE_NAME,
        codg_ibge
      )
      .then(async (fvers: any[]) => {
        return Promise.all(
          fvers.map(async (fver) => {
            return await super._delete(this.DB_STORE_TFR_LIST, fver.id);
          })
        );
      });
  }

  static async update(id: number, obj: ServiceIDBPayloadInput) {
    this.delete(id);

    const db = await openDB(DBConfig.DB_NAME);
    return db.add(this.DB_STORE_TFR_INPUT, obj);
  }

  static async put(id: number, obj: TermoFiscalizacao.Request[]) {
    const db = await openDB(DBConfig.DB_NAME);
    const oldTFR: ServiceIDBPayloadInput = await db.get(
      TFRIDBService.DB_STORE_TFR_INPUT,
      id
    );
    const newTFR: ServiceIDBPayloadInput = {
      ...oldTFR,
      payload: obj,
    };

    db.put(TFRIDBService.DB_STORE_TFR_INPUT, newTFR);
  }

  static async count() {
    return this._count(this.DB_STORE_TFR_INPUT);
  }
}

export default TFRIDBService;
