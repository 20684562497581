import { Card, Col, Form, FormInstance, Radio, Row, Typography } from 'antd';

import { useEffect, useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

type CustomCardProps = {
  title: string;
  name: string;
  specify: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChange: (
    name: string,
    value: string,
    specify: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  specify,
  formValue,
  formDisable,
  updatedValue,
  handleChange,
}) => {
  return (
    <Card title={title} key={formValue}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChange(name, e.target.value, specify, updatedValue)
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            {formValue === 'NAO' && (
              <Form.Item
                label={'Observação'}
                name={specify}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea showCount rows={5} maxLength={2000} />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default function Documentos(props: InformacoesCadastroProps) {
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const handleChange = (name: string, value: string, specify: string) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [specify]: undefined,
      });
    }
  };
  const manutencaoRegistro = props.form.getFieldValue('manutencaoRegistro');

  return (
    <>
      <Typography.Title level={5}>
        Documentos para protocolo na unidade local do INDEA do município da
        granja
      </Typography.Title>
      {manutencaoRegistro === false ? (
        <>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiRequerimentoSolicitacao_8_1'
                title='8.1 - Requerimento de solicitação ao INDEA-MT, na forma do Anexo II (disponível no site do INDEA).'
                name='possuiRequerimentoSolicitacao_8_1'
                specify='requerimentoSolicitacaoDescricao_8_1'
                formValue={props.form.getFieldValue(
                  'possuiRequerimentoSolicitacao_8_1'
                )}
                updatedValue={
                  updatedValues['possuiRequerimentoSolicitacao_8_1']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiCadastroProprietario_8_2'
                title='8.2 - Cadastro de proprietário e cadastro de propriedade no INDEA/MT.'
                name='possuiCadastroProprietario_8_2'
                specify='cadastroProprietarioDescricao_8_2'
                formValue={props.form.getFieldValue(
                  'possuiCadastroProprietario_8_2'
                )}
                updatedValue={updatedValues['possuiCadastroProprietario_8_2']}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiResponsabilidadeTecnica_8_3'
                title='8.3 - Anotação de responsabilidade técnica do médico veterinário que realiza o controle higiênico-sanitário do estabelecimento avícola.'
                name='possuiResponsabilidadeTecnica_8_3'
                specify='responsabilidadeTecnicaDescricao_8_3'
                formValue={props.form.getFieldValue(
                  'possuiResponsabilidadeTecnica_8_3'
                )}
                updatedValue={
                  updatedValues['possuiResponsabilidadeTecnica_8_3']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiPlantaLocalizacaoPropriedade_8_4'
                title='8.4 - Planta de localização da propriedade ou outro instrumento, a critério do INDEA-MT, capaz de demonstrar as instalações, estradas, cursos d`água, propiedades limítrofes e suas respectivas atividades.'
                name='possuiPlantaLocalizacaoPropriedade_8_4'
                specify='plantaLocalizacaoPropriedadeDescricao_8_4'
                formValue={props.form.getFieldValue(
                  'possuiPlantaLocalizacaoPropriedade_8_4'
                )}
                updatedValue={
                  updatedValues['possuiPlantaLocalizacaoPropriedade_8_4']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiPlantaBaixaEstabelecimento_8_5'
                title='8.5 - Planta baixa das instalações do estabelecimento ou outro instrumento, a critério do INDEAS-MT, capaz de demontrar toda a infraestrutura instalada.'
                name='possuiPlantaBaixaEstabelecimento_8_5'
                specify='plantaBaixaEstabelecimentoDescricao_8_5'
                formValue={props.form.getFieldValue(
                  'possuiPlantaBaixaEstabelecimento_8_5'
                )}
                updatedValue={
                  updatedValues['possuiPlantaBaixaEstabelecimento_8_5']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiMemorialMedidasHigienicoSanitarias_8_6'
                title='8.6 - Memorial descritivo das medidas higiênico-sanitárias e de biossegurança que serão adotadas pelo estabelecimento avícola e dos processos tecnológicos, contendo descrição.'
                name='possuiMemorialMedidasHigienicoSanitarias_8_6'
                specify='memorialMedidasHigienicoSanitariasDescricao_8_6'
                formValue={props.form.getFieldValue(
                  'possuiMemorialMedidasHigienicoSanitarias_8_6'
                )}
                updatedValue={
                  updatedValues['possuiMemorialMedidasHigienicoSanitarias_8_6']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiDocumentoComprobatorioQualidadeAgua_8_7'
                title='8.7 - Documento comprobatório da qualidade microbiológica da água de consumo das aves.'
                name='possuiDocumentoComprobatorioQualidadeAgua_8_7'
                specify='documentoComprobatorioQualidadeAguaDescricao_8_7'
                formValue={props.form.getFieldValue(
                  'possuiDocumentoComprobatorioQualidadeAgua_8_7'
                )}
                updatedValue={
                  updatedValues['possuiDocumentoComprobatorioQualidadeAgua_8_7']
                }
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiResponsabilidadeTecnica_8_3'
                title='8.3 - Anotação de responsabilidade técnica do médico veterinário que realiza o controle higiênico-sanitário do estabelecimento avícola.'
                name='possuiResponsabilidadeTecnica_8_3'
                specify='responsabilidadeTecnicaDescricao_8_3'
                formValue={props.form.getFieldValue(
                  'possuiResponsabilidadeTecnica_8_3'
                )}
                updatedValue={
                  updatedValues['possuiResponsabilidadeTecnica_8_3']
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <CustomCard
                formDisable={props.formDisabled}
                handleChange={handleChange}
                key='possuiDocumentoComprobatorioQualidadeAgua_8_7'
                title='8.7 - Documento comprobatório da qualidade microbiológica da água de consumo das aves.'
                name='possuiDocumentoComprobatorioQualidadeAgua_8_7'
                specify='documentoComprobatorioQualidadeAguaDescricao_8_7'
                formValue={props.form.getFieldValue(
                  'possuiDocumentoComprobatorioQualidadeAgua_8_7'
                )}
                updatedValue={
                  updatedValues['possuiDocumentoComprobatorioQualidadeAgua_8_7']
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
