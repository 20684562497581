import { EmpresaIntegradora } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';

class EmpresaIntegradoraIDBService {
  static DB_STORE_EMPRESA_INTEGRADORA = 'empresaIntegradora';
  static DB_STORE_EMPRESA_INTEGRADORA_INDEX_NAME =
    'empresaIntegradora_index_id';
  static DB_STORE_EMPRESA_INTEGRADORA_INDEX_KEY = 'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      EmpresaIntegradoraIDBService.DB_STORE_EMPRESA_INTEGRADORA
    );
    return list;
  }

  static async getAllPayload(): Promise<EmpresaIntegradora.Summary[]> {
    let list: EmpresaIntegradora.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllEmpresaIntegradora(lista: EmpresaIntegradora.Summary[]) {
    try {
      const db = await openDB(DBConfig.DB_NAME);
      lista.forEach((tipo) => {
        const date = new Date();
        const obj = {
          date: date,
          payload: tipo,
        };

        db.add(this.DB_STORE_EMPRESA_INTEGRADORA, obj).catch((e) => {
          return;
        });
      });
    } catch (e) {
      return;
    }
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_EMPRESA_INTEGRADORA);
  }
}

export default EmpresaIntegradoraIDBService;
