export enum TipoAlimentacaoRuminantes {
  SOMENTE_PASTO = 'Somente pasto',
  PASTO_CONCENTRADO = 'Pasto + concentrado',
  PASTO_E_SAL = 'Pasto + Sal',
  PASTO_VOLUMOSO_CONCENTRADO = 'Pasto + volumoso e concentrado',
  VOLUMOSO_CONCENTRADO_NO_COCHO = 'Volumoso + concentrado no cocho',
  RACAO_COMERCIAL = 'Ração comercial',
  FABRICACAO_PROPRIA = 'Fabricação Própria',
}
export namespace TipoAlimentacaoRuminantes {
  export function keys(): string[] {
    return Object.keys(TipoAlimentacaoRuminantes).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoAlimentacaoRuminantes[
      key as keyof typeof TipoAlimentacaoRuminantes
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoAlimentacaoRuminantes).indexOf(
      value as unknown as TipoAlimentacaoRuminantes
    );

    const key = Object.keys(TipoAlimentacaoRuminantes)[indexOfS];

    return key;
  }
}
