import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormLIA from './FormLIA';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as liaActions from '../../../core/store/LIA.slice';
import LIAIDBService from '../../../sdk/services/indexeddb/LIAIDB.service';
import { LIA } from '../../../sdk/@types/LIA';

function EditLIA() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const liaOnline = useSelector(liaActions.selectLIA);
  const loadingState = useSelector((state: RootState) => state.lia.loading);
  const [liaOffline, setLIAOffline] = useState<LIA.Request | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchLIAOffline = useCallback(async () => {
    const result = await LIAIDBService.getByIdFromInputTable(Number(id));
    setLIAOffline(result);
    setLoading(false);
  }, [id]);
  const [, setTriggerRender] = useState(0);

  const prepareData = useCallback(
    (lia) => ({
      ...lia,
      dataParecer: moment(lia?.dataParecer),
    }),
    []
  );

  useEffect(() => {
    if (loadingState === 'fulfilled' || loadingState === 'rejected') {
      setLoading(false);
      setTriggerRender((prev) => prev + 1);
    }
  }, [loadingState]);

  useEffect(() => {
    if (!isNaN(Number(id))) {
      setLoading(true);

      if (!local) {
        dispatch(liaActions.fetchLIAById(Number(id)));
      } else {
        fetchLIAOffline();
      }
    }
  }, [fetchLIAOffline, id, local, dispatch]);

  const dataToDisplay = local ? liaOffline : liaOnline;

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && dataToDisplay && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>
              Editando Laudo de Inspeção{' '}
              {liaOnline ? ` nº ${liaOnline?.id}` : ' offline'}
            </Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loadingState === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormLIA lia={prepareData(dataToDisplay)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditLIA;
