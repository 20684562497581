import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import TFRExternalList from '../features/FormTFR/TFRExternalList';

export default function TFRExternalListWrapper() {
  usePageTitle('Termos de Fiscalização');

  return (
    <>
      <ErrorBoundary component='os termos de fiscalização'>
        <TFRExternalList />
      </ErrorBoundary>
    </>
  );
}
