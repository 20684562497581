import { FormCustom } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';

class FormCustomIDBService extends ServiceIDB {
  static DB_STORE_FORM_CUSTOM = 'form_custom';

  static DB_STORE_FORM_CUSTOM_INDEX_NAME = 'formCustom_index_id';
  static DB_STORE_FORM_CUSTOM_INDEX_KEY = 'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(FormCustomIDBService.DB_STORE_FORM_CUSTOM)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll(): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FormCustomIDBService.DB_STORE_FORM_CUSTOM);

    return list;
  }

  static async getAllPayload(): Promise<FormCustom.Summary[]> {
    let list: FormCustom.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getAllFromInputTable(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FormCustomIDBService.DB_STORE_FORM_CUSTOM);
    return list;
  }

  static async getAllFromInputTableOnlyPayload(): Promise<
    FormCustom.Request[]
  > {
    let list: FormCustom.Request[] = [];

    await this.getAllFromInputTable().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<FormCustom.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const formCustom = await db.get(
      FormCustomIDBService.DB_STORE_FORM_CUSTOM,
      id
    );

    return formCustom.payload;
  }

  static async getByIdFromInputTable(id: number): Promise<FormCustom.Request> {
    const db = await openDB(DBConfig.DB_NAME);
    const formCustom = await db.get(
      FormCustomIDBService.DB_STORE_FORM_CUSTOM,
      id
    );

    return formCustom.payload;
  }

  static async add(formCustom: FormCustom.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: formCustom,
      };

      db.add(this.DB_STORE_FORM_CUSTOM, obj);
    });
  }
  
  static async addAllFormCustom(lista: FormCustom.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_FORM_CUSTOM, obj).catch((e) => {
        return;
      });
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FORM_CUSTOM);
  }

  static async deleteAll_InputTable() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FORM_CUSTOM);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_FORM_CUSTOM, id);
  }

  static async update(id: number, obj: ServiceIDBPayloadInput) {
    this.delete(id);

    const db = await openDB(DBConfig.DB_NAME);
    return db.add(this.DB_STORE_FORM_CUSTOM, obj);
  }

  static async put(id: number, obj: FormCustom.Request[]) {
    const db = await openDB(DBConfig.DB_NAME);
    const old: ServiceIDBPayloadInput = await db.get(
      FormCustomIDBService.DB_STORE_FORM_CUSTOM,
      id
    );
    const newTFR: ServiceIDBPayloadInput = {
      ...old,
      payload: obj,
    };

    db.put(FormCustomIDBService.DB_STORE_FORM_CUSTOM, newTFR);
  }

  static async count() {
    return this._count(this.DB_STORE_FORM_CUSTOM);
  }
}

export default FormCustomIDBService;
