import { Revenda } from '../../@types/Revenda';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class RevendaService extends Service {
  static getByCNPJ(cnpj: string): Promise<Revenda.Summary[]> {
    const queryString = generateQueryString({ cnpj });
    return this.Http.get<Revenda.Summary[]>(
      '/revendas'.concat(queryString)
    ).then(this.getData);
  }

  static getByNome(nome: string): Promise<Revenda.Summary[]> {
    const queryString = generateQueryString({ nome });
    return this.Http.get<Revenda.Summary[]>(
      '/revendas'.concat(queryString)
    ).then(this.getData);
  }

  static getByMunicipio(codigoIBGE: string): Promise<Revenda.Summary[]> {
    const queryString = generateQueryString({ codigoIBGE });
    return this.Http.get<Revenda.Summary[]>('/revendas'.concat(queryString), {
      timeout: 600000,
    }).then(this.getData);
  }

  static countByMunicipio(codgIbge: string) {
    return this.Http.get<number>(`revendas/municipio/${codgIbge}/count`).then(
      this.getData
    );
  }
}

export default RevendaService;
