import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type DistanciasExternasProps = {
  data: LIA.Response;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  distance?: string;
};
export default function PrintDistanciasExternas({
  data,
}: DistanciasExternasProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.distance && (
                  <div>
                    <strong>Qual a distância?:</strong> <br />
                    {row.distance}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      {panelDescription('Distâncias Externas', [
        {
          label: '1.1 - Está distante no mínimo 3,0Km de matrizeiros?',
          condition: data?.distanciaMinima3kmMatrizeiros_1_1,
          distance: data?.distanciaMatrizeiros_1_1,
        },
        {
          label:
            '1.2 - Está distante no mínimo 3,0Km de estabelecimento de aves ornamentais?',
          condition: data?.distanciaMinima3kmAvesOrnamentais_1_2,
          distance: data?.distanciaAvesOrnamentais_1_2,
        },
        {
          label:
            '1.3 - Está distante no mínimo 3,0Km estabelecimento de ensino ou pesquisa?',
          condition: data?.distanciaMinima3kmEstabelecimentoEnsino_1_3,
          distance: data?.distanciaEstabelecimentoEnsino_1_3,
        },
        {
          label: '1.4 - Está distante no mínimo 3,0Km de incubatórios?',
          condition: data?.distanciaMinima3kmIncubatorios_1_4,
          distance: data?.distanciaIncubatorios_1_4,
        },
        {
          label:
            '1.5 - Está distante no mínimo 10 Km de Sistemas de criação de aves comerciais ao ar livre?',
          condition: data?.distanciaMinima10kmCriacaoDeAves_1_5,
          distance: data?.distanciaCriacaoDeAves_1_5,
        },
        {
          label:
            '1.6 - Está distante no mínimo 10 Km de criações comerciais de até 1.000(mil) aves?',
          condition: data?.distanciaMinima10kmCriacoesAteMilAves_1_6,
          distance: data?.distanciaCriacoesAteMilAves_1_6,
        },
        {
          label:
            '1.7 - A distância entre aves de corte e aves poedeiras de ovos comerciais é de no mínimo 3,0 km?',
          condition: data?.distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7,
          distance: data?.distanciaEntreAvesCorteAvesPoedeiras_1_7,
        },
        {
          label:
            '1.8 - A distância entre aves de corte de diferentes integradoras e independentes é de no mínimo 1,0km?',
          condition:
            data?.distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8,
          distance: data?.distanciaEntreAvesCortesDiferentesIntegradoras_1_8,
        },
        {
          label:
            '1.9 - A distância entre granjas de aves de corte de mesma integradora é de no mínimo 200m?',
          condition:
            data?.distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9,
          distance: data?.distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9,
        },
        {
          label:
            '1.10 - A distância entre granjas de aves poedeiras de ovos comerciais e de no mínimo 1,0 km?',
          condition: data?.distanciaMinima1kmGranjaAvesPoedeiras_1_10,
          distance: data?.distanciaGranjaAvesPoedeiras_1_10,
        },
        {
          label:
            '1.11 - A distância entre aviário(s) e fábrica de ração de terceiros é de no mínimo 3,0 km?',
          condition: data?.distanciaMinima3kmAviarioFabricaRacao_1_11,
          distance: data?.distanciaAviarioFabricaRacao_1_11,
        },
        {
          label:
            '1.12 - Está distante no mínimo 3,0 Km de indústria de processamento de produtos e subprodutos de origem animal?',
          condition:
            data?.distanciaMinima3kmIndustriaProcessamentoProdutos_1_12,
          distance: data?.distanciaIndustriaProcessamentoProdutos_1_12,
        },
        {
          label:
            '1.13 - Está distante no mínimo 3,0 Km do aterro sanitário e/ou lixão?',
          condition: data?.distanciaMinima3kmAterroSanitarioLixao_1_13,
          distance: data?.distanciaAterroSanitarioLixao_1_13,
        },
        {
          label:
            '1.14 - Está distante no mínimo 3,0 Km de lagoa de resíduo de outra Natureza?',
          condition: data?.distanciaMinima3kmLagoaResiduo_1_14,
          distance: data?.distanciaLagoaResiduo_1_14,
        },
        {
          label:
            '1.15 - Está distante 1,0Km de biodigestores (suínos/aves/ outros)?',
          condition: data?.distanciaMinima1kmBiodigestores_1_15,
          distance: data?.distanciaBiodigestores_1_15,
        },
        {
          label:
            '1.16 - Está distante no mínimo 4,0km de criação de aves de diferentes espécies, exóticas, silvestres ou domésticas, com objetivo de produção de matrizes ou de aves vivas para atendimento ao mercado de aves de estimação?',
          condition: data?.distanciaMinima4kmCriacaoDeAves_1_16,
          distance: data?.distanciaCriacaoDeAves_1_16,
        },
        {
          label: 'Observações',
          condition: data?.observacoesDistanciasExternas_1_17,
        },
      ])}
    </>
  );
}
