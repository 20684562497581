export enum FaseExploracaoBovinosEBubalinos {
  CRIA = 'Cria',
  ENGORDA = 'Engorda',
  REPRODUCAO = 'Reprodução',
  RECRIA = 'Recria',
  NDA = 'Outro',
}
export namespace FaseExploracaoBovinosEBubalinos {
  export function keys(): string[] {
    return Object.keys(FaseExploracaoBovinosEBubalinos).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return FaseExploracaoBovinosEBubalinos[
      key as keyof typeof FaseExploracaoBovinosEBubalinos
    ];
  }
  export function keyOf(value: string) {
    const indexOfS = Object.values(FaseExploracaoBovinosEBubalinos).indexOf(
      value as unknown as FaseExploracaoBovinosEBubalinos
    );

    const key = Object.keys(FaseExploracaoBovinosEBubalinos)[indexOfS];

    return key;
  }
}
