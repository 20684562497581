export enum Especie {
  ANIMAL_AQUATICO = 'Animal Aquático',
  ANIMAL_SILVESTRE = 'Animal Silvestre',
  ABELHA = 'Abelha',
  ASININA = 'Asinina',
  AVESTRUZ = 'Avestruz',
  AVE_ORNAMENTAL = 'Ave ornamental',
  AVICOLA = 'Avícola',
  AQUICOLA = 'Aquícola',
  BAGRE_AFRICANO = 'Bagre africano',
  BOVINA = 'Bovina',
  BUBALINA = 'Bubalina',
  CAMELO = 'Camelo',
  CANIDEO = 'Canídeo',
  CAPRINA = 'Caprina',
  CARPA_CABECA_GRANDE = 'Carpa cabeça grande',
  CODORNA = 'Codorna',
  CURIMATA_CURIMBATU_CURIMATA = 'Curimatá/curimbatú/curimatã',
  EMA = 'Ema',
  EQUINA = 'Equina',
  FAISAO = 'Faisão',
  FELIDEO = 'Felideo',
  GALINACEO = 'Galináceo',
  GALINHA_ANGOLA = "Galinha d'Angola",
  GANSO = 'Ganso',
  JUNDIA = 'Jundiá',
  LAGOMORFO = 'Lagomorfo',
  LAMBARI = 'Lambari',
  MARRECO = 'Marreco',
  MATRINCHA = 'Matrinchã',
  MORCEGO_HEMATOFAGO = 'Morcego hematófago',
  MORCEGO_NAO_HEMATOFAGO = 'Morcego não-hematófago',
  MUAR = 'Muar',
  OVINA = 'Ovina',
  OUTROS_PALMIPEDE = 'Outro Palmípede',
  OUTRAS_AVES = 'Outras aves',
  PACU_CARANHA = 'Pacu caranha',
  PASSERIFORME = 'Passeriforme',
  PATINGA = 'Patinga',
  PATO = 'Pato',
  PAVAO = 'Pavão',
  PERU = 'Peru',
  PERDIZ = 'Perdiz',
  PIAU_VERDADEIRO = 'Piau verdadeiro',
  PIAUCU = 'Piauçu',
  PINTADO_SURUBIM = 'Pintado/surubim',
  PIRAPITINGA = 'Pirapitinga',
  PIRARUCU = 'Pirarucu',
  TAMBACU = 'Tambacu',
  TAMBAQUI = 'Tambaqui',
  TAMBATINGA = 'Tambatinga',
  TILAPIA_DO_NILO = 'Tilapia do Nilo',
  TRUTA = 'Truta',
  PEIXES_ORNAMENTAIS = 'Peixes ornamentais',
  PSCITACIFORMES = 'Psitaciformes',
  RATITAS = 'Ratitas',
  SUINA = 'Suína',
  ZEBRA = 'Zebra',
}
export namespace Especie {
  export function keys(): string[] {
    return Object.keys(Especie).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return Especie[key as keyof typeof Especie];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(Especie).indexOf(
      value as unknown as Especie
    );

    const key = Object.keys(Especie)[indexOfS];

    return key;
  }

  export function especiesVigilanciaVeterinaria(): string[] {
    return [
      keyOf(Especie.BOVINA),
      keyOf(Especie.BUBALINA),
      keyOf(Especie.SUINA),
      keyOf(Especie.AQUICOLA),
      keyOf(Especie.ASININA),
      keyOf(Especie.ABELHA),
      keyOf(Especie.CAPRINA),
      keyOf(Especie.OVINA),
      keyOf(Especie.AVICOLA),
      keyOf(Especie.EQUINA),
      keyOf(Especie.MUAR),
    ];
  }

  export function aves(): string[] {
    return [
      keyOf(Especie.AVE_ORNAMENTAL),
      keyOf(Especie.CODORNA),
      keyOf(Especie.FAISAO),
      keyOf(Especie.GALINACEO),
      keyOf(Especie.GALINHA_ANGOLA),
      keyOf(Especie.GANSO),
      keyOf(Especie.MARRECO),
      keyOf(Especie.PATO),
      keyOf(Especie.PAVAO),
      keyOf(Especie.PERDIZ),
      keyOf(Especie.PERU),
      keyOf(Especie.RATITAS),
    ];
  }

  export function peixes(): string[] {
    return [
      keyOf(Especie.BAGRE_AFRICANO),
      keyOf(Especie.CARPA_CABECA_GRANDE),
      keyOf(Especie.CURIMATA_CURIMBATU_CURIMATA),
      keyOf(Especie.JUNDIA),
      keyOf(Especie.LAMBARI),
      keyOf(Especie.MATRINCHA),
      keyOf(Especie.PACU_CARANHA),
      keyOf(Especie.PATINGA),
      keyOf(Especie.PEIXES_ORNAMENTAIS),
      keyOf(Especie.PIAU_VERDADEIRO),
      keyOf(Especie.PIAUCU),
      keyOf(Especie.PINTADO_SURUBIM),
      keyOf(Especie.PIRAPITINGA),
      keyOf(Especie.PIRARUCU),
      keyOf(Especie.TAMBACU),
      keyOf(Especie.TAMBAQUI),
      keyOf(Especie.TAMBATINGA),
      keyOf(Especie.TILAPIA_DO_NILO),
      keyOf(Especie.TRUTA),
    ];
  }
}
