import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BadgeStateType {
  notificacoes: number;
  quantidadeAcoesNaoSincronizadas: number;
}

const initialState: BadgeStateType = {
  notificacoes: 0,
  quantidadeAcoesNaoSincronizadas: 0,
};

const BadgeStateSlice = createSlice({
  initialState,
  name: 'badgeState',
  reducers: {
    setNotificacoes(state, action: PayloadAction<number>) {
      state.notificacoes = action.payload;
    },
    setQuantidadeAcoesNaoSincronizadas(state, action: PayloadAction<number>) {
      state.quantidadeAcoesNaoSincronizadas = action.payload;
    },
  },
});

export const { setNotificacoes, setQuantidadeAcoesNaoSincronizadas } =
  BadgeStateSlice.actions;

const BadgeStateReducer = BadgeStateSlice.reducer;
export default BadgeStateReducer;
