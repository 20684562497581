import { Card, Descriptions, Row, Typography } from 'antd';
import { FormularioRespostas } from '../../../sdk/@types';
import HeaderGovernoMt from './HeaderGovernoMt.report';
import moment from 'moment';

type PrintFormCustomProps = {
  formCustom?: FormularioRespostas.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintFormCustom(props: PrintFormCustomProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div
        className='newPage'
        style={{ display: 'block', marginBottom: '16px' }}
      >
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <Row gutter={24} justify={'center'}>
        <Typography.Title level={4}>{props.formCustom?.nome}</Typography.Title>
      </Row>
      <br />
      {props.formCustom?.fver &&
        panelDescription('FVER', [
          {
            label: 'Número',
            value: props.formCustom.fver?.numero,
          },
          {
            label: 'Data',
            value: moment(props?.formCustom.fver?.dataDaVisita).format(
              'DD/MM/YYYY HH:mm'
            ),
          },
          {
            label: 'Código Estabelecimento',
            value: props?.formCustom.fver?.codigoEstabelecimento,
          },
          {
            label: 'Código Verificador',
            value: props?.formCustom.fver.codigoVerificador,
          },
          {
            label: 'Estabelecimento',
            value: props?.formCustom.fver?.nomeEstabelecimento,
          },
          {
            label: 'Tipo Estabelecimento',
            value: props?.formCustom.fver?.tipoEstabelecimento,
          },
          {
            label: 'Status',
            value: props?.formCustom.fver?.status,
          },
          {
            label: 'Resumo',
            value: props?.formCustom.fver?.resumo,
          },
        ])}
      <br />
      {props.formCustom?.municipio &&
        panelDescription('Município', [
          {
            label: 'Nome',
            value: props.formCustom.municipio?.nome,
          },
          {
            label: 'UF',
            value: props?.formCustom.municipio?.uf.nome,
          },
        ])}
      <br />
      {props.formCustom && (
        <>
          {props.formCustom.respostas &&
            props.formCustom.respostas.map((chave, index) =>
              panelDescription(`${chave.pergunta?.descricao}`, [
                {
                  value: chave.descricaoResposta || 'Sem resposta',
                  span: 2,
                },
              ])
            )}
        </>
      )}
    </>
  );
}
