import { Produtor } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
import removeAcentos from '../../../core/functions/removeAcentos';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';

class ProdutorIDBService extends ServiceIDB {
  static DB_STORE_PRODUTOR = 'produtor';
  static DB_STORE_PRODUTOR_INDEX_ID_KEY = 'id';
  static DB_STORE_PRODUTOR_INDEX_ID_NAME = 'produtor_index_id';

  static DB_STORE_PRODUTOR_INDEX_CPF_KEY = 'produtor.cpf';
  static DB_STORE_PRODUTOR_INDEX_CPF_NAME = 'produtor_index_cpf';

  static DB_STORE_PRODUTOR_INDEX_NOME_KEY = 'produtor.nome';
  static DB_STORE_PRODUTOR_INDEX_NOME_NAME = 'produtor_index_nome';

  static DB_STORE_PRODUTOR_INDEX_MUNICIPIO_KEY =
    'propriedade.municipio.codgIBGE';
  static DB_STORE_PRODUTOR_INDEX_MUNICIPIO_NAME = 'produtor_index_municipio';

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(ProdutorIDBService.DB_STORE_PRODUTOR);
    return list;
  }

  static async getAllPayload(): Promise<Produtor.Summary[]> {
    let list: Produtor.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_PRODUTOR,
      this.DB_STORE_PRODUTOR_INDEX_ID_NAME,
      id
    );
  }

  static async getByNome(nome: string) {
    return await super
      ._listAllFromIndex(
        this.DB_STORE_PRODUTOR,
        this.DB_STORE_PRODUTOR_INDEX_ID_NAME
      )
      .then(async (list: any[]) => {
        return list
          .map((element) => element.produtor)
          .filter((produtor) =>
            removeAcentos(produtor.nome.toLowerCase()).includes(
              removeAcentos(nome.toLowerCase())
            )
          );
      });
  }

  static async getByCpf(cpf: string) {
    return await super._getFromIndex(
      this.DB_STORE_PRODUTOR,
      this.DB_STORE_PRODUTOR_INDEX_CPF_NAME,
      removeNonNumericCharactersFromString(cpf)
    );
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_PRODUTOR);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_PRODUTOR,
      this.DB_STORE_PRODUTOR_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default ProdutorIDBService;
