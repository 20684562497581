export enum StatusDocumento {
  ALTERADO = 'Alterado',
  CANCELADO = 'Cancelado',
  VALIDO = 'Válido',
  NOVO = 'Novo',
}
export namespace StatusDocumento {
  export function keys(): string[] {
    return Object.keys(StatusDocumento).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return StatusDocumento[key as keyof typeof StatusDocumento];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(StatusDocumento).indexOf(
      value as unknown as StatusDocumento
    );

    const key = Object.keys(StatusDocumento)[indexOfS];

    return key;
  }
}
