import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TipoChavePrincipalTFR } from '../../sdk/@types';
import TipoChavePrincipalTFRIDBService from '../../sdk/services/indexeddb/TipoChavePrincipalTFRIDB.service';
import TipoChavePrincipalTFRService from '../../sdk/services/SIZ-API/TipoChavePrincipalTFR.service';

interface TipoChaveState {
  tipoChavePrincipalTFR: TipoChavePrincipalTFR.Summary[] | undefined;
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  errorMessage?: string;
}

const initialState: TipoChaveState = {
  tipoChavePrincipalTFR: undefined,
  loading: 'idle',
  errorMessage: undefined,
};

export const fetchActive = createAsyncThunk(
  'tipoChave/fetchActive',
  async (_, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        const result = await TipoChavePrincipalTFRService.getAllActive();
        return result;
      } else {
        const lista = await TipoChavePrincipalTFRIDBService.getAllPayload();
        if (!lista || lista.length === 0) {
          throw Error('Não foram encontrados Tipo Chave Principal offline');
        }
        return lista;
      }
    } catch (error) {
      console.error('Error in fetchActive:', error);
      return rejectWithValue(error);
    }
  }
);

const tipoChaveSlice = createSlice({
  initialState,
  name: 'termoFiscalizacao',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActive.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchActive.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.tipoChavePrincipalTFR = action.payload as TipoChavePrincipalTFR.Summary[];
      })
      .addCase(fetchActive.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      });
  },
});

const tipoChaveReducer = tipoChaveSlice.reducer;
export default tipoChaveReducer;
