import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import usePageTitle from '../../core/hooks/usePageTitle';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../core/hooks/useNavigatorStatus';
import generateFVERReport from '../../core/functions/generateFVERReport';
import FVERGeralReportService from '../../sdk/services/SIZ-API/FVERGeralReport.service';
import moment from 'moment';
import useMunicipio from '../../core/hooks/useMunicipio';
import { Municipio } from '../../sdk/@types';
import { DeleteOutlined } from '@ant-design/icons';
import { CustomModal } from '../components/CustomModal';

export default function FVERReportGeralView() {
  usePageTitle('FVER - Relatório');

  const [form] = Form.useForm();
  const { xs } = useBreakpoint();
  const { online } = useNavigatorStatus();
  const [fetching, setFetching] = useState(false);

  const [municipioSelecionado, setMunicipioSelecionado] =
    useState<Municipio.Detailed>();
  const { listaMunicipio, fetchMunicipiosByUf } = useMunicipio();

  const [modalFinalizacao, setModalFinalizacao] = useState<{
    show: boolean;
    title: string;
    info: string;
  }>({ show: false, title: '', info: '' });

  useEffect(() => {
    if (online) fetchMunicipiosByUf('mt');
  }, [fetchMunicipiosByUf, online]);

  const handleSelectMunicipioChange = (value: any) => {
    setMunicipioSelecionado(JSON.parse(value));
  };

  const handleFormSubmit = useCallback(
    async (values) => {
      const formValues = form.getFieldsValue();

      const diff = formValues.dataFim.diff(formValues.dataIni, 'days');

      if (diff > 366) {
        Modal.warn({
          title:
            'Não é possível gerar o relatório para períodos maiores do que 1 ano',
          content: `Dias solicitados: ${diff}`,
        });
        return;
      }

      setFetching(true);
      await FVERGeralReportService.get(
        formValues.dataIni.format('DD/MM/YYYY'),
        formValues.dataFim.format('DD/MM/YYYY'),
        municipioSelecionado?.codgIBGE
      )
        .then(async (listFVERs) => {
          await generateFVERReport(listFVERs).then(() => {
            setFetching(false);
            setModalFinalizacao({
              show: true,
              title: 'Relatório gerado com sucesso',
              info: 'O relatório de FVERs foi baixado para o seu dispositivo automaticamente',
            });
          });
        })
        .catch((e) => {
          setFetching(false);
          setModalFinalizacao({
            show: true,
            title: 'Ocorreu um erro ao gerar o relatório',
            info: e.message,
          });
        });
    },
    [form, municipioSelecionado]
  );

  const removeMunicipio = useCallback(() => {
    form.setFieldsValue({
      municipio: null,
    });
  }, [form]);

  return (
    <>
      <Spin
        size='large'
        spinning={fetching}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <Row style={{ width: '100%' }}>
          <Collapse
            defaultActiveKey={1}
            style={{ width: '100%', backgroundColor: '#e0e4e8' }}
          >
            <Collapse.Panel
              showArrow={false}
              collapsible='header'
              header={
                <span style={{ fontWeight: 'bold' }}>Relatório de FVER</span>
              }
              key={1}
            >
              <Typography.Paragraph>
                Informe um período inferior a 1 ano.
              </Typography.Paragraph>
              <Form
                layout={'horizontal'}
                form={form}
                size={!xs ? 'small' : 'large'}
                onFinish={handleFormSubmit}
                autoComplete={'off'}
              >
                <Row gutter={24} style={{ width: '100%' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={'Data Início'}
                      name={'dataIni'}
                      initialValue={moment().set(
                        'month',
                        moment().get('month') - 1
                      )}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        inputReadOnly
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={'Data Fim'}
                      name={'dataFim'}
                      initialValue={moment()}
                      rules={[
                        { required: true, message: 'O campo é obrigatório' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        inputReadOnly
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label={'Município'} style={{ width: '100%' }}>
                      <Input.Group
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Form.Item
                          name={'municipio'}
                          style={{
                            width: '100%',
                            marginBottom: '0',
                            marginTop: '0',
                          }}
                        >
                          <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder='Selecione um município'
                            optionFilterProp='children'
                            onChange={handleSelectMunicipioChange}
                            filterOption={(input, option) => {
                              if (option && option.children)
                                return (
                                  option.children
                                    .toString()
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .indexOf(input.toLowerCase()) >= 0
                                );

                              return false;
                            }}
                          >
                            {listaMunicipio?.map((municipio) => (
                              <Select.Option
                                key={municipio.codgIBGE}
                                value={JSON.stringify(municipio)}
                              >
                                {municipio.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Button
                          style={{
                            height: '34px',
                          }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={removeMunicipio}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify={'end'} gutter={24}>
                  <Col xs={12} lg={6}>
                    <Button
                      style={{ width: '100%' }}
                      htmlType={'submit'}
                      type={'primary'}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Row>
      </Spin>

      <CustomModal
        open={modalFinalizacao.show}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ maxWidth: '400px' }}
        title={modalFinalizacao.title}
        footer={
          <Row gutter={12}>
            <Col span={24}>
              <Button
                type='default'
                style={{ width: '100%' }}
                onClick={() => {
                  setModalFinalizacao({
                    show: false,
                    title: '',
                    info: '',
                  });
                }}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        }
      >
        <Typography.Paragraph style={{ textAlign: 'justify' }}>
          {modalFinalizacao.info}
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
