import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';
import scrollFieldToTop from '../../core/functions/scrollFieldToTop';
import useServidor from '../../core/hooks/useServidor';
import { Servidor } from '../../sdk/@types';
import { CustomModal } from './CustomModal';

interface ServidoresProps {
  form: FormInstance;
  formDisabled: boolean;
  listaServidores: Servidor.Summary[];
  setListaServidores: (listaServidores: Servidor.Summary[]) => void;
}

export default function ServidoresPanel(props: ServidoresProps) {
  const { xs } = useBreakpoint();
  const [showModalServidores, setShowModalServidores] =
    useState<boolean>(false);

  const [form] = Form.useForm<Servidor.Summary>();

  const {
    fetchExistingServidorByCpf,
    fetchExistingServidorByMatricula,
    fetchingServidor,
  } = useServidor();
  const [servidor, setServidor] = useState<Servidor.Summary | null>();

  const [servidorSelecionado, setServidorSelecionado] =
    useState<boolean>(false);

  useEffect(() => {}, [servidor]);

  const checkServidor = useCallback(
    (servidor: Servidor.Summary) => {
      if (!fetchingServidor) {
        if (!servidor) {
          notification.info({
            message: `Não foi possível encontrar nenhum servidor`,
          });
        } else {
          form.setFieldsValue({ ...servidor });

          console.log('form', form.getFieldsValue());

          setServidorSelecionado(true);

          console.log('servidor encontrado', servidor);

          notification.success({
            message: `Servidor ${servidor.pessoa.nome} encontrado`,
          });
        }
      }
    },
    [fetchingServidor, form]
  );

  const cleanModalServidor = useCallback(() => {
    console.log('1');

    form.resetFields();
    setServidorSelecionado(false);
    setServidor(undefined);
  }, [form]);

  const handleAddServidor = useCallback(() => {
    let servidorModal = form.getFieldsValue();

    const arrayDeChavesIguaisAChaveQueEstaSendoInserida =
      props.listaServidores.filter((servidor) => {
        return servidor.matricula === servidorModal.matricula;
      });

    if (arrayDeChavesIguaisAChaveQueEstaSendoInserida?.length > 0) {
      notification.warn({
        message: 'Este servidor já foi incluído',
        description: 'Selecione outro servidor',
      });
      return;
    }

    const novoServidor: typeof props.listaServidores = [servidorModal];

    props.setListaServidores(props.listaServidores.concat(novoServidor));

    setShowModalServidores(false);
    cleanModalServidor();
    props.form.validateFields(['tableServidores']);
    notification.success({
      message: `Servidor ${servidorModal.pessoa.nome} incluído com sucesso`,
    });
  }, [cleanModalServidor, form, props]);

  const servidorTableActions = (servidor: Servidor.Summary) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          title={'Deseja remover o servidor?'}
          disabled={props.formDisabled}
          onConfirm={() => {
            props.setListaServidores(
              props.listaServidores.filter(
                (servidorFilter) =>
                  servidorFilter.matricula !== servidor.matricula
              )
            );
            notification.success({
              message: `Servidor ${servidor.pessoa.nome} removido`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            disabled={props.formDisabled}
            type={'ghost'}
            title={'Remover servidor'}
          />
        </Popconfirm>
      </Row>
    );
  };

  const removeServidor = () => {
    form.setFieldsValue({});
    setServidorSelecionado(false);
  };

  const fetchServidorByCpf = useCallback(
    async (cpf: string) => {
      if (cpf) {
        await fetchExistingServidorByCpf(cpf).then((servidor) => {
          setServidor(servidor);

          if (servidor) {
            checkServidor(servidor);
          }
        });
      } else return;
    },
    [checkServidor, fetchExistingServidorByCpf]
  );

  const fetchServidorByMatricula = useCallback(
    async (matricula: string) => {
      if (matricula) {
        await fetchExistingServidorByMatricula(matricula).then((servidor) => {
          setServidor(servidor);

          if (servidor) {
            checkServidor(servidor);
          }
        });
      } else return;
    },
    [checkServidor, fetchExistingServidorByMatricula]
  );

  return (
    <>
      <Col xs={24} lg={24}>
        <Form.Item
          validateTrigger={'onSubmit'}
          name='tableServidores'
          rules={[
            {
              validator() {
                if (props.listaServidores.length < 1)
                  return Promise.reject(
                    new Error('Deve ser informado pelo menos um servidor')
                  );
                return Promise.resolve();
              },
            },
          ]}
        >
          <Table<Servidor.Summary>
            dataSource={props.listaServidores}
            size={'small'}
            rowKey={(record) => record.matricula}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Servidores
                  </Typography.Title>
                  <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() => {
                      setShowModalServidores(true);

                      scrollFieldToTop('tableServidores');
                    }}
                    disabled={props.formDisabled}
                  />
                </Row>
              );
            }}
            columns={[
              {
                dataIndex: ['pessoa', 'nome'],
                title: 'Nome',
                responsive: ['sm'],
                width: 450,
              },
              {
                dataIndex: ['pessoa', 'cpfCnpj'],
                responsive: ['sm'],
                title: 'CPF',
              },
              {
                dataIndex: 'matricula',
                responsive: ['sm'],
                title: 'Matrícula',
              },
              {
                dataIndex: 'id',
                title: 'Ações',
                responsive: ['sm'],
                width: 60,
                render(_, servidor) {
                  return servidorTableActions(servidor);
                },
              },
            ]}
          />
        </Form.Item>
      </Col>

      <CustomModal
        centered
        open={showModalServidores}
        title={'Cadastro de servidores'}
        onCancel={() => {
          setShowModalServidores(false);
          cleanModalServidor();
        }}
        width={800}
        footer={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              style={{ width: '50%' }}
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalServidores(false);
                cleanModalServidor();
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '50%' }}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              loading={fetchingServidor}
              onClick={() => {
                props.form.validateFields([
                  ['pessoa', 'cpfCnpj'],
                  ['pessoa', 'nome'],
                  ['matricula'],
                ]);

                const cpf = form.getFieldValue(['pessoa', 'cpfCnpj']);
                const matricula = form.getFieldValue(['matricula']);

                const nome = form.getFieldValue(['pessoa', 'nome']);

                if (!cpf || !matricula || !nome) {
                  notification.warn({
                    message: 'Não foi possível salvar o servidor',
                    description: 'Nenhum servidor selecionado',
                  });
                } else handleAddServidor();
              }}
            >
              Salvar
            </Button>
          </div>
        }
        maskClosable={false}
        destroyOnClose
      >
        <Form form={form} layout={'vertical'} size={!xs ? 'small' : 'large'}>
          <Row gutter={24}>
            <Form.Item name={'id'} hidden>
              <Input disabled hidden />
            </Form.Item>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'CPF'}
                name={['pessoa', 'cpfCnpj']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input.Search
                  width={'100%'}
                  placeholder={'Informe cpf do servidor'}
                  style={{ width: '100%' }}
                  loading={fetchingServidor}
                  allowClear={false}
                  disabled={servidorSelecionado}
                  inputMode='numeric'
                  addonAfter={
                    <Button
                      disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeServidor}
                    />
                  }
                  onSearch={(cpf) => {
                    if (!cpf) removeServidor();
                    else fetchServidorByCpf(cpf);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Matrícula'}
                name={['matricula']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input.Search
                  width={'100%'}
                  placeholder={'Informe a matrícula do servidor'}
                  style={{ width: '100%' }}
                  loading={fetchingServidor}
                  allowClear={false}
                  disabled={servidorSelecionado}
                  inputMode='numeric'
                  addonAfter={
                    <Button
                      disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeServidor}
                    />
                  }
                  onSearch={(matricula) => {
                    console.log('matricula', matricula);

                    if (!matricula) removeServidor();
                    else fetchServidorByMatricula(matricula);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Nome'}
                name={['pessoa', 'nome']}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={0} lg={0}>
              <Form.Item label={''} name={['matricula']} hidden>
                <Input hidden />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  );
}
