import { Layout } from 'antd';

import DefaultlayoutContent from './Content';
import DefaultLayoutFooter from './Footer';
import DefaultLayoutHeader from './Header';
import DefaultLayoutSider from './Sider';
import AuthorizationService, {
  AuthorizationEventType,
} from '../../../core/auth/Authorization.service';

import { useEffect, useState } from 'react';

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const [needRerender, setNeedRerender] = useState<boolean>(false);

  window.addEventListener(AuthorizationEventType.AUTHORIZATION_CHANGED, () => {
    console.log('Detectada alteração', AuthorizationService.isAuthenticated());
    setNeedRerender(true);
  });

  useEffect(() => {}, [needRerender]);

  return (
    <>
      <Layout>
        {/* <Spin
          size='large'
          spinning={false}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        > */}

        <DefaultLayoutHeader />

        <Layout hasSider>
          <DefaultLayoutSider />

          <Layout className='site-layout'>
            <DefaultlayoutContent>{props.children}</DefaultlayoutContent>
          </Layout>
        </Layout>
        {/* </Spin> */}

        <DefaultLayoutFooter />
      </Layout>
    </>
  );
}
