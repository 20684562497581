import { Revenda } from '../../@types';
import { IDBPCursorWithValue, openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';

class RevendaIDBService extends ServiceIDB {
  static DB_STORE_REVENDA = 'revenda';
  static DB_STORE_REVENDA_INDEX_CNPJ_NAME = 'revenda_index_cnpj';
  static DB_STORE_REVENDA_INDEX_CNPJ_KEY = 'payload.cpfCnpj';

  static DB_STORE_REVENDA_INDEX_MUNICIPIO_KEY =
    'payload.ule.municipio.codgIBGE';
  static DB_STORE_REVENDA_INDEX_MUNICIPIO_NAME = 'revenda_index_municipio';

  static DB_STORE_REVENDA_INDEX_REVENDA_NAME = 'revenda_index_nome';
  static DB_STORE_REVENDA_INDEX_REVENDA_KEY = 'payload.nome';

  static async getByCnpj(cnpj: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_REVENDA,
      this.DB_STORE_REVENDA_INDEX_CNPJ_NAME,
      cnpj
    );
  }

  static async getByNome(nome: string): Promise<ServiceIDBPayload[]> {
    const results: ServiceIDBPayload[] = [];

    const db = await openDB(DBConfig.DB_NAME);
    const tx = db.transaction(this.DB_STORE_REVENDA, 'readonly');
    const store = tx.objectStore(this.DB_STORE_REVENDA);
    const index = store.index(this.DB_STORE_REVENDA_INDEX_REVENDA_NAME);

    let cursorRequest = index.openCursor();
    let cursor: IDBPCursorWithValue<
      unknown,
      [string],
      string,
      string,
      'readonly'
    > | null;

    try {
      cursor = await cursorRequest;
    } catch (error) {
      // Handle the error
      return Promise.reject(error);
    }

    while (cursor) {
      const value: ServiceIDBPayload = cursor.value;

      if (
        value.payload.nome.toLowerCase().includes(nome.toLowerCase().trim())
      ) {
        results.push(value);
      }

      try {
        cursor = await cursor.continue();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return results;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(RevendaIDBService.DB_STORE_REVENDA);
    return list;
  }

  static async getAllPayload(): Promise<Revenda.Summary[]> {
    let list: Revenda.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async add(revenda: Revenda.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: revenda,
      };

      db.add(this.DB_STORE_REVENDA, obj);
    });
  }
  static async addAllRevenda(lista: Revenda.Summary[]) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_REVENDA, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_REVENDA);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super._deleteByIndexKey(
      this.DB_STORE_REVENDA,
      this.DB_STORE_REVENDA_INDEX_MUNICIPIO_NAME,
      codg_ibge
    );
  }
}

export default RevendaIDBService;
