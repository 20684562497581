import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as AssinaturaBase64ImageActions from '../store/AssinaturaBase64Image.slice';

export default function useAssinaturaBase64Image() {
  const dispatch = useDispatch();

  const assinaturaBase64Image = useSelector(
    (state: RootState) => state.assinaturaBase64Image.assinaturaBase64Image
  );

  const setAssinaturaBase64Image = useCallback(
    (string: string | undefined) => {
      dispatch(AssinaturaBase64ImageActions.storeString(string));
    },
    [dispatch]
  );

  return {
    assinaturaBase64Image,
    setAssinaturaBase64Image,
  };
}
