import { useEffect, useState } from 'react';
import { Card, Descriptions, Row, Table, Typography } from 'antd';
import {
  FVER,
  FVV,
  VigilanciaAves,
  VigilanciaPeixes,
} from '../../../sdk/@types';
import { FinalidadeExploracaoBovinosEBubalinos } from '../../../core/enums/FinalidadeExploracaoBovinosEBubalinos';
import { SistemaDeCriacaoDeRuminantes } from '../../../core/enums/SistemaDeCriacaoDeRuminantes';
import { FaseExploracaoBovinosEBubalinos } from '../../../core/enums/FaseExploracaoBovinosEBubalinos';
import { IdadeRuminantesAlimentadosComRacao } from '../../../core/enums/IdadeRuminantesAlimentadosComRacao';
import { TipoAlimentacaoRuminantes } from '../../../core/enums/TipoAlimentacaoRuminantes';
import { EpocaDoAnoSuplementacao } from '../../../core/enums/EpocaDoAnoSuplementacao';
import { TipoExploracaoAvicolaSistemaIndustrial } from '../../../core/enums/TipoExploracaoAvicolaSistemaIndustrial';
import { SimNao } from '../../../core/enums/SimNao';
import { TipoExploracaoSuinosSistemaIndustrial } from '../../../core/enums/TipoExploracaoSuinosSistemaIndustrial';
import { ResultadoTesteRapido } from '../../../core/enums/ResultadoTesteRapido';
import { AtivaPassiva } from '../../../core/enums/AtivaPassiva';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { Especie } from '../../../core/enums/Especie';

import moment from 'moment';
import HeaderGovernoMt from '../reports/HeaderGovernoMt.report';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';

type PrintFVVProps = {
  fvv?: FVV.Detailed | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintFVV(props: PrintFVVProps) {
  const [fver, setFVER] = useState<FVER.Input>();

  const isEmpty = (object: any): boolean => {
    //return false;
    if (!object.id) return true;

    let objectNoId;
    if (object.id)
      objectNoId = {
        ...object,
        id: null,
      };

    let isEmpty = true;

    let values = Object.values(objectNoId);
    values.forEach((e) => {
      console.log(e);

      if (e) {
        if (Array.isArray(e)) {
          if ((e as Array<any>).length > 0) isEmpty = false;
        } else if (e !== null && e !== undefined) {
          isEmpty = false;
        }
      }
    });

    return isEmpty;
  };

  useEffect(() => {
    const fetchFVER = async () => {
      if (props.fvv)
        if (props.fvv.visitaPropriedadeRural)
          await FVERService.getById(props.fvv.visitaPropriedadeRural.id).then(
            setFVER
          );
    };

    fetchFVER();
  }, [props.fvv]);

  useEffect(() => {}, [props.fvv]);

  const panelDescription = (
    title: string,
    data: DescriptionRow[],
    style?: React.CSSProperties
  ) => {
    return (
      <div
        className='newPage'
        style={{ display: 'block', marginBottom: '24px', ...style }}
      >
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={
            title !== '' ? (
              <Typography.Title level={5}>{title}</Typography.Title>
            ) : (
              ''
            )
          }
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((item) => (
              <Descriptions.Item
                key={item.value}
                label={item.label}
                span={item.span ? item.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {item.value ? item.value : '-'}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div
        className={`${newPage ? newPage : ''}`}
        style={{ display: 'block', marginBottom: '24px' }}
      >
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          //bodyStyle={{ padding: '0px' }}
        >
          {children}
        </Card>
      </div>
    );
  };

  const getDataSourceAves = (vigilanciaAves: VigilanciaAves.Input) => {
    let primeiraLinha: any = {};
    let segundaLinha: any = {};

    primeiraLinha = {
      yAxis: 'Vistoriado',
    };
    if (vigilanciaAves.vigilanciaAvesEspecies) {
      vigilanciaAves.vigilanciaAvesEspecies.forEach((especie) => {
        primeiraLinha = {
          ...primeiraLinha,
          ['esp' + especie.especie]: especie.quantidadeVistoriados,
        };
      });
    }

    segundaLinha = {
      yAxis: 'Examinado',
    };
    if (vigilanciaAves.vigilanciaAvesEspecies) {
      vigilanciaAves.vigilanciaAvesEspecies.forEach((especie) => {
        segundaLinha = {
          ...segundaLinha,
          ['esp' + especie.especie]: especie.quantidadeInspecionados,
        };
      });
    }

    let dataSource: any[] = [];
    dataSource.push(primeiraLinha);
    dataSource.push(segundaLinha);

    return dataSource;
  };

  const getColunasAves = (vigilanciaAves: VigilanciaAves.Input) => {
    let primeiraColuna = {
      title: '',
      dataIndex: 'yAxis',
      key: 'yAxis',
    };

    let dataSource: any[] = [];
    dataSource.push(primeiraColuna);
    if (vigilanciaAves.vigilanciaAvesEspecies) {
      vigilanciaAves.vigilanciaAvesEspecies.forEach((especie) => {
        dataSource.push({
          title: Especie.valueOf(especie.especie),
          dataIndex: `esp${especie.especie}`,
          key: `esp${especie.especie}`,
        });
      });
    }
    return dataSource;
  };

  const getDataSourcePeixes = (vigilanciaPeixes: VigilanciaPeixes.Input) => {
    let primeiraLinha: any = {};
    let segundaLinha: any = {};

    primeiraLinha = {
      yAxis: 'Vistoriado',
    };
    if (vigilanciaPeixes.vigilanciaPeixesEspecies) {
      vigilanciaPeixes.vigilanciaPeixesEspecies.forEach((especie) => {
        primeiraLinha = {
          ...primeiraLinha,
          ['esp' + especie.especie]: especie.quantidadeVistoriados,
        };
      });
    }

    segundaLinha = {
      yAxis: 'Examinado',
    };
    if (vigilanciaPeixes.vigilanciaPeixesEspecies) {
      vigilanciaPeixes.vigilanciaPeixesEspecies.forEach((especie) => {
        segundaLinha = {
          ...segundaLinha,
          ['esp' + especie.especie]: especie.quantidadeInspecionados,
        };
      });
    }

    let dataSource: any[] = [];
    dataSource.push(primeiraLinha);
    dataSource.push(segundaLinha);

    return dataSource;
  };

  const getColunasPeixes = (vigilanciaPeixes: VigilanciaPeixes.Input) => {
    let primeiraColuna = {
      title: '',
      dataIndex: 'yAxis',
      key: 'yAxis',
    };

    let dataSource: any[] = [];
    dataSource.push(primeiraColuna);
    if (vigilanciaPeixes.vigilanciaPeixesEspecies) {
      vigilanciaPeixes.vigilanciaPeixesEspecies.forEach((especie) => {
        dataSource.push({
          title: Especie.valueOf(especie.especie),
          dataIndex: `esp${especie.especie}`,
          key: `esp${especie.especie}`,
        });
      });
    }
    return dataSource;
  };

  const getDataSourceAbelhas = (
    vigilanciaAbelha: FVV.Input['vigilanciaAbelha']
  ) => {
    let primeiraLinha: any = {};
    let segundaLinha: any = {};

    primeiraLinha = {
      yAxis: 'Vistoriado',
      melifera: vigilanciaAbelha?.quantidadeVistoriadosMelifera,
      nativa: vigilanciaAbelha?.quantidadeVistoriadosNativa,
      total:
        (vigilanciaAbelha?.quantidadeVistoriadosMelifera
          ? vigilanciaAbelha?.quantidadeVistoriadosMelifera
          : 0) +
        (vigilanciaAbelha?.quantidadeVistoriadosNativa
          ? vigilanciaAbelha?.quantidadeVistoriadosNativa
          : 0),
    };

    segundaLinha = {
      yAxis: 'Examinado',
      melifera: vigilanciaAbelha?.quantidadeExaminadosMelifera,
      nativa: vigilanciaAbelha?.quantidadeExaminadosNativa,
      total:
        (vigilanciaAbelha?.quantidadeExaminadosMelifera
          ? vigilanciaAbelha?.quantidadeExaminadosMelifera
          : 0) +
        (vigilanciaAbelha?.quantidadeExaminadosNativa
          ? vigilanciaAbelha?.quantidadeExaminadosNativa
          : 0),
    };

    let dataSource: any[] = [];
    dataSource.push(primeiraLinha);
    dataSource.push(segundaLinha);

    return dataSource;
  };

  const getColunasAbelha = () => {
    let dataSource: any[] = [
      {
        title: '',
        dataIndex: 'yAxis',
        key: 'yAxis',
      },
      {
        title: 'APIS melifera',
        dataIndex: 'melifera',
        key: 'melifera',
      },
      {
        title: 'Nativa',
        dataIndex: 'nativa',
        key: 'nativa',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
      },
    ];

    return dataSource;
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} align={'middle'} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO DE VIGILÂNCIA VETERIÁRIA
              </Typography.Title>
            </Row>

            {panelDescription('Dados da Vigilância', [
              {
                label: 'Número',
                value: props.fvv?.numero,
              },
              {
                label: 'Data',
                value: moment(props.fvv?.dataVigilancia).format(
                  'DD/MM/YYYY HH:mm'
                ),
              },

              {
                label: 'Número FVER',
                value: props.fvv?.visitaPropriedadeRural?.numero,
              },
            ])}

            {fver &&
              panelDescription('Identificação do Proprietário', [
                {
                  label: 'Nome',
                  value: fver?.proprietario?.nome,
                  span: 2,
                },
                {
                  label: 'Cpf',
                  value: fver?.proprietario?.cpfCnpj?.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                  ),
                },
                {
                  label: 'Apelido',
                  value: fver?.proprietario?.apelido,
                },
                {
                  label: 'Telefone',
                  value: fver?.proprietario?.endereco?.telefone,
                },
                {
                  label: 'Email',
                  value: fver?.proprietario?.email,
                },
              ])}

            {fver?.proprietario &&
              panelDescription('1. Identificação do Estabelecimento Rural', [
                {
                  label: 'Nome',
                  value: fver?.propriedade?.nome,
                  span: 2,
                },
                {
                  label: 'Código',
                  value: '51' + ('' + fver?.propriedade?.id).padStart(9, '0'),
                },
                {
                  label: 'Exploração',
                  value: fver?.exploracao?.codigo,
                },
                {
                  label: 'Município',
                  value: fver?.propriedade?.municipio.nome,
                },
                {
                  label: 'Coordenada',
                  value: `${fver?.propriedade?.coordenadaGeografica?.latGrau}º 
                    ${fver?.propriedade?.coordenadaGeografica?.latMin}' 
                    ${fver?.propriedade?.coordenadaGeografica?.latSeg}"
                    ${fver?.propriedade?.coordenadaGeografica?.orientacaoLatitude}", 
                    ${fver?.propriedade?.coordenadaGeografica?.longGrau}º 
                    ${fver?.propriedade?.coordenadaGeografica?.longMin}' 
                    ${fver?.propriedade?.coordenadaGeografica?.longSeg}"
                    ${fver?.propriedade?.coordenadaGeografica?.orientacaoLongitude}"`,
                },

                {
                  label: 'Endereço',
                  value: fver?.propriedade?.viaAcesso,
                  span: 2,
                },
              ])}

            {props.fvv?.vigilanciaAlimentosRuminantes &&
              props.fvv?.vigilanciaAlimentosRuminantes
                .descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes &&
              panelDescription(
                '2. Investigação de alimentos fornecidos a ruminantes',
                [
                  {
                    label: 'Finalidade da exploração de ruminantes',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.finalidadeExploracaoBovinosEBubalinos
                      ? FinalidadeExploracaoBovinosEBubalinos.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.finalidadeExploracaoBovinosEBubalinos
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Sistema de produção de ruminantes',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.sistemaDeCriacaoDeRuminantes
                      ? SistemaDeCriacaoDeRuminantes.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.sistemaDeCriacaoDeRuminantes
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Fase da produção',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.sistemaDeCriacaoDeRuminantes2
                      ? props.fvv.vigilanciaAlimentosRuminantes.sistemaDeCriacaoDeRuminantes2
                          .map((s) =>
                            FaseExploracaoBovinosEBubalinos.valueOf(s)
                          )
                          .toString()
                      : '',
                  },
                  {
                    label: 'Outra',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .outroSistemaDeCriacaoDeRuminante,
                  },

                  {
                    label: 'Idade dos bovinos alimentados com ração',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.idadeBovinosAlimentadosComRacao
                      ? props.fvv.vigilanciaAlimentosRuminantes.idadeBovinosAlimentadosComRacao
                          .map((s) =>
                            IdadeRuminantesAlimentadosComRacao.valueOf(s)
                          )
                          .toString()
                      : '',
                  },

                  {
                    label: 'Idade dos ovinos/caprinos alimentados com ração',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.idadeOvinosCaprinosAlimentadosComRacao
                      ? props.fvv.vigilanciaAlimentosRuminantes.idadeOvinosCaprinosAlimentadosComRacao
                          .map((s) =>
                            IdadeRuminantesAlimentadosComRacao.valueOf(s)
                          )
                          .toString()
                      : '',
                  },

                  {
                    label: 'Quantidade bovinos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeBovinos,
                  },
                  {
                    label: 'Quantidade caprinos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeCaprinos,
                  },
                  {
                    label: 'Quantidade ovinos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes.quantidadeOvinos,
                  },
                  {
                    label: 'Quantidade (outros)',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeOutraEspecieRuminante,
                  },

                  {
                    label: 'Quantidade bovinos expostos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeBovinosExpostos,
                  },
                  {
                    label: 'Quantidade caprinos expostos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeCaprinosExpostos,
                  },
                  {
                    label: 'Quantidade ovinos expostos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeOvinosExpostos,
                  },
                  {
                    label: 'Quantidade (outros) expostos',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .quantidadeOutraEspecieRuminanteExpostos,
                  },
                  {
                    label: 'Tipo de alimentação',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.tipoAlimentacaoRuminantesMobile
                      ? TipoAlimentacaoRuminantes.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.tipoAlimentacaoRuminantesMobile
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Época que acontece suplementação',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.epocaDoAnoQueAconteceASuplementacaoMobile
                      ? EpocaDoAnoSuplementacao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.epocaDoAnoQueAconteceASuplementacaoMobile
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Há criação avícola em sistema industrial',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.tipoExploracaoAvicolaSistemaIndustrial
                      ? TipoExploracaoAvicolaSistemaIndustrial.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.tipoExploracaoAvicolaSistemaIndustrial
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Outro',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .outroTipoExploracaoAvicolaSistemaIndustrial,
                  },
                  {
                    label: 'Presença de cama de aviário',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.presencaDeCamaDeAviario
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.presencaDeCamaDeAviario
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Observação sobre cama de aviário',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .observacaoSobreCamaDeAviario,
                  },
                  {
                    label: 'Utilização da cama de aviário na alimentação',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Há criação de suínos em sistema industrial',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.tipoExploracaoSuinosSistemaIndustrial
                      ? TipoExploracaoSuinosSistemaIndustrial.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.tipoExploracaoSuinosSistemaIndustrial
                        ).toString()
                      : '',
                  },
                  {
                    label:
                      'Observação sobre criação de suínos em sistema industrial',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .observacaoTipoExploracaoSuinosSistemaIndustrial,
                  },
                  {
                    label:
                      'Psicultura com sistema de alimentação à base de ração',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.pisciculturaComSistemaDeAlimentacaoABaseDeRacao
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.pisciculturaComSistemaDeAlimentacaoABaseDeRacao
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Indícios de contaminação cruzada peixe x ração',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.contaminacaoCruzadaDeRacoesDePeixeERuminante
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.contaminacaoCruzadaDeRacoesDePeixeERuminante
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Colheita de amostra de alimentos',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.colheitaDeAmostraDeAlimentosDeRuminantes
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.colheitaDeAmostraDeAlimentosDeRuminantes
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Tipo de vigilância',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.tipoFiscalizacao
                      ? AtivaPassiva.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.tipoFiscalizacao
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Nº da denúncia',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes.numeroDenuncia,
                  },
                  {
                    label: 'Uso de teste rápido',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.usoTesteRapido
                      ? SimNao.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.usoTesteRapido
                        ).toString()
                      : '',
                  },
                  {
                    label: 'Resultado do teste rápido',
                    value: props.fvv?.vigilanciaAlimentosRuminantes
                      ?.resultadoTesteRapido
                      ? ResultadoTesteRapido.valueOf(
                          props.fvv?.vigilanciaAlimentosRuminantes
                            ?.resultadoTesteRapido
                        ).toString()
                      : '',
                  },
                  {
                    label:
                      'Descrição do sistema de armazenamento e elaboração de rações',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes,
                    span: 2,
                  },
                  {
                    label: 'Outras observações',
                    value:
                      props.fvv?.vigilanciaAlimentosRuminantes
                        .outrasObservacoes,
                    span: 2,
                  },
                ]
              )}

            {(!props.fvv?.vigilanciaAlimentosRuminantes ||
              !props.fvv?.vigilanciaAlimentosRuminantes
                ?.descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes) &&
              panelDescription(
                '2. Investigação de alimentos fornecidos a ruminantes',
                [
                  {
                    value: 'Não foi realizada durante a visita.',
                  },
                ]
              )}

            {props.fvv &&
              props.fvv.vigilanciaBovinos &&
              !isEmpty(props.fvv.vigilanciaBovinos) &&
              panelComponent(
                '3.1 Bovinos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        F_00_04:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados00_04_F,
                        M_00_04:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados00_04_M,
                        F_05_12:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados05_12_F,
                        M_05_12:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados05_12_M,
                        F_13_24:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados13_24_F,
                        M_13_24:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados13_24_M,
                        F_25_36:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados25_36_F,
                        M_25_36:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionados25_36_M,
                        F_acima36:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionadosAcima36_F,
                        M_acima36:
                          props.fvv.vigilanciaBovinos
                            .quantidadeInspecionadosAcima36_M,
                        total_examinado:
                          props.fvv.vigilanciaBovinos.quantidadeExaminados,
                        total_vistoriado:
                          props.fvv.vigilanciaBovinos.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: '00_04',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_00_04',
                            key: 'F_00_04',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_00_04',
                            key: 'M_00_04',
                          },
                        ],
                      },
                      {
                        title: '05_12',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_05_12',
                            key: 'F_05_12',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_05_12',
                            key: 'M_05_12',
                          },
                        ],
                      },

                      {
                        title: '13_24',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_13_24',
                            key: 'F_13_24',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_13_24',
                            key: 'M_13_24',
                          },
                        ],
                      },

                      {
                        title: '25_36',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_25_36',
                            key: 'F_25_36',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_25_36',
                            key: 'M_25_36',
                          },
                        ],
                      },

                      {
                        title: '> 36',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_acima36',
                            key: 'F_acima36',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_acima36',
                            key: 'M_acima36',
                          },
                        ],
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaBovinos.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaBovinos.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaBovinos.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaBubalino &&
              !isEmpty(props.fvv.vigilanciaBubalino) &&
              panelComponent(
                '3.2 Bubalinos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        F_00_04:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados00_04_F,
                        M_00_04:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados00_04_M,
                        F_05_12:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados05_12_F,
                        M_05_12:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados05_12_M,
                        F_13_24:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados13_24_F,
                        M_13_24:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados13_24_M,
                        F_25_36:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados25_36_F,
                        M_25_36:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionados25_36_M,
                        F_acima36:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionadosAcima36_F,
                        M_acima36:
                          props.fvv.vigilanciaBubalino
                            .quantidadeInspecionadosAcima36_M,
                        total_examinado:
                          props.fvv.vigilanciaBubalino.quantidadeExaminados,
                        total_vistoriado:
                          props.fvv.vigilanciaBubalino.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: '00_04',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_00_04',
                            key: 'F_00_04',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_00_04',
                            key: 'M_00_04',
                          },
                        ],
                      },
                      {
                        title: '05_12',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_05_12',
                            key: 'F_05_12',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_05_12',
                            key: 'M_05_12',
                          },
                        ],
                      },

                      {
                        title: '13_24',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_13_24',
                            key: 'F_13_24',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_13_24',
                            key: 'M_13_24',
                          },
                        ],
                      },

                      {
                        title: '25_36',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_25_36',
                            key: 'F_25_36',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_25_36',
                            key: 'M_25_36',
                          },
                        ],
                      },

                      {
                        title: '> 36',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_acima36',
                            key: 'F_acima36',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_acima36',
                            key: 'M_acima36',
                          },
                        ],
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaBubalino.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaBubalino.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaBubalino.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaSuideos &&
              !isEmpty(props.fvv.vigilanciaSuideos) &&
              panelComponent(
                '3.5 Suídeos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        F_Ate_30:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionadosAte_30_F,
                        M_Ate_30:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionadosAte_30_M,
                        F_31_60:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionados31_60_F,
                        M_31_60:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionados31_60_M,
                        F_61_180:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionados61_180_F,
                        M_61_180:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionados61_180_M,
                        F_Acima_180:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionadosAcima180_F,
                        M_Acima_180:
                          props.fvv.vigilanciaSuideos
                            .quantidadeInspecionadosAcima180_M,
                        total_examinado:
                          props.fvv.vigilanciaSuideos.quantidadeExaminados,
                        total_vistoriado:
                          props.fvv.vigilanciaSuideos.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: 'Ate_30',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_Ate_30',
                            key: 'F_Ate_30',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_Ate_30',
                            key: 'M_Ate_30',
                          },
                        ],
                      },
                      {
                        title: '31_60',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_31_60',
                            key: 'F_31_60',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_31_60',
                            key: 'M_31_60',
                          },
                        ],
                      },

                      {
                        title: '61_180',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_61_180',
                            key: 'F_61_180',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_61_180',
                            key: 'M_61_180',
                          },
                        ],
                      },

                      {
                        title: 'Acima_180',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_Acima_180',
                            key: 'F_Acima_180',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_Acima_180',
                            key: 'M_Acima_180',
                          },
                        ],
                      },

                      {
                        title: '> 36',
                        children: [
                          {
                            title: 'F',
                            dataIndex: 'F_acima36',
                            key: 'F_acima36',
                          },
                          {
                            title: 'M',
                            dataIndex: 'M_acima36',
                            key: 'M_acima36',
                          },
                        ],
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaSuideos.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaSuideos.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaSuideos.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },

                    {
                      label: 'Há presença de suínos asselvajados?',
                      value:
                        props.fvv.vigilanciaSuideos
                          .presencaDeSuinosAsselvajados,
                    },
                    {
                      label:
                        'Há contato direto dos suínos domésticos com os suinos asselvajados na propriedade?',
                      value:
                        props.fvv.vigilanciaSuideos
                          .contatoDiretoDeSuinosDomesticosComAsselvajados,
                    },
                    {
                      label: 'Destino dos cadáveres',
                      value: props.fvv.vigilanciaSuideos.tipoDestinoCadaveres,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaAves &&
              !isEmpty(props.fvv.vigilanciaAves) &&
              panelComponent(
                '3.6 Aves',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={getDataSourceAves(props.fvv.vigilanciaAves)}
                    size='small'
                    bordered
                    pagination={false}
                    columns={getColunasAves(props.fvv.vigilanciaAves)}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaAves.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaAves.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaAves.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaPeixes &&
              !isEmpty(props.fvv.vigilanciaPeixes) &&
              panelComponent(
                '3.7 Peixes',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={getDataSourcePeixes(props.fvv.vigilanciaPeixes)}
                    size='small'
                    bordered
                    pagination={false}
                    columns={getColunasPeixes(props.fvv.vigilanciaPeixes)}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaPeixes.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaPeixes.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaPeixes.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaEquino &&
              !isEmpty(props.fvv.vigilanciaEquino) &&
              panelComponent(
                '3.8 Equinos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        ate_6:
                          props.fvv.vigilanciaEquino
                            .quantidadeInspecionadosAbaixo06Meses,
                        acima_6:
                          props.fvv.vigilanciaEquino
                            .quantidadeInspecionadosAcima06Meses,
                        total_examinado:
                          (props.fvv.vigilanciaEquino
                            .quantidadeInspecionadosAbaixo06Meses
                            ? props.fvv.vigilanciaEquino
                                .quantidadeInspecionadosAbaixo06Meses
                            : 0) +
                          (props.fvv.vigilanciaEquino
                            .quantidadeInspecionadosAcima06Meses
                            ? props.fvv.vigilanciaEquino
                                .quantidadeInspecionadosAcima06Meses
                            : 0),
                        total_vistoriado:
                          props.fvv.vigilanciaEquino.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: 'Até 6 meses',
                        dataIndex: 'ate_6',
                        key: 'ate_6',
                      },
                      {
                        title: 'Acima de 6 meses',
                        dataIndex: 'acima_6',
                        key: 'acima_6',
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaEquino.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaEquino.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaEquino.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaAsinino &&
              !isEmpty(props.fvv.vigilanciaAsinino) &&
              panelComponent(
                '3.9 Asininos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        ate_6:
                          props.fvv.vigilanciaAsinino
                            .quantidadeInspecionadosAbaixo06Meses,
                        acima_6:
                          props.fvv.vigilanciaAsinino
                            .quantidadeInspecionadosAcima06Meses,
                        total_examinado:
                          (props.fvv.vigilanciaAsinino
                            .quantidadeInspecionadosAbaixo06Meses
                            ? props.fvv.vigilanciaAsinino
                                .quantidadeInspecionadosAbaixo06Meses
                            : 0) +
                          (props.fvv.vigilanciaAsinino
                            .quantidadeInspecionadosAcima06Meses
                            ? props.fvv.vigilanciaAsinino
                                .quantidadeInspecionadosAcima06Meses
                            : 0),
                        total_vistoriado:
                          props.fvv.vigilanciaAsinino.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: 'Até 6 meses',
                        dataIndex: 'ate_6',
                        key: 'ate_6',
                      },
                      {
                        title: 'Acima d e6 meses',
                        dataIndex: 'acima_6',
                        key: 'acima_6',
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaAsinino.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaAsinino.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaAsinino.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaMuar &&
              !isEmpty(props.fvv.vigilanciaMuar) &&
              panelComponent(
                '3.10 Muares',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        faixa: 'Examinados',
                        ate_6:
                          props.fvv.vigilanciaMuar
                            .quantidadeInspecionadosAbaixo06Meses,
                        acima_6:
                          props.fvv.vigilanciaMuar
                            .quantidadeInspecionadosAcima06Meses,
                        total_examinado:
                          (props.fvv.vigilanciaMuar
                            .quantidadeInspecionadosAbaixo06Meses
                            ? props.fvv.vigilanciaMuar
                                .quantidadeInspecionadosAbaixo06Meses
                            : 0) +
                          (props.fvv.vigilanciaMuar
                            .quantidadeInspecionadosAcima06Meses
                            ? props.fvv.vigilanciaMuar
                                .quantidadeInspecionadosAcima06Meses
                            : 0),
                        total_vistoriado:
                          props.fvv.vigilanciaMuar.quantidadeVistoriados,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Faixa etária',
                        dataIndex: 'faixa',
                        key: 'faixa',
                      },
                      {
                        title: 'Até 6 meses',
                        dataIndex: 'ate_6',
                        key: 'ate_6',
                      },
                      {
                        title: 'Acima d e6 meses',
                        dataIndex: 'acima_6',
                        key: 'acima_6',
                      },

                      {
                        title: 'Total',
                        children: [
                          {
                            title: 'Examinado',
                            dataIndex: 'total_examinado',
                            key: 'total_examinado',
                          },
                          {
                            title: 'Vistoriado',
                            dataIndex: 'total_vistoriado',
                            key: 'total_vistoriado',
                          },
                        ],
                      },
                    ]}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaMuar.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaMuar.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaMuar.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.vigilanciaAves &&
              !isEmpty(props.fvv.vigilanciaAves) &&
              panelComponent(
                '3.11 Abelhas',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={getDataSourceAbelhas(
                      props.fvv.vigilanciaAbelha
                    )}
                    size='small'
                    bordered
                    pagination={false}
                    columns={getColunasAbelha()}
                  />

                  {panelDescription('', [
                    {
                      label: 'Há suspeita clínica?',
                      value: props.fvv.vigilanciaAves.haSuspeitaClinica
                        ? 'Sim'
                        : 'Não',
                    },
                    {
                      label: 'Qual?',
                      value: props.fvv.vigilanciaAves.suspeitaClinica,
                    },
                    {
                      label: 'Sinais ou sintomas observados pelo MVO',
                      value: String(
                        props.fvv.vigilanciaAves.sinaisSintomasObservados?.map(
                          (sintoma) => SinaisClinicos.valueOf(sintoma)
                        )
                      ).replaceAll(',', ', '),
                      span: 2,
                    },
                  ])}
                </>
              )}

            {props.fvv &&
              props.fvv.historicoDeSugadurasDeMorcegos &&
              !isEmpty(props.fvv.historicoDeSugadurasDeMorcegos) &&
              panelComponent(
                '3.12 Histórico de sugaduras de morcegos',
                <>
                  {panelDescription(
                    '',
                    [
                      {
                        label: 'Há historico de mordedura?',
                        value: props.fvv.historicoDeSugadurasDeMorcegos
                          ? 'Sim'
                          : 'Não',
                      },
                    ],
                    { marginBottom: 0 }
                  )}

                  <Table
                    className='tableFvvReport'
                    dataSource={[
                      {
                        quantidadeBovinos:
                          props.fvv.quantidadeSugadurasEmBovinos,
                        quantidadeBubalinos:
                          props.fvv.quantidadeSugadurasEmBubalinos,
                        quantidadeAves: props.fvv.quantidadeSugadurasEmAves,
                        quantidadeSuideos:
                          props.fvv.quantidadeSugadurasEmSuideos,
                        quantidadeEquideos:
                          props.fvv.quantidadeSugadurasEmEquideos,
                        quantidadeCaprinos:
                          props.fvv.quantidadeSugadurasEmCaprinos,
                      },
                    ]}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Bovinos',
                        dataIndex: 'quantidadeBovinos',
                        key: 'quantidadeBovinos',
                      },
                      {
                        title: 'Bubalinos',
                        dataIndex: 'quantidadeBubalinos',
                        key: 'quantidadeBubalinos',
                      },
                      {
                        title: 'Aves',
                        dataIndex: 'quantidadeAves',
                        key: 'quantidadeAves',
                      },
                      {
                        title: 'Suideos',
                        dataIndex: 'quantidadeSuideos',
                        key: 'quantidadeSuideos',
                      },
                      {
                        title: 'Equideos',
                        dataIndex: 'quantidadeEquideos',
                        key: 'quantidadeEquideos',
                      },
                      {
                        title: 'Caprinos',
                        dataIndex: 'quantidadeCaprinos',
                        key: 'quantidadeCaprinos',
                      },
                    ]}
                  />
                </>
              )}

            {props.fvv &&
              props.fvv.investigacoesEpidemiologicas &&
              !isEmpty(props.fvv.investigacoesEpidemiologicas) &&
              panelComponent(
                '3.13 Histórico de sugaduras de morcegos',
                <>
                  <Table
                    className='tableFvvReport'
                    dataSource={props.fvv.investigacoesEpidemiologicas}
                    size='small'
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: 'Espécie',
                        dataIndex: 'especie',
                        key: 'especie',
                      },
                      {
                        title: 'Data',
                        dataIndex: 'data',
                        key: 'data',
                        render(data) {
                          return moment(data).format('MM/YYYY');
                        },
                      },
                      {
                        title: 'Causas',
                        dataIndex: 'doencas',
                        key: 'doencas',
                        render(doencas) {
                          return String(
                            doencas?.map((doenca: any) => doenca.nome)
                          ).replaceAll(',', ', ');
                        },
                      },
                    ]}
                  />
                </>
              )}

            {props.fvv &&
              panelDescription('3.16. Outras informações', [
                {
                  label: 'Observações',
                  value: props.fvv?.observacao,
                },

                {
                  label: 'Houve emissão de Form IN?',
                  value: props.fvv?.emissaoDeFormIN
                    ? SimNao.valueOf(props.fvv?.emissaoDeFormIN).toString()
                    : '',
                },
              ])}

            {props.fvv &&
              props.fvv.veterinario &&
              panelDescription('3.16. Veterinário', [
                {
                  label: 'Nome',
                  value: props.fvv?.veterinario.pessoa?.nome,
                },

                {
                  label: 'CRMV',
                  value: props.fvv?.veterinario.numeroConselho,
                },

                {
                  label: 'Unidade',
                  value: props.fvv?.veterinario.ule?.nome,
                },
              ])}
          </td>
        </tr>
      </tbody>
    </>
  );
}
