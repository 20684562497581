import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import EditFVV from '../features/FVV/EditFVV';

export default function VigilanciaVeterinariaEditView() {
  usePageTitle('Editando de FVV');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formulário'>
        <EditFVV />
      </ErrorBoundary>
    </>
  );
}
