import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import TFRService from '../../sdk/services/SIZ-API/TermoFiscalizacao.service';
import { TermoFiscalizacao } from '../../sdk/@types';
import localforage from 'localforage';
import AuthorizationService from '../auth/Authorization.service';
import TFRIDBService from '../../sdk/services/indexeddb/TFRIDB.service';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import TermoFisacalizacaoService from '../../sdk/services/SIZ-API/TermoFiscalizacao.service';
import { RootState } from '../../core/store';
// ... outros imports, como o generateHash ...
type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface TFRState {
  tfr: TermoFiscalizacao.Request | null;
  tfrs: TermoFiscalizacao.Paginated | null;
  loading: LoadingState;
  fetching: boolean;
  query: TermoFiscalizacao.Query;
  errorMessage?: string;
}

const initialState: TFRState = {
  tfr: null,
  tfrs: null,
  loading: 'idle',
  fetching: false,
  query: { page: 0, size: 10 },
  errorMessage: undefined,
};

export const insertTFR = createAsyncThunk(
  'termoFiscalizacao/insert',
  async (tfrRequest: TermoFiscalizacao.Request, { rejectWithValue }) => {
    try {
      if (navigator.onLine) {
        return await TFRService.add(tfrRequest);
      } else {
        if (navigator.serviceWorker) {
          const swRegistration = await navigator.serviceWorker.ready;
          //@ts-ignore
          if (swRegistration.sync) {
            localforage.setItem('token', AuthorizationService.getAccessToken());
            const registration = await navigator.serviceWorker.ready;
            //@ts-ignore
            registration.sync.register(SYNC_TERMOFISCALIZACAO_NEEDED);
          }
        }
        return TFRIDBService.insert(tfrRequest);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const filterTFRs = createAsyncThunk(
  'termoFiscalizacao/filterTFRs',
  async (query: TermoFiscalizacao.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const tfrs = await TFRService.getAll(query);
      return tfrs;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os TermoFiscalizacaos')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);
export const updateTFROffline = createAsyncThunk(
  'termoFiscalizacao/updateTFROffline',
  async ({ id, tfr }: { id: number; tfr: TermoFiscalizacao.Request }) => {
    //const codigoVerificador = generateHash(tfr);
    let newTFR: ServiceIDBPayloadInput = {
      id: id,
      payload: {
        ...tfr,
      },
      status: 'NOVO',
      date: new Date(),
    };

    return await TFRIDBService.update(id, newTFR);
  }
);

export const updateTFR = createAsyncThunk(
  'termoFiscalizacao/updateTFR',
  async (
    { id, tfr }: { id: number; tfr: TermoFiscalizacao.Request },
    { rejectWithValue }
  ) => {
    try {
      return await TFRService.update(id, tfr);
    } catch (error) {
      const message = (error as Error).message;
      const serializableError = {
        message: message,
      };
      return rejectWithValue(serializableError);
    }
  }
);
export const removeTFR = createAsyncThunk(
  'termoFiscalizacao/remove',
  async (id: number, { rejectWithValue }) => {
    try {
      return await TFRService.remove(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTFRById = createAsyncThunk<
  TermoFiscalizacao.Response,
  number
>('termoFiscalizacao/fetchTFRById', async (id: number, { rejectWithValue }) => {
  try {
    return await TermoFisacalizacaoService.getById(id);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const selectTFR = (state: RootState) => state.formTfr.tfr;

const TermoFiscalizacaoSlice = createSlice({
  name: 'termoFiscalizacao',
  initialState,
  reducers: {
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<TermoFiscalizacao.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertTFR.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(insertTFR.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.tfr = action.payload;
      })
      .addCase(insertTFR.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(updateTFR.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateTFR.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.tfr = action.payload;
      })
      .addCase(updateTFR.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(removeTFR.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(removeTFR.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
      })
      .addCase(removeTFR.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchTFRById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchTFRById.fulfilled,
        (
          state,
          action: PayloadAction<TermoFiscalizacao.Response | undefined>
        ) => {
          state.loading = 'fulfilled';
          //@ts-ignore
          state.tfr = action.payload;
        }
      )
      .addCase(fetchTFRById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(updateTFROffline.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateTFROffline.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        // Update state based on the payload if needed
      })
      .addCase(updateTFROffline.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
      .addCase(filterTFRs.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterTFRs.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.tfrs = action.payload;
        state.fetching = false;
      })
      .addCase(filterTFRs.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      });
  },
});
export const { setQuery, setFetching } = TermoFiscalizacaoSlice.actions;
export default TermoFiscalizacaoSlice.reducer;
