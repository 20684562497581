export enum TipoExploracaoAvicolaSistemaIndustrial {
  CORTE = 'Corte',
  POSTURA = 'Postura',
  NAO_EXISTE = 'Não existe',
  OUTROS = 'Outro',
}
export namespace TipoExploracaoAvicolaSistemaIndustrial {
  export function keys(): string[] {
    return Object.keys(TipoExploracaoAvicolaSistemaIndustrial).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoExploracaoAvicolaSistemaIndustrial[
      key as keyof typeof TipoExploracaoAvicolaSistemaIndustrial
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(
      TipoExploracaoAvicolaSistemaIndustrial
    ).indexOf(value as unknown as TipoExploracaoAvicolaSistemaIndustrial);

    const key = Object.keys(TipoExploracaoAvicolaSistemaIndustrial)[indexOfS];

    return key;
  }
}
