import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useNavigate } from 'react-router-dom';
import syncTFRs from '../../../core/functions/syncTFRs';
import { SYNC_TERMOFISCALIZACAO_DONE } from '../../../sdk/@types/ServiceWorker.types';
import { ServiceIDBPayloadInput } from '../../../sdk/services/indexeddb/ServiceIDB';

import useSyncingState from '../../../core/hooks/useSyncingState';
import TroubleshootingButton from '../../components/TroubleshootingButton';
import { CustomModal } from '../../components/CustomModal';
import TFRIDBService from '../../../sdk/services/indexeddb/TFRIDB.service';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useDevOptions from '../../../core/hooks/useDevOptions';

interface ListTFROfflineProps {
  visible?: boolean;
}

export default function ListTFROffline(props: ListTFROfflineProps) {
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [, setSyncing] = useState(false);
  const [listaTFRs, setListaTFRs] = useState<ServiceIDBPayloadInput[]>();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const { online } = useNavigatorStatus();
  const [, setFetching] = useState(false);
  const [syncTFRDone, setSyncTFRDone] = useState<string | undefined>(undefined);
  const [showModalMotivoErro, setShowModalMotivoErro] =
    useState<boolean>(false);
  const [showModalDeleteTFRs, setShowModalDeleteTFRs] =
    useState<boolean>(false);
  const [motivoErro, setMotivoErro] = useState<string>();

  const { tfr, setTfr } = useSyncingState();

  if (navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === SYNC_TERMOFISCALIZACAO_DONE) {
        fetchTFRsOffline();
        setSyncTFRDone(event.data.message);
      }
    };

  const fetchTFRsOffline = useCallback(async () => {
    setFetching(true);

    await TFRIDBService.getAllFromInputTable()
      .then(async (tfrs) => {
        let quantidadeTFRsSincronizados = 0;
        tfrs.forEach((tfr) => {
          if (tfr.status === 'SINCRONIZADO') quantidadeTFRsSincronizados++;
        });

        if (quantidadeTFRsSincronizados >= 10) setShowModalDeleteTFRs(true);

        setListaTFRs(tfrs);
        setFetching(false);
      })
      .catch((e) => {
        if (e.code === 8) return;
        else {
          setError(e);
          throw e;
        }
      });
  }, []);

  const deleteTFR = useCallback(
    async (tfr) => {
      setSyncing(true);
      setTfr(true);
      if (tfr.id) {
        await TFRIDBService.delete(tfr.id)
          .then(() => {
            fetchTFRsOffline();
          })
          .finally(() => {
            setSyncing(false);
            setTfr(false);
            navigate(`/acoes-de-campo`);
          });
      }
    },
    [fetchTFRsOffline, setTfr, navigate]
  );

  const deleteSynced = useCallback(async () => {
    const listTFRsSynced = listaTFRs?.filter(
      (tfr) => tfr.status === 'SINCRONIZADO'
    );

    const listTFRsNotSynced = listaTFRs?.filter(
      (tfr) => tfr.status !== 'SINCRONIZADO'
    );

    if (listTFRsSynced)
      for (const tfr of listTFRsSynced) {
        //@ts-ignore
        await TFRIDBService.delete(tfr.id);
      }

    setListaTFRs(listTFRsNotSynced);
    notification.success({
      message: 'Todas os TFRs sincronizados foram removidos',
    });
  }, [listaTFRs]);

  useEffect(() => {}, [syncTFRDone]);

  useEffect(() => {
    const fetchTFRsAsync = async () => {
      await fetchTFRsOffline();
    };
    fetchTFRsAsync();
  }, [fetchTFRsOffline]);

  useEffect(() => {
    setFetching(false);
  }, [listaTFRs]);

  useEffect(() => {}, [tfr]);

  if (error) throw error;

  return (
    <>
      <Row>
        <Space size={4} direction='vertical' style={{ width: '100%' }}>
          <Col hidden={!syncTFRDone} xs={24}>
            <Alert
              message={syncTFRDone}
              type='info'
              showIcon
              closable={false}
            ></Alert>
          </Col>
          <Col span={24}>
            <Table
              dataSource={listaTFRs}
              size={'small'}
              rowKey={'id'}
              loading={tfr}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      TFRs
                    </Typography.Title>

                    <Space>
                      <Popconfirm
                        title={'Deseja remover todos os sincronizados??'}
                        onConfirm={deleteSynced}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          size={'small'}
                          type={'primary'}
                          danger
                        />
                      </Popconfirm>
                      <Popconfirm
                        title={'Confirma a sincronização?'}
                        disabled={
                          !listaTFRs || listaTFRs.length === 0 || !online
                        }
                        onConfirm={async () => {
                          setSyncing(true);
                          setTfr(true);
                          await syncTFRs(true)
                            .then(async (message) => {
                              notification.success({
                                message: message,
                              });
                            })
                            .then(async () => {
                              await syncTFRs(true).finally(() => setTfr(false));
                            })
                            .then(async () => {
                              await fetchTFRsOffline();
                            })
                            .catch(async (e) => {
                              await fetchTFRsOffline();
                              notification.error({
                                message: 'Não foi possível sincronizar',
                                description: e.message,
                              });
                            })
                            .finally(() => {
                              setSyncing(false);
                              setTfr(false);
                              navigate(`/acoes-de-campo`);
                            });
                        }}
                      >
                        <Button
                          icon={<CloudUploadOutlined />}
                          type={'primary'}
                          disabled={
                            !listaTFRs || listaTFRs.length === 0 || !online
                          }
                        />
                      </Popconfirm>

                      <TroubleshootingButton component='TFR' data={listaTFRs} />
                    </Space>
                  </Row>
                );
              }}
              columns={[
                {
                  dataIndex: 'id',
                  width: idInTables ? 100 : 0,
                  render(_, tfrIdb) {
                    return (
                      idInTables && (
                        <>
                          <Typography.Text>
                            {`IDB: ${tfrIdb.id}`}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {`ID: ${
                              tfrIdb.payload.id ? tfrIdb.payload.id : '-'
                            }`}
                          </Typography.Text>
                        </>
                      )
                    );
                  },
                },

                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  render(_, tfrIdb) {
                    return (
                      <Space direction='vertical' size={0}>
                        <Space direction='horizontal' size={8} wrap={xs}>
                          {tfrIdb.payload.numero && (
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{
                                    backgroundColor: 'rgba(131, 239, 156, 0.5)',
                                    borderRadius: '5px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                  }}
                                />
                              }
                              onClick={() =>
                                navigate(`/tfr/visualizar/${tfrIdb.payload.id}`)
                              }
                              style={{ fontWeight: 'bold', paddingLeft: 0 }}
                              type='text'
                            >
                              {tfrIdb.payload.numero}
                            </Button>
                          )}

                          <Tag
                            style={{
                              backgroundColor: `${
                                tfrIdb.status === 'NOVO'
                                  ? '#09f'
                                  : tfrIdb.status === 'SINCRONIZADO'
                                  ? '#2baf57'
                                  : '#ff4e4e'
                              }`,
                              color: 'white',
                            }}
                          >
                            {tfrIdb.status}
                          </Tag>

                          {tfrIdb.status === 'ERRO' && (
                            <Button
                              danger
                              onClick={() => {
                                setShowModalMotivoErro(true);
                                setMotivoErro(tfrIdb.motivoErro);
                              }}
                              style={{ paddingLeft: 0 }}
                              type='link'
                            >
                              <span style={{ borderBottom: '1px solid' }}>
                                Detalhes
                              </span>
                            </Button>
                          )}
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {moment(
                              new Date(tfrIdb.payload.dataVisita!)
                            ).format('DD/MM/YYYY HH:mm')}
                          </Typography.Text>

                          <Typography.Text>
                            {tfrIdb.payload.revenda.endereco
                              ? tfrIdb.payload.revenda.endereco.municipio.nome
                              : ''}
                          </Typography.Text>

                          <Typography.Text>
                            {tfrIdb.payload.nomeFiscalizado}
                          </Typography.Text>
                        </Space>
                      </Space>
                    );
                  },
                },
                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(id, tfr) {
                    return (
                      <>
                        <Space>
                          {tfr.status !== 'SINCRONIZADO' && (
                            <Button
                              icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                              onClick={() =>
                                navigate(`/tfr/edicao/${id}/${'local'}`)
                              }
                              size={'small'}
                              type={'ghost'}
                            />
                          )}
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'ghost'}
                            danger
                            onClick={() => {
                              Modal.confirm({
                                content: (
                                  <>
                                    <Typography.Paragraph>
                                      Deseja proseguir?
                                    </Typography.Paragraph>
                                  </>
                                ),
                                onOk: async () => {
                                  try {
                                    await deleteTFR(tfr);
                                    notification.success({
                                      message: 'TFR removido com sucesso',
                                    });
                                  } catch (e) {
                                    throw e;
                                  }
                                },
                              });
                            }}
                          />
                        </Space>
                      </>
                    );
                  },
                },
              ]}
              expandable={{
                expandedRowRender: (record) => 'Tsest',
                defaultExpandAllRows: true,
                showExpandColumn: false,
              }}
              showHeader={false}
            />
          </Col>
        </Space>
      </Row>

      <CustomModal
        open={showModalMotivoErro}
        title={'Motivo'}
        footer={
          <Button
            style={{ width: '100%' }}
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => {
              setShowModalMotivoErro(false);
              setMotivoErro(undefined);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          {motivoErro}
        </Typography.Title>
      </CustomModal>

      <CustomModal
        open={showModalDeleteTFRs}
        title={'Aviso'}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '100%' }}
              type='default'
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalDeleteTFRs(false);
              }}
            >
              Fechar
            </Button>

            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<CloseOutlined />}
              onClick={async () => {
                await deleteSynced().finally(() =>
                  setShowModalDeleteTFRs(false)
                );
              }}
            >
              Remover
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>
          Notamos que você ainda está mantendo no seu dispositivo muitos TFRs
          que já foram sincronizados.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Deseja removê-los? Essa ação não irá afetar os TFRs no banco de dados.
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
