import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateTFR from '../features/FormTFR/CreateTFR';

export default function TFRCreateView() {
  usePageTitle('Novo Termo de Fiscalização de Revenda');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateTFR />
      </ErrorBoundary>
    </>
  );
}
