import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import EnvConfig from '../../../app/EnvConfig';

const Http = axios.create({
  timeout: 10000,
  timeoutErrorMessage: 'Timeout',
});

type SIZ_APIError = {
  status: number;
  title: string;
  detail: string;
  timestamp: string;
  type: string;
};

Http.interceptors.response.use(undefined, async (error) => {
  console.log('Http.interceptors INTERCEPTOR', error.message, error.code);

  if (error.message?.includes(Service.TIMEOUT)) {
    notification.error({
      message: 'Não conseguimos conectar com o servidor',
      description: 'Tempo limite de conexão estourado',
    });
  }

  if (error.message?.includes('Network Error')) {
    notification.error({
      message: 'Não conseguimos conectar com o servidor',
      description: 'Tente novamente mais tarde',
    });
  }

  const erroAPI: SIZ_APIError = error.response.data;

  if (error?.response?.status === 502) {
    notification.error({
      message:
        'Ocorreu um erro tentando comunicar com o servidor. Contate o administrador do sistema.',
    });
  }

  return Promise.reject(error);
});

class Service {
  static API_URL = EnvConfig.apiUrl();
  static NETWORK_ERROR = 'Network Error';
  static TIMEOUT = 'Timeout';

  protected static Http = Http;
  protected static getData = getData;

  public static setRequestInterceptor(
    onFulfilled: (
      request: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any
  ) {
    Http.interceptors.request.use(onFulfilled, onRejected);
  }

  public static setResponseInterceptor(
    onFulfilled: (
      request: AxiosResponse
    ) => AxiosResponse | Promise<AxiosResponse>,
    onRejected?: (error: any) => any
  ) {
    Http.interceptors.response.use(onFulfilled, onRejected);
  }
}

function getDataThrowable<T>(res: AxiosResponse<T>) {
  return res.data;
}

function getData<T>(res: AxiosResponse<T>) {
  if (res instanceof Error) {
    //@ts-ignore
    if (!res.response) {
      throw new Error(Service.NETWORK_ERROR);
    } else {
      //@ts-ignore
      throw new Error((res.response.data as SIZ_APIError).detail);
    }
  } else {
    return getDataThrowable(res);
  }
}

Http.defaults.baseURL = Service.API_URL;

export default Service;
