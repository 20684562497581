import { useEffect } from 'react';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import RecebimentoRevendaView from '../features/SOVIV/RecebimentoRevendaView';
import useLoadingPage from '../../core/hooks/useLoadingPage';

export default function RecebimentoRevendaViewWrapper() {
  usePageTitle('SOVIV');
  const { setLoading, setMode, setFirstOpening } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='SOVIV'>
        <RecebimentoRevendaView />
      </ErrorBoundary>
    </>
  );
}
