import { FormVIN } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormVINService extends Service {
  static getAll(query: FormVIN.Query): Promise<FormVIN.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<FormVIN.Paginated>(
      '/form-vins'.concat(queryString)
    ).then(this.getData);
  }

  static add(formVINInput: FormVIN.Input) {
    return this.Http.post<FormVIN.Input>('/form-vins', formVINInput).then(
      this.getData
    );
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`/form-vins/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get<FormVIN.Input>(`/form-vins/${id}`).then(this.getData);
  }
}

export default FormVINService;
