import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Especie } from '../../../core/enums/Especie';
import { InvestigacaoEpidemiologica } from '../../../sdk/@types';
import { CustomModal } from '../../components/CustomModal';

type VigilanciaInvestigacaoEpidemiologicaProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaInvestigacaoEpidemiologica(
  props: VigilanciaInvestigacaoEpidemiologicaProps
) {
  const { xs } = useBreakpoint();

  const listaEspecie = Especie.especiesVigilanciaVeterinaria();

  const [
    dataSourceInvestigacaoEpidemiologica,
    setDatasourceInvestigacaoEpidemiologica,
  ] = useState<InvestigacaoEpidemiologica.Input[]>([]);

  const [
    showModalInvestigacaoEpidemiologica,
    setShowModalInvestigacaoEpidemiologica,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (props.form.getFieldValue('investigacoesEpidemiologicas')) {
      let investigacoesEpidemiologicas = props.form
        .getFieldValue('investigacoesEpidemiologicas')
        ?.map((investigacao: any) => {
          return {
            ...investigacao,
            data: moment(investigacao.data),
          };
        });

      setDatasourceInvestigacaoEpidemiologica(investigacoesEpidemiologicas);
    }
  }, [props.form]);

  const investigacaoEpidemiologicaTableActions = (
    investigacaoEpimiologicaToBeRemoved: InvestigacaoEpidemiologica.Input
  ) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          disabled={props.formDisabled}
          title={'Deseja remover a espécie?'}
          onConfirm={() => {
            setDatasourceInvestigacaoEpidemiologica(
              dataSourceInvestigacaoEpidemiologica.filter(
                (investigacao) =>
                  investigacao.especie !==
                  investigacaoEpimiologicaToBeRemoved.especie
              )
            );
            notification.success({
              message: `Espécie ${Especie.valueOf(
                investigacaoEpimiologicaToBeRemoved.especie
              )} removida`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            type={'ghost'}
            title={'Remover espécie'}
            disabled={props.formDisabled}
          />
        </Popconfirm>
      </Row>
    );
  };

  const clearModalInvestigacaoEpidemiologica = useCallback(() => {
    props.form.setFieldsValue({ modalInvestigacaoEpimiologica: undefined });
  }, [props.form]);

  const handleAddInvestigacaoEpidemiologica = useCallback(() => {
    let investigacaoEpidemiologica_modal: InvestigacaoEpidemiologica.Input =
      props.form.getFieldValue('modalInvestigacaoEpimiologica');

    const doencas: InvestigacaoEpidemiologica.Input['doencas'] =
      investigacaoEpidemiologica_modal.doencas;
    const doencasLimpas = doencas.filter((doenca) => doenca.nome !== null);
    investigacaoEpidemiologica_modal.doencas = doencasLimpas;

    const newDataSource = dataSourceInvestigacaoEpidemiologica.concat(
      investigacaoEpidemiologica_modal
    );

    setDatasourceInvestigacaoEpidemiologica(newDataSource);

    props.form.setFieldsValue({
      investigacoesEpidemiologicas: newDataSource,
    });

    setShowModalInvestigacaoEpidemiologica(false);
    clearModalInvestigacaoEpidemiologica();
    notification.success({
      message: 'Investigação epidemiológica incluída com sucesso',
    });
  }, [
    clearModalInvestigacaoEpidemiologica,
    dataSourceInvestigacaoEpidemiologica,
    props.form,
  ]);

  return (
    <>
      <Col xs={24} sm={24}>
        <Form.Item
          validateTrigger={'onSubmit'}
          name='tableInvestigacaoEpidemiologicas'
          /* rules={[
        {
          validator(_, value) {
            if (dataSourceInvestigacaoEpidemiologica.length < 1)
              return Promise.reject(
                new Error(
                  'Deve ser informado pelo menos um programa'
                )
              );
            return Promise.resolve();
          },
        },
      ]} */
        >
          <Table<InvestigacaoEpidemiologica.Input>
            size={'small'}
            dataSource={dataSourceInvestigacaoEpidemiologica}
            rowKey={(record) => record.especie}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Investigação epidemiológica com base no relato do produtor
                  </Typography.Title>
                  <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() => {
                      setShowModalInvestigacaoEpidemiologica(true);
                    }}
                    disabled={props.formDisabled}
                  />
                </Row>
              );
            }}
            columns={[
              /* {
            dataIndex: [
              'tipoInvestigacaoEpidemiologica',
              'id',
            ],
            responsive: ['xs'],
            render(_, chavePrimaria) {
              return (
                <Descriptions size={'small'} column={1} bordered>
                  <Descriptions.Item label={'Chave principal'}>
                    {
                      chavePrimaria
                        .tipoInvestigacaoEpidemiologica
                        .nome
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={'Motivo principal?'}>
                    <Switch
                      disabled={formDisabled}
                      defaultChecked={false}
                      checked={
                        chavePrimaria.motivoPrincipal === 'SIM'
                          ? true
                          : false
                      }
                      onChange={(value) =>
                        onChangeSwitchMotivoPrincipal(
                          value,
                          chavePrimaria
                        )
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={'Chaves secundárias'}>
                    {chavePrimaria.listChaveSecundariaVisitaPropriedadeRural?.map(
                      (chave) => (
                        <Tooltip
                          title={
                            chave
                              .tipoChaveSecundariaVisitaPropriedadeRural
                              ?.nome
                          }
                        >
                          <Tag
                            style={{
                              maxWidth: '160px',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                            key={
                              chave
                                .tipoChaveSecundariaVisitaPropriedadeRural
                                ?.id
                            }
                          >
                            {
                              chave
                                .tipoChaveSecundariaVisitaPropriedadeRural
                                ?.nome
                            }
                          </Tag>
                        </Tooltip>
                      )
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label='Ações'>
                    {chavePrimariaTableActions(chavePrimaria)}
                  </Descriptions.Item>
                </Descriptions>
              );
            },
          }, */
              {
                dataIndex: 'especie',
                title: 'Espécie',
                responsive: ['sm'],
                render(especie: string) {
                  return Especie.valueOf(especie);
                },
              },

              {
                dataIndex: 'data',
                title: 'Data',
                responsive: ['sm'],
                render(data) {
                  return moment(data).format('MM/yyyy');
                },
              },

              {
                dataIndex: 'doencas',
                title: 'Causas',
                responsive: ['sm'],
                render(doencas: InvestigacaoEpidemiologica.Input['doencas'][]) {
                  return doencas.map((doenca) => {
                    return (
                      <Tag>
                        <Typography.Text
                          ellipsis
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '200px',
                          }}
                        >
                          {
                            //@ts-ignore
                            doenca.nome
                          }
                        </Typography.Text>
                      </Tag>
                    );
                  });
                },
              },

              {
                dataIndex: 'id',
                title: 'Ações',
                responsive: ['sm'],
                width: 60,
                render(id, investigacaoEpidemiologica) {
                  return investigacaoEpidemiologicaTableActions(
                    investigacaoEpidemiologica
                  );
                },
              },
            ]}
          />
        </Form.Item>
      </Col>

      <CustomModal
        centered
        open={showModalInvestigacaoEpidemiologica}
        title={'Cadastro de investigação epidemiológica'}
        onCancel={() => {
          setShowModalInvestigacaoEpidemiologica(false);
          clearModalInvestigacaoEpidemiologica();
        }}
        width={750}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Form
          layout={'vertical'}
          form={props.form}
          autoComplete={'off'}
          onFinish={handleAddInvestigacaoEpidemiologica}
        >
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item name={['modalInvestigacaoEpimiologica', 'id']} hidden>
                <Input disabled hidden />
              </Form.Item>
              <Form.Item
                label={'Espécie'}
                name={['modalInvestigacaoEpimiologica', 'especie']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione uma espécie'}
                  placement={'bottomLeft'}
                >
                  {listaEspecie?.map((especie) => (
                    <Select.Option key={especie} value={especie}>
                      {Especie.valueOf(especie)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Data da última ocorrência/morte'}
                name={['modalInvestigacaoEpimiologica', 'data']}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'MM/YYYY'}
                  disabledDate={(date) => {
                    return date.isAfter(moment().toDate());
                  }}
                  disabled={props.formDisabled}
                  inputReadOnly
                  picker='month'
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Typography.Title level={5}>
                Relato do produtor das possíveis causas
              </Typography.Title>
              <Divider />

              <Form.List
                name={['modalInvestigacaoEpimiologica', 'doencas']}
                rules={[
                  {
                    validator: async (_, doencas) => {
                      if (!doencas || doencas.length < 1)
                        return Promise.reject(
                          new Error('O campo é obrigatório')
                        );
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Row gutter={24} key={field.name}>
                            <Col xs={18} lg={20}>
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: 'O campo é obrigatório',
                                    whitespace: true,
                                  },
                                ]}
                                {...field}
                                name={[field.name, 'nome']}
                                label={index === 0 ? 'Causa' : ''}
                                validateTrigger={['onChange', 'onBlur']}
                              >
                                <Input />
                              </Form.Item>
                            </Col>

                            <Col xs={6} lg={4}>
                              <Form.Item
                                {...field}
                                label={index === 0 ? 'Remover' : ''}
                              >
                                <Button
                                  onClick={() => remove(field.name)}
                                  block
                                  icon={<DeleteOutlined />}
                                  danger
                                  size='small'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.ErrorList errors={errors} />
                      <Button
                        type='dashed'
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar causa
                      </Button>
                    </>
                  );
                }}
              </Form.List>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Providência'}
                name={['modalInvestigacaoEpimiologica', 'providencia']}
              >
                <Input autoComplete='off' maxLength={255} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={24} justify={'end'}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                danger
                icon={<CloseOutlined />}
                onClick={() => {
                  setShowModalInvestigacaoEpidemiologica(false);
                  clearModalInvestigacaoEpidemiologica();
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                type={'primary'}
                icon={<CheckCircleOutlined />}
                htmlType='submit'
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  );
}
