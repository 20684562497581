import { StageType } from '../core/enums/StageType';

export default class EnvConfig {
  protected static STAGE = process.env.REACT_APP_STAGE
    ? process.env.REACT_APP_STAGE
    : 'DEV';

  //DESENVOLVIMENTO
  protected static VERSION_DEV = '1.10.12';
  protected static API_URL_DEV = 'http://localhost:8080';
  protected static BASE_URL_DEV = 'http://localhost:3000';

  //HOMOLOGAÇÃO
  protected static VERSION_HOM = '1.24.1';
  protected static API_URL_HOM = 'https://web.indea.mt.gov.br/siz-api-hom';
  protected static BASE_URL_HOM = 'https://siz-v3-hom.indea.mt.gov.br';

  //PRODUÇÃO
  protected static VERSION_PROD = '1.12.4';
  protected static API_URL_PROD = 'https://web.indea.mt.gov.br/siz-api';
  protected static BASE_URL_PROD = 'https://siz-v3.indea.mt.gov.br';

  public static apiUrl() {
    //REACT_APP_API_URL_DEV pode ser definida no env.local
    if (process.env.REACT_APP_API_URL_DEV)
      return process.env.REACT_APP_API_URL_DEV;

    if (this.stage() === StageType.DEV) return this.API_URL_DEV;
    else if (this.stage() === StageType.HOMOLOG) return this.API_URL_HOM;
    else if (this.stage() === StageType.PROD) return this.API_URL_PROD;
  }

  public static baseUrl() {
    //REACT_APP_API_URL_DEV pode ser definida no env.local
    if (process.env.REACT_APP_BASE_URL_DEV)
      return process.env.REACT_APP_BASE_URL_DEV;

    if (this.stage() === StageType.DEV) return this.BASE_URL_DEV;
    else if (this.stage() === StageType.HOMOLOG) return this.BASE_URL_HOM;
    else if (this.stage() === StageType.PROD) return this.BASE_URL_PROD;
  }

  public static stage() {
    return StageType.valueOf(this.STAGE);
  }

  public static version() {
    if (this.stage() === StageType.DEV) return this.VERSION_DEV;
    else if (this.stage() === StageType.HOMOLOG) return this.VERSION_HOM;
    else if (this.stage() === StageType.PROD) return this.VERSION_PROD;

    return '0';
  }

  public static isDev() {
    return this.stage() === StageType.DEV;
  }

  public static isHom() {
    return this.stage() === StageType.HOMOLOG;
  }

  public static isProd() {
    return this.stage() === StageType.PROD;
  }
}
