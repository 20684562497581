import { Col, Row, Typography, Descriptions, Card, Button } from 'antd';
import { FVER } from '../../../sdk/@types';
import { formatCpf_Cnpj } from '../../../core/functions/formatCpf_Cnpj';
import { ButtonGoogleMaps } from '../../components/ButtonGoogleMaps';
import moment from 'moment';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface PanelFVERInputProps {
  customTitle?: string;
  fver: FVER.Input;
  showPrintButton?: boolean;
}

export default function PanelFVERInput(props: PanelFVERInputProps) {
  return (
    <Card
      title={
        <Row justify='space-between'>
          <Typography.Title level={4}>
            {props.customTitle ? props.customTitle : 'FVER'}
          </Typography.Title>

          {props.showPrintButton && (
            <Link
              rel='noopener noreferrer'
              target='_blank'
              to={`/public/visualizarFVER/${props.fver.codigoVerificador}`}
            >
              <Button className='top' type='primary' icon={<PrinterOutlined />}>
                Imprimir
              </Button>
            </Link>
          )}
        </Row>
      }
    >
      <Descriptions column={3} labelStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label='Número' span={1}>
          {props.fver?.numero ? (
            props.fver?.numero
          ) : (
            <b style={{ color: '#f11d1d' }}>FVER não Sincronizado</b>
          )}
        </Descriptions.Item>

        <Descriptions.Item label='Data' span={2}>
          {moment(props.fver?.dataDaVisita).format('DD/MM/YYYY HH:mm')}
        </Descriptions.Item>

        <Descriptions.Item label='Estabelecimento' span={3}>
          {`${props.fver?.propriedade?.id} - ${props.fver?.propriedade?.nome}`}
        </Descriptions.Item>

        <Descriptions.Item label='Exploração' span={1}>
          {`${props.fver?.exploracao ? props.fver?.exploracao.codigo : '-'}`}
        </Descriptions.Item>

        <Descriptions.Item label='Produtor' span={2}>
          {`${formatCpf_Cnpj(props.fver?.proprietario?.cpfCnpj)} - ${
            props.fver?.proprietario?.nome
          }`}
        </Descriptions.Item>
        <Descriptions.Item label='Município' span={1}>
          {`${props.fver?.propriedade?.municipio.nome}`}
        </Descriptions.Item>
        <Descriptions.Item label='Coordenadas' span={2}>
          <Row gutter={8} align='middle'>
            <Col>
              <span>
                {`${props.fver.propriedade?.coordenadaGeografica?.latGrau}º${props.fver.propriedade?.coordenadaGeografica?.latMin}'${props.fver.propriedade?.coordenadaGeografica?.latSeg}''${props.fver.propriedade?.coordenadaGeografica?.orientacaoLatitude}`}
                {', '}
                {`${props.fver.propriedade?.coordenadaGeografica?.longGrau}º${props.fver.propriedade?.coordenadaGeografica?.longMin}'${props.fver.propriedade?.coordenadaGeografica?.longSeg}''${props.fver.propriedade?.coordenadaGeografica?.orientacaoLongitude}`}
              </span>
            </Col>
            <Col>
              {props.fver.propriedade?.coordenadaGeografica && (
                <ButtonGoogleMaps
                  coordenadas={props.fver.propriedade?.coordenadaGeografica}
                />
              )}
            </Col>
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}
