import {
  Affix,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FVV } from '../../../sdk/@types/FVV';
import {
  InvestigacaoEpidemiologica,
  VigilanciaAves,
  VigilanciaPeixes,
  FVER,
  Produtor,
  Propriedade,
} from '../../../sdk/@types';
import moment from 'moment';
import useFVER from '../../../core/hooks/useFVER';
import useFVV from '../../../core/hooks/useFVV';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useDispatch, useSelector } from 'react-redux';
import * as FVERActions from '../../../core/store/FVER.slice';
import { RootState } from '../../../core/store';
import { TipoEstabelecimento } from '../../../core/enums/TipoEstabelecimento';
import { CloseOutlined, SelectOutlined } from '@ant-design/icons';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import AuthorizationService from '../../../core/auth/Authorization.service';
import VeterinarioIDBService from '../../../sdk/services/indexeddb/VeterinarioIDB.service';
import VigilanciaInvestigacaoDeAlimentos from './VigilanciaInvestigacaoDeAlimentos';
import VigilanciaBovinos from './VigilanciaBovinos';
import VigilanciaBubalinos from './VigilanciaBubalinos';
import VigilanciaCaprinos from './VigilanciaCaprinos';
import VigilanciaOvinos from './VigilanciaOvinos';
import VigilanciaSuideos from './VigilanciaSuideos';
import VigilanciaAvesPanel from './VigilanciaAves';
import VigilanciaPeixesPanel from './VigilanciaPeixes';
import VigilanciaEquinos from './VigilanciaEquinos';
import VigilanciaAsininos from './VigilanciaAsininos';
import VigilanciaMuares from './VigilanciaMuares';
import VigilanciaAbelhas from './VigilanciaAbelhas';
import VigilanciaHistoricoMordedura from './VigilanciaHistoricoMordedura';
import VigilanciaInvestigacaoEpidemiologica from './VigilanciaInvestigacaoEpidemiologica';
import VigilanciaOutrasInformacoes from './VigilanciaOutrasInformacoes';
import FilterPanelFVER from '../FVER/FilterPanelFVER';
import FVVService from '../../../sdk/services/SIZ-API/FVV.service';
import { CustomModal } from '../../components/CustomModal';
import { usePrompt } from '../../../core/hooks/usePrompt';
import PanelFVERInput from '../FVER/PanelFVER_Input';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import PropriedadeIDBService from '../../../sdk/services/indexeddb/PropriedadeIDB.service';

export type FormFVVType = {
  modalInvestigacaoEpimiologica?: InvestigacaoEpidemiologica.Input;
  modalVigilanciaAves?: VigilanciaAves.Input;
  modalVigilanciaPeixes?: VigilanciaPeixes.Input;
} & FVV.Input;

type FormFVVProps = {
  fvv?: FormFVVType;
};

export default function FormFVV(props: FormFVVProps) {
  const { online } = useNavigatorStatus();
  const dispatch = useDispatch();

  const fvers = useSelector((state: RootState) => state.fver.fvers);
  const query = useSelector((state: RootState) => state.fver.query);
  const fetching = useSelector((state: RootState) => state.fver.fetching);

  const [, setError] = useState<Error>();

  const params = useParams<{ id: string; local?: string; problem?: string }>();
  const [form] = Form.useForm<FormFVVType>();
  const [shouldSaveOffline, setShouldSaveOffline] = useState<boolean>(false);

  const { xs, lg } = useBreakpoint();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const { mode, firstOpening, setFirstOpening, setLoading } = useLoadingPage();

  const { updateFVVOffline } = useFVV();
  const { fetchFVERById, fetchFVERByCodigoVerificador, fver } = useFVER();
  const [proprietarios, setProprietarios] =
    useState<Propriedade.Summary['proprietarios']>();

  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);
  const [fverNaoEncontrado, setFVERNaoEncontrado] = useState(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  type StateStep = {
    step: number;
    state: boolean;
  };

  const [stateStep_0, setStateStep_0] = useState<StateStep>({
    step: 0,
    state: false,
  });
  const [stateStep_1, setStateStep_1] = useState<StateStep>({
    step: 1,
    state: false,
  });
  const [stateStep_2, setStateStep_2] = useState<StateStep>({
    step: 2,
    state: false,
  });
  const [stateStep_3, setStateStep_3] = useState<StateStep>({
    step: 3,
    state: false,
  });
  const [stateStep_4, setStateStep_4] = useState<StateStep>({
    step: 4,
    state: false,
  });
  const [stateStep_5, setStateStep_5] = useState<StateStep>({
    step: 5,
    state: false,
  });
  const [stateStep_6, setStateStep_6] = useState<StateStep>({
    step: 6,
    state: false,
  });
  const [stateStep_7, setStateStep_7] = useState<StateStep>({
    step: 7,
    state: false,
  });
  const [stateStep_8, setStateStep_8] = useState<StateStep>({
    step: 8,
    state: false,
  });
  const [stateStep_9, setStateStep_9] = useState<StateStep>({
    step: 9,
    state: false,
  });
  const [stateStep_10, setStateStep_10] = useState<StateStep>({
    step: 10,
    state: false,
  });
  const [stateStep_11, setStateStep_11] = useState<StateStep>({
    step: 11,
    state: false,
  });
  const [stateStep_12, setStateStep_12] = useState<StateStep>({
    step: 12,
    state: false,
  });
  const [stateStep_13, setStateStep_13] = useState<StateStep>({
    step: 13,
    state: false,
  });
  const [stateStep_14, setStateStep_14] = useState<StateStep>({
    step: 14,
    state: false,
  });
  const [stateStep_15, setStateStep_15] = useState<StateStep>({
    step: 15,
    state: false,
  });

  useEffect(() => {
    console.log('online');
  }, [online]);

  useEffect(() => {
    setFirstOpening(true);
  }, []);

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      if (!params.local) {
        fetchFVERById(
          //@ts-ignore
          props.fvv?.visitaPropriedadeRural?.id
        ).catch((e) => {
          setError(new Error(e.message));
        });
      } else {
        if (props.fvv?.visitaPropriedadeRural?.codigoVerificador)
          fetchFVERByCodigoVerificador(
            props.fvv?.visitaPropriedadeRural?.codigoVerificador,
            params.local ? true : false
          )
            .then((fver) => {
              if (!fver) {
                fetchFVERById(
                  //@ts-ignore
                  props.fvv?.visitaPropriedadeRural?.id
                ).catch((e) => {
                  setError(new Error(e.message));
                });
              }
            })
            .catch((e) => {
              setError(new Error(e.message));
            });
      }

      if (props.fvv?.vigilanciaAlimentosRuminantes)
        form.setFieldValue(
          ['vigilanciaAlimentosRuminantes', 'houveInvestigacao'],
          true
        );
      else
        form.setFieldValue(
          ['vigilanciaAlimentosRuminantes', 'houveInvestigacao'],
          false
        );
    }
  }, [mode]);

  useEffect(() => {
    if (params.local) {
      setLoading(false);
      if (firstOpening)
        if (params.problem) {
          setFVERNaoEncontrado(true);
          setLoading(false);
        }
    }
  }, [firstOpening, params, props.fvv, setLoading, form]);

  useEffect(() => {
    if (mode === 'CREATE') {
      //procurar visita on line
      fetchFVERById(Number(params.id), params.local ? true : false)
        .catch((e) => {
          setError(new Error(e.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetchFVERById]);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);

    /* if (props.fverOffline) {
      //@ts-ignore
      form.setFieldsValue({ visitaPropriedadeRural: props.fverOffline });

      form.setFieldsValue({
        //@ts-ignore
        dataVigilancia: moment(props.fverOffline.dataDaVisita),
        visitaPropriedadeRural: {
          ...props.fverOffline,
          dataDaVisita: moment(props.fverOffline.dataDaVisita).format(
            'DD/MM/YYYY'
          ),
          //@ts-ignore
          nomeEstabelecimento:
            props.fverOffline.tipoEstabelecimento === 'PROPRIEDADE'
              ? props.fverOffline.propriedade?.nome
              : props.fverOffline.tipoEstabelecimento === 'ABATEDOURO'
              ? props.fverOffline.abatedouro?.pessoa.nome
              : props.fverOffline.recinto?.pessoa.nome,
          //@ts-ignore
          codigoEstabelecimento:
            props.fverOffline.tipoEstabelecimento === 'PROPRIEDADE'
              ? props.fverOffline.propriedade?.id
              : props.fverOffline.tipoEstabelecimento === 'ABATEDOURO'
              ? props.fverOffline.abatedouro?.id
              : props.fverOffline.recinto?.id,
          numero: props.fverOffline.numero ? props.fverOffline.numero : '-',
          id: props.fverOffline.id ? props.fverOffline.id : -1,
          local: moment(props.fverOffline.dataDaVisita)
            .format('DD/MM/YYYY')
            .concat('-')
            .concat(props.fverOffline.tipoEstabelecimento)
            .concat(' - ')
            .concat(props.fverOffline.propriedade?.nome + ''),
        },
        //@ts-ignore
        proprietario: props.fverOffline.proprietario,
        propriedade: props.fverOffline.propriedade,
        setor: props.fverOffline.setor,
        exploracao: props.fverOffline.exploracao,
      });
      setLoading(false);
    } else {
      if (!params.local)
        if (mode === 'CREATE') {
          //procurar visita on line
          fetchFVERById(Number(params.id))
            .catch((e) => {
              setError(new Error(e.message));
            })
            .finally(() => {
              setLoading(false);
            });
        }
    } */

    if (mode === 'EDIT' || mode === 'VIEW') {
      setStateStep_0({
        step: 0,
        state: true,
      });
      setStateStep_1({
        step: 1,
        state: true,
      });
      setStateStep_2({
        step: 2,
        state: true,
      });
      setStateStep_3({
        step: 3,
        state: true,
      });
      setStateStep_4({
        step: 4,
        state: true,
      });
      setStateStep_5({
        step: 5,
        state: true,
      });
      setStateStep_6({
        step: 6,
        state: true,
      });
      setStateStep_7({
        step: 7,
        state: true,
      });
      setStateStep_8({
        step: 8,
        state: true,
      });
      setStateStep_9({
        step: 9,
        state: true,
      });
      setStateStep_10({
        step: 10,
        state: true,
      });
      setStateStep_11({
        step: 11,
        state: true,
      });
      setStateStep_12({
        step: 12,
        state: true,
      });
      setStateStep_13({
        step: 13,
        state: true,
      });
      setStateStep_14({
        step: 14,
        state: true,
      });
      setStateStep_15({
        step: 15,
        state: true,
      });
    }
  }, [mode]);

  useEffect(() => {
    console.log('fver', fver);

    //populate form
    if (fver) {
      //@ts-ignore
      form.setFieldsValue({ visitaPropriedadeRural: fver });

      form.setFieldsValue({
        //@ts-ignore
        dataVigilancia: moment(fver.dataDaVisita),
        visitaPropriedadeRural: {
          ...fver,
          dataDaVisita: moment(fver.dataDaVisita).format('DD/MM/YYYY'),
          //@ts-ignore
          nomeEstabelecimento:
            fver.tipoEstabelecimento === 'PROPRIEDADE'
              ? fver.propriedade?.nome
              : fver.tipoEstabelecimento === 'ABATEDOURO'
              ? fver.abatedouro?.pessoa.nome
              : fver.recinto?.pessoa.nome,
          //@ts-ignore
          codigoEstabelecimento:
            fver.tipoEstabelecimento === 'PROPRIEDADE'
              ? fver.propriedade?.id
              : fver.tipoEstabelecimento === 'ABATEDOURO'
              ? fver.abatedouro?.id
              : fver.recinto?.id,
          numero: fver.numero ? fver.numero : '-',
          id: fver.id ? fver.id : -1,
          local: moment(fver.dataDaVisita)
            .format('DD/MM/YYYY')
            .concat('-')
            .concat(fver.tipoEstabelecimento)
            .concat(' - ')
            .concat(fver.propriedade?.nome + ''),
        },
        propriedade: fver.propriedade,
        setor: fver.setor,
        exploracao: fver.exploracao,
      });

      setLoading(false);
    }

    //populate proprietarios
    if (fver) {
      if (!params.local) setProprietarios(fver.propriedade?.proprietarios);
      else {
        PropriedadeIDBService.getById(fver.propriedade!.id).then(
          (propriedade) => {
            if (propriedade)
              setProprietarios(propriedade.payload.proprietarios);
            else setProprietarios(fver.propriedade?.proprietarios);
          }
        );
      }
    }
  }, [setLoading, fver, form]);

  useEffect(() => {
    if (mode === 'CREATE' && proprietarios)
      form.setFieldsValue({
        proprietario: proprietarios[0].proprietario,
      });
    else {
      let proprietario: any = props.fvv?.proprietario;

      // REMOVER. Código criado para corrigir problema da edição de FVV
      if (proprietario && proprietario.proprietario) {
        proprietario = {
          ...proprietario,
          nome: proprietario.proprietario.nome,
          proprietario: undefined,
        };
      }
      form.setFieldsValue({
        proprietario: proprietario,
      });
    }
  }, [proprietarios]);

  useEffect(() => {
    const insertLoggedUser = async () => {
      if (mode === 'CREATE') {
        if (AuthorizationService.getUsuarioSIZ()) {
          await VeterinarioIDBService.getByCpf(
            //@ts-ignore
            AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
          ).then((veterinario) => {
            if (veterinario) {
              form.setFieldsValue({
                veterinario: veterinario.payload,
              });
            }
          });
        }
      }
    };

    insertLoggedUser();
  }, []);

  const saveFVVLocal = useCallback(
    async (fvv: FVV.Input) => {
      updateFVVOffline(Number(params.id), fvv)
        .then((fvv) => {
          setDadosModalFinalizacao({
            id: Number(params.id),
            local: true,
            numero: '',
          });

          setShowModalFinalizacao(true);
        })
        .catch(console.log);
    },
    [params.id, updateFVVOffline]
  );

  const saveFVVOnline = useCallback(
    async (fvv: FVV.Input) => {
      setLoading(true);
      await FVVService.save(fvv)
        .then((fvv) => {
          setDadosModalFinalizacao({
            id: fvv.id,
            numero: fvv.numero,
          });

          setLoading(false);
          setShowModalFinalizacao(true);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            message: 'Houve um erro ao salvar os dados',
            description: e.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormFVVType) => {
      form.validateFields().then(() => {
        setStateStep_15({
          step: 15,
          state: true,
        });
      });

      if (
        stateStep_0.state &&
        stateStep_1.state &&
        stateStep_2.state &&
        stateStep_3.state &&
        stateStep_4.state &&
        stateStep_5.state &&
        stateStep_6.state &&
        stateStep_7.state &&
        stateStep_8.state &&
        stateStep_9.state &&
        stateStep_10.state &&
        stateStep_11.state &&
        stateStep_12.state &&
        stateStep_13.state &&
        stateStep_14.state
      ) {
      } else {
        notification.error({
          message: 'Algumas etapas não foram finalizadas',
          description: 'Por favor revise o formulário',
        });
        return;
      }

      /**
       * Por algum motivo (suspeito que seja por causa do form
       * englobando todos os steps), os valores do form não estão
       * armazenados no formParam, apenas no form.
       * Então foi preciso copiar manualmente cada campo
       */

      const data = form.getFieldValue('dataVigilancia');

      const isVigilanciaAlimentosRuminantesEmpty = (
        vigilanciaAlimentosRuminantes: FVV.Input['vigilanciaAlimentosRuminantes']
      ): boolean => {
        let isEmpty = true;

        if (
          vigilanciaAlimentosRuminantes?.contaminacaoCruzadaDeRacoesDePeixeERuminante
        )
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes
        )
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.epocaDoAnoQueAconteceASuplementacaoMobile
        )
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.finalidadeExploracaoBovinosEBubalinos
        )
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.idadeDosRuminantesAlimentadosComRacao
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.localCamaAviario) isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.observacaoTipoExploracaoSuinosSistemaIndustrial
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.outraEspecieRuminante)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.outraEspecieRuminanteExposta)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.outrasObservacoes) isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.outroSistemaDeCriacaoDeRuminante)
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.pisciculturaComSistemaDeAlimentacaoABaseDeRacao
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.presencaDeCamaDeAviario)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeBovinos) isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeBovinosExpostos)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeCaprinos) isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeCaprinosExpostos)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeOutraEspecieRuminante)
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.quantidadeOutraEspecieRuminanteExpostos
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeOvinos) isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.quantidadeOvinosExpostos)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.sistemaDeCriacaoDeRuminantes)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.sistemaDeCriacaoDeRuminantes2)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.tipoAlimentacaoRuminantes)
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.tipoExploracaoAvicolaSistemaIndustrial
        )
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.tipoExploracaoSuinosSistemaIndustrial
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.tipoFiscalizacao) isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes
        )
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.usoTesteRapido) isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.resultadoTesteRapido)
          isEmpty = false;

        if (vigilanciaAlimentosRuminantes?.tipoAlimentacaoRuminantesMobile)
          isEmpty = false;

        if (
          vigilanciaAlimentosRuminantes?.epocaDoAnoQueAconteceASuplementacaoMobile
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaAbelhaEmpty = (
        vigilanciaAbelha: FVV.Input['vigilanciaAbelha']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaAbelha?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaAbelha?.quantidadeVistoriadosMelifera) isEmpty = false;

        if (vigilanciaAbelha?.quantidadeVistoriadosNativa) isEmpty = false;

        if (vigilanciaAbelha?.suspeitaClinica) isEmpty = false;

        if (vigilanciaAbelha?.quantidadeExaminadosMelifera) isEmpty = false;

        if (vigilanciaAbelha?.quantidadeExaminadosNativa) isEmpty = false;

        if (
          vigilanciaAbelha?.sinaisSintomasObservados &&
          vigilanciaAbelha?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaAsininoEmpty = (
        vigilanciaAsinino: FVV.Input['vigilanciaAsinino']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaAsinino?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaAsinino?.suspeitaClinica) isEmpty = false;

        if (vigilanciaAsinino?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaAsinino?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaAsinino?.quantidadeInspecionadosAbaixo06Meses)
          isEmpty = false;

        if (vigilanciaAsinino?.quantidadeInspecionadosAcima06Meses)
          isEmpty = false;

        if (
          vigilanciaAsinino?.sinaisSintomasObservados &&
          vigilanciaAsinino?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaBovinosEmpty = (
        vigilanciaBovinos: FVV.Input['vigilanciaBovinos']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaBovinos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaBovinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados00_04_F) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados00_04_M) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados05_12_F) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados05_12_M) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados13_24_F) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados13_24_M) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados25_36_F) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionados25_36_M) isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionadosAcima36_F)
          isEmpty = false;

        if (vigilanciaBovinos?.quantidadeInspecionadosAcima36_M)
          isEmpty = false;

        if (
          vigilanciaBovinos?.sinaisSintomasObservados &&
          vigilanciaBovinos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaBubalinoEmpty = (
        vigilanciaBubalinos: FVV.Input['vigilanciaBubalino']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaBubalinos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaBubalinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados00_04_F)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados00_04_M)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados05_12_F)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados05_12_M)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados13_24_F)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados13_24_M)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados25_36_F)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionados25_36_M)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionadosAcima36_F)
          isEmpty = false;

        if (vigilanciaBubalinos?.quantidadeInspecionadosAcima36_M)
          isEmpty = false;

        if (
          vigilanciaBubalinos?.sinaisSintomasObservados &&
          vigilanciaBubalinos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaCaprinoEmpty = (
        vigilanciaCaprinos: FVV.Input['vigilanciaCaprino']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaCaprinos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaCaprinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeInspecionadosAcima_12_F)
          isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeInspecionadosAcima_12_M)
          isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeInspecionadosAte_12_F)
          isEmpty = false;

        if (vigilanciaCaprinos?.quantidadeInspecionadosAte_12_M)
          isEmpty = false;

        if (
          vigilanciaCaprinos?.sinaisSintomasObservados &&
          vigilanciaCaprinos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaOvinoEmpty = (
        vigilanciaOvinos: FVV.Input['vigilanciaOvino']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaOvinos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaOvinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaOvinos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaOvinos?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaOvinos?.quantidadeInspecionadosAcima_12_F)
          isEmpty = false;

        if (vigilanciaOvinos?.quantidadeInspecionadosAcima_12_M)
          isEmpty = false;

        if (vigilanciaOvinos?.quantidadeInspecionadosAte_12_F) isEmpty = false;

        if (vigilanciaOvinos?.quantidadeInspecionadosAte_12_M) isEmpty = false;

        if (
          vigilanciaOvinos?.sinaisSintomasObservados &&
          vigilanciaOvinos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaSuideosEmpty = (
        vigilanciaSuideos: FVV.Input['vigilanciaSuideos']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaSuideos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaSuideos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeVistoriados) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionadosAte_30_F) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionadosAte_30_M) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionados31_60_F) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionados31_60_M) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionados61_180_F) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionados61_180_M) isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionadosAcima180_F)
          isEmpty = false;

        if (vigilanciaSuideos?.quantidadeInspecionadosAcima180_M)
          isEmpty = false;

        if (vigilanciaSuideos?.presencaDeSuinosAsselvajados) isEmpty = false;

        if (vigilanciaSuideos?.contatoDiretoDeSuinosDomesticosComAsselvajados)
          isEmpty = false;

        if (vigilanciaSuideos?.tipoDestinoCadaveres) isEmpty = false;

        if (
          vigilanciaSuideos?.sinaisSintomasObservados &&
          vigilanciaSuideos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaAvesEmpty = (
        vigilanciaAves: FVV.Input['vigilanciaAves']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaAves?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaAves?.suspeitaClinica) isEmpty = false;

        if (
          vigilanciaAves?.vigilanciaAvesEspecies &&
          vigilanciaAves?.vigilanciaAvesEspecies.length > 0
        )
          isEmpty = false;

        if (
          vigilanciaAves?.sinaisSintomasObservados &&
          vigilanciaAves?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaPeixesEmpty = (
        vigilanciaPeixes: FVV.Input['vigilanciaPeixes']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaPeixes?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaPeixes?.suspeitaClinica) isEmpty = false;

        if (
          vigilanciaPeixes?.vigilanciaPeixesEspecies &&
          vigilanciaPeixes?.vigilanciaPeixesEspecies.length > 0
        )
          isEmpty = false;

        if (
          vigilanciaPeixes?.sinaisSintomasObservados &&
          vigilanciaPeixes?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaEquinoEmpty = (
        vigilanciaEquinos: FVV.Input['vigilanciaEquino']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaEquinos?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaEquinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaEquinos?.suspeitaClinica) isEmpty = false;

        if (vigilanciaEquinos?.quantidadeInspecionadosAbaixo06Meses)
          isEmpty = false;

        if (vigilanciaEquinos?.quantidadeInspecionadosAcima06Meses)
          isEmpty = false;

        if (vigilanciaEquinos?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaEquinos?.quantidadeVistoriados) isEmpty = false;

        if (
          vigilanciaEquinos?.sinaisSintomasObservados &&
          vigilanciaEquinos?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const isVigilanciaMuarEmpty = (
        vigilanciaMuares: FVV.Input['vigilanciaMuar']
      ): boolean => {
        let isEmpty = true;

        if (vigilanciaMuares?.haSuspeitaClinica) isEmpty = false;

        if (vigilanciaMuares?.suspeitaClinica) isEmpty = false;

        if (vigilanciaMuares?.suspeitaClinica) isEmpty = false;

        if (vigilanciaMuares?.quantidadeInspecionadosAbaixo06Meses)
          isEmpty = false;

        if (vigilanciaMuares?.quantidadeInspecionadosAcima06Meses)
          isEmpty = false;

        if (vigilanciaMuares?.quantidadeExaminados) isEmpty = false;

        if (vigilanciaMuares?.quantidadeVistoriados) isEmpty = false;

        if (
          vigilanciaMuares?.sinaisSintomasObservados &&
          vigilanciaMuares?.sinaisSintomasObservados.length > 0
        )
          isEmpty = false;

        return isEmpty;
      };

      const getInvestigacoesEpidemiologicas = (
        investigacoesEpidemiologica: FVV.Input['investigacoesEpidemiologicas']
      ): FVV.Input['investigacoesEpidemiologicas'] | undefined => {
        let investigacoesEpidemiologicasFormatado;

        if (investigacoesEpidemiologica)
          investigacoesEpidemiologicasFormatado =
            investigacoesEpidemiologica.map(
              (investigacao: InvestigacaoEpidemiologica.Input) => {
                return {
                  ...investigacao,
                  data: JSON.stringify(investigacao.data).replaceAll('"', ''),
                };
              }
            );
        else investigacoesEpidemiologicasFormatado = undefined;

        return investigacoesEpidemiologicasFormatado;
      };

      let contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected = undefined;
      let presencaDeSuinosAsselvajadosCorrected = undefined;

      if (form.getFieldValue('vigilanciaSuideos')) {
        //contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected
        if (
          form.getFieldValue([
            'vigilanciaSuideos',
            'contatoDiretoDeSuinosDomesticosComAsselvajados',
          ]) === 'SIM' ||
          form.getFieldValue([
            'vigilanciaSuideos',
            'contatoDiretoDeSuinosDomesticosComAsselvajados',
          ]) === 'NAO'
        ) {
          contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected =
            form.getFieldValue([
              'vigilanciaSuideos',
              'contatoDiretoDeSuinosDomesticosComAsselvajados',
            ]);
        } else {
          if (
            form.getFieldValue([
              'vigilanciaSuideos',
              'contatoDiretoDeSuinosDomesticosComAsselvajados',
            ]) === true
          )
            contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected = 'SIM';
          else if (
            form.getFieldValue([
              'vigilanciaSuideos',
              'contatoDiretoDeSuinosDomesticosComAsselvajados',
            ]) === false
          )
            contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected = 'NAO';
        }

        //presencaDeSuinosAsselvajadosCorrected
        if (
          form.getFieldValue([
            'vigilanciaSuideos',
            'presencaDeSuinosAsselvajados',
          ]) === 'SIM' ||
          form.getFieldValue([
            'vigilanciaSuideos',
            'presencaDeSuinosAsselvajados',
          ]) === 'NAO'
        ) {
          presencaDeSuinosAsselvajadosCorrected = form.getFieldValue([
            'vigilanciaSuideos',
            'presencaDeSuinosAsselvajados',
          ]);
        } else {
          if (
            form.getFieldValue([
              'vigilanciaSuideos',
              'presencaDeSuinosAsselvajados',
            ]) === true
          )
            presencaDeSuinosAsselvajadosCorrected = 'SIM';
          else if (
            form.getFieldValue([
              'vigilanciaSuideos',
              'presencaDeSuinosAsselvajados',
            ]) === false
          )
            presencaDeSuinosAsselvajadosCorrected = 'NAO';
        }
      }

      const fvvDTO: FVV.Input = {
        ...formParam,
        id: form.getFieldValue('id'),
        dataVigilancia: JSON.stringify(data).replaceAll('"', ''),
        emissaoDeFormIN: form.getFieldValue('emissaoDeFormIN'),
        exploracao: form.getFieldValue('exploracao'),
        historicoDeSugadurasDeMorcegos: form.getFieldValue(
          'historicoDeSugadurasDeMorcegos'
        ),
        investigacoesEpidemiologicas: getInvestigacoesEpidemiologicas(
          form.getFieldValue('investigacoesEpidemiologicas')
        ),
        motivosVigilanciaVeterinaria: form.getFieldValue(
          'motivosVigilanciaVeterinaria'
        ),
        numero: form.getFieldValue('numero'),
        observacao: form.getFieldValue('observacao'),
        pontosDeRisco: form.getFieldValue('pontosDeRisco'),
        propriedade: form.getFieldValue('propriedade'),
        proprietario: form.getFieldValue('proprietario'),
        quantidadeSugadurasEmAves: form.getFieldValue(
          'quantidadeSugadurasEmAves'
        ),
        quantidadeSugadurasEmBovinos: form.getFieldValue(
          'quantidadeSugadurasEmBovinos'
        ),
        quantidadeSugadurasEmBubalinos: form.getFieldValue(
          'quantidadeSugadurasEmBubalinos'
        ),
        quantidadeSugadurasEmCaprinos: form.getFieldValue(
          'quantidadeSugadurasEmCaprinos'
        ),
        quantidadeSugadurasEmEquideos: form.getFieldValue(
          'quantidadeSugadurasEmEquideos'
        ),
        quantidadeSugadurasEmSuideos: form.getFieldValue(
          'quantidadeSugadurasEmSuideos'
        ),
        setor: form.getFieldValue('setor'),
        tipoEstabelecimento: form.getFieldValue('tipoEstabelecimento'),
        veterinario: form.getFieldValue('veterinario'),

        vigilanciaAlimentosRuminantes: isVigilanciaAlimentosRuminantesEmpty(
          form.getFieldValue('vigilanciaAlimentosRuminantes')
        )
          ? undefined
          : form.getFieldValue('vigilanciaAlimentosRuminantes'),

        vigilanciaAves: isVigilanciaAvesEmpty(
          form.getFieldValue('vigilanciaAves')
        )
          ? undefined
          : form.getFieldValue('vigilanciaAves'),

        vigilanciaBovinos: isVigilanciaBovinosEmpty(
          form.getFieldValue('vigilanciaBovinos')
        )
          ? undefined
          : form.getFieldValue('vigilanciaBovinos'),

        vigilanciaBubalino: isVigilanciaBubalinoEmpty(
          form.getFieldValue('vigilanciaBubalino')
        )
          ? undefined
          : form.getFieldValue('vigilanciaBubalino'),

        vigilanciaCaprino: isVigilanciaCaprinoEmpty(
          form.getFieldValue('vigilanciaCaprino')
        )
          ? undefined
          : form.getFieldValue('vigilanciaCaprino'),

        vigilanciaOvino: isVigilanciaOvinoEmpty(
          form.getFieldValue('vigilanciaOvino')
        )
          ? undefined
          : form.getFieldValue('vigilanciaOvino'),

        vigilanciaPeixes: isVigilanciaPeixesEmpty(
          form.getFieldValue('vigilanciaPeixes')
        )
          ? undefined
          : form.getFieldValue('vigilanciaPeixes'),

        vigilanciaSuideos: isVigilanciaSuideosEmpty(
          form.getFieldValue('vigilanciaSuideos')
        )
          ? undefined
          : {
              ...form.getFieldValue('vigilanciaSuideos'),
              contatoDiretoDeSuinosDomesticosComAsselvajados:
                contatoDiretoDeSuinosDomesticosComAsselvajadosCorrected,
              presencaDeSuinosAsselvajados:
                presencaDeSuinosAsselvajadosCorrected,
            },

        vigilanciaAbelha: isVigilanciaAbelhaEmpty(
          form.getFieldValue('vigilanciaAbelha')
        )
          ? undefined
          : form.getFieldValue('vigilanciaAbelha'),

        vigilanciaAsinino: isVigilanciaAsininoEmpty(
          form.getFieldValue('vigilanciaAsinino')
        )
          ? undefined
          : form.getFieldValue('vigilanciaAsinino'),

        vigilanciaEquino: isVigilanciaEquinoEmpty(
          form.getFieldValue('vigilanciaEquino')
        )
          ? undefined
          : form.getFieldValue('vigilanciaEquino'),

        vigilanciaMuar: isVigilanciaMuarEmpty(
          form.getFieldValue('vigilanciaMuar')
        )
          ? undefined
          : form.getFieldValue('vigilanciaMuar'),

        visitaPropriedadeRural: {
          ...form.getFieldValue('visitaPropriedadeRural'),
          dataDaVisita: undefined,
        },
      };

      if (online) {
        if (params.local) {
          saveFVVLocal(fvvDTO);
        } else {
          if (shouldSaveOffline) {
            saveFVVLocal(fvvDTO);
          } else {
            saveFVVOnline(fvvDTO);
          }
        }
      } else {
        if (!fvvDTO.id) {
          saveFVVLocal(fvvDTO);
        } else {
          saveFVVOnline(fvvDTO);
        }
      }
    },
    [
      form,
      online,
      params.local,
      saveFVVLocal,
      saveFVVOnline,
      shouldSaveOffline,
      stateStep_0,
      stateStep_1,
      stateStep_2,
      stateStep_3,
      stateStep_4,
      stateStep_5,
      stateStep_6,
      stateStep_7,
      stateStep_8,
      stateStep_9,
      stateStep_10,
      stateStep_11,
      stateStep_12,
      stateStep_13,
      stateStep_14,
    ]
  );

  const handleChangeSelectProprietario = (proprietario: any) => {
    let proprietarioJSON: Produtor.Summary = JSON.parse(proprietario);

    form.setFieldsValue({
      proprietario: proprietarioJSON,
    });
  };

  /* Controle Steps */
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  enum content {
    VISITA,
    INVESTIGACAO_DE_ALIMENTOS_RUMINANTES,
    BOVINOS,
    BUBALINOS,
    CAPRINOS,
    OVINOS,
    SUIDEOS,
    AVES,
    PEIXES,
    EQUINOS,
    ASININOS,
    MUARES,
    ABELHAS,
    HISTORICO_MORDEDURA,
    INVESTIGACAO_EPIDEMIOLOGICA,
    OUTRAS_INFORMACOES,
  }
  const steps = [
    {
      title: 'Visita',
      content: content.VISITA,
    },
    {
      title: 'Investigação de alimentos',
      content: content.INVESTIGACAO_DE_ALIMENTOS_RUMINANTES,
    },
    {
      title: 'Bovinos',
      content: content.BOVINOS,
    },
    {
      title: 'Bubalinos',
      content: content.BUBALINOS,
    },
    {
      title: 'Caprinos',
      content: content.CAPRINOS,
    },
    {
      title: 'Ovinos',
      content: content.OVINOS,
    },
    {
      title: 'Suídeos',
      content: content.SUIDEOS,
    },
    {
      title: 'Aves',
      content: content.AVES,
    },
    {
      title: 'Peixes',
      content: content.PEIXES,
    },
    {
      title: 'Equinos',
      content: content.EQUINOS,
    },
    {
      title: 'Asininos',
      content: content.ASININOS,
    },
    {
      title: 'Muares',
      content: content.MUARES,
    },
    {
      title: 'Abelhas',
      content: content.ABELHAS,
    },
    {
      title: 'Histórico de mordedura de morcegos',
      content: content.HISTORICO_MORDEDURA,
    },
    {
      title: 'Investigação epidemiológica',
      content: content.INVESTIGACAO_EPIDEMIOLOGICA,
    },
    {
      title: 'Outras informações',
      content: content.OUTRAS_INFORMACOES,
    },
  ];

  const getStatus = (atual: any) => {
    let state = false;

    if (atual === 0) {
      state = stateStep_0.state;
    } else if (atual === 1) {
      state = stateStep_1.state;
    } else if (atual === 2) {
      state = stateStep_2.state;
    } else if (atual === 3) {
      state = stateStep_3.state;
    } else if (atual === 4) {
      state = stateStep_4.state;
    } else if (atual === 5) {
      state = stateStep_5.state;
    } else if (atual === 6) {
      state = stateStep_6.state;
    } else if (atual === 7) {
      state = stateStep_7.state;
    } else if (atual === 8) {
      state = stateStep_8.state;
    } else if (atual === 9) {
      state = stateStep_9.state;
    } else if (atual === 10) {
      state = stateStep_10.state;
    } else if (atual === 11) {
      state = stateStep_11.state;
    } else if (atual === 12) {
      state = stateStep_12.state;
    } else if (atual === 13) {
      state = stateStep_13.state;
    } else if (atual === 14) {
      state = stateStep_14.state;
    } else if (atual === 15) {
      state = stateStep_15.state;
    }

    let v: 'process' | 'wait' | 'finish' | 'error' = 'wait';
    if (current === atual) v = 'process';
    else if (state) v = 'finish';
    else if (current > atual) v = 'error';
    else v = 'wait';

    return v;
  };

  const stepsMenu = (
    direction: 'vertical' | 'horizontal',
    labelPlacement: 'vertical' | 'horizontal'
  ) => {
    return (
      <Steps
        current={current}
        direction={direction}
        labelPlacement={labelPlacement}
        onChange={(value: number) => {
          if (value < current) {
            setCurrent(value);
          } else {
            form
              .validateFields()
              .then(() => {
                if (current === stateStep_0.step)
                  setStateStep_0({ step: 0, state: true });
                else if (current === stateStep_1.step)
                  setStateStep_1({ step: 1, state: true });
                else if (current === stateStep_2.step)
                  setStateStep_2({ step: 2, state: true });
                else if (current === stateStep_3.step)
                  setStateStep_3({ step: 3, state: true });
                else if (current === stateStep_4.step)
                  setStateStep_4({ step: 4, state: true });
                else if (current === stateStep_5.step)
                  setStateStep_5({ step: 5, state: true });
                else if (current === stateStep_6.step)
                  setStateStep_6({ step: 6, state: true });
                else if (current === stateStep_7.step)
                  setStateStep_7({ step: 7, state: true });
                else if (current === stateStep_8.step)
                  setStateStep_8({ step: 8, state: true });
                else if (current === stateStep_9.step)
                  setStateStep_9({ step: 9, state: true });
                else if (current === stateStep_10.step)
                  setStateStep_10({ step: 10, state: true });
                else if (current === stateStep_11.step)
                  setStateStep_11({ step: 11, state: true });
                else if (current === stateStep_12.step)
                  setStateStep_12({ step: 12, state: true });
                else if (current === stateStep_13.step)
                  setStateStep_13({ step: 13, state: true });
                else if (current === stateStep_14.step)
                  setStateStep_14({ step: 14, state: true });
                else if (current === stateStep_15.step)
                  setStateStep_15({ step: 15, state: true });

                setCurrent(value);
                window.location.replace('#');
              })
              .catch(() => {
                notification.error({
                  message: 'Alguns campos obrigatórios não foram preenchidos',
                  description: 'Por favor revise o formulário',
                });
              });
          }
        }}
        style={{ paddingBottom: '24px' }}
      >
        {steps.map((item, atual) => (
          /* atual > current - 5 &&
            atual < current + 5 && */
          <Step
            key={item.title}
            title={item.title}
            status={getStatus(atual)}
            onClick={() => {
              onClose();
            }}
          />
        ))}
      </Steps>
    );
  };
  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Spin spinning={!fver}>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='FVV'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />

      <Form<FormFVVType>
        layout={'vertical'}
        form={form}
        size={!xs ? 'small' : 'large'}
        onFinish={handleFormSubmit}
        autoComplete={'off'}
        onFinishFailed={() => {
          notification.error({
            message: 'Alguns campos obrigatórios não foram preenchidos',
            description: 'Por favor revise o formulário',
          });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        initialValues={props.fvv}
      >
        {lg && stepsMenu('horizontal', 'vertical')}
        {!lg && (
          <Affix offsetTop={80}>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                showDrawer();
              }}
              block
            >
              Ir para etapa
            </Button>
          </Affix>
        )}
        <Divider />

        <Drawer title='Etapas' placement='right' onClose={onClose} open={open}>
          {stepsMenu('vertical', 'horizontal')}
        </Drawer>

        <div id='top' className='top' />
        <div>
          <fieldset disabled={formDisabled}>
            {steps[current].content === content.VISITA && (
              <>
                {fver && (
                  <>
                    <Col xs={24} lg={24} style={{ marginBottom: '24px' }}>
                      <PanelFVERInput fver={fver} />
                    </Col>
                    <Col xs={24} lg={24}>
                      <Form.Item
                        label={'Proprietário'}
                        name={['proprietario', 'nome']}
                        rules={[
                          {
                            required: true,
                            message: 'O campo é obrigatório',
                          },
                        ]}
                      >
                        <Select
                          disabled={formDisabled}
                          onClick={() => {
                            scrollFieldToTop('exploracao_codigo');
                          }}
                          onChange={handleChangeSelectProprietario}
                          loading={fetching}
                          placement={'bottomLeft'}
                        >
                          {proprietarios?.map((proprietario) => (
                            <Select.Option
                              key={proprietario.id}
                              value={JSON.stringify(proprietario)}
                            >
                              <Typography.Text key={proprietario.id}>
                                {proprietario.proprietario.nome}
                              </Typography.Text>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            {steps[current].content ===
              content.INVESTIGACAO_DE_ALIMENTOS_RUMINANTES && (
              <VigilanciaInvestigacaoDeAlimentos
                form={form}
                formDisabled={formDisabled}
              />
            )}

            {steps[current].content === content.BOVINOS && (
              <VigilanciaBovinos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.BUBALINOS && (
              <VigilanciaBubalinos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.CAPRINOS && (
              <VigilanciaCaprinos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.OVINOS && (
              <VigilanciaOvinos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.SUIDEOS && (
              <VigilanciaSuideos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.AVES && (
              <VigilanciaAvesPanel form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.PEIXES && (
              <VigilanciaPeixesPanel form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.EQUINOS && (
              <VigilanciaEquinos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.ASININOS && (
              <VigilanciaAsininos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.MUARES && (
              <VigilanciaMuares form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.ABELHAS && (
              <VigilanciaAbelhas form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.HISTORICO_MORDEDURA && (
              <VigilanciaHistoricoMordedura
                form={form}
                formDisabled={formDisabled}
              />
            )}

            {steps[current].content === content.INVESTIGACAO_EPIDEMIOLOGICA && (
              <VigilanciaInvestigacaoEpidemiologica
                form={form}
                formDisabled={formDisabled}
              />
            )}

            {steps[current].content === content.OUTRAS_INFORMACOES && (
              <VigilanciaOutrasInformacoes
                form={form}
                formDisabled={formDisabled}
              />
            )}
          </fieldset>
        </div>
        <Divider />

        <Row justify={'end'} gutter={24}>
          {!params.local &&
            online &&
            mode === 'CREATE' &&
            current === steps.length - 1 &&
            !formDisabled && (
              <Col xs={24}>
                <Row justify={'end'}>
                  <Col>
                    <Form.Item>
                      <Switch
                        checked={shouldSaveOffline}
                        onChange={setShouldSaveOffline}
                      />
                      <span> Salvar offline</span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

          {current > 0 && (
            <Col xs={12}>
              <Button
                style={{ width: '100%', height: '40px' }}
                onClick={() => {
                  prev();
                  window.location.replace('#');
                }}
              >
                Anterior
              </Button>
            </Col>
          )}
          {current < steps.length - 1 && (
            <Col xs={12}>
              <Button
                type='primary'
                style={{ width: '100%', height: '40px' }}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      if (current === stateStep_0.step)
                        setStateStep_0({ step: 0, state: true });
                      else if (current === stateStep_1.step)
                        setStateStep_1({ step: 1, state: true });
                      else if (current === stateStep_2.step)
                        setStateStep_2({ step: 2, state: true });
                      else if (current === stateStep_3.step)
                        setStateStep_3({ step: 3, state: true });
                      else if (current === stateStep_4.step)
                        setStateStep_4({ step: 4, state: true });
                      else if (current === stateStep_5.step)
                        setStateStep_5({ step: 5, state: true });
                      else if (current === stateStep_6.step)
                        setStateStep_6({ step: 6, state: true });
                      else if (current === stateStep_7.step)
                        setStateStep_7({ step: 7, state: true });
                      else if (current === stateStep_8.step)
                        setStateStep_8({ step: 8, state: true });
                      else if (current === stateStep_9.step)
                        setStateStep_9({ step: 9, state: true });
                      else if (current === stateStep_10.step)
                        setStateStep_10({ step: 10, state: true });
                      else if (current === stateStep_11.step)
                        setStateStep_11({ step: 11, state: true });
                      else if (current === stateStep_12.step)
                        setStateStep_12({ step: 12, state: true });
                      else if (current === stateStep_13.step)
                        setStateStep_13({ step: 13, state: true });
                      else if (current === stateStep_14.step)
                        setStateStep_14({ step: 14, state: true });
                      else if (current === stateStep_15.step)
                        setStateStep_15({ step: 15, state: true });

                      next();
                      window.location.replace('#');
                    })
                    .catch(() => {
                      notification.error({
                        message:
                          'Alguns campos obrigatórios não foram preenchidos',
                        description: 'Por favor revise o formulário',
                      });
                    });
                }}
              >
                Próximo
              </Button>
            </Col>
          )}
          {current === steps.length - 1 && !formDisabled && (
            <Col xs={12}>
              <Button
                type='primary'
                htmlType={'submit'}
                block
                style={{ height: '40px' }}
              >
                Salvar
              </Button>
            </Col>
          )}
          {formDisabled && current === steps.length - 1 && (
            <Col xs={12} lg={12}>
              <Button
                style={{ width: '100%', height: '40px' }}
                htmlType={'submit'}
                type={'primary'}
                onClick={() => {
                  navigate(`/vigilancias/edicao/${params.id}`);
                }}
              >
                Editar
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <CustomModal
        open={fverNaoEncontrado}
        closable={true}
        maskClosable={false}
        keyboard={false}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        bodyStyle={{ padding: 0 }}
        footer={null}
        title='FVER não encontrado'
        width={'100%'}
        onCancel={() => navigate('/acoes-de-campo')}
      >
        <div
          style={{
            marginTop: '15px',
            marginLeft: '25px',
            marginRight: '25px',
          }}
        >
          <Typography.Paragraph style={{ textAlign: 'justify' }}>
            Este FVV não possui nenhum FVER vinculado. Por causa disso, será
            necessário selecionar um novo FVER.
          </Typography.Paragraph>

          <FilterPanelFVER open />

          <br />
          <Table<FVER.Summary>
            rowKey={'id'}
            dataSource={fvers?.content}
            size={'small'}
            loading={fetching}
            pagination={{
              current: query.page ? query.page + 1 : 1,
              onChange: (page, pageSize) => {
                dispatch(
                  FVERActions.setQuery({ page: page - 1, size: pageSize })
                );
              },
              total: fvers?.totalElements,
              pageSize: query.size,
            }}
            columns={[
              {
                dataIndex: 'numero',
                title: 'Número',
                width: 175,
                responsive: ['xs', 'sm'],
                render(numero) {
                  return <span style={{ fontWeight: 'bold' }}>{numero}</span>;
                },
              },
              {
                dataIndex: 'dataDaVisita',
                title: 'Data',
                width: 100,
                responsive: ['xs', 'sm'],
                render(data: FVER.Summary['dataDaVisita']) {
                  return moment(new Date(data)).format('DD/MM/YYYY');
                },
              },
              {
                dataIndex: 'tipoEstabelecimento',
                title: 'Tipo',
                width: 150,
                responsive: ['md'],
                render(tipo: FVER.Summary['tipoEstabelecimento']) {
                  return (
                    <Tag>{TipoEstabelecimento.valueOf(tipo).toString()}</Tag>
                  );
                },
              },
              {
                dataIndex: 'nomeEstabelecimento',
                title: 'Nome',
                responsive: ['sm'],
                render(nome: FVER.Summary['nomeEstabelecimento']) {
                  return <Tooltip title={nome}>{nome}</Tooltip>;
                },
              },
              {
                dataIndex: 'id',
                title: '',
                responsive: ['xs', 'sm'],
                width: 130,
                align: 'right',
                render(idVisita: number, fverSelected) {
                  return (
                    <Space>
                      <Button
                        icon={<SelectOutlined style={{ color: 'green' }} />}
                        size={'small'}
                        type={'ghost'}
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Deseja selecionar o FVER nº{' '}
                                {fverSelected.numero}
                              </>
                            ),
                            content: (
                              <Typography.Paragraph>
                                Esta ação não pode ser desfeita.
                              </Typography.Paragraph>
                            ),
                            onOk: async () => {
                              await FVERService.getById(fverSelected.id)
                                .then((fver) => {
                                  form.setFieldValue(
                                    'visitaPropriedadeRural',
                                    fver
                                  );
                                  setFVERNaoEncontrado(false);
                                  notification.success({
                                    message: (
                                      <>
                                        FVER nº {fver.numero} vinculado ao FVV
                                      </>
                                    ),
                                  });
                                })
                                .catch((e) => {
                                  notification.error({
                                    message: 'Erro ao buscar o FVER',
                                  });
                                });
                            },
                          });
                        }}
                      />
                    </Space>
                  );
                },
              },
            ]}
          ></Table>
        </div>
        <br />
      </CustomModal>
    </Spin>
  );
}
