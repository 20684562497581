export enum ModalidadeAcaoEducativa {
  HIBRIDA = 'Hibrida',
  PRESENCIAL = 'Presencial',
  REMOTA = 'Remota',
}

export namespace ModalidadeAcaoEducativa {
  export function keys(): string[] {
    return Object.keys(ModalidadeAcaoEducativa).filter(
      (key) => typeof ModalidadeAcaoEducativa[key as keyof typeof ModalidadeAcaoEducativa] === 'string'
    );
  }

  export function valueOf(key: string) {
    return ModalidadeAcaoEducativa[key as keyof typeof ModalidadeAcaoEducativa];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(ModalidadeAcaoEducativa).indexOf(
      value as ModalidadeAcaoEducativa
    );

    const key = Object.keys(ModalidadeAcaoEducativa)[indexOfS];

    return key;
  }
}
