import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EnvConfig from '../../app/EnvConfig';

interface LatestVersionAvaiableStateType {
  latestVersion: string | undefined;
}

const initialState: LatestVersionAvaiableStateType = {
  latestVersion: undefined,
};

const LatestVersionAvaiableStateSlice = createSlice({
  initialState,
  name: 'latestVersionAvaiableState',
  reducers: {
    setLatestVersion(state, action: PayloadAction<string>) {
      state.latestVersion = action.payload;
    },
  },
});

export const { setLatestVersion } = LatestVersionAvaiableStateSlice.actions;

const LatestVersionAvaiableStateReducer =
  LatestVersionAvaiableStateSlice.reducer;
export default LatestVersionAvaiableStateReducer;
