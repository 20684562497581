export enum ResultadoTesteRapido {
  POSITIVO = 'Positivo',
  NEGATIVO = 'Negativo',
  SUSPEITO = 'Suspeito',
}
export namespace ResultadoTesteRapido {
  export function keys(): string[] {
    return Object.keys(ResultadoTesteRapido).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return ResultadoTesteRapido[key as keyof typeof ResultadoTesteRapido];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(ResultadoTesteRapido).indexOf(
      value as unknown as ResultadoTesteRapido
    );

    const key = Object.keys(ResultadoTesteRapido)[indexOfS];

    return key;
  }
}
