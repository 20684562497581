import {
  Alert,
  Button,
  Card,
  Layout,
  Row,
  Space,
  Typography,
  notification,
} from 'antd';
import { useCallback, useEffect } from 'react';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import useLatestVersionAvaiableState from '../../../core/hooks/useLatestVersionAvaiableState';
import AuthorizationService from '../../../core/auth/Authorization.service';
import EnvConfig from '../../EnvConfig';
import { InfoCircleTwoTone } from '@ant-design/icons';
const { Content } = Layout;

interface DefaultlayoutContentProps {
  children: React.ReactNode;
}
export default function DefaultlayoutContent(props: DefaultlayoutContentProps) {
  const { online } = useNavigatorStatus();
  const { latestVersion, newVersionAvailable } =
    useLatestVersionAvaiableState();

  const updateSIZ = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) =>
        registration?.waiting?.postMessage({
          type: 'SKIP_WAITING',
        })
      );
      window.location.reload();
    } else {
      notification.error({
        message: 'Não é possível atualizar o SIZ manualmente',
      });
    }
  };

  const showNotificationUpdateSIZ = useCallback(() => {
    if (AuthorizationService.isAuthenticated() && newVersionAvailable())
      notification.info({
        message: (
          <Typography.Text>
            Versão <b>{latestVersion}</b> disponível
          </Typography.Text>
        ),
        description: 'Clique aqui para atualizar o SIZ',
        placement: 'bottom',
        duration: 0,
        onClick: updateSIZ,
        icon: <InfoCircleTwoTone />,
        closeIcon: <></>,
      });
  }, [latestVersion, newVersionAvailable]);

  useEffect(() => {}, [online]);

  useEffect(() => {
    showNotificationUpdateSIZ();
  }, [showNotificationUpdateSIZ]);

  return (
    <>
      <Content
        className='site-layout'
        style={{
          paddingBottom: '50px',
          zIndex: '0',
          width: '100%',
          maxWidth: '1024px',
          marginTop: '60px',
          padding: 24,
          margin: 'auto',
        }}
      >
        <>
          {/* {AuthorizationService.isAuthenticated() && newVersionAvailable() && (
            <Alert
              closable={false}
              message={
                <span style={{ fontWeight: 'bold' }}>
                  Nova versão disponível!
                </span>
              }
              description={
                <>
                  <Typography.Text>
                    A versão <b>{latestVersion}</b> está disponível.
                  </Typography.Text>
                  <Button onClick={updateSIZ} type='link'>
                    Clique aqui ara atualizar o SIZ
                  </Button>
                </>
              }
              showIcon
              style={{ marginBottom: '24px' }}
              type='info'
            />
          )} */}
          {props.children}
        </>
      </Content>
    </>
  );
}
