import { Col, Image, Row, Typography } from 'antd';

export default function HeaderGovernoMt() {
  return (
    <thead>
      <tr>
        <td>
          <div className='page-header-space' style={{ display: 'block' }}></div>
          <Row
            className='governoMT'
            align={'middle'}
            justify={'center'}
            gutter={6}
          >
            <Col>
              <Image width={60} src='/LOGO_MT.png' preview={false} />
            </Col>
            <Col>
              <Typography.Paragraph
                className='cabecalhoGovernoMT'
                style={{ padding: 0, margin: 0, marginBottom: -8 }}
              >
                Governo do Estado de Mato Grosso
              </Typography.Paragraph>
              <Typography.Paragraph
                className='cabecalhoGovernoMT'
                style={{ padding: 0, margin: 0, marginBottom: -8 }}
              >
                Secretária de Estado de Desenvolvimento Econômico
              </Typography.Paragraph>
              <Typography.Paragraph
                className='cabecalhoGovernoMT'
                style={{ padding: 0, margin: 0, marginBottom: -8 }}
              >
                Instituto de Defesa Agropecuária do Estado de Mato Grosso
              </Typography.Paragraph>
            </Col>
          </Row>

          <br />
        </td>
      </tr>
    </thead>
  );
}
