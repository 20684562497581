import { Card, Descriptions, Row, Typography } from 'antd';
import { FormCustom } from '../../../sdk/@types';
import HeaderGovernoMt from './HeaderGovernoMt.report';

type PrintFormCustomProps = {
  formCustom?: FormCustom.Response | null;
};
type PerguntaType =
  | 'MULTIPLA_ESCOLHA'
  | 'NUMERICO'
  | 'PARAGRAFO'
  | 'RESPOSTA_CURTA';

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintFormCustom(props: PrintFormCustomProps) {
  const formatarTipoPergunta = (tipoPergunta: PerguntaType): string => {
    const mapeamento: Record<PerguntaType, string> = {
      MULTIPLA_ESCOLHA: 'Múltipla Escolha',
      NUMERICO: 'Numérico',
      PARAGRAFO: 'Parágrafo',
      RESPOSTA_CURTA: 'Resposta Curta',
    };

    // Retorna o valor formatado ou o próprio tipoPergunta se não for encontrado no mapeamento
    return mapeamento[tipoPergunta] || tipoPergunta;
  };
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item) => (
                <Descriptions.Item
                  key={item.value}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                {props.formCustom?.nome}
              </Typography.Title>
            </Row>
            <br />
            {panelDescription('Vincular Município', [
              {
                value: props.formCustom?.municipio === true ? 'Sim' : 'Não',
              },
            ])}
            <br />
            {props.formCustom?.perguntas &&
              props.formCustom?.perguntas?.map((chave) =>
                panelDescription(`${chave.descricao}`, [
                  {
                    label: 'Tipo de Resposta',
                    value: formatarTipoPergunta(chave.tipoPergunta),
                  },
                  {
                    label: 'Ordem',
                    value: chave.ordem,
                    span: 2,
                  },
                ])
              )}
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
