import localforage from 'localforage';
import { useCallback, useState } from 'react';
import { FVV } from '../../sdk/@types';
import { SYNC_VIGILANCIA_NEEDED } from '../../sdk/@types/ServiceWorker.types';
import FVVIDBService from '../../sdk/services/indexeddb/FVVIDB.service';
import FVVService from '../../sdk/services/SIZ-API/FVV.service';
import AuthorizationService from '../auth/Authorization.service';
import generateHash from '../functions/generateHash';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import useNavigatorStatus from './useNavigatorStatus';

export default function useFVV() {
  const { online } = useNavigatorStatus();
  const [fvv, setFVV] = useState<FVV.Input>();
  const [fvvs, setFVVs] = useState<FVV.Paginated>();
  const [query, setQuery] = useState<FVV.Query>({
    page: 0,
    size: 10,
  });

  const fetchFVVs = useCallback(() => {
    return FVVService.getAll(query).then(setFVVs);
  }, [query]);

  const fetchById = useCallback((id: number) => {
    return FVVService.getById(id).then(setFVV);
  }, []);

  const fetchByNumero = useCallback((id: number) => {
    return FVVService.getById(id).then(setFVV);
  }, []);

  const insert = useCallback(
    (fvv: FVV.Input) => {
      fvv.codigoVerificador = generateHash(fvv);

      if (online) {
        return FVVService.insert(fvv);
      } else {
        if (navigator.serviceWorker)
          navigator.serviceWorker.ready.then((swRegistration) => {
            //@ts-ignore
            if (swRegistration.sync) {
              localforage.setItem(
                'token',
                AuthorizationService.getAccessToken()
              );
              navigator.serviceWorker.ready.then((registration) => {
                //@ts-ignore
                registration.sync.register(SYNC_VIGILANCIA_NEEDED);
              });
            } else {
            }
          });
        return FVVIDBService.insert(fvv);
      }
    },
    [online]
  );

  const updateFVVOffline = useCallback(async (id: number, fvv: FVV.Input) => {
    const codigoVerificador = generateHash(fvv);
    let newFVV: ServiceIDBPayloadInput = {
      id: id,
      payload: {
        ...fvv,
        codigoVerificador,
      },
      status: 'NOVO',
      date: new Date(),
    };

    return await FVVIDBService.update(id, newFVV);
  }, []);

  return {
    fetchFVVs,
    fetchById,
    fetchByNumero,
    insert,
    query,
    setQuery,
    updateFVVOffline,
    fvv,
    fvvs,
  };
}
