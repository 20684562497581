import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import EditTFR from '../features/FormTFR/EditTFR';

export default function TFREditView() {
  usePageTitle('Editando TFR');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <EditTFR />
    </>
  );
}
