import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import AuthorizationService from '../../core/auth/Authorization.service';
import { NotificacaoSistema } from '../../sdk/@types/NotificacaoSistema';
import NotificacaoSistemaService from '../../sdk/services/SIZ-API/NotificacaoSistema.service';
import { CustomModal } from '../components/CustomModal';
import useDevOptions from '../../core/hooks/useDevOptions';
import useBadgeState from '../../core/hooks/useBadgeState';

type NotificacoesDoUsuario = {
  lida: boolean;
} & NotificacaoSistema.Response;

export default function NotificacoesSistema() {
  const { idInTables } = useDevOptions();

  const [notificacoesDoUsuario, setNotificacoesDoUsuario] =
    useState<NotificacoesDoUsuario[]>();
  const [showModalNotificacao, setShowModalNotificacao] = useState(false);
  const [notificacaoModal, setNotificacaoModal] =
    useState<NotificacoesDoUsuario>();
  const { notificacoes, setNotificacoes } = useBadgeState();

  const markAsRead = useCallback(
    async (notificacaoSistema: NotificacaoSistema.Response) => {
      const usuarioSIZ = AuthorizationService.getUsuarioSIZ();
      if (usuarioSIZ) {
        return await NotificacaoSistemaService.markAsRead(
          usuarioSIZ.userName,
          notificacaoSistema.id
        )
          .then(() => {
            setNotificacoes(notificacoes - 1);
          })
          .catch((e) => console.log(e));
      }
    },
    [notificacoes, setNotificacoes]
  );

  const fetchNotificacoesLidas = async () => {
    const usuarioSIZ = AuthorizationService.getUsuarioSIZ();
    if (usuarioSIZ) {
      return await NotificacaoSistemaService.getByUsuario(usuarioSIZ.userName);
    }
  };

  const fetchTodasAsNotificacies = async () => {
    return await NotificacaoSistemaService.getAll();
  };

  const populateTableNotificacoes = useCallback(async () => {
    let notificacoesDoUsuario: NotificacoesDoUsuario[] = [];

    const todasAsNotificacoes = await fetchTodasAsNotificacies();
    const notificacoesLidas = await fetchNotificacoesLidas();

    todasAsNotificacoes.forEach((notificacao) => {
      if (
        notificacoesLidas?.find(
          (notificacaoLida) => notificacao.id === notificacaoLida.id
        )
      ) {
        notificacoesDoUsuario.push({
          lida: true,
          ...notificacao,
        });
      } else {
        notificacoesDoUsuario.push({
          lida: false,
          ...notificacao,
        });
      }
    });

    notificacoesDoUsuario.sort((n1, n2) => {
      if (n1.id > n2.id) return -1;
      if (n1.id < n2.id) return 1;

      return 0;
    });

    setNotificacoesDoUsuario(notificacoesDoUsuario);
  }, []);

  useEffect(() => {
    populateTableNotificacoes();
  }, [populateTableNotificacoes]);

  useEffect(() => {}, [notificacoesDoUsuario]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Table<NotificacoesDoUsuario>
            dataSource={notificacoesDoUsuario}
            rowKey={'id'}
            size={'small'}
            showHeader={false}
            title={() => {
              return (
                <Typography.Title level={5} style={{ color: 'white' }}>
                  Notificações
                </Typography.Title>
              );
            }}
            columns={[
              {
                dataIndex: 'id',
                width: idInTables ? 100 : 0,
                render(id) {
                  return (
                    idInTables && (
                      <Typography.Text>{`ID: ${id}`}</Typography.Text>
                    )
                  );
                },
              },
              {
                dataIndex: 'titulo',
                title: 'Título',
                width: '50%',
                render(titulo, notificacao) {
                  if (notificacao.lida)
                    return (
                      <>
                        <span style={{ fontWeight: 'bold' }}>{titulo}</span>{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </>
                    );
                  else return titulo;
                },
              },
              {
                dataIndex: 'dataCadastro',
                title: 'Data',
                render(date: string) {
                  return moment(date).format('DD/MM/YYYY');
                },
              },

              {
                dataIndex: 'lida',
                title: 'Lida',
                width: '100px',
                render(lida) {
                  return !lida ? '' : 'Não lida';
                },
              },
              {
                dataIndex: 'id',
                title: '',
                width: '60px',
                render(id) {
                  return <Button icon={<SearchOutlined />} />;
                },
              },
            ]}
            onRow={(record, index) => {
              return {
                onClick: (e) => {
                  setShowModalNotificacao(true);
                  setNotificacaoModal(record);
                },
              };
            }}
          />
        </Col>
      </Row>

      <CustomModal
        open={showModalNotificacao}
        title={notificacaoModal?.titulo}
        closable={false}
        maskClosable
        onCancel={async () => {
          if (notificacaoModal?.lida) {
            //@ts-ignore
            await markAsRead(notificacaoModal).then(populateTableNotificacoes);
          }
          setShowModalNotificacao(false);
        }}
        footer={
          <Button
            style={{ width: '100%' }}
            type='default'
            icon={<CloseOutlined />}
            onClick={async () => {
              if (notificacaoModal?.lida) {
                //@ts-ignore
                await markAsRead(notificacaoModal).then(
                  populateTableNotificacoes
                );
              }
              setShowModalNotificacao(false);
            }}
          >
            Fechar
          </Button>
        }
      >
        {notificacaoModal &&
          notificacaoModal.descricao.split('#').map((notificacao) => {
            return (
              <Typography style={{ marginBottom: '10px' }}>
                ● {notificacao}
              </Typography>
            );
          })}
      </CustomModal>
    </>
  );
}
