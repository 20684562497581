import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditTwoTone,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SYNC_VIGILANCIA_DONE } from '../../../sdk/@types/ServiceWorker.types';
import { ServiceIDBPayloadInput } from '../../../sdk/services/indexeddb/ServiceIDB';
import FVVIDBService from '../../../sdk/services/indexeddb/FVVIDB.service';
import useSyncingState from '../../../core/hooks/useSyncingState';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import syncFVVs from '../../../core/functions/syncFVVs';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import TroubleshootingButton from '../../components/TroubleshootingButton';
import { CustomModal } from '../../components/CustomModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ButtonToForm from '../../components/ButtonToForm';
import useDevOptions from '../../../core/hooks/useDevOptions';

interface ListFVVOfflineProps {
  visible?: boolean;
}

type FVVComErro = {
  erro: boolean;
} & ServiceIDBPayloadInput;

export default function ListFVVOffline(props: ListFVVOfflineProps) {
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();

  const navigate = useNavigate();
  const { online } = useNavigatorStatus();

  const [listaFVVsComErro, setlistaFVVsComErro] = useState<FVVComErro[]>();

  const [fetching, setFetching] = useState(false);
  const [syncFVVDone, setSyncFVVDone] = useState<string | undefined>(undefined);

  const [showModalMotivoErro, setShowModalMotivoErro] =
    useState<boolean>(false);
  const [showModalDeleteFVVs, setShowModalDeleteFVV] = useState<boolean>(false);
  const [motivoErro, setMotivoErro] = useState<string>();

  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, fvv: FVVComErro) => {
    setExpandedKey((prev: any) => {
      const newKey = fvv.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };
  //end control expandedKeys

  const { fvv, setFvv } = useSyncingState();
  const [, setDeleteOpen] = useState(false);

  if (navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === SYNC_VIGILANCIA_DONE) {
        fetchListFVVsOffline();
        setSyncFVVDone(event.data.message);
      }
    };

  const fetchListFVVsOffline = useCallback(async () => {
    await FVVIDBService.getAll()
      .then(async (listFVVOffline) => {
        let newList = [];
        let newFVV;
        let quantidadeFVVsSincronizados = 0;
        let quantidadeFVVsdeRecinto = 0;

        for (const fvv of listFVVOffline) {
          if (fvv.status === 'SINCRONIZADO') quantidadeFVVsSincronizados++;

          if (
            fvv.payload.visitaPropriedadeRural.tipoEstabelecimento !==
            'PROPRIEDADE'
          ) {
            quantidadeFVVsdeRecinto++;
            if (fvv.id) {
              await FVVIDBService.delete(fvv.id);
            }
          } else {
            if (!fvv.payload.visitaPropriedadeRural.codigoVerificador) {
              newFVV = { ...fvv, erro: true };
            } else {
              newFVV =
                await FVERIDBService.getAllFromInputTableOnlyPayload().then(
                  async (lista) => {
                    const fverLocal = lista.filter(
                      (fver) =>
                        fver.codigoVerificador ===
                        fvv.payload.visitaPropriedadeRural.codigoVerificador
                    );
                    if (fverLocal.length > 0) return { ...fvv, erro: false };
                    else {
                      if (online) {
                        return await FVERService.getByCodigoVerificador(
                          fvv.payload.visitaPropriedadeRural.codigoVerificador
                          //'bd69c284cefd8b9b2e5ba145be5e65f67750df07'
                        )
                          .then(async (fver) => {
                            if (fver) {
                              if (fver.content && fver.content?.length > 0) {
                                return { ...fvv, erro: false };
                              } else return { ...fvv, erro: true };
                            } else return { ...fvv, erro: true };
                          })
                          .catch((e) => ({ ...fvv, erro: true }));
                      } else {
                        return { ...fvv, erro: true };
                      }
                    }
                  }
                );
            }

            newList.push(newFVV);
          }
        }

        if (quantidadeFVVsSincronizados >= 10) {
          setShowModalDeleteFVV(true);
        }
        if (quantidadeFVVsdeRecinto > 0) {
          notification.info({
            message:
              'Identificamos FVVs criados a partir de Recintos ou Abatedouros e eles foram excluídos automaticamente',
            description:
              'Qualquer dúvida sobre este procedimento, por favor consulte a CDSA',
          });
        }
        return newList;
      })
      .then(async (lista) => {
        setlistaFVVsComErro(lista);
        setFetching(false);
      })
      .catch((e) => {
        notification.error({
          message: 'Houve um erro ao exibir os FVV',
          description: `Motivo: ${e.message}`,
        });
        setlistaFVVsComErro(undefined);
      })
      .finally(() => setFetching(false));
  }, [online]);

  const deleteFVV = useCallback(
    (a1) => {
      if (a1) FVVIDBService.delete(a1).then(fetchListFVVsOffline);
    },
    [fetchListFVVsOffline]
  );

  const deleteSynced = useCallback(async () => {
    const listFVVsSynced = listaFVVsComErro?.filter(
      (fvv) => fvv.status === 'SINCRONIZADO'
    );

    const listFVVsNotSynced = listaFVVsComErro?.filter(
      (fvv) => fvv.status !== 'SINCRONIZADO'
    );

    if (listFVVsSynced)
      for (const fvv of listFVVsSynced) {
        //@ts-ignore
        await FVVIDBService.delete(fvv.id);
      }

    setlistaFVVsComErro(listFVVsNotSynced);
    setDeleteOpen(false);
    notification.success({
      message: 'Todas os FVVs sincronizados foram removidos',
    });
  }, [listaFVVsComErro]);

  useEffect(() => {}, [syncFVVDone]);

  useEffect(() => {
    setFetching(false);

    if (listaFVVsComErro)
      for (const fvv of listaFVVsComErro) {
        if (fvv.status === 'SINCRONIZADO') setDeleteOpen(true);
      }
  }, [listaFVVsComErro]);

  useEffect(() => {
    fetchListFVVsOffline();
  }, [fetchListFVVsOffline, fvv]);

  return (
    <>
      <Row>
        <Space size={4} direction='vertical' style={{ width: '100%' }}>
          <Col hidden={!syncFVVDone} xs={24}>
            <Alert
              message={syncFVVDone}
              type='info'
              showIcon
              closable={false}
            ></Alert>
          </Col>
          <Col span={24}>
            <Table
              columns={[
                {
                  dataIndex: 'id',
                  width: idInTables ? 100 : 0,
                  render(_, fvvIdb) {
                    return (
                      idInTables && (
                        <>
                          <Typography.Text>
                            {`IDB: ${fvvIdb.id}`}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {`ID: ${
                              fvvIdb.payload.id ? fvvIdb.payload.id : '-'
                            }`}
                          </Typography.Text>
                        </>
                      )
                    );
                  },
                },
                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 800,
                  title: 'Detalhes',
                  render(_, fvvIdb) {
                    return (
                      <Space direction='vertical' size={0}>
                        <Space direction='horizontal' size={8} wrap={xs}>
                          {fvvIdb.payload.numero && (
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{
                                    backgroundColor: 'rgba(131, 239, 156, 0.5)',
                                    borderRadius: '5px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                  }}
                                />
                              }
                              onClick={() =>
                                navigate(
                                  `/vigilancias/edicao/${fvvIdb.payload?.id}`
                                )
                              }
                              style={{ fontWeight: 'bold', paddingLeft: 0 }}
                              type='text'
                            >
                              {fvvIdb.payload.numero}
                            </Button>
                          )}
                          <Tag
                            style={{
                              backgroundColor: `${
                                fvvIdb.status === 'NOVO'
                                  ? '#09f'
                                  : fvvIdb.status === 'SINCRONIZADO'
                                  ? '#2baf57'
                                  : '#ff4e4e'
                              }`,
                              color: 'white',
                            }}
                          >
                            {fvvIdb.status}
                          </Tag>

                          {fvvIdb.status === 'ERRO' && (
                            <Button
                              danger
                              onClick={() => {
                                setShowModalMotivoErro(true);
                                setMotivoErro(fvvIdb.motivoErro);
                              }}
                              style={{ paddingLeft: 0 }}
                              type='link'
                            >
                              <span style={{ borderBottom: '1px solid' }}>
                                Detalhes
                              </span>
                            </Button>
                          )}
                        </Space>

                        <Space
                          direction='horizontal'
                          size={24}
                          style={{ fontSize: '0.8em' }}
                          wrap={sm || xs}
                        >
                          <Typography.Text>
                            {moment(
                              new Date(fvvIdb.payload.dataVigilancia)
                            ).format('DD/MM/YYYY HH:mm')}
                          </Typography.Text>

                          {fvvIdb.payload.visitaPropriedadeRural
                            ?.propriedade && (
                            <Typography>
                              {`${fvvIdb.payload.visitaPropriedadeRural?.propriedade.id} - ${fvvIdb.payload.visitaPropriedadeRural?.propriedade.nome}`}
                            </Typography>
                          )}
                        </Space>
                      </Space>
                    );
                  },
                },
                {
                  dataIndex: 'id',
                  responsive: ['xs', 'sm'],
                  width: 80,
                  align: 'right',
                  render(id, fvv) {
                    return (
                      <>
                        <Space>
                          <Button
                            disabled={fvv.status === 'SINCRONIZADO'}
                            icon={<EditTwoTone twoToneColor={'#84aee6'} />}
                            onClick={() =>
                              navigate(`/vigilancias/edicao/${id}/${'local'}`)
                            }
                            size={'small'}
                            type={'ghost'}
                          />

                          <Popconfirm
                            title={'Deseja remover o FVV?'}
                            onConfirm={() => {
                              try {
                                deleteFVV(id);
                                notification.success({
                                  message: 'FVV removida com sucesso',
                                });
                              } catch (e) {
                                throw e;
                              }
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>
                        </Space>
                      </>
                    );
                  },
                },
              ]}
              dataSource={listaFVVsComErro}
              expandable={{
                columnWidth: '50px',
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <MinusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ) : (
                    <PlusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                      style={{ fontSize: '24px' }}
                    />
                  ),
                expandRowByClick: true,
                expandedRowKeys: [expandedKey],
                expandedRowRender: (fvvIdb) => (
                  <Space style={{ fontSize: '0.8em' }}>
                    {fvvIdb.erro && (
                      <Typography>
                        FVV sem FVER{' '}
                        {
                          <ButtonToForm
                            alternativeBackgroundColor='rgb(222, 80, 80)'
                            alternateIcon={<WarningFilled />}
                            label='Solucionar'
                            route={`/vigilancias/edicao/${
                              fvvIdb.id
                            }/${'local'}/${'problem'}`}
                            type='INCLUDE'
                          />
                        }
                      </Typography>
                    )}
                    {!fvvIdb.erro && (
                      <Typography.Text>
                        {fvvIdb.payload.visitaPropriedadeRural?.id === -1 ? (
                          <ButtonToForm
                            alternativeBackgroundColor='rgba(111, 239, 253, 0.5)'
                            label={'FVER - Pendente'}
                            route={`/visitas/edicao/${fvvIdb.id}/local`}
                            type='VIEW'
                          />
                        ) : (
                          <ButtonToForm
                            label={`FVER - ${fvvIdb.payload.visitaPropriedadeRural?.numero}`}
                            route={`/visitas/visualizar/${fvvIdb.payload.visitaPropriedadeRural?.id}`}
                            type='VIEW'
                          />
                        )}
                      </Typography.Text>
                    )}
                  </Space>
                ),
                onExpand: onExpand,
                expandedRowClassName: () => 'expanded-row-siz',
              }}
              loading={fvv || fetching}
              size={'small'}
              rowKey={'id'}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      FVVs
                    </Typography.Title>

                    <Space>
                      <Popover
                        arrowPointAtCenter={false}
                        content={
                          <div
                            style={{ maxWidth: '350px', textAlign: 'justify' }}
                          >
                            <Typography.Text>
                              Os dados das visitas com o status{' '}
                              <Tag
                                style={{
                                  backgroundColor: '#2baf57',
                                  color: 'white',
                                }}
                              >
                                SINCRONIZADO
                              </Tag>{' '}
                              já se encontram no servidor. Esta operação irá
                              remover apenas os registros no dispositivo, sem
                              perda de dados das atividades realizadas.
                            </Typography.Text>
                          </div>
                        }
                        title={
                          <Row justify='space-between'>
                            <span style={{ fontWeight: 'bold' }}>
                              Remover FVVs sincronizados
                            </span>
                            <Button
                              icon={<CloseOutlined />}
                              size={'small'}
                              type={'text'}
                              onClick={() => {
                                setDeleteOpen(false);
                              }}
                            />
                          </Row>
                        }
                        open={false}
                      >
                        <Popconfirm
                          title={'Deseja remover todos os sincronizados??'}
                          onConfirm={deleteSynced}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'primary'}
                            danger
                          />
                        </Popconfirm>
                      </Popover>

                      <Popconfirm
                        title={'Confirma a sincronização?'}
                        disabled={
                          !listaFVVsComErro ||
                          listaFVVsComErro.length === 0 ||
                          !online
                        }
                        onConfirm={async () => {
                          setFvv(true);
                          await syncFVVs(true)
                            .then(async (message) => {
                              notification.success({
                                message: message,
                              });
                              await fetchListFVVsOffline();
                            })
                            .catch((e) => {
                              notification.error({
                                message: 'Não foi possível sincronizar',
                                description: e,
                              });
                            })
                            .finally(() => {
                              setFvv(false);
                              navigate(`/acoes-de-campo`);
                            });
                        }}
                      >
                        <Button
                          icon={<CloudUploadOutlined />}
                          type={'primary'}
                          disabled={
                            !listaFVVsComErro ||
                            listaFVVsComErro.length === 0 ||
                            !online
                          }
                        />
                      </Popconfirm>

                      <TroubleshootingButton
                        component='FVV'
                        data={listaFVVsComErro}
                      />
                    </Space>
                  </Row>
                );
              }}
              showHeader={false}
            />
          </Col>
        </Space>
      </Row>

      <CustomModal
        open={showModalMotivoErro}
        title={'Motivo'}
        footer={
          <Button
            style={{ width: '100%' }}
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => {
              setShowModalMotivoErro(false);
              setMotivoErro(undefined);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          {motivoErro}
        </Typography.Title>
      </CustomModal>

      <CustomModal
        open={showModalDeleteFVVs}
        title={'Aviso'}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '100%' }}
              type='default'
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalDeleteFVV(false);
              }}
            >
              Fechar
            </Button>

            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<CloseOutlined />}
              onClick={async () => {
                await deleteSynced().finally(() =>
                  setShowModalDeleteFVV(false)
                );
              }}
            >
              Remover
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>
          Notamos que você ainda está mantendo no seu dispositivo muitos FVVs
          que já foram sincronizados.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Deseja removê-los? Essa ação não irá afetar os FVVs no banco de dados.
        </Typography.Paragraph>
      </CustomModal>
    </>
  );
}
