import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Affix, Button, Col, Row, Spin, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../core/store';
import { fetchFormCustomById } from '../../../core/store/FormCustom.slice';

import PrintFormCustom from '../reports/PrintFormCustom';

export default function FormCustomPrint() {
  usePageTitle('FormCustom');
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const { loading, setLoading } = useLoadingPage();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();

  const params = useParams<{ id: string }>();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });
  const FormCustom = useSelector((state: RootState) => state.formCustom.formCustomData);

  useEffect(() => {}, [params]);

  useEffect(() => {
    const formId = Number(params.id);
    if (!isNaN(formId)) {
      dispatch(fetchFormCustomById(formId))
        .unwrap()
        .catch((error) => {
          notification.error({
            message: 'Erro ao buscar FormCustom',
            description: error,
          });
        });
    }
  }, [params.id, dispatch]);

  if (error) throw error;

  return (
    <Spin
      size='large'
      spinning={loading}
      style={{
        position: 'fixed',
        top: '20%',
        zIndex: '1',
      }}
    >
      <div
        style={{
          maxWidth: '90%',
          margin: 'auto',
        }}
      >
        <Affix offsetTop={80} className='printButton'>
          <Row gutter={12}>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  navigate('/formCustom/visualizar');
                }}
              >
                Voltar
              </Button>
            </Col>
            <Col>
              <Button
                className='top'
                type='primary'
                onClick={() => {
                  //window.print();
                  reactPrint();
                }}
                icon={<PrinterOutlined />}
              >
                Imprimir
              </Button>
            </Col>
          </Row>
        </Affix>
        <div ref={printRef}>
          <table
            className='tablePrintFormulario'
            style={{
              tableLayout: 'fixed',
              width: '100%',
            }}
          >
            <PrintFormCustom formCustom={FormCustom} />
          </table>
        </div>
      </div>
    </Spin>
  );
}
