export enum SistemaDeCriacaoDeRuminantes {
  EXTENSIVO = 'Extensivo',
  SEMI_INTENSIVO = 'Intensivo/Semi-intensivo',
  CONFINAMENTO = 'Confinamento',
}
export namespace SistemaDeCriacaoDeRuminantes {
  export function keys(): string[] {
    return Object.keys(SistemaDeCriacaoDeRuminantes).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return SistemaDeCriacaoDeRuminantes[
      key as keyof typeof SistemaDeCriacaoDeRuminantes
    ];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(SistemaDeCriacaoDeRuminantes).indexOf(
      value as unknown as SistemaDeCriacaoDeRuminantes
    );

    const key = Object.keys(SistemaDeCriacaoDeRuminantes)[indexOfS];

    return key;
  }
}
