import { Divider, Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormRespostas from './FormRespostas';
import { useEffect } from 'react';

export default function CreateFormCustom() {
  const { loading } = useLoadingPage();

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('CREATE');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <Divider orientation='left'>
        <Typography.Title level={3}>
          Novo Formulário Customizado
        </Typography.Title>
      </Divider>
      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormRespostas />
      </Spin>
    </>
  );
}
