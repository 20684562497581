import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import TFRExternalView from '../features/FormTFR/TFRExternalView';

export default function TFRExternalViewWrapper() {
  usePageTitle('Termo de Fiscalização');

  return (
    <>
      <ErrorBoundary component='termo de fiscalização'>
        <TFRExternalView />
      </ErrorBoundary>
    </>
  );
}
