import { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { FaseExploracaoBovinosEBubalinos } from '../../../core/enums/FaseExploracaoBovinosEBubalinos';
import { IdadeRuminantesAlimentadosComRacao } from '../../../core/enums/IdadeRuminantesAlimentadosComRacao';
import { FinalidadeExploracaoBovinosEBubalinos } from '../../../core/enums/FinalidadeExploracaoBovinosEBubalinos';
import { SistemaDeCriacaoDeRuminantes } from '../../../core/enums/SistemaDeCriacaoDeRuminantes';
import { TipoAlimentacaoRuminantes } from '../../../core/enums/TipoAlimentacaoRuminantes';
import { EpocaDoAnoSuplementacao } from '../../../core/enums/EpocaDoAnoSuplementacao';
import { TipoExploracaoAvicolaSistemaIndustrial } from '../../../core/enums/TipoExploracaoAvicolaSistemaIndustrial';
import { TipoExploracaoSuinosSistemaIndustrial } from '../../../core/enums/TipoExploracaoSuinosSistemaIndustrial';
import { AtivaPassiva } from '../../../core/enums/AtivaPassiva';
import { ResultadoTesteRapido } from '../../../core/enums/ResultadoTesteRapido';
import { ChavePrincipalFVER, FVER } from '../../../sdk/@types';
import TextArea from 'antd/lib/input/TextArea';
import { CustomModal } from '../../components/CustomModal';
import { useParams } from 'react-router';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import { SimNao } from '../../../core/enums/SimNao';

type VigilanciaInvestigacaoDeAlimentosProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaInvestigacaoDeAlimentos(
  props: VigilanciaInvestigacaoDeAlimentosProps
) {
  const params = useParams<{ id: string; local?: string; problem?: string }>();
  const [showAlertNoTotalDeRuminantes, setShowAlertNoTotalDeRuminantes] =
    useState(false);
  const ID_PREVENÇÃO_E_CONTROLE_DAS_ENCEFALOPATIAS_ESPONGIFORMES_TRANSMISSÍVEIS: number = 6;

  const [
    statusHouveInvestigacaoDeAlimentos,
    setStatusHouveInvestigacaoDeAlimentos,
  ] = useState<boolean | undefined>(undefined);

  const [
    statusRadioPresencaDeCamaDeAviario,
    setStatusRadioPresencaDeCamaDeAviario,
  ] = useState<boolean | undefined>();
  const [
    statusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes,
    setStatusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes,
  ] = useState<boolean | undefined>();
  const [
    statusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao,
    setStatusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao,
  ] = useState<boolean | undefined>();
  const [
    statusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante,
    setStatusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante,
  ] = useState<boolean | undefined>();
  const [
    statusRadioColheitaDeAmostraDeAlimentosDeRuminantes,
    setStatusRadioColheitaDeAmostraDeAlimentosDeRuminantes,
  ] = useState<boolean | undefined>();
  const [statusRadioUsoTesteRapido, setStatusRadioUsoTesteRapido] = useState<
    boolean | undefined
  >();

  const listaFaseExploracaoBovinosEBubalinos =
    FaseExploracaoBovinosEBubalinos.keys();
  const listaIdadeRuminantesAlimentadosComRacaoBovinos =
    IdadeRuminantesAlimentadosComRacao.idadeRuminantesAlimentadosComRacao_Bovinos();
  const listaIdadeRuminantesAlimentadosComRacaoOvinosCaprinos =
    IdadeRuminantesAlimentadosComRacao.idadeRuminantesAlimentadosComRacao_Ovinos_Caprinos();
  const listaFinalidadeExploracaoBovinosEBubalinos =
    FinalidadeExploracaoBovinosEBubalinos.keys();
  const listaSistemaDeCriacaoDeRuminantes = SistemaDeCriacaoDeRuminantes.keys();
  const listaTipoAlimentacaoRuminantes = TipoAlimentacaoRuminantes.keys();
  const listaEpocaAnoSuplementacao = EpocaDoAnoSuplementacao.keys();
  const listaTipoExploracaoAvicolaSistemaIndustrial =
    TipoExploracaoAvicolaSistemaIndustrial.keys();
  const listaTipoExploracaoSuinosSistemaIndustrial =
    TipoExploracaoSuinosSistemaIndustrial.keys();
  const listaAtivaPassiva = AtivaPassiva.keys();
  const listaResultadoTesteRapido = ResultadoTesteRapido.keys();

  const [showModalInforme, setShowModalInforme] = useState(false);

  useEffect(() => {}, [showAlertNoTotalDeRuminantes]);

  useEffect(() => {
    console.log(
      'vigilanciaAlimentosRuminantes',
      props.form.getFieldValue('vigilanciaAlimentosRuminantes')
    );

    console.log(
      'vigilanciaAlimentosRuminantes, houveInvestigacao',
      props.form.getFieldValue([
        'vigilanciaAlimentosRuminantes',
        'houveInvestigacao',
      ])
    );

    if (!props.form.getFieldValue('vigilanciaAlimentosRuminantes')) {
    } else {
      setStatusHouveInvestigacaoDeAlimentos(
        props.form.getFieldValue([
          'vigilanciaAlimentosRuminantes',
          'houveInvestigacao',
        ])
      );
    }
  }, [props.form]);

  const handleChangeStatusRadioPresencaDeCamaDeAviario = useCallback(
    (status: boolean | undefined) => {
      let simNao;

      if (status === undefined) {
        simNao = undefined;
      } else if (status) {
        simNao = SimNao.keyOf(SimNao.SIM);
      } else {
        simNao = SimNao.keyOf(SimNao.NAO);
      }

      setStatusRadioPresencaDeCamaDeAviario(status);

      props.form.setFieldValue(
        ['vigilanciaAlimentosRuminantes', 'presencaDeCamaDeAviario'],
        simNao
      );
    },
    [props.form]
  );

  const handleChangeStatusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes =
    useCallback(
      (status: boolean | undefined) => {
        let simNao;

        if (status === undefined) {
          simNao = undefined;
        } else if (status) {
          simNao = SimNao.keyOf(SimNao.SIM);
        } else {
          simNao = SimNao.keyOf(SimNao.NAO);
        }

        setStatusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes(
          status
        );

        props.form.setFieldValue(
          [
            'vigilanciaAlimentosRuminantes',
            'utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes',
          ],
          simNao
        );
      },
      [props.form]
    );

  const handleChangeStatusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao =
    useCallback(
      (status: boolean | undefined) => {
        let simNao;

        if (status === undefined) {
          simNao = undefined;
        } else if (status) {
          simNao = SimNao.keyOf(SimNao.SIM);
        } else {
          simNao = SimNao.keyOf(SimNao.NAO);
        }

        setStatusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao(status);

        props.form.setFieldValue(
          [
            'vigilanciaAlimentosRuminantes',
            'pisciculturaComSistemaDeAlimentacaoABaseDeRacao',
          ],
          simNao
        );
      },
      [props.form]
    );

  const handleChangeStatusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante =
    useCallback(
      (status: boolean | undefined) => {
        let simNao;

        if (status === undefined) {
          simNao = undefined;
        } else if (status) {
          simNao = SimNao.keyOf(SimNao.SIM);
        } else {
          simNao = SimNao.keyOf(SimNao.NAO);
        }

        setStatusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante(status);

        props.form.setFieldValue(
          [
            'vigilanciaAlimentosRuminantes',
            'contaminacaoCruzadaDeRacoesDePeixeERuminante',
          ],
          simNao
        );
      },
      [props.form]
    );

  const handleChangeStatusRadioColheitaDeAmostraDeAlimentosDeRuminantes =
    useCallback(
      (status: boolean | undefined) => {
        let simNao;

        if (status === undefined) {
          simNao = undefined;
        } else if (status) {
          simNao = SimNao.keyOf(SimNao.SIM);
        } else {
          simNao = SimNao.keyOf(SimNao.NAO);
        }

        setStatusRadioColheitaDeAmostraDeAlimentosDeRuminantes(status);

        props.form.setFieldValue(
          [
            'vigilanciaAlimentosRuminantes',
            'colheitaDeAmostraDeAlimentosDeRuminantes',
          ],
          simNao
        );
      },
      [props.form]
    );

  const handleChangeStatusRadioUsoTesteRapido = useCallback(
    (status: boolean | undefined) => {
      let simNao;

      if (status === undefined) {
        simNao = undefined;
      } else if (status) {
        simNao = SimNao.keyOf(SimNao.SIM);
      } else {
        simNao = SimNao.keyOf(SimNao.NAO);
      }

      setStatusRadioUsoTesteRapido(status);

      props.form.setFieldValue(
        ['vigilanciaAlimentosRuminantes', 'usoTesteRapido'],
        simNao
      );
    },
    [props.form]
  );

  const isInvestigacaoDeAlimentosLiberada = () => {
    const chavesVisita: ChavePrincipalFVER.Input[] = props.form.getFieldValue([
      'visitaPropriedadeRural',
      'listChavePrincipalVisitaPropriedadeRural',
    ]);

    let result = false;
    chavesVisita.forEach((chave) => {
      if (
        Number(chave.tipoChavePrincipalVisitaPropriedadeRural.id) ===
        ID_PREVENÇÃO_E_CONTROLE_DAS_ENCEFALOPATIAS_ESPONGIFORMES_TRANSMISSÍVEIS
      )
        result = true;
    });

    return result;
  };

  const updateFVER = async () => {
    await FVERIDBService.getByIdFromInput_Table(Number(params.id)).then(
      async (fver: FVER.Input) => {
        const newListChavePrincipalVisitaPropriedadeRural =
          fver.listChavePrincipalVisitaPropriedadeRural;
        newListChavePrincipalVisitaPropriedadeRural.push({
          motivoPrincipal: 'NAO',
          tipoChavePrincipalVisitaPropriedadeRural: {
            id: 6,
            nome: 'PREVENÇÃO E VIGILÂNCIA DAS ENCEFALOPATIAS ESPONGIFORMES TRANSMISSÍVEIS',
          },
          listChaveSecundariaVisitaPropriedadeRural: [
            {
              tipoChaveSecundariaVisitaPropriedadeRural: {
                id: 3,
                nome: 'EDUCAÇÃO SANITÁRIA',
              },
            },
          ],
        });

        const newFVER: FVER.Input = {
          ...fver,
          listChavePrincipalVisitaPropriedadeRural:
            newListChavePrincipalVisitaPropriedadeRural,
        };

        await FVERIDBService.put(Number(params.id), newFVER);
      }
    );
  };

  const handleSelectFaseCriacao = (list: any[]) => {
    props.form.setFieldsValue({
      vigilanciaAlimentosRuminantes: {
        sistemaDeCriacaoDeRuminantes2: list,
      },
    });
  };

  const handleSelectIdadeRuminantesAlimentadosComRacaoBovinos = (
    list: any[]
  ) => {
    props.form.setFieldsValue({
      vigilanciaAlimentosRuminantes: {
        idadeBovinosAlimentadosComRacao: list,
      },
    });
  };

  const handleSelectIdadeRuminantesAlimentadosComRacaoOvinosCaprinos = (
    list: any[]
  ) => {
    props.form.setFieldsValue({
      vigilanciaAlimentosRuminantes: {
        idadeOvinosCaprinosAlimentadosComRacao: list,
      },
    });
  };

  const validateTotalRuminantes_VigilanciaInvestigacaoDeAlimentos = () => {
    let isValid = false;

    const quantidadeBovinos = props.form.getFieldValue([
      'vigilanciaAlimentosRuminantes',
      'quantidadeBovinos',
    ]);

    const quantidadeCaprinos = props.form.getFieldValue([
      'vigilanciaAlimentosRuminantes',
      'quantidadeCaprinos',
    ]);

    const quantidadeOvinos = props.form.getFieldValue([
      'vigilanciaAlimentosRuminantes',
      'quantidadeOvinos',
    ]);

    const quantidadeOutraEspecieRuminante = props.form.getFieldValue([
      'vigilanciaAlimentosRuminantes',
      'quantidadeOutraEspecieRuminante',
    ]);

    if (
      quantidadeBovinos !== undefined &&
      quantidadeBovinos !== null &&
      quantidadeBovinos !== '' &&
      quantidadeBovinos !== '0' &&
      quantidadeBovinos !== 0
    )
      isValid = true;
    if (
      quantidadeCaprinos !== undefined &&
      quantidadeCaprinos !== null &&
      quantidadeCaprinos !== '' &&
      quantidadeCaprinos !== '0' &&
      quantidadeCaprinos !== 0
    )
      isValid = true;
    if (
      quantidadeOvinos !== undefined &&
      quantidadeOvinos !== null &&
      quantidadeOvinos !== '' &&
      quantidadeOvinos !== '0' &&
      quantidadeOvinos !== 0
    )
      isValid = true;
    if (
      quantidadeOutraEspecieRuminante !== undefined &&
      quantidadeOutraEspecieRuminante !== null &&
      quantidadeOutraEspecieRuminante !== '' &&
      quantidadeOutraEspecieRuminante !== '0' &&
      quantidadeOutraEspecieRuminante !== 0
    )
      isValid = true;

    return isValid;
  };

  return (
    <>
      {isInvestigacaoDeAlimentosLiberada() && (
        <Alert
          description={
            <>
              A investigação de alimentos deve ser preenchida.
              <br /> Chave{' '}
              <span style={{ fontWeight: 'bold' }}>
                PREVENÇÃO E CONTROLE DAS ENCEFALOPATIAS ESPONGIFORMES
                TRANSMISSÍVEIS.
              </span>{' '}
              detectada no FVER.
            </>
          }
          showIcon
        />
      )}
      <Divider orientation='left'>Investigação de Alimentos Ruminantes</Divider>

      <fieldset disabled={props.formDisabled}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Houve Investigação?'}
            name={['vigilanciaAlimentosRuminantes', 'houveInvestigacao']}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHouveInvestigacaoDeAlimentos}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaAlimentosRuminantes', 'houveInvestigacao'],
                  e.target.value
                );
                setStatusHouveInvestigacaoDeAlimentos(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        {statusHouveInvestigacaoDeAlimentos && (
          <>
            <Row gutter={24}>
              <Form.Item
                label={'id'}
                name={['vigilanciaAlimentosRuminantes', 'id']}
                hidden
              >
                <Input />
              </Form.Item>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Finalidade exploração bovinos'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'finalidadeExploracaoBovinosEBubalinos',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione uma finalidade'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaFinalidadeExploracaoBovinosEBubalinos?.map(
                      (finalidade) => (
                        <Select.Option key={finalidade} value={finalidade}>
                          {FinalidadeExploracaoBovinosEBubalinos.valueOf(
                            finalidade
                          )}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Sistema de produção de ruminantes'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'sistemaDeCriacaoDeRuminantes',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione um sistema'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaSistemaDeCriacaoDeRuminantes?.map((finalidade) => (
                      <Select.Option key={finalidade} value={finalidade}>
                        {SistemaDeCriacaoDeRuminantes.valueOf(finalidade)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Fase da produção'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'sistemaDeCriacaoDeRuminantes2',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder={'Selecione uma opção'}
                    onChange={handleSelectFaseCriacao}
                    showSearch={false}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                    maxTagCount={'responsive'}
                    style={{
                      inlineSize: 250,
                      height: 'auto',
                      wordWrap: 'break-word',
                      width: '100%',
                    }}
                  >
                    {listaFaseExploracaoBovinosEBubalinos?.map((finalidade) => (
                      <Select.Option key={finalidade} value={finalidade}>
                        {FaseExploracaoBovinosEBubalinos.valueOf(finalidade)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Outro'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'outroSistemaDeCriacaoDeRuminante',
                  ]}
                >
                  <Input autoComplete='off' />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Idade dos bovinos alimentados com ração'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'idadeBovinosAlimentadosComRacao',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder={'Selecione uma opção'}
                    onChange={
                      handleSelectIdadeRuminantesAlimentadosComRacaoBovinos
                    }
                    showSearch={false}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                    maxTagCount={'responsive'}
                    style={{
                      inlineSize: 250,
                      height: 'auto',
                      wordWrap: 'break-word',
                      width: '100%',
                    }}
                  >
                    {listaIdadeRuminantesAlimentadosComRacaoBovinos?.map(
                      (idade) => (
                        <Select.Option key={idade} value={idade}>
                          {IdadeRuminantesAlimentadosComRacao.valueOf(idade)}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Idade dos ovinos/caprinos alimentados com ração'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'idadeOvinosCaprinosAlimentadosComRacao',
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder={'Selecione uma opção'}
                    onChange={
                      handleSelectIdadeRuminantesAlimentadosComRacaoOvinosCaprinos
                    }
                    showSearch={false}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                    maxTagCount={'responsive'}
                    style={{
                      inlineSize: 250,
                      height: 'auto',
                      wordWrap: 'break-word',
                      width: '100%',
                    }}
                  >
                    {listaIdadeRuminantesAlimentadosComRacaoOvinosCaprinos?.map(
                      (idade) => (
                        <Select.Option key={idade} value={idade}>
                          {IdadeRuminantesAlimentadosComRacao.valueOf(idade)}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Space direction='vertical' style={{ width: '100%' }}>
                <Col xs={24} lg={24}>
                  <Card title='Nº total de ruminantes'>
                    <Row gutter={24}>
                      <Col xs={24} lg={6}>
                        <Form.Item
                          dependencies={[
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeCaprinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOvinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOutraEspecieRuminante',
                            ],
                          ]}
                          initialValue={
                            props.form.getFieldValue([
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeBovinos',
                            ]) !== undefined
                              ? props.form.getFieldValue([
                                  'vigilanciaAlimentosRuminantes',
                                  'quantidadeBovinos',
                                ])
                              : ''
                          }
                          label={'Bovinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeBovinos',
                          ]}
                          rules={[
                            {
                              validator() {
                                const isValid =
                                  validateTotalRuminantes_VigilanciaInvestigacaoDeAlimentos();
                                setShowAlertNoTotalDeRuminantes(!isValid);
                                if (isValid) return Promise.resolve();
                                else return Promise.reject();
                              },
                            },
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          dependencies={[
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeBovinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOvinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOutraEspecieRuminante',
                            ],
                          ]}
                          initialValue={
                            props.form.getFieldValue([
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeCaprinos',
                            ]) !== undefined
                              ? props.form.getFieldValue([
                                  'vigilanciaAlimentosRuminantes',
                                  'quantidadeCaprinos',
                                ])
                              : ''
                          }
                          label={'Caprinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeCaprinos',
                          ]}
                          rules={[
                            {
                              validator() {
                                const isValid =
                                  validateTotalRuminantes_VigilanciaInvestigacaoDeAlimentos();
                                setShowAlertNoTotalDeRuminantes(!isValid);
                                if (isValid) return Promise.resolve();
                                else return Promise.reject();
                              },
                            },
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          dependencies={[
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeBovinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeCaprinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOutraEspecieRuminante',
                            ],
                          ]}
                          initialValue={
                            props.form.getFieldValue([
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOvinos',
                            ]) !== undefined
                              ? props.form.getFieldValue([
                                  'vigilanciaAlimentosRuminantes',
                                  'quantidadeOvinos',
                                ])
                              : ''
                          }
                          label={'Ovinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeOvinos',
                          ]}
                          rules={[
                            {
                              validator() {
                                const isValid =
                                  validateTotalRuminantes_VigilanciaInvestigacaoDeAlimentos();
                                setShowAlertNoTotalDeRuminantes(!isValid);
                                if (isValid) return Promise.resolve();
                                else return Promise.reject();
                              },
                            },
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          dependencies={[
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeBovinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeCaprinos',
                            ],
                            [
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOvinos',
                            ],
                          ]}
                          initialValue={
                            props.form.getFieldValue([
                              'vigilanciaAlimentosRuminantes',
                              'quantidadeOutraEspecieRuminante',
                            ]) !== undefined
                              ? props.form.getFieldValue([
                                  'vigilanciaAlimentosRuminantes',
                                  'quantidadeOutraEspecieRuminante',
                                ])
                              : ''
                          }
                          label={'Outros '}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeOutraEspecieRuminante',
                          ]}
                          rules={[
                            {
                              validator() {
                                const isValid =
                                  validateTotalRuminantes_VigilanciaInvestigacaoDeAlimentos();
                                setShowAlertNoTotalDeRuminantes(!isValid);
                                if (isValid) return Promise.resolve();
                                else return Promise.reject();
                              },
                            },
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    {showAlertNoTotalDeRuminantes && (
                      <Alert
                        message='Nº total de ruminantes não pode ser 0'
                        showIcon
                        type='error'
                      />
                    )}
                  </Card>
                </Col>

                <Col xs={24} lg={24}>
                  <Card title='Nº total de ruminantes expostos'>
                    <Row gutter={24}>
                      <Col xs={24} lg={6}>
                        <Form.Item
                          label={'Bovinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeBovinosExpostos',
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          label={'Caprinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeCaprinosExpostos',
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          label={'Ovinos'}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeOvinosExpostos',
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={6}>
                        <Form.Item
                          label={'Outros '}
                          name={[
                            'vigilanciaAlimentosRuminantes',
                            'quantidadeOutraEspecieRuminanteExpostos',
                          ]}
                        >
                          <Input
                            inputMode='numeric'
                            autoComplete='off'
                            maxLength={5}
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Space>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Tipo de alimentação'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'tipoAlimentacaoRuminantesMobile',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaTipoAlimentacaoRuminantes?.map((finalidade) => (
                      <Select.Option key={finalidade} value={finalidade}>
                        {TipoAlimentacaoRuminantes.valueOf(finalidade)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Época que acontece suplementação'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'epocaDoAnoQueAconteceASuplementacaoMobile',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaEpocaAnoSuplementacao?.map((finalidade) => (
                      <Select.Option key={finalidade} value={finalidade}>
                        {EpocaDoAnoSuplementacao.valueOf(finalidade)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Há criação avícola em sistema industrial?'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'tipoExploracaoAvicolaSistemaIndustrial',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione uma opção'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaTipoExploracaoAvicolaSistemaIndustrial?.map(
                      (tipoExploracaoAvicola) => (
                        <Select.Option
                          key={tipoExploracaoAvicola}
                          value={tipoExploracaoAvicola}
                        >
                          {TipoExploracaoAvicolaSistemaIndustrial.valueOf(
                            tipoExploracaoAvicola
                          )}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Outro'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'outroTipoExploracaoAvicolaSistemaIndustrial',
                  ]}
                >
                  <Input autoComplete='off' />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Presença de cama de aviário'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'presencaDeCamaDeAviario',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={statusRadioPresencaDeCamaDeAviario}
                    onChange={(e) => {
                      handleChangeStatusRadioPresencaDeCamaDeAviario(
                        e.target.value
                      );
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'presencaDeCamaDeAviario',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'presencaDeCamaDeAviario',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={24}>
                <Form.Item
                  label={'Observação sobre cama de aviário'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'observacaoSobreCamaDeAviario',
                  ]}
                >
                  <TextArea showCount rows={5} maxLength={200} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Utilização da cama de aviário na alimentação'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={
                      statusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes
                    }
                    onChange={(e) => {
                      handleChangeStatusRadioUtilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes(
                        e.target.value
                      );
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'utilizacaoDeCamaDeAviarioNaAlimentacaoDeRuminantes',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Há criação de suínos em sistema industrial?'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'tipoExploracaoSuinosSistemaIndustrial',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione uma opção'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaTipoExploracaoSuinosSistemaIndustrial?.map(
                      (tipoExploracaoSuinos) => (
                        <Select.Option
                          key={tipoExploracaoSuinos}
                          value={tipoExploracaoSuinos}
                        >
                          {TipoExploracaoSuinosSistemaIndustrial.valueOf(
                            tipoExploracaoSuinos
                          )}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={24}>
                <Form.Item
                  label={
                    'Observação sobre criação de suínos em sistema industrial'
                  }
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'observacaoTipoExploracaoSuinosSistemaIndustrial',
                  ]}
                >
                  <TextArea showCount rows={5} maxLength={200} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={
                    'Psicultura com sistema de alimentação à base de ração'
                  }
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'pisciculturaComSistemaDeAlimentacaoABaseDeRacao',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={
                      statusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao
                    }
                    onChange={(e) => {
                      handleChangeStatusRadioPisciculturaComSistemaDeAlimentacaoABaseDeRacao(
                        e.target.value
                      );
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'pisciculturaComSistemaDeAlimentacaoABaseDeRacao',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'pisciculturaComSistemaDeAlimentacaoABaseDeRacao',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Indícios de contaminação cruzada peixe x ração'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'contaminacaoCruzadaDeRacoesDePeixeERuminante',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={
                      statusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante
                    }
                    onChange={(e) => {
                      handleChangeStatusRadioContaminacaoCruzadaDeRacoesDePeixeERuminante(
                        e.target.value
                      );
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'contaminacaoCruzadaDeRacoesDePeixeERuminante',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'contaminacaoCruzadaDeRacoesDePeixeERuminante',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Colheita de amostra de alimentos'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'colheitaDeAmostraDeAlimentosDeRuminantes',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={statusRadioColheitaDeAmostraDeAlimentosDeRuminantes}
                    onChange={(e) => {
                      handleChangeStatusRadioColheitaDeAmostraDeAlimentosDeRuminantes(
                        e.target.value
                      );
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'colheitaDeAmostraDeAlimentosDeRuminantes',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'colheitaDeAmostraDeAlimentosDeRuminantes',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Tipo de vigilância'}
                  name={['vigilanciaAlimentosRuminantes', 'tipoFiscalizacao']}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Select
                    placeholder={'Selecione uma opção'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaAtivaPassiva?.map((tipo) => (
                      <Select.Option key={tipo} value={tipo}>
                        {AtivaPassiva.valueOf(tipo)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Nº da denúncia'}
                  name={['vigilanciaAlimentosRuminantes', 'numeroDenuncia']}
                >
                  <Input
                    inputMode='numeric'
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Uso de teste rápido'}
                  name={['vigilanciaAlimentosRuminantes', 'usoTesteRapido']}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                  valuePropName={'checked'}
                >
                  <Radio.Group
                    value={statusRadioUsoTesteRapido}
                    onChange={(e) => {
                      handleChangeStatusRadioUsoTesteRapido(e.target.value);
                    }}
                    defaultValue={
                      !props.form.getFieldValue([
                        'vigilanciaAlimentosRuminantes',
                        'usoTesteRapido',
                      ])
                        ? undefined
                        : props.form.getFieldValue([
                            'vigilanciaAlimentosRuminantes',
                            'usoTesteRapido',
                          ]) === 'SIM'
                        ? true
                        : false
                    }
                  >
                    <Radio key={'0'} value={false}>
                      {SimNao.NAO}
                    </Radio>
                    <Radio key={'1'} value={true}>
                      {SimNao.SIM}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8}>
                <Form.Item
                  label={'Resultado do teste rápido'}
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'resultadoTesteRapido',
                  ]}
                >
                  <Select
                    placeholder={'Selecione uma opção'}
                    placement={'bottomLeft'}
                    disabled={props.formDisabled}
                  >
                    {listaResultadoTesteRapido?.map((tipo) => (
                      <Select.Option key={tipo} value={tipo}>
                        {ResultadoTesteRapido.valueOf(tipo)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={24}>
                <Form.Item
                  label={
                    'Descrição do sistema de armazenamento e elaboração de rações'
                  }
                  name={[
                    'vigilanciaAlimentosRuminantes',
                    'descricaoDoSistemaDeArmazenamentoEElaboracaoDeRacoes',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <TextArea showCount rows={5} maxLength={2000} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={24}>
                <Form.Item
                  label={'Outras observações'}
                  name={['vigilanciaAlimentosRuminantes', 'outrasObservacoes']}
                >
                  <TextArea showCount rows={5} maxLength={2000} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </fieldset>

      <CustomModal
        open={showModalInforme}
        title={'Vigilância de Alimentos'}
        closable={false}
        maskClosable={false}
        width={600}
        footer={
          <>
            {!params.local && (
              <Button
                onClick={() => {
                  setShowModalInforme(false);
                }}
              >
                Fechar
              </Button>
            )}

            {params.local && (
              <>
                <Button
                  onClick={() => {
                    setShowModalInforme(false);
                  }}
                >
                  Fechar
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    updateFVER().then(() => {
                      window.location.reload();
                    });
                  }}
                >
                  Incluir chave primária no FVER
                </Button>
              </>
            )}
          </>
        }
      >
        <Typography.Title level={5} style={{ textAlign: 'justify' }}>
          Para informar investigação de alimentos para ruminantes é necessário
          informar no FVER a chave primária:
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            PREVENÇÃO E CONTROLE DAS ENCEFALOPATIAS ESPONGIFORMES TRANSMISSÍVEIS
          </span>
        </Typography.Title>
      </CustomModal>
    </>
  );
}
