import { Button, Space, Spin, Typography, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import AuthorizationService from '../../core/auth/Authorization.service';
import LoginMT from '../features/Home/LoginMT';
import { useNavigate } from 'react-router';
import useUsuario from '../../core/hooks/useUsuario';
import LoginSIZ from '../features/Home/LoginSIZ';
import { CustomModal } from '../components/CustomModal';
import localforage from 'localforage';

export default function LoginView() {
  const navigate = useNavigate();
  const [showLoginSIZ, setShowLoginSIZ] = useState(false);
  const [showModalNoUser, setShowModalNoUser] = useState(false);
  const { fetchUsuarioByCpf, usuario } = useUsuario();
  const [errorInFetchingUsuario, setErrorInFetchingUsuario] = useState(false);

  const [loginEnded, setLoginEnded] = useState(false);

  const checkValidLoginAndFetchToken = useCallback(async () => {
    if (!AuthorizationService.isAuthenticated()) {
      const params: any = new Proxy(
        new URLSearchParams(window.location.search),
        {
          //@ts-ignore
          get: (searchParams, prop) => searchParams.get(prop),
        }
      );

      const code = params.code;
      if (!code) {
        navigate('/');
        return;
      }

      AuthorizationService.setCodeVerifier(code);
      await AuthorizationService.getFirstAccessToken(code)
        .then((data) => data.json())
        .then(async (data) => {
          if (data.access_token) {
            AuthorizationService.setAccessToken(data.access_token);
          }
          if (data.refresh_token)
            AuthorizationService.setRefreshToken(data.refresh_token);
        })
        .then(async () => {
          await AuthorizationService.fetchUsuarioXVia();
        })
        .then(async () => {
          const cpf = AuthorizationService.getUsuarioXVia()?.cpf;

          if (cpf) {
            await fetchUsuarioByCpf(cpf)
              .then(() => {
                setLoginEnded(true);
              })
              .catch((e) => {
                console.log('11');
                setErrorInFetchingUsuario(true);
                setLoginEnded(true);
              });
          }
        })
        .catch((error: any) => {
          console.log('12');
          notification.error({
            message: 'Ocorreu um erro inesperado',
            description: error.message,
          });
          setLoginEnded(true);
        })
        .finally(() => {
          setLoginEnded(true);
        });

      console.log('LOGIN - FINALIZADO');
    }
  }, [fetchUsuarioByCpf, navigate]);

  useEffect(() => {}, [showLoginSIZ]);

  const isInAuthorizationRoute = useCallback((): boolean => {
    return window.location.pathname === '/auth';
  }, []);

  useEffect(() => {
    console.log('-1');

    if (isInAuthorizationRoute()) {
      console.log('-2');
      if (!AuthorizationService.getAccessToken()) {
        console.log('-3');
        checkValidLoginAndFetchToken();
      } else {
        console.log('-4');
        navigate('/home');
      }
    } else if (AuthorizationService.isAuthenticated()) {
      console.log('-5');
      navigate('/home');
    } else {
      console.log('-6');
      localStorage.clear();
      localforage.clear();
    }
  }, [checkValidLoginAndFetchToken, isInAuthorizationRoute, navigate]);

  const evaluateUsuarioAfterLoginEnded = useCallback(() => {
    console.log('1', errorInFetchingUsuario);

    if (!errorInFetchingUsuario) {
      console.log('2');
      if (usuario) {
        console.log('3');
        AuthorizationService.setUsuarioSIZ(usuario);
        if (!usuario.vinculadoMTCidadao) {
          navigate('/');
          setShowLoginSIZ(true);
        } else {
          console.log('LOGIN - NAVIGATE HOME');

          navigate('/home');
        }
      } else {
        console.log('LOGIN - NAVIGATE LOGOUT');
        localStorage.clear();
        localforage.clear();
        setShowModalNoUser(true);
      }
    } else {
      AuthorizationService.logout({ error: true });
    }
  }, [errorInFetchingUsuario, navigate, usuario]);

  useEffect(() => {
    if (loginEnded) {
      evaluateUsuarioAfterLoginEnded();
    }
  }, [loginEnded]);

  return (
    <>
      <Spin
        size='large'
        spinning={isInAuthorizationRoute()}
        style={{
          zIndex: '10',
          width: '100%',
          paddingTop: '20%',
        }}
        tip={
          <Space
            direction='vertical'
            size={12}
            style={{
              width: '100%',
            }}
          >
            <br />
            <Typography.Text>
              Aguarde enquanto validamos seus dados
            </Typography.Text>
            <Button
              onClick={() => {
                localStorage.clear();
                localforage.clear();
                navigate('/');

                notification.warn({
                  message: 'Erro ao buscar as informações do usuário!',
                  description: 'Refaça o login ',
                });
              }}
              style={{ maxWidth: '325px', width: '100%' }}
              type='primary'
            >
              Voltar
            </Button>
          </Space>
        }
      >
        {!isInAuthorizationRoute() && (
          <>
            {!showLoginSIZ && <LoginMT />}
            {showLoginSIZ && (
              <LoginSIZ
                usuarioSIZ={usuario}
                setUsuarioSIZ={AuthorizationService.setUsuarioSIZ}
                callback={() => setShowLoginSIZ(false)}
              />
            )}
          </>
        )}
      </Spin>

      <CustomModal
        centered
        open={showModalNoUser}
        width={600}
        footer={null}
        title={'Usuário não encontrado!'}
      >
        <Typography.Paragraph>
          Para que você consiga utilizar o SIZ, além de estar cadastrado no MT
          Login, também é necessário que você possua cadastro no SIZ.
        </Typography.Paragraph>

        <Typography.Paragraph>
          <Typography.Text>
            Há 2 motivos para que isso tenha ocorrido:
          </Typography.Text>
          <br />

          <Typography.Text style={{ marginLeft: '10px' }}>
            - Seu CPF no MT Login está diferente do seu CPF no SIZ.
          </Typography.Text>
          <br />

          <Typography.Text style={{ marginLeft: '10px' }}>
            - Você não possui cadastro no SIZ.
          </Typography.Text>
        </Typography.Paragraph>

        <Typography.Paragraph>
          Para regularizar seu cadastro no SIZ, entre em contato com a CDSA.
        </Typography.Paragraph>

        <Button
          onClick={() => {
            setShowModalNoUser(false);
            AuthorizationService.logout();
          }}
          type='primary'
          style={{ fontWeight: 'bold', height: '60px', width: '100%' }}
          htmlType='submit'
        >
          Fechar
        </Button>
      </CustomModal>
    </>
  );
}
