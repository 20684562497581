import { useCallback, useState } from 'react';
import { Abatedouro } from '../../sdk/@types/Abatedouro';
import AbatedouroIDBService from '../../sdk/services/indexeddb/AbatedouroIDB.service';
import AbatedouroService from '../../sdk/services/SIZ-API/Abatedouro.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useAbatedouro() {
  const { online } = useNavigatorStatus();
  const [abatedouro, setAbatedouro] = useState<Abatedouro.Summary | null>();
  const [listaAbatedouros, setListaAbatedouros] =
    useState<Abatedouro.Summary[]>();
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchExistingAbatedouroByCNPJ = useCallback(
    async (cnpj: string, fromApi?: boolean) => {
      try {
        setFetching(true);

        if (!fromApi) {
          return await AbatedouroIDBService.getByCnpj(cnpj).then(
            async (payload) => {
              if (payload) {
                setAbatedouro(payload?.payload);
              } else if (online) {
                return await AbatedouroService.getByCNPJ(cnpj).then(
                  setAbatedouro
                );
              } else setAbatedouro(null);
            }
          );
        } else {
          return await AbatedouroService.getByCNPJ(cnpj).then(setAbatedouro);
        }
      } catch (err) {
        throw err;
      } finally {
        setFetching(false);
      }
    },
    [online]
  );

  const fetchAbatedourosByMunicipio = useCallback((codgIbge: string) => {
    try {
      setFetching(true);
      return AbatedouroService.getByMunicipio(codgIbge).then(
        setListaAbatedouros
      );
    } finally {
      setFetching(false);
    }
  }, []);

  return {
    fetching,
    abatedouro,
    listaAbatedouros,
    fetchExistingAbatedouroByCNPJ,
    fetchAbatedourosByMunicipio,
  };
}
