import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FVER } from '../../sdk/@types';
import FVERService from '../../sdk/services/SIZ-API/FVER.service';

interface FVERState {
  fvers: FVER.Paginated | undefined;
  fetching: boolean;
  query: FVER.Query;
}

const initialState: FVERState = {
  fvers: undefined,
  fetching: false,
  query: { page: 0, size: 10 },
};

export const filter = createAsyncThunk(
  'fver/filter',
  async (query: FVER.Query, { dispatch, rejectWithValue }) => {
    await FVERService.getAll(query)
      .then(async (fvers) => await dispatch(storeFVER(fvers)))
      .catch((e) => {
        rejectWithValue(new Error('Não foi possível buscar os FVERs'));
      });
  }
);

const FVERSlice = createSlice({
  initialState,
  name: 'fver',
  reducers: {
    storeFVER(state, action: PayloadAction<FVER.Paginated>) {
      state.fvers = action.payload;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FVER.Query>) {
      state.query = action.payload;
    },
  },
});

export const { storeFVER, setFetching, setQuery } = FVERSlice.actions;

const FVERReducer = FVERSlice.reducer;
export default FVERReducer;
