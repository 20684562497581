import { CoordenadaGeografica } from '../../sdk/@types';

export function coordinatesDDtoDMS(
  lat: number,
  lng: number
): CoordenadaGeografica.Detailed {
  let orientacaoLatitude, orientacaoLongitude;

  const latDMS = ddToDMS(lat);
  const longDMS = ddToDMS(lng);

  orientacaoLongitude = lng >= 0 ? 'L' : 'O';
  orientacaoLatitude = lat >= 0 ? 'N' : 'S';

  const coord: CoordenadaGeografica.Detailed = {
    latGrau: latDMS.grau,
    latMin: latDMS.min,
    latSeg: latDMS.sec,
    longGrau: longDMS.grau,
    longMin: longDMS.min,
    longSeg: longDMS.sec,
    orientacaoLatitude,
    orientacaoLongitude,
  };

  return coord;
}

function ddToDMS(val: number): {
  grau: number;
  min: number;
  sec: number;
} {
  let grau, min, sec;

  val = Math.abs(val); // -40.601203 = 40.601203

  grau = Math.floor(val); // 40.601203 = 40
  min = Math.floor((val - grau) * 60); // 36.07218 = 36
  sec = Math.round((val - grau - min / 60) * 3600 * 1000) / 1000; // 40.601203 = 4.331

  return {
    grau,
    min,
    sec,
  };
}

export function coordinatesDMStoDD(coordenadas: CoordenadaGeografica.Detailed) {
  let coordenadasDD, lat, long;

  lat = dmsToDD(
    coordenadas.latGrau,
    coordenadas.latMin,
    coordenadas.latSeg,
    coordenadas.orientacaoLatitude
  );
  long = dmsToDD(
    coordenadas.longGrau,
    coordenadas.longMin,
    coordenadas.longSeg,
    coordenadas.orientacaoLongitude
  );

  coordenadasDD = `${lat},${long}`;
  return coordenadasDD;
}

function dmsToDD(grau: number, min: number, sec: number, orientacao: string) {
  var dd = grau + min / 60 + sec / (60 * 60);

  if (orientacao === 'S' || orientacao === 'O') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}
