import { useEffect } from 'react';
import { Route, useLocation, Routes } from 'react-router-dom';
import useAssinaturaBase64Image from '../core/hooks/useAssinaturaBase64Image';
import CreateFormVIN from './features/FormVIN/CreateFormVIN';
import FormVINEditView from './view/FormVINEditWrapper.view';
import FormVINList from './view/FormVINListWrapper.view';
import FormVINViewView from './view/FormVINViewWrapper.view';
import OfflineModeView from './view/OfflineMode.view';
import FVERCreateView from './view/FVERCreateWrapper.view';
import VisitaPropriedadeRuralEditView from './view/FVEREditWrapper.view';
import FVERList from './view/FVERListWrapper.view';
import FVERViewWrapper from './view/FVERViewWrapper.view';
import LandingView from './view/Landing.view';
import UsuarioView from './view/Usuario.view';
import VigilanciaVeterinariaList from './view/FVVListWrapper.view';
import VigilanciaVeterinariaCreateView from './view/FVVCreateWrapper.view';
import VigilanciaVeterinariaEditView from './view/FVVEditWrapper.view';
import VigilanciaVeterinariaViewView from './view/FVVViewWrapper.view';
import AcoesDeCampo from './view/AcoesDeCampo.view';
import NotificacoesSistema from './view/Notificacoes.view';
import FVERPublicPrintWrapper from './view/FVERPublicPrintWrapper.view';
import FVERReportGeralView from './view/FVERReportGeral.view';
import LoginView from './view/Login.view';
import SessionExpired from './components/SessionExpired';
import NotFoundPage from './components/NotFoundPage';
import { CustomRoute } from './components/CustomRoute';
import useStartUp from '../core/hooks/useStartUp';
import useLoadingPage from '../core/hooks/useLoadingPage';
import TFREditView from './view/TFRditWrapper.view';
import TFRListView from './view/TFRListWrapper.view';
import TFRViewWrapper from './view/TFRViewWrapper.view';
import TFRExternalListWrapper from './view/TFRExternalListWrapper.view';
import FVVReportSimplificadoView from './view/FVVReportSimplificado.view';
import LIACreateView from './view/LIACreateWrapper.view';
import TFRExternalViewWrapper from './view/TFRExternalViewWrapper.view';
import FVERPublicListWrapper from './view/FVERPublicListWrapper.view';
import LIAEditView from './view/LIAEditWrapper.view';
import TFRCreateView from './view/TFRCreateWrapper.view';
import EnvironmentView from './features/Dev/Environment.view';
import ImportJsonView from './features/Dev/ImportJson.view';
import ListAtendimentoErrosSincronizacaoView from './features/AtendimentoErrosSincronizacao/ListAtendimentoErrosSincronizacao.view';
import FormAtendimentoErrosSincronizacaoView from './features/AtendimentoErrosSincronizacao/FormAtendimentoErrosSincronizacao.view';
import ListUsuarioView from './features/Usuario/ListUsuario';
import ListUsuarioPermissoesView from './features/Usuario/ListUsuarioPermissoes';
import AuthorizationService from '../core/auth/Authorization.service';
import LIAViewWrapper from './view/LIAViewWrapper.view';
import LIAListView from './view/LIAListWrapper.view';
import FVERPublicCompletoWrapper from './view/FVERPublicCompletoWrapper.view';
import FVVViewWrapper from './view/FVVViewWrapper_p_.view';
import LIAPublicPrintWrapper from './view/LIAPublicPrintWrapper.view';
import FVVPublicPrintWrapper from './view/FVVPublicPrintWrapper.view';
import TFRIDBService from '../sdk/services/indexeddb/TFRIDB.service';
import LIAIDBService from '../sdk/services/indexeddb/LIAIDB.service';
import useBadgeState from '../core/hooks/useBadgeState';
import FVVIDBService from '../sdk/services/indexeddb/FVVIDB.service';
import FVERIDBService from '../sdk/services/indexeddb/FVERIDB.service';
import useOfflinePersistState from '../core/hooks/useOfflinePersistState';
import useLatestVersionAvaiableState from '../core/hooks/useLatestVersionAvaiableState';
import HistoricoEstabelecimentoView from './view/HistoricoEstabelecimento';
import FormCustomViewWrapper from './view/FormCustomViewWrapper.view';
import FormCustomPrintWrapper from './view/FormCustomPrintWrapper.view';
import FormCustomListView from './view/FormCustomListWrapper.view';
import FormCustomCreateView from './view/FormCustomCreateWrapper.view';
import FormCustomEditView from './view/FormCustomEditWrapper.view';
import FormRespostasCreateView from './view/FormRespostasCreateWrapper.view';
import FormRespostasViewWrapper from './view/FormRespostasViewWrapper.view';
import FormRespostaEditView from './view/FormRespostaditWrapper.view';
import FormFormularioRespostasListView from './view/FormFormularioRespostasListWrapper.view';
import FormRespostasListView from './view/FormRespostasListWrapper.view';
import LocationTest from './features/Dev/LocationTest.view';
import GRAFCreateView from './view/GRAFCreateWrapper.view';
import GRAFListView from './view/GRAFListWrapper.view';
import GRAFViewWrapper from './view/GRAFViewWrapper.view';
import EDUCACreateView from './view/EDUCACreateWrapper.view';
import EDUCAListView from './view/EDUCAListWrapper.view';
import CadastroLocalAcaoEducativaView from './view/CadastroLocalAcaoEducativaWrapper.view';
import LocalAcaoEducativaListWrapper from './view/LocalAcaoEducativaListWrapper.view';
import LocalAcaoEducativaEditView from './view/LocalAcaoEducativaEditWrapper.view';
import LocalAcaoEducativaViewWrapper from './view/LocalAcaoEducativaViewWrapper.view';
import FormGrafEditView from './view/GRAFEditWrapper.view';
import FormEducaEditView from './view/EDUCAEditWrapper.view';
import FormEducaViewWrapper from './view/EDUCAViewWrapper.view';
import FormularioRespostasIDBService from '../sdk/services/indexeddb/FormularioRespostaIDB.service';
import SOVIVListView from './view/SOVIVListWrapper.view';
import SOVIVCreateView from './view/SOVIVCreateWrapper.view';
import SOVIVViewWrapper from './view/SOVIVViewWrapper.view';
import SOVIVditWrapper from './view/SOVIVEditWrapper.view';

export default function CustomRoutes() {
  const location = useLocation();
  const { init } = useStartUp();
  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();
  const { setLoading } = useLoadingPage();
  const { setQuantidadeAcoesNaoSincronizadas } = useBadgeState();
  const { done } = useOfflinePersistState();
  const { setLatestVersion } = useLatestVersionAvaiableState();

  const countOfflineInputData = async () => {
    let totalFVER = 0;
    let totalFVV = 0;
    let totalTFR = 0;
    let totalLIA = 0;
    let totalFormRespostas = 0;
    await FVERIDBService.count().then((value) => (totalFVER = value));
    await FVVIDBService.count().then((value: any) => (totalFVV = value));
    await TFRIDBService.count().then((value: any) => (totalTFR = value));
    await LIAIDBService.count().then((value: any) => (totalLIA = value));
    await FormularioRespostasIDBService.count().then(
      (value: any) => (totalFormRespostas = value)
    );

    setQuantidadeAcoesNaoSincronizadas(
      totalFVER + totalFVV + totalTFR + totalLIA + totalFormRespostas
    );
  };

  useEffect(() => {
    if (AuthorizationService.isAuthenticated()) {
      init();
    }
  }, []);

  useEffect(() => {
    if (done) {
      setLatestVersion();
    }
  }, [done]);

  useEffect(() => {
    if (AuthorizationService.isAuthenticated()) {
      if (done) countOfflineInputData();
    }
  });

  useEffect(() => {
    if (assinaturaBase64Image) setAssinaturaBase64Image(undefined);
    setLoading(false);
  }, [location]);

  return (
    <>
      <Routes>
        {/* Home */}
        <Route path={'/'} element={<CustomRoute component={LoginView} />} />
        <Route path={'/auth'} element={<CustomRoute component={LoginView} />} />
        s
        <Route
          path={'/home'}
          element={<CustomRoute isprivate={true} component={LandingView} />}
        />
        <Route
          path={'/usuario'}
          element={<CustomRoute isprivate={true} component={UsuarioView} />}
        />
        {/* FVER */}
        <Route
          path={'/visitas'}
          element={<CustomRoute isprivate={true} component={FVERList} />}
        />
        <Route
          path={'/visitas/cadastro/:local?'}
          element={<CustomRoute isprivate={true} component={FVERCreateView} />}
        />
        <Route
          path={`/visitas/edicao/:id/:local?`}
          element={
            <CustomRoute
              isprivate={true}
              component={VisitaPropriedadeRuralEditView}
            />
          }
        />
        <Route
          path={`/visitas/visualizar/:id`}
          element={<CustomRoute isprivate={true} component={FVERViewWrapper} />}
        />
        {/* Form VIN */}
        <Route
          path={'/formvins'}
          element={<CustomRoute isprivate={true} component={FormVINList} />}
        />
        <Route
          path={'/formvins/cadastro'}
          element={<CustomRoute isprivate={true} component={CreateFormVIN} />}
        />
        <Route
          path={`/formvins/edicao/:id/:local?`}
          element={<CustomRoute isprivate={true} component={FormVINEditView} />}
        />
        <Route
          path={`/formvins/FormRespostasCreatevisualizar/:id`}
          element={<CustomRoute isprivate={true} component={FormVINViewView} />}
        />
        {/* FVV */}
        <Route
          path={'/vigilancias'}
          element={
            <CustomRoute
              isprivate={true}
              component={VigilanciaVeterinariaList}
            />
          }
        />
        <Route
          path={'/vigilancias/cadastro'}
          element={
            <CustomRoute
              isprivate={true}
              component={VigilanciaVeterinariaCreateView}
            />
          }
        />
        <Route
          path={'/vigilancias/cadastro/:id/:local?'}
          element={
            <CustomRoute
              isprivate={true}
              component={VigilanciaVeterinariaCreateView}
            />
          }
        />
        <Route
          path={`/vigilancias/edicao/:id/:local?/:problem?`}
          element={
            <CustomRoute
              isprivate={true}
              component={VigilanciaVeterinariaEditView}
            />
          }
        />
        <Route
          path={`/vigilancias/visualizar/:id`}
          element={
            <CustomRoute
              isprivate={true}
              component={VigilanciaVeterinariaViewView}
            />
          }
        />
        <Route
          path={`/vigilancias/visualizarP/:id`}
          element={<CustomRoute isprivate={true} component={FVVViewWrapper} />}
        />
        {/* FORM-GRAF */}
        <Route
          path={'/graf/cadastro/:local?'}
          element={<CustomRoute component={GRAFCreateView} isprivate={true} />}
        />
        <Route
          path={`/graf/edicao/:id/:local?`}
          element={
            <CustomRoute isprivate={true} component={FormGrafEditView} />
          }
        />
        <Route
          path={`/graf`}
          element={<CustomRoute isprivate={true} component={GRAFListView} />}
        />
        <Route
          path={`/graf/visualizar/:id`}
          element={<CustomRoute isprivate={true} component={GRAFViewWrapper} />}
        />
        {/* CADASTRO LOCALIDADE | FORM-EDUCA */}
        <Route
          path={'/locaisAcaoEducativa/cadastro/:local?'}
          element={
            <CustomRoute
              component={CadastroLocalAcaoEducativaView}
              isprivate={true}
            />
          }
        />
        <Route
          path={`/locaisAcaoEducativa/edicao/:id/:local?`}
          element={
            <CustomRoute
              isprivate={true}
              component={LocalAcaoEducativaEditView}
            />
          }
        />
        <Route
          path={'/locaisAcaoEducativa'}
          element={
            <CustomRoute
              component={LocalAcaoEducativaListWrapper}
              isprivate={true}
            />
          }
        />
        <Route
          path={`/locaisAcaoEducativa/visualizar/:id`}
          element={
            <CustomRoute
              isprivate={true}
              component={LocalAcaoEducativaViewWrapper}
            />
          }
        />
        {/* FORM-EDUCA */}
        <Route
          path={'/educa/cadastro/:local?'}
          element={<CustomRoute component={EDUCACreateView} isprivate={true} />}
        />
        <Route
          path={`/educa/edicao/:id/:local?`}
          element={
            <CustomRoute isprivate={true} component={FormEducaEditView} />
          }
        />
        <Route
          path={`/educa`}
          element={<CustomRoute isprivate={true} component={EDUCAListView} />}
        />
        <Route
          path={`/educa/visualizar/:id`}
          element={
            <CustomRoute isprivate={true} component={FormEducaViewWrapper} />
          }
        />
        {/* TFR */}
        <Route
          path={'/tfr/cadastro/:local?'}
          element={<CustomRoute component={TFRCreateView} isprivate={true} />}
        />
        <Route
          path={`/tfr/edicao/:id/:local?`}
          element={<CustomRoute isprivate={true} component={TFREditView} />}
        />
        <Route
          path={`/tfr`}
          element={<CustomRoute isprivate={true} component={TFRListView} />}
        />
        <Route
          path={`/tfr/visualizar/:id`}
          element={<CustomRoute isprivate={true} component={TFRViewWrapper} />}
        />
        {/* LIA */}
        <Route
          path={'/lia/cadastro'}
          element={<CustomRoute isprivate={true} component={LIACreateView} />}
        />
        <Route
          path={'/lia/cadastro/:id/:local?'}
          element={<CustomRoute isprivate={true} component={LIACreateView} />}
        />
        <Route
          path={`/lia`}
          element={<CustomRoute isprivate={true} component={LIAListView} />}
        />
        <Route
          path={'/lia/visualizar/:id'}
          element={<CustomRoute isprivate={true} component={LIAViewWrapper} />}
        />
        <Route
          path={`/lia/edicao/:id/:local?/:problem?`}
          element={<CustomRoute isprivate={true} component={LIAEditView} />}
        />
        {/* FormCustom */}
        <Route
          path={'/formCustom/cadastro'}
          element={
            <CustomRoute isprivate={true} component={FormCustomCreateView} />
          }
        />
        <Route
          path={`/formCustom/impressao/:id`}
          element={
            <CustomRoute isprivate={true} component={FormCustomPrintWrapper} />
          }
        />
        <Route
          path={`/formCustom/edicao/:id/:local?/:problem?`}
          element={
            <CustomRoute isprivate={true} component={FormCustomEditView} />
          }
        />
        <Route
          path={`/formCustom/visualizar/:id`}
          element={
            <CustomRoute isprivate={true} component={FormCustomViewWrapper} />
          }
        />
        <Route
          path={`/formCustom/visualizar`}
          element={
            <CustomRoute isprivate={true} component={FormCustomListView} />
          }
        />
        {/* FormRespostas */}
        <Route
          path={'/formRespostas/cadastro/:id'}
          element={
            <CustomRoute isprivate={true} component={FormRespostasCreateView} />
          }
        />
        <Route
          path={`/formRespostas/edit/:id/:local?/:problem?`}
          element={
            <CustomRoute isprivate={true} component={FormRespostaEditView} />
          }
        />
        <Route
          path={`/formRespostas/visualizar/:id`}
          element={
            <CustomRoute
              isprivate={true}
              component={FormRespostasViewWrapper}
            />
          }
        />
        <Route
          path={`/formRespostas/visualizar/formularios/:id`}
          element={
            <CustomRoute isprivate={true} component={FormRespostasListView} />
          }
        />
        <Route
          path={`/formRespostas/visualizar/`}
          element={
            <CustomRoute
              isprivate={true}
              component={FormFormularioRespostasListView}
            />
          }
        />
        {/* SOVIV */}
        <Route
          path={'/recebimentoRevenda/cadastro/:local?'}
          element={<CustomRoute component={SOVIVCreateView} isprivate={true} />}
        />
        <Route
          path={`/recebimentoRevenda`}
          element={<CustomRoute isprivate={true} component={SOVIVListView} />}
        />
        <Route
          path={`/recebimentoRevenda/visualizar/:id`}
          element={
            <CustomRoute isprivate={true} component={SOVIVViewWrapper} />
          }
        />
        <Route
          path={`/recebimentoRevenda/edicao/:id`}
          element={<CustomRoute isprivate={true} component={SOVIVditWrapper} />}
        />
        {/* External */}
        <Route
          path={'/public/visualizarFVERCompleto/:codigoVerificador'}
          element={<CustomRoute component={FVERPublicCompletoWrapper} />}
        />
        <Route
          path={'/public/visualizarFVER/:codigoVerificador'}
          element={<CustomRoute component={FVERPublicPrintWrapper} />}
        />
        <Route
          path={'/public/visualizarFVERs/:cpf'}
          element={<CustomRoute component={FVERPublicListWrapper} />}
        />
        <Route
          path={'/public/visualizarLIA/:codigoVerificador'}
          element={<CustomRoute component={LIAPublicPrintWrapper} />}
        />
        <Route
          path={'/public/visualizarFVV/:codigoVerificador'}
          element={<CustomRoute component={FVVPublicPrintWrapper} />}
        />
        <Route
          path={'/public/visualizarTFR/:id'}
          element={<CustomRoute component={TFRExternalViewWrapper} />}
        />
        <Route
          path={'/public/visualizarTFRs/:cnpj'}
          element={<CustomRoute component={TFRExternalListWrapper} />}
        />
        {/* Relatórios */}
        <Route
          path={'/relatorioFVER'}
          element={
            <CustomRoute isprivate={true} component={FVERReportGeralView} />
          }
        />
        <Route
          path={'/relatorioFVV'}
          element={
            <CustomRoute
              isprivate={true}
              component={FVVReportSimplificadoView}
            />
          }
        />
        <Route
          path={'/offline'}
          element={<CustomRoute isprivate={true} component={OfflineModeView} />}
        />
        <Route
          path={'/acoes-de-campo'}
          element={<CustomRoute isprivate={true} component={AcoesDeCampo} />}
        />
        <Route
          path={'/environment'}
          element={<CustomRoute isprivate={true} component={EnvironmentView} />}
        />
        <Route
          path={'/gps'}
          element={<CustomRoute isprivate={false} component={LocationTest} />}
        />
        <Route
          path={'/atendimentosErrosSincronizacao'}
          element={
            <CustomRoute
              isprivate={true}
              component={ListAtendimentoErrosSincronizacaoView}
            />
          }
        />
        <Route
          path={'/atendimentoErrosSincronizacao/:id'}
          element={
            <CustomRoute
              isprivate={true}
              component={FormAtendimentoErrosSincronizacaoView}
            />
          }
        />
        <Route
          path={'/importJSON'}
          element={<CustomRoute isprivate={true} component={ImportJsonView} />}
        />
        <Route
          path={'/usuarios'}
          element={<CustomRoute isprivate={true} component={ListUsuarioView} />}
        />
        <Route
          path={'/usuarios/:userName/permissoes'}
          element={
            <CustomRoute
              isprivate={true}
              component={ListUsuarioPermissoesView}
            />
          }
        />
        <Route
          path={'/notificacoes'}
          element={
            <CustomRoute isprivate={true} component={NotificacoesSistema} />
          }
        />
        <Route
          path={'/historico'}
          element={
            <CustomRoute
              isprivate={true}
              component={HistoricoEstabelecimentoView}
            />
          }
        />
        <Route
          path={'/logout'}
          element={<CustomRoute isprivate={true} component={LoginView} />}
        />
        <Route
          path='*'
          element={<CustomRoute isprivate={true} component={NotFoundPage} />}
        />
      </Routes>
      <SessionExpired />
    </>
  );
}
