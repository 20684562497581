import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import EditFormResposta from '../features/FormRespostas/EditFormResposta';

export default function FormRespostaEditView() {
  usePageTitle('Editando Formulário Resposta');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('EDIT');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <EditFormResposta />
    </>
  );
}
