import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateFormRespostas from '../features/FormRespostas/CreateFormRespostas';

export default function FormCustomResponseCreateView() {
  usePageTitle('Novo Formulário Customizado');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('CREATE');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateFormRespostas />
      </ErrorBoundary>
    </>
  );
}
