import { useCallback, useState } from 'react';
import { TipoChaveSecundariaFVER } from '../../sdk/@types';
import TipoChaveSecundariaFVERIDBService from '../../sdk/services/indexeddb/TipoChaveSecundariaFVERIDB.service';
import TipoChaveSecundariaFVERService from '../../sdk/services/SIZ-API/TipoChaveSecundariaFVER.service';

export default function useTipoChaveSecundariaFVER() {
  const [tipoChaveSecundariaFVER, setTipoChaveSecundariaFVER] = useState<
    TipoChaveSecundariaFVER.Summary[]
  >([]);

  const fetchActive = useCallback(async (fromApi?: boolean) => {
    try {
      if (!fromApi) {
        await TipoChaveSecundariaFVERIDBService.getAllPayload().then(
          (lista) => {
            if (!lista || lista.length !== 0) setTipoChaveSecundariaFVER(lista);
            else
              throw Error(
                'Não foram encontrados Tipo Chave Secundária offline'
              );
          }
        );
      } else {
        await TipoChaveSecundariaFVERService.getAllActive().then(
          setTipoChaveSecundariaFVER
        );
      }
    } catch (e: any) {
      await TipoChaveSecundariaFVERService.getAllActive().then(
        setTipoChaveSecundariaFVER
      );
    } finally {
    }
  }, []);

  return {
    tipoChaveSecundariaFVER,
    fetchActive,
  };
}
