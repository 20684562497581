import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OfflinePersistState {
  done: boolean;
  none: boolean;
  syncing: boolean;
}

const initialState: OfflinePersistState = {
  done: false,
  none: true,
  syncing: false,
};

const OfflinePersistStateSlice = createSlice({
  initialState,
  name: 'offlinePersistState',
  reducers: {
    setDone(state, action: PayloadAction<boolean>) {
      state.done = action.payload;
    },
    setNone(state, action: PayloadAction<boolean>) {
      state.none = action.payload;
    },
    setSyncing(state, action: PayloadAction<boolean>) {
      state.syncing = action.payload;
    },
  },
});

export const { setDone, setNone, setSyncing } =
  OfflinePersistStateSlice.actions;

const OfflinePersistStateReducer = OfflinePersistStateSlice.reducer;
export default OfflinePersistStateReducer;
