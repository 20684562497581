import { TipoChaveSecundariaFVER } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';

class TipoChaveSecundariaFVERIDBService {
  static DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL =
    'tipoChaveSecundariaVisitaPropriedadeRural';
  static DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL_INDEX_NAME =
    'tipoChaveSecundariaVisitaPropriedadeRural_index_id';
  static DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL_INDEX_KEY =
    'payload.id';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(
        TipoChaveSecundariaFVERIDBService.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL
      )
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      TipoChaveSecundariaFVERIDBService.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL
    );
    return list;
  }

  static async getAllPayload(): Promise<TipoChaveSecundariaFVER.Summary[]> {
    let list: TipoChaveSecundariaFVER.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async addAllTipoChaveSecundariaVisitaPropriedadeRural(
    lista: TipoChaveSecundariaFVER.Summary[]
  ) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((tipo) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: tipo,
      };

      db.add(this.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL, obj);
    });
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_TIPO_CHAVE_SECUNDARIA_VISITA_PROPRIEDADE_RURAL);
  }
}

export default TipoChaveSecundariaFVERIDBService;
