import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';

import { useEffect, useMemo, useState } from 'react';
import { setHasFieldWithNoValueDistanciasInternas } from '../../../core/store/LIA.slice';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch } from 'react-redux';

type CustomCardProps = {
  title: string;
  name: string;
  whatDistance: string;
  updatedValue: string | null;
  formDisable: boolean | undefined;
  formValue: string | null;
  handleChangeDistanciasInternas: (
    name: string,
    value: string,
    whatDistance: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  whatDistance,
  formDisable,
  formValue,
  updatedValue,
  handleChangeDistanciasInternas,
}) => {
  return (
    <Card title={title}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChangeDistanciasInternas(
                    name,
                    e.target.value,
                    whatDistance,
                    updatedValue
                  )
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
                <Radio value='NA'>Não se Aplica (N.A)</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12}>
            {formValue === 'NAO' && (
              <Form.Item label='Qual a distancia ?' name={whatDistance}>
                <Input disabled={formDisable} />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};
type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function DistanciasInternas(props: InformacoesCadastroProps) {
  const dispatch = useDispatch();
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});
  const [hasNAOValueDistanciasInternas, setHasNAOValueDistanciasInternas] =
    useState<boolean>(false);

  const handleChangeDistanciasInternas = (
    name: string,
    value: string,
    whatDistance: string
  ) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [whatDistance]: undefined,
      });
    }
    if (value === 'NAO') {
      setHasNAOValueDistanciasInternas(true);
    } else {
      setHasNAOValueDistanciasInternas(false);
    }
  };

  useEffect(() => {
    const hasAnyNAOValue = fieldsToCheck.some(
      (fieldName) => props.form.getFieldValue(fieldName) === 'NAO'
    );
    setHasNAOValueDistanciasInternas(hasAnyNAOValue);
  }, [props.form, hasNAOValueDistanciasInternas, dispatch]);

  useEffect(() => {
    dispatch(
      setHasFieldWithNoValueDistanciasInternas(hasNAOValueDistanciasInternas)
    );
  }, [hasNAOValueDistanciasInternas, dispatch]);

  const fieldsToCheck = useMemo(
    () => [
      'distanciaMinima5mDaCercaIsolamento_2_1',
      'distanciaMinima20mDasResidencias_2_2',
      'distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3',
      'distanciaMinima200mEntreNucleoAvesCorte_2_4',
      'distanciaMinima100mDaEstradaVicinal_2_5',
      'distanciaMinima30mDosLimitesPerifericos_2_6',
      'distanciaMinima500mDaFabricaRacaoPropria_2_7',
      'distanciaMinima600mEntreNucleoDeAvesESuinos_2_8',
      'distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9',
    ],
    []
  );

  return (
    <>
      <Typography.Title level={5}>
        Distâncias mínimas entre estabelecimentos avícolas | Distâncias Internas
      </Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaDaCercaIsolamento_2_1'
            title='2.1 - O(s) aviário(s) estão distantes no mínimo 5,0m da cerca de isolamento?'
            name='distanciaMinima5mDaCercaIsolamento_2_1'
            whatDistance='distanciaDaCercaIsolamento_2_1'
            formValue={props.form.getFieldValue(
              'distanciaMinima5mDaCercaIsolamento_2_1'
            )}
            updatedValue={updatedValues['distanciaDaCercaIsolamento_2_1']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima20mDasResidencias_2_2'
            title='2.2 - A distância entre os aviário(s) e residências é de no mínimo 20m?'
            name='distanciaMinima20mDasResidencias_2_2'
            whatDistance='distanciaDasResidencias_2_2'
            formValue={props.form.getFieldValue(
              'distanciaMinima20mDasResidencias_2_2'
            )}
            updatedValue={updatedValues['distanciaMinima20mDasResidencias_2_2']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
            title='2.3 - A distância entre o núcleo de cria/ recria de aves poedeiras de ovos comerciais e o núcleo 
            de produção de ovos é de no mínimo 200m?'
            name='distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
            whatDistance='distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
            formValue={props.form.getFieldValue(
              'distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
            )}
            updatedValue={
              updatedValues[
                'distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima200mEntreNucleoAvesCorte_2_4'
            title='2.4 - A distância entre núcleos de aves de corte é de no mínimo 200m?'
            name='distanciaMinima200mEntreNucleoAvesCorte_2_4'
            whatDistance='distanciaEntreNucleoAvesCorte_2_4'
            formValue={props.form.getFieldValue(
              'distanciaMinima200mEntreNucleoAvesCorte_2_4'
            )}
            updatedValue={
              updatedValues['distanciaMinima200mEntreNucleoAvesCorte_2_4']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima100mDaEstradaVicinal_2_5'
            title='2.5 - A distância do(s) aviário(s) à estrada vicinal é de no mínimo 100m?'
            name='distanciaMinima100mDaEstradaVicinal_2_5'
            whatDistance='distanciaDaEstradaVicinal_2_5'
            formValue={props.form.getFieldValue(
              'distanciaMinima100mDaEstradaVicinal_2_5'
            )}
            updatedValue={
              updatedValues['distanciaMinima100mDaEstradaVicinal_2_5']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima30mDosLimitesPerifericos_2_6'
            title='2.6 - A distância do(s) aviário(s)aos limites periféricos da propriedade é de no mínimo 30m?'
            name='distanciaMinima30mDosLimitesPerifericos_2_6'
            whatDistance='distanciaDosLimitesPerifericos_2_6'
            formValue={props.form.getFieldValue(
              'distanciaMinima30mDosLimitesPerifericos_2_6'
            )}
            updatedValue={
              updatedValues['distanciaMinima30mDosLimitesPerifericos_2_6']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima500mDaFabricaRacaoPropria_2_7'
            title='2.7 - A fábrica de ração, quando dentro da granja, adota medidas de boas práticas de fabricação de ração?'
            name='distanciaMinima500mDaFabricaRacaoPropria_2_7'
            whatDistance='distanciaDaFabricaRacaoPropria_2_7'
            formValue={props.form.getFieldValue(
              'distanciaMinima500mDaFabricaRacaoPropria_2_7'
            )}
            updatedValue={
              updatedValues['distanciaMinima500mDaFabricaRacaoPropria_2_7']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima600mEntreNucleoDeAvesESuinos_2_8'
            title='2.8 - A distância entre o núcleo de aves e aves/suínos não comerciais é de no mínimo 600m?'
            name='distanciaMinima600mEntreNucleoDeAvesESuinos_2_8'
            whatDistance='distanciaEntreNucleoDeAvesESuinos_2_8'
            formValue={props.form.getFieldValue(
              'distanciaMinima600mEntreNucleoDeAvesESuinos_2_8'
            )}
            updatedValue={
              updatedValues['distanciaMinima600mEntreNucleoDeAvesESuinos_2_8']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChangeDistanciasInternas={handleChangeDistanciasInternas}
            key='distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9'
            title='2.9 - A distância entre núcleo de aves e outras espécies de animais não comerciais é de no mínimo 20m?'
            name='distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9'
            whatDistance='distanciaEntreNucleoAvesEOutrasEspecies_2_9'
            formValue={props.form.getFieldValue(
              'distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9'
            )}
            updatedValue={
              updatedValues[
                'distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9'
              ]
            }
          />
        </Col>
      </Row>
      <Col xs={24} lg={24}>
        <Form.Item label={'Observações'} name={'observacoesDistanciasInternas'}>
          <TextArea
            showCount
            rows={5}
            maxLength={2000}
            disabled={props.formDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
