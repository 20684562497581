import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import SinaisClinicosPanel from '../../components/SinaisClinicosPanel';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { useEffect, useState } from 'react';
import { SimNao } from '../../../core/enums/SimNao';

type VigilanciaAbelhasProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaAbelhas(props: VigilanciaAbelhasProps) {
  const [statusHaSuspeitaClinica, setStatusHaSuspeitaClinica] = useState();

  useEffect(() => {
    setStatusHaSuspeitaClinica(
      props.form.getFieldValue(['vigilanciaAbelha', 'haSuspeitaClinica'])
    );
  }, [props.form]);

  const triggerRecountTotaInspecionadosAbelhas = () => {
    const quantidadeExaminadosMelifera: number = props.form.getFieldValue([
      'vigilanciaAbelha',
      'quantidadeExaminadosMelifera',
    ]);
    const quantidadeExaminadosNativa: number = props.form.getFieldValue([
      'vigilanciaAbelha',
      'quantidadeExaminadosNativa',
    ]);

    const dados = [quantidadeExaminadosMelifera, quantidadeExaminadosNativa];

    let totalAnimaisExaminados: number = 0;

    dados.forEach((qtdade) => {
      if (!isNaN(Number(qtdade))) {
        totalAnimaisExaminados =
          Number(totalAnimaisExaminados) + Number(qtdade);
      }

      props.form.setFieldValue(
        ['vigilanciaAbelha', 'quantidadeExaminados'],
        totalAnimaisExaminados
      );
    });
  };

  const triggerRecountTotaVistoriadosAbelhas = () => {
    const quantidadeVistoriadosMelifera: number = props.form.getFieldValue([
      'vigilanciaAbelha',
      'quantidadeVistoriadosMelifera',
    ]);
    const quantidadeVistoriadosNativa: number = props.form.getFieldValue([
      'vigilanciaAbelha',
      'quantidadeVistoriadosNativa',
    ]);

    const dados = [quantidadeVistoriadosMelifera, quantidadeVistoriadosNativa];

    let totalExaminados: number = 0;

    dados.forEach((qtdade) => {
      if (!isNaN(Number(qtdade))) {
        totalExaminados = Number(totalExaminados) + Number(qtdade);
        props.form.setFieldValue(
          ['vigilanciaAbelha', 'quantidadeVistoriados'],
          totalExaminados
        );
      }
    });
  };

  return (
    <>
      <Divider orientation='left'>Abelhas</Divider>

      <Row gutter={24}>
        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Colméias de APIS melifera'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Vistoriadas'}
                  name={['vigilanciaAbelha', 'quantidadeVistoriadosMelifera']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaVistoriadosAbelhas();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Examinadas'}
                  name={['vigilanciaAbelha', 'quantidadeExaminadosMelifera']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaInspecionadosAbelhas();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Coméias de abelhas nativas'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Vistoriadas'}
                  name={['vigilanciaAbelha', 'quantidadeVistoriadosNativa']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaVistoriadosAbelhas();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Examinadas'}
                  name={['vigilanciaAbelha', 'quantidadeExaminadosNativa']}
                >
                  <Input
                    inputMode='numeric'
                    maxLength={4}
                    autoComplete='off'
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(event: any) => {
                      triggerRecountTotaInspecionadosAbelhas();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12} style={{ marginBottom: '24px' }}>
          <Card title='Total'>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Tooltip
                  title={
                    'Preencher com todos os animais observados, inclusive os examinados'
                  }
                >
                  <Form.Item
                    label={'Vistoriados'}
                    name={['vigilanciaAbelha', 'quantidadeVistoriados']}
                  >
                    <Input inputMode='numeric' disabled />
                  </Form.Item>
                </Tooltip>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={'Examinados'}
                  name={['vigilanciaAbelha', 'quantidadeExaminados']}
                >
                  <Input inputMode='numeric' disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Há suspeita clínica?'}
            name={['vigilanciaAbelha', 'haSuspeitaClinica']}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHaSuspeitaClinica}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaAbelha', 'haSuspeitaClinica'],
                  e.target.value
                );
                setStatusHaSuspeitaClinica(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            dependencies={['vigilanciaAbelha', 'haSuspeitaClinica']}
            label={'Suspeita clinica'}
            name={['vigilanciaAbelha', 'suspeitaClinica']}
            rules={[
              {
                required: statusHaSuspeitaClinica,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>

        <SinaisClinicosPanel
          listaSinaisClinicos={SinaisClinicos.abelhas()}
          pathToCoordenada={'vigilanciaAbelha'}
          form={props.form}
          required={statusHaSuspeitaClinica}
        />
      </Row>
    </>
  );
}
