import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Spin,
  notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import { FVER, FVV, LIA } from '../../../sdk/@types';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { CustomModal } from '../../components/CustomModal';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';
import PanelFVERInput from './PanelFVER_Input';
import PanelLIA from '../LIA/PanelLIA';
import LIAService from '../../../sdk/services/SIZ-API/LIA.service';
import FVVService from '../../../sdk/services/SIZ-API/FVV.service';
import PanelFVV from '../FVV/PanelFVV';

export default function PublicFVERCompleto() {
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const params = useParams<{ codigoVerificador: string }>();
  const [fver, setFVER] = useState<FVER.Detailed>();
  const [lia, setLIA] = useState<LIA.Response>();
  const [fvv, setFVV] = useState<FVV.Detailed>();
  const [finishedFetching, setFinishedFetching] = useState<boolean>(false);
  const [showModalConsulta, setShowModalConsulta] = useState(false);

  const fetchLIA = useCallback(async (codigoVerificador: string) => {
    await LIAService.getByCodigoVerificador_PublicAccess(codigoVerificador)
      .then(setLIA)
      .catch((e) => {
        /* setError(e); */
        setFinishedFetching(true);
      })
      .finally(() => {
        setFinishedFetching(true);
      });
  }, []);

  const fetchFVV = useCallback(async (codigoVerificador: any) => {
    await FVVService.getById(codigoVerificador)
      .then(setFVV)
      .catch((e) => {
        /* setError(e); */
        setFinishedFetching(true);
      })
      .finally(() => {
        setFinishedFetching(true);
      });
  }, []);

  const fetchFVERByCodigoVerificador = useCallback(
    async (codigoVerificador: string) => {
      await FVERService.getByCodigoVerificador_PublicAccess(codigoVerificador)

        .then(async (fver) => {
          if (!fver) setShowModalConsulta(true);
          setFVER(fver);
          if (fver.laudoIFSEAC) {
            await fetchLIA(fver.laudoIFSEAC?.codigoVerificador!);
          }

          setFinishedFetching(true);
        })
        .catch((e) => {
          console.log('ERROR, ', e.message);

          notification.info({
            message: e.message.includes(
              'Não foi encontrada nenhuma visita para o código verificador'
            )
              ? e.message
              : 'Não foi possível encontrar nenhuma visita',
            description: e.message.includes(
              'Não foi encontrada nenhuma visita para o código verificador'
            )
              ? undefined
              : 'Ocorreu um erro ao realizar a busca',
          });
          navigate('/');
          //setError(e);
          setFinishedFetching(true);
        })
        .finally(() => {
          setFinishedFetching(true);
        });
    },
    [fetchLIA, navigate, params]
  );

  const fetchFVERsByParams = useCallback(async () => {
    if (params.codigoVerificador)
      fetchFVERByCodigoVerificador(params.codigoVerificador);
    else
      notification.warn({
        message: 'Nenhum Código Verificador foi informado para a consulta',
      });
  }, [fetchFVERByCodigoVerificador, params]);

  useEffect(() => {
    fetchFVERsByParams();
  }, []);

  if (error) throw error;

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
      }}
    >
      <Space direction={'vertical'} style={{ width: '100%' }} size={24}>
        <Spin
          size='large'
          spinning={!finishedFetching}
          tip={'Buscando Formulários de Visita'}
        >
          {!fver && <Skeleton />}

          {fver && (
            <PanelFVERInput
              customTitle='Formulário de Visita'
              fver={fver}
              showPrintButton
            />
          )}

          {lia && (
            <div style={{ paddingTop: '1em' }}>
              <PanelLIA
                customTitle='Laudo de Inspeção'
                lia={lia}
                showPrintButton
              />
            </div>
          )}

          {/* {fvv && (
            <div style={{ paddingTop: '1em' }}>
              <PanelFVV
                customTitle='Formulário de Vigilância Veterinária'
                fvv={fvv}
                showPrintButton
              />
            </div>
          )} */}

          <Row justify={'end'} style={{ paddingTop: '12px', width: '100%' }}>
            <Col xs={24} sm={12} md={8}>
              <Button
                style={{ width: '100%' }}
                htmlType={'submit'}
                type={'primary'}
                onClick={() => navigate('/')}
              >
                Voltar
              </Button>
            </Col>
          </Row>
        </Spin>
      </Space>

      <CustomModal
        centered
        open={showModalConsulta}
        title={'Consultar'}
        width={500}
        footer={null}
        style={{
          backgroundColor: 'transparent',
          minHeight: '350px',
        }}
        bodyStyle={{
          backgroundColor: '#f7f5f3',
          fontWeight: 'bold',
        }}
        closable
        maskClosable={false}
        onCancel={() => {
          setShowModalConsulta(false);
        }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <Form
          layout={'vertical'}
          size={'small'}
          onFinish={(formValues) => {
            notification.warn({
              message:
                'Não encontramos nenhum Formulário de Visita para este CNPJ',
            });
            setShowModalConsulta(false);
            navigate(
              `/public/visualizarFVERs/${removeNonNumericCharactersFromString(
                formValues.cnpj
              )}`
            );
          }}
        >
          {
            <div>
              <Alert
                message={
                  'Não encontramos nenhum Formulário de Visita para este CNPJ. Deseja consultar outro CNPJ?'
                }
                type='warning'
                showIcon
              />
              <br />

              <Col span={24}>
                <Form.Item
                  label={'Cpf/Cnpj'}
                  name={'cnpj'}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder='CPF ou CNPJ' />
                </Form.Item>
              </Col>

              <Button
                type='primary'
                style={{ fontWeight: 'bold', height: '60px', width: '100%' }}
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Consultar
              </Button>
            </div>
          }
        </Form>
      </CustomModal>
    </div>
  );
}
