import {
  CloseOutlined,
  ExclamationCircleTwoTone,
  LoginOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import AuthorizationService from '../../../core/auth/Authorization.service';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { CustomModal } from '../../components/CustomModal';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';
import { formatCpf_Cnpj } from '../../../core/functions/formatCpf_Cnpj';
import usePageTitle from '../../../core/hooks/usePageTitle';
import Disconnected from '../../Icons/Disconnected';

enum UserType {
  'ABATEDOURO',
  'PRODUTOR',
  'REVENDA',
}

type FormConsultaType = {
  codigoVerificador: string;
  cpfCnpj: string;
};

export default function LoginMT() {
  usePageTitle('SIZ - Sistema de Informações Zoossanitárias');
  const { xs, sm, md } = useBreakpoint();
  const [form] = Form.useForm<FormConsultaType>();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<UserType>();
  const [showModalConsulta, setShowModalConsulta] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [disableFieldCpfCnpj, setDisableFieldCpfCnpj] = useState(false);
  const [disableFieldCodigoVerificador, setDisableFieldCodigoVerificador] =
    useState(false);

  const checkIfError = useCallback(async () => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      //@ts-ignore
      get: (searchParams, prop) => searchParams.get(prop),
    });

    console.log('fsdf4dfg4d56sfg4d65g4d65fg4df65g4df65g4fd65g4df65g4df65g');

    const error = params.error;
    if (error) setShowErrorAlert(true);
  }, []);

  const buscarFVERByCodigoVerificador = useCallback(
    (codigoVerificador) => {
      navigate(`/public/visualizarFVERCompleto/${codigoVerificador}`);
    },
    [navigate]
  );

  const buscarFVERByCpf = useCallback(
    (cpf) => {
      navigate(`/public/visualizarFVERs/${cpf}`);
    },
    [navigate]
  );

  const buscarTFRByCpf = useCallback(
    (cnpj) => {
      navigate(`/public/visualizarTFRs/${cnpj}`);
    },
    [navigate]
  );

  const handleFormSubmit = useCallback(
    (formValues: FormConsultaType) => {
      if (userType === UserType.ABATEDOURO || userType === UserType.PRODUTOR) {
        if (formValues.codigoVerificador)
          buscarFVERByCodigoVerificador(formValues.codigoVerificador);
        else {
          buscarFVERByCpf(
            removeNonNumericCharactersFromString(formValues.cpfCnpj)
          );
        }
      } else if (userType === UserType.REVENDA) {
        buscarTFRByCpf(
          removeNonNumericCharactersFromString(formValues.cpfCnpj)
        );
      }
    },
    [buscarFVERByCodigoVerificador, buscarFVERByCpf, buscarTFRByCpf, userType]
  );

  const loginPanel = () => {
    return (
      <Row
        align={'middle'}
        style={{ textAlign: 'center', width: '100%', height: '100%' }}
      >
        <Space size={8} direction='vertical' style={{ width: '100%' }}>
          <Col xs={24}>
            {sm && <Image width={256} src='/SIZ-256x256.png' preview={false} />}
            {!sm && (
              <Image width={128} src='/SIZ-128x128.png' preview={false} />
            )}
          </Col>
        </Space>
      </Row>
    );
  };

  const LoginButton = () => {
    return (
      <Button
        type='primary'
        style={{
          width: '100%',
          height: '60px',
          position: 'relative',
        }}
        icon={<LoginOutlined />}
        onClick={() => {
          window.location.replace(AuthorizationService.URL_LOGIN);
        }}
      >
        Entrar
      </Button>
    );
  };

  const ConsultarButton = () => {
    return (
      <Button
        type='primary'
        style={{
          width: '100%',
          height: '60px',
          position: 'relative',
        }}
        icon={<SearchOutlined />}
        onClick={() => {
          setShowModalConsulta(true);
        }}
      >
        Consultar
      </Button>
    );
  };

  const consultaPanel = () => {
    return (
      <>
        {(xs || md) && (
          <Row
            align={'middle'}
            style={{ textAlign: 'left', width: '100%', height: '100%' }}
          >
            <Space
              size={sm ? 50 : 25}
              direction='vertical'
              style={{ width: '100%' }}
            >
              <Col xs={24}>{LoginButton()}</Col>

              <Col xs={24}>{ConsultarButton()}</Col>
            </Space>
          </Row>
        )}

        {sm && !md && (
          <Row gutter={24}>
            <Col xs={12}>{LoginButton()}</Col>

            <Col xs={12}>{ConsultarButton()}</Col>
          </Row>
        )}
      </>
    );
  };

  useEffect(() => {
    checkIfError();
  }, []);

  useEffect(() => {}, [showAlert, userType]);

  useEffect(() => {}, [disableFieldCpfCnpj, disableFieldCodigoVerificador]);

  return (
    <>
      <CustomModal
        centered
        destroyOnClose
        open={true}
        title={
          <Typography.Text
            style={{ color: 'white', fontSize: xs ? '19px' : '' }}
          >
            SIZ - Sistema de Informações Zoossanitárias
          </Typography.Text>
        }
        width={700}
        footer={null}
        mask={false}
        style={{ minHeight: '350px' }}
      >
        {showErrorAlert && (
          <Alert
            message={
              <span style={{ marginLeft: '8px' }}>
                O SIZ está offline. Tente novamente mais tarde.
              </span>
            }
            showIcon
            style={{
              textAlign: 'left',
              marginBottom: '8px',
            }}
            type='error'
            icon={<Disconnected />}
          />
        )}

        {md && (
          <Row
            id='top_row'
            align={'middle'}
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Col md={11}>{loginPanel()}</Col>

            <Col md={2}>
              <Divider
                type='vertical'
                style={{
                  borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                  height: '200px',
                }}
              />
            </Col>

            <Col md={11}>{consultaPanel()}</Col>
          </Row>
        )}

        {!md && (
          <Row
            id='top_row'
            align={'middle'}
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Col xs={24}>{loginPanel()}</Col>

            <Col xs={24}>
              <Divider style={{ margin: '12px 0' }} />
            </Col>

            <Col xs={24}>{consultaPanel()}</Col>
          </Row>
        )}
      </CustomModal>

      <CustomModal
        centered
        open={showModalConsulta}
        title={'Consultar'}
        width={500}
        footer={null}
        style={{
          backgroundColor: 'transparent',
          minHeight: '350px',
        }}
        bodyStyle={{
          backgroundColor: '#f7f5f3',
          fontWeight: 'bold',
        }}
        closable
        maskClosable={false}
        onCancel={() => {
          setShowModalConsulta(false);
          setShowAlert(false);
        }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <Form<FormConsultaType>
          form={form}
          layout={'vertical'}
          size={'small'}
          onFinish={(formValues: FormConsultaType) => {
            let isValid = false;

            if (formValues.codigoVerificador !== undefined) isValid = true;

            if (formValues.cpfCnpj !== undefined) isValid = true;

            if (isValid) handleFormSubmit(formValues);
            else setShowAlert(true);
          }}
        >
          <Col xs={24} style={{ width: '100%' }}>
            <Form.Item
              label={'Quem é você?'}
              name={'userType'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                buttonStyle='solid'
                size='large'
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
                onChange={(e) => {
                  if (e.target.value === UserType.REVENDA) {
                    form.setFieldValue('codigoVerificador', undefined);
                    setDisableFieldCpfCnpj(false);
                  }
                  setUserType(e.target.value);
                }}
              >
                <Row gutter={0}>
                  <Col span={8}>
                    <Radio.Button
                      value={UserType.ABATEDOURO}
                      style={{ width: '100%', height: '60px' }}
                    >
                      <Typography.Text
                        style={{
                          width: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                          color: `${
                            userType === UserType.ABATEDOURO
                              ? 'white'
                              : '#274060'
                          }`,
                        }}
                      >
                        Abatedouro
                      </Typography.Text>
                    </Radio.Button>
                  </Col>
                  <Col span={8}>
                    <Radio.Button
                      value={UserType.PRODUTOR}
                      style={{ width: '100%', height: '60px' }}
                    >
                      <Typography.Text
                        style={{
                          width: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                          color: `${
                            userType === UserType.PRODUTOR ? 'white' : '#274060'
                          }`,
                        }}
                      >
                        Produtor Rural
                      </Typography.Text>
                    </Radio.Button>
                  </Col>
                  <Col span={8}>
                    <Radio.Button
                      value={UserType.REVENDA}
                      style={{ width: '100%', height: '60px' }}
                    >
                      <Typography.Text
                        style={{
                          width: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                          color: `${
                            userType === UserType.REVENDA ? 'white' : '#274060'
                          }`,
                        }}
                      >
                        Revenda
                      </Typography.Text>
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>

          {showAlert && (
            <Alert
              message={'Preencha pelo menos um dos campos para pesquisa'}
              type='error'
              showIcon
            />
          )}

          {userType !== undefined && (
            <div>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item label={'Cpf/Cnpj'} name={'cpfCnpj'}>
                    <Input
                      allowClear
                      disabled={disableFieldCpfCnpj}
                      maxLength={18}
                      placeholder='CPF ou CNPJ'
                      onChange={(event) => {
                        if (event.target.value && event.target.value !== '') {
                          setDisableFieldCodigoVerificador(true);
                        } else {
                          setDisableFieldCodigoVerificador(false);
                        }
                      }}
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                          event.preventDefault();
                        }

                        event.currentTarget.value = formatCpf_Cnpj(
                          event.target.value
                        );
                      }}
                      onKeyUp={(event: any) => {
                        //handle ctrl+v
                        const code = event.which || event.keyCode;
                        let charCode = String.fromCharCode(code).toLowerCase();
                        if (
                          (event.ctrlKey || event.metaKey) &&
                          charCode === 'v'
                        ) {
                          event.currentTarget.value = formatCpf_Cnpj(
                            event.target.value
                          );
                        }

                        //handle delete|backspace
                        if (event.keyCode === 8 || event.keyCode === 46) {
                          event.currentTarget.value = formatCpf_Cnpj(
                            event.target.value
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Divider
                    style={{
                      marginTop: -15,
                      marginBottom: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    OU
                  </Divider>
                </Col>

                <Col span={24} id='box'>
                  <Spin
                    spinning={userType === UserType.REVENDA}
                    tip={'Indisponível para Revenda'}
                    style={{
                      border: '1px solid',
                      borderColor: 'rgba(255,77,79, 0.45)',
                      color: '#ff4d4f',
                      backgroundColor: 'rgba(252,237,223, 0.3)',
                    }}
                    indicator={
                      <ExclamationCircleTwoTone twoToneColor={'#ff4d4f'} />
                    }
                  >
                    <Form.Item
                      label={'Código de autenticação'}
                      name={'codigoVerificador'}
                    >
                      <Input
                        allowClear
                        disabled={
                          userType === UserType.REVENDA ||
                          disableFieldCodigoVerificador
                        }
                        placeholder={'Código de autenticação enviado por email'}
                        onChange={(e) => {
                          if (e.target.value && e.target.value !== '') {
                            setDisableFieldCpfCnpj(true);
                          } else {
                            setDisableFieldCpfCnpj(false);
                          }
                        }}
                      />
                    </Form.Item>
                  </Spin>
                </Col>
              </Row>

              <Button
                type='primary'
                style={{ fontWeight: 'bold', height: '60px', width: '100%' }}
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Consultar
              </Button>
            </div>
          )}
        </Form>
      </CustomModal>
    </>
  );
}
