import { EstabelecimentoAvicola } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, { ServiceIDBPayload } from './ServiceIDB';
import ProdutorIDBService from './ProdutorIDB.service';
import PropriedadeIDBService from './PropriedadeIDB.service';

class EstabelecimentoAvicolaIDBService extends ServiceIDB {
  static DB_STORE_ESTABELECIMENTOAVICOLA = 'estabelecimentoAvicola';
  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_ID_NAME =
    'estabelecimentoAvicola_index_id';
  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_ID_KEY = 'payload.id';

  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_CPF_NAME =
    'estabelecimentoAvicola_index_cpf';
  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_CPF_KEY = 'payload.cpf';

  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_MUNICIPIO_NAME =
    'estabelecimentoAvicola_index_municipio';
  static DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_MUNICIPIO_KEY =
    'propriedade.municipio.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(
        EstabelecimentoAvicolaIDBService.DB_STORE_ESTABELECIMENTOAVICOLA
      )
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getAll() {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(
      EstabelecimentoAvicolaIDBService.DB_STORE_ESTABELECIMENTOAVICOLA
    );
    return list;
  }

  static async getAllPayload(): Promise<EstabelecimentoAvicola.Summary[]> {
    let list: EstabelecimentoAvicola.Summary[] = [];

    await this.getAll().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static add(estabelecimentoAvicola: EstabelecimentoAvicola.Summary) {
    return openDB(DBConfig.DB_NAME).then((db) => {
      /* if (EstabelecimentoAvicola.id === 593584)
        throw new Error('Erro adicionando estabelecimentoAvicola 593584'); */

      const date = new Date();
      const obj = {
        date: date,
        payload: estabelecimentoAvicola,
      };

      db.add(this.DB_STORE_ESTABELECIMENTOAVICOLA, obj);

      estabelecimentoAvicola.exploracaos?.forEach((exp) => {
        exp.produtores.forEach((produtor) => {
          db.add(ProdutorIDBService.DB_STORE_PRODUTOR, {
            produtor: {
              cpf: produtor.produtor.cpfCnpj,
              nome: produtor.produtor.nome,
            },
            estabelecimentoAvicola: {
              id: estabelecimentoAvicola.id,
              municipio: estabelecimentoAvicola.municipio,
            },
          });
        });
      });
    });
  }

  static async addAllEstabelecimentoAvicola(
    lista: EstabelecimentoAvicola.Summary[]
  ) {
    const db = await openDB(DBConfig.DB_NAME);
    lista.forEach((estabelecimentoAvicola) => {
      const date = new Date();
      const obj = {
        date: date,
        payload: estabelecimentoAvicola,
      };

      db.add(this.DB_STORE_ESTABELECIMENTOAVICOLA, obj);

      estabelecimentoAvicola.exploracaos?.forEach((exp) => {
        exp.produtores.forEach((produtor) => {
          db.add(ProdutorIDBService.DB_STORE_PRODUTOR, {
            produtor: {
              cpf: produtor.produtor.cpfCnpj,
              nome: produtor.produtor.nome,
            },
            estabelecimentoAvicola: {
              id: estabelecimentoAvicola.id,
              municipio: estabelecimentoAvicola.municipio,
            },
          });
        });
      });
    });
  }

  static async getByProdutorCpf(
    cpf: string
  ): Promise<EstabelecimentoAvicola.Summary[]> {
    let listaEstabelecimentoAvicola: EstabelecimentoAvicola.Summary[] = [];
    return await super
      ._getAllFromIndex(
        ProdutorIDBService.DB_STORE_PRODUTOR,
        ProdutorIDBService.DB_STORE_PRODUTOR_INDEX_CPF_NAME,
        cpf
      )
      .then((codigos: any[]) => {
        return Promise.all(
          codigos.map(async (element) => {
            return await PropriedadeIDBService.getById(
              element.propriedade.id
            ).then((e) => {
              listaEstabelecimentoAvicola = listaEstabelecimentoAvicola.concat(
                e.payload
              );
              return e.payload;
            });
          })
        );
      });
  }

  static async getById(id: number): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_ESTABELECIMENTOAVICOLA,
      this.DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_ID_NAME,
      id
    );
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    return db.clear(this.DB_STORE_ESTABELECIMENTOAVICOLA);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super
      ._getAllFromIndex(
        this.DB_STORE_ESTABELECIMENTOAVICOLA,
        this.DB_STORE_ESTABELECIMENTOAVICOLA_INDEX_MUNICIPIO_NAME,
        codg_ibge
      )
      .then(async (estabelecimentoAvicolas: any[]) => {
        return await Promise.all(
          estabelecimentoAvicolas.map((estabelecimentoAvicola) => {
            return super._delete(
              this.DB_STORE_ESTABELECIMENTOAVICOLA,
              estabelecimentoAvicola.id
            );
          })
        );
      });
  }
}

export default EstabelecimentoAvicolaIDBService;
