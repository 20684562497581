import { useEffect } from 'react';
import { Card, Descriptions, Row, Typography } from 'antd';
import { TermoFiscalizacao } from '../../../sdk/@types';
import moment from 'moment';
import HeaderGovernoMt from './HeaderGovernoMt.report';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';

type PrintTFRProps = {
  tfr?: TermoFiscalizacao.Response | null;
};

type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
};

export default function PrintTFR(props: PrintTFRProps) {
  useEffect(() => {}, [props.tfr]);

  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {Array.isArray(data) &&
              data.map((item) => (
                <Descriptions.Item
                  key={item.value}
                  label={item.label}
                  span={item.span ? item.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  const panelComponent = (
    title: string,
    children: React.ReactNode,
    newPage?: boolean
  ) => {
    return (
      <div className={`${newPage ? newPage : ''}`} style={{ display: 'block' }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
        >
          {children}
        </Card>
      </div>
    );
  };

  return (
    <>
      <HeaderGovernoMt />
      <tbody>
        <tr>
          <td>
            <Row gutter={24} justify={'center'}>
              <Typography.Title level={4}>
                FORMULÁRIO TERMO DE FISCALIZAÇÃO DE REVENDA
              </Typography.Title>
            </Row>
            <br />

            {panelDescription('Dados da Visita', [
              {
                label: 'Número',
                value: props.tfr?.numero,
              },
              {
                label: 'Data',
                value: moment(props.tfr?.dataVisita).format('DD/MM/YYYY HH:mm'),
              },
            ])}
            <br />
            {panelDescription('Resumo', [
              {
                value: props.tfr?.ocorrencias,
              },
            ])}
            <br />
            {props.tfr?.revenda &&
              panelDescription('Revenda', [
                {
                  label: 'CNPJ',
                  value: props.tfr.revenda.cpfCnpj?.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                  ),
                  span: 2,
                },
                {
                  label: 'Nome (Fantasia)',
                  value: props.tfr.revenda.nome,
                },
                {
                  label: 'Apelido',
                  value: props.tfr.revenda.apelido,
                },
                {
                  label: 'Telefone',
                  value: props.tfr.revenda.codigo,
                },
                {
                  label: 'Email',
                  value: props.tfr.revenda.email,
                },
              ])}
            <br />
            {props.tfr?.servidores &&
              panelComponent(
                'Servidores',
                props.tfr?.servidores?.map((chavePrimaria) =>
                  panelDescription('', [
                    {
                      label: 'Nome',
                      value: chavePrimaria.servidor?.pessoa?.nome,
                    },
                    {
                      label: 'CPF',
                      value: chavePrimaria.servidor?.pessoa?.cpfCnpj?.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4'
                      ),
                      span: 2,
                    },

                    {
                      label: 'Matrícula',
                      value: chavePrimaria.servidor?.matricula,
                    },
                    {
                      label: 'Profissão',
                      value: chavePrimaria.servidor?.profissao?.nome,
                    },
                  ])
                )
              )}
            <br />
            {panelComponent(
              'Programas e Atividades',
              props.tfr?.chavesPrincipaisTermoFiscalizacao
                ? props.tfr.chavesPrincipaisTermoFiscalizacao.map(
                    (chavePrimaria, index) => (
                      <div className='newPage' key={`chave-primaria-${index}`}>
                        {panelDescription(
                          chavePrimaria?.tipoChavePrincipalTermoFiscalizacao
                            ?.nome as any,
                          chavePrimaria?.chavesSecundariasTermoFiscalizacao
                            ? chavePrimaria.chavesSecundariasTermoFiscalizacao.map(
                                (chaveSecundaria, secIndex) => ({
                                  value:
                                    chaveSecundaria
                                      .tipoChaveSecundariaTermoFiscalizacao
                                      ?.nome,
                                  span: 2,
                                  key: `chave-secundaria-${secIndex}`,
                                })
                              )
                            : []
                        )}
                      </div>
                    )
                  )
                : null
            )}
            <br />
            {props.tfr &&
              panelComponent(
                'Assinatura',
                <div style={{ marginTop: '-10px' }}>
                  {props.tfr.statusAssinatura && (
                    <Typography.Text>
                      {(() => {
                        let label;
                        switch (props.tfr.statusAssinatura) {
                          case 'ASSINADO_DIGITALMENTE':
                            label = 'Assinado Digitalmente';
                            break;
                          case 'ASSINADO_FISICAMENTE':
                            label = 'Assinado Fisicamente';
                            break;
                          case 'PRODUTOR_RECUSOU_ASSINAR':
                            label = 'Responsável recusou-se a assinar';
                            break;
                          default:
                            label = '';
                        }
                        return label;
                      })()}
                    </Typography.Text>
                  )}
                  {props.tfr?.assinatura && (
                    <img
                      src={props.tfr?.assinatura}
                      alt='my signature'
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        width: '150px',
                        height: '20vh',
                      }}
                    />
                  )}
                </div>
              )}

            <br />
            <br />
          </td>
        </tr>
      </tbody>
    </>
  );
}
