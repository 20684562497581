import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DeleteOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import * as LIAActions from '../../../core/store/LIA.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useDevOptions from '../../../core/hooks/useDevOptions';
import { ParecerLaudoInspecao } from '../../../core/enums/ParecerLaudoInspecao';
import ButtonToForm from '../../components/ButtonToForm';
import FilterPanelLIA from './FilterPanelLIA';
import { LIA } from '../../../sdk/@types/LIA';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { useNavigate } from 'react-router';

export default function ListLIA() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { idInTables } = useDevOptions();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();
  const { xs, sm } = useBreakpoint();

  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, fvv: LIA.Summary) => {
    setExpandedKey((prev: any) => {
      const newKey = fvv.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };
  //end control expandedKeys

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const lia = useSelector((state: RootState) => state.lia.lias);
  const query = useSelector((state: RootState) => state.lia.query);
  const fetching = useSelector((state: RootState) => state.lia.fetching);

  if (!online)
    throw new Error(
      'Não é possível visualizar a lista de Laudos de Inspeção no modo offline'
    );
  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, []);

  useEffect(() => {}, [lia]);

  const handleDelete = useCallback(
    async (id) => {
      if (id) {
        setDeleting(true);
        try {
          await dispatch(LIAActions.removeLIA(id));
          setDeleting(false);
          notification.success({
            message: 'Laudo de Inspeção removido com sucesso',
          });
          dispatch(LIAActions.filterLIA(query));
        } catch (e: any) {
          setDeleting(false);
          notification.error({
            message: 'Não foi possível remover o Laudo de Inspeção',
            description: e.message,
          });
        }
      }
    },
    [dispatch, query]
  );

  return (
    <>
      <FilterPanelLIA />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<LIA.Summary>
                rowKey={'id'}
                dataSource={lia?.content}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      LIAActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: lia?.totalElements,
                  pageSize: query.size,
                }}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Laudos de Inspeção
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(LIAActions.filterLIA(query));
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
                size={'small'}
                columns={[
                  {
                    dataIndex: 'id',
                    width: idInTables ? 100 : 0,
                    render(id) {
                      return (
                        idInTables && (
                          <Typography.Text>{`ID: ${id}`}</Typography.Text>
                        )
                      );
                    },
                  },

                  {
                    dataIndex: 'id',
                    render(_, lia) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontWeight: '500' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {moment(new Date(lia.dataParecer)).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </Typography.Text>

                            <Typography.Text>
                              {lia.registro
                                ? 'REGISTRO'
                                : 'MANUTENÇÃO DO REGISTRO'}
                            </Typography.Text>
                          </Space>

                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {lia.municipioFiscalizado?.nome}
                            </Typography.Text>

                            <Typography.Text>
                              {lia.nomeEstabelecimento}
                            </Typography.Text>

                            <Typography.Text>
                              {`Parecer ${ParecerLaudoInspecao.valueOf(
                                lia.parecer!
                              )}`}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },

                  {
                    dataIndex: 'id',
                    title: '',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<SearchOutlined />}
                            onClick={() => navigate(`/lia/visualizar/${id}`)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Popconfirm
                            title={'Deseja remover Laudo de Inspeção'}
                            onConfirm={() => {
                              handleDelete(id);
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>
                        </Space>
                      );
                    },
                  },
                ]}
                expandable={{
                  columnWidth: '50px',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <MinusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ) : (
                      <PlusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ),
                  expandRowByClick: true,
                  expandedRowKeys: [expandedKey],
                  expandedRowRender: (lia) => (
                    <Space style={{ fontSize: '0.8em' }}>
                      <Typography.Text>
                        {lia.fver?.id === -1 ? (
                          'FVER - Pendente'
                        ) : (
                          <ButtonToForm
                            label={`FVER - ${lia.fver?.numero}`}
                            route={`/visitas/visualizar/${lia.fver?.id}`}
                            type='VIEW'
                          />
                        )}
                      </Typography.Text>
                    </Space>
                  ),
                  onExpand: onExpand,
                  expandedRowClassName: () => 'expanded-row-siz',
                }}
                showHeader={false}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
