import { Spin } from 'antd';
import Home from '../features/Home/Home';

export default function LandingView() {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Spin
          size='large'
          spinning={false}
          tip={'Estamos buscando as suas informações...'}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <Home />
        </Spin>
      </div>
    </>
  );
}
