import { Spin } from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ListTFR from '../features/FormTFR/ListTFR';
import { useEffect } from 'react';

export default function TFRList() {
  usePageTitle('SIZ - Lista de TFRs');
  const { setLoading, setMode, setFirstOpening, loading } = useLoadingPage();
  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='a lista de TFR'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <ListTFR />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
