import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import CreateLIA from '../features/LIA/CreateLIA';

export default function LIACreateView() {
  usePageTitle('Novo Laudo de Inspeção');

  const { setMode, setFirstOpening } = useLoadingPage();

  setFirstOpening(false);
  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <CreateLIA />
      </ErrorBoundary>
    </>
  );
}
