export enum TipoDeAgrupamento {
  APIARIO = 'Apiário',
  APRISCO = 'Aprisco',
  BAIAS = 'Baias',
  ESTABULO = 'Estábulo',
  GALPOES = 'Galpões',
  NUCLEOS = 'Núcleos',
  PASTOS = 'Pastos',
  PIQUETES = 'Piquetes',
}
export namespace TipoDeAgrupamento {
  export function keys(): string[] {
    return Object.keys(TipoDeAgrupamento).filter(
      //precisa filtrar as chaves que são nomes de métodos
      (key) => key !== 'keys' && key !== 'valueOf' && key !== 'keyOf'
    );
  }

  export function valueOf(key: string) {
    return TipoDeAgrupamento[key as keyof typeof TipoDeAgrupamento];
  }

  export function keyOf(value: string) {
    const indexOfS = Object.values(TipoDeAgrupamento).indexOf(
      value as unknown as TipoDeAgrupamento
    );

    const key = Object.keys(TipoDeAgrupamento)[indexOfS];

    return key;
  }
}
