import { Modal, ModalProps, Typography } from 'antd';
import { FunctionComponent } from 'react';

type CustomModalProps = {
  fullscreen?: boolean;
} & ModalProps;

export const CustomModal: FunctionComponent<CustomModalProps> = (props) => {
  let bodyStyle = {
    padding: 0,
    ...props.bodyStyle,
  };

  return (
    <>
      <Modal
        {...props}
        title={null}
        bodyStyle={{ ...bodyStyle }}
        closable={props.closable ? props.closable : false}
      >
        {props.title && (
          <div
            className='customModalTitle'
            style={{
              width: '100%',
              height: '60px',
              boxShadow: '0 0 20px -10px rgba(@text-color, 0.5)',
              backgroundColor: '#337ab7',
            }}
          >
            <Typography.Title
              level={4}
              className='centeredElement'
              style={{
                color: 'white',
                margin: 'auto',
                paddingTop: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {props.title}
            </Typography.Title>
          </div>
        )}

        <div
          className='customModalBody'
          style={{
            margin: props.fullscreen ? 0 : '25px',
            paddingBottom: props.fullscreen ? 0 : '25px',
            height: '100%',
          }}
        >
          {props.children}
        </div>
      </Modal>
    </>
  );
};
