import { useEffect } from 'react';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import LIAView from '../features/LIA/LiaView';
import ErrorBoundary from '../components/ErrorBoundary';

export default function LIAViewWrapper() {

  usePageTitle('Visualizando Laudos de Inspeção');

  const { setLoading, setMode, setFirstOpening } = useLoadingPage();

  useEffect(() => {
    setLoading(true);
    setFirstOpening(true);
    setMode('VIEW');
  }, [setLoading, setMode, setFirstOpening]);

  return (
    <>
      <ErrorBoundary component='formulário'>
        <LIAView />
      </ErrorBoundary>
    </>
  );
}
