import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type DocumentosProtocolacaoProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  specify?: string;
};
export default function PrintDocumentrosProtocolacao({
  data,
}: DocumentosProtocolacaoProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.specify && (
                  <div>
                    <strong>Especificar:</strong> <br />
                    {row.specify}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {data &&
          panelDescription('Documentos Protocolados', [
            ...(data?.manutencaoRegistro === false
              ? [
                  {
                    label:
                      '8.1 - Requerimento de solicitação ao INDEA-MT, na forma do Anexo II (disponível no site do INDEA).',
                    condition: data.possuiRequerimentoSolicitacao_8_1,
                    specify: data.requerimentoSolicitacaoDescricao_8_1,
                  },
                  {
                    label:
                      '8.2 - Cadastro de proprietário e cadastro de propriedade no INDEA/MT.',
                    condition: data.possuiCadastroProprietario_8_2,
                    specify: data.cadastroProprietarioDescricao_8_2,
                  },
                  {
                    label:
                      '8.3 - Anotação de responsabilidade técnica do médico veterinário que realiza o controle higiênico-sanitário do estabelecimento avícola.',
                    condition: data.possuiResponsabilidadeTecnica_8_3,
                    specify: data.responsabilidadeTecnicaDescricao_8_3,
                  },
                  {
                    label:
                      '8.4 - Planta de localização da propriedade ou outro instrumento, a critério do INDEA-MT, capaz de demonstrar as instalações, estradas, cursos d`água, propiedades limítrofes e suas respectivas atividades.',
                    condition: data.possuiPlantaLocalizacaoPropriedade_8_4,
                    specify: data.plantaLocalizacaoPropriedadeDescricao_8_4,
                  },
                  {
                    label:
                      '8.5 - Planta baixa das instalações do estabelecimento ou outro instrumento, a critério do INDEAS-MT, capaz de demontrar toda a infraestrutura instalada.',
                    condition: data.possuiPlantaBaixaEstabelecimento_8_5,
                    specify: data.plantaBaixaEstabelecimentoDescricao_8_5,
                  },
                  {
                    label:
                      '8.6 - Memorial descritivo das medidas higiênico-sanitárias e de biossegurança que serão adotadas pelo estabelecimento avícola e dos processos tecnológicos, contendo descrição.',
                    condition:
                      data.possuiMemorialMedidasHigienicoSanitarias_8_6,
                    specify:
                      data.memorialMedidasHigienicoSanitariasDescricao_8_6,
                  },
                  {
                    label:
                      '8.7 - Documento comprobatório da qualidade microbiológica da água de consumo das aves.',
                    condition:
                      data.possuiDocumentoComprobatorioQualidadeAgua_8_7,
                    specify:
                      data.documentoComprobatorioQualidadeAguaDescricao_8_7,
                  },

                  {
                    label: 'Descreva as Medida(s)',
                    condition: data.medidasDeBiosseguridadeCompensatorias, // Este campo contém uma descrição, portanto usamos 'value' em vez de 'condition'
                  },
                ]
              : [
                  {
                    label:
                      '8.3 - Anotação de responsabilidade técnica do médico veterinário que realiza o controle higiênico-sanitário do estabelecimento avícola.',
                    condition: data.possuiResponsabilidadeTecnica_8_3,
                    specify: data.responsabilidadeTecnicaDescricao_8_3,
                  },
                  {
                    label:
                      '8.7 - Documento comprobatório da qualidade microbiológica da água de consumo das aves.',
                    condition:
                      data.possuiDocumentoComprobatorioQualidadeAgua_8_7,
                    specify:
                      data.documentoComprobatorioQualidadeAguaDescricao_8_7,
                  },
                ]),
          ])}
      </div>
    </>
  );
}
