import AuthorizationService from './Authorization.service';

enum Permissions {
  admin = 'admin',
  dev = 'dev',
  enfermidadeAbatedouroFrigorifico_criar = 'enfermidadeAbatedouroFrigorifico_criar',
  enfermidadeAbatedouroFrigorifico_editar = 'enfermidadeAbatedouroFrigorifico_editar',
  enfermidadeAbatedouroFrigorifico_remover = 'enfermidadeAbatedouroFrigorifico_remover',
  erroSincronizacao_criar = 'erroSincronizacao_criar',
  erroSincronizacao_editar = 'erroSincronizacao_editar',
  erroSincronizacao_remover = 'erroSincronizacao_remover',
  formIN_criar = 'formIN_criar',
  formIN_editar = 'formIN_editar',
  formIN_remover = 'formIN_remover',
  formNotifica_criar = 'formNotifica_criar',
  formNotifica_editar = 'formNotifica_editar',
  formNotifica_remover = 'formNotifica_remover',
  formulárioCustomizavel_criar = 'formulárioCustomizavel_criar',
  formulárioCustomizavel_editar = 'formulárioCustomizavel_editar',
  formulárioCustomizavel_remover = 'formulárioCustomizavel_remover',
  laudoInspecao_criar = 'laudoInspecao_criar',
  laudoInspecao_editar = 'laudoInspecao_editar',
  laudoInspecao_remover = 'laudoInspecao_remover',
  registroDeEntradaLasa_criar = 'registroDeEntradaLasa_criar',
  registroDeEntradaLasa_editar = 'registroDeEntradaLasa_editar',
  registroDeEntradaLasa_remover = 'registroDeEntradaLasa_remover',
  termoFiscalizacao_criar = 'termoFiscalizacao_criar',
  termoFiscalizacao_editar = 'termoFiscalizacao_editar',
  termoFiscalizacao_remover = 'termoFiscalizacao_remover',
  tester = 'tester',
  tipoChavePrincipalTermoFiscalizacao_criar = 'tipoChavePrincipalTermoFiscalizacao_criar',
  tipoChavePrincipalTermoFiscalizacao_editar = 'tipoChavePrincipalTermoFiscalizacao_editar',
  tipoChavePrincipalTermoFiscalizacao_remover = 'tipoChavePrincipalTermoFiscalizacao_remover',
  tipoChavePrincipalVisitaPropriedadeRural_criar = 'tipoChavePrincipalVisitaPropriedadeRural_criar',
  tipoChavePrincipalVisitaPropriedadeRural_editar = 'tipoChavePrincipalVisitaPropriedadeRural_editar',
  tipoChavePrincipalVisitaPropriedadeRural_remover = 'tipoChavePrincipalVisitaPropriedadeRural_remover',
  tipoChaveSecundariaTermoFiscalizacao_criar = 'tipoChaveSecundariaTermoFiscalizacao_criar',
  tipoChaveSecundariaTermoFiscalizacao_editar = 'tipoChaveSecundariaTermoFiscalizacao_editar',
  tipoChaveSecundariaTermoFiscalizacao_remover = 'tipoChaveSecundariaTermoFiscalizacao_remover',
  tipoChaveSecundariaVisitaPropriedadeRural_criar = 'tipoChaveSecundariaVisitaPropriedadeRural_criar',
  tipoChaveSecundariaVisitaPropriedadeRural_editar = 'tipoChaveSecundariaVisitaPropriedadeRural_editar',
  tipoChaveSecundariaVisitaPropriedadeRural_remover = 'tipoChaveSecundariaVisitaPropriedadeRural_remover',
  usuarios_criar = 'usuarios_criar',
  usuarios_editar = 'usuarios_editar',
  usuarios_remover = 'usuarios_remover',
  vigilanciaVeterinaria_criar = 'vigilanciaVeterinaria_criar',
  vigilanciaVeterinaria_editar = 'vigilanciaVeterinaria_editar',
  vigilanciaVeterinaria_remover = 'vigilanciaVeterinaria_remover',
  visitaPropriedadeRural_criar = 'visitaPropriedadeRural_criar',
  visitaPropriedadeRural_editar = 'visitaPropriedadeRural_editar',
  visitaPropriedadeRural_remover = 'visitaPropriedadeRural_remover',
}

export default class PermissionsService {
  static get admin() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.admin
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get dev() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.dev
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tester() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.tester
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get enfermidadeAbatedouroFrigorifico_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.enfermidadeAbatedouroFrigorifico_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get enfermidadeAbatedouroFrigorifico_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.enfermidadeAbatedouroFrigorifico_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get enfermidadeAbatedouroFrigorifico_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.enfermidadeAbatedouroFrigorifico_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get erroSincronizacao_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.erroSincronizacao_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get erroSincronizacao_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.erroSincronizacao_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get erroSincronizacao_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.erroSincronizacao_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formIN_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formIN_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formIN_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formIN_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formIN_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formIN_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formNotifica_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formNotifica_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formNotifica_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formNotifica_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formNotifica_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formNotifica_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formulárioCustomizavel_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.formulárioCustomizavel_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formulárioCustomizavel_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.formulárioCustomizavel_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get formulárioCustomizavel_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.formulárioCustomizavel_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get laudoInspecao_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.laudoInspecao_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get laudoInspecao_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.laudoInspecao_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get laudoInspecao_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.laudoInspecao_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get registroDeEntradaLasa_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.registroDeEntradaLasa_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get registroDeEntradaLasa_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.registroDeEntradaLasa_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get registroDeEntradaLasa_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.registroDeEntradaLasa_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get termoFiscalizacao_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.termoFiscalizacao_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get termoFiscalizacao_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.termoFiscalizacao_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get termoFiscalizacao_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.termoFiscalizacao_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalTermoFiscalizacao_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.tipoChavePrincipalTermoFiscalizacao_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalTermoFiscalizacao_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChavePrincipalTermoFiscalizacao_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalTermoFiscalizacao_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChavePrincipalTermoFiscalizacao_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalVisitaPropriedadeRural_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChavePrincipalVisitaPropriedadeRural_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalVisitaPropriedadeRural_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChavePrincipalVisitaPropriedadeRural_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChavePrincipalVisitaPropriedadeRural_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChavePrincipalVisitaPropriedadeRural_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaTermoFiscalizacao_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaTermoFiscalizacao_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaTermoFiscalizacao_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaTermoFiscalizacao_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaTermoFiscalizacao_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaTermoFiscalizacao_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaVisitaPropriedadeRural_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaVisitaPropriedadeRural_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaVisitaPropriedadeRural_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaVisitaPropriedadeRural_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get tipoChaveSecundariaVisitaPropriedadeRural_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome ===
        Permissions.tipoChaveSecundariaVisitaPropriedadeRural_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get usuarios_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.usuarios_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get usuarios_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.usuarios_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get usuarios_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.usuarios_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get vigilanciaVeterinaria_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.vigilanciaVeterinaria_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get vigilanciaVeterinaria_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.vigilanciaVeterinaria_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get vigilanciaVeterinaria_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.vigilanciaVeterinaria_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get visitaPropriedadeRural_criar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) => permissao.nome === Permissions.visitaPropriedadeRural_criar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get visitaPropriedadeRural_editar() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.visitaPropriedadeRural_editar
    );

    if (result && result.length > 0) return true;
    return false;
  }

  static get visitaPropriedadeRural_remover() {
    if (!AuthorizationService.getUsuarioSIZ()?.permissoes) return false;

    const result = AuthorizationService.getUsuarioSIZ()?.permissoes?.filter(
      (permissao) =>
        permissao.nome === Permissions.visitaPropriedadeRural_remover
    );

    if (result && result.length > 0) return true;
    return false;
  }

  protected valueOf(key: string) {
    return Permissions[key as keyof typeof Permissions];
  }

  protected keyOf(value: string) {
    const indexOfS = Object.values(Permissions).indexOf(
      value as unknown as Permissions
    );
    const key = Object.keys(Permissions)[indexOfS];
    return key;
  }
}
