import { Checkbox, Col, Form, FormInstance, Row } from 'antd';
import { SinaisClinicos } from '../../core/enums/SinaisClinicos';

type SinaisClinicosPanelProps = {
  form: FormInstance;
  formDisabled?: boolean;
  listaSinaisClinicos: string[];
  pathToCoordenada: string;
  required?: boolean;
};

export default function SinaisClinicosPanel(props: SinaisClinicosPanelProps) {
  return (
    <>
      <Col xs={24} lg={24}>
        <Form.Item
          label={'Sinais Clínicos'}
          name={[props.pathToCoordenada, 'sinaisSintomasObservados']}
          valuePropName={'checked'}
          rules={[
            {
              required: props.required,
              message: 'O campo é obrigatório',
            },
          ]}
        >
          <Checkbox.Group
            style={{ width: '100%' }}
            defaultValue={props.form.getFieldValue([
              props.pathToCoordenada,
              'sinaisSintomasObservados',
            ])}
            onChange={(e) => {
              let listSintomas: any[] = [];

              e.forEach((name) => {
                listSintomas = listSintomas.concat([
                  SinaisClinicos.valueOf(name.toString()),
                ]);
              });

              props.form.setFieldsValue({
                [props.pathToCoordenada]: {
                  sinaisSintomasObservados: e,
                },
              });
            }}
          >
            <Row>
              {props.listaSinaisClinicos?.map((sintoma: any) => (
                <Col xs={24} lg={8} sm={12}>
                  <Checkbox key={sintoma} value={sintoma}>
                    {SinaisClinicos.valueOf(sintoma)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </>
  );
}
