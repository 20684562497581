import { FormEduca } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormEducaService extends Service {
  static BASE_URL = '/form-educacionais';

  static getAll(query: FormEduca.Query): Promise<FormEduca.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<FormEduca.Paginated>(
      this.BASE_URL.concat(queryString)
    ).then(this.getData);
  }

  static async add(
    formEducaRequest: FormEduca.Request,
    files: File[]
  ): Promise<FormEduca.Request> {
    try {
      const formData = new FormData();
      buildFormData(formData, formEducaRequest, files);
      // Adicionar arquivos ao FormData
      files.forEach((file, index) => {
        formData.append(`anexos[${index}]`, file);
      });
      const response = await this.Http.post<FormEduca.Request>(
        this.BASE_URL,
        formData
      );
      return this.getData(response);
    } catch (error) {
      throw error;
    }
  }

  static async update(id: number, data: FormEduca.Request) {
    return this.Http.put<FormEduca.Request>(
      `${this.BASE_URL}/${id}`,
      data
    ).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get<FormEduca.Response>(`${this.BASE_URL}/${id}`).then(
      this.getData
    );
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`${this.BASE_URL}/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static async addParticipant(
    formEducaId: number,
    participante: FormEduca.ParticipanteRequest
  ) {
    try {
      const response = await this.Http.patch<FormEduca.ParticipanteRequest>(
        `${this.BASE_URL}/${formEducaId}/participante`,
        participante
      );
      const data = this.getData(response);
      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else {
        console.error('Unknown error:', error);
      }
      throw error;
    }
  }

  static getAttachmentByName(nomeArquivo: string): Promise<string> {
    return this.Http.get<Blob>(
      `${this.BASE_URL}/anexo/${encodeURIComponent(nomeArquivo)}`,
      { responseType: 'blob' }
    )
      .then((response) => URL.createObjectURL(response.data))
      .catch((error) => {
        throw new Error(
          `Erro ao buscar o arquivo: ${
            error.response?.statusText || error.message
          }`
        );
      });
  }
}

function buildFormData(
  formData: FormData,
  data: any,
  files: File[],
  parentKey: string | null = null
) {
  if (Array.isArray(data)) {
    data.forEach((value, index) => {
      const key = parentKey ? `${parentKey}[${index}]` : String(index);
      buildFormData(formData, value, files, key);
    });
  } else if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      buildFormData(formData, value, files, newKey);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey!, value);
  }
}

export default FormEducaService;
