import { useCallback, useState } from 'react';
import { FormVIN } from '../../sdk/@types';
import FormVINIDBService from '../../sdk/services/indexeddb/FormVINIDB.service';
import FormVINService from '../../sdk/services/SIZ-API/FormVIN.service';
import generateHash from '../functions/generateHash';
import { SYNC_FORM_VIN_NEEDED } from '../../sdk/@types/ServiceWorker.types';
import localforage from 'localforage';
import AuthorizationService from '../auth/Authorization.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useFormVIN() {
  const { online } = useNavigatorStatus();
  const [formVIN, setFormVIN] = useState<FormVIN.Input>();
  const [formVINs, setFormVINs] = useState<FormVIN.Paginated>();
  const [query, setQuery] = useState<FormVIN.Query>({
    page: 0,
    size: 10,
  });

  const fetchFormVINs = useCallback(() => {
    return FormVINService.getAll(query).then(setFormVINs);
  }, [query]);

  const fetchFormVINById = useCallback((id: number) => {
    return FormVINService.getById(id).then(setFormVIN);
  }, []);

  const insertNewFormVIN = useCallback(
    (visitaInput: FormVIN.Input) => {
      visitaInput.codigoVerificador = generateHash(visitaInput);
      //visitaInput.status = 'NOVO';

      if (online) {
        return FormVINService.add(visitaInput);
      } else {
        if (navigator.serviceWorker)
          navigator.serviceWorker.ready.then((swRegistration) => {
            //@ts-ignore
            if (swRegistration.sync) {
              localforage.setItem(
                'token',
                AuthorizationService.getAccessToken()
              );
              navigator.serviceWorker.ready.then((registration) => {
                //@ts-ignore
                registration.sync.register(SYNC_FORM_VIN_NEEDED);
              });
            } else {
            }
          });
        return FormVINIDBService.addFormVIN(visitaInput);
      }
    },
    [online]
  );

  return {
    formVIN,
    formVINs,
    fetchFormVINById,
    fetchFormVINs,
    insertNewFormVIN,
    query,
    setQuery,
  };
}
