import { TipoChavePrincipalFVER } from '../../@types';
import Service from './Service';

class TipoChavePrincipalFVERService extends Service {
  static getAllActive() {
    return this.Http.get<TipoChavePrincipalFVER.Summary[]>(
      '/tipoChavePrincipalVisitaPropriedadeRural',
      { timeout: 600000 }
    ).then(this.getData);
  }
}

export default TipoChavePrincipalFVERService;
