import { Spin } from 'antd';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import usePageTitle from '../../core/hooks/usePageTitle';
import ErrorBoundary from '../components/ErrorBoundary';
import ListFormCustom from '../features/FormCustom/ListFormCustom';

export default function FormCustomList() {
  usePageTitle('SIZ - Lista de formulários customizados');
  const { loading } = useLoadingPage();

  return (
    <>
      <ErrorBoundary component='a lista de formulários customizados'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <ListFormCustom />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
