import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import SinaisClinicosPanel from '../../components/SinaisClinicosPanel';
import { SinaisClinicos } from '../../../core/enums/SinaisClinicos';
import { SimNao } from '../../../core/enums/SimNao';
import { useEffect, useState } from 'react';

type VigilanciaEquinosProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function VigilanciaEquinos(props: VigilanciaEquinosProps) {
  const [statusHaSuspeitaClinica, setStatusHaSuspeitaClinica] = useState();

  useEffect(() => {
    setStatusHaSuspeitaClinica(
      props.form.getFieldValue(['vigilanciaEquino', 'haSuspeitaClinica'])
    );
  }, [props.form]);

  const triggerRecountTotaExaminadosEquinos = () => {
    const ate6Meses: number = props.form.getFieldValue([
      'vigilanciaEquino',
      'quantidadeInspecionadosAbaixo06Meses',
    ]);
    const acima6Meses: number = props.form.getFieldValue([
      'vigilanciaEquino',
      'quantidadeInspecionadosAcima06Meses',
    ]);
    const dados = [ate6Meses, acima6Meses];

    let totalAnimaisExaminados: number = 0;

    dados.forEach((qtdade) => {
      if (!isNaN(Number(qtdade))) {
        totalAnimaisExaminados =
          Number(totalAnimaisExaminados) + Number(qtdade);
      }

      props.form.setFieldValue(
        ['vigilanciaEquino', 'quantidadeExaminados'],
        totalAnimaisExaminados
      );
    });
  };

  return (
    <>
      <Divider orientation='left'>Equinos examinados</Divider>

      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            label={'Até 6 meses'}
            name={['vigilanciaEquino', 'quantidadeInspecionadosAbaixo06Meses']}
          >
            <Input
              inputMode='numeric'
              autoComplete='off'
              maxLength={5}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={(event: any) => {
                triggerRecountTotaExaminadosEquinos();
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={12}>
          <Form.Item
            label={'Acima de 6 meses'}
            name={['vigilanciaEquino', 'quantidadeInspecionadosAcima06Meses']}
          >
            <Input
              inputMode='numeric'
              autoComplete='off'
              maxLength={5}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={(event: any) => {
                triggerRecountTotaExaminadosEquinos();
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Tooltip
            title={
              'Preencher com todos os animais observados, inclusive os examinados'
            }
          >
            <Form.Item
              label={'Vistoriados'}
              name={['vigilanciaEquino', 'quantidadeVistoriados']}
              rules={[
                {
                  validator(_, vistoriados) {
                    let isValid;
                    var examinados = props.form.getFieldValue([
                      'vigilanciaEquino',
                      'quantidadeExaminados',
                    ]);

                    console.log('value', vistoriados);
                    console.log('totalAnimaisExaminados', examinados);

                    if (examinados === undefined && vistoriados === undefined) {
                      console.log('1');

                      isValid = true;
                    } else if (examinados === undefined) {
                      console.log('2');
                      isValid = true;
                    } else if (vistoriados === undefined) {
                      console.log('3');
                      isValid = false;
                    } else {
                      console.log('4');
                      isValid = vistoriados >= examinados;
                    }

                    console.log('VISTORIADOS, ', vistoriados, isValid);

                    if (isValid) return Promise.resolve();
                    else
                      return Promise.reject(
                        'Deve ser maior que o total de Examinados'
                      );
                  },
                },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                inputMode='numeric'
                maxLength={4}
                autoComplete='off'
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Tooltip>
        </Col>

        <Col xs={24} md={12} lg={12}>
          <Form.Item
            label={'Examinados'}
            name={['vigilanciaEquino', 'quantidadeExaminados']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Há suspeita clínica?'}
            name={['vigilanciaEquino', 'haSuspeitaClinica']}
            valuePropName={'checked'}
          >
            <Radio.Group
              value={statusHaSuspeitaClinica}
              onChange={(e) => {
                props.form.setFieldValue(
                  ['vigilanciaEquino', 'haSuspeitaClinica'],
                  e.target.value
                );
                setStatusHaSuspeitaClinica(e.target.value);
              }}
            >
              <Radio key={'0'} value={false}>
                {SimNao.NAO}
              </Radio>
              <Radio key={'1'} value={true}>
                {SimNao.SIM}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item
            dependencies={['vigilanciaEquino', 'haSuspeitaClinica']}
            label={'Suspeita clinica'}
            name={['vigilanciaEquino', 'suspeitaClinica']}
            rules={[
              {
                required: statusHaSuspeitaClinica,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>

        <SinaisClinicosPanel
          listaSinaisClinicos={SinaisClinicos.equinos()}
          pathToCoordenada={'vigilanciaEquino'}
          form={props.form}
          required={statusHaSuspeitaClinica}
        />
      </Row>
    </>
  );
}
