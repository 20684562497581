import { Divider, Spin, Typography } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import FormCadastroLocalAcaoEducativa from './FormCadastroLocalAcaoEducativa';

export default function CreateCadastroLocalAcaoEducativa() {
  const { loading } = useLoadingPage();

  return (
    <>
      <Divider orientation='left'>
        <Typography.Title level={3}>
          Novo Formulário Local Ação Educativa - FORM-EDUCA
        </Typography.Title>
      </Divider>

      <Spin
        size='large'
        spinning={loading}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        <FormCadastroLocalAcaoEducativa />
      </Spin>
    </>
  );
}
