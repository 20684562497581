import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type ButtonToFormProps = {
  alternativeBackgroundColor?: string;
  disabled?: boolean;
  alternateIcon?: React.ReactNode;
  label: string;
  route: string;
  type: 'VIEW' | 'INCLUDE';
};

export default function ButtonToForm(props: ButtonToFormProps) {
  const navigate = useNavigate();

  useEffect(() => {}, [props]);

  return (
    <Button
      disabled={props.disabled && props.type === 'INCLUDE'}
      icon={
        props.alternateIcon ? (
          props.alternateIcon
        ) : props.type === 'VIEW' ? (
          <SearchOutlined />
        ) : (
          <PlusOutlined />
        )
      }
      onClick={() => navigate(props.route)}
      size='small'
      shape='round'
      style={{
        backgroundColor: props.alternativeBackgroundColor
          ? props.alternativeBackgroundColor
          : props.type === 'VIEW'
          ? 'rgba(131, 239, 156, 0.5)'
          : '',
        height: '22px',
        fontSize: '12px',
        paddingTop: '0',
        paddingBottom: '0',
      }}
      type={props.type === 'INCLUDE' ? 'primary' : 'text'}
    >
      {props.label}
    </Button>
  );
}
