import generateHash from '../../../core/functions/generateHash';
import { FVV } from '../../@types';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FVVService extends Service {
  static getAll(query: FVV.Query): Promise<FVV.Paginated> {
    const queryString = generateQueryString(query);
    return this.Http.get<FVV.Paginated>(
      '/vigilancias-veterinarias'.concat(queryString)
    ).then(this.getData);
  }

  static async save(fvv: FVV.Input) {
    const codigoVerificador = generateHash(fvv);

    if (!fvv.id)
      return this.insert({
        ...fvv,
        codigoVerificador,
      });
    else return this.update(fvv);
  }

  static async insert(fvv: FVV.Input) {
    return this.Http.post<FVV.Input>('/vigilancias-veterinarias', fvv).then(
      this.getData
    );
  }

  static async update(fvv: FVV.Input) {
    const codigoVerificador = generateHash(fvv);

    return this.Http.put<FVV.Input>(`/vigilancias-veterinarias/${fvv.id}`, {
      ...fvv,
      codigoVerificador,
    }).then(this.getData);
  }

  static remove(id: number) {
    return this.Http.delete<boolean>(`/vigilancias-veterinarias/${id}`, {
      data: { id: id },
    }).then(this.getData);
  }

  static getById(id: number) {
    return this.Http.get(`/vigilancias-veterinarias/${id}`).then(this.getData);
  }

  static getByFVER(id: number) {
    return this.Http.get<number>(`/vigilancias-veterinarias/visita/${id}`).then(
      this.getData
    );
  }

  static getByCodigoVerificador(codigoVerificador: string) {
    const queryString = generateQueryString({
      codigoVerificador,
    });
    return this.Http.get<FVV.Paginated>(
      `/vigilancias-veterinarias`.concat(queryString)
    ).then(this.getData);
  }

  static getByCodigoVerificador_PublicAccess(codigoVerificador: string) {
    return this.Http.get<FVV.Detailed>(
      `/vigilancias-veterinarias/publico/codigo/${codigoVerificador}`
    ).then(this.getData);
  }
}

export default FVVService;
