import {
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Radio,
  Row,
  Typography,
} from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';

type CustomCardProps = {
  title: string;
  name: string;
  specify: string;
  formValue: string | null;
  formDisable: boolean | undefined;
  updatedValue: string | null;
  handleChange: (
    name: string,
    value: string,
    specify: string,
    updatedValue: string | null
  ) => void;
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  name,
  specify,
  formValue,
  formDisable,
  updatedValue,
  handleChange,
}) => {
  return (
    <Card title={title}>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Col lg={16}>
            <Form.Item
              name={name}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Radio.Group
                disabled={formDisable}
                onChange={(e) =>
                  handleChange(name, e.target.value, specify, updatedValue)
                }
                value={updatedValue}
              >
                <Radio value='SIM'>SIM</Radio>
                <Radio value='NAO'>NÃO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            {formValue === 'NAO' && (
              <Form.Item
                label={'Especificar'}
                name={specify}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea
                  showCount
                  rows={5}
                  maxLength={2000}
                  disabled={formDisable}
                />
              </Form.Item>
            )}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

type InformacoesCadastroProps = {
  form: FormInstance;
  formDisabled?: boolean;
};

export default function MedidasSanitarias(props: InformacoesCadastroProps) {
  const [updatedValues, setUpdatedValues] = useState<{
    [key: string]: string | null;
  }>({});

  const handleChange = (name: string, value: string, specify: string) => {
    props.form.setFieldsValue({ [name]: value });
    setUpdatedValues((prevState) => ({ ...prevState, [name]: value }));
    if (value !== 'NAO') {
      props.form.setFieldsValue({
        [specify]: undefined,
      });
    }
  };
  return (
    <>
      <Typography.Title level={5}>Medidas Sanitárias</Typography.Title>

      <Divider />
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='adotaVazioSanitario_6_1'
            title='6.1 - Adota vazio sanitário?'
            name='adotaVazioSanitario_6_1'
            specify='adotaVazioSanitarioEspecificacao_6_1'
            formValue={props.form.getFieldValue('adotaVazioSanitario_6_1')}
            updatedValue={updatedValues['adotaVazioSanitario_6_1']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2'
            title='6.2 - Adota procedimento adequado para o destino de águas utilizadas, aves mortas, 
            ovos descartados, esterco e embalagem, de modo a garantir a biosseguridade do estabelecimento?'
            name='adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2'
            specify='adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2'
            formValue={props.form.getFieldValue(
              'adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2'
            )}
            updatedValue={
              updatedValues[
                'adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='executaProgramaDeLimpezaEDesinfeccao_6_3'
            title='6.3 - Executa programa de limpeza e desinfecção nos aviários após a saída de cada lote de aves?'
            name='executaProgramaDeLimpezaEDesinfeccao_6_3'
            specify='executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3'
            formValue={props.form.getFieldValue(
              'executaProgramaDeLimpezaEDesinfeccao_6_3'
            )}
            updatedValue={
              updatedValues['executaProgramaDeLimpezaEDesinfeccao_6_3']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4'
            title='6.4 - Possui protocolo e registro de limpeza e desinfecção dos reservatórios de captação e distribuição de águas servidas?'
            name='possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4'
            specify='possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4'
            formValue={props.form.getFieldValue(
              'possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4'
            )}
            updatedValue={
              updatedValues['possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='mantemRegistroDeControleDePragas_6_5'
            title='6.5 - Mantem registros do programa de controle de pragas, a fim de manter os aviários e os locais para armazenagem de 
            alimentos ou ovos livres de insetos e roedores, animais silvestres ou domésticos?'
            name='mantemRegistroDeControleDePragas_6_5'
            specify='mantemRegistroDeControleDePragasEspecificacao_6_5'
            formValue={props.form.getFieldValue(
              'mantemRegistroDeControleDePragas_6_5'
            )}
            updatedValue={updatedValues['mantemRegistroDeControleDePragas_6_5']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6'
            title='6.6 - Está realizando com periodicidade anual a análise microbiológica da água? Está dentro dos padrões previstos em normativas vigentes?'
            name='realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6'
            specify='realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6'
            formValue={props.form.getFieldValue(
              'realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6'
            )}
            updatedValue={
              updatedValues['realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='aguaUtilizadaETratadaComCloro_6_7'
            title='6.7 - A água utilizada para o consumo das aves e para o sistema de nebulização dos aviários é tratada com cloro obtendo uma concentração 
            residual mínima de 3ppm?'
            name='aguaUtilizadaETratadaComCloro_6_7'
            specify='aguaUtilizadaETratadaComCloroEspecificacao_6_7'
            formValue={props.form.getFieldValue(
              'aguaUtilizadaETratadaComCloro_6_7'
            )}
            updatedValue={updatedValues['aguaUtilizadaETratadaComCloro_6_7']}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='mantemRegistroDasAtividadesDeTransitoDeAves_6_8'
            title='6.8 - Mantém os registros das atividades de trânsito de aves à disposição do serviço oficial?'
            name='mantemRegistroDasAtividadesDeTransitoDeAves_6_8'
            specify='mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8?'
            formValue={props.form.getFieldValue(
              'mantemRegistroDasAtividadesDeTransitoDeAves_6_8'
            )}
            updatedValue={
              updatedValues['mantemRegistroDasAtividadesDeTransitoDeAves_6_8']
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='mantemRegistroDasAcoesSanitariasExecutadas_6_9'
            title='6.9 - Mantém os registros das ações sanitárias executadas à disposição do serviço oficial?'
            name='mantemRegistroDasAcoesSanitariasExecutadas_6_9'
            specify='mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9?'
            formValue={props.form.getFieldValue(
              'mantemRegistroDasAcoesSanitariasExecutadas_6_9'
            )}
            updatedValue={
              updatedValues[
                'mantemRegistroDasAcoesSanitariasExecutadas_6_9'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10'
            title='6.10 - Mantém os registros de vacinações e medicações utilizadas com datas das visitas e recomendações do responsável técnico e do médico veterinário oficial?'
            name='mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10'
            specify='mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10'
            formValue={props.form.getFieldValue(
              'mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10'
            )}
            updatedValue={
              updatedValues[
                'mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10'
              ]
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <CustomCard
            formDisable={props.formDisabled}
            handleChange={handleChange}
            key='anotacaoDeRTDentroDoPrazoDeValiade_6_11'
            title='6.11- A Anotação de Responsabilidade Técnica do médico veterinário RT está dentro do prazo de validade?'
            name='anotacaoDeRTDentroDoPrazoDeValiade_6_11'
            specify='anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11'
            formValue={props.form.getFieldValue(
              'anotacaoDeRTDentroDoPrazoDeValiade_6_11'
            )}
            updatedValue={
              updatedValues[
                'anotacaoDeRTDentroDoPrazoDeValiade_6_11'
              ]
            }
          />
        </Col>
      </Row>
      <Divider />
      <Col xs={24} lg={24}>
        <Form.Item
          label={'Observações'}
          name={'observacoesMedidasSanitarias_6_12'}
        >
          <TextArea
            showCount
            rows={5}
            maxLength={2000}
            disabled={props.formDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}
