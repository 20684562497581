import localforage from 'localforage';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import Service from '../../sdk/services/SIZ-API/Service';
import AuthorizationService from '../auth/Authorization.service';
import LIAIDBService from '../../sdk/services/indexeddb/LIAIDB.service';
import LIAService from '../../sdk/services/SIZ-API/LIA.service';

export default async function syncLIAs(manually?: boolean): Promise<string> {
  let errors = 0;
  let liasWithFVERsUnsynced = 0;
  let liasSynced = 0;

  const promise = new Promise<string>(async (resolve, reject) => {
    return await LIAIDBService.getAllFromInputTable()
      .then(async (listLIAsOffline) => {
        const saveLIAAsync = async () => {
          for (const liaOffline of listLIAsOffline) {
            if (
              liaOffline.status === 'SINCRONIZADO' ||
              liaOffline.status === 'NAO FINALIZADO'
            ) {
              //@TODO preguiça de negar a condição. Arrumar depois
            } else if (liaOffline.payload.fver.id !== -1) {
              console.log('liaDTO', liaOffline.payload);

              await localforage.getItem('token').then(async (token) => {
                let access_token;
                if (token) access_token = token;
                else access_token = AuthorizationService.getAccessToken();

                if (!access_token) {
                  throw new Error('Sessão expirada');
                }

                await fetch(`${Service.API_URL}${LIAService.BASE_URL}`, {
                  method: 'POST',
                  body: JSON.stringify(liaOffline.payload),
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`,
                  },
                })
                  .then(async (response) => {
                    if (response.status === 401) {
                      throw new Error('Sessão expirada');
                    }

                    if (response.ok) {
                      return await response.json().then(async (liaResponse) => {
                        let newLIA: ServiceIDBPayloadInput = {
                          ...liaOffline,
                          payload: {
                            ...liaOffline.payload,
                            id: liaResponse.id,
                            numero: liaResponse.numero,
                          },
                          status: 'SINCRONIZADO',
                        };

                        await LIAIDBService.update(
                          //@ts-ignore
                          liaOffline.id,
                          newLIA
                        ).catch((e) => {});

                        liasSynced++;
                      });
                    } else {
                      return await response.json().then(async (liaResponse) => {
                        let motivoErro = liaResponse.detail;

                        errors = errors + 1;
                        let newLIA: ServiceIDBPayloadInput = {
                          ...liaOffline,
                          status: 'ERRO',
                          motivoErro,
                        };

                        return await LIAIDBService.update(
                          //@ts-ignore
                          liaOffline.id,
                          newLIA
                        );
                      });
                    }
                  })
                  .catch((e) => {
                    throw new Error(
                      'Não conseguimos conectar com o servidor. Motivo: ' + e
                    );
                  });
              });
            } else {
              liasWithFVERsUnsynced++;
            }
          }
        };
        await saveLIAAsync();
      })
      .then(async () => {
        if (errors === 0 && liasWithFVERsUnsynced === 0 && liasSynced === 0)
          return resolve('Sem FVVs para sincronizar');
        else if (errors === 0 && liasWithFVERsUnsynced === 0 && liasSynced > 0)
          return resolve(
            'Todos os Laudos de Inspeção foram sincronizados com sucesso'
          );
        else if (errors === 0 && liasWithFVERsUnsynced > 0 && liasSynced > 0)
          return resolve(
            'Alguns Laudos de Inspeção não foram sincronizados. Verifique se o FVER correspondente já está sincronizado'
          );
        else if (errors === 0 && liasWithFVERsUnsynced > 0 && liasSynced === 0)
          return resolve(
            'Nenhum Laudo de Inspeção foi sincronizado. Verifique se o FVER correspondente já está sincronizado'
          );
        else {
          return reject(
            'A sincronização dos Laudos de Inspeção foi finalizada com erros. Alguns Laudos de Inspeção não puderam ser sincronizadas. Revise-os e tente novamente'
          );
        }
      })
      .catch((e) => {
        return reject(e.message);
      });
  });

  return await promise;
}
