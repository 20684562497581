import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type TerrenoLocalizacaoControleAcessoProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  distance?: string;
};
export default function PrintTerrenoLocalizacaoControleAcesso({
  data,
}: TerrenoLocalizacaoControleAcessoProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card headStyle={{ backgroundColor: '#e9e8e8' }}>
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                key={index}
              >
                {row.condition}
              </Descriptions.Item>
            ))}
            {data
              .filter((row) => row.value)
              .map((row, index) => (
                <Descriptions.Item
                  label='Observações'
                  span={row.span ? row.span : 1}
                  labelStyle={{
                    fontWeight: 'bold',
                    display: 'inline-block',
                    alignItems: 'left',
                  }}
                  key={`observation-${index}`}
                >
                  {row.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {panelDescription('Distâcias Internas', [
          {
            label: `3.1 - O terreno de construção dos aviários possui solo permeável e seco com declividade adequada,
      evitando áreas baixas onde possam se acumular água ou umidade em excesso;
      A localização do terreno está em área não sujeita a condições adversas que possam interferir
      na saúde e bem-estar das aves ou na qualidade do produto?`,
            condition: data?.terrenoELocalizacao,
          },
          {
            value: data?.observacoesTerrenoELocalizacao,
          },
        ])}
      </div>
    </>
  );
}
