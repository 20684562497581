import { FormIN } from '../../@types/FormIN';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FormINService extends Service {
  static getByNumero(numero: string): Promise<FormIN.Summary> {
    return this.Http.get<FormIN.Summary>(`/form-ins/numero/${numero}`).then(
      this.getData
    );
  }

  static getByMunicipio(codgIbge: string, query: FormIN.Query) {
    const queryString = generateQueryString(query);
    return this.Http.get<FormIN.Summary[]>(
      `form-ins/municipio/${codgIbge}`.concat(queryString)
    ).then(this.getData);
  }
}

export default FormINService;
