import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FormGraf } from '../../../sdk/@types';
import FilterPanelGRAF from './FilterPanelGRAF';
import { useNavigate } from 'react-router-dom';
import * as FormGrafActions from '../../../core/store/FormGRAF.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import useDevOptions from '../../../core/hooks/useDevOptions';

export default function ListGRAFs() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { xs, sm } = useBreakpoint();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();
  const { idInTables } = useDevOptions();
  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const formGrafs = useSelector((state: RootState) => state.formGraf.formGrafs);
  const query = useSelector((state: RootState) => state.formGraf.query);
  const fetching = useSelector((state: RootState) => state.formGraf.fetching);

  if (!online)
    throw new Error(
      'Não é possível visualizar a lista de FormGrafs no modo offline'
    );
  if (error) throw error;

  useEffect(() => {
    dispatch(FormGrafActions.filterFormGrafs(query));
    setFirstOpening(true);
  }, [dispatch, query]);

  const handleDelete = useCallback(
    async (id) => {
      if (id) {
        setDeleting(true);
        try {
          await dispatch(FormGrafActions.removeFormGraf(id));
          setDeleting(false);
          notification.success({
            message: 'FORM-GRAF removido com sucesso',
          });
          dispatch(FormGrafActions.filterFormGrafs(query));
        } catch (e: any) {
          setDeleting(false);
          notification.error({
            message: 'Não foi possível remover o FORM-GRAF',
            description: e.message,
          });
        }
      }
    },
    [dispatch, query]
  );
  const handleEditClick = (id: number) => {
    dispatch(FormGrafActions.clearFormGrafState());
    navigate(`/graf/edicao/${id}`);
  };

  const handleViewClick = (id: number) => {
    dispatch(FormGrafActions.clearFormGrafState());
    navigate(`/graf/visualizar/${id}`);
  };

  return (
    <>
      <FilterPanelGRAF />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FormGraf.Summary>
                columns={[
                  {
                    dataIndex: 'dataDistribuicao',
                    title: 'Data',
                    width: idInTables ? 100 : 0,
                    render(dataDistribuicao) {
                      return (
                        <Typography.Text>
                          {moment(new Date(dataDistribuicao)).format(
                            'DD/MM/YYYY'
                          ) || '-'}
                        </Typography.Text>
                      );
                    },
                  },
                  {
                    dataIndex: 'municipio',
                    title: 'município',
                    render(municipio) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {municipio?.nome || '-'}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'modalidadeDistribuicao',
                    title: 'Modalidade',
                    render(modalidadeDistribuicao) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {modalidadeDistribuicao || '-'}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'tiposApresentacaoMaterialEducativo',
                    title: 'Tipo de apresentação do material educativo',
                    render(tiposApresentacaoMaterialEducativo) {
                      return (
                        <Space direction='vertical'>
                          <Space
                            direction='horizontal'
                            size={2}
                            wrap={sm || xs}
                          >
                            {tiposApresentacaoMaterialEducativo &&
                            tiposApresentacaoMaterialEducativo.length > 0 ? (
                              tiposApresentacaoMaterialEducativo.map(
                                (tipo: string, index: number) => (
                                  <Tag
                                    style={{
                                      fontSize: '0.7em',
                                      padding: 4,
                                      margin: 2,
                                    }}
                                    key={index}
                                  >
                                    {tipo}
                                  </Tag>
                                )
                              )
                            ) : (
                              <Typography.Text>
                                <Tag> - </Tag>
                              </Typography.Text>
                            )}
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Button
                            icon={<PrinterOutlined />}
                            onClick={() => handleViewClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Popconfirm
                            title={'Deseja remover esse Form-Graf'}
                            onConfirm={() => {
                              handleDelete(id);
                            }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              size={'small'}
                              type={'ghost'}
                              danger
                            />
                          </Popconfirm>
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={formGrafs?.content}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      FormGrafActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: formGrafs?.totalElements,
                  pageSize: query.size,
                }}
                rowKey={'id'}
                size={'small'}
                showHeader={true}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Form GRAFs
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/graf/cadastro`)}
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(
                                  FormGrafActions.filterFormGrafs(query)
                                );
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
