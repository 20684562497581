import { Navigate } from 'react-router-dom';
import AuthorizationService from '../../core/auth/Authorization.service';

interface Props {
  component: React.ComponentType;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  //const user = AuthorizationService.getUsuarioSIZ()
  //const userHasRequiredRole = AuthorizationService.getUsuarioSIZ().getRoles()
  const isAuthenticated = AuthorizationService.isAuthenticated();
  if (isAuthenticated) {
    return <RouteComponent />;
  }

  return <Navigate to='/' />;
};
