import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { LIA } from '../../../../sdk/@types';

type DistanciasInternasProps = {
  data: LIA.Response;
};
type DescriptionRow = {
  label?: string;
  value?: string | number;
  span?: number;
  condition?: string;
  distance?: string;
};
export default function PrintDistanciasInternaas({
  data,
}: DistanciasInternasProps) {
  const panelDescription = (title: string, data: DescriptionRow[]) => {
    return (
      <div className='newPage' style={{ display: 'block', marginTop: 40 }}>
        <Card
          headStyle={{ backgroundColor: '#e9e8e8' }}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          size='small'
          bodyStyle={{ padding: '0px' }}
        >
          <Descriptions bordered layout='vertical' column={2}>
            {data.map((row, index) => (
              <Descriptions.Item
                label={row.label}
                key={index}
                span={row.span ? row.span : 1}
                labelStyle={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  alignItems: 'left',
                }}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {row.condition}
                {row.condition === 'NAO' && row.distance && (
                  <div>
                    <strong>Qual a distância?:</strong> <br/>{row.distance}
                  </div>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div>
        {panelDescription('Distâcias Internas', [
          {
            label:
              '2.1 - O(s) aviário(s) estão distantes no mínimo 5,0m da cerca de isolamento?',
            condition: data?.distanciaMinima5mDaCercaIsolamento_2_1,
            distance: data?.distanciaDaCercaIsolamento_2_1,
          },
          {
            label:
              '2.2 - A distância entre os aviário(s) e residências é de no mínimo 20m?',
            condition: data?.distanciaMinima20mDasResidencias_2_2,
            distance: data?.distanciaDasResidencias_2_2,
          },
          {
            label:
              '2.3 - A distância entre o núcleo de cria/recria de aves poedeiras de ovos comerciais e o núcleo de produção de ovos é de no mínimo 200m?',
            condition:
              data?.distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3,
            distance: data?.distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3,
          },
          {
            label:
              '2.4 - A distância entre núcleos de aves de corte é de no mínimo 200m?',
            condition: data?.distanciaMinima200mEntreNucleoAvesCorte_2_4,
            distance: data?.distanciaEntreNucleoAvesCorte_2_4,
          },
          {
            label:
              '2.5 - A distância do(s) aviário(s) à estrada vicinal é de no mínimo 100m?',
            condition: data?.distanciaMinima100mDaEstradaVicinal_2_5,
            distance: data?.distanciaDaEstradaVicinal_2_5,
          },
          {
            label:
              '2.6 - A distância do(s) aviário(s) aos limites periféricos da propriedade é de no mínimo 30m?',
            condition: data?.distanciaMinima30mDosLimitesPerifericos_2_6,
            distance: data?.distanciaDosLimitesPerifericos_2_6,
          },
          {
            label:
              '2.7 - A fábrica de ração da granja implementa práticas de fabricação de qualidade e segue diretrizes de boas práticas?',
            condition: data?.distanciaMinima500mDaFabricaRacaoPropria_2_7, // Verifique se esta condição está correta, parece desalinhada com a descrição.
            distance: data?.distanciaDaFabricaRacaoPropria_2_7,
          },
          {
            label:
              '2.8 - A distância entre o núcleo de aves e aves/suínos não comerciais é de no mínimo 600m?',
            condition: data?.distanciaMinima600mEntreNucleoDeAvesESuinos_2_8,
            distance: data?.distanciaEntreNucleoDeAvesESuinos_2_8,
          },
          {
            label:
              '2.9 - A distância entre núcleo de aves e outras espécies de animais não comerciais é de no mínimo 20m?',
            condition:
              data?.distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9,
            distance: data?.distanciaEntreNucleoAvesEOutrasEspecies_2_9,
          },
          {
            label: 'Observações',
            condition: data?.observacoesDistanciasInternas,
          },
        ])}
      </div>
    </>
  );
}
