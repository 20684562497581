import { SelectOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Table,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCnpjAndNumero } from '../../core/store/RecebimentosRevendas.slice';
import { AppDispatch } from '../../core/store';
import { CustomModal } from './CustomModal';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { RecebimentosRevendas } from '../../sdk/@types';
import { formatCpf_Cnpj } from '../../core/functions/formatCpf_Cnpj';

interface NotaFiscalProps {
  form: FormInstance;
  formDisabled: boolean;
  onSelectNota: (nota: RecebimentosRevendas.Response) => void;
  onClearForm: () => void;
}

export default function NotaFiscalPanel(props: NotaFiscalProps) {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const { xs } = useBreakpoint();
  const { setLoading, mode } = useLoadingPage();
  const [cnpj, setCnpj] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [showModalResultados, setShowModalResultados] =
    useState<boolean>(false);

  const [resultadosBusca, setResultadosBusca] = useState<
    RecebimentosRevendas.Response[]
  >([]);

  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    if (mode === 'EDIT') {
      const cnpjValue = props.form.getFieldValue(['revendaDestino', 'cpfCnpj']);
      const numeroValue = props.form.getFieldValue('numeroNFE');

      if (cnpjValue || numeroValue) {
        props.form.setFieldsValue({
          cnpj: formatCpf_Cnpj(cnpjValue) || '',
          numero: numeroValue || '',
        });

        setCnpj(formatCpf_Cnpj(cnpjValue));
        setNumero(String(numeroValue));
      }
    }
  }, [mode, props.form]);

  const handleCnpjChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value.replace(/\D/g, '');
      const formattedValue = formatCpf_Cnpj(rawValue);
      setCnpj(formattedValue);
    },
    []
  );

  const handleNumeroChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNumero(String(e.target.value));
    },
    []
  );

  const clearFields = useCallback(() => {
    props.onClearForm();
  }, [props]);

  const handleSearch = useCallback(async () => {
    if (!cnpj || !numero) return;

    setLoading(true);
    setFetching(true);

    try {
      const unformattedCNPJ = cnpj.replace(/\D/g, '');
      const action = await dispatch(
        fetchCnpjAndNumero({ cnpj: unformattedCNPJ, numero: Number(numero) })
      ).unwrap();

      if (action && action.length > 0) {
        setResultadosBusca(action);
        if (action.length === 1) {
          handleSelectItem(action[0]);
        } else {
          setShowModalResultados(true);
        }
      } else {
        throw new Error('Nenhuma nota fiscal encontrada.');
      }
    } catch (error) {
      console.error('Erro ao buscar Nota Fiscal:', error);
      notification.error({
        message: 'Erro na busca',
        description:
          'Não foi possível encontrar notas fiscais com os dados fornecidos.',
      });
    } finally {
      setLoading(false);
      setFetching(false);
    }
  }, [dispatch, cnpj, numero, setLoading]);

  const handleSelectItem = (item: RecebimentosRevendas.Response) => {
    props.form.setFieldsValue(item);
    props.onSelectNota(item);
    notification.success({
      message: `Nota Fiscal ${item.numeroNFE} selecionada com sucesso`,
    });

    setShowModalResultados(false);
  };

  return (
    <>
      <Divider orientation='left'>Dados da Nota Fiscal</Divider>
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form.Item
            label='CNPJ'
            name='cnpj'
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input
              value={cnpj}
              onChange={handleCnpjChange}
              disabled={props.formDisabled}
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label='Número'
            name='numero'
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input
              value={numero}
              onChange={handleNumeroChange}
              disabled={props.formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} justify='end'>
        <Col>
          <Button onClick={clearFields} disabled={props.formDisabled}>
            Limpar
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={handleSearch}
            disabled={props.formDisabled || fetching}
          >
            Buscar
          </Button>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={6}>
          <Form.Item label='Número' name={'numeroNFE'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label='Série' name={'documentoEntradaId'}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <CustomModal
        title={'Selecione uma Nota Fiscal'}
        open={showModalResultados}
        width={750}
        footer={
          <Button
            type='default'
            onClick={() => {
              setShowModalResultados(false);
            }}
          >
            Fechar
          </Button>
        }
      >
        <Table
          dataSource={resultadosBusca}
          size={'small'}
          rowKey={(record) => record.id.toString()} // Ensure the key is unique
          columns={[
            {
              dataIndex: 'revendaDestino',
              title: 'CNPJ',
              render: (revendaDestino) => {
                if (revendaDestino && revendaDestino.cpfCnpj) {
                  let cnpjFormatado = revendaDestino.cpfCnpj;
                  cnpjFormatado = cnpjFormatado.replace(/(\d{2})(\d)/, '$1.$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{3})(\d)/, '$1.$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{3})(\d)/, '$1/$2');
                  cnpjFormatado = cnpjFormatado.replace(/(\d{4})(\d)/, '$1-$2');
                  return cnpjFormatado;
                }
                return '-';
              },
            },
            {
              dataIndex: 'numeroNFE',
              title: 'Número',
            },
            {
              dataIndex: 'revendaDestino',
              title: 'Emitente',
              render: (revendaDestino) => {
                return revendaDestino ? revendaDestino.nome : '-';
              },
            },
            {
              width: xs ? '0' : '80px',
              render(_, item) {
                return (
                  <Button
                    icon={<SelectOutlined />}
                    type='primary'
                    onClick={() => handleSelectItem(item)}
                  >
                    {xs ? '' : 'Selecionar'}
                  </Button>
                );
              },
            },
          ]}
        />
      </CustomModal>
    </>
  );
}
