import { Divider, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TermoFiscalizacao } from '../../../sdk/@types';
import TFRIDBService from '../../../sdk/services/indexeddb/TFRIDB.service';
import FormTFR from './FormTFR';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as TFRActions from '../../../core/store/TFR.slice';

function EditTFR() {
  const dispatch = useDispatch();
  const { id, local } = useParams<{ id: string; local?: string }>();
  const tfrOnline = useSelector(TFRActions.selectTFR);
  const loadingState = useSelector((state: RootState) => state.formTfr.loading);

  const [tfrOffline, setTFROffline] =
    useState<TermoFiscalizacao.Request | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchTFROffline = useCallback(async () => {
    const result = await TFRIDBService.getByIdFromInputTable(Number(id));
    setTFROffline(result);
    setLoading(false);
  }, [id]);
  const [triggerRender, setTriggerRender] = useState(0);
  const prepareData = useCallback(
    (tfr) => ({
      ...tfr,
      dataVisita: moment(tfr?.dataVisita),
    }),
    []
  );
useEffect(() => {
    if (loadingState === 'fulfilled' || loadingState === 'rejected') {
      setLoading(false);
      setTriggerRender((prev) => prev + 1);
    }
  }, [loadingState]);

  useEffect(() => {
    if (!isNaN(Number(id))) {
      setLoading(true);
  
      if (local) {
        fetchTFROffline();
      } else {
        dispatch(TFRActions.fetchTFRById(Number(id)));
      }
    }
  }, [fetchTFROffline, id, local, dispatch]);
  
  
  const dataToDisplay = local ? tfrOffline : tfrOnline;

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && dataToDisplay && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={3}>Editando TFR</Typography.Title>
          </Divider>
          <Spin
            size='large'
            spinning={loadingState === 'pending'}
            style={{ position: 'fixed', top: '20%', zIndex: '1' }}
          >
            <FormTFR tfr={prepareData(dataToDisplay)} />
          </Spin>
        </>
      )}
    </>
  );
}

export default EditTFR;
