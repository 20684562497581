import { useCallback, useState } from 'react';
import ServidorIDBService from '../../sdk/services/indexeddb/ServidorIDB.service';
import TFRServidorService from '../../sdk/services/SIZ-API/TFRServidor.service';
import { TFRServidor } from '../../sdk/@types';
import useNavigatorStatus from './useNavigatorStatus';

export default function useTFRServidor() {
  const { online } = useNavigatorStatus();
  const [servidor, setServidor] = useState<TFRServidor.Summary | null>();
  const [fetchingServidor, setFetchingServidor] = useState<boolean>(false);

  const fetchExistingServidorByCpf = useCallback(
    async (cpf: string, fromApi?: boolean) => {
      try {
        setFetchingServidor(true);

        if (!fromApi) {
          return await ServidorIDBService.getByCpf(cpf).then(
            async (payload) => {
              if (payload) {
                setServidor(payload?.payload);
              } else if (online) {
                return await TFRServidorService.getByCpf(cpf).then(setServidor);
              } else setServidor(null);
            }
          );
        } else return await TFRServidorService.getByCpf(cpf).then(setServidor);
      } catch (err) {
        throw err;
      } finally {
        setFetchingServidor(false);
      }
    },
    [online]
  );

  const fetchExistingServidorByMatricula = useCallback(
    async (matricula: string, fromApi?: boolean) => {
      try {
        setFetchingServidor(true);

        if (!fromApi) {
          return await ServidorIDBService.getByMatricula(matricula).then(
            async (payload) => {
              if (payload) {
                setServidor(payload?.payload);
              } else if (online) {
                return await TFRServidorService.getByMatricula(matricula).then(
                  setServidor
                );
              } else setServidor(null);
            }
          );
        } else
          return await TFRServidorService.getByMatricula(matricula).then(
            setServidor
          );
      } catch (err) {
        throw err;
      } finally {
        setFetchingServidor(false);
      }
    },
    [online]
  );

  return {
    servidor,
    fetchingServidor,
    fetchExistingServidorByCpf,
    fetchExistingServidorByMatricula,
  };
}
