import { Spin } from 'antd';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import usePageTitle from '../../../core/hooks/usePageTitle';
import ErrorBoundary from '../../components/ErrorBoundary';
import FormFormVIN from './FormFormVIN';

export default function CreateFormVIN() {
  usePageTitle('Novo Form VIN');

  const { setMode, loading } = useLoadingPage();

  setMode('CREATE');

  return (
    <>
      <ErrorBoundary component='formulário'>
        <Spin
          size='large'
          spinning={loading}
          style={{
            position: 'fixed',
            top: '20%',
            zIndex: '1',
          }}
        >
          <FormFormVIN />
        </Spin>
      </ErrorBoundary>
    </>
  );
}
