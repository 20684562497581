import { FVER } from '../../@types';
import { openDB } from 'idb';
import DBConfig from './DB.config';
import ServiceIDB, {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from './ServiceIDB';

class FVERIDBService extends ServiceIDB {
  static DB_STORE_FVER_LIST = 'fver_list';

  static DB_STORE_FVER_INPUT = 'fver_input';

  static DB_STORE_FVER_LIST_INDEX_NUMERO_NAME = 'fver_list_index_numero';
  static DB_STORE_FVER_LIST_INDEX_NUMERO_KEY = 'payload.numero';

  static DB_STORE_FVER_LIST_INDEX_CODG_IBGE_NAME = 'fver_list_index_codg_ibge';
  static DB_STORE_FVER_LIST_INDEX_CODG_IBGE_KEY = 'payload.codgIBGE';

  static async getFirst() {
    const db = await openDB(DBConfig.DB_NAME);
    let cursor = await db
      .transaction(FVERIDBService.DB_STORE_FVER_LIST)
      .store.openCursor();

    const first = cursor?.value;
    return first;
  }

  static async getByNumero(numero: string): Promise<ServiceIDBPayload> {
    return await super._getFromIndex(
      this.DB_STORE_FVER_LIST,
      this.DB_STORE_FVER_LIST_INDEX_NUMERO_NAME,
      numero
    );
  }

  static async getAll(): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FVERIDBService.DB_STORE_FVER_LIST);
    return list;
  }

  static async getAllFromInputTable(): Promise<ServiceIDBPayloadInput[]> {
    const db = await openDB(DBConfig.DB_NAME);
    const list = await db.getAll(FVERIDBService.DB_STORE_FVER_INPUT);
    return list;
  }

  static async getAllFromInputTableOnlyPayload(): Promise<FVER.Input[]> {
    let list: FVER.Input[] = [];

    await this.getAllFromInputTable().then((result) => {
      list = result.map((row: any) => {
        return row.payload;
      });
    });

    return list;
  }

  static async getById(id: number): Promise<FVER.Input> {
    const db = await openDB(DBConfig.DB_NAME);
    const fver = await db.get(FVERIDBService.DB_STORE_FVER_LIST, id);

    return fver.payload;
  }

  static async getByIdFromInput_Table(id: number): Promise<FVER.Input> {
    const db = await openDB(DBConfig.DB_NAME);
    const fver = await db.get(FVERIDBService.DB_STORE_FVER_INPUT, id);

    return fver.payload;
  }

  static async getByCodigoVerificador_InputTable(
    codigoVerificador: string
  ): Promise<FVER.Input> {
    return await FVERIDBService.getAllFromInputTableOnlyPayload()
      .then(async (lista) => {
        const fverLocal = lista.filter(
          (fver) => fver.codigoVerificador === codigoVerificador
        );

        return fverLocal;
      })
      .then((listFvers) => listFvers[0]);
  }

  static async insert(
    fver: FVER.Input,
    options?: {
      status?: ServiceIDBPayloadInput['status'];
      motivoErro?: ServiceIDBPayloadInput['motivoErro'];
    }
  ) {
    const db = await openDB(DBConfig.DB_NAME);

    const obj: ServiceIDBPayloadInput = {
      date: new Date(),
      payload: fver,
      status: options?.status ? options.status : 'NOVO',
      motivoErro: options?.motivoErro ? options.motivoErro : '',
    };

    return db.add(this.DB_STORE_FVER_INPUT, obj);
  }

  static async deleteAll() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FVER_LIST);
  }

  static async deleteAll_InputTable() {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(this.DB_STORE_FVER_INPUT);
  }

  static async delete(id: number) {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.delete(this.DB_STORE_FVER_INPUT, id);
  }

  static async deleteAllByMunicipio(codg_ibge: string) {
    return await super
      ._getAllFromIndex(
        this.DB_STORE_FVER_LIST,
        this.DB_STORE_FVER_LIST_INDEX_CODG_IBGE_NAME,
        codg_ibge
      )
      .then(async (fvers: any[]) => {
        return Promise.all(
          fvers.map(async (fver) => {
            return await super._delete(this.DB_STORE_FVER_LIST, fver.id);
          })
        );
      });
  }

  static async update(id: number, obj: ServiceIDBPayloadInput) {
    this.delete(id);

    const db = await openDB(DBConfig.DB_NAME);
    return db.add(this.DB_STORE_FVER_INPUT, obj);
  }

  static async put(id: number, obj: FVER.Input) {
    const db = await openDB(DBConfig.DB_NAME);
    const oldFVER: ServiceIDBPayloadInput = await db.get(
      FVERIDBService.DB_STORE_FVER_INPUT,
      id
    );
    const newFVER: ServiceIDBPayloadInput = {
      ...oldFVER,
      payload: obj,
    };

    db.put(FVERIDBService.DB_STORE_FVER_INPUT, newFVER);
  }

  static async count() {
    return this._count(this.DB_STORE_FVER_INPUT);
  }
}

export default FVERIDBService;
