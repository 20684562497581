import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Affix, Button, Col, Row, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import usePageTitle from '../../../core/hooks/usePageTitle';
import Service from '../../../sdk/services/SIZ-API/Service';
import PrintFVER from '../reports/PrintFVER';
import { FVER } from '../../../sdk/@types';

export default function PublicFVERPrintView() {
  usePageTitle('FVER');

  const navigate = useNavigate();

  const [error, setError] = useState<Error>();

  const params = useParams<{ codigoVerificador: string }>();
  const [fver, setFVER] = useState<FVER.Input>();

  const printRef = useRef(null);
  const reactPrint = useReactToPrint({ content: () => printRef.current });

  const fetchFVERByCodigoVerificador = useCallback(
    async (codigoVerificador: string) => {
      await fetch(`${Service.API_URL}/visitas/codigo/${codigoVerificador}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return await response.json().then(async (response) => {
              setFVER(response);
            });
          } else {
            if (response.status === 400) {
              throw new Error(
                `Não foi possível encontrar o FVER com o código ${params.codigoVerificador}`
              );
            }

            if (response.status === 401) {
              throw new Error(
                'Você não possui acesso para acessar esse recurso'
              );
            }

            if (response.status === 500) {
              throw new Error(
                `Ocorreu um erro ao buscar o FVER. Entre em contato com o administrador do sistema.`
              );
            }

            throw new Error(
              'Erro desconhecido. Entre em contato com o administrador do sistema.'
            );
          }
        })
        .catch((e) => {
          setError(e);
          notification.error({
            message: e.message,
          });
          navigate('/');
        });
    },
    []
  );

  useEffect(() => {}, [params]);

  useEffect(() => {
    // @ts-ignore
    fetchFVERByCodigoVerificador(params.codigoVerificador)
      .then(() => {})
      .catch((e) => {
        setError(new Error(e.message));
      });
  }, [fetchFVERByCodigoVerificador, params]);

  useEffect(() => {}, [fver]);

  if (error) throw error;

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
      }}
    >
      <Affix offsetTop={80} className='printButton'>
        <Row gutter={12}>
          <Col>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                navigate('/home');
              }}
            >
              Voltar
            </Button>
          </Col>
          <Col>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                //window.print();
                reactPrint();
              }}
              icon={<PrinterOutlined />}
            >
              Imprimir
            </Button>
          </Col>
        </Row>
      </Affix>
      <div ref={printRef}>
        {fver?.status === 'CANCELADO' && (
          <div className='marcadagua'>{'CANCELADO'}</div>
        )}
        <table
          style={{
            tableLayout: 'fixed',
            width: '100%',
          }}
        >
          <PrintFVER fver={fver} />
        </table>
      </div>
    </div>
  );
}
