import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd';
import FVVIDBService from '../../../sdk/services/indexeddb/FVVIDB.service';
import FVERIDBService from '../../../sdk/services/indexeddb/FVERIDB.service';
import usePageTitle from '../../../core/hooks/usePageTitle';
import LIAIDBService from '../../../sdk/services/indexeddb/LIAIDB.service';
import TFRIDBService from '../../../sdk/services/indexeddb/TFRIDB.service';

export default function ImportJsonView() {
  usePageTitle('Importar JSON');
  const [form] = Form.useForm();

  const inserirJson = (formValues: any) => {
    if (formValues.fvv) {
      const fvvs: any[] = JSON.parse(formValues.fvv);
      if (fvvs) {
        fvvs.forEach((e) => {
          FVVIDBService.insert(e.payload);
        });
      }
    }

    if (formValues.fver) {
      const fvers: any[] = JSON.parse(formValues.fver);
      if (fvers) {
        fvers.forEach((e) => {
          FVERIDBService.insert(e.payload);
        });
      }
    }

    if (formValues.lia) {
      const lias: any[] = JSON.parse(formValues.lia);
      if (lias) {
        lias.forEach((e) => {
          LIAIDBService.insert(e.payload);
        });
      }
    }

    if (formValues.tfr) {
      const tfrs: any[] = JSON.parse(formValues.tfr);
      if (tfrs) {
        tfrs.forEach((e) => {
          TFRIDBService.insert(e.payload);
        });
      }
    }

    notification.info({
      message: 'JSON incluído com sucesso',
    });
    form.resetFields();
  };

  return (
    <>
      <Typography.Title level={3}>Importar JSON</Typography.Title>
      <Divider />

      <Form form={form} size='small' layout='vertical' onFinish={inserirJson}>
        <Col span={24}>
          <Card>
            <Form.Item label={'FVER'} name={'fver'}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Card>
        </Col>

        <br />

        <Col span={24}>
          <Card>
            <Form.Item label={'FVV'} name={'fvv'}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Card>
        </Col>

        <br />

        <Col span={24}>
          <Card>
            <Form.Item label={'LIA'} name={'lia'}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Card>
        </Col>

        <br />

        <Col span={24}>
          <Card>
            <Form.Item label={'TFR'} name={'tfr'}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Card>
        </Col>

        <br />

        <Row justify={'end'} gutter={12}>
          <Button type='primary' htmlType='submit'>
            Inserir
          </Button>
        </Row>
      </Form>
    </>
  );
}
