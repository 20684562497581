import { Mensagem } from '../../@types/Mensagem';
import Service from './Service';

class SendEmailService extends Service {
  static send(mensagem: Mensagem.Body) {
    return this.Http.post<Mensagem.Body>('/enviar-email', mensagem).then(
      this.getData
    );
  }
}

export default SendEmailService;
