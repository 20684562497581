import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Usuario } from '../../sdk/@types';
import UsuarioService from '../../sdk/services/SIZ-API/Usuario.service';

interface UsuarioState {
  usuarios: Usuario.Summary[] | undefined;
  fetching: boolean;
  query: Usuario.Query;
}

const initialState: UsuarioState = {
  usuarios: undefined,
  fetching: false,
  query: {},
};

export const filter = createAsyncThunk(
  'usuario/filter',
  async (query: Usuario.Query, { dispatch, rejectWithValue }) => {
    await UsuarioService.getAll(query)
      .then(async (usuarios) => await dispatch(storeUsuario(usuarios)))
      .catch((e) => {
        rejectWithValue(new Error('Não foi possível buscar os Usuários'));
      });
  }
);

const UsuarioSlice = createSlice({
  initialState,
  name: 'usuario',
  reducers: {
    storeUsuario(state, action: PayloadAction<Usuario.Summary[]>) {
      state.usuarios = action.payload;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<Usuario.Query>) {
      state.query = action.payload;
    },
  },
});

export const { storeUsuario, setFetching, setQuery } = UsuarioSlice.actions;

const UsuarioReducer = UsuarioSlice.reducer;
export default UsuarioReducer;
