import {
  Button,
  Col,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { RecebimentosRevendas } from '../../../sdk/@types';
import { useNavigate } from 'react-router-dom';
import * as RecebimentoRevendaActions from '../../../core/store/RecebimentosRevendas.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { setFirstOpening } from '../../../core/store/LoadingPage.slice';
import useDevOptions from '../../../core/hooks/useDevOptions';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import FilterPanelRecebimentoRevenda from './FilterPanelRecebimentoRevenda';

export default function ListRecebimentoRevendas() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const [deleting, setDeleting] = useState(false);
  const { loading, setLoading } = useLoadingPage();

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  const recebimentoRevendas = useSelector(
    (state: RootState) => state.recebimentosRevendas.recebimentosRevendas
  );
  const query = useSelector(
    (state: RootState) => state.recebimentosRevendas.query
  );
  const fetching = useSelector(
    (state: RootState) => state.recebimentosRevendas.fetching
  );

  if (!online)
    throw new Error(
      'Não é possível visualizar a lista de RecebimentoRevenda no modo offline'
    );
  if (error) throw error;

  useEffect(() => {
    dispatch(RecebimentoRevendaActions.filterRecebimentosRevendas(query));
    setFirstOpening(true);
  }, [dispatch, query]);

  const handleViewClick = (id: number) => {
    dispatch(RecebimentoRevendaActions.clearRecebimentosRevendasState());
    navigate(`/recebimentoRevenda/visualizar/${id}`);
  };
  
  const handleEditClick = (id: number) => {
    dispatch(RecebimentoRevendaActions.clearRecebimentosRevendasState());
    navigate(`/recebimentoRevenda/edicao/${id}`);
  };
  
  return (
    <>
      <FilterPanelRecebimentoRevenda />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<RecebimentosRevendas.Summary>
                columns={[
                  {
                    dataIndex: 'id',
                    width: idInTables ? 100 : 0,
                    render(id) {
                      return (
                        idInTables && (
                          <Typography.Text>{`ID: ${id}`}</Typography.Text>
                        )
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    render(_, recebimentoRevenda) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Typography.Text strong>
                            {recebimentoRevenda.cnpjRevenda}
                          </Typography.Text>

                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {moment(
                                new Date(recebimentoRevenda.dataRecebimento!)
                              ).format('DD/MM/YYYY HH:mm')}
                            </Typography.Text>

                            <Typography.Text>
                              {recebimentoRevenda.nomeRevenda}
                            </Typography.Text>

                            <Typography.Text>
                              {recebimentoRevenda.numeroNFE}
                            </Typography.Text>
                          </Space>
                        </Space>
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    width: 100,
                    align: 'right',
                    render(id: number) {
                      return (
                        <Space>
                          <Button
                            icon={<SearchOutlined />}
                            onClick={() => handleViewClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(id)}
                            size={'small'}
                            type={'ghost'}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
                dataSource={recebimentoRevendas?.content}
                loading={deleting || fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      RecebimentoRevendaActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: recebimentoRevendas?.totalElements,
                  pageSize: query.size,
                }}
                rowKey={'id'}
                size={'small'}
                showHeader={false}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        Form Recebimento de Insumos Pecuários - SVO IV
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() =>
                              navigate(`/recebimentoRevenda/cadastro`)
                            }
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined spin={loading} />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(
                                  RecebimentoRevendaActions.filterRecebimentosRevendas(
                                    query
                                  )
                                );
                              } catch (e) {
                                setError(new Error(error));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
