import { FVVReport } from '../../@types/FVVReport';
import generateQueryString from '../../utils/generateQueryString';
import Service from './Service';

class FVVReportService extends Service {
  static getSimplificado(
    dataIni: string,
    dataFim: string,
    codigoIBGE?: string
  ): Promise<FVVReport.Simplificado[]> {
    const queryString = generateQueryString({
      dataIni,
      dataFim,
      codigoIBGE,
    });
    return this.Http.get<FVVReport.Simplificado[]>(
      '/vigilancias-veterinarias/relatorio-simplificado'.concat(queryString)
    ).then(this.getData);
  }
}

export default FVVReportService;
