import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormGraf } from '../../sdk/@types';
import FormGrafService from '../../sdk/services/SIZ-API/formGraf.service';

type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

interface FormCustomState {
  data: FormGraf.Response | null;
  formGrafs: FormGraf.Paginated | null;
  fetching: boolean;
  loading: LoadingState;
  errorMessage?: string | null;
  query: FormGraf.Query;
}

const initialState: FormCustomState = {
  data: null,
  formGrafs: null,
  fetching: false,
  loading: 'idle',
  errorMessage: null,
  query: { page: 0, size: 10 },
};

export const filterFormGrafs = createAsyncThunk(
  'formGraf/filterFormGrafs',
  async (query: FormGraf.Query, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));

    try {
      const formGrafs = await FormGrafService.getAll(query);
      return formGrafs;
    } catch (e) {
      rejectWithValue(
        new Error('Não foi possível buscar os Termo Fiscalizacaos')
      );
    } finally {
      dispatch(setFetching(false));
    }
  }
);

export const insertFormGraf = createAsyncThunk(
  'formGraf/insert',
  async (formGrafRequest: FormGraf.Request, { rejectWithValue }) => {
    try {
      return await FormGrafService.add(formGrafRequest);
    } catch (error) {
      console.error('Error in insertFormGraf:', error);
      return rejectWithValue('Falha ao inserir o formulário');
    }
  }
);
export const updateFormGraf = createAsyncThunk(
  'formGraf/updateFormGraf',
  async (
    { id, formGraf }: { id: number; formGraf: FormGraf.Request },
    { rejectWithValue }
  ) => {
    return await FormGrafService.update(id, formGraf).catch(rejectWithValue);
  }
);
export const fetchFormGrafById = createAsyncThunk<FormGraf.Response, number>(
  'formGraf/fetchformGrafById',
  async (id: number, { rejectWithValue }) => {
    return await FormGrafService.getById(id).catch(rejectWithValue);
  }
);

export const removeFormGraf = createAsyncThunk(
  'formGraf/remove',
  async (id: number, { rejectWithValue }) => {
    return await FormGrafService.remove(id).catch(rejectWithValue);
  }
);

const formGrafSlice = createSlice({
  name: 'formGraf',
  initialState,
  reducers: {
    clearFormGrafState: (state) => {
      return initialState;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    setQuery(state, action: PayloadAction<FormGraf.Query>) {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertFormGraf.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(insertFormGraf.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(insertFormGraf.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(filterFormGrafs.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(filterFormGrafs.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.formGrafs = action.payload;
        state.fetching = false;
      })
      .addCase(filterFormGrafs.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(updateFormGraf.pending, (state) => {
        state.loading = 'pending';
        state.fetching = true;
      })
      .addCase(updateFormGraf.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        //@ts-ignore
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(updateFormGraf.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
        state.fetching = false;
      })
      .addCase(fetchFormGrafById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        fetchFormGrafById.fulfilled,
        (state, action: PayloadAction<FormGraf.Response | null>) => {
          state.loading = 'fulfilled';
          state.data = action.payload;
        }
      )
      .addCase(fetchFormGrafById.rejected, (state, action) => {
        state.loading = 'rejected';
        state.errorMessage = action.error.message;
      })
  },
});

export const { clearFormGrafState, setFetching, setQuery } =
  formGrafSlice.actions;

const FormGrafReducer = formGrafSlice.reducer;
export default FormGrafReducer;
