import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import { FVER } from '../../../sdk/@types';
import moment from 'moment';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { CustomModal } from '../../components/CustomModal';
import removeNonNumericCharactersFromString from '../../../core/functions/removeNonNumericCharactersFromString';

export default function PublicFVERList() {
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const params = useParams<{ cpf: string }>();
  const [fvers, setFVERs] = useState<FVER.Paginated>();
  const [query, setQuery] = useState<FVER.Query>({
    page: 0,
    size: 10,
  });
  const [finishedFetching, setFinishedFetching] = useState<boolean>(false);
  const [showModalConsulta, setShowModalConsulta] = useState(false);

  const fetchFVERsByCPF = useCallback(
    async (cpf: string) => {
      if (!cpf) {
        notification.warn({
          message: 'Nenhum CPF foi informado para a consulta',
        });

        setFinishedFetching(true);
        setShowModalConsulta(true);
        return;
      }

      await FVERService.getAll_PublicAccess({
        ...query,
        cpfProprietario: cpf,
      })
        .then(async (lista) => {
          setFVERs(lista);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setFinishedFetching(true);
        });
    },
    [query]
  );

  const fetchFVERsByParams = useCallback(async () => {
    if (params.cpf) fetchFVERsByCPF(params.cpf);
    else
      notification.warn({
        message: 'Nenhum CPF foi informado para a consulta',
      });
  }, [fetchFVERsByCPF, params]);

  useEffect(() => {
    fetchFVERsByParams();
  }, [fetchFVERsByParams, params]);

  useEffect(() => {
    if (fvers) {
      if (fvers.content?.length === 0) {
        setShowModalConsulta(true);
      }
    }
  }, [fvers]);

  if (error) throw error;

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
      }}
    >
      <Space direction={'vertical'} style={{ width: '100%' }} size={24}>
        <Spin
          size='large'
          spinning={!finishedFetching}
          tip={'Buscando Formulários de Visita'}
        >
          {fvers && fvers?.content && fvers?.content.length > 0 && (
            <>
              <Typography.Title level={3}>
                Formulários de Visita
              </Typography.Title>
              <Divider style={{ marginTop: '-10px' }} />
            </>
          )}

          <Col span={24}>
            <Table<FVER.Summary>
              rowKey={'id'}
              dataSource={fvers?.content}
              size={'small'}
              pagination={{
                current: query.page ? query.page + 1 : 1,
                onChange: (page, pageSize) => {
                  setQuery({
                    ...query,
                    page: page - 1,
                    size: pageSize,
                  });
                },
                total: fvers?.totalElements,
                pageSize: query.size,
              }}
              title={() => {
                return (
                  <Row justify={'space-between'}>
                    <Typography.Title level={5} style={{ color: 'white' }}>
                      Formulário de Visita
                    </Typography.Title>
                  </Row>
                );
              }}
              columns={[
                {
                  dataIndex: 'numero',
                  title: 'Número',
                  width: 175,
                  responsive: ['xs', 'sm'],
                  render(numero: FVER.Summary['numero']) {
                    return numero;
                  },
                },
                {
                  dataIndex: 'dataDaVisita',
                  title: 'Data',
                  width: 100,
                  responsive: ['xs', 'sm'],
                  render(data: FVER.Summary['dataDaVisita']) {
                    return data
                      ? moment(new Date(data)).format('DD/MM/YYYY')
                      : undefined;
                  },
                },
                {
                  dataIndex: 'nomeEstabelecimento',
                  title: 'Estabelecimento',
                  width: 200,
                  responsive: ['md'],
                  render(nomeFiscalizado: FVER.Summary['nomeEstabelecimento']) {
                    return nomeFiscalizado;
                  },
                },
                {
                  dataIndex: 'resumo',
                  title: 'Resumo',
                  width: 200,
                  responsive: ['md'],
                  render(nomeFiscalizado: FVER.Summary['resumo']) {
                    return nomeFiscalizado;
                  },
                },
                {
                  dataIndex: 'id',
                  title: 'Ações',
                  responsive: ['xs', 'sm'],
                  width: 100,
                  align: 'right',
                  render(_, fver: FVER.Summary) {
                    return (
                      <Button
                        icon={<SearchOutlined />}
                        size={'small'}
                        type={'ghost'}
                        onClick={() => {
                          navigate(
                            `/public/visualizarFVERCompleto/${fver.codigoVerificador}`
                          );
                        }}
                      />
                    );
                  },
                },
              ]}
            ></Table>
          </Col>
        </Spin>
        <Row justify={'end'} style={{ width: '100%' }}>
          <Col xs={24} sm={12} md={8}>
            <Button
              style={{ width: '100%' }}
              htmlType={'submit'}
              type={'primary'}
              onClick={() => navigate('/')}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </Space>

      <CustomModal
        centered
        open={showModalConsulta}
        title={'Consultar'}
        width={500}
        footer={null}
        style={{
          backgroundColor: 'transparent',
          minHeight: '350px',
        }}
        bodyStyle={{
          backgroundColor: '#f7f5f3',
          fontWeight: 'bold',
        }}
        closable
        maskClosable={false}
        onCancel={() => {
          setShowModalConsulta(false);
        }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <Form
          layout={'vertical'}
          size={'small'}
          onFinish={(formValues) => {
            notification.warn({
              message:
                'Não encontramos nenhum Formulário de Visita para este CNPJ',
            });
            setShowModalConsulta(false);
            navigate(
              `/public/visualizarFVERs/${removeNonNumericCharactersFromString(
                formValues.cnpj
              )}`
            );
          }}
        >
          {
            <div>
              <Alert
                message={
                  'Não encontramos nenhum Formulário de Visita para este CNPJ. Deseja consultar outro CNPJ?'
                }
                type='warning'
                showIcon
              />
              <br />

              <Col span={24}>
                <Form.Item
                  label={'Cpf/Cnpj'}
                  name={'cnpj'}
                  rules={[
                    {
                      required: true,
                      message: 'O campo é obrigatório',
                    },
                  ]}
                >
                  <Input placeholder='CPF ou CNPJ' />
                </Form.Item>
              </Col>

              <Button
                type='primary'
                style={{ fontWeight: 'bold', height: '60px', width: '100%' }}
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Consultar
              </Button>
            </div>
          }
        </Form>
      </CustomModal>
    </div>
  );
}
