import { openDB } from 'idb';
import moment from 'moment';
import DBConfig from './DB.config';

export type ServiceIDBPayload = {
  id?: number;
  date: Date;
  payload: any;
};

export type ServiceIDBPayloadInput = {
  status: 'ERRO' | 'NAO FINALIZADO' | 'NOVO' | 'SINCRONIZADO';
  motivoErro?: string;
} & ServiceIDBPayload;

class ServiceIDB {
  static DAYS_TO_EXPIRATION = 3;

  protected static async _add(
    storeName: string,
    object: ServiceIDBPayload
  ): Promise<IDBValidKey> {
    const db = await openDB(DBConfig.DB_NAME);
    return db.add(storeName, object);
  }

  protected static async _getFromIndex(
    storeName: string,
    indexName: string,
    key: any
  ): Promise<ServiceIDBPayload> {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.getFromIndex(storeName, indexName, key);
  }

  protected static async _getAllFromIndex(
    storeName: string,
    indexName: string,
    key: any
  ): Promise<any> {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.getAllFromIndex(storeName, indexName, key);
  }

  protected static async _listAllFromIndex(
    storeName: string,
    indexName: string
  ): Promise<any> {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.getAllFromIndex(storeName, indexName);
  }

  protected static async _get(
    storeName: string,
    key: number
  ): Promise<ServiceIDBPayload> {
    const db = await openDB(DBConfig.DB_NAME);
    return await db.get(storeName, key);
  }

  protected static async _getAll(
    storeName: string,
    indexName?: string
  ): Promise<ServiceIDBPayload[]> {
    const db = await openDB(DBConfig.DB_NAME);
    if (!indexName) return db.getAll(storeName);
    else return db.getAllFromIndex(storeName, indexName);
  }

  protected static async _delete(storeName: string, id: number): Promise<void> {
    const db = await openDB(DBConfig.DB_NAME);

    return db.delete(storeName, id);
  }

  protected static async _clear(storeName: string): Promise<void> {
    const db = await openDB(DBConfig.DB_NAME);
    db.clear(storeName);
  }

  protected static async _deleteByIndexKey(
    storeName: string,
    indexName: string,
    key: any
  ): Promise<any> {
    const request = indexedDB.open(DBConfig.DB_NAME);
    request.onsuccess = () => {
      const db = request.result;

      let tx = db.transaction([storeName], 'readwrite');
      let store = tx.objectStore(storeName);
      let index = store.index(indexName);
      let cursorIndex = index.openKeyCursor(IDBKeyRange.only(key));
      cursorIndex.onsuccess = () => {
        let cursor = cursorIndex.result;
        if (cursor) {
          store.delete(cursor.primaryKey);
          cursor.continue();
        }
      };
    };
  }

  static isDataExpired(date: Date) {
    const daysFromSync = moment(date).diff(moment(), 'days');
    return daysFromSync < -this.DAYS_TO_EXPIRATION;
  }

  static async _count(storeName: string) {
    const db = await openDB(DBConfig.DB_NAME);
    return db.count(storeName);
  }
}

export default ServiceIDB;
